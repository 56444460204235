<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="card-header bg-light" cdkDragHandle>
      <h4 class="card-title mb-0">Nova Autuação</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-container p-3">
        <div class="row ">
          <div class="form-group col-md-12">
            <label for="selectPapel" class="mb-1">Com qual Cargo/Função você irá Autuar o Processo?</label>
            <select id="selectPapel" #selectPapel [(ngModel)]="model.papelAutuadorId"
              (change)="onSelectPapel()" class="form-control form-control-sm">
              <option *ngFor="let item of papeis" [ngValue]="item.id">
                {{item.nomeFormatado}}</option>
            </select>
          </div>
        </div>

        <div class="row ">
          <div class="form-group col-md-12">
            <label for="selectLocalAutuacao" class="mb-1">Qual é o Local de Autuação?</label>
            <select id="selectLocalAutuacao" #selectLocalAutuacao [(ngModel)]="model.localizacaoId"
              class="form-control form-control-sm" [disabled]="!model.papelAutuadorId">
              <option *ngFor="let item of locaisAutuacao" [ngValue]="item.id">
                {{item.nomeFormatado}}</option>
            </select>
          </div>
        </div>
        
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()"
        [disabled]="!model.papelAutuadorId || !model.localizacaoId">
        <i class="fas fa-check"></i> Ok
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
</div>
