<!-- ABA INFORMAÇÕES BÁSICAS -->
<form [formGroup]="form" #f="ngForm" >
<div class="card-body">
	<div class="row">
		<div class="form-group col-md-3">
			<label for="numeroOpa" class="mb-1">Número da OPA</label>
			<input
				disabled
				type="text"
				id="numeroOpa"
				class="form-control form-control-sm"
				[ngModel]="model?.planejamento?.opa?.numero"
        [ngModelOptions]="{standalone: true}"
			/>
		</div>

		<div class="form-group col-md-3">
			<label for="numeroOsa" class="mb-1">Número da OSA</label>
			<input
				disabled
				type="text"
				id="numeroOsa"
				class="form-control form-control-sm"
				[ngModel]="model?.numero"
        [ngModelOptions]="{standalone: true}"
			/>
		</div>

		<div class="form-group col-md-3">
			<label for="origemAuditoria" class="mb-1"
				>Origem de Auditoria</label
			>
			<input
				disabled
				type="text"
				id="origemAuditoria"
				class="form-control form-control-sm"
				[ngModel]="model?.planejamento?.opa?.origem?.descricao"
        [ngModelOptions]="{standalone: true}"
			/>
		</div>

		<div class="form-group col-md-3">
			<label for="dataElaboracao" class="mb-1">Data de Elaboração</label>
			<input
				disabled
				type="text"
				id="dataElaboracao"
				class="form-control form-control-sm"
				[ngModel]="
					model?.planejamento?.opa?.dataElaboracao
						| date: 'dd/MM/yyyy'
				"
        [ngModelOptions]="{standalone: true}"
			/>
		</div>
	</div>

	<div class="row">
		<div class="form-group col-md-6">
			<label for="ugAuditar" class="mb-1">UG a ser Auditado</label>
			<input
				disabled
				type="text"
				id="ugAuditar"
				class="form-control form-control-sm"
				[ngModel]="model?.planejamento?.opa?.unidadeGestora?.nome"
        [ngModelOptions]="{standalone: true}"
			/>
		</div>

    <div class="form-group col-md-3">
			<label for="dataInicioPrevista" class="mb-1"
				>Data de Início Prevista</label
			>
			<input
				disabled
				type="text"
				id="dataInicioPrevista"
				class="form-control form-control-sm"
				[ngModel]="
					model?.planejamento?.opa?.dataInicioPrevista
						| date: 'dd/MM/yyyy'
				"
        [ngModelOptions]="{standalone: true}"
			/>
		</div>

		<div class="form-group col-md-3">
			<label for="dataFimPrevista" class="mb-1"
				>Data de Fim Prevista</label
			>
			<input
				disabled
				type="text"
				id="dataFimPrevista"
				class="form-control form-control-sm"
				[ngModel]="
					model?.planejamento?.opa?.dataFimPrevista
						| date: 'dd/MM/yyyy'
				"
        [ngModelOptions]="{standalone: true}"

			/>
		</div>
	</div>

	<div class="row">
		<div class="form-group col-md-6">
			<label for="objeto" class="mb-1">Objeto</label>
			<input
				disabled
				type="text"
				id="objeto"
				maxlength="100"
				class="form-control form-control-sm"
				[ngModel]="model?.planejamento?.opa?.objeto"
        [ngModelOptions]="{standalone: true}"
			/>
		</div>
    <div class="form-group col-md-3">
			<label for="dataInicioRealizacao" class="mb-1"
				>Data Início de Realização</label
			>
			<input
				[attr.disabled]="visualizar ? true : null"
				type="date"
				id="dataInicioRealizacao"
				class="form-control form-control-sm"
				formControlName="dataInicioRealizacao"
        [ngClass]="{ 'is-invalid': submitted && form.controls['dataInicioRealizacao'].invalid }"
			/>
      <div *ngIf="submitted && form.controls['dataInicioRealizacao'].invalid" class="invalid-feedback">
        <div *ngIf="form.controls['dataInicioRealizacao'].errors.required">Campo obrigatório</div>
      </div>
		</div>

		<div class="form-group col-md-3">
			<label for="dataFimRealizacao" class="mb-1"
				>Data Fim de Realização</label
			>
			<input
				[attr.disabled]="visualizar ? true : null"
				type="date"
				id="dataFimRealizacao"
				class="form-control form-control-sm"
				formControlName="dataFimRealizacao"
        [ngClass]="{ 'is-invalid': submitted && form.controls['dataFimRealizacao'].invalid }"
			/>
      <div *ngIf="submitted && form.controls['dataFimRealizacao'].invalid" class="invalid-feedback">
        <div *ngIf="form.controls['dataFimRealizacao'].errors.required">Campo obrigatório</div>
      </div>
		</div>
	</div>

	<div class="row">
		<div class="form-group col-md-6">
			<label for="responsavelUg" class="mb-1">Responsável pela UG</label>
			<input
				disabled
				type="text"
				id="responsavelUg"
				class="form-control form-control-sm"
				[ngModel]="
					model?.planejamento?.opa?.unidadeGestora?.nomeResponsavel
				"
        [ngModelOptions]="{standalone: true}"
			/>
		</div>

    <div class="form-group col-md-3">
			<label for="dataInicioPeriodo" class="mb-1"
				>Data Início Periodo Auditado</label
			>
			<input
				[attr.disabled]="visualizar ? true : null"
				type="date"
				id="dataInicioPeriodo"
				class="form-control form-control-sm"
        formControlName="dataInicioPeriodoAuditado"
        [ngClass]="{ 'is-invalid': submitted && form.controls['dataInicioPeriodoAuditado'].invalid }"
			/>
      <div *ngIf="submitted && form.controls['dataInicioPeriodoAuditado'].invalid" class="invalid-feedback">
        <div *ngIf="form.controls['dataInicioPeriodoAuditado'].errors.required">Campo obrigatório</div>
      </div>
		</div>

		<div class="form-group col-md-3">
			<label for="dataFimPeriodo" class="mb-1"
				>Data Fim Período Auditado</label
			>
			<input
				[attr.disabled]="visualizar ? true : null"
				type="date"
				id="dataFimPeriodo"
        formControlName="dataFimPeriodoAuditado"
				class="form-control form-control-sm"
        [ngClass]="{ 'is-invalid': submitted && form.controls['dataFimPeriodoAuditado'].invalid }"
			/>
      <div *ngIf="submitted && form.controls['dataFimPeriodoAuditado'].invalid" class="invalid-feedback">
        <div *ngIf="form.controls['dataFimPeriodoAuditado'].errors.required">Campo obrigatório</div>
      </div>
		</div>
	</div>

	<div class="row">
    <div class="form-group col-md-6">
      <label for="tipoAuditoria" class="mb-1">Tipo da Auditoria</label>
      <input
        disabled
        type="text"
        id="tipoAuditoria"
        class="form-control form-control-sm"
        [ngModel]="model?.planejamento?.opa?.tipoAuditoria?.nome"
        [ngModelOptions]="{standalone: true}"
      />
    </div>
	</div>

  <label class="mt-4">SubTipos</label>
	<div class="mat-elevation-z4 mb-4">
		<table
			[hidden]="subtipoDataSource.data.length === 0"
			mat-table
			[dataSource]="subtipoDataSource"
			matSort
      #tabela1="matSort"
			matSortDirection="asc"
		>

			<!-- Coluna Auditor -->
			<ng-container matColumnDef="nome">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
				<td mat-cell *matCellDef="let element">
					{{ element?.subTipoAuditoria?.nome }}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumnsSubtipo"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumnsSubtipo"></tr>
		</table>
		<h5 [hidden]="subtipoDataSource.data.length !== 0" class="table-no-data">
			Nenhum registro encontrado.
		</h5>
		<mat-paginator [pageSizeOptions]="[5]"  #TableOnePaginator="matPaginator">
		</mat-paginator>

	</div>


  <label >Alocar Equipe</label>
	<div class="mat-elevation-z4" >
		<table
			mat-table
			[dataSource]="equipeDataSource"
      matSort
      #tabela2="matSort"
		>

			<!-- Coluna Auditor -->
			<ng-container matColumnDef="auditor">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Auditor</th>
				<td mat-cell *matCellDef="let element">
					{{ element?.nomeAuditor }}
				</td>
			</ng-container>

			<!-- Coluna Ações -->
			<ng-container matColumnDef="acoes">
				<th mat-header-cell *matHeaderCellDef class="text-right">
          <button
					[hidden]="visualizar"
					type="button"
					class="btn btn-success btn-xs"
					(click)="exibirModalAlocarEquipe()"
				>
					Alocar Equipe <i class="far fas fa-plus fa-sm"></i>
				</button>
        </th>
				<td
					mat-cell
					*matCellDef="let element;"
					class="block"
				>
        <div class="d-flex justify-content-end align-items-center p-0" >
          <button
          [attr.disabled]="visualizar ? true : null"
          type="button"
          class="btn btn-danger btn-group-xs p-1 mr-2 d-flex justify-content-center align-items-center"
          style="margin-right: 32px !important; height: 20px;"
          (click)="desvincularAuditor(element.id)"
          >
          <i class="far fa-trash-alt" style="font-size: 13px;"></i>
          </button>
        </div>
        </td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<h5 [hidden]="equipeDataSource.data.length > 0" class="table-no-data" >
			Nenhum registro encontrado.
		</h5>
		<mat-paginator [pageSizeOptions]="[5]" #TableTwoPaginator="matPaginator">
		</mat-paginator>
	</div>

	<div class="row mt-4">
		<div class="form-group col-md-12">
			<label for="responsavelUg" class="mb-1"
				>Coordenador da auditoria</label
			>
      <div class="input-group input-group-sm" >
        <input type="text" name="table_search" class="form-control form-control-sm " disabled
          type="text"
          class="form-control form-control-sm"
          [ngClass]="{ 'invalid': submitted && form.controls['auditor'].invalid }"
          formControlName="auditor"
          />
          <div *ngIf="submitted && form.controls['auditor'].invalid" class="invalid-feedback">
            <div *ngIf="form.controls['auditor'].errors.required">Campo obrigatório</div>
          </div>

          <div class="input-group-append">
            <button type="button" [attr.disabled]="visualizar ? true : null" class="btn btn-info"
            (click)="exibirModalCoordenadorAuditoria()">
              <i class="fas fa-search"></i>
            </button>
          </div>
      </div>
		</div>
	</div>
	<div class="row">
		<div class="form-group col-md-12">
			<label for="txtNotaRodape" class="mb-1">Nota de Rodapé</label>
			<textarea
				rows="3"
				id="txtNotaRodape"
				[attr.disabled]="visualizar ? true : null"
				class="form-control form-control-sm"
				maxlength="500"
				formControlName="notaRodape"
        [ngClass]="{ 'is-invalid': submitted && form.controls['notaRodape'].invalid }"
			>
      <div *ngIf="submitted && form.controls['notaRodape'].invalid" class="invalid-feedback">
        <div *ngIf="form.controls['notaRodape'].errors.required">Campo obrigatório</div>
      </div>
			</textarea>
		</div>
	</div>
</div>
</form>
