<div id="modal-content-wrapper">
    <div class="card card-secondary card-outline">
        <div class="card-header bg-light">
            <h4 class="card-title mb-0">Avaliação</h4>
        </div>
        <div class="card-body p-2">
            <label for="" class="mb-2">Avaliar</label>
            <div class="row col-12 p-2">
                <div class="form-check mr-4">
                    <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="1" [(ngModel)]="situacaoAvaliacao"
                        checked>
                    <label class="form-check-label" for="gridRadios1">Aprovar
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="2" [(ngModel)]="situacaoAvaliacao"
                        checked>
                    <label class="form-check-label" for="gridRadios2">Reprovar
                    </label>
                </div>
            </div>
            <div class="row p-1">
                <div class="form-group col-md-12">
                    <label for="" class="mb-1">Comentário</label>
                    <div class="input-group input-group-sm" style="width: 100%">
                        <textarea rows="3" id="" class="form-control form-control-sm" [(ngModel)]="comentario">
                       </textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <button type="button" class="btn btn-success btn-sm mr-1" (click)="concluir()">
                <i class="fas fa-check"></i> Confirmar
            </button>

            <button type="button" class="btn btn-danger btn-sm" (click)="cancelar()">
                <i class="fas fa-times"></i> Cancelar
            </button>
        </div>
    </div>
