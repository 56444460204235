import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { ICategoriaRiscoModel } from 'src/app/models/categoria-risco.model';
import { AuthService } from 'src/app/services/auth.service';
import { CategoriaRiscoService } from 'src/app/services/categoria-risco.service';
import { FiltroModel } from 'src/app/models/filtro.model';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';

const sortingDataAccessor = (item, property) => {
  switch (property) {
     case 'nome': return item.nome.trim().toLowerCase();   
     case 'descricao': return item.descricao.trim().toLowerCase();  
     case 'ativo':
      return item.ativo; 
    default:
      return item.nome.trim().toLowerCase();
  }
};

@Component({
  selector: 'app-categoria-risco-list',
  templateUrl: './categoria-risco-list.component.html',
  styleUrls: ['./categoria-risco-list.component.scss']
})
export class CategoriaRiscoListComponent extends BaseListComponent  {
// declarar aqui as variavis
public dataSource = new MatTableDataSource<ICategoriaRiscoModel>([]);
public selectedModel: ICategoriaRiscoModel;
public displayedColumns: string[] = ['selecao', 'nome', 'descricao','ativo'];
public searchNome: string;
public searchAtivo = true;
public semDados = true;
public permissoes: IPermissaoModel;
public filtro = {} as FiltroModel;
public totalItens: number;
public pageEvent: any;


@ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
@ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private authService: AuthService,
    private categoriaRiscoService: CategoriaRiscoService,
    private router: Router
  ) {
    super();
    this.buscar();
   }

  ngOnInit(): void {
    this.setarPermissoes();
    this.configurarFiltro();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Visualizar);
 }

 ngAfterViewInit() {
  this.configurarFiltro();
  this.buscar();
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;
  this.dataSource.sortingDataAccessor = sortingDataAccessor;
}

public novo() {
  this.router.navigate(['/categoria-risco/novo']);
}

public buscar() {
  this.selectedModel = null;
  this.categoriaRiscoService
    .obter(this.filtro)
    .then((res) => {
      this.totalItens = res.dados.totalItens;
      this.dataSource = new MatTableDataSource<any>(res.dados.resultado);
      this.totalItens = res.dados.totalItens;
      this.paginator = this.tableOnePaginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = sortingDataAccessor;
      this.dataSource._updateChangeSubscription();
      this.semDados = this.dataSource.filteredData.length === 0;
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem);
    })
}

public reativar(id: number) {
  this.categoriaRiscoService
    .reativar(id)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso('Categoria de Risco reativado com sucesso.');
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err);
    })
    .finally(() => {
      this.buscar();
    });
}

public inativar(id: number) {
  this.categoriaRiscoService
    .inativar(id)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso('Categoria de Risco inativado com sucesso.');
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem);
    })
    .finally(() => {
      this.buscar();
    });
}

public editar(id: number) {
  this.router.navigate([`/categoria-risco/${id}`]);
}

public selecionar(item: any) {
  this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
}

public excluir(id: number) {
  this.categoriaRiscoService
    .excluir(id)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso('Categoria de Risco excluído com sucesso.');
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem);
    })
    .finally(() => {
      this.buscar();
    });
}

public visualizar(id: number) {
  this.router.navigate([`/categoria-risco/${id}/visualizar`]);
}

private configurarFiltro() {
  this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
  this.filtro.itensPorPagina = this.tableOnePaginator.pageSize;
  this.filtro.colunaOrder = this.sort.active;
  this.filtro.ativo = this.searchAtivo;
  this.filtro.pesquisa = this.searchNome;
  this.filtro.direcao = this.sort.direction;
}

sortData(sort) {
  this.filtro.colunaOrder = sort.active;
  this.filtro.direcao = sort.direction;

  this.buscar();
}

pageChanged(e) {
  this.filtro.pagina = e.pageIndex + 1;
  e.pageIndex = this.filtro.pagina;
  this.filtro.itensPorPagina = e.pageSize;
  this.buscar();
}

}
