import { AfterViewInit, Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import Inputmask from "inputmask";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { toIntlNumberString } from "src/app/core/helpers/number.helper";
import { IPerguntaMetricaModel } from "src/app/models/pergunta-metrica.model";
import { IPerguntaModel } from "src/app/models/pergunta.model";
import { PerguntaService } from "src/app/services/pergunta.service";
import { IEnumModel } from "src/app/models/enum.model";
import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ReactiveFormsModule } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatIconModule } from "@angular/material/icon";

@Component({
	selector: "app-modal-metrica-pergunta",
	templateUrl: "./modal-metrica-pergunta.component.html",
	styleUrls: ["./modal-metrica-pergunta.component.scss"],
})
export class ModalMetricaPerguntaComponent
	extends BaseFormComponent
	implements AfterViewInit, OnInit
{
	public tipoEntrada: string;
	public enumTipoComparacao = [] as IEnumModel[];
	public model: IPerguntaModel = {} as IPerguntaModel;
	public element = {} as IPerguntaMetricaModel;
	public dataSource = new MatTableDataSource<IPerguntaModel>([]);
	public filtroPergunta: string | null = null;
	public perguntas: any;
	public subPergunta: any;
	perguntasFiltradas: Observable<any[]>;

	public form = new FormGroup({
		id: new FormControl({ value: "", disabled: true }),
		nome: new FormControl("", Validators.required),
		descricao: new FormControl("", Validators.required),
		tipoEntradaId: new FormControl(null, Validators.required),
		unidade: new FormControl(null, Validators.required),
		perguntaId: new FormControl(null),
		subPergunta: new FormControl(null, Validators.required),
	});

	constructor(
		route: ActivatedRoute,
		toastr: ToastrService,
		router: Router,
		localeService: BsLocaleService,
		matDialog: MatDialog,
		private dialogRef: MatDialogRef<ModalMetricaPerguntaComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private perguntaService: PerguntaService
	) {
		super(route, toastr, router, localeService, matDialog);
		this.element = data.element;
		this.tipoEntrada = data.tipoEntrada;
		this.enumTipoComparacao = data.enumTipoComparacao;

		this.formMetrica = new FormGroup(
			{
				operacao1Id: new FormControl(
					this.element?.operacao1Id,
					Validators.required
				),
				valorNumerico1: new FormControl(
					this.element?.valorNumerico1,
					Validators.required
				),
				operacao2Id: new FormControl(this.element?.operacao2Id),
				valorNumerico2: new FormControl(this.element?.valorNumerico2),
				subPergunta: new FormControl(
					this.element?.subPergunta,
					Validators.required
				),
				nota: new FormControl(this.element?.nota),
			},
			{ validators: [this.ValidateRequiredOperacao2.bind(this)] }
		);
	}

	public formMetrica: FormGroup;

	ngAfterViewInit() {
		Inputmask().mask(document.querySelectorAll("input"));
	}

	ngOnInit(): void {
		this.carregaTodasPerguntas();
	}

	private filter(value: string): any[] {
		const filterValue = value.toLowerCase();
		return this.perguntas.filter((option) =>
			option.nome.toLowerCase().includes(filterValue)
		);
	}

	public concluir() {
		const operacao2Id = this.formMetrica.controls["operacao2Id"];

		const valorNumerico1 = parseFloat(
			toIntlNumberString(
				this.formMetrica.controls["valorNumerico1"].value.toString()
			)
		);
		const valorNumerico2 = this.formMetrica.controls["valorNumerico2"].value
			? parseFloat(
					toIntlNumberString(
						this.formMetrica.controls[
							"valorNumerico2"
						].value.toString()
					)
			  )
			: null;

		operacao2Id.setErrors(
			!operacao2Id.value &&
				this.formMetrica.controls["valorNumerico2"].value
				? { required: true }
				: null
		);
		this.formMetrica.controls["valorNumerico2"].setErrors(
			operacao2Id.value &&
				!this.formMetrica.controls["valorNumerico2"].value
				? { required: true }
				: null
		);

		this.formMetrica.markAllAsTouched();

		if (!this.formMetrica.valid) {
			return;
		}

		const nota = this.formMetrica.controls["nota"].value? parseFloat(
			toIntlNumberString(
				this.formMetrica.controls["nota"].value.toString()
			)
		): null;


		const modelMetrica = {
			operacao1Id: parseInt(
				this.formMetrica.controls["operacao1Id"].value,
				null
			),
			operacao1: this.enumTipoComparacao.find(
				(t) =>
					t.codigo ===
					parseInt(
						this.formMetrica.controls["operacao1Id"].value,
						null
					)
			),
			operacao2Id: parseInt(
				this.formMetrica.controls["operacao2Id"].value,
				null
			),
			operacao2: this.enumTipoComparacao.find(
				(t) =>
					t.codigo ===
					parseInt(
						this.formMetrica.controls["operacao2Id"].value,
						null
					)
			),
			valorNumerico1,
			valorNumerico2,
			subPergunta: this.formMetrica.controls["subPergunta"].value || null,
			nota,
		} as IPerguntaMetricaModel;

		this.dialogRef.close(modelMetrica);
	}

	public cancelar() {
		this.dialogRef.close(null);
	}

	public async carregaTodasPerguntas() {
		const res = await this.perguntaService
			.obter("", true)
			.then((res) => {
				this.dataSource = new MatTableDataSource<any>(res.dados);
				this.perguntas = res.dados;
				if(this.element?.subPergunta){
					this.formMetrica.get('subPergunta').setValue(this.element.subPergunta);
				}
			})
			.catch((err) => {
				this.exibirMensagemErro(err.mensagem);
			});
	}

	private ValidateRequiredOperacao2() {
		return (formGroup: FormGroup) => {
			const operacao2Id = formGroup.controls["operacao2Id"];
			const valorNumerico2 = formGroup.controls["valorNumerico2"];

			if (operacao2Id.value && !valorNumerico2.value) {
				operacao2Id.setErrors(null);
				valorNumerico2.setErrors({ required: true });
			} else if (!operacao2Id.value && valorNumerico2.value) {
				operacao2Id.setErrors({ required: true });
				valorNumerico2.setErrors(null);
			} else {
				operacao2Id.setErrors(null);
				valorNumerico2.setErrors(null);
			}
		};
	}
}
