<ngx-loading-bar color="#ffc107" height="3px"></ngx-loading-bar>
<ngx-spinner
  bdColor="rgba(0,0,0,0.3)"
  size="small"
  color="#fff"
  type="line-scale"
  [fullScreen]="true"
>
  <p style="color: white">Processando...</p>
</ngx-spinner>
<router-outlet></router-outlet>
