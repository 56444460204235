<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item active">Rol de Responsáveis</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i class="fas fa-table pr-2"></i>
              Rol de Responsáveis
            </h3>
          </div>
          <div class="card-body p-2">
            <div class="mat-elevation-z4">
              <mat-toolbar class="table-header">
                <mat-toolbar-row>
                  <button
                    type="button"
                    class="btn btn-success btn-sm mr-1"
                    [disabled]="!selectedModel"
                    (click)="responsaveis(selectedModel.id)">
                    <i class="far fa-file"></i> Responsáveis
                  </button>
                  <button
                    type="button"
                    class="btn btn-info btn-sm mr-1"
                    [disabled]="!selectedModel"
                    [hidden]="!permissoes.visualizar"
                    (click)="visualizar(selectedModel.id)">
                    <i class="far fas fa-eye"></i>
                    Visualizar
                  </button>
                  <div
                    class="imprimir__buttons btn-group-sm mr-1">
                    <button id="btnGroupDrop1" type="button" class="btn btn-info
                      btn-secondary dropdown-toggle" data-toggle="dropdown"
                      [hidden]="!permissoes.imprimir"
                      aria-haspopup="true" aria-expanded="false">
                      Imprimir
                    </button>
                    <div class="dropdown-menu" role="menu">
                      <button
                        type="button"
                        class="btn btn-default dropdown-item"
                        [hidden]="!permissoes.imprimir"
                        (click)="imprimirRol()">
                        Imprimir Rol
                      </button>
                      <button
                        type="button"
                        class="btn btn-default text-center dropdown-item"
                        [hidden]="!permissoes.imprimir"
                        (click)="exibirModalUgImprimir()">
                        Imprimir Rol por Unidade Gestora
                      </button>
                    </div>
                  </div>

                  <button
                    type="button"
                    class="btn btn-warning btn-sm mr-1"
                    [hidden]="!selectedModel || selectedModel.ativo || !permissoes.inativarReativar"
                    mwlConfirmationPopover popoverTitle="Reativar" popoverMessage="Tem certeza que deseja reativar esse registro?" confirmButtonType="primary" placement="bottom" confirmText="Sim" cancelText="Não" (confirm)="reativar(selectedModel.id)"
                    >
                    <i class="far fa-play-circle"></i>
                    Reativar
                  </button>
                  <button
                    type="button"
                    class="btn btn-warning btn-sm mr-1"
                    [hidden]="(selectedModel && !selectedModel.ativo) || !permissoes.inativarReativar"
                    [disabled]="!selectedModel?.ativo"
                    mwlConfirmationPopover popoverTitle="Inativar" popoverMessage="Tem certeza que deseja inativar esse registro?" confirmButtonType="primary" placement="bottom" confirmText="Sim" cancelText="Não" (confirm)="inativar(selectedModel.id)">
                    <i class="far fa-pause-circle"></i>
                    Inativar
                  </button>

                  <span class="fill-remaining-space"></span>
                  <span class="fill-remaining-space"></span>

                  <div
                    class="input-group input-group-sm mr-1"
                    style="width: 100px">
                    <input
                      type="datetime"
                      name="date_search"
                      class="form-control float-right"
                      placeholder="Data Início"
                      [(ngModel)]="dataInicio"
                      bsDatepicker
                      [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                      />
                  </div>

                  <div
                    class="input-group input-group-sm mr-1"
                    style="width: 100px">
                    <input
                      type="datetime"
                      name="date_search"
                      class="form-control float-right"
                      placeholder="Data Fim"
                      [(ngModel)]="dataFim"
                      bsDatepicker
                      [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                      />
                  </div>

                  <div class="input-group input-group-sm" style="width: 200px">
                    <input
                      type="text"
                      name="table_search"
                      class="form-control float-right"
                      (keyup.enter)="buscar()"
                      placeholder="Pesquisar"
                      [(ngModel)]="searchNome"
                      />

                    <div class="input-group-append">
                      <button
                        type="button"
                        class="btn btn-info"
                        (click)="buscar()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  <div class="btn-group">
                    <button
                      type="button"
                      data-toggle="dropdown"
                      class="btn btn-tool btn-default dropdown-toggle">
                      <i class="fas fa-filter"></i>
                    </button>
                    <div
                      role="menu"
                      class="dropdown-menu dropdown-menu-right
                      dropdown-menu-checkbox">
                      <a
                        (click)="searchAtivo= undefined; buscar()"
                        class="dropdown-item"><i
                          [ngClass]="searchAtivo === undefined
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'"></i>Exibir todos</a>
                      <a
                        (click)="searchAtivo= true; buscar()"
                        class="dropdown-item"><i
                          [ngClass]="searchAtivo === true
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'"></i>Exibir somente ativos</a>
                      <a
                        (click)="searchAtivo= false; buscar()"
                        class="dropdown-item"><i
                          [ngClass]="searchAtivo === false
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'"></i>Exibir somente inativos</a>
                    </div>
                  </div>
                </mat-toolbar-row>
              </mat-toolbar>
              <table
                mat-table
                [dataSource]="dataSource"
                class="w-100"
                matSort
                matSortDisableClear
                matSortActive="id"
                matSortDirection="asc"
                class="mat-elevation-z8"
                [hidden]="semDados">
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <ng-container matColumnDef="selecao">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      color="primary"
                      (change)="selectedModel= $event.checked ? element : null"
                      [checked]="selectedModel?.id === element.id">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="codigoUg">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Código UG
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.codigoUg }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="nome">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Unidade Gestora
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.sigla }} - {{ element.nome }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="dataInicio">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Data Início
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.dataInicio | date: 'dd/MM/yyyy' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="dataFim">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Data Fim
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.dataFim | date: 'dd/MM/yyyy' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="quantidadeResponsaveis">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Qtde de Responsáveis Vigentes
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center;">
                    {{ element.quantidadeResponsaveis }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="ativo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Situação
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.ativo" class="badge bg-success
                      text-xs">Ativo</span>
                    <span
                      *ngIf="!element.ativo"
                      class="badge bg-gray-dark text-xs">Inativo</span>
                  </td>
                </ng-container>

                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  (click)="selecionar(row)"
                  class="clickable"
                  [ngClass]="{ 'bg-light': selectedModel?.id === row.id }"></tr>
              </table>
              <h5 [hidden]="!semDados" class="table-no-data">
                Nenhum registro encontrado.
              </h5>
              <mat-paginator
                [pageSizeOptions]="[15, 30, 50]"
                showFirstLastButtons
                (page)="selectedModel= null">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
