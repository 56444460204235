import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as cloneDeep from 'lodash/cloneDeep';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { IProcedimentoAuditoriaModel } from 'src/app/models/procedimento-auditoria.model';
import { IQuestaoConstatacaoModel } from 'src/app/models/questao-constatacao.model';
import { IMatrizPlanejamentoModel } from 'src/app/models/matriz-planejamento.model';
import { IQuestaoProcedimentoAuditoriaModel } from 'src/app/models/questao-procedimento-auditoria.model';
import { ISubtipoAuditoriaMatrizPlanejamentoModel } from 'src/app/models/subtipo-auditoria-matriz-planejamento.model';
import { ISubtipoAuditoriaModel } from 'src/app/models/subtipo-auditoria.model';
import { IQuestaoModel } from 'src/app/models/questao.model';
import { IProcedimentoTecnicaAuditoriaModel } from 'src/app/models/procedimento-tecnica-auditoria.model';
import { IMatrizPlanejamentoQuestaoModel } from 'src/app/models/matriz-planejamento-questao.model';
import { ProcedimentoAuditoriaService } from 'src/app/services/procedimento-auditoria.service';
import { TecnicaAuditoriaService } from 'src/app/services/tecnica-auditoria.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-procedimento-auditoria-form',
  templateUrl: './procedimento-auditoria-form.component.html',
  styleUrls: ['./procedimento-auditoria-form.component.scss']
})
export class ProcedimentoAuditoriaFormComponent extends BaseFormComponent implements OnInit {
  public model: IProcedimentoAuditoriaModel = {} as IProcedimentoAuditoriaModel;
  public modelMatrizPlanejamento: IMatrizPlanejamentoModel = {} as IMatrizPlanejamentoModel;
  public modelProcedimentoTecnicaAuditoria: IProcedimentoTecnicaAuditoriaModel = {} as IProcedimentoTecnicaAuditoriaModel;
  public modelSubtipoAuditoria: ISubtipoAuditoriaModel = {} as ISubtipoAuditoriaModel;
  public tecnicaAuditoriaDataSource = new MatTableDataSource<IProcedimentoTecnicaAuditoriaModel>([]);
  public listaConstatacao = new MatTableDataSource<IQuestaoConstatacaoModel>([]);
  public listaProcedimentoAuditoria = new MatTableDataSource<IQuestaoProcedimentoAuditoriaModel>([]);
  public listaQuestao = new MatTableDataSource<IMatrizPlanejamentoQuestaoModel>([]);
  public listaMatrizPlanejamento = new MatTableDataSource<ISubtipoAuditoriaMatrizPlanejamentoModel>([]);
  public salvar: boolean;

  public rotaPrefixoSubtipoAuditoria = undefined;
  public rotaPrefixoMatrizPlanejamento = undefined;
  public semDados = true;
  public modelQuestao: IQuestaoModel = {} as IQuestaoModel;

  public isModal: boolean = false;

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    fonteInformacao: new FormControl('', Validators.required),
    detalhamentoProcedimento: new FormControl('', Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private procedimentoAuditoriaService: ProcedimentoAuditoriaService,
    private tecnicaAuditoriaService: TecnicaAuditoriaService,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<ProcedimentoAuditoriaFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dados: any
  ) {
    super(route, toastr, router, localeService, matDialog);

    if(dados?.isModal) this.isModal = dados?.isModal;
    if(dados.model?.id) {
      this.id = dados?.data?.id;
      this.novoRegistro = !this.id ? true : false;
    }
  }


  config: AngularEditorConfig = {
    editable: !this.visualizar,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async ngOnInit() {
    this.state();
    try {
      if (!this.novoRegistro && this.id) {
        const res = await this.procedimentoAuditoriaService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;

          this.tecnicaAuditoriaDataSource = new MatTableDataSource<IProcedimentoTecnicaAuditoriaModel>
          (
            this.model.procedimentoTecnicaAuditoria
          );
          this.tecnicaAuditoriaDataSource._updateChangeSubscription();
          this.semDados = this.tecnicaAuditoriaDataSource.filteredData.length === 0;
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          // this.router.navigate(['/procedimento-auditoria']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      // this.router.navigate(['/procedimento-auditoria']);
    }
    this.atribuirTitulo();
  }

  public async state() {
    // model questao
    this.modelQuestao = window.history.state.model;
    // model Subtipo Auditoria
    this.modelSubtipoAuditoria = window.history.state.modelSubtipoAuditoria;
    // model matriz planejamento
    this.modelMatrizPlanejamento = window.history.state.modelMatrizPlanejamento;
    // lista procedimento
    this.listaProcedimentoAuditoria = window.history.state.listaProcedimentoAuditoria;
    // lista questão
    this.listaQuestao = window.history.state.listaQuestao;
    // lista constatacao
    this.listaConstatacao = window.history.state.listaConstatacao;
    // lista Matriz Planejamento
    this.listaMatrizPlanejamento = window.history.state.listaMatrizPlanejamento;
    // rota
    if (window.history.state.rotaPrefixoSubtipoAuditoria) {
      this.rotaPrefixoSubtipoAuditoria = window.history.state.rotaPrefixoSubtipoAuditoria;
    }
    if (window.history.state.rotaPrefixoMatrizPlanejamento) {
      this.rotaPrefixoMatrizPlanejamento = window.history.state.rotaPrefixoMatrizPlanejamento;
    }
  }

  public async onSubmit(salvarEFechar: boolean = false) {
    this.salvar = true;
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }

    this.atualizarModel(this.form.value);
    this.model.procedimentoTecnicaAuditoria = this.tecnicaAuditoriaDataSource.data;

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        res = await this.procedimentoAuditoriaService.atualizar(this.model);
      } else {
        res = await this.procedimentoAuditoriaService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Registro salvo com sucesso!',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        if (salvarEFechar){
        if (this.modelQuestao && !this.isModal) {
          this.router.navigate(['/questao/novo'], {
            state: {
              model: this.modelQuestao,
              modelMatrizPlanejamento: this.modelMatrizPlanejamento,
              modelSubtipoAuditoria: this.modelSubtipoAuditoria,
              listaProcedimentoAuditoria: this.listaProcedimentoAuditoria,
              listaConstatacao: this.listaConstatacao,
              listaQuestao: this.listaQuestao,
              listaMatrizPlanejamento: this.listaMatrizPlanejamento,
              rotaPrefixoSubtipoAuditoria: this.rotaPrefixoSubtipoAuditoria,
              rotaPrefixoMatrizPlanejamento: this.rotaPrefixoMatrizPlanejamento
            }
          });
        }else{
          if(!this.isModal) this.router.navigate(['/procedimento-auditoria']);
          this.dialogRef.close();
        }
      }else {
        if (this.novoRegistro) {
          this.router.navigate([`/procedimento-auditoria/${res.dados.id}`]);
          await this.ngOnInit(); 
        }
      }
      } else {
        const validacoes = this.montarMensagensValidacao(res);

        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: validacoes,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public incluirTecnicaAuditoria() {
    if (this.tecnicaAuditoriaDataSource.data.findIndex(a => a.tecnicaAuditoriaId === this.modelProcedimentoTecnicaAuditoria.tecnicaAuditoriaId) >= 0) {
      this.exibirMensagemAlerta('Técnica de auditoria já cadastrado nesse procedimento');
      return;
    }

    this.tecnicaAuditoriaDataSource.data.push(cloneDeep(this.modelProcedimentoTecnicaAuditoria));
    this.tecnicaAuditoriaDataSource._updateChangeSubscription();
    this.modelProcedimentoTecnicaAuditoria = {} as IProcedimentoTecnicaAuditoriaModel;
    this.semDados = false;
  }

  public excluirTecnicaAuditoria(ind: number) {
    this.tecnicaAuditoriaDataSource.data.splice(ind, 1);
    this.tecnicaAuditoriaDataSource._updateChangeSubscription();
    this.semDados = this.tecnicaAuditoriaDataSource.filteredData.length === 0;
  }

  public async exibirModalTecnicaAuditoria() {
    try {
      const res = await this.tecnicaAuditoriaService.obter('', true);
      if (res.sucesso) {
        const dadosFiltrados = res.dados.filter(tecnicaAuditoria => tecnicaAuditoria.ativo);
        const modal = this.exibirModal('Selecionar Técnica de Auditoria', dadosFiltrados);
        modal.afterClosed().subscribe(data => {
          if (data) {
            this.modelProcedimentoTecnicaAuditoria.tecnicaAuditoriaId = data.id;
            this.modelProcedimentoTecnicaAuditoria.tecnicaAuditoria = data;
          }
        }
        );
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    if(this.isModal) {
      this.dialogRef.close();
      return;
    }
    if (this.modelQuestao) {
    this.router.navigate(['/questao/novo'], {
      state: {
        model: this.modelQuestao,
        modelMatrizPlanejamento: this.modelMatrizPlanejamento,
        modelSubtipoAuditoria: this.modelSubtipoAuditoria,
        listaProcedimentoAuditoria: this.listaProcedimentoAuditoria,
        listaConstatacao: this.listaConstatacao,
        listaQuestao: this.listaQuestao,
        listaMatrizPlanejamento: this.listaMatrizPlanejamento,
        rotaPrefixoSubtipoAuditoria: this.rotaPrefixoSubtipoAuditoria,
        rotaPrefixoMatrizPlanejamento: this.rotaPrefixoMatrizPlanejamento
      }
    });
    }else{
      this.router.navigate(['/procedimento-auditoria']);
    }
  }

  atribuirTitulo(){
    if (this.novoRegistro) {
      this.titulo = 'Novo Procedimento';
    } else {
      this.titulo = 'Editar Procedimento - ' + this.model.nome;
    }
    if (this.visualizar) {
      this.titulo = 'Visualizar Procedimento - ' + this.model.nome;
    }
  }
}
