<div class="card-body">
    <div class="row">
        <div class="col-md-12">
            <div class="mat-elevation-z1">
                <mat-table  [dataSource]="indicadoresDataSource" class="w-100">
                  
                    <!-- Critério Column -->
                    <ng-container matColumnDef="nomeCriterio">
                      <mat-header-cell *matHeaderCellDef> Critério </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.nomeCriterio}} </mat-cell>
                    </ng-container>
                  
                    <!-- Unidade Medida Column -->
                    <ng-container matColumnDef="unidadeMedida">
                      <mat-header-cell *matHeaderCellDef> Unidade Medida </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.unidadeMedida}} </mat-cell>
                    </ng-container>
                  
                    <!-- Tipo entrada Column -->
                    <ng-container matColumnDef="tipoEntrada">
                      <mat-header-cell *matHeaderCellDef> Tipo entrada </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.tipoEntrada}} </mat-cell>
                    </ng-container>
                  
                    <!-- Ações Column -->
                    <ng-container matColumnDef="actions">
                      <mat-header-cell *matHeaderCellDef style="justify-content: end;"> Ações </mat-header-cell>
                      <mat-cell *matCellDef="let element" style="justify-content: end;"> 
                        <button
															type="button"
															class="
																btn
																btn-default
																btn-xs
															"
															*ngIf="
                                                            mostrarBotaoImportarArquivo(element)
															"
															(click)="
																importarArquivoCriterioPaa(
																	element.idPaaIndiceCriterio
																)
															"
														>
															Importar Arquivo
														</button>
                                                        <button
                                                        type="button"
                                                        class="
                                                            btn
                                                            btn-default
                                                            btn-xs
                                                        "
                                                        *ngIf="
                                                        mostrarBotaoPreencherFormulario(element)
                                                        "
                                                        (click)="
                                                            preencherFormularioCriterioPaa(
                                                                element.idPaaIndiceCriterio
                                                            )
                                                        "
                                                    >
                                                        Preencher Formulário
                                                    </button>
                                                    <div class="btn-group">
														<button
															*ngIf="
                                                            mostrarBotaoVisualizarResposta(element)
															"
															class="
																btn
																btn-info
																btn-xs
																mr-1
															"
															type="button"
															(click)="
																preencherFormularioCriterioPaa(
																	element.idPaaIndiceCriterio,
																	true
																)
															"
														>
															<i
																class="
																	far
																	fa-eye
																"
															></i>
														</button>
                                                        <button
														type="button"
														class="
															btn
															btn-danger
															btn-sm
															mr-1
														"
														(click)="
														excluirCriterio(
																element.idPaaIndiceCriterio
															)
														"
														*ngIf="
                                                        mostrarBotaoExcluirCriterio(element)
														"
													>
														<i
															class="
																fas
																fa-trash-alt
															"
														></i>
													</button>
													</div>
                    </mat-cell>
                    </ng-container>
                  
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  
                    <!-- Group header -->
                    <ng-container matColumnDef="groupHeader">
                      <mat-cell *matCellDef="let iPaaAbaICIndicadorModel" class="block" colspan="999">
                        <strong> {{iPaaAbaICIndicadorModel.nomeIndicador}}</strong>
                        <span class="fill-remaining-space"></span>
                        <button
                            *ngIf="mostrarBotaoAbrirModalRankingPrioridade(iPaaAbaICIndicadorModel)"
                            type="button"
                            class="
                                btn btn-info btn-xs
                                mr-1
                                ml-auto
                            "
                            (click)="
                                abrirModalRankingPrioridade(
                                    iPaaAbaICIndicadorModel
                                )
                            "
                        >
                            <i
                                class="far fa-eye"
                            ></i>
                        </button>
                        <button
                        type="button"
                        class="
                            btn
                            btn-danger
                            btn-sm
                            mr-1
                        "
                        (click)="
                        excluirIndicador(
                            iPaaAbaICIndicadorModel.idIndicador
                            )
                        "
                        *ngIf="
                        mostrarBotaoExcluirIndicador(iPaaAbaICIndicadorModel)
                        "
                    >
                        <i
                            class="
                                fas
                                fa-trash-alt
                            "
                        ></i>
                    </button>
                    </mat-cell>
                    </ng-container>
                  
                    <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </mat-row>
                  
                  </mat-table>
                <h5
                    [hidden]="!criteriosDataSourceSemDados"
                    class="table-no-data"
                >
                    Nenhuma Carga de Critérios a
                    exibir
                </h5>
            </div>
        </div>
    </div>
</div>
<div class="card-footer">
    <button
								type="button"
                                [hidden]="visualizar"
								class="btn btn-primary btn-sm mr-1"
								*ngIf="mostrarBotaoAdicionarCriterio"
								(click)="adicionarCriterio()"
							>
								<i class="far fa-file"></i> Adicionar Critério
							</button>

							<button
								type="button"
                                [hidden]="visualizar"
								class="btn btn-primary btn-sm mr-1"
								*ngIf="mostrarBotaoAdicionarIndicador"
								(click)="adicionarIndicador()"
							>
								<i class="far fa-file"></i> Adicionar Indicador
							</button>

							<div
								*ngIf="
									mostrarBotaoQuestionario
								"
								class="btn-group btn-sm mr-1"
							>
								<button
									type="button"
									[hidden]="visualizar"
									class="btn btn-primary btn-sm"
								>
									<i class="far fa-folder-open"></i>
									Questionário
								</button>

								<button
									[hidden]="visualizar"
									type="button"
									class="
										btn btn-primary
										dropdown-toggle dropdown-icon
									"
									data-toggle="dropdown"
								>
									<span class="sr-only">Toggle Dropdown</span>
								</button>
								<div class="dropdown-menu" role="menu">
									<button
										[hidden]="visualizar"
										type="button"
										class="btn btn-default dropdown-item"
										(click)="
											exibirModalEnviarQuestionarioCriterio()
										"
									>
										Enviar Questionário
									</button>
									<button
										[hidden]="visualizar"
										type="button"
										class="
											btn btn-default
											text-center
											dropdown-item
										"
										(click)="
											exibirModalVisualizarQuestionarioCriterio()
										"
									>
										Visualizar
									</button>
									<button
										[hidden]="visualizar"
										type="button"
										class="
											btn btn-default
											text-center
											dropdown-item
										"
										(click)="confirmarCancelamento()"
									>
										Cancelar Envio
									</button>
								</div>
							</div>

                        <button
                        type="button"
                        class="btn btn-default btn-sm mr-1"
                        (click)="fechar()"
                    >
                        <i class="fas fa-times"></i> Fechar
                    </button>					
</div>