<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">Detalhes do Envio</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-2" style="min-height: auto !important;">
      <div class="row">
        <!-- <div class="form-group col-md-8">
          <label for="naturezaResponsabilidadeNome" class="mb-1"
            >Natureza da Responsabilidade</label
          >
          <div class="input-group input-group-sm" style="width: 100%">
            <input
              type="text"
              id="naturezaResponsabilidadeNome"
              class="form-control form-control-sm"
              readonly
              [ngModel]="model.naturezaResponsabilidadeNome"
            />
            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-info"
                (click)="exibirModalNaturezaResponsabilidade()"
              >
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div> -->

        <div class="form-group col-md-4">
          <label for="prazoDias" class="mb-1">Prazo em dias úteis</label>
          <input
          type="text"
          id="prazoDias"
          class="form-control form-control-sm"
          [ngModel]="model.prazoDias"
          [ngModelOptions]="{ standalone: true }"
          (change)="onChangePreencherPrazo($event)"
          type="number"
          min="1"
          />
        </div>
      </div>
    </div>

    <div class="card-footer p-2">
      <button
        type="button"
        class="btn btn-primary btn-sm mr-1"
        (click)="concluir()"
      >
        <i class="fas fa-check"></i> Enviar
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
</div>
