	<div class="card-header bg-light">
		<h4 class="card-title mb-0">Avaliação</h4>
	</div>
	<div class="card-body  wrapper">
    <form [formGroup]="form" #f="ngForm" id="avaliacao-solicitacao">
		<div class="row" *ngIf="model?.avaliacao === 1">
			<div class="col-12">
				<label class="form-label">Prazo *</label>
				<input
					type="text"
					class="form-control form-control-sm"
					placeholder="DD/MM/AAAA"
					bsDatepicker
					[bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
          formControlName="prazo"
          [ngClass]="{ 'is-invalid': submitted && form.controls['prazo'].invalid && model?.avaliacao === 1}"
				/>
        <div *ngIf="submitted && form.controls['prazo'].invalid && model?.avaliacao === 1" class="invalid-feedback">
          <div *ngIf="form.controls['prazo'].errors.required">Campo obrigatório</div>
        </div>
			</div>
		</div>

    <div class="row" *ngIf="model?.avaliacao === 0">
			<div class="col-12">
				<label class="form-label">Justificativa *</label>
				<input
					type="text"
					class="form-control form-control-sm"
					placeholder="Insira uma justificativa"
          formControlName="justificativa"
          [ngClass]="{ 'is-invalid': f.submitted && form.controls['justificativa'].invalid && model?.avaliacao === 0}"
				/>
        <div *ngIf="submitted && form.controls['justificativa'].invalid && model?.avaliacao === 0" class="invalid-feedback">
          <div *ngIf="form.controls['justificativa'].errors.required">Campo obrigatório</div>
        </div>
			</div>
		</div>
		<div class="row col-12 p-2">
			<div class="form-check mr-4">
				<input
					class="form-check-input"
					type="radio"
					id="avaliacao"
					[value]="1"
          name="opcao"
          [(ngModel)]="model.avaliacao"
          [ngModelOptions]="{standalone: true}"
				/>
				<label class="form-check-label" for="gridRadios1"
					>Aprovar
				</label>
			</div>
			<div class="form-check">
				<input
					class="form-check-input"
					type="radio"
					id="avaliacao"
					[value]="0"
          name="opcao"
          checked
          [(ngModel)]="model.avaliacao"
          [ngModelOptions]="{standalone: true}"
				/>
				<label class="form-check-label" for="gridRadios2"
					>Reprovar
				</label>
			</div>
		</div>
  </form>
	</div>

<div class="card-footer">
	<button type="submit" class="btn btn-success btn-sm mr-1" form="avaliacao-solicitacao" (click)="salvar()">
		<i class="fas fa-check"></i> Confirmar
	</button>

	<button type="button" class="btn btn-danger btn-sm" (click)="cancelar()">
		<i class="fas fa-times"></i> Cancelar
	</button>
</div>
