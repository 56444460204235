<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">Editar Parametrização</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body px-0">
      <div class="table-container p-2">

        <div class="row p-2">
          <div class="form-group col-md-12 mb-1">
            <label for=inputDescricao class="mb-1">Id</label>
            <input type="text" class="form-control form-control-sm" disabled [ngModel]="model.id" />
          </div>
        </div>
        <div class="row p-2">
          <div class="form-group col-md-12 mb-1">
            <label for=inputDescricao class="mb-1">Descrição</label>
            <textarea class="form-control form-control-sm" disabled [ngModel]="model.descricao"></textarea>
          </div>
        </div>
        <div class="row p-2">
          <div class="form-group col-md-12 mb-1">
            <label for=inputDescricao class="mb-1">Valor</label>

            <textarea *ngIf="model.tipoValor.nome === 'Texto'" id="valorTexto" rows="3"
              class="form-control form-control-sm" [(ngModel)]="model.valor"></textarea>

            <input *ngIf="model.tipoValor.nome === 'Inteiro'" type=" text" id="valorInteiro"
              class="form-control form-control-sm" data-inputmask="'alias': 'integer'" inputmode="numeric"
              [(ngModel)]="model.valor" />

            <input *ngIf="model.tipoValor.nome === 'Decimal'" type=" text" id="valorDecimal"
              class="form-control form-control-sm" data-inputmask="'alias': 'numeric', 'radixPoint': ',',
                    'digits': 2, 'digitsOptional': false, 'placeholder': '0', 'groupSeparator': '.'"
              [(ngModel)]="model.valor" />

            <select *ngIf="model.tipoValor.nome === 'SimNao'" id="selectSimNao" [(ngModel)]="model.valor"
              [ngModelOptions]="{standalone: true}" class="form-control form-control-sm">
              <option [ngValue]="'true'"> Sim</option>
              <option [ngValue]="'false'"> Não</option>
            </select>

            <select *ngIf="model.tipoValor.nome === 'Lista'" id="selectLista" [(ngModel)]="model.valor"
              [ngModelOptions]="{standalone: true}" class="form-control form-control-sm">
              <option *ngFor="let item of model.opcoesValorLista.split(';')" [value]="item">{{item}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
        <i class="fas fa-check"></i> Salvar
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
</div>
