import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IEnumModel } from 'src/app/models/enum.model';
import { IQuestionarioUGModel, IVisualizarQuestionarioRespostaUGModel, QuestionarioUGItensResposta } from 'src/app/models/questionario-ug.model';
import { AuthService } from 'src/app/services/auth.service';
import { EntendendoEntidadeService } from 'src/app/services/entendendo-entidade.service';
import { PerguntaService } from 'src/app/services/pergunta.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { QuestionarioUGRespostaService } from 'src/app/services/questionarioug-resposta.service';
import { MatTableDataSource } from '@angular/material/table';
import { IListaQuestionarioRespostaUGModel } from 'src/app/models/questionario-ug.model';

@Component({
  selector: 'app-imprimir-entendendo-entidade',
  templateUrl: './imprimir-entendendo-entidade.component.html',
  styleUrls: ['./imprimir-entendendo-entidade.component.scss']
})
export class ImprimirEntendendoEntidadeComponent extends BaseFormComponent implements OnInit {
  public tiles = [];
  public idUnidadeGestora : number;
  public versao : number;
  public dataFormatada : string; 
  public nomeUnidadeGestora : string;
  public dataSource = new MatTableDataSource<QuestionarioUGItensResposta>([]);
  displayedColumns: string[] = ['pergunta'];
  public model= {} as IListaQuestionarioRespostaUGModel;


  constructor(
    private questionarioUGService: EntendendoEntidadeService,
    toastr: ToastrService,
    route: ActivatedRoute,
    router: Router,
    private authService: AuthService,
    localeService: BsLocaleService,
    private perguntaService: PerguntaService,
    private tabelaDominioService: TabelaDominioService,
    private questionarioService: QuestionarioUGRespostaService,
    public matDialog: MatDialog,
    public matGridListModule: MatGridListModule
  ) { 
    super(route, toastr, router, localeService, matDialog);
  }

  async ngOnInit() {

    if(!this.novoRegistro){
      this.questionarioService.ObterRespostaQuestionario(this.id)
      .then((res) => {
        this.model = res.dados;
        this.dataSource = new MatTableDataSource<QuestionarioUGItensResposta>(res.dados.respostas);
      });
    }
  
      if(!this.novoRegistro){
        this.questionarioUGService
        .ImprimirRespostaQuestionario(this.id)
        .then((res) => {
            if(res.sucesso){
                this.idUnidadeGestora = res.dados[0].idUnidadeGestora;
                this.dataFormatada = res.dados[0].dataFormatada;
                this.versao = res.dados[0].versao;
                this.nomeUnidadeGestora = res.dados[0].nomeUnidadeGestora;
            }else{
                this.exibirMensagemErro(res.mensagem.descricao);
            }
        })
         }   
  }

  print(): void {
    window.print();
  }
  public onBack() {
    this.submit = false;
    this.router.navigateByUrl(`/unidades-gestoras/${this.idUnidadeGestora}?aba=1`);
  }

}
