import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { IRecomendacaoModel } from 'src/app/models/recomendacao.model';
import { RecomendacaoService } from 'src/app/services/recomendacao.service';
import { BaseListComponent } from '../../shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { ConstatacaoService } from 'src/app/services/constatacao.service';

const sortingDataAccessor = (item, property) => {
    switch (property) {
      case 'marco':
        if (item.tipoRecomendacao === 1) {
          return 'Interna';
        } else if (item.tipoRecomendacao === 2) {
          return 'Externa';
        } 
        case 'nome': return item.nome.trim().toLowerCase();      
      default:
        return item[property];
    }
  };
@Component({
    selector: 'app-recomendacao-list',
    templateUrl: './recomendacao-list.component.html',
    styleUrls: [ './recomendacao-list.component.scss' ],
})
export class RecomendacaoListComponent extends BaseListComponent {
    public dataSource = new MatTableDataSource<IRecomendacaoModel>([]);
    public selectedModel: IRecomendacaoModel;
    public displayedColumns: string[] = [ 'selecao', 'nome', 'marco', 'ativo' ];
    public searchNome: string;
    public searchAtivo = true;
    public semDados = true;

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;
    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    constructor(
        private recomendacaoService: RecomendacaoService,
        private constatacaoService: ConstatacaoService,
        private router: Router,
        private authService: AuthService
    ) {
        super();
        this.buscar();
    }

    ngOnInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = sortingDataAccessor;
        this.setarPermissoes();
    }

    private setarPermissoes(): void {
        this.permissoes = new IPermissaoModel();
        this.permissoes.consultar = this.authService.possuiPermissao(
            EnumRecursoPerfil.Cadastros,
            EnumAcaoRecurso.Consultar
        );
        this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
        this.permissoes.excluir = this.authService.possuiPermissao(
            EnumRecursoPerfil.Cadastros,
            EnumAcaoRecurso.Excluir
        );
        this.permissoes.inativarReativar = this.authService.possuiPermissao(
            EnumRecursoPerfil.Cadastros,
            EnumAcaoRecurso.AtivarInativar
        );
        this.permissoes.incluir = this.authService.possuiPermissao(
            EnumRecursoPerfil.Cadastros,
            EnumAcaoRecurso.Inserir
        );
        this.permissoes.visualizar = this.authService.possuiPermissao(
            EnumRecursoPerfil.Cadastros,
            EnumAcaoRecurso.Visualizar
        );
    }

    public novo() {
        this.router.navigate([ '/recomendacao/novo' ]);
    }

    public buscar() {
        this.selectedModel = null;

        this.recomendacaoService
            .obter(this.searchNome, this.searchAtivo)
            .then((res) => {
                this.dataSource = new MatTableDataSource<IRecomendacaoModel>(res.dados);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.dataSource.sortingDataAccessor = sortingDataAccessor;
                this.dataSource._updateChangeSubscription();
                this.semDados = this.dataSource.filteredData.length === 0;
            })
            .catch((err) => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    text: err.mensagem.descricao,
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
            });
    }

    public reativar(id: number) {
        this.recomendacaoService
            .reativar(id)
            .then((res) => {
                if (res.sucesso) {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'success',
                        text: 'Registro reativado com sucesso!',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                } else {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'warning',
                        text: res.mensagem.descricao,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }
            })
            .catch((err) => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    text: err.mensagem.descricao,
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
            })
            .finally(() => {
                this.buscar();
            });
    }

    public excluir(id: number) {
        this.recomendacaoService
            .excluir(id)
            .then((res) => {
                if (res.sucesso) {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'success',
                        text: 'Registro excluído com sucesso!',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                } else {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'warning',
                        text: res.mensagem.descricao,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }
            })
            .catch((err) => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    text: err.mensagem.descricao,
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
            })
            .finally(() => {
                this.buscar();
            });
    }

    public inativar(id: number) {
        this.constatacaoService
            .obterPorRecomendacaoId(id)
            .then((res) => {
                if (res.dados == null) {
                    this.recomendacaoService
                        .inativar(id)
                        .then((res) => {
                            if (res.sucesso) {
                                Swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    icon: 'success',
                                    text: 'Registro inativado com sucesso!',
                                    showConfirmButton: false,
                                    timer: 5000,
                                    timerProgressBar: true,
                                });
                            } else {
                                Swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    icon: 'warning',
                                    text: res.mensagem.descricao,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    timerProgressBar: true,
                                });
                            }
                        })
                        .catch((err) => {
                            Swal.fire({
                                toast: true,
                                position: 'top-end',
                                icon: 'error',
                                text: err.mensagem.descricao,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        })
                        .finally(() => {
                            this.buscar();
                        });
                } else {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'warning',
                        text: 'Recomendação vinculada a uma constatação',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }
            })
            .catch((err) => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    text: err.mensagem.descricao,
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
            })
            .finally(() => {
                this.buscar();
            });
    }
    public visualizar(id: number) {
        this.router.navigate([ `/recomendacao/${id}/visualizar` ]);
    }

    public editar(id: number) {
        this.router.navigate([ `/recomendacao/${id}` ]);
    }

    public selecionar(item: any) {
        this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
    }
}
