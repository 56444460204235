<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i>
							Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item">
						<a [routerLink]="['/unidades-gestoras']"
							>Unidades Gestoras</a
						>
					</li>
					<li class="breadcrumb-item active">{{ titulo }}</li>
				</ol>
			</div>
		</div>
	</div>
</div>
<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<div class="component data component-card">
				<mat-card class="mat-card">
					<mat-table [dataSource]="dataSource" class="table">
						<ng-container class="container" matColumnDef="pergunta">
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header">Seção/Pergunta</span>
							</mat-header-cell>
							<mat-cell
								class="cell"
								*matCellDef="let element"
								
							>
								<span>
                  {{ element.pergunta }}
                </span>
                <br>
                <span class="resposta-pergunta" *ngIf="element.tipoEntrada"
                  [innerHtml]="element.resposta">
                 
                </span>
							</mat-cell>
						</ng-container>

						<ng-container
							class="container"
							matColumnDef="tipoAvaliacaoAuditor"
						>
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header"
									>Avaliação Auditor</span
								>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta">
                  {{element.tipoAvaliacaoAuditor	}}
                </span>
              </mat-cell>
						</ng-container>

            <ng-container
							class="container"
							matColumnDef="avaliacaoAuditor"
						>
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header"
									>Resposta Revisada</span
								>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta" [innerHtml]="element.avaliacaoAuditor"></span>
                
              </mat-cell>
						</ng-container>

						<ng-container class="container" matColumnDef="tipoReplica">
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header">Réplica</span>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta">
                  {{element.tipoReplica}}
                </span>
            </mat-cell>
						</ng-container>

            <ng-container class="container" matColumnDef="replica">
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header">Resposta Revisada da Réplica</span>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta" [innerHtml]="element.replica"></span>
            </mat-cell>
						</ng-container>

						<!-- <ng-container class="container" matColumnDef="status">
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header">Status</span>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta">
                  {{element.status}}
                </span> -->
                
            <!-- </mat-cell>
						</ng-container> -->

						<ng-container
							class="container"
							matColumnDef="tipoAvaliacaoFinal"
						>
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header">Avaliação Final</span>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta">
                  {{element.tipoAvaliacaoFinal}}
                
                </span>
            </mat-cell>
						</ng-container>

						<ng-container
							class="container"
							matColumnDef="avaliacaoFinal"
						>
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header"
									> Resposta Final</span
								>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta"
                  [innerHtml]="element.avaliacaoFinal"></span>
            </mat-cell>
						</ng-container>

						<mat-header-row
							class="header-row"
							*matHeaderRowDef="displayedColumns; sticky: true"
						></mat-header-row>
						<mat-row
							class="row"
							*matRowDef="let row; columns: displayedColumns"
              [ngClass]="{
    'tipo-secao': row.tipoEntrada === '',
    'tipo-pergunta': row.tipoEntrada !== ''
  }"
						>
						</mat-row>
					</mat-table>
				</mat-card>
			</div>

			<!-- <div class="mat-elevation-z1">
        <table
        mat-table
        [dataSource]="dataSource"
        class="table"
        matSort
        #tabela1="matSort"
        matSortDisableClear
        [hidden]="semDados"
      >

        
        <ng-container matColumnDef="pergunta">
          <th
            colspan="4"
            mat-header-cell
            class="text-left"
            *matHeaderCellDef
          >
            Seção/Pergunta
          </th>
          <td colspan="4" mat-cell *matCellDef="let element" class="text-left">
            {{ element?.pergunta }}
          </td>
        </ng-container>

        <ng-container matColumnDef="avaliacaoAuditor">
          <th
            colspan="4"
            mat-header-cell
            *matHeaderCellDef
            class="text-left"
          >
            Avaliação Auditor
          </th>
          <td colspan="4" mat-cell *matCellDef="let element" class="text-left">
            {{ element?.avaliacaoAuditor }}
          </td>
        </ng-container>

        <ng-container matColumnDef="resposta">
          <th
            colspan="4"
            mat-header-cell
            *matHeaderCellDef
            class="text-left"
          >
            Resposta Revisada
          </th>
          <td colspan="4" mat-cell *matCellDef="let element" class="text-left">
            {{ element?.resposta }}
          </td>
        </ng-container>

        <ng-container matColumnDef="replica">
          <th
            colspan="4"
            mat-header-cell
            *matHeaderCellDef
            class="text-center"
          >
            Réplica
          </th>
          <td colspan="4" mat-cell *matCellDef="let element" class="text-center">
            {{ element?.replica }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th
            colspan="4"
            mat-header-cell
            *matHeaderCellDef
            class="text-center"
          >
            Resposta réplica
          </th>
          <td colspan="4" mat-cell *matCellDef="let element" class="text-center">
            {{ element?.resposta }}
          </td>
        </ng-container>
        <ng-container matColumnDef="avaliacaoFinal">
          <th
            colspan="4"
            mat-header-cell
            *matHeaderCellDef
            class="text-center"
          >
            Avaliação Final
          </th>
          <td colspan="4" mat-cell *matCellDef="let element" class="text-center">
            {{ element?.avaliacaoFinal }}
          </td>
        </ng-container>
        <ng-container matColumnDef="avaliacaoFinalResposta">
          <th
            colspan="4"
            mat-header-cell
            *matHeaderCellDef
            class="text-center"
          >
            Resposta Final
          </th>
          <td colspan="4" mat-cell *matCellDef="let element" class="text-center">
            {{ element?.avaliacaoFinal }}
          </td>
        </ng-container> 

         <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              type="button"
              class="btn btn-info btn-xs"
              (click)="exibirModalDetalharValorRisco(element)"
            >
              <i class="fas fa-eye"></i>
            </button>
          </td>
        </ng-container> 

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      
      </div> -->
		</div>
    
	</div>
  <div class="row">
    <div class="col-md-12">
      <button
      [hidden]="visualizar"
      type="button"
      class="btn btn-default btn-sm"
      (click)="onBack()"
    >
      <i class="fas fa-times"></i> Fechar
    </button>
    </div>
  </div>

	
</div>
