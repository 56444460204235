import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { IRecomendacaoModel } from 'src/app/models/recomendacao.model';
import { RecomendacaoService } from 'src/app/services/recomendacao.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recomendacao-form',
  templateUrl: './recomendacao-form.component.html',
  styleUrls: ['./recomendacao-form.component.scss']
})
export class RecomendacaoFormComponent extends BaseFormComponent implements OnInit {

  public model: IRecomendacaoModel = {} as IRecomendacaoModel;
  public semDados = true;

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
    tipoRecomendacao: new FormControl(null, Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private recomendacaoService: RecomendacaoService,
    public matDialog: MatDialog,
  ) {
    super(route, toastr, router, localeService, matDialog);
  }

  config: AngularEditorConfig = {
    editable: !this.visualizar,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async ngOnInit() {
    this.submit = true;
    try {
      if (!this.novoRegistro) {
        const res = await this.recomendacaoService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.router.navigate(['/recomendacao']);
          return;
        }
      }

      this.form.updateValueAndValidity();
      this.form.patchValue(this.model);
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.router.navigate(['/recomendacao']);
    }
    this.atribuirTitulo();
  }

  public async onSubmit(salvarEFechar: boolean = false) {
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }

    Object.assign(this.model, this.form.value);

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        res = await this.recomendacaoService.atualizar(this.model);
      } else {
        res = await this.recomendacaoService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Registro salvo com sucesso!',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        if (salvarEFechar) {
          this.router.navigate(['/recomendacao']);
        }else
        if(this.novoRegistro){
          this.router.navigate([`/recomendacao/${res.dados.id}`]);
          await this.ngOnInit();
        }
      } else {
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(validacoes);
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }


  public onBack() {
    this.submit = false;
    this.router.navigate(['/recomendacao']);
  }

  atribuirTitulo(){
    if (this.novoRegistro) {
      this.titulo = 'Nova Recomendação';
    } else {
      this.titulo = 'Editar Recomendação - ' + this.model.nome;
    }
    if (this.visualizar) {
      this.titulo = 'Visualizar Recomendação - ' + this.model.nome;
    }
  }

}
