<div id="modal-content-a-equipe">
    <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <div class="card-header bg-light" cdkDragHandle>
            <h4 class="card-title mb-0">Tipo de trabalho</h4>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="cancelar()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-container p-3">

                <div class="row">
                    <div class="form-group col-md-8 was-validated"
                        [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                        <label for="selectTipoTrabalho" class="mb-1">Tipo de trabalho</label>
                        <select
							id="selectTipoTrabalho"
							#selectTipoTrabalho
							[(ngModel)]="model.tipoTrabalhoId"
							class="form-control form-control-sm"
                            required
						>
							<option
								*ngFor="let item of tabelaTipoTrabalho"
								[ngValue]="item.id"
							>
								{{ item.nome }}
							</option>
						</select>
                        <div class="invalid-feedback">
                            Campo obrigatório
                        </div>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="mb-1">Ano</label>
                        <input [attr.disabled]="true"
                        type="text"
                        id="inputAno" [(ngModel)]="model.ano"/>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6 was-validated"
                        [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                        <label for="inputQtdMediaDias" class="mb-1">Qtd Média dias</label>
                        <input 
                        required
                        class="form-control form-control-sm"
                        type="number" 
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="1"
                        id="inputQtdMediaDias" [(ngModel)]="model.qtdMediaDias"/>
                        <div class="invalid-feedback">
                            Campo obrigatório
                        </div>
                    </div>
                    <div class="form-group col-md-6 was-validated"
                        [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                        <label for="inputQtdMembros" class="mb-1">Qtd Membros</label>
                        <input 
                        required
                        class="form-control form-control-sm"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="1"
                        id="inputQtdMembros" [(ngModel)]="model.qtdMembros"/>
                        <div class="invalid-feedback">
                            Campo obrigatório
                        </div>
                    </div>
                </div>

            </div>
            <div class="card-footer">
                <button  type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
                    <i class="fas fa-check"></i> {{txtBtnConcluir}}
                </button>

                <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
                    <i class="fas fa-times"></i> Cancelar
                </button>
            </div>
        </div>
    </div>
</div>