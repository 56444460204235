import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { IEnviarQuestionarioModel } from 'src/app/models/enviar-questionario.model';
import { NaturezaResponsabilidadeService } from 'src/app/services/natureza-responsabilidade.service';
import { ParametrizacaoService } from 'src/app/services/parametrizacao.service';
import { LookupModalComponent } from 'src/app/shared/components/lookup-modal/lookup-modal.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-enviar-questionario-criterio',
  templateUrl: './modal-enviar-questionario-criterio.component.html',
  styleUrls: ['./modal-enviar-questionario-criterio.component.scss']
})
export class ModalEnviarQuestionarioCriterioPaaComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public model: IEnviarQuestionarioModel = {} as IEnviarQuestionarioModel;

  constructor(
    private dialogRef: MatDialogRef<ModalEnviarQuestionarioCriterioPaaComponent>,
    private naturezaResponsabilidadeService: NaturezaResponsabilidadeService,
    private parametrizacaoService: ParametrizacaoService,
    public matDialog: MatDialog) {
  }

  ngOnInit(): void {
    this.parametrizacaoService.obterPorId('pea-prazo-padrao')
    .then(res => {
      this.model.prazoDias = res.dados.valor;
    });
  }

  public concluir() {
    if (Number(this.model.prazoDias) <= 0){
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: 'O prazo não pode ser menor que 1',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    return;
    } 
    this.dialogRef.close(this.model);
  }

  public cancelar() {
    this.dialogRef.close();
  }
  
  public async exibirModalNaturezaResponsabilidade() {
    try {
      const res = await this.naturezaResponsabilidadeService.obter('', true);
      if (res.sucesso) {
        const modal = this.exibirModal('Selecionar Natureza da Responsabilidade', res.dados);
        modal.afterClosed().subscribe((data) => {
          if (data) {
            this.model.naturezaResponsabilidadeId = data.id;
            this.model.naturezaResponsabilidadeNome = data.nome;
          }
        });
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }
  
  public exibirModal(titulo: string, dados: any, exibirId = false, customizeColumns = null): MatDialogRef<any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component-natureza';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = { titulo, dados, exibirId, customizeColumns };
    return this.matDialog.open(LookupModalComponent, dialogConfig);
  }

  public onChangePreencherPrazo(event){
    this.model.prazoDias = event.target.value;
  }

}

