import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { IMenuItemModel } from 'src/app/models/menu.model';

@Component({
  selector: '[app-menuitem]',
  templateUrl: './menuitem.component.html',
  styleUrls: ['./menuitem.component.scss'],
})
export class MenuItemComponent implements OnInit, AfterViewInit{
  @Input() menuItems: IMenuItemModel[];
  @Input() menuName: string = "";

  constructor() {  }

  async ngOnInit() {        
    if (this.menuName === 'Cadastros/Geral') {
      this.menuItems.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))
    }
  }
  async ngAfterViewInit() {
  }
}
