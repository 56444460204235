<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">Associar Unidades Gestoras</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">

      <div class="table-container p-3">

        <div class="row">
          <div class="form-group col-md-12">
            <label for="selectUnidade" class="mb-1">Unidade Gestora</label>
            <div class="input-group input-group-sm" style="width: 100%;">
              <select id="selectUnidade" [(ngModel)]="idSelecionado" [ngModelOptions]="{standalone: true}"
                class="form-control form-control-sm">
                <option *ngFor="let item of unidadesGestorasDisponiveis" [ngValue]="item.id">
                  {{item.sigla}} - {{item.nome}}</option>
              </select>

              <div class="input-group-append">
                <button type="button" class="btn btn-default ml-1" (click)="adicionar()" [disabled]="!idSelecionado">
                  <i class="fas fa-plus"></i> Adicionar
                </button>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <table mat-table [dataSource]="dataSource" matSort matSortDisableClear [hidden]="semDados">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="sigla">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Sigla </th>
              <td mat-cell *matCellDef="let element"> {{element.sigla}} </td>
            </ng-container>

            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
              <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
            </ng-container>

            <ng-container cdkColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element; let i = index" class="block">
                <div class="btn-group">
                  <button type="button" class="btn btn-danger btn-xs text-left" (click)="remover(i)">
                    <i class="far fa-trash-alt"></i> </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          </table>
          <h5 [hidden]="!semDados" class="table-no-data">Nenhuma Unidade Gestora selecionada</h5>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()"
        [disabled]="dataSource.data.length < 2">
        <i class="fas fa-check"></i> Concluir
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
</div>
