import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as cloneDeep from 'lodash/cloneDeep';
import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';

@Component({
  selector: 'app-modal-selecionar-ug',
  templateUrl: './modal-selecionar-ug.component.html',
  styleUrls: ['./modal-selecionar-ug.component.scss']
})
export class ModalSelecionarUGComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  public todasUnidadesGestoras = [] as IUnidadeGestoraModel[];
  public semDados = true;
  public displayedColumns = ['selecao', 'sigla', 'nome'];
  public searchNome: string;
  public selectedModel: any;
  public noItemSelected: boolean = true;
  public modoSelecaoMultiplo: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<ModalSelecionarUGComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.todasUnidadesGestoras = data.dados;
    this.modoSelecaoMultiplo = data.modoSelecaoMultiplo ?? false;
    this.dataSource.data = this.todasUnidadesGestoras;
    this.dataSource._updateChangeSubscription();
  }

  ngOnInit(): void {
    this.sort.sort(({ id: 'nome', start: 'asc'}) as MatSortable);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public buscar() {
    this.selectedModel = null;
    this.dataSource.data = this.todasUnidadesGestoras.filter(a =>
        (a.nome.toLowerCase().includes(this.searchNome.trim().toLowerCase())) ||
        (a.sigla.toLowerCase().includes(this.searchNome.trim().toLowerCase()))
    );
    this.dataSource._updateChangeSubscription();
    this.semDados = false;
  }

  public concluir() {

    if(this.modoSelecaoMultiplo){
      let selectedUgs = this.dataSource.data.filter(s => s.selecao == true);
      this.dialogRef.close(selectedUgs);
    }else{
      this.dialogRef.close(this.selectedModel);
    }
    
  }

  public selecionar(item: any) {
    
    if(this.modoSelecaoMultiplo){
      item.selecao = !item.selecao;
      this.dataSource._updateChangeSubscription();
      
      let selecionados = this.dataSource.data.filter(s => s.selecao == true);
      this.noItemSelected = selecionados.length == 0;
    }else{
      this.selectedModel = !this.selectedModel || this.selectedModel !== item ? item : null;
      this.noItemSelected = !this.selectedModel;
    }
  }

  public itemAgrupador(index: number, item: any): boolean {
    return item.grupo;
  }

  public cancelar() {
    this.dialogRef.close();
  }

  
}
