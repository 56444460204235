<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light" >
      <h4 class="card-title mb-0">Visualização de procedimento auditoria</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">

        <div class="form-group col-md-12">
          <label for="nome" class="mb-1">Procedimento</label>
          <input type="text" id="nome" [readonly]="visualizacao" class="form-control form-control-sm"
           maxlength="100" [(ngModel)]="model.nome" />

        </div>

      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="fonteInformacao" class="mb-1">Fonte de Informação</label>
          <input type="text" id="fonteInformacao" [readonly]="visualizacao" class="form-control form-control-sm"
            maxlength="100" [(ngModel)]="model.fonteInformacao" />

        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="detalhamentoProcedimento" class="mb-1">Detalhamento do Procedimento</label>
          <angular-editor [config]="config" id="detalhamentoProcedimento" rows="20"
            [(ngModel)]="model.detalhamentoProcedimento">
          </angular-editor>

        </div>
      </div>

      <div class="row">
        <div class="col-md-12" [ngClass]="{'form-group': visualizacao}">
          <label for="inputConstatacao" class="mb-1" [hidden]="!visualizacao">Técnica de auditoria</label>
          <div class="mat-elevation-z1">
            <table mat-table [dataSource]="tecnicaAuditoriaDataSource">
              <tr mat-header-row *matHeaderRowDef="['nome']" class="bg-light"></tr>

              <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef>Nome </th>
                <td mat-cell *matCellDef="let element"> {{ element.tecnicaAuditoria.nome }}</td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: ['nome'];"></tr>
            </table>
            <h5 [hidden]="!semDados" class="table-no-data">Nenhuma técnica de auditoria cadastrada</h5>
          </div>
        </div>
      </div>

    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Fechar
      </button>
    </div>
  </div>
</div>
