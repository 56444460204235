<div class="p-4 content">
  <div class="card card-outline">
    <div class="card-header d-flex pb-0">
      <h3>
        {{tituloImpressao}}
      </h3>
    </div>

    <div class="card-body">
      <div [class.minuta]="statusPlanejamento!==4"></div>
      <div class="row p-2 border border-dark d-block">
        <strong>Nome:</strong>
        <p>{{model?.nome}}</p>
      </div>
      <div class="row p-2 border border-top-0 border-dark d-block">
        <strong>Descrição:</strong>
        <p>{{model?.descricao}}</p>
      </div>
      <div class="row p-2 border border-top-0 border-dark d-block">
        <strong>Unidade Gestora associada:</strong>
        <p *ngFor="let item of model.amostraUnidadeGestoras">{{ item?.unidadeGestora.nome }}</p>
      </div>
      <div class="row p-2 border border-top-0 border-dark d-block">

        <strong>Arquivos:</strong>
        <p *ngFor="let item of model.amostraArquivos">{{ item?.nome }}</p>
      </div>
    </div>


    <div class="card-footer doNotPrint">
      <button type="button" class="btn btn-default btn-sm mr-1" (click)="cancelar()">
        <i class="fas fa-times"></i> Fechar
      </button>
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="print()">
        <i class="fas fa-print"></i> Imprimir
      </button>
    </div>
  </div>
</div>