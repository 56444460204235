import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IRolResponsaveisModel } from 'src/app/models/rol-responsaveis.model';
import { IPeriodoExercicioModel } from 'src/app/models/periodo-exercicio.model';
import { RolResponsaveisService } from 'src/app/services/rol-responsaveis.service';
import Swal from 'sweetalert2';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { ModalIncluirResponsavelComponent } from './modal-incluir-responsaveis/modal-incluir-responsavel.component';
import { IResponsavelModel } from 'src/app/models/responsaveis.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ResponsaveisService } from 'src/app/services/responsaveis.service ';
import { PeriodoExercicioService } from 'src/app/services/periodo-exercicio.service';
import { IListaResponsavelModel } from 'src/app/models/lista-responsaveis.model';

const sortingDataAccessor = (item, property) => {
  switch (property) {
     case 'naturezaResponsabilidade':
      return item.nomeNaturezaResponsabilidadeId;
      case 'tipoAgente':
        return item.nomeTipoAgente; 
        case 'Situação':
          return item.ativo; 
  
        
    default:
      return item[property];
  }
};
@Component({
  selector: 'app-rol-responsaveis-form',
  templateUrl: './rol-responsaveis-form.component.html',
  styleUrls: ['./rol-responsaveis-form.component.scss'],
})
export class RolResponsaveisFormComponent extends BaseFormComponent implements OnInit {
  public model: IRolResponsaveisModel = {} as IRolResponsaveisModel;

  public selectedModel: IRolResponsaveisModel;
  public displayedColumns = [
    'nome', 'naturezaResponsabilidade', 'tipoAgente', 'dataInicio', 'dataFim', 'ativo', 'actions'
  ];
  public dataSource = new MatTableDataSource<IListaResponsavelModel>([]);
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;
  public dataInicio: Date;
  public dataFim: Date;
  public NaturezaResponsabilidade: string;
  public TipoAgente: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private responsaveisService: ResponsaveisService,
    private rolResponsaveisService: RolResponsaveisService,
    private periodoExercicioService: PeriodoExercicioService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);


    if (this.novoRegistro) {
      this.titulo = 'Rol de Responsáveis';
    } 
  }

  async ngOnInit() {

    try {
      if (!this.novoRegistro) {
        const res = await this.rolResponsaveisService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;
          if (this.visualizar) {
            this.titulo = 'Visualizar Rol de Responsáveis - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Rol de Responsáveis - ' + this.model.nome;
          }

          this.buscar();
        } else {
          this.toastr.error(res.mensagem.descricao, 'Atenção');
          this.router.navigate(['/rolresponsaveis']);
          return;
        }
      }

    } catch (err) {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
      this.router.navigate(['/rolresponsaveis']);
    } 
  }

  public async buscar() {

    if (this.dataInicio  && this.dataFim && this.dataInicio.getTime() > this.dataFim.getTime()) {
      this.exibirMensagemAlerta('Data inicial não pode ser maior que a data final');
      return;
    }

    this.responsaveisService
      .obterListaResponsaveis(this.id, this.searchNome,this.NaturezaResponsabilidade, this.TipoAgente, this.searchAtivo, this.dataInicio, this.dataFim, true)
      .then((res) => {
        this.dataSource = new MatTableDataSource<IListaResponsavelModel>(res.dados);
        this.dataSource.sortingDataAccessor = sortingDataAccessor;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.exibirMensagemAlerta(err.mensagem.descricao);
      })
  }

  public async modalIncluirResponsavel(editar?: boolean, element?: IResponsavelModel, visualizar?: boolean) {
    try {
      
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component-incluir-responsavel';
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        element,
        editar,
        visualizar,
        unidadeGestoraId: this.id
      };
      const modal = this.matDialog.open(ModalIncluirResponsavelComponent, dialogConfig);
      modal.afterClosed().subscribe((sucesso) => {
        if (sucesso) {
          this.buscar();
        }
      });
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    } finally {
      this.buscar();
    }
  }

  public async inativarReativarResponsaveis(id: number, acao: boolean) {
    let res;
    if (acao) {
      Swal.fire({
        title: 'Reativar Registro',
        text: 'Tem certeza que deseja reativar esse Registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (confirm) => {
        if(confirm.value) {
          res = await this.periodoExercicioService.reativar(id);
        }if (res.sucesso) {
          this.buscar();
        }else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
    }
    else {
      Swal.fire({
        title: 'Inativar Registro',
        text: 'Tem certeza que deseja inativar esse Registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (confirm) => {
        if(confirm.value) {
          res = await this.periodoExercicioService.inativar(id);
        }
        if (res.sucesso) {
          this.buscar();
        }else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      });
    }
  }

  public excluirResponsaveis(id: number) {
    Swal.fire({
      title: 'Excluir Registro',
      text: 'Tem certeza que deseja excluir esse Registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((res) => {
      if(res.value) {
        this.periodoExercicioService.excluir(id)
        .then((res) => {
          if (res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
            this.buscar();
          }
          else {
            this.exibirMensagemAlerta(res.mensagem.descricao);
          }
        });
      }
    })
    
  }

  public async imprimir() {
    this.router.navigate([`/rolresponsaveis/relatorio/unidades-gestoras`, { ids: this.id }]);
  }

  public onBack() {
    this.submit = false;
    this.router.navigate(['/rolresponsaveis']);
  }

  public cancelar() {
    this.submit = false;
    this.router.navigate(['/rolresponsaveis']);
  }
}
