import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { TipoEnfaseFormComponent } from 'src/app/pages/tipo-enfase-form/tipo-enfase-form.component';

@Injectable()
export class TipoEnfaseGuard implements CanDeactivate<TipoEnfaseFormComponent> {
  canDeactivate(component: TipoEnfaseFormComponent): Observable<boolean> | boolean {
    if (component.novoRegistro) {
      if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    } else { return true; }
  }
}
