import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Inputmask from 'inputmask';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { toIntlNumberString } from 'src/app/core/helpers/number.helper';
import { ICriterioMetricaModel } from 'src/app/models/criterio-metrica.model';
import { IEnumModel } from 'src/app/models/enum.model';

@Component({
  selector: 'app-modal-metrica-numerico',
  templateUrl: './modal-metrica-numerico.component.html',
  styleUrls: ['./modal-metrica-numerico.component.scss']
})
export class ModalMetricaNumericoComponent implements AfterViewInit {
  public tipoEntrada: string;
  public enumTipoComparacao = [] as IEnumModel[];
  public element = {} as ICriterioMetricaModel;

  constructor(private dialogRef: MatDialogRef<ModalMetricaNumericoComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.element = data.element;
    this.tipoEntrada = data.tipoEntrada;
    this.enumTipoComparacao = data.enumTipoComparacao;

    this.formMetrica = new FormGroup({
      operacao1Id: new FormControl(this.element?.operacao1Id, Validators.required),
      valorNumerico1: new FormControl(this.element?.valorNumerico1, Validators.required),
      operacao2Id: new FormControl(this.element?.operacao2Id),
      valorNumerico2: new FormControl(this.element?.valorNumerico2),
      nota: new FormControl(this.element?.nota, Validators.required),
    }, { validators: [this.ValidateRequiredOperacao2.bind(this)] });
  }

  public formMetrica: FormGroup;

  ngAfterViewInit() {
    Inputmask().mask(document.querySelectorAll('input'));
  }

  public concluir() {
    const operacao2Id = this.formMetrica.controls['operacao2Id'];

    const valorNumerico1 = parseFloat(toIntlNumberString(this.formMetrica.controls['valorNumerico1'].value.toString()));
    const valorNumerico2 = this.formMetrica.controls['valorNumerico2'].value
      ? parseFloat(toIntlNumberString(this.formMetrica.controls['valorNumerico2'].value.toString()))
      : null;

    operacao2Id.setErrors((!operacao2Id.value && this.formMetrica.controls['valorNumerico2'].value) ? { required: true } : null);
    this.formMetrica.controls['valorNumerico2'].setErrors((operacao2Id.value && !this.formMetrica.controls['valorNumerico2'].value) ? { required: true } : null);

    this.formMetrica.markAllAsTouched();

    if (!this.formMetrica.valid) {
      return;
    }

    const nota = parseFloat(toIntlNumberString(this.formMetrica.controls['nota'].value.toString()));

    const modelMetrica = {
      operacao1Id: parseInt(this.formMetrica.controls['operacao1Id'].value, null),
      operacao1: this.enumTipoComparacao.find(t => t.codigo === parseInt(this.formMetrica.controls['operacao1Id'].value, null)),
      operacao2Id: parseInt(this.formMetrica.controls['operacao2Id'].value, null),
      operacao2: this.enumTipoComparacao.find(t => t.codigo === parseInt(this.formMetrica.controls['operacao2Id'].value, null)),
      valorNumerico1,
      valorNumerico2,
      nota,
    } as ICriterioMetricaModel;

    this.dialogRef.close(modelMetrica);
  }

  public cancelar() {
    this.dialogRef.close(null);
  }

  private ValidateRequiredOperacao2() {
    return (formGroup: FormGroup) => {
      const operacao2Id = formGroup.controls['operacao2Id'];
      const valorNumerico2 = formGroup.controls['valorNumerico2'];

      if (operacao2Id.value && !valorNumerico2.value) {
        operacao2Id.setErrors(null);
        valorNumerico2.setErrors({ required: true });
      } else if (!operacao2Id.value && valorNumerico2.value) {
        operacao2Id.setErrors({ required: true });
        valorNumerico2.setErrors(null);
      } else {
        operacao2Id.setErrors(null);
        valorNumerico2.setErrors(null);
      }
    };
  }
}

