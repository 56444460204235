<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i> Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/planejamento']">Planejamento</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header d-flex pb-0">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i> {{ titulo }}
            </h3>

            <ul class="nav nav-tabs ml-auto p-0">
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{ active: aba === 0 }" (click)="mudarAba(0)">Informações Básicas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{ active: aba === 7 }" (click)="mudarAba(7)">Matriz de planejamento</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{ active: aba === 1 }" (click)="mudarAba(1)">Custos e Recursos</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{ active: aba === 2 }" (click)="mudarAba(2)">Cronograma</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{ active: aba === 3 }" (click)="mudarAba(3)">Amostra</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{ active: aba === 4 }" (click)="mudarAba(4)">Solicitação Técnica</a>
              </li>
              <li class="nav-item">
               <a class="nav-link" [ngClass]="{ active: aba === 5 }" (click)="mudarAba(5)" >Relatoria</a>
             </li>
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{ active: aba === 6 }" (click)="mudarAba(6)">Linha do tempo</a>
              </li>
            </ul>
          </div>

          <form [formGroup]="form" #f="ngForm" (keydown.enter)="$event.preventDefault()">
            <div class="card-body">

              <!-- INFORMAÇÕES BÁSICAS -->
              <div #abaDados [hidden]="aba !== 0">
                <div class="row">
                  <div class="form-group col-md-3">
                    <label class="mb-1">Número da OPA </label>
                    <input type="text" [readonly]="true" class="form-control form-control-sm"
                      [value]="model.opa?.numero" />
                  </div>
                  <div class="form-group col-md-3">
                    <label class="mb-1">Origem da Auditoria</label>
                    <input type="text" [readonly]="true" class="form-control form-control-sm"
                      [value]="model.opa?.origem.nome" />
                  </div>
                  <div class="form-group col-md-3">
                    <label class="mb-1">Data de Elaboração</label>
                    <input type="text" [readonly]="true" class="form-control form-control-sm"
                      [value]="model.opa?.dataElaboracao | date:'dd/MM/yyyy' " />
                  </div>
                  <div class="form-group col-md-3">
                    <label class="mb-1">Tipo da Auditoria</label>
                    <input type="text" [readonly]="true" class="form-control form-control-sm"
                      [value]="model.opa?.tipoAuditoria.nome" />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="mb-1">UG a ser Auditado</label>
                    <input type="text" [readonly]="true" class="form-control form-control-sm"
                      [value]="model.opa?.unidadeGestora.nome" />
                  </div>
                  <div class="form-group col-md-3">
                    <label class="mb-1">Data prevista para Início</label>
                    <input type="text" [readonly]="true" class="form-control form-control-sm"
                      [value]="model.opa?.dataInicioPrevista | date:'dd/MM/yyyy'" />
                  </div>
                  <div class="form-group col-md-3">
                    <label class="mb-1">Data prevista para Fim</label>
                    <input type="text" [readonly]="true" class="form-control form-control-sm"
                      [value]="model.opa?.dataFimPrevista | date:'dd/MM/yyyy'" />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="mb-1">Objeto</label>
                    <input type="text" [readonly]="true" class="form-control form-control-sm"
                      [value]="model.opa?.objeto" />
                  </div>
                  <div class="form-group col-md-3">
                    <label class="mb-1">Data início de realização</label>
                    <input type="text" [readonly]="true" class="form-control form-control-sm"
                      [value]="model.opa?.dataInicioRealizacao | date:'dd/MM/yyyy'" />
                  </div>
                  <div class="form-group col-md-3">
                    <label class="mb-1">Data fim de realização</label>
                    <input type="text" [readonly]="true" class="form-control form-control-sm"
                      [value]="model.opa?.dataFimRealizacao | date:'dd/MM/yyyy'" />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4">
                    <label class="mb-1">Status</label>
                    <input type="text" [readonly]="true" class="form-control form-control-sm"
                      [value]="model?.status?.descricao"/>
                  </div>

                  <div class="form-group col-md-8">
                    <label class="mb-1">Coordenador de auditoria</label>
                    <input type="text" [readonly]="true" class="form-control form-control-sm"
                      [value]="model.opa?.auditorCoordenacao.nomeAuditor" />
                  </div>
                </div>

                <div class="row">
                  <!-- SUBTIPOAUDITORIA -->
                  <div class="col-md-6">
                    <label for="#" class="form-label">Selecionar subtipo auditoria</label>
                    <div class="mat-elevation-z1">
                      <table mat-table [dataSource]="subtipoDataSource" #tabela1="matSort" matSort >
                        <tr mat-header-row *matHeaderRowDef="['nome', 'actions']" class="bg-light"></tr>

                        <ng-container matColumnDef="nome">
                          <th mat-header-cell *matHeaderCellDef>
                            Subtipo
                          </th>
                          <td mat-cell *matCellDef="let element" class="limit-text">
                            {{ element?.subTipoAuditoria?.nome }}
                          </td>
                        </ng-container>

                        <ng-container cdkColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef>
                            <button [hidden]="visualizar || hiddenEdicao" type="button" class="btn btn-success btn-xs"
                              (click)="exibirModalSubtipo()">
                              Adicionar
                            </button>
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index" class="block">
                            <div class="btn-group">
                              <button [hidden]="visualizar || hiddenEdicao" *ngIf="!element.justificativa" type="button"
                                class="btn btn-danger btn-xs text-left" (click)="excluirSubtipo(i, element.subTipoAuditoria.id)">
                                <i class="far fa-trash-alt"></i>
                              </button>
                              <i [hidden]="!element.justificativa" [matTooltip]="element.justificativa"
                                class="fa fa-info-circle"></i>

                            </div>
                          </td>
                        </ng-container>

                        <tr mat-row *matRowDef="let row; columns: ['nome', 'actions']"></tr>
                      </table>
                      <h5 [hidden]="!subtipoSemDados" class="table-no-data">
                        Nenhum Subtipo vinculado
                      </h5>
                      <mat-paginator #TableOnePaginator="matPaginator" [hidden]="subtipoSemDados" [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
                    </div>
                  </div>

                  <!-- AUDITORES -->
                  <div mat-top class="col-md-6">
                    <label for="#" class="form-label">Selecionar auditores</label>
                    <div class="mat-elevation-z1">
                      <table mat-table [dataSource]="auditoresDataSource"  #tabela2="matSort" matSort >
                        <tr mat-header-row *matHeaderRowDef="['nome', 'actions']" class="bg-light"></tr>

                        <ng-container matColumnDef="nome">
                          <th mat-header-cell *matHeaderCellDef> Equipe de Auditores </th>
                          <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.indisponivelPeriodo" style="color: red;"><i
                                class="fas fa-exclamation-triangle" title="Auditor indisponível para o período"></i>
                              {{element.nomeAuditor}} </span>
                            <span *ngIf="!element.indisponivelPeriodo"> {{element.nomeAuditor}} </span>
                          </td>
                        </ng-container>

                        <ng-container cdkColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef><button type="button" *ngIf="!visualizar" 
                            [hidden] = "hiddenEdicao"
                            class="btn btn-success btn-xs"
                              (click)="exibirModalAlocarEquipe()"> Adicionar
                            </button></th>
                          <td mat-cell *matCellDef="let element; let i = index" [hidden]="hiddenEdicao" class="block">
                            <div class="btn-group">
                              <button type="button" class="btn btn-danger btn-xs text-left" *ngIf="!visualizar" (click)="desvincularAlocarEquipe(element.id)">
                                <i class="far fa-trash-alt"></i> </button>
                            </div>
                          </td>
                        </ng-container>

                        <tr mat-row *matRowDef="let row; columns: ['nome', 'actions'];"></tr>
                      </table>
                      <h5 [hidden]="!auditoresSemDados" class="table-no-data">Nenhum Auditor vinculado</h5>
                      <mat-paginator #TableTwoPaginator="matPaginator" [hidden]="auditoresSemDados" [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
                    </div>
                  </div>
                </div>

                <!-- Unidades Gestoras -->
                <div class="row mt-2">
                  <div class="col-md-12">
                    <label for="#" class="form-label">Selecionar Ugs</label>
                    <app-unidade-gestora [visualizar]="visualizar" [hiddenEdicao]="hiddenEdicao" [displayedColumns]="displayedColumnsUg" (excluir)="excluirUg($event)" [ugType]="'subtipo'" [planejamentoUgSubtipo]="model?.planejamentoUgSubtipo" [subtipoSelecionado]="subtipoDataSource?.data" (dados)="unidadeGestoraSelecionada($event)"></app-unidade-gestora>
                  </div>
                </div>


              </div>
              <!-- FIM -->

              <!-- Matriz de planejamento -->
              <div #abaDados [hidden]="aba !== 7">
                <app-aba-matriz-planejamento [visualizar]="visualizar" [id]="this.id" ></app-aba-matriz-planejamento>
              </div>
              <!-- Fim matriz -->

              <!-- CUSTOS E RECURSOS -->
              <div #abaDados [hidden]="aba !== 1">
                <app-custos-recursos [visualizar]="visualizar"></app-custos-recursos>
              </div>

              <div #abaDados [hidden]="aba !== 2">
                <div >
                  <app-aba-cronograma [tipo]="'planejamento'" [visualizar]="visualizar"></app-aba-cronograma>
                </div>
              </div>

              <!-- Aba Amostras -->
              <div #abaDados [hidden]="aba !== 3">
                <div >
                 <app-aba-amostra [numeroOpa]="numeroOpa" [visualizar]="visualizar" [statusPlanejamento]="statusPlanejamento"></app-aba-amostra>
                </div>
              </div>
              <!-- FIM -->
               <!-- Aba Solicitação Técnica -->
               <div #abaDados [hidden]="aba !== 4">
                <div >
                 <app-aba-solicitacao-tecnica [numeroOpa]="numeroOpa" [visualizar]="visualizar"></app-aba-solicitacao-tecnica>
                </div>
              </div>
              <!-- FIM -->

              <!-- Aba Linha do tempo -->
              <div #abaDados [hidden]="aba !== 6" class="p-4">
                <app-linha-do-tempo [aba]="aba"></app-linha-do-tempo>
              </div>
              <!-- Fim Linha do tempo -->

              <div #abaDados [hidden]="aba !== 5" class="p-4">
              <app-relatoria
                [aba]="aba"
                [relatoriaDados]="relatoriaDados"
                [obterDados]="obterDados.bind(this)"
                [visualizar]="visualizar"
                [submit]="submit"
                [model]="model"
                [tipoTrabalhoId]="id"
              >
              </app-relatoria>
            </div>

            </div>




            <div class="card-footer">
              <button type="button" class="btn btn-warning btn-sm mr-1" (click)="enviarParaAprovacao()" [hidden]="!permissoes.enviarParaAprovacao"
              *ngIf="!visualizar && aba === 5" [disabled]="!model.habilitarEnviarAprovacao">
                <i class="far fa-save"></i> Enviar para aprovação
              </button>
              <button type="button" class="btn btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Sair
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
