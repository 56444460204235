import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { IAgentePublicoModel } from "src/app/models/agente-publico.model";
import { IAuditorCoordenacaoModel } from "src/app/models/auditor-coordenacao.model";
import {
	MatDialog,
	MatDialogConfig,
} from "@angular/material/dialog";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import { defaultDataAcessor } from "src/app/core/helpers/mat-sort-data-accessor";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AgentePublicoService } from "src/app/services/agente-publico.service";
import Swal from 'sweetalert2';
import { IAgentePublicoDisponibilidadeModel } from "src/app/models/agente-publico-disponibilidade.model";
import { SharedService } from "src/app/services/shared.service";
import { Subscription } from "rxjs";
import { IAgentePublicoDispensaModel } from "src/app/models/agente-publico-dispensa.model";
import { ModalAdicionarDispensaComponent } from "./modal-adicionar-dispensa/modal-adicionar-dispensa.component";

const sortingDataAccessor = (item, property) => {
	switch (property) {
	  case 'nome':
		return item.pessoa.nome;
	  case 'cpfFormatted':
		return item.pessoa.cpf;
	  default:
		return item[property];
	}
  };

@Component({
	selector: "app-aba-dispensas",
	templateUrl: "./aba-dispensas.component.html",
	styleUrls: ["./aba-dispensas.component.scss"],
})
export class AgentePublicoAbaDispensasComponent extends BaseFormComponent{
	
	@Input() public visualizar;

	clickEventSubscription: Subscription;
	public model : IAgentePublicoModel;
	public aba = 0;
	public selectedModelYears: Array<number> = [];
	public selectedYear: number;
	public semDados = true;
	public dispensasDataSource = new MatTableDataSource<IAgentePublicoDispensaModel>([]);
  
	public form =  new FormGroup({
		nome: new FormControl(null, Validators.required),
	});


	constructor(
		public route: ActivatedRoute,
		public toastr: ToastrService,
		public router: Router,
		public localeService: BsLocaleService,
		public matDialog: MatDialog,
		private agentePublicoService: AgentePublicoService,
		private sharedService: SharedService,
		
	) {
		super(route, toastr, router, localeService, matDialog);
		this.titulo = 'Agente público';
		this.clickEventSubscription = this.sharedService
			.getEvent()
			.subscribe(() => {
				this.onSalvar();
			});
	}

  
	async ngOnInit(): Promise<void> {
		await this.popularComboAnos();
		this.buscar();
	}


  public async buscar() {

	const res = await this.agentePublicoService.getDadosDispensas(this.id, this.selectedYear);
	if(res.sucesso){
		this.model = res.dados;
		this.titulo = "Agente Público: " + this.model.pessoa.nome;
		this.dispensasDataSource = new MatTableDataSource<IAgentePublicoDispensaModel>(this.model.dispensas);
		this.semDados = this.dispensasDataSource.filteredData.length === 0;
	}

	}

	private atualizarModel(values: any) {
		Object.assign(this.model, values);
	  }
	
	  public onBack() {
		this.router.navigate(['/agente-publico']);
	  }
	  
	  public cancelar() {
		this.router.navigate(['/agente-publico']);
	  }

	  
	  public excluirDispensa(id: number){
		Swal.fire({
			title: 'Exclusão de dispensa',
			text:
			  `Deseja realmente excluir esta dispensa?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Sim',
			cancelButtonText: 'Não',
		  }).then(async (result) => {
			if (result.value) {
			  this.agentePublicoService
						.excluirDispensa(id)
						.then((res) =>{
						  if(res.sucesso){
							this.exibirMensagemSucesso(res.mensagem.descricao);
							this.buscar();
						  }else{
							this.exibirMensagemErro(res.mensagem.descricao);
						  }
						})
						.catch((err)=>{
						  this.exibirMensagemAlerta(err);
						});
			}
		  });
	  }

	  public async onSalvar(){
		

	  }

	  public abrirModalDispensa(element: IAgentePublicoDispensaModel){
		
		const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
		id: element ? element.id : 0,
        agentePublicoId: this.model.idAgentePublico,
		anoSelecionado: this.selectedYear,
		tipoDispensaId: element? element.tipoDispensaId : 0,
		dataInicio: element ? element.dataInicio : null,
		dataFim: element ? element.dataFim : null,
		qtdDias : element ? element.qtdDias : 0
      };
      
	  const modal = this.matDialog.open(
        ModalAdicionarDispensaComponent,
        dialogConfig
      );
      modal.afterClosed().subscribe((sucesso) => {
        if (sucesso) {
          this.buscar();
        }
      });
	  }

	  public async popularComboAnos(){
		const anoAtual = new Date().getFullYear();
		this.selectedModelYears.push(anoAtual + 4);
		this.selectedModelYears.push(anoAtual + 3);
		this.selectedModelYears.push(anoAtual + 2);
		this.selectedModelYears.push(anoAtual + 1);
		this.selectedModelYears.push(anoAtual);
		this.selectedModelYears.push(anoAtual - 1);
		this.selectedModelYears.push(anoAtual - 2);
		this.selectedYear = anoAtual;
	  }

	  public async onChangeSelectAno(element) {
		this.buscar();
	  }

}

