import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { LogService } from 'src/app/services/log.service';
import { IFiltroLog } from 'src/app/models/filtros/filtro-log.model';
import { IEnumModel } from 'src/app/models/enum.model';
import { Router } from '@angular/router';
import FileSaver from 'file-saver';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';

const sortingDataAccessor = (item, property) => {
  switch (property) {
    case 'funcionalidade':
      return item.funcionalidade.descricao;
    case 'tipoLog':
      return item.tipoLog.descricao;
    default:
      return item[property];
  }
};

@Component({
  selector: 'app-log-list',
  templateUrl: './log-list.component.html',
  styleUrls: ['./log-list.component.scss']
})
export class LogListComponent extends BaseListComponent implements OnInit, AfterViewInit {
  public dataSource = new MatTableDataSource<any>([]);
  public dataOriginal = [];
  public selectedModel: any;
  public pageEvent: any;
  public semDados = true;
  public searchNome = '';
  public totalItens: number;
  public displayedColumns: string[] = [
    'selecao',
    'id',
    'dataRegistro',
    'funcionalidade',
    'tipoLog',
    'nomeUsuario',
    'ip'
  ];
  private filtro: IFiltroLog = {} as IFiltroLog;
  public filtroModel: IFiltroLog = {} as IFiltroLog;
  public funcionalidades = [] as IEnumModel[];
  public tiposLog = [] as IEnumModel[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('tabela') public tabela: MatSort;

  constructor(private router: Router,
              private toastr: ToastrService,
              private logService: LogService,
              public authService: AuthService,) {
    super();
  }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.LogsDoSistema, EnumAcaoRecurso.Consultar);
    this.permissoes.exportar = this.authService.possuiPermissao(EnumRecursoPerfil.LogsDoSistema, EnumAcaoRecurso.Exportar);
    this.permissoes.detalhar = this.authService.possuiPermissao(EnumRecursoPerfil.LogsDoSistema, EnumAcaoRecurso.Detalhar);
 }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
    this.configFiltro();
    this.obterFuncionalidades();
    this.obterTiposLog();
    this.buscar();
  }

  public obterFuncionalidades() {

    this.logService
    .obterFuncionalidades()
    .then((res) => {
      this.funcionalidades = res.dados;
    })
    .catch((err) => {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
    })
  }

  public obterTiposLog() {

    this.logService
    .obterTiposLog()
    .then((res) => {
      this.tiposLog = res.dados;
    })
    .catch((err) => {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
    })
  }

  public buscar() {
    this.selectedModel = null;
    this.logService
    .obter(this.filtro)
    .then((res) => {
      this.dataOriginal = cloneDeep(res.dados);

      this.dataSource = new MatTableDataSource<any>(res.dados.resultado);
      this.dataSource.sortingDataAccessor = sortingDataAccessor;
      this.dataSource.sort = this.tabela;
      this.totalItens = res.dados.totalItens;

      this.semDados = this.dataSource.filteredData.length === 0;
    })
    .catch((err) => {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
    })
  }

  buscarComFiltro() {
    this.filtro.dataInicial = this.filtroModel.dataInicial;
    this.filtro.dataFinal = this.filtroModel.dataFinal;
    this.filtro.funcionalidade = this.filtroModel.funcionalidade;
    this.filtro.tipoLog = this.filtroModel.tipoLog;
    this.filtro.nomeUsuario = this.filtroModel.nomeUsuario;
    this.buscar();
  }

  configFiltro()
  {
    this.filtro.pagina = this.paginator.pageIndex + 1;
    this.filtro.tamanhoPagina = this.paginator.pageSize;
    this.filtro.ordenacaoColuna = this.tabela.active;
    this.filtro.ordenacaoSentido = this.tabela.direction;
  }

  sortData(sort) {
    this.filtro.ordenacaoColuna = sort.active;
    this.filtro.ordenacaoSentido = sort.direction;

    this.buscar();
  }

  pageChanged(e)
  {
    this.filtro.pagina = e.pageIndex + 1;
    e.pageIndex =  this.filtro.pagina;
    this.filtro.tamanhoPagina = e.pageSize;

    this.buscar();
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel !== item ? item : null;
  }

  public detalhar() {
    if (this.selectedModel !== null){
      this.router.navigate(['/log/' + this.selectedModel.id]);
    }
  }

  public exportar() {

    this.logService
    .exportar(this.filtro)
    .then((res) => {
      const pdf = new Blob([res], {type: 'application/pdf;charset=utf-8'});
      FileSaver.saveAs(pdf, 'Exportação Logs do Sistema.pdf');
    })
    .catch((err) => {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
    })
  }
}
