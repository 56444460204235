<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>
          </div>
          <form [formGroup]="form" #f="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
            <div class=" card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="tetoBaixo" class="mb-1">Baixo</label>
                    <div class="input-group">
                      <input type="text" 
                      id="pisoBaixo" 
                      class="form-control form-control-sm" 
                      formControlName="pisoBaixo" 
                      data-inputmask="'alias': 'numeric', 
                                      'radixPoint': ',', 
                                      'digits': 4, 
                                      'digitsOptional': false, 
                                      'placeholder': '0'"
                      maxlength="6"
                      />
                      <div class="input-group-prepend input-group-append">
                        <span class="input-group-text form-control-sm">a</span>
                      </div>
                      <input type="text" id="tetoBaixo" class="form-control form-control-sm" formControlName="tetoBaixo" (keyup)="calcular($event.target)" data-inputmask="'alias': 'numeric',
                      'radixPoint': ',', 'digits': 4, 'digitsOptional': false, 'placeholder': '0'" maxlength="6"/>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="pisoMedio" class="mb-1">Médio</label>
                    <div class="input-group">
                      <input type="text" id="pisoMedio" class="form-control form-control-sm mask-number"
                        formControlName="pisoMedio" (keyup)="calcular($event.target)" data-inputmask="'alias': 'numeric',
                        'radixPoint': ',', 'digits': 4, 'digitsOptional': false, 'placeholder': '0'" [ngClass]="{
                        'is-invalid': form.controls['pisoMedio'].invalid }" maxlength="6" />
                      <div class="input-group-prepend input-group-append"> 
                        <span class="input-group-text form-control-sm">a</span>
                      </div>
                      <input type="text" id="tetoMedio" class="form-control form-control-sm mask-number"
                        formControlName="tetoMedio" (keyup)="calcular($event.target)" data-inputmask="'alias': 'numeric',
                        'radixPoint': ',', 'digits': 4, 'digitsOptional': false, 'placeholder': '0'" [ngClass]="{
                        'is-invalid': form.controls['tetoMedio'].invalid }" maxlength="6" />
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="pisoAlto" class="mb-1">Alto</label>
                    <div class="input-group">
                      <input type="text" id="pisoAlto" class="form-control form-control-sm" formControlName="pisoAlto" (keyup)="calcular($event.target)" data-inputmask="'alias': 'numeric',
                      'radixPoint': ',', 'digits': 4, 'digitsOptional': false, 'placeholder': '0'" maxlength="6"/>
                      <div class="input-group-prepend input-group-append">
                        <span class="input-group-text form-control-sm">a</span>
                      </div>
                      <input type="text" id="tetoAlto" class="form-control form-control-sm" formControlName="tetoAlto" (keyup)="calcular($event.target)" data-inputmask="'alias': 'numeric',
                      'radixPoint': ',', 'digits': 4, 'digitsOptional': false, 'placeholder': '0'" maxlength="7"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="btn-group">
                <button type="submit" class="btn btn-primary btn-sm mr">
                  <i class="far fa-save"></i> Salvar
                </button>
                <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                <div class="dropdown-menu" role="menu">
                  <a class="dropdown-item" (click)="onSubmit()">Salvar</a>
                  <a class="dropdown-item" (click)="onSubmitClose()">Salvar e fechar</a>
                </div>
              </div>

              <button type="button" class="btn btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
