import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { ITecnicaAuditoriaModel } from 'src/app/models/tecnica-auditoria.model';
import { TecnicaAuditoriaService } from 'src/app/services/tecnica-auditoria.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tecnica-auditoria-form',
  templateUrl: './tecnica-auditoria-form.component.html',
  styleUrls: ['./tecnica-auditoria-form.component.scss']
})
export class TecnicaAuditoriaFormComponent extends BaseFormComponent implements OnInit {
  public model: ITecnicaAuditoriaModel = {} as ITecnicaAuditoriaModel;
  public auditoresDataSource = new MatTableDataSource<ITecnicaAuditoriaModel>([]);
  public semDados = true;
  public salvar: boolean;

  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private tecnicaAuditoriaService: TecnicaAuditoriaService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Nova Técnica de Auditoria';
    } 
  }


  config: AngularEditorConfig = {
    editable: !this.visualizar,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async ngOnInit() {
    try {
      if (!this.novoRegistro) {
        const res = await this.tecnicaAuditoriaService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;
          
          if (this.visualizar) {
            this.titulo = 'Visualizar Técnica de Auditoria - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Técnica de Auditoria - ' + this.model.nome;
          } 
      

        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.router.navigate(['/tecnica-auditoria']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.router.navigate(['/tecnica-auditoria']);
    }
  }

  public async onSubmit(salvarEFechar: boolean = false) {
    this.salvar = true
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }

    this.atualizarModel(this.form.value);

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        res = await this.tecnicaAuditoriaService.atualizar(this.model);
      } else {
        res = await this.tecnicaAuditoriaService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        if (salvarEFechar) {
          this.router.navigate(['/tecnica-auditoria']);
        }else
        if(this.novoRegistro){
          this.router.navigate([`/tecnica-auditoria/${res.dados.id}`]);
          await this.ngOnInit();
        }
      } else {
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.router.navigate(['/tecnica-auditoria']);
  }
}
