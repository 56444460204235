import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IPerguntaMetricaModel } from "src/app/models/pergunta-metrica.model";
import { MatTableDataSource } from "@angular/material/table";

@Component({
	selector: "app-modal-excluir-metrica",
	templateUrl: "./modal-excluir-metrica.component.html",
	styleUrls: ["./modal-excluir-metrica.component.scss"],
})
export class ModalExcluirMetricaComponent implements OnInit {
	public metricasDataSource = new MatTableDataSource<IPerguntaMetricaModel>(
		[]
	);
	public semDados = true;

	constructor(
		public dialogRef: MatDialogRef<ModalExcluirMetricaComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {}

	ngOnInit(): void {}

	onConfirm(): void {
		this.dialogRef.close(true);
	}

	onCancel(): void {
		this.dialogRef.close(false);
	}
}
