<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">Importar Arquivo de Respostas</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-container p-3">
        <div class="row ">
          <div class="form-group col-md-12">
            <label for="arquivo">Selecione um arquivo do tipo txt ou csv contendo o código da UG e o valor. Ex: 190101;2.872.506</label> <br>
            <input type="file" id="arquivo" accept=".txt,.csv" (change)="onFileChange($event)" #fileInput>
          </div>
        </div>
        <div class="row ">
          <h3 *ngIf="conteudoValido === true" class="text-success">Conteúdo do arquivo validado e disponível para
            importação</h3>
          <h3 *ngIf="conteudoValido === false" class="text-danger">Arquivo com formato ou formato inválido</h3>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary btn-sm mr-1" [disabled]="!(conteudoArquivo && conteudoValido)"
        (click)="concluir()">
        <i class="fas fa-check"></i> Ok
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
</div>
