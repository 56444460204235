<div id="modal-content-wrapper">
  <div
    class="card card-secondary card-outline"
    cdkDragBoundary="html"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
  >
    <div class="card-header bg-light" cdkDragHandle>
      <h4 class="card-title mb-0">Preencher Formulário</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <mat-toolbar>
        <mat-toolbar-row style="height: 30px">
          <span>Critério</span>
        </mat-toolbar-row>
        <mat-toolbar-row>
          <div class="input-group input-group-sm" style="width: 100%">
            <input
              type="text"
              name="table_search"
              class="form-control float-right"
              disabled
              [ngModel]="criterio?.nome"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" [hidden]="semDados">
          <ng-container matColumnDef="selecao">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                color="primary"
                (change)="element.selecionado = $event.checked"
                [checked]="element.selecionado"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="sigla">
            <th mat-header-cell *matHeaderCellDef>Sigla</th>
            <td mat-cell *matCellDef="let element">
              {{ element.unidadeGestora.sigla }}
            </td>
          </ng-container>
          
          <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef>Valor ({{ criterio?.unidade }})</th>
            <td mat-cell *matCellDef="let element">
              <input
                type="text"
                class="form-control form-control-sm"
                maxlength="19"
                [(ngModel)]="element.valorNumerico"
                data-inputmask="'alias': 'numeric', 'radixPoint': ',', 'digits': 2,
                'digitsOptional': false, 'placeholder': '0'" maxlength="18"
              />
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
        </table>
        <h5 [hidden]="!semDados" class="table-no-data">Sem dados a exibir</h5>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="button"
        class="btn btn-primary btn-sm mr-1"
        (click)="concluir()"
      >
        <i class="fas fa-check"></i> Ok
      </button>

            <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
        </div>
    </div>
</div>