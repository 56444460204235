function toFormatValue(item: any)  {
  if (!item) return value;
  var value = item.toString();

  var result = value.search(/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]/);

  if(result !== -1) {
    return value[(value.length - 1)]
  }

  const currentValue = parseInt(value.replace(/[^\d]/g, "")).toString();
  const cvLength = currentValue.length;

  if (cvLength === 1) return `0,0${currentValue.slice(0)}`;

  if (cvLength === 2) return `0,${currentValue.slice(0)}`;

  if (cvLength === 3) return `${currentValue.slice(0, 1)},${currentValue.slice(1)}`;

  if (cvLength === 4) return `${currentValue.slice(0, 2)},${currentValue.slice(2)}`;

  if (cvLength === 5) return `${currentValue.slice(0, 3)},${currentValue.slice(3)}`;

  if (cvLength === 6) return `${currentValue.slice(0, 1)}.${currentValue.slice(1, 4)},${currentValue.slice(4)}`;

  if (cvLength === 7) return `${currentValue.slice(0, 2)}.${currentValue.slice(2, 5)},${currentValue.slice(5)}`;

  if (cvLength === 8) return `${currentValue.slice(0, 3)}.${currentValue.slice(3, 6)},${currentValue.slice(6)}`;

  if (cvLength === 9) return `${currentValue.slice(0, 1)}.${currentValue.slice(1, 4)}.${currentValue.slice(4, 7)},${currentValue.slice(7)}`;

  if (cvLength === 10) return `${currentValue.slice(0, 2)}.${currentValue.slice(2, 5)}.${currentValue.slice(5, 8)},${currentValue.slice(8)}`;

  if (cvLength === 11) return `${currentValue.slice(0, 3)}.${currentValue.slice(3, 6)}.${currentValue.slice(5, 9)},${currentValue.slice(9)}`;

  if (cvLength === 12) return `${currentValue.slice(0, 3)}.${currentValue.slice(4, 7)}.${currentValue.slice(8, 11)},${currentValue.slice(10)}`;

  if (cvLength === 13) return `${currentValue.slice(0, 3)}.${currentValue.slice(4, 7)}.${currentValue.slice(8, 11)},${currentValue.slice(11)}`;

  return value;
}

function toFormatDecimal(valor: number, casasDecimais: number) {
  if (valor !== null && !isNaN(valor)) {
    return valor.toFixed(casasDecimais) + ' %';
  }
}

function toRemoveCharacters(str) {
  var a = str.toString().replaceAll(/[, ]+/g, "").trim();
  var b = a.toString().replaceAll(/[. ]+/g, "").trim();
 return b
}

export { toFormatValue, toRemoveCharacters, toFormatDecimal };