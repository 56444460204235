import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { data } from 'jquery';
import { IBaseModel } from '../models/base.model';
import { IRolResponsaveisModel } from '../models/rol-responsaveis.model';
import { BaseService } from './base.service';
@Injectable({
  providedIn: 'root',
})
export class RolResponsaveisService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean, dataInicio: Date = null, dataFim: Date = null, simplificado = false): Promise<IBaseModel<IRolResponsaveisModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (dataInicio) {
      params = params.append('dataInicio', dataInicio.toDateString());
    }

    if (dataFim) {
      params = params.append('dataFim', dataFim.toDateString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<IRolResponsaveisModel[]>>(`${this.apiBaseUrl}/rolresponsaveis`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IRolResponsaveisModel>> {
    return this.httpClient
      .get<IBaseModel<IRolResponsaveisModel>>(`${this.apiBaseUrl}/rolresponsaveis/${id}`)
      .toPromise();
  }

  public async atualizar(request: IRolResponsaveisModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/tipocriterio/${request.id}`, request)
      .toPromise();
  }

  public async inserir(request: IRolResponsaveisModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/rolresponsaveis`, request)
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/rolresponsaveis/${id}/inativar`, {})
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/rolresponsaveis/${id}/reativar`, {})
      .toPromise();
  }
}
