import { AfterViewInit, Component } from '@angular/core';
import Inputmask from 'inputmask';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { IParametrizacaoModel } from 'src/app/models/parametrizacao.model';
import { ParametrizacaoService } from 'src/app/services/parametrizacao.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-parametrizacao-form',
  templateUrl: './modal-parametrizacao-form.component.html',
  styleUrls: ['./modal-parametrizacao-form.component.scss']
})
export class ModalParametrizacaoFormComponent implements AfterViewInit {
  public model = {} as IParametrizacaoModel;
  public onClose: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef,
    private paramService: ParametrizacaoService) { }

  ngAfterViewInit() {
    this.onClose = new Subject();
    Inputmask().mask(document.querySelectorAll('input'));
  }

  public async concluir() {
    if (!this.model.valor || this.model.valor.length === 0) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        icon: 'warning',
        text: 'Obrigatório informar um valor para a parametrização'
      });
      return;
    }

    try {
      const res = await this.paramService.atualizar(this.model);

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: 'success',
          text: 'Parametrização salva com sucesso'
        });
        this.onClose.next(true);
        this.bsModalRef.hide();
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: 'warning',
          text: res.mensagem.descricao,
        });
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        icon: 'error',
        text: err.mensagem.descricao,
      });
    }
  }

  public cancelar() {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }
}
