import { IUgResponsavelModel } from "./../models/item-solicitacao-tecnica.model";
import { IUnidadeGestoraModel } from "src/app/models/unidade-gestora.model";
import { IBaseModel } from "src/app/models/base.model";
import { ISolicitacaoTecnicaModel } from "./../models/solicitacao-tecnica.model";
import { LocationStrategy } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import {
	IArquivoRespostaModel,
	IItemSolicitacaoTecnicaModel,
	IRepostaItemModel,
} from "../models/item-solicitacao-tecnica.model";
import { IArquivoModel } from "../models/arquivo.model";
import { IResponsavelModel } from "../models/responsaveis.model";
import { PaginacaoModel } from "../models/paginacao.model";
import { filterBy } from './auditoria.service';

@Injectable({
	providedIn: "root",
})
export class SolicitacaoTecnicaService extends BaseService {
	constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
		super(httpClient, locationStrategy);
	}

	public async obter(
		tipoTrabalhoId: number,
		tipoTrabalho: string
	): Promise<IBaseModel<ISolicitacaoTecnicaModel[]>> {
		if (tipoTrabalho === "planejamento") {
			return this.httpClient
				.get<IBaseModel<ISolicitacaoTecnicaModel[]>>(
					`${this.apiBaseUrl}/solicitacao-tecnica/planejamento/tipo-trabalho/${tipoTrabalhoId}`
				)
				.toPromise();
		}
		if (tipoTrabalho === "osa") {
			return this.httpClient
				.get<IBaseModel<ISolicitacaoTecnicaModel[]>>(
					`${this.apiBaseUrl}/solicitacao-tecnica/osa/tipo-trabalho/${tipoTrabalhoId}`
				)
				.toPromise();
		}
	}

	public async obterPorId(
		id: number
	): Promise<IBaseModel<ISolicitacaoTecnicaModel>> {
		return this.httpClient
			.get<IBaseModel<ISolicitacaoTecnicaModel>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}`
			)
			.toPromise();
	}

	public async obterUnidadeGestora(tipoTrabalho: string, tipoTrabalhoId: number,
	): Promise<IBaseModel<IUnidadeGestoraModel[]>> {
			return this.httpClient
				.get<IBaseModel<IUnidadeGestoraModel[]>>(
					`${this.apiBaseUrl}/solicitacao-tecnica/${tipoTrabalho}/tipo-trabalho/${tipoTrabalhoId}/unidades-gestoras`
				)
				.toPromise();

	}

	public async obterResponsaveis(
		unidadeGestoraId: number
	): Promise<IBaseModel<IResponsavelModel[]>> {
		return this.httpClient
			.get<IBaseModel<IResponsavelModel[]>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/responsaveis-unidade-gestora/${unidadeGestoraId}`
			)
			.toPromise();
	}


	public async salvarSolicitacao(
		data: ISolicitacaoTecnicaModel
	): Promise<IBaseModel<ISolicitacaoTecnicaModel>> {
		return this.httpClient
			.post<IBaseModel<ISolicitacaoTecnicaModel>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/salvar`,
				data
			)
			.toPromise();
	}
	public async excluir(id: number): Promise<IBaseModel<void>> {
		return this.httpClient
			.delete<IBaseModel<void>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}/excluir`,
				{}
			)
			.toPromise();
	}

	public async desvincularUgResponsavel(
		solicitacaoTecnicaId: number,
		unidadeGestoraId: number
	): Promise<IBaseModel<void>> {
		return this.httpClient
			.delete<IBaseModel<void>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${solicitacaoTecnicaId}/unidade-gestora/${unidadeGestoraId}/excluir`,
				{}
			)
			.toPromise();
	}

	public async salvarUgResponsavel(
		id: number,
		data: any
	): Promise<IBaseModel<any>> {
		return this.httpClient
			.post<IBaseModel<any>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}/salvar-ugs-responsavel`,
				data
			)
			.toPromise();
	}

	public async cancelarSolicitacao(
		id: number,
		justificativa: string
	): Promise<IBaseModel<ISolicitacaoTecnicaModel>> {
		return this.httpClient
			.patch<IBaseModel<ISolicitacaoTecnicaModel>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}/cancelar`,
				{ justificativa }
			)
			.toPromise();
	}

	public async habilitarRespostaSolicitacao(
		id: number,
		justificativa: string,
		prazo: Date
	): Promise<IBaseModel<ISolicitacaoTecnicaModel>> {
		return this.httpClient
			.patch<IBaseModel<ISolicitacaoTecnicaModel>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}/habilitar-resposta`,
				{ justificativa, prazo }
			)
			.toPromise();
	}

	public async finalizar(
		id: number
	): Promise<IBaseModel<ISolicitacaoTecnicaModel>> {
		return this.httpClient
			.patch<IBaseModel<ISolicitacaoTecnicaModel>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}/finalizar`,
				{}
			)
			.toPromise();
	}

	public async enviar(
		id: number,
		tipoTrabalho: string
	): Promise<IBaseModel<ISolicitacaoTecnicaModel>> {
		if (tipoTrabalho === "planejamento") {
			return this.httpClient
				.patch<IBaseModel<ISolicitacaoTecnicaModel>>(
					`${this.apiBaseUrl}/solicitacao-tecnica/${id}/tipo-trabalho/planejamento/enviar`,
					{}
				)
				.toPromise();
		}
		if (tipoTrabalho === "osa") {
			return this.httpClient
				.patch<IBaseModel<ISolicitacaoTecnicaModel>>(
					`${this.apiBaseUrl}/solicitacao-tecnica/${id}/tipo-trabalho/osa/enviar`,
					{}
				)
				.toPromise();
		}
	}

	public async obterUgResponsavel(
		solicitacaoTecnicaId: number
	): Promise<IBaseModel<IUgResponsavelModel[]>> {
		return this.httpClient
			.get<IBaseModel<IUgResponsavelModel[]>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${solicitacaoTecnicaId}/ugs-responsavel`
			)
			.toPromise();
	}

  public async avaliarProrrogacao(
		id: number, dados: any
	): Promise<IBaseModel<any>> {
		return this.httpClient
			.patch<IBaseModel<any>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}/avaliar`, dados
			)
			.toPromise();
	}

	// ITEM
	public async obterItemId(
		solicitacaoTecnicaId: number,
		id: number
	): Promise<IBaseModel<IItemSolicitacaoTecnicaModel>> {
		return this.httpClient
			.get<IBaseModel<IItemSolicitacaoTecnicaModel>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${solicitacaoTecnicaId}/item/${id}`
			)
			.toPromise();
	}

  public async obterRespostaItem(id: number, itemId: number): Promise<IBaseModel<IItemSolicitacaoTecnicaModel>> {
		return this.httpClient
			.get<IBaseModel<IItemSolicitacaoTecnicaModel>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}/item/${itemId}/respostas`
			)
			.toPromise();
	}

	public async salvarItem(
		id: number,
		data: IItemSolicitacaoTecnicaModel
	): Promise<IBaseModel<IItemSolicitacaoTecnicaModel>> {
		return this.httpClient
			.post<IBaseModel<IItemSolicitacaoTecnicaModel>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}/salvar-item`,
				data
			)
			.toPromise();
	}

	public async excluirItem(
		id: number,
		itemId: number
	): Promise<IBaseModel<void>> {
		return this.httpClient
			.delete<IBaseModel<void>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}/item/${itemId}/excluir`,
				{}
			)
			.toPromise();
	}

	public async cancelarItem(
		id: number,
		itemId: number,
		justificativa: string
	): Promise<IBaseModel<void>> {
		return this.httpClient
			.patch<IBaseModel<void>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}/item/${itemId}/cancelar`,
				{ justificativa }
			)
			.toPromise();
	}

	public async finalizarItem(id: number): Promise<IBaseModel<any>> {
		return this.httpClient
			.put<IBaseModel<any>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/item/${id}/finalizar`,
				{}
			)
			.toPromise();
	}

  public async enviarItem(id: number): Promise<IBaseModel<any>> {
		return this.httpClient
			.put<IBaseModel<any>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/item/${id}/enviar`,
				{}
			)
			.toPromise();
	}

	public async delegarItem(
		idItem: number,
		idResponsavel: string
	): Promise<IBaseModel<any>> {
		return this.httpClient
			.put<IBaseModel<any>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/item/${idItem}/responsavel/${idResponsavel}/delegar`,
				{}
			)
			.toPromise();
	}

	//  ARQUIVO

	public async salvarItemResposta(
		itemSolicitacaoTecnicaId: number,
		itemResposta: IRepostaItemModel
	): Promise<IBaseModel<IRepostaItemModel>> {
		return this.httpClient
			.post<IBaseModel<IRepostaItemModel>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/item/${itemSolicitacaoTecnicaId}/resposta`,
				itemResposta
			)
			.toPromise();
	}

	public async salvarAnexo(dados: any	): Promise<IBaseModel<IArquivoModel>> {		
		return this.httpClient
			.post<IBaseModel<IArquivoModel>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/item/salvar-anexo`,
				dados
			)
			.toPromise();
	}

	public async excluirAnexo(
		id: number,
		itemId: number,
		anexoId: number
	): Promise<IBaseModel<void>> {
		return this.httpClient
			.delete<IBaseModel<void>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}/item/${itemId}/anexo/${anexoId}/excluir`,
				{}
			)
			.toPromise();
	}

	public async downloadAnexo(
		itemId: number,
		anexoId: number
	): Promise<IBaseModel<IArquivoModel>> {
		return this.httpClient
			.get<IBaseModel<IArquivoModel>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/item/${itemId}/anexo/${anexoId}/download`,
				{}
			)
			.toPromise();
	}

	public async filtrar(filtro: any): Promise<IBaseModel<PaginacaoModel<any[]>>> {
		return this.httpClient
		.get<IBaseModel<PaginacaoModel<any[]>>>(filterBy(filtro, `${this.apiBaseUrl}/solicitacao-tecnica/filtrar`) )
      	.toPromise();
	}
	private recuperarParametrosFiltro(filtro: any): HttpParams {
		let params = new HttpParams();

		if (filtro.responsavelDelegacao){
		  params = params.append('responsavelId', filtro.responsavelDelegacao.toString());
		}
		if (filtro.prazoResposta){
		  params = params.append('prazo', filtro.prazoResposta.toString());
		}
		if (filtro.numeroOsa) {
		  params = params.append('numOsa', filtro.numeroOsa.toString());
		}
		if (filtro.numeroOpa) {
		  params = params.append('numOpa', filtro.numeroOpa.toString());
		}
		if (filtro.numeroSolicitacao) {
		  params = params.append('numSolicitacao', filtro.numeroSolicitacao.toString());
		}
		if (filtro.status) {
		  params = params.append('status', filtro.status.toString());
		}

		return params;
	  }

	//Resposta Item

  public async prorrogarPrazo(
		id: number, dados: any
	): Promise<IBaseModel<any>> {
		return this.httpClient
			.patch<IBaseModel<any>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/${id}/prorrogar-prazo`, dados
			)
			.toPromise();
	}

	public async excluirAnexoItemResposta(
		arquivo: IArquivoRespostaModel
	): Promise<IBaseModel<void>> {
		return this.httpClient
			.delete<IBaseModel<void>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/item/resposta/${arquivo.id}/anexo-resposta/excluir`
			)
			.toPromise();
	}

	public async downloadArquivoRespostaItem(
		arquivo: IArquivoRespostaModel
	): Promise<IBaseModel<string>> {
		return this.httpClient
			.get<IBaseModel<string>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/item/resposta/${arquivo.id}/anexo-resposta/download`
			)
			.toPromise();
	}

	public async obterResponsaveisUGs(): Promise<IBaseModel<IResponsavelModel[]>> {
		return this.httpClient
			.get<IBaseModel<IResponsavelModel[]>>(
				`${this.apiBaseUrl}/solicitacao-tecnica/responsaveis-unidades-gestoras`
			)
			.toPromise();
	}
}
