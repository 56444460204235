
import { ManterMatrizPlanejamentoService } from './../../../../services/manter-matriz-planejamento.service';
import { ISubtipoAuditoriaModel } from 'src/app/models/subtipo-auditoria.model';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { IQuestaoModel } from 'src/app/models/questao.model';
import { SubtipoAuditoriaService } from 'src/app/services/subtipo-auditoria.service';
import { IMatrizPlanejamentoQuestaoModel } from 'src/app/models/matriz-planejamento-questao.model';
import { QuestaoService } from './../../../../services/questao.service';
import { IMatrizPlanejamentoModel } from './../../../../models/matriz-planejamento.model';
import { QuestaoFormComponent } from 'src/app/pages/questao-form/questao-form.component';
import { ModalUgComponent } from '../modal-ug/modal-ug.component';
import { IEnumModel } from 'src/app/models/enum.model';

interface IMatDialogDataModel {
  titulo: string;
  data: IMatrizPlanejamentoModel,
  planejamentoId: number;
  questoes: any[];
  tipoTrabalhoId: number;
}

@Component({
  selector: 'app-modal-adicionar-questoes',
  templateUrl: './modal-adicionar-questoes.component.html',
  styleUrls: ['./modal-adicionar-questoes.component.scss']
})
export class ModalAdicionarQuestoesComponent implements OnInit, AfterViewInit {

  public titulo: string = '';
  public dataSource: MatTableDataSource<IQuestaoModel> = new MatTableDataSource<IQuestaoModel>();
  public questaoSelecionada: any[] = {} as any[];
  public displayedColumns: string[] = ['incluir', 'questao'];
  public selectedModel: IMatrizPlanejamentoQuestaoModel[] = [] as IMatrizPlanejamentoQuestaoModel[];
  public matrizPlanejamentoModel: IMatrizPlanejamentoModel = {} as IMatrizPlanejamentoModel;
  public listSubtipo: ISubtipoAuditoriaModel[] = [] as ISubtipoAuditoriaModel[];
  public id: number = null;
  public tipoTrabalho: IEnumModel = {} as IEnumModel;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModalAdicionarQuestoesComponent>,
    private router: Router,
    private questaoService: QuestaoService,
    private subtipoAuditoriaService: SubtipoAuditoriaService,
    private manterMatrizPlanejamentoService: ManterMatrizPlanejamentoService,
    public route: ActivatedRoute,
    public matDialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public dados: IMatDialogDataModel
  ) {
    this.titulo = dados.titulo;
    this.matrizPlanejamentoModel = dados.data;
    this.id = dados?.tipoTrabalhoId;
    this.questaoSelecionada = dados.questoes;

    if (this.router.url.includes("planejamento")) {
      this.tipoTrabalho.nome = 'planejamento';
      this.tipoTrabalho.codigo = 7;
    }
    else if (this.router.url.includes("osa")) {
      this.tipoTrabalho.nome = 'osa';
      this.tipoTrabalho.codigo = 4;
    }else {
      this.tipoTrabalho = null
    }
  }

  public filtrarModel: FormGroup = this.fb.group({
		tituloAuditoria: ["", Validators.required],
		descricao: ["", Validators.required],
		subtipo: [""]
	});

  ngOnInit(): void {
    this.obterQuestoes();
    this.obterSubtiposAuditoria();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.sort = this.sort;
  }

  public onSubmit(): void {
    this.dialogRef.close(this.selecionados());
  }


  public selecionados() : IQuestaoModel[]{
    let selecionados : IQuestaoModel[] = this.dataSource.data.filter(item => item.selecionado);
    return selecionados;
  }

  public cancelar(): void {
    this.dialogRef.close();
  }

  public buscarComFiltro(): void {
    /*if(!+this.filtrarModel?.value?.subtipo){
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        text:'Selecione o subtipo!',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return;
    }*/
    this.manterMatrizPlanejamentoService.obterQuestaoSubtipo(this.filtrarModel?.value?.subtipo, this.filtrarModel?.value?.descricao)
      .then(res => {
        if(res.sucesso){
          let questoes = res.dados
          this.dataSource.data = questoes.map(a => {
            const questaoSelecionada = this.questaoSelecionada.find(b => b?.questaoId === +a?.value);
            if(questaoSelecionada?.questaoId) a.selecionado = true;
            return a;
          });
          this.dataSource.paginator = this.paginator;
          this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
          this.dataSource.sort = this.sort
        }else{
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "warning",
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      })
  }

  public async obterQuestoes(): Promise<void> {
    let questoes = (await this.questaoService.obterPorTipoTrabalho(this.tipoTrabalho.codigo)).dados;
    this.dataSource.data = questoes.map(a => {
      const questaoSelecionada = this.questaoSelecionada.find(b => b?.questaoId === a?.id);
      if(questaoSelecionada?.questaoId && !questaoSelecionada?.readonly){
        a.selecionado = true;
        a.readonly = false
      } else if(questaoSelecionada?.questaoId && questaoSelecionada?.readonly){
        a.readonly = true
      }
      return a;
    });
  }

  public async obterSubtiposAuditoria(): Promise<void> {
    this.listSubtipo = (await this.subtipoAuditoriaService.obter()).dados;
  }

  public abrirModalAdicionarQuestao(visualizar: Boolean = false): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-listagem-questoes-manter-planejamento';
    dialogConfig.width = '65%';
    dialogConfig.height = '85%';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = false;

    dialogConfig.data = {
      titulo: 'Adicionar questão',
      visualizar,
      tipoTrabalho: this.tipoTrabalho,
      tipoTrabalhoId: this.id,
      subTipoCategoria : this.dados.data?.matrizPlanejamento?.id
    };

    const modal = this.matDialog.open(
      QuestaoFormComponent,
      dialogConfig
    );

    modal.afterClosed().subscribe((data) => {
      if (data) {
        let selecionados : IQuestaoModel[] = [];
        selecionados.push(data.data as IQuestaoModel);
        this.dialogRef.close(selecionados);
      }
      this.ngOnInit()
    });

  }


  public sortingDataAccessor: (T: IQuestaoModel, I: any) => any = (item: IQuestaoModel, property) => {
		switch (property) {
			case "questao":
				return item?.nome;
			default:
				return item[property];
		}
	};


}
