import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { MatTableDataSource } from '@angular/material/table';
import Inputmask from 'inputmask';
import { ToastrService } from 'ngx-toastr';
import { IAgentePublicoModel } from 'src/app/models/agente-publico.model';
import { IPaaAbaCapacidadeModalCapacidade, IPaaAbaCapacidadeModalCapacidadeDispensa, IPaaAbaCapacidadeModalCapacidadeDisponibilidade, IPaaAbaCapacidadeModel } from 'src/app/models/paa-aba-capacidade.model';

@Component({
  selector: 'app-modal-capacidade-paa',
  templateUrl: './modal-capacidade-paa.component.html',
  styleUrls: ['./modal-capacidade-paa.component.scss']
})
export class ModalCapacidadePaaComponent implements OnInit {
  public model: IPaaAbaCapacidadeModalCapacidade;
  
  public dispensaDataSource = new MatTableDataSource<IPaaAbaCapacidadeModalCapacidadeDispensa>([]);
  public auditorDataSource = new MatTableDataSource<any>([]);
  public disponibilidadeDataSource = new MatTableDataSource<IPaaAbaCapacidadeModalCapacidadeDisponibilidade>([]);

  public dispensaSemDados = true;
  public disponibilidadeSemDados = true;
  public colunasOriginais = ['nomeAuditor'];
  public colunasAdicionar = [];
  public columnsDisponibilidades = [];

  public tabelaAuditores = [] as IAgentePublicoModel[];
  public tabelaDispensas = [] as IPaaAbaCapacidadeModalCapacidadeDispensa[]
  public tabelaDisponibilidades = [] as IPaaAbaCapacidadeModalCapacidadeDisponibilidade[];

  constructor(
    public toastr: ToastrService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {

    this.model = data.dados;
    
    this.dispensaDataSource = new MatTableDataSource<IPaaAbaCapacidadeModalCapacidadeDispensa>(this.model.dispensas);
    this.dispensaSemDados = this.dispensaDataSource.data.length === 0;

    this.disponibilidadeDataSource = new MatTableDataSource<IPaaAbaCapacidadeModalCapacidadeDisponibilidade>(this.model.disponibilidades);
    this.disponibilidadeSemDados = this.disponibilidadeDataSource.data.length == 0;

     this.tabelaDisponibilidades = this.model.disponibilidades;

     if(this.tabelaDisponibilidades[0]){
      
      this.tabelaDisponibilidades[0].tiposTrabalho.forEach((disponibilidade) => {
        const template = disponibilidade as any;
        const nomeColuna = template.item1;
        if(!this.colunasAdicionar.some((c) => c === nomeColuna)){
          this.colunasAdicionar.push(nomeColuna);
        }
      })

     }
    this.columnsDisponibilidades = [];
    this.columnsDisponibilidades = this.colunasOriginais.concat(this.colunasAdicionar); 
    
  }


  public obterValorColunaNovo(element: IPaaAbaCapacidadeModalCapacidadeDisponibilidade, coluna: string): string{
    
    if(!element || !coluna)
      return '';

    var retorno = "";

    element.tiposTrabalho.forEach((tipoTrabalho) => {
      const templateTipoTrabalho = tipoTrabalho as any;
      if(templateTipoTrabalho.item1 == coluna){
        retorno = templateTipoTrabalho.item2.toFixed(2).toString();
        retorno += "%";
        return;
      }
      
    })
    return retorno;
  }

  ngOnInit(): void {
    Inputmask().mask(document.querySelectorAll('input'));
  }

  
  public fechar(){
    return this.matDialog.closeAll()
  }
}

