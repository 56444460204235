import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IBaseModel } from '../models/base.model';
import { ILogModel } from '../models/log.model';
import { IFiltroLog } from '../models/filtros/filtro-log.model';
import { IEnumModel } from '../models/enum.model';
import { LocationStrategy } from '@angular/common';
import { PaginacaoModel } from '../models/paginacao.model';

@Injectable({
  providedIn: 'root'
})
export class LogService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(filtro: IFiltroLog): Promise<IBaseModel<PaginacaoModel<ILogModel[]>>> {
    const params = this.recuperarParametrosFiltro(filtro);

    return this.httpClient
      .get<IBaseModel<PaginacaoModel<ILogModel[]>>>(`${this.apiBaseUrl}/logsistema`, { params })
      .toPromise();
  }

  public obterFuncionalidades(): Promise<IBaseModel<IEnumModel[]>> {
    return this.httpClient
      .get<IBaseModel<IEnumModel[]>>(`${this.apiBaseUrl}/logsistema/funcionalidades`, {})
      .toPromise();
  }

  public obterTiposLog(): Promise<IBaseModel<IEnumModel[]>> {
    return this.httpClient
      .get<IBaseModel<IEnumModel[]>>(`${this.apiBaseUrl}/logsistema/tipos-log`, {})
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<ILogModel>> {
    return this.httpClient
      .get<IBaseModel<ILogModel>>(`${this.apiBaseUrl}/logsistema/${id}`)
      .toPromise();
  }

  public async exportar(filtro: IFiltroLog): Promise<Blob> {
    const params = this.recuperarParametrosFiltro(filtro);

    return this.httpClient
      .get(`${this.apiBaseUrl}/logsistema/exportar`, { params, responseType: 'blob' })
      .toPromise();
  }

  private recuperarParametrosFiltro(filtro: IFiltroLog): HttpParams {
    let params = new HttpParams();
    params = params.append('pagina', filtro.pagina.toString());
    params = params.append('tamanhoPagina', filtro.tamanhoPagina.toString());
    params = params.append('ordenacaoColuna', filtro.ordenacaoColuna.toString());
    params = params.append('ordenacaoSentido', filtro.ordenacaoSentido.toString());

    if (filtro.dataInicial){
      params = params.append('dataInicial', filtro.dataInicial.toString());
    }
    if (filtro.dataFinal){
      params = params.append('dataFinal', filtro.dataFinal.toString());
    }
    if (filtro.funcionalidade){
      params = params.append('funcionalidade', filtro.funcionalidade.toString());
    }
    if (filtro.tipoLog){
      params = params.append('tipoLog', filtro.tipoLog.toString());
    }
    if (filtro.nomeUsuario){
      params = params.append('nomeUsuario', filtro.nomeUsuario);
    }

    return params;
  }
}
