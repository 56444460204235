import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as cloneDeep from 'lodash/cloneDeep';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { IMatrizPlanejamentoModel } from 'src/app/models/matriz-planejamento.model';
import { IMatrizPlanejamentoQuestaoModel } from 'src/app/models/matriz-planejamento-questao.model';
import { ISubtipoAuditoriaMatrizPlanejamentoModel } from 'src/app/models/subtipo-auditoria-matriz-planejamento.model';
import { ISubtipoAuditoriaModel } from 'src/app/models/subtipo-auditoria.model';
import { MatrizPlanejamentoService } from 'src/app/services/matriz-planejamento.service';
import { QuestaoService } from 'src/app/services/questao.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-matriz-planejamento-form',
  templateUrl: './matriz-planejamento-form.component.html',
  styleUrls: ['./matriz-planejamento-form.component.scss']
})
export class MatrizPlanejamentoFormComponent extends BaseFormComponent implements OnInit {
  public model: IMatrizPlanejamentoModel = {} as IMatrizPlanejamentoModel;
  public modelSubtipoAuditoria: ISubtipoAuditoriaModel = {} as ISubtipoAuditoriaModel;
  public modelMatrizPlanejamentoQuestao: IMatrizPlanejamentoQuestaoModel = {} as IMatrizPlanejamentoQuestaoModel;
  public questaoDataSource = new MatTableDataSource<IMatrizPlanejamentoQuestaoModel>([]);
  public listaMatrizPlanejamento: ISubtipoAuditoriaMatrizPlanejamentoModel = {} as ISubtipoAuditoriaMatrizPlanejamentoModel;

  public rotaPrefixoSubtipoAuditoria = undefined;
  public semDados = true;
  public adicionarQuestão: boolean;
  public isAuditoria!: boolean;

  @Input() auditoriaModel!: IMatrizPlanejamentoModel;


  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private matrizPlanejamentoService: MatrizPlanejamentoService,
    private questaoService: QuestaoService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if(this.prefixo == 'novo'){
      this.titulo = 'Nova Matriz Planejamento';
    }  

    this.isAuditoria = this.router.url.includes("auditoria");

  }

  config: AngularEditorConfig = {
    editable: !this.visualizar,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async ngOnInit() {
    try {

      if(this.auditoriaModel?.id && this.isAuditoria){
        this.model = this.auditoriaModel;
        this.setQuestions();
      } else if (!this.novoRegistro && !this.auditoriaModel?.id && !this.isAuditoria) {
        const res = await this.matrizPlanejamentoService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;

          switch (this.prefixo) {
            case ('edicao'):
              this.titulo = 'Editar Matriz Planejamento - ' + this.model.nome;
              break;
            case ('visualizar'):
              this.titulo = 'Visualização Matriz Planejamento #' + this.model.nome;
              break;
          }
          // questão
          this.setQuestions();
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
          this.router.navigate(['/matriz-planejamento']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
      this.router.navigate(['/matriz-planejamento']);
    } 
    this.state();
    this.form.patchValue(this.model);
  }

  public setQuestions(): void { 
    this.questaoDataSource = new MatTableDataSource<IMatrizPlanejamentoQuestaoModel>(
      this.model.matrizPlanejamentoQuestao
    );
    this.questaoDataSource._updateChangeSubscription();
    this.semDados = this.questaoDataSource.filteredData.length === 0;
  }

  public state() {
    // Subtipo Auditoria
    if (window.history.state.modelSubtipoAuditoria) {
      this.modelSubtipoAuditoria = window.history.state.modelSubtipoAuditoria;
    }

    // matriz planejamento
    if (window.history.state.modelMatrizPlanejamento) {
      this.model = window.history.state.modelMatrizPlanejamento;
    }

    // questão
    if (window.history.state.listaQuestao) {
      this.questaoDataSource = new MatTableDataSource<IMatrizPlanejamentoQuestaoModel>(
        window.history.state.listaQuestao
      );
      this.questaoDataSource._updateChangeSubscription();
      this.semDados = this.questaoDataSource.filteredData.length === 0;
    }

    // Matriz Planejamento
    if (window.history.state.listaMatrizPlanejamento) {
      this.listaMatrizPlanejamento = window.history.state.listaMatrizPlanejamento;
    }

    // Rota
    if (window.history.state.rotaPrefixoSubtipoAuditoria) {
      this.rotaPrefixoSubtipoAuditoria = window.history.state.rotaPrefixoSubtipoAuditoria;
    }
  }

  public async onSubmit(salvarEFechar: boolean = false) {
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário invalido');
      return;
    }

    this.atualizarModel(this.form.value);
    this.model.matrizPlanejamentoQuestao = this.questaoDataSource.data;

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        this.model.id = this.id
        this.model.ativo = true
        res = await this.matrizPlanejamentoService.atualizar(this.model);
      } else {
        res = await this.matrizPlanejamentoService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Registro salvo com sucesso!',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        if (this.rotaPrefixoSubtipoAuditoria) {
          this.router.navigate([this.rotaPrefixoSubtipoAuditoria], {
            state: {
              modelSubtipoAuditoria: this.modelSubtipoAuditoria,
              listaMatrizPlanejamento: this.listaMatrizPlanejamento
            }
          });
        }else{
          if (salvarEFechar == true) {
            this.router.navigate(['/matriz-planejamento']);
          }
          else
          if(this.novoRegistro){
            this.router.navigate([`/matriz-planejamento/${res.dados.id}/edicao`]);
            await this.ngOnInit();
          }
        }
      } else {
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(validacoes);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    } finally {
      this.adicionarQuestão = true
    }
  }

  public incluirQuestao() {
    if (this.questaoDataSource.data.findIndex(a => a.questaoId === this.modelMatrizPlanejamentoQuestao.questaoId) >= 0) {
      this.exibirMensagemAlerta('Questão já cadastrada nessa questão');
      return;
    }

    this.questaoDataSource.data.push(cloneDeep(this.modelMatrizPlanejamentoQuestao));
    this.questaoDataSource._updateChangeSubscription();
    this.modelMatrizPlanejamentoQuestao = {} as IMatrizPlanejamentoQuestaoModel;
    this.semDados = false;
  }

  public excluirQuestao(ind: number) {
    this.questaoDataSource.data.splice(ind, 1);
    this.questaoDataSource._updateChangeSubscription();
    this.semDados = this.questaoDataSource.filteredData.length === 0;
  }

  public visualizarQuestao(ind: number) {
    this.adicionarQuestão = true
    this.router.navigate([`/questao/${ind}/visualizar`], {
      state: {
        modelMatrizPlanejamento: this.form.value,
        listaQuestao: this.questaoDataSource.data,
        listaMatrizPlanejamento: this.listaMatrizPlanejamento,
        rotaPrefixoSubtipoAuditoria: this.rotaPrefixoSubtipoAuditoria,
        rotaPrefixoMatrizPlanejamento: `/matriz-planejamento/${this.id !== undefined ? this.id + '/' : ''}${this.prefixo}`
      },
    });
  }

  public async exibirModalQuestao() {
    try {
      const res = await this.questaoService.obter('', true);
      if (res.sucesso) {
        const dadosFiltrados = res.dados.filter(questao => questao.ativo);
        const modal = this.exibirModal('Selecionar Questão', dadosFiltrados);
        modal.afterClosed().subscribe(data => {
          if (data) {
            this.modelMatrizPlanejamentoQuestao.questaoId = data.id;
            this.modelMatrizPlanejamentoQuestao.questao = data;
          }
        }
        );
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public async cadastroRapidoQuestao() {
    this.adicionarQuestão = true;
    this.router.navigate([`/questao/novo`], {
      state: {
        modelMatrizPlanejamento: this.form.value,
        modelSubtipoAuditoria: this.modelSubtipoAuditoria,
        listaQuestao: this.questaoDataSource.data,
        listaMatrizPlanejamento: this.listaMatrizPlanejamento,
        rotaPrefixoSubtipoAuditoria: this.rotaPrefixoSubtipoAuditoria,
        rotaPrefixoMatrizPlanejamento: `/matriz-planejamento/${this.id !== undefined ? this.id + '/' : ''}${this.prefixo}`
      },
    });
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    if (window.history.state.rotaPrefixoSubtipoAuditoria) {
      this.router.navigate([window.history.state.rotaPrefixoSubtipoAuditoria], {
        state: {
          modelSubtipoAuditoria: this.modelSubtipoAuditoria,
          listaMatrizPlanejamento: this.listaMatrizPlanejamento
        }
      });
    } else {
      this.router.navigate(['/matriz-planejamento']);
    }
  }
}
