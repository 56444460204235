import { LocationStrategy } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IAtividadeModel } from "../models/atividade.model";
import { IBaseModel } from "../models/base.model";
import { ICronogramaModel } from "../models/cronograma.model";
import { ISubAtividadeModel } from "../models/sub-atividade.model";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: 'root',
})
export class CronogramaService extends BaseService {

    constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
        super(httpClient, locationStrategy);
    }

    public async obterCronograma(planejamentoId :number): Promise<IBaseModel<ICronogramaModel>> {
        return this.httpClient
          .get<IBaseModel<ICronogramaModel>>(`${this.apiBaseUrl}/cronogramas/${planejamentoId}`)
          .toPromise();
      }

    public obterAtividade(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<IAtividadeModel[]>> {
        let params = new HttpParams();
        params = params.append('pesquisa', pesquisa || '');

        if (ativo !== undefined) {
            params = params.append('ativo', ativo.toString());
        }

        if (simplificado) {
            params = params.append('simplificado', simplificado.toString());
        }

        return this.httpClient
            .get<IBaseModel<IAtividadeModel[]>>(`${this.apiBaseUrl}/atividades`, { params })
            .toPromise();
    }

    public async obterAtividadePorId(id: number): Promise<IBaseModel<IAtividadeModel>> {
        return this.httpClient
          .get<IBaseModel<IAtividadeModel>>(`${this.apiBaseUrl}/atividades/${id}`)
          .toPromise();
      }

      public obterSubAtividade(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<ISubAtividadeModel[]>> {
        let params = new HttpParams();
        params = params.append('pesquisa', pesquisa || '');

        if (ativo !== undefined) {
            params = params.append('ativo', ativo.toString());
        }

        if (simplificado) {
            params = params.append('simplificado', simplificado.toString());
        }

        return this.httpClient
            .get<IBaseModel<ISubAtividadeModel[]>>(`${this.apiBaseUrl}/subatividades`, { params })
            .toPromise();
    }

    public async obterSubAtividadePorId(id: number): Promise<IBaseModel<ISubAtividadeModel>> {
        return this.httpClient
          .get<IBaseModel<ISubAtividadeModel>>(`${this.apiBaseUrl}/subatividades/${id}`)
          .toPromise();
      }

      public async salvar(data: ICronogramaModel): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/cronogramas/salvar`, data )
          .toPromise();
      }

      public async excluirAtividade(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .delete<IBaseModel<void>>(`${this.apiBaseUrl}/cronogramas/${id}/excluir-atividade`, {})
          .toPromise ();
      }

      public async excluirSubAtividade(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .delete<IBaseModel<void>>(`${this.apiBaseUrl}/cronogramas/${id}/excluir-subatividade`, {})
          .toPromise ();
      }

      public async inserirAtividade(data: IAtividadeModel): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/cronogramas/atividade`, data )
          .toPromise();
      }

      public async inserirsubAtividade(data: ISubAtividadeModel): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/cronogramas/subatividade`, data )
          .toPromise();
      }

      public async atualizarAtividade(model: any, id: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .put<IBaseModel<void>>(`${this.apiBaseUrl}/cronogramas/atividade/${id}` , model)
          .toPromise();
      }

      public async atualizarSubAtividade(model: ISubAtividadeModel, id: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .put<IBaseModel<void>>(`${this.apiBaseUrl}/cronogramas/subatividade/${id}` , model)
          .toPromise();
      }

}
