<div id="modal-content-wrapper">
    <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">

        <div class="card-header bg-light" cdkDragHandle>
            <h4 class="card-title mb-0">Cancelar Atividade</h4>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="Nao()"><i class="fas fa-times"></i>
          </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-container p-3">
                <div class="row ">
                    <div class="form-group col-md-12">
                        <label for="coordenacaoNome" class="mb-1">Todas as informações preenchidas serão perdidas. Deseja continuar?</label>
                    </div>
                </div>
            </div>

        </div>
        <div class="card-footer">
            <button type="button" class="btn btn-primary btn-sm mr-1" (click)="Sim()">
          <i class="fas fa-check"></i> Sim
        </button>

            <button type="button" class="btn btn-default btn-sm" (click)="Nao()">
          <i class="fas fa-times"></i> Não
        </button>
        </div>
    </div>
</div>