<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">Adicionar Métrica</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body px-0">
      <div class="table-container">
        <form [formGroup]="formMetrica" #f="ngForm" (ngSubmit)="concluir()" (keydown.enter)="$event.preventDefault()">

          <div class="row p-2">
            <div class="form-group col-md-9">
              <label for="selectOpcao" class="mb-1">Opção</label>
              <select id="selectOpcao" formControlName="opcaoId" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': formMetrica.controls['opcaoId'].touched &&
                  formMetrica.controls['opcaoId'].invalid }">
                <option *ngFor="let item of listaOpcoes" [value]="item.id">
                  {{item.nome}}</option>
              </select>
            </div>

            <div class="form-group col-md-3">
              <label for="inputId" class="mb-1">Nota</label>
              <input type="text" class="form-control form-control-sm float-right" formControlName="nota" data-inputmask="'alias': 'numeric', 'radixPoint': ',',
                    'digits': 2, 'digitsOptional': false, 'placeholder': '0', 'groupSeparator': '.'"
                [ngClass]="{ 'is-invalid': formMetrica.controls['nota'].touched && formMetrica.controls['nota'].invalid }" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
        <i class="fas fa-check"></i> Adicionar
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
</div>
