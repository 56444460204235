<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item active">Parametrizações do Sistema</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i class="fas fa-table pr-2"></i>
              Parametrizações do Sistema
            </h3>
          </div>

          <div class="card-body p-2">
            <div class="mat-elevation-z4">
              <mat-toolbar class="table-header">
                <mat-toolbar-row>
                  <!-- <button type="button" class="btn btn-default btn-sm mr-1" (click)="novo()">
                    <i class="far fa-file"></i> Novo
                  </button> -->
                  <button type="button" class="btn btn-primary btn-sm mr-1" [disabled]="!selectedModel"
                    (click)="editar(selectedModel.id)" [hidden]="!permissoes.editar">
                    <i class="far fas fa-pen"></i> Editar
                  </button>
                  <span class="fill-remaining-space"></span>
                  <div class="input-group input-group-sm" style="width: 200px;">
                    <input type="text" name="table_search" class="form-control float-right" (keyup.enter)="buscar()"
                      placeholder="Pesquisar por nome" [(ngModel)]="searchNome" />

                    <div class="input-group-append">
                      <button type="button" class="btn btn-info" (click)="buscar()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </mat-toolbar-row>
              </mat-toolbar>

              <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear
                class="mat-elevation-z8" matSortActive="id" matSortDirection="asc" [hidden]="semDados">
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                <ng-container matColumnDef="selecao">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                      (change)="selectedModel = ($event.checked ? element : null)"
                      [checked]="selectedModel?.id === element.id">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                  <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <ng-container matColumnDef="descricao">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrição </th>
                  <td mat-cell *matCellDef="let element"> {{element.descricao}} </td>
                </ng-container>

                <ng-container matColumnDef="valor">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="(element.tipoValor.nome === 'Texto' || element.tipoValor.nome === 'Inteiro' ||
                      element.tipoValor.nome === 'Lista' || element.tipoValor.nome === 'Decimal')">
                      {{element.valor}}
                    </ng-container>
                    <ng-container *ngIf="element.tipoValor.nome === 'SimNao'">
                      {{(element.valor === 'true' ? 'Sim' : 'Não')}}
                    </ng-container>

                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selecionar(row)" class="clickable"
                  [ngClass]="{'bg-light': selectedModel?.id === row.id}"></tr>
              </table>
              <h5 [hidden]="!semDados" class="table-no-data">Nenhum registro encontrado.</h5>
              <mat-paginator [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons (page)="selectedModel = null">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
