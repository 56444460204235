<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="card-header bg-light" cdkDragHandle>
      <h4 [hidden]="visualizar" class="card-title mb-0" >Preencher Formulário</h4>
      <h4 *ngIf="visualizar" class="card-title mb-0" >Visualizar Respostas</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" [hidden]="semDados">

          <ng-container matColumnDef="ug">
            <th mat-header-cell *matHeaderCellDef> Unidade Gestora </th>
            <td mat-cell *matCellDef="let element"> {{element.unidadeGestora.nome}} </td>
          </ng-container>

          <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef> Valor </th>
            <td mat-cell *matCellDef="let element">
              <input 
              [hidden]="visualizar"
              type="text" 
               class="form-control form-control-sm"
                [(ngModel)]="element.valorNumerico" data-inputmask="'alias': 'numeric', 'radixPoint': ',', 'digits': 2,
                'digitsOptional': false, 'placeholder': '0'" />
              <span *ngIf="visualizar">{{element.valorNumerico  | number:'1.2-2':'pt-br'}}</span> </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

        </table>
        <h5 [hidden]=" !semDados" class="table-no-data">Sem dados a exibir</h5>
      </div>
    </div>
    <div class="card-footer">
      <button [hidden]="visualizar" type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
        <i class="fas fa-check"></i> Ok
      </button>

      <button [hidden]="visualizar" type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>

      <button [hidden]="!visualizar" type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Fechar
      </button>
    </div>
  </div>
</div>