import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IFaixaClassificacaoModel } from '../models/faixa-classificacao';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class FaixaClassificacaoService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(): Promise<IBaseModel<IFaixaClassificacaoModel>> {
    return this.httpClient
      .get<IBaseModel<IFaixaClassificacaoModel>>(`${this.apiBaseUrl}/faixaclassificacao`)
      .toPromise();
  }

  public async atualizar(data: IFaixaClassificacaoModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/faixaclassificacao`, data)
      .toPromise();
  }
}
