import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    public router: Router,
    private toastr: ToastrService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.permiteAcesso(next.data);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.permiteAcesso(next.data);
  }

  private permiteAcesso(routeData: any): Promise<boolean> {
    return this.authService.obterEstado()
      .then(async (res) => {
        if (res.sucesso && res.dados.autenticado) {
          if (routeData && routeData.acao) {
            if (!this.authService.usuario) {
              await this.authService.obterPerfilUsuario();
            }

            if(routeData.recurso == EnumRecursoPerfil.Aberto){
              return true;
            }
			
			let perm = this.authService.possuiPermissao(routeData.recurso, routeData.acao);         
			
			if (perm) {
              return true;
            } else {
              perm = this.authService.possuiPerfis(routeData.perfis);

              if (perm) {
                return true;
              } else {
                this.toastr.error('Você não possui acesso a esse recurso.', 'Acesso negado!');
                this.router.navigate(['/']);
                return false;
              }
            }
          } else {
              return true;
          }
        } else {
          this.authService.login();
          return false;
        }
      })
      .catch((err) => {
        this.authService.login();
        return false;
      });
  }
}
