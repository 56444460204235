import { UnidadeGestoraService } from './../../../../services/unidade-gestora.service';
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import Swal from 'sweetalert2';

import { IResponsavelModel } from "src/app/models/responsaveis.model";
import { IUnidadeGestoraModel } from "src/app/models/unidade-gestora.model";
import { ISubtipoAuditoriaModel } from './../../../../models/subtipo-auditoria.model';
import { PlanejamentoUgSubtipo } from '../unidade-gestora.component';

@Component({
	selector: "app-modal-incluir-ug-component",
	templateUrl: "./modal-incluir-ug-component.component.html",
	styleUrls: ["./modal-incluir-ug-component.component.scss"],
})
export class ModalIncluirUgComponentComponent implements OnInit {
	public dataSourceUG =
		new MatTableDataSource<any>([]);
	public unidadesGestoras: IUnidadeGestoraModel[];
	public responsaveis: IResponsavelModel[];
	public ugSelecionada!: IUnidadeGestoraModel;
	public responsavelSelecionado: IResponsavelModel;
  public subtipoList: ISubtipoAuditoriaModel[] = [] as ISubtipoAuditoriaModel[];
  public subtipoSelecionado!: ISubtipoAuditoriaModel;
  public ugType: string = '';
  public subTiposVinculados: PlanejamentoUgSubtipo[] = [] as PlanejamentoUgSubtipo[];

  public displayedColumns: string[] = ['ug', 'responsavel', 'actions'];

	public form = new FormGroup({
		ug: new FormControl("", Validators.required),
		responsavel: new FormControl("", Validators.required),
	});

	constructor(
		public matDialog: MatDialog,
		private dialogRef: MatDialogRef<ModalIncluirUgComponentComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
    private unidadeGestoraService: UnidadeGestoraService
	) {
    this.displayedColumns = data.displayedColumns;
    this.subtipoList = data?.data?.subtipoSelecionado;
    this.unidadesGestoras = data?.data?.unidadesGestoras;
    this.subTiposVinculados = data?.data?.planejamentoUgSubtipo;
    this.ugType = data.ugType;
  }

	@ViewChild("TableOnePaginator", { static: true }) tableOnePaginator: MatPaginator;
	@ViewChild("tabela1", { static: true }) tabela1: MatSort;

	ngOnInit(): void {
    this.obterUnidadesGestoras();
		this.dataSourceUG.sort = this.tabela1;
		this.dataSourceUG.sortingDataAccessor = this.sortingDataAccessor;
  }

	public salvar() {
		this.dialogRef.close(this.dataSourceUG.data);
	}

  public async obterUnidadesGestoras() {
    try {
      if(this.unidadesGestoras?.length === 0) {
        const result = await this.unidadeGestoraService.obterAtivos();
        if (result.sucesso) {
          this.unidadesGestoras = result.dados;
        }
      }
    } catch (error) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        text: ' Erro, ao obter unidades gestoras!',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }

  }

	public adicionarUg() {
    if(this.ugType === 'subtipo'){
      if (this.ugSelecionada?.id === undefined || (this.subtipoSelecionado as any)?.subTipoAuditoria?.id === undefined){
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: ' Preencha todos os campos!',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        return;
      }

      const findUgSelecionada = this.subTiposVinculados.find(x =>  x.subtipoAuditoria.id === (this.subtipoSelecionado as any)?.subTipoAuditoria?.id && x.unidadeGestora.id === this.ugSelecionada.id)?.planejamentoId;
      const findUgDataSourceSelecionada = this.dataSourceUG.data.find(x => x.subtipoAuditoria.subTipoAuditoriaId === (this.subtipoSelecionado as any).subTipoAuditoria?.id && x.unidadeGestora.id === this.ugSelecionada.id);

      if (findUgSelecionada || findUgDataSourceSelecionada?.unidadeGestora?.id ) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: ' Esse items ja estão na listagem!',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        return;
      }
    }

		this.dataSourceUG.data.push({
			unidadeGestora: this.ugSelecionada,
			responsavel: {
				nome: this.responsavelSelecionado?.nome,
				responsavelId: this.responsavelSelecionado?.idResponsavel,
			},
      subtipoAuditoria: this.subtipoSelecionado
		} as any);

		this.dataSourceUG.data = this.dataSourceUG.data;
		this.dataSourceUG.paginator = this.tableOnePaginator;

		this.ugSelecionada = undefined;
		this.responsavelSelecionado = undefined;
		this.subtipoSelecionado = undefined;
	}

	public sortingDataAccessor = (item, property) => {
		switch (property) {
			case "ug":
				return item.unidadeGestora?.sigla;
			case "responsavel":
				return item.unidadeGestora?.sigla;
			default:
				return item[property];
		}
	};

	public excluirUg(i: number) {
		this.dataSourceUG.data.splice(i, 1);
		this.dataSourceUG._updateChangeSubscription();
	}

  public incluiColuna(nomeColuna: string): boolean {
    return this.displayedColumns.includes(nomeColuna);
  }

	public cancelar() {
		this.dialogRef.close();
	}

}
