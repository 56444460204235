<div class="card-body">
    <div class="row">
        <div class="col-md-12">
            <div class="mat-elevation-z1">
                <table
                    mat-table
                    [dataSource]="
                        equipeAlocacaoDataSource
                    "
                    #tabela4="matSort"
                    matSort
                    matSortDisableClear
                >
                    <tr
                        mat-header-row
                        *matHeaderRowDef="[
                            'nome',
                            'tipoAuditoria',
                            'tipoEnfase',
                            'dataInicio',
                            'dataFim',
                            'equipeAlocada',
                            'acao'
                        ]"
                        class="bg-light"
                    ></tr>

                    <ng-container matColumnDef="nome">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                            Unidade(s) Gestora(s)
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            {{
                                element?.paaDistribuicao
                                    ?.unidadeGestora
                                    ?.sigla
                            }}
                        </td>
                    </ng-container>

                    <ng-container
                        matColumnDef="tipoAuditoria"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                            Tipo de Auditoria
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            {{
                                element?.paaDistribuicao
                                    ?.tipoAuditoria
                                    ?.nome
                            }}
                        </td>
                    </ng-container>

                    <ng-container
                        matColumnDef="tipoEnfase"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                            Tipo de Ênfase
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            {{
                                element?.paaDistribuicao
                                    ?.tipoEnfase
                                    ?.nome
                            }}
                        </td>
                    </ng-container>

                    <ng-container
                        matColumnDef="dataInicio"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                            Data Início
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            {{
                                element?.dataInicio
                                    | date: "dd/MM/yyyy"
                            }}
                        </td>
                    </ng-container>

                    <ng-container
                        matColumnDef="dataFim"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                            Data Fim
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            {{
                                element?.dataFim
                                    | date: "dd/MM/yyyy"
                            }}
                        </td>
                    </ng-container>

                    <ng-container
                        matColumnDef="equipeAlocada"
                        style="max-width: 80px"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                            Equipe Alocada
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            <p
                                *ngFor="
                                    let item of element?.paaEquipeAuditor
                                "
                                class="p-0 m-0"
                            >
                                {{
                                    lowerCase(
                                        item?.nomeAuditor
                                    )
                                }},
                            </p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="acao">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                        >
                            <span>
                                Ação
                            </span>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            <button
                                type="button"
                                class="
                                    btn
                                    btn-primary
                                    btn-xs
                                "
                                (click)="
                                    alocarEquipe(
                                        element
                                    )
                                "
                                *ngIf="mostrarBotaoAlocarEquipe()">
                                <i
                                    class="fas fa-pen"
                                ></i>
                                Alocar Equipe
                            </button>
                        </td>
                    </ng-container>

                    <tr
                        mat-row
                        *matRowDef="
                            let row;
                            columns: [
                                'nome',
                                'tipoAuditoria',
                                'tipoEnfase',
                                'dataInicio',
                                'dataFim',
                                'equipeAlocada',
                                'acao'
                            ]
                        "
                    ></tr>
                </table>
                <h5
                    [hidden]="!equipeAlocacaoSemDados"
                    class="table-no-data"
                >
                    Nenhuma Unidade Gestora cadastrada
                </h5>
            </div>
        </div>
    </div>
</div>

<div class="card-footer">

    <button
        type="button"
        class="btn btn-info btn-sm mr-1"
        (click)="visualizarGrafico()"
    >
        <i class="far fa-eye"></i> Visualizar Gráfico
    </button>


</div>