import { LocationStrategy } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IBaseModel } from "../models/base.model";
import { IRiscosModel } from "../models/riscos.model";
import { PaginacaoModel } from "../models/paginacao.model";
import { FiltroModel } from "../models/filtro.model";
import { BaseService } from "./base.service";
import { filterBy } from "../core/helpers/filterby";

@Injectable({
	providedIn: "root",
})
export class RiscosService extends BaseService {
	constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
		super(httpClient, locationStrategy);
	}

	public obter(
		filtro: FiltroModel
	): Promise<IBaseModel<PaginacaoModel<IRiscosModel[]>>> {
		return this.httpClient
			.get<IBaseModel<PaginacaoModel<IRiscosModel[]>>>(
				filterBy(filtro, `${this.apiBaseUrl}/Riscos`)
			)
			.toPromise();
	}

	public async obterPorId(id: number): Promise<IBaseModel<IRiscosModel>> {
		return this.httpClient
			.get<IBaseModel<IRiscosModel>>(`${this.apiBaseUrl}/Riscos/${id}`)
			.toPromise();
	}

	public async inserir(
		data: IRiscosModel
	): Promise<IBaseModel<IRiscosModel>> {
		return this.httpClient
			.post<IBaseModel<IRiscosModel>>(`${this.apiBaseUrl}/Riscos`, data)
			.toPromise();
	}

	public async atualizar(data: IRiscosModel): Promise<IBaseModel<void>> {
		return this.httpClient
			.put<IBaseModel<void>>(`${this.apiBaseUrl}/Riscos/${data.id}`, data)
			.toPromise();
	}

	public async reativar(id: number): Promise<IBaseModel<void>> {
		return this.httpClient
			.post<IBaseModel<void>>(
				`${this.apiBaseUrl}/Riscos/${id}/reativar`,
				{}
			)
			.toPromise();
	}

	public async inativar(id: number): Promise<IBaseModel<void>> {
		return this.httpClient
			.post<IBaseModel<void>>(
				`${this.apiBaseUrl}/Riscos/${id}/inativar`,
				{}
			)
			.toPromise();
	}

	public async excluir(id: number): Promise<IBaseModel<void>> {
		return this.httpClient
			.delete<IBaseModel<void>>(
				`${this.apiBaseUrl}/Riscos/${id}/excluir`,
				{}
			)
			.toPromise();
	}

	public obterCategorias(): Promise<IBaseModel<any[]>> {
		return this.httpClient
		  .get<IBaseModel<any[]>>(`${this.apiBaseUrl}/Riscos/obterCategorias`, {})
		  .toPromise();
	}

	public obterSubTipoAuditoria(): Promise<IBaseModel<any[]>> {
		return this.httpClient
		  .get<IBaseModel<any[]>>(`${this.apiBaseUrl}/Riscos/obterSubTipoAuditoria`, {})
		  .toPromise();
	}

	public obterTipoEnfase(): Promise<IBaseModel<any[]>> {
		return this.httpClient
		  .get<IBaseModel<any[]>>(`${this.apiBaseUrl}/Riscos/obterTipoEnfase`, {})
		  .toPromise();
	}
}
