import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { ICriterioQuestionarioModel } from '../models/criterio-questionario.model';
import { IPaaRankingPrioridadeModel } from '../models/paa-ranking-prioridade.model';
import { IPeaRankingPrioridadeModel } from '../models/pea-ranking-prioridade.model';
import { IPendenciasRankingModel } from '../models/pendencias-ranking.model';
import { IQuestionarioModel } from '../models/questionario.model';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root',
})
export class RankingDistribuicaoService extends BaseService {

    constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
        super(httpClient, locationStrategy);
    }

    public obterRankingDistribuicao(
        id: number,
        indicadorId: number,
        tipoDocumento: 1 | 2
    ): Promise<IBaseModel<IPeaRankingPrioridadeModel[]>> {
        let url = '';
        if (tipoDocumento == 1) {
            url = `${this.apiBaseUrl}/pearankingprioridade/ranking-distribuicao/${id}/peaId`;
        }
        else if (tipoDocumento == 2) {
            url = `${this.apiBaseUrl}/paarankingprioridade/ranking-distribuicao/paa/${id}/indicador/${indicadorId}`;
        }
        else
            return;

        return this.httpClient
            .get<IBaseModel<IPeaRankingPrioridadeModel[]>>(
                `${url}`
            )
            .toPromise();
    }

    public obterRankingDistribuicaoSimplificado(
        id: number,
        indicadorId: number,
        tipoDocumento: 1 | 2
    ): Promise<IBaseModel<IPeaRankingPrioridadeModel[]>> {
        let url = '';
        if (tipoDocumento == 1) {
            url = `${this.apiBaseUrl}/pearankingprioridade/ranking-distribuicao/${id}/peaId`;
        }
        else if (tipoDocumento == 2) {
            url = `${this.apiBaseUrl}/paarankingprioridade/ranking-distribuicao-simplificado/paa/${id}/indicador/${indicadorId}`;
        }
        else
            return;

        return this.httpClient
            .get<IBaseModel<IPeaRankingPrioridadeModel[]>>(
                `${url}`
            )
            .toPromise();
    }

    public gerarRankingDistribuicao(
        id: number,
        tipoDocumento: 1 | 2
    ): Promise<IBaseModel<IPendenciasRankingModel>> {
        let url = '';
        if (tipoDocumento == 1) {
            url = `${this.apiBaseUrl}/pearankingprioridade/ranking-distribuicao/${id}/peaId`;
        }
        else if (tipoDocumento == 2) {
            url = `${this.apiBaseUrl}/paarankingprioridade/ranking-distribuicao/paa/${id}`;
        }
        else
            return;

        return this.httpClient
            .put<IBaseModel<IPendenciasRankingModel>>(
                `${url}`,
                {}
            )
            .toPromise();
    }
}
