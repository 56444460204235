import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalQuestionarioImportaDadosSharedComponent } from '../modal-questionario-importa-dados/modal-questionario-importa-dados.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-questionario-nova-versao-shared',
  templateUrl: './modal-questionario-nova-versao.component.html',
  styleUrls: ['./modal-questionario-nova-versao.component.scss']
})
export class ModalQuestionarioNovaVersaoSharedComponent implements OnInit {
  private currentDialogRef: MatDialogRef<any>;

  constructor(
    private dialogRef: MatDialogRef<ModalQuestionarioNovaVersaoSharedComponent>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any ,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public Sim() {

    debugger
    if(!this.data.tipoQuestionario){
      this.dialogRef.close();
    }

    if(this.data.source === 'importarArquivo')
    {
      this.dialogRef.close();
      this.router.navigate([`importacao-arquivo-questionario-shared`]);
    }
    else
    {
      this.dialogRef.close();
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal2-component';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        tipoQuestionario: this.data.tipoQuestionario
      };
    
      const modal = this.matDialog.open(ModalQuestionarioImportaDadosSharedComponent, dialogConfig);
      modal.afterClosed().subscribe((data) => {
        if(data){
  
      }
    })
  }
  }

  public Nao() {
    this.dialogRef.close();
  }

  public cancelar() {
    this.dialogRef.close();
  }

}
