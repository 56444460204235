<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">Buscar Critério</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0 wrapper">

      <div class="table-container p-3">

        <div class="row">
          <div class="form-group col-md-12">
            <label for="selectUnidade" class="mb-1">Nome</label>
            <div class="input-group input-group-sm" style="width: 100%;">
              <input type="text" name="table_search" class="form-control float-right" (keyup.enter)="buscar()"
                      placeholder="Pesquisar por nome" [(ngModel)]="searchNome" />

              <div class="input-group-append">
                <button type="button" class="btn btn-default ml-1" (click)="buscar()">
                  <i class="fas fa-plus"></i> Filtrar
                </button>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <table mat-table [dataSource]="dataSource" matSort matSortDisableClear [hidden]="semDados">
            <ng-container matColumnDef="selecao">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                  (change)="selectedModel = ($event.checked ? element : null);"
                  [checked]="selectedModel?.id === element.id">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrição </th>
              <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
            </ng-container>

            <ng-container matColumnDef="tipoEntrada">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo entrada </th>
              <td mat-cell *matCellDef="let element"> {{element.tipoEntrada.descricao}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; when: !itemAgrupador"
                (click)="selecionar(row)" class="clickable"
                [ngClass]="{'bg-light': selectedModel?.id === row.id && selectedModel?.grupo === row.grupo}">
            </tr>

          </table>
          <h5 [hidden]="!semDados" class="table-no-data">Nenhum critério selecionado</h5>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons (page)="selectedModel = null">
          </mat-paginator>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <label for="selectUnidade" class="mb-1">Valor de Exibição:</label>
            <select id="selectUnidade" [(ngModel)]="valorExibicao" [ngModelOptions]="{standalone: true}"
              class="form-control form-control-sm">
              <option [ngValue]="1">Valor Bruto</option>
              <option [ngValue]="2">Peso</option>
              <option [ngValue]="3">Valor Bruto / Peso</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()"
      [disabled]="!selectedModel">
        <i class="fas fa-check"></i> Concluir
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
</div>
