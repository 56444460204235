import { Component, Inject, OnInit } from "@angular/core";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { IAgentePublicoACModel } from "src/app/models/agente-publico-ac.model";
import { IItemSolicitacaoTecnicaModel } from "src/app/models/item-solicitacao-tecnica.model";
import { AcessoCidadaoService } from "src/app/services/acesso-cidadao.service";
import { SolicitacaoTecnicaService } from "src/app/services/solicitacao-tecnica.service";
import { BaseListComponent } from "src/app/shared/components/base-list/base-list.component";
import Swal from "sweetalert2";

@Component({
	selector: "app-modal-delegar-item",
	templateUrl: "./modal-delegar-item.component.html",
})
export class ModalDelegarItemComponent
	extends BaseListComponent
	implements OnInit
{
	constructor(
		public matDialog: MatDialog,
		private dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public data,
		private acessoCidadaoService: AcessoCidadaoService,
		private solicitacaoTecnicaService: SolicitacaoTecnicaService
	) {
		super();
		this.item = data.item;
		this.responsavelDelegado = this.item?.responsavelId
	}

	public responsavelDelegado: any;
	public item: IItemSolicitacaoTecnicaModel;
	public responsaveis = [] as IAgentePublicoACModel[];
	public responsavelDelegacao = "";

	ngOnInit(): void {
		this.ObterResponsaveis();
	}

	public ObterResponsaveis() {
		this.acessoCidadaoService
			.ObterAgentesPublicosSecont()
			.then((res) => {
				this.responsaveis = res.dados;
        let responsavelEncontrado = this.responsaveis.find(
					(r) => r.id == this.responsavelDelegado
				)?.nome;

        if(responsavelEncontrado) {
          this.responsavelDelegado = responsavelEncontrado;
        } else {
          this.responsavelDelegado = '';
        }
			})
	}

	public delegar() {
		this.solicitacaoTecnicaService
			.delegarItem(this.item.id, this.responsavelDelegacao)
			.then((res) => {
				if (res.sucesso) {
					this.dialogRef.close();
					Swal.fire({
						toast: true,
						position: "top-end",
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
						icon: "success",
						text: res.mensagem.descricao,
					});
				} else {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "warning",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
				}
			});
	}

	public fechar() {
		this.dialogRef.close();
	}
}
