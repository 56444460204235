import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Inputmask from 'inputmask';
import { ModalCancelarComponent } from '../../../shared/components/modal-cancelar/modal-cancelar-component';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AprovacaoService } from 'src/app/services/aprovacao.service';
import { IEnumModel } from 'src/app/models/enum.model';
import { ModalEntranharComponent } from '../../paa-elaborar/modal-entranhar/modal-entranhar.component';
import { Router } from '@angular/router';
import { PeaService } from 'src/app/services/pea.service';
import { PaaService } from 'src/app/services/paa.service';
import { OpaService } from 'src/app/services/opa.service';
import { ModalAutuacaoComponent } from '../../pea-form/modal-autuacao/modal-autuacao.component';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { OsaService } from 'src/app/services/osa.service';
import { IEntranharDocumentoModel } from 'src/app/models/edocs/entranhar-documento.model';
import { IAutuacaoModel } from 'src/app/models/edocs/autuacao.model';
import { ParametrizacaoService } from 'src/app/services/parametrizacao.service';

@Component({
  selector: 'app-modal-avaliar',
  templateUrl: './modal-avaliar.component.html',
  styleUrls: ['./modal-avaliar.component.scss'],
})
export class ModalAvaliarComponent
  implements OnInit, AfterViewInit {
  public tipoDocumento: IEnumModel;
  public id: number;
  public situacaoAvaliacao = '2';
  public comentario: string;
  public ultimoNivel: boolean;
  public modelEntranhar = {} as IEntranharDocumentoModel;
  public modelAutuacao = {} as IAutuacaoModel;
  public semEntranhar: boolean;
  public comentarioHomologacao = {} as IEntranharDocumentoModel;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<ModalAvaliarComponent>,
    public matDialog: MatDialog,
    public aprovacaoService: AprovacaoService,
    public peaService: PeaService,
    public paaService: PaaService,
    public opaService: OpaService,
    public osaService: OsaService,
    public parametrizacaoService: ParametrizacaoService,
    public planejamentoService: PlanejamentoService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) data) {
    this.id = data.id;
    this.tipoDocumento = data.tipoDocumento;
  }

  ngOnInit(): void {
    this.parametrizacaoService.obterPorId('remove-edocs-opa')
    .then(res => {
    if (res.dados?.valor === 'true')
      this.semEntranhar = true;
    else this.semEntranhar = false;
    });
  }

  concluir() {

    if (!this.comentario) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'É necessário preencher o comentário.',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return;
    }

    Swal.fire({
      title: 'Avaliação',
      text:
        `Deseja realmente ${this.situacaoAvaliacao.toString() === '1' ? 'aprovar' : 'reprovar'} esse ${this.tipoDocumento?.descricao}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        const verificacao = await this.verificarFluxoAprovacao();

        if (verificacao) {
          if (!this.ultimoNivel) {
            this.aprovarReprovarTipoTrabalho();
          }
          else if (this.ultimoNivel === true) {
            this.homologarTipoTrabalho();
          }
        }
      }
    });
  }

  public async verificarFluxoAprovacao() {
    let res;
    switch (this.tipoDocumento?.codigo) {
      case 1: { // Pea
        res = await this.peaService.verificarNivelAprovacao(this.id);
        break;
      }
      case 2: { // Paa
        res = await this.paaService.verificarNivelAprovacao(this.id);
        break;
      }
      case 3: { // Opa
        res = await this.opaService.verificarNivelAprovacao(this.id);
        break;
      }
      case 7: { // Planejamento
        res = await this.planejamentoService.verificarNivelAprovacao(this.id);
        break;
      }
      case 4: { // Osa
        res = await this.osaService.verificarNivelAprovacao(this.id);
        break;
      }
    }

    if (res.sucesso) {
      this.ultimoNivel = res.dados.ultimoNivel;
      return true;
    }
    else {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: res.dados.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return false;
    }
  }

  public aprovarReprovarTipoTrabalho() {
    this.aprovacaoService.aprovarReprovar(this.id, this.tipoDocumento?.codigo, parseInt(this.situacaoAvaliacao, null), this.comentario)
      .then(res => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Tipo de trabalho atualizado com sucesso',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.dialogRef.close(true);
        }
        else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'warning',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      });
  }

  public homologarTipoTrabalho() {
    if (this.situacaoAvaliacao === '1') {
      switch (this.tipoDocumento?.codigo) {
        case 1: { // Pea
          this.homologarPea();
          break;
        }
        case 2: { // Paa
          this.homologarPaa();
          break;
        }
        case 3: { // Opa
          this.homologarOpa();
          break;
        }
        case 7: { // Planejamento
          this.homologarPlanejamento();
          break;
        }
        case 4: { // Osa
          this.homologarOsa();
          break;
        }
      }
    }
    else {
      this.aprovarReprovarTipoTrabalho();
    }

  }

  public async homologarPaa() {
    
    //COMENTADO ATÉ DEFINIÇÃO DE V2 EDOCS
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.id = 'modal-component';
    // dialogConfig.width = '650px';
    // dialogConfig.hasBackdrop = true;
    // dialogConfig.disableClose = true;
    // dialogConfig.data = { tipoTrabalhoId: this.id,
    //  tipoTrabalho: 1, comentario: this.comentario};
    // const modal = this.matDialog.open(ModalEntranharComponent, dialogConfig);
    // modal.afterClosed().subscribe((sucesso) => {
    //   if (sucesso) {
    //     this.router.navigate(['/pendencias']);
    //   }
    // });

    await this.paaService.homologar(this.id, this.modelEntranhar).then((res) =>{
      if(res.sucesso){
        this.exibirMensagem(true, 'Item homologado com sucesso!');
        this.dialogRef.close(true);
        this.router.navigate(['/pendencias']);
      }else{
        this.exibirMensagem(false, res.mensagem.descricao);
      }
    }).catch(err => this.exibirMensagem(false, err.mensagem.descricao));;

  }

  public async homologarOpa() {
  try {
    if (this.semEntranhar) {
      const res = await this.opaService.homologar(this.id, this.comentarioHomologacao);
      
      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Item homologado com sucesso!',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
        this.dialogRef.close(true);
        this.matDialog.closeAll();
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
      }
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = { 
        tipoTrabalhoId: this.id,
        tipoTrabalho: 2,
        comentario: this.comentario 
      };

      const modal = this.matDialog.open(ModalEntranharComponent, dialogConfig);
      modal.afterClosed().subscribe((sucesso) => {
        if (sucesso) {
          this.router.navigate(['/pendencias']);
        }
      });
    }
  } catch (error) {
    console.error('Erro ao homologar:', error);
  }
}



  public async homologarPlanejamento() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = { tipoTrabalhoId: this.id,
      tipoTrabalho: 3, comentario: this.comentario };
    const modal = this.matDialog.open(ModalEntranharComponent, dialogConfig);
    modal.afterClosed().subscribe((sucesso) => {
      if (sucesso) {
        this.router.navigate(['/pendencias']);
      }
    });
  }

  public async homologarOsa() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = { tipoTrabalhoId: this.id, tipoTrabalho: 4, comentario: this.comentario };
    const modal = this.matDialog.open(ModalEntranharComponent, dialogConfig);
    modal.afterClosed().subscribe((sucesso) => {
      if (sucesso) {
        this.router.navigate(['/pendencias']);
      }
    });
  }

  public async homologarPea() {
    await this.peaService.homologar(this.id, this.comentario).then((res) =>{
      if(res.sucesso){
        this.exibirMensagem(true, 'Item homologado com sucesso!');
        this.dialogRef.close(true);
        this.router.navigate(['/pendencias']);
      }else{
        this.exibirMensagem(false, res.mensagem.descricao);
      }
    }).catch(err => this.exibirMensagem(false, err.mensagem.descricao));
  }

  ngAfterViewInit(): void {
    Inputmask().mask(document.querySelectorAll('input'));
  }

  public cancelar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { interno: true };
    this.matDialog.open(ModalCancelarComponent, dialogConfig).afterClosed();
  }

  exibirMensagem(sucesso: boolean,msg: string){
    if(sucesso){
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'success',
        text: msg,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }else{
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: msg,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
    
  }
}
