import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IIndicadorModel } from 'src/app/models/indicador.model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { IndicadorService } from 'src/app/services/indicador.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-indicador-list',
  templateUrl: './indicador-list.component.html',
  styleUrls: ['./indicador-list.component.scss']
})
export class IndicadorListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<IIndicadorModel>([] as IIndicadorModel[]);
  public selectedModel: IIndicadorModel;
  public displayedColumns: string[] = ['selecao', 'nome', 'quantidadeCriterio', 'ativo'];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private indicadorService: IndicadorService,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService
  ) {
    super();
    this.buscar();
  }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Visualizar);
 }

  public novo() {
    this.router.navigate(['/indicadores/novo']);
  }

  public buscar() {
    this.selectedModel = null;

    this.indicadorService
      .obter(this.searchNome, this.searchAtivo, false)
      .then((res) => {
        for (const item of res.dados) {
          delete (item.indicadorCriterio);
        }
        this.dataSource = new MatTableDataSource<IIndicadorModel>(res.dados as IIndicadorModel[]);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public reativar(id: number) {
    this.indicadorService
     .reativar(id)
     .then((res) => {
       if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Indicador reativado com sucesso.',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
    .finally(() => {
      this.buscar();
    });
}

  public inativar(id: number){
    this.indicadorService
    .inativar(id)
    .then((res) => {
      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Indicador inativado com sucesso.',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
    .finally(() => {
      this.buscar();
    });
}

  public editar(id: number) {
    this.router.navigate([`/indicadores/${id}`]);
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }  

  public visualizar(id: number) {
    this.router.navigate([`/indicadores/${id}/visualizar`]);
  }
  
  public excluir(id: number) {
    this.indicadorService
      .excluir(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Indicador excluído com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
      .finally(() => {
        this.buscar();
      });
  }
}
