<div class="card-header bg-light">
	<h4 class="card-title mb-0">
		<i class="far fa-file"></i> {{ tituloModal }}
	</h4>
</div>
<div class="card-body">
  <div class="p-2 wrapper">
    <div class="row mb-2">
      <div class="col-md-4 mb-2">
        <label class="form-label">Nº da Solicitação Técnica</label>
        <input
          type="text"
          class="form-control form-control-sm"
          disabled
          placeholder="-"
          [ngModel]="numeroSolicitacao"
        />
      </div>
      <div class="col-md-4 mb-2">
        <label class="form-label">Nº do Item</label>
        <input
          type="text"
          class="form-control form-control-sm"
          disabled
          placeholder="-"
          [ngModel]="model?.numero"
        />
      </div>
      <div class="col-md-4 mb-2">
        <label class="form-label">Título</label>
        <input
          type="text"
          class="form-control form-control-sm"
          disabled
          placeholder="-"
          [ngModel]="model?.titulo"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-6 mb-2">
        <label class="form-label">Responsável</label>
        <input
          type="text"
          class="form-control form-control-sm"
          disabled
          placeholder="-"
          [ngModel]="model?.nomeAuditor"
        />
      </div>
      <div class="col-md-6 mb-2">
        <label class="form-label">Status</label>
        <input
          type="text"
          class="form-control form-control-sm"
          disabled
          placeholder="-"
          [ngModel]="model?.status?.descricao"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-12 mb-2">
        <label class="form-label">Descrição do Item</label>
        <textarea
          type="text"
          class="form-control"
          rows="5"
          disabled
          [ngModel]="model?.descricao"
          placeholder="-"
        ></textarea>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-12 mt-2">
        <div class="mat-elevation-z2">
          <table
            mat-table
            [dataSource]="dataSource"
            class="w-100"
            #tabelaItemSort="matSort"
            matSort
            matSortDisableClear
          >
            <tr
              mat-header-row
              *matHeaderRowDef="['arquivo', 'acoes']"
              class="bg-light"
            ></tr>
            <ng-container matColumnDef="arquivo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Arquivos do Item
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.nomeArquivo }}
              </td>
            </ng-container>

            <ng-container matColumnDef="acoes">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Ação
              </th>
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button
                  type="button"
                  (click)="Download(element)"
                  class="btn btn-success btn-xs"
                >
                  <i class="far fas fa-download"></i>
                </button>
              </td>
            </ng-container>

            <tr
              mat-row
              *matRowDef="let row; columns: ['arquivo', 'acoes']"
            ></tr>
          </table>
          <h5
            [hidden]="dataSource?.data?.length > 0"
            class="table-no-data"
          >
            Nenhum registro encontrado
          </h5>
          <mat-paginator
            #tabelaItemPaginator="matPaginator"
            [pageSizeOptions]="[5, 10, 15]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-12 mt-2">
        <label class="form-label">Descrição da Resposta</label>
        <textarea
          type="text"
          class="form-control"
          rows="5"
          [readonly]="visualizar"
          [(ngModel)]="respostaItemModel.descricao"
          placeholder="-"
        ></textarea>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-12 mt-2">
        <div class="mat-elevation-z2">
          <table
            mat-table
            [dataSource]="dataSourceResposta"
            class="w-100"
            #tabelaRespostaSort="matSort"
            matSort
            matSortDisableClear
          >
            <tr
              mat-header-row
              *matHeaderRowDef="['arquivo', 'acoes']"
              class="bg-light"
            ></tr>
            <ng-container matColumnDef="arquivo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Arquivos da Resposta
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.nomeArquivo }}
              </td>
            </ng-container>

            <ng-container matColumnDef="acoes">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Ações
              </th>
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">

                <button
                  *ngIf="element.id !== 0"
                  type="button"
                  (click)="downloadAnexoResposta(element)"
                  class="btn btn-success btn-xs mr-1"
                >
                  <i class="far fas fa-download"></i>
                </button>
                <button
                  *ngIf="!visualizar && (model?.status?.codigo === 1 || model?.status?.codigo === 4)"
                  type="button"
                  (click)="removerArquivoItemResposta(element)"
                  class="btn btn-danger btn-xs mr-1"
                >
                  <i class="far fas fa-trash"></i>
                </button>
              </td>
            </ng-container>

            <tr
              mat-row
              *matRowDef="let row; columns: ['arquivo', 'acoes']"
            ></tr>
          </table>
          <h5
            [hidden]="dataSourceResposta?.data?.length > 0"
            class="table-no-data"
          >
            Nenhum registro encontrado
          </h5>
          <mat-paginator
            #tabelaRespostaPaginator="matPaginator"
            [pageSizeOptions]="[5, 10, 15]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card-footer">
	<div class="d-flex w-100 justify-content-between align-items-center">
		<div class="list2">
			<button
				*ngIf="!visualizar && model?.status?.codigo !== 2"
				type="button"
				class="btn btn-primary btn-sm mr-1"
				(click)="salvar()"
			>
				<i class="far fa-save"></i> Salvar
			</button>

			<button
       *ngIf="!visualizar && (model?.status?.codigo === 1 || model?.status?.codigo === 5)"
				type="button"
				class="btn btn-warning btn-sm mr-1"
        (click)="finalizarItem(model?.id)"
			>
				<i class="fas fa-check"></i> Finalizar
			</button>

			<button
       *ngIf="!visualizar && model?.status?.codigo === 4"
				type="button"
				class="btn btn-success btn-sm mr-1"
        (click)="enviarItem(model?.id)"
			>
				<i class="fas fa-paper-plane"></i> Enviar
			</button>

			<button
        *ngIf="!visualizar"
				[disabled]="model?.status?.codigo === 2 "
				type="button"
				class="btn btn-success btn-sm mr-1"
        (click)="delegarItem(model)"
			>
			<i class="fas fa-plus"></i> Delegar
			</button>
			<span *ngIf="!visualizar && (model?.status?.codigo === 1 || model?.status?.codigo === 4 || model?.status?.codigo === 5)">
				<label
					for="file"
					class="btn btn-primary btn-sm mr-1"
					style="margin-top: 10px"
					><i class="fa fa-upload" aria-hidden="true"></i> Anexar
					Arquivos</label
				>
				<input
					type="file"
					id="file"
					name="file[]"
					multiple="multiple"
					style="display: none"
					(change)="anexarArquivosResposta($event)"
				/>
			</span>
		</div>
		<div class="list1">
			<button
				type="button"
				class="btn btn-default btn-sm mr-1"
				(click)="fechar()"
			>
				<i class="fas fa-times"></i> Fechar
			</button>
		</div>
	</div>
</div>
