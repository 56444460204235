import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-modal-confirmar-reativacao-distribuicao',
  templateUrl: './modal-confirmar-reativacao-distribuicao.component.html',
  styleUrls: ['./modal-confirmar-reativacao-distribuicao.component.scss']
})
export class ModalConfirmarReativacaoDistribuicaoComponent implements OnInit {
  public form = new FormGroup({
    justificativa: new FormControl('', Validators.required),
  });
  
  public titulo: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<ModalConfirmarReativacaoDistribuicaoComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    this.titulo = data.titulo ?? "Tem certeza que deseja reativar esse registro?";
  }

  ngOnInit(): void {
  }

  public concluir() {
    this.dialogRef.close(this.form.get('justificativa').value);
  }

  public cancelar() {
    this.dialogRef.close();
  }
}

