<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i>
							Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item">
						<a [routerLink]="['/coordenacoes']">Coordenações</a>
					</li>
					<li class="breadcrumb-item active">{{ titulo }}</li>
				</ol>
			</div>
		</div>
	</div>
</div>

<div class="content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card card-outline card-lightblue">
					<div class="card-header">
						<h3 class="card-title mb-0">
							<i
								*ngIf="this.id"
								class="fas fa-pencil-alt pr-2"
							></i>
							<i *ngIf="!this.id" class="far fa-file pr-2"></i>
							{{ titulo }}
						</h3>
					</div>

					<ul class="nav nav-tabs ml-auto p-0 mt-2">
						<li class="nav-item">
							<a
								class="nav-link"
								[ngClass]="{ active: aba === 0 }"
								(click)="mudarAba(0)"
								>Dados</a
							>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								[ngClass]="{ active: aba === 1 }"
								(click)="mudarAba(1)"
								>Equipe</a
							>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								[ngClass]="{ active: aba === 2 }"
								(click)="mudarAba(2)"
								>Disponibilidade</a
							>
						</li>
					</ul>
					<form
						[formGroup]="form"
						#f="ngForm"
						(keydown.enter)="$event.preventDefault()"
					>
						<div class="card-body pb-0">
							<!-- ABA DADOS -->
							<div #abaDados [hidden]="aba !== 0">
								<div class="row">
									<div class="form-group col-md-2">
										<label for="sigla" class="mb-1"
											>Sigla</label
										>
										<input
											[attr.disabled]="
												visualizar ? true : null
											"
											type="text"
											id="sigla"
											class="form-control form-control-sm"
											formControlName="sigla"
											maxlength="10"
											[ngClass]="{
												'is-invalid':
													form.get('sigla').touched &&
													form.get('sigla').invalid
											}"
										/>
										<div
											*ngIf="
												form.get('sigla').touched &&
												form.get('sigla').invalid
											"
											class="invalid-feedback"
										>
											<div
												*ngIf="
													form.get('sigla').errors
														?.required
												"
											>
												Campo obrigatório
											</div>
										</div>
									</div>

									<div class="form-group col-md-10">
										<label for="nome" class="mb-1"
											>Nome</label
										>
										<input
											[attr.disabled]="
												visualizar ? true : null
											"
											type="text"
											id="nome"
											class="form-control form-control-sm"
											formControlName="nome"
											maxlength="100"
											[ngClass]="{
												'is-invalid':
													form.get('nome').touched &&
													form.get('nome').invalid
											}"
										/>
										<div
											*ngIf="
												form.get('nome').touched &&
												form.get('nome').invalid
											"
											class="invalid-feedback"
										>
											<div
												*ngIf="
													form.get('nome').errors
														?.required
												"
											>
												Campo obrigatório
											</div>
										</div>
									</div>

									<div class="form-group col-md-12">
										<label for="inputDescricao" class="mb-1"
											>Descrição</label
										>
										<textarea
											[attr.disabled]="
												visualizar ? true : null
											"
											rows="3"
											id="inputDescricao"
											class="form-control form-control-sm"
											formControlName="descricao"
											maxlength="500"
											[ngClass]="{
												'is-invalid':
													form.get('descricao')
														.touched &&
													form.get('descricao')
														.invalid
											}"
										></textarea>
										<div
											*ngIf="
												form.get('descricao').touched &&
												form.get('descricao').invalid
											"
											class="invalid-feedback"
										>
											<div
												*ngIf="
													form.get('descricao').errors
														?.required
												"
											>
												Campo obrigatório
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div
										class="col-md-12"
										*ngIf="!novoRegistro"
									>
										<div class="mat-elevation-z1">
											<mat-toolbar class="table-header">
												<mat-toolbar-row>
													<label
														class="mb-1"
														style="font-size: 16px"
														>Tipos de
														trabalho</label
													>
													<span
														class="fill-remaining-space"
													></span>
													<div
														class="input-group input-group-sm"
														style="width: 200px"
													>
														<select
															id="selectUnidadeDisponibilidade"
															[(ngModel)]="
																selectedYear
															"
															(change)="
																onChangeSelectAno(
																	$event
																)
															"
															[ngModelOptions]="{
																standalone: true
															}"
															class="mat-form-ano form-control form-control-sm"
														>
															<option
																*ngFor="
																	let item of selectedModelYears
																"
																[ngValue]="item"
															>
																{{ item }}
															</option>
														</select>
													</div>

													<button
														type="button"
														class="btn btn-success btn-sm mr-1"
														(click)="
															adicionarCoordenacaoTipoTrabalho()
														"
														[disabled]="
															!permiteAdicionarTipoTrabalho ||
															visualizar ||
															desabilitaBotoesGridTipoTrabalho
														"
													>
														<i
															class="far fa-add"
														></i>
														Adicionar
													</button>
												</mat-toolbar-row>
											</mat-toolbar>
											<table
												mat-table
												[dataSource]="
													coordenacaoTipoTrabalhoDataSource
												"
												class="w-100"
												matSort
												matSortDisableClear
												class="mat-elevation-z8"
												#tabela1="matSort"
												[hidden]="semDadosTipoTrabalho"
											>
												<tr
													mat-header-row
													*matHeaderRowDef="
														displayedTipoTrabalhoColumns
													"
												></tr>

												<ng-container
													matColumnDef="nome"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														mat-sort-header
													>
														Nome
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														{{
															element.tipoTrabalho
																.nome
														}}
													</td>
												</ng-container>

												<ng-container
													matColumnDef="qtdMediaDias"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														mat-sort-header
													>
														Qtd. Média Dias
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														{{
															element.qtdMediaDias
														}}
													</td>
												</ng-container>

												<ng-container
													matColumnDef="qtdMembros"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														mat-sort-header
													>
														Qtd. Membros
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														{{ element.qtdMembros }}
													</td>
												</ng-container>

												<ng-container
													matColumnDef="ativo"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														mat-sort-header
													>
														Situação
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														<span
															*ngIf="
																element.ativo
															"
															class="badge bg-success text-xs"
															>Ativo</span
														>
														<span
															*ngIf="
																!element.ativo
															"
															class="badge bg-gray-dark text-xs"
															>Inativo</span
														>
													</td>
												</ng-container>

												<ng-container
													cdkColumnDef="actions"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
													></th>
													<td
														mat-cell
														*matCellDef="
															let element;
															let i = index
														"
														class="block"
													>
														<div class="btn-group">
															<button
																[hidden]="
																	visualizar
																"
																type="button"
																[disabled]="
																	desabilitaBotoesGridTipoTrabalho
																"
																class="btn btn-primary btn-xs text-left"
																(click)="
																	abrirModalTipoTrabalho(
																		element
																	)
																"
															>
																<i
																	class="fas fa-pen"
																></i>
															</button>
															<button
																[hidden]="
																	visualizar ||
																	element.ativo
																"
																type="button"
																[disabled]="
																	desabilitaBotoesGridTipoTrabalho
																"
																class="btn btn-warning btn-xs text-left"
																(click)="
																	inativarReativarTipoTrabalho(
																		element,
																		true
																	)
																"
															>
																<i
																	class="far fa-play-circle"
																></i>
															</button>
															<button
																[hidden]="
																	visualizar ||
																	!element.ativo
																"
																type="button"
																[disabled]="
																	desabilitaBotoesGridTipoTrabalho
																"
																class="btn btn-warning btn-xs text-left"
																(click)="
																	inativarReativarTipoTrabalho(
																		element,
																		false
																	)
																"
															>
																<i
																	class="far fa-pause-circle"
																></i>
															</button>
															<button
																[hidden]="
																	visualizar
																"
																type="button"
																[disabled]="
																	desabilitaBotoesGridTipoTrabalho
																"
																class="btn btn-danger btn-xs text-left"
																(click)="
																	excluirTipoTrabalho(
																		element
																	)
																"
															>
																<i
																	class="far fa-trash-alt"
																></i>
															</button>
														</div>
													</td>
												</ng-container>

												<tr
													mat-row
													*matRowDef="
														let row;
														columns: displayedTipoTrabalhoColumns
													"
													class="clickable"
													[ngClass]="'bg-light'"
												></tr>
											</table>
											<h5
												[hidden]="!semDadosTipoTrabalho"
												class="table-no-data"
											>
												Nenhum tipo de trabalho
												cadastrado.
											</h5>
										</div>
									</div>
								</div>
							</div>

							<!-- ABA EQUIPE -->
							<div #abaEquipe *ngIf="aba == 1">
								<div class="row">
									<div class="form-group col-md-2">
										<label for="sigla" class="mb-1"
											>Sigla</label
										>
										<input
											[attr.disabled]="true"
											type="text"
											id="sigla"
											class="form-control form-control-sm"
											formControlName="sigla"
											maxlength="10"
											[ngClass]="{
												'is-invalid':
													f.submitted &&
													form.controls['sigla']
														.invalid
											}"
										/>
										<div
											*ngIf="
												f.submitted &&
												form.controls['sigla'].invalid
											"
											class="invalid-feedback"
										>
											<div
												*ngIf="
													form.controls['sigla']
														.errors.required
												"
											>
												Campo obrigatório
											</div>
										</div>
									</div>
									<div class="form-group col-md-7">
										<label for="nome" class="mb-1"
											>Nome</label
										>
										<input
											[attr.disabled]="true"
											type="text"
											id="nome"
											class="form-control form-control-sm"
											formControlName="nome"
											maxlength="100"
											[ngClass]="{
												'is-invalid':
													f.submitted &&
													form.controls['nome']
														.invalid
											}"
										/>
										<div
											*ngIf="
												f.submitted &&
												form.controls['nome'].invalid
											"
											class="invalid-feedback"
										>
											<div
												*ngIf="
													form.controls['nome'].errors
														.required
												"
											>
												Campo obrigatório
											</div>
										</div>
									</div>
									<div class="col-md-3 mb-3">
										<label
											class="mb-1"
											style="font-size: 16px"
											>Ano</label
										>
										<select
											id="selectUnidade"
											[(ngModel)]="selectedYear"
											(change)="onSelectEquipe($event)"
											[ngModelOptions]="{
												standalone: true
											}"
											class="mat-form-ano form-control form-control-sm"
										>
											<option
												*ngFor="
													let item of selectedModelYearsAbaEquipe
												"
												[ngValue]="item"
											>
												{{ item }}
											</option>
										</select>
									</div>
								</div>

								<div class="row">
									<div class="form-group col-md-7">
										<label
											[hidden]="visualizar"
											for="inputAuditorId"
											class="mb-1"
											>Servidor</label
										>
										<div
											class="input-group input-group-sm"
											style="width: 100%"
										>
											<input
												[hidden]="visualizar"
												type="text"
												id="inputAuditorId"
												class="form-control form-control-sm"
												readonly
												[(ngModel)]="
													modelAuditor.nomeAuditor
												"
												[ngModelOptions]="{
													standalone: true
												}"
											/>
											<div class="input-group-append">
												<button
													[hidden]="visualizar"
													type="button"
													class="btn btn-info"
													[disabled]="desabilitaBotoesGridEquipe"
													(click)="
														exibirModalAuditor()
													"
												>
													<i
														class="fas fa-search"
													></i>
												</button>
											</div>
										</div>
									</div>

									<div class="form-group col-md-5">
										<label
											[hidden]="visualizar"
											for="selectCargo"
											class="mb-1"
											>Cargo/Função</label
										>
										<div
											class="input-group input-group-sm"
											style="width: 100%"
										>
											<select
												[hidden]="visualizar"
												[attr.disabled]="
													visualizar ? true : null
												"
												id="selectCargo"
												[(ngModel)]="modelAuditor.cargo"
												[ngModelOptions]="{
													standalone: true
												}"
												class="form-control form-control-sm"
												[disabled]="desabilitaBotoesGridEquipe"
											>
												<option [ngValue]="1">
													Coordenador Titular
												</option>
												<option [ngValue]="2">
													Coordenador Substituindo
												</option>
												<option [ngValue]="3">
													Coordenador Respondendo
												</option>
												<option [ngValue]="4">
													Auditor do Estado
												</option>
											</select>

											<div class="input-group-append">
												<button
													[hidden]="visualizar"
													type="button"
													class="btn btn-success ml-1"
													(click)="
														incluirNovoAuditor(
															$event
														)
													"
													[disabled]="
														visualizar ||
														!modelAuditor.auditorId ||
														!modelAuditor.cargo || desabilitaBotoesGridEquipe
													"
												>
													<i class="fas fa-plus"></i>
													Adicionar
												</button>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<div class="mat-elevation-z1">
											<table
												mat-table
												[dataSource]="
													auditoresDataSource
												"
												class="w-100"
												matSort
												matSortDisableClear
												class="mat-elevation-z8"
												#tabelaAuditores="matSort"
												[hidden]="semDados"
											>
												<tr
													mat-header-row
													*matHeaderRowDef="
														displayedColumns
													"
												></tr>

												<ng-container
													matColumnDef="nomeAuditor"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														mat-sort-header
													>
														Nome
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														{{
															element.nomeAuditor
														}}
													</td>
												</ng-container>

												<ng-container
													matColumnDef="cargo"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														mat-sort-header
													>
														Cargo
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														<span
															*ngIf="
																element.cargo ===
																1
															"
															class="text-xs"
															>Coordenador
															Titular</span
														>
														<span
															*ngIf="
																element.cargo ===
																2
															"
															class="text-xs"
															>Coordenador
															Substituindo</span
														>
														<span
															*ngIf="
																element.cargo ===
																3
															"
															class="text-xs"
															>Coordenador
															Respondendo</span
														>
														<span
															*ngIf="
																element.cargo ===
																4
															"
															class="text-xs"
															>Auditor do
															Estado</span
														>
													</td>
												</ng-container>

												<ng-container
													matColumnDef="ativo"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														mat-sort-header
													>
														Situação
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														<span
															*ngIf="
																element.ativo
															"
															class="badge bg-success text-xs"
															>Ativo</span
														>
														<span
															*ngIf="
																!element.ativo
															"
															class="badge bg-gray-dark text-xs"
															>Inativo</span
														>
													</td>
												</ng-container>

												<ng-container
													cdkColumnDef="actions"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
													></th>
													<td
														mat-cell
														*matCellDef="
															let element;
															let i = index
														"
														class="block"
													>
														<div class="btn-group">
															<button
																[hidden]="
																	visualizar
																"
																type="button"
																class="btn btn-info btn-xs text-left"
																[disabled]="desabilitaBotoesGridEquipe"
																(click)="
																	editarAuditor(
																		element,
																		i
																	)
																"
															>
																<i
																	class="fas fa-pen"
																></i>
															</button>
															<button
																[hidden]="
																	visualizar ||
																	element.ativo
																"
																type="button"
																class="btn btn-warning btn-xs text-left"
																[disabled]="desabilitaBotoesGridEquipe"
																(click)="
																	inativarReativarAuditor(
																		element,
																		i,
																		true
																	)
																"
															>
																<i
																	class="far fa-play-circle"
																></i>
															</button>
															<button
																[hidden]="
																	visualizar ||
																	!element.ativo
																"
																type="button"
																class="btn btn-warning btn-xs text-left"
																[disabled]="desabilitaBotoesGridEquipe"
																(click)="
																	inativarReativarAuditor(
																		element,
																		i,
																		false
																	)
																"
															>
																<i
																	class="far fa-pause-circle"
																></i>
															</button>
															<button
																[hidden]="
																	visualizar
																"
																type="button"
																class="btn btn-danger btn-xs text-left"
																[disabled]="desabilitaBotoesGridEquipe"
																(click)="
																	excluirAuditor(
																		element
																	)
																"
															>
																<i
																	class="far fa-trash-alt"
																></i>
															</button>
														</div>
													</td>
												</ng-container>

												<tr
													mat-row
													*matRowDef="
														let row;
														columns: displayedColumns
													"
													class="clickable"
													[ngClass]="'bg-light'"
												></tr>
											</table>
											<h5
												[hidden]="!semDados"
												class="table-no-data"
											>
												Nenhum auditor cadastrado.
											</h5>
										</div>
									</div>
								</div>
							</div>

							<div #abaDisponibilidade *ngIf="aba == 2">
								<div class="card-body">
									<div class="row col-md-12">
										<div class="col-md-2 mb-3">
											<label for="sigla" class="mb-1"
												>Sigla</label
											>
											<input
												[attr.disabled]="true"
												type="text"
												id="sigla"
												class="form-control form-control-sm"
												formControlName="sigla"
												maxlength="10"
												[ngClass]="{
													'is-invalid':
														f.submitted &&
														form.controls['sigla']
															.invalid
												}"
											/>
										</div>
										<div class="col-md-7 mb-3">
											<label for="nome" class="mb-1"
												>Nome</label
											>
											<input
												[attr.disabled]="true"
												type="text"
												id="nome"
												class="form-control form-control-sm"
												formControlName="nome"
												maxlength="100"
												[ngClass]="{
													'is-invalid':
														f.submitted &&
														form.controls['nome']
															.invalid
												}"
											/>
										</div>

										<div class="col-md-3 mb-3">
											<label
												class="mb-1"
												style="font-size: 16px"
												>Ano</label
											>
											<select
												id="selectUnidade"
												[(ngModel)]="selectedYear"
												(change)="
													onSelectTipoTrabalho($event)
												"
												[ngModelOptions]="{
													standalone: true
												}"
												class="mat-form-ano form-control form-control-sm"
											>
												<option
													*ngFor="
														let item of selectedModelYears
													"
													[ngValue]="item"
												>
													{{ item }}
												</option>
											</select>
										</div>
									</div>
								</div>
								<div class="card-body">
									<label
										for="input5"
										class="tableDistribuicao"
										>Disponibilidade por Tipo de
										Trabalho(%)</label
									>

									<table
										class="tableDistribuicao"
										mat-table
										matSort
                    matSortDisableClear
                    matSortActive="nome"
										#tabelaDisponibilidade="matSort"
										[dataSource]="disponibilidadeDataSource"
									>
										<ng-container
											[matColumnDef]="column"
											*ngFor="
												let column of columnsDistribuicao
											"
										>
											<th
												mat-sort-header
												mat-header-cell
												*matHeaderCellDef
											>
												{{ formatarNome(column) }}
											</th>
											<td
												mat-cell
												*matCellDef="let element"
												[className]="
													'distribuicao-' +
													element[column + '_class']
												"
											>
                        <ng-container>
                          <div
                            class="text-distribuicao"
                          >
                            {{ element[column] }}
                          </div>
                        </ng-container>
											</td>
										</ng-container>

										<tr
											mat-header-row
											*matHeaderRowDef="
												columnsDistribuicao
											"
											class="bg-light"
										></tr>
										<tr
											mat-row
											*matRowDef="
												let row;
												columns: columnsDistribuicao
											"
										></tr>
									</table>
									<h5
										[hidden]="!disponibilidadeSemDados"
										class="table-no-data"
									>
										Não existem dados de Disponibilidade por
										Tipo de Trabalho para a Coordenação e o
										Ano selecionado.
									</h5>
								</div>
								<div class="card-body">
									<label
										for="input5"
										class="tableDistribuicao"
										>Dispensa</label
									>
									<table
										class="tableDispensa"
										mat-table
										matSort
										#tabelaDispensa="matSort"
										[dataSource]="dispensaDataSource"
									>
										<ng-container
											[matColumnDef]="column"
											*ngFor="
												let column of columnsDispensa
											"
										>
											<th
												mat-sort-header
												mat-header-cell
												*matHeaderCellDef
											>
												{{ formatarNome(column) }}
											</th>
											<td
												mat-cell
												*matCellDef="let element"
												[className]="
													'distribuicao-' +
													element[column + '_class']
												"
											>
												<ng-container>
													<div
														class="text-distribuicao"
													>
														{{ element[column] }}
													</div>
												</ng-container>
											</td>
										</ng-container>

										<tr
											mat-header-row
											*matHeaderRowDef="columnsDispensa"
											class="bg-light"
										></tr>
										<tr
											mat-row
											*matRowDef="
												let row;
												columns: columnsDispensa
											"
										></tr>
									</table>
									<h5
										[hidden]="!dispensaSemDados"
										class="table-no-data"
									>
										Não existem dados de Dispensa para a
										Coordenação e o Ano selecionado!
									</h5>
								</div>
							</div>
						</div>

						<div class="card-footer">

								<div class="btn-group" [hidden]="visualizar || aba == 2">
									<button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
									<i class="far fa-save"></i> Salvar
								</button>
								<button
									type="button"
									class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon"
									data-toggle="dropdown"
									aria-expanded="false"
								></button>
								<div class="dropdown-menu" role="menu">
									<a
										class="dropdown-item"
										(click)="salvarDados(false)"
										>Salvar</a
									>
									<a
										class="dropdown-item"
										(click)="salvarDados(true)"
										>Salvar e fechar</a
									>
								</div>
							</div>

							<button
								[hidden]="visualizar"
								type="button"
								class="btn btn-default btn-sm"
								(click)="onBack()"
							>
								<i class="fas fa-times"></i> Cancelar
							</button>

							<button
								[hidden]="!visualizar"
								type="button"
								class="btn btn-default btn-sm"
								(click)="onBack()"
							>
								<i class="fas fa-times"></i> Sair
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
