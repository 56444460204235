import { Component, Input, OnInit, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ISubtipoAuditoriaModel } from 'src/app/models/subtipo-auditoria.model';
import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';
import { ISolicitacaoTecnicaUnidadeGestoraModel } from './../../../models/solicitacao-tecnica.model';
import { ModalIncluirUgComponentComponent } from './modal-incluir-ug-component/modal-incluir-ug-component.component';
import { IResponsavelModel } from 'src/app/models/responsaveis.model';
import { IPlanejamentoSubtipoAuditoriaModel } from 'src/app/models/planejamento-subtipo-auditoria.model';
import Swal from 'sweetalert2';

type mainType = IUnidadeGestoraModel | ISolicitacaoTecnicaUnidadeGestoraModel | PlanejamentoUgSubtipo;
export interface IPlanejamentoUnidadeGestoraModel{
  unidadeGestora?: IUnidadeGestoraModel;
  responsavel?: IResponsavelModel;
}
export interface PlanejamentoUgSubtipo {
  planejamentoId: number,
  unidadeGestora: IUnidadeGestoraModel,
  subtipoAuditoria: ISubtipoAuditoriaModel
}

@Component({
  selector: 'app-unidade-gestora',
  templateUrl: './unidade-gestora.component.html',
  styleUrls: ['./unidade-gestora.component.scss']
})
export class UnidadeGestoraComponent implements OnInit, OnChanges {

  public dataSource = new MatTableDataSource<mainType>([]);

  @Input() public visualizar: boolean = false;
  @Input() public hiddenEdicao: boolean = false;
  @Input() public displayedColumns: string[] = ['ug', 'responsavel', 'actions'];
  @Input() public subtipoSelecionado:  IPlanejamentoSubtipoAuditoriaModel[] = [] as  IPlanejamentoSubtipoAuditoriaModel[];
  @Input() public unidadesGestoras: IUnidadeGestoraModel[] = [] as IUnidadeGestoraModel[];
  @Input() public planejamentoUgSubtipo: PlanejamentoUgSubtipo[] = [] as PlanejamentoUgSubtipo[];
  @Input() public ugType: string = '';
  @Output() public dados: EventEmitter<any> = new EventEmitter<any>();
  @Output() public excluir: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("TableOnePaginator", { static: true }) tableOnePaginator: MatPaginator;
	@ViewChild("tabela1", { static: true }) tabela1: MatSort;

  constructor(public matDialog: MatDialog) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.planejamentoUgSubtipo.currentValue !== undefined) {
      this.dataSource.data = changes.planejamentoUgSubtipo.currentValue
      this.dataSource.sort = this.tabela1;
      this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
      this.dataSource.paginator = this.tableOnePaginator;
    }
  }

  ngOnInit(): void {
  }

  public naoTemJustificativa(planejamento: IPlanejamentoSubtipoAuditoriaModel[]): IPlanejamentoSubtipoAuditoriaModel[] {
    return planejamento.filter(a => !a.justificativa)
  }

  public modalIncluirUg() {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component-ug";
		dialogConfig.width = "70vw";
		dialogConfig.maxHeight = "auto";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		dialogConfig.data = {
      displayedColumns: this.displayedColumns,
      ugType: this.ugType,
      data: {
        subtipoSelecionado: this.naoTemJustificativa(this.subtipoSelecionado),
        unidadesGestoras: this.unidadesGestoras,
        planejamentoUgSubtipo: this.planejamentoUgSubtipo
      }
    };

		const modal = this.matDialog.open(
      ModalIncluirUgComponentComponent,
			dialogConfig
      );
      modal.afterClosed().subscribe((data: any) => {
      if (data) {
        data.forEach(element => {
          this.dataSource.data = [...this.dataSource.data, element] ;
        });
        this.dados.emit(this.dataSource.data);
      }
    });
	}
  public excluirUg(ugId: number, subtipoId: number) {
    Swal.fire({
      title: "Excluir Registro",
      text: "Tem certeza que deseja excluir esse Registro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((res) => {
      if (res.value) {
        this.excluir.emit({ugId, subtipoId});
      }})

	}

  public sortingDataAccessor = (item, property) => {
		switch (property) {
			case "subtipo":
				return  item?.subtipoAuditoria?.subTipoAuditoria?.nome ?? item?.subtipoAuditoria?.nome;
			case "responsavel":
				return item?.responsavel?.nome;
			case "ug":
				return item?.unidadeGestora?.sigla;
			default:
				return item[property];
		}
	};

}
