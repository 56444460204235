import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-cancelar',
  templateUrl: './modal-cancelar.component.html',
})
export class ModalCancelarModeloQuestionario implements OnInit {
  ngOnInit(): void {}

  constructor(private dialogRef: MatDialogRef<ModalCancelarModeloQuestionario>) {}

  Sim() {
    this.dialogRef.close(true);
  }

  Nao() {
    this.dialogRef.close(false);
  }
}
