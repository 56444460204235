<div class="card-body p-0">
  <div class="mat-elevation-z4">
    <mat-toolbar class="table-header">
      <mat-toolbar-row>
        <button type="button" class="btn btn-success btn-sm mr-1" [disabled]="visualizar" *ngIf="!visualizar" (click)="incluirSolicitacaoTecnica('Incluir',false)">
          <i class="far fa-file"></i> Novo
        </button>
        <button type="button" class="btn btn-primary btn-sm mr-1" [disabled]="!selectedModel || permitido === false || visualizar" *ngIf="!visualizar" (click)="incluirSolicitacaoTecnica('Editar', false, selectedModel)">
          <i class="fas fa-pen"></i> Editar
        </button>
        <button type="button" class="btn btn-warning btn-sm mr-1" [disabled]="!selectedModel || cancelada === true || visualizar" *ngIf="!visualizar" (click)="cancelarSolicitacao(selectedModel?.id)">
          <i class="fas fa-ban"></i> Cancelar
        </button>
        <button type="button"  class="btn btn-danger btn-sm mr-1" [disabled]="!selectedModel || permitido === false || visualizar" *ngIf="!visualizar" (click)="excluir(selectedModel?.id)">
          <i class="fa fa-trash"></i> Excluir
        </button>
        <button type="button" class="btn btn-info btn-sm mr-1" [disabled]="!selectedModel" (click)="incluirSolicitacaoTecnica('Visualizar', true, selectedModel)">
          <i class="far fas fa-eye"></i> Visualizar
        </button>
        <button type="button" class="btn btn-info btn-sm mr-1" [disabled]="!selectedModel" (click)="imprimir(selectedModel)">
          <i class="far fas fa-print"></i> Imprimir
        </button>
        <button type="button" class="btn btn-secondary btn-sm mr-1" [disabled]="!selectedModel || selectedModel?.status?.codigo !== 3 || visualizar" *ngIf="!visualizar" (click)="habilitarResposta(selectedModel?.id, selectedModel?.prazo)">
          <i class="far fa-play-circle"></i> Hablitar Resposta
        </button>

      </mat-toolbar-row>
    </mat-toolbar>
    <table [hidden]="semDados" mat-table [dataSource]="dataSource" class="mat-elevation-z1 w-100 " matSort matSortDisableClear>
      <ng-container matColumnDef="selecao">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary" 
            (change)="selecionar(element)" [checked]="selectedModel?.id === element.id">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="numero">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>N° Solicitação <br>Técnica</th>
        <td mat-cell *matCellDef="let element">{{ element?.numero ? element?.numero : '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="assunto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Assunto</th>
        <td mat-cell *matCellDef="let element">
          <a class="e2e-inner-html-bound" [innerHTML]="element?.assunto"></a>
        </td>
      </ng-container>

      <ng-container matColumnDef="prazo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Prazo</th>
        <td mat-cell *matCellDef="let element">{{ element?.prazo | date: 'shortDate' }}</td>
      </ng-container>

      <ng-container matColumnDef="responsavel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Responsável</th>
        <td mat-cell *matCellDef="let element">{{ element?.quantidadeResponsaveis }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element?.status?.codigo === 1" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
          <span *ngIf="element?.status?.codigo === 2" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
          <span *ngIf="element?.status?.codigo === 3" class="badge bg-primary text-xs">{{element?.status?.descricao}}</span>
          <span *ngIf="element?.status?.codigo === 4" class="badge bg-danger text-xs">{{element?.status?.descricao}}</span>
          <span *ngIf="element?.status?.codigo === 5" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
          <span *ngIf="element?.status?.codigo === 6" class="badge bg-info text-xs">{{element?.status?.descricao}}</span>
          <span *ngIf="element?.status?.codigo === 7" class="badge bg-primary text-xs">{{element?.status?.descricao}}</span>
          <span *ngIf="element?.status?.codigo === 8" class="badge bg-primary text-xs">{{element?.status?.descricao}}</span>
          <span *ngIf="element?.status?.codigo === 9" class="badge bg-secondary text-xs">{{element?.status?.descricao}}</span>
          <span *ngIf="element?.status?.codigo === 10" class="badge bg-secondary text-xs">{{element?.status?.descricao}}</span>
          <span *ngIf="element?.status?.codigo === 11" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
          <span *ngIf="element?.status?.codigo === 12" class="badge bg-danger text-xs">{{element?.status?.descricao}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="resposta">
        <th mat-header-cell *matHeaderCellDef class="text-center">Resposta</th>

        <td mat-cell *matCellDef="let element">
          <div class="w-100 d-flex justify-content-center">
            <button class="icon-btn btn btn-info btn-xs" (click)="modalVisualizarResposta(element.id)">
              <i class="far fas fa-eye"></i>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selecionar(row)" class="clickable"
        [ngClass]="{'bg-light': selectedModel?.id === row.id}"></tr>
    </table>
    <h5 [hidden]="!semDados" class="table-no-data">
			Nenhum registro encontrado.
		</h5>
    <mat-paginator [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons (page)="selectedModel= null">
    </mat-paginator>

  </div>
</div>
