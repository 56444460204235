import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { toIntlNumberString } from 'src/app/core/helpers/number.helper';
import { IPaaCriterioRespostaModel } from 'src/app/models/paa-criterio-resposta.model';

@Component({
  selector: 'app-modal-importar-arquivo-criterio-paa',
  templateUrl: './modal-importar-arquivo-criterio-paa.component.html',
  styleUrls: ['./modal-importar-arquivo-criterio-paa.component.scss']
})
export class ModalImportarArquivoCriterioPaaComponent {
  public conteudoArquivo: string;
  public respostas = [] as IPaaCriterioRespostaModel[];
  public conteudoValido: boolean;

  constructor(
    private dialogRef: MatDialogRef<ModalImportarArquivoCriterioPaaComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.respostas = data.respostas as IPaaCriterioRespostaModel[];
  }

  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsText(file);
      reader.onload = () => {
        this.conteudoArquivo = reader.result.toString();
        this.lerValidarArquivo();
      };
    }
  }

  public lerValidarArquivo() {
    if (!this.conteudoArquivo) {
      this.conteudoValido = false;
      return;
    }

    let erros = 0;
    this.conteudoArquivo.split('\n').forEach(l => {
      if (l.trim().length > 0 && l.split(';').length !== 2) {
        erros++;
      } else {
        const resposta = this.respostas.find(r => r.unidadeGestora?.codigoUg?.toString() === l.split(';')[0]);
        if (resposta) {
          const valor = parseFloat(toIntlNumberString(l.split(';')[1]));
          if (isNaN(valor)) {
            erros++;
          } else {
            resposta.valorNumerico = valor;
          }
        }
      }
    });

    this.conteudoValido = erros === 0;
  }

  public concluir() {
    this.dialogRef.close(this.respostas);
  }

  public cancelar() {
    this.dialogRef.close();
  }
}

