import { IRecomendacaoModel } from './../../models/recomendacao.model';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as cloneDeep from 'lodash/cloneDeep';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { IQuestaoModel } from 'src/app/models/questao.model';
import { IQuestaoProcedimentoAuditoriaModel } from 'src/app/models/questao-procedimento-auditoria.model';
import { IConstatacaoModel } from 'src/app/models/constatacao.model';
import { IMatrizPlanejamentoModel } from 'src/app/models/matriz-planejamento.model';
import { IMatrizPlanejamentoQuestaoModel } from 'src/app/models/matriz-planejamento-questao.model';
import { IConstatacaoRecomendacaoModel } from 'src/app/models/constatacao-recomendacao.model';
import { ISubtipoAuditoriaMatrizPlanejamentoModel } from 'src/app/models/subtipo-auditoria-matriz-planejamento.model';
import { ISubtipoAuditoriaModel } from 'src/app/models/subtipo-auditoria.model';
import { IQuestaoConstatacaoModel } from 'src/app/models/questao-constatacao.model';
import { RecomendacaoService } from 'src/app/services/recomendacao.service';
import { ConstatacaoService } from 'src/app/services/constatacao.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { ModalRecomendacaoComponent } from './modal-recomendacao/modal-recomendacao.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-constatacao-form',
  templateUrl: './constatacao-form.component.html',
  styleUrls: ['./constatacao-form.component.scss']
})
export class ConstatacaoFormComponent extends BaseFormComponent implements OnInit {
  public model: IConstatacaoModel = {} as IConstatacaoModel;
  public modelConstatacaoRecomendacao: IConstatacaoRecomendacaoModel = {} as IConstatacaoRecomendacaoModel;
  public recomendacaoDataSource = new MatTableDataSource<IConstatacaoRecomendacaoModel>([]);
  public modelQuestao: IQuestaoModel = {} as IQuestaoModel;
  public modelMatrizPlanejamento: IMatrizPlanejamentoModel = {} as IMatrizPlanejamentoModel;
  public modelSubtipoAuditoria: ISubtipoAuditoriaModel = {} as ISubtipoAuditoriaModel;
  public listaProcedimentoAuditoria = new MatTableDataSource<IQuestaoProcedimentoAuditoriaModel>([]);
  public listaQuestao = new MatTableDataSource<IMatrizPlanejamentoQuestaoModel>([]);
  public listaConstatacao = [] as IQuestaoConstatacaoModel[];
  public listaMatrizPlanejamento = new MatTableDataSource<ISubtipoAuditoriaMatrizPlanejamentoModel>([]);
  public listaRecomendacoes: any[] = [] as any[];
  public rotaPrefixoSubtipoAuditoria = undefined;
  public rotaPrefixoMatrizPlanejamento = undefined;
  public semDados = true;

  public isModal: boolean = false;

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
    contaGovernador: new FormControl(false, Validators.required),
    contaOrdenador: new FormControl(false, Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private recomendacaoService: RecomendacaoService,
    private constatacaoService: ConstatacaoService,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<ConstatacaoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(route, toastr,  router, localeService, matDialog);
    if(data?.isModal) {
      this.isModal = data?.isModal;
    }
    if (this.novoRegistro) {
      this.titulo = 'Nova Constatação';
    } 
  }


  config: AngularEditorConfig = {
    editable: !this.visualizar,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async ngOnInit() {
    this.buscarRecomendacoes();
    this.state();
    try {
      if (!this.novoRegistro) {
        const res = await this.constatacaoService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;

          if (this.visualizar) {
            this.titulo = 'Visualização de Constatação - ' + this.model.nome;
            this.form.controls['contaGovernador'].disable();
            this.form.controls['contaOrdenador'].disable();
          } else {
            this.titulo = 'Editar Constatação - ' + this.model.nome;
          }

          this.recomendacaoDataSource = new MatTableDataSource<IConstatacaoRecomendacaoModel>
          (
            this.model.constatacaoRecomendacao
          );
          this.recomendacaoDataSource._updateChangeSubscription();
          this.semDados = this.recomendacaoDataSource.filteredData.length === 0;
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
          this.router.navigate(['/constatacao']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
      this.router.navigate(['/constatacao']);
    }
  }

  public async state() {
    // model questao
    this.modelQuestao = window.history.state.model;
    // model Subtipo Auditoria
    this.modelSubtipoAuditoria = window.history.state.modelSubtipoAuditoria;
    // model matriz planejamento
    this.modelMatrizPlanejamento = window.history.state.modelMatrizPlanejamento;
    // lista procedimento
    this.listaProcedimentoAuditoria = window.history.state.listaProcedimentoAuditoria;
    // lista questão
    this.listaQuestao = window.history.state.listaQuestao;
    // lista constatacao
    this.listaConstatacao = window.history.state.listaConstatacao;
    // lista Matriz Planejamento
    this.listaMatrizPlanejamento = window.history.state.listaMatrizPlanejamento;
    // rota
    if (window.history.state.rotaPrefixoSubtipoAuditoria) {
      this.rotaPrefixoSubtipoAuditoria = window.history.state.rotaPrefixoSubtipoAuditoria;
    }
    if (window.history.state.rotaPrefixoMatrizPlanejamento) {
      this.rotaPrefixoMatrizPlanejamento = window.history.state.rotaPrefixoMatrizPlanejamento;
    }
  }

  public async buscarRecomendacoes() {
    const res = await this.recomendacaoService.obter('', true);
    if(res.sucesso) this.listaRecomendacoes = res.dados;
  }

  public async onSubmit(salvarEFechar: boolean = false) {
    if (this.form.invalid) {
        this.exibirMensagemAlerta('Formulário inválido!');
        return;
    }

    this.atualizarModel(this.form.value);
    this.model.constatacaoRecomendacao = this.recomendacaoDataSource.data;

    try {
        let res: IBaseModel<IConstatacaoModel> = null;

        if (!this.novoRegistro) {
            this.model.constatacaoRecomendacao = [];
            res = await this.constatacaoService.atualizar(this.model);
            this.model.id = res.dados.id;
            this.vincularConstatacaoRecomendacao(this.id);
        } else {
            res = await this.constatacaoService.inserir(this.model);
            this.model.id = res.dados.id;
            this.vincularConstatacaoRecomendacao(res.dados.id);
        }

        if (res.sucesso) {
            this.exibirMensagemSucesso('Registro salvo com sucesso!');
            if (salvarEFechar) {
                this.router.navigate(['/constatacao']);
            }else
            if(this.novoRegistro){
              this.router.navigate([`/constatacao/${res.dados.id}`]);
              await this.ngOnInit();
            }
        } else {
            const validacoes = this.montarMensagensValidacao(res);
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'warning',
                text: res.mensagem.descricao,
                title: validacoes,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            });
        }

        if (!this.modelQuestao || this.isModal) {
            // Se não houver questão ou se for um modal, fechar o modal
            this.dialogRef.close();
        } else {
            // Caso contrário, navegar para a página de questões
            this.listaConstatacao.push({ constatacaoId: this.model.id, constatacao: this.model, questaoId: this.modelQuestao.id } as IQuestaoConstatacaoModel);
            this.router.navigate(['/questao/' + this.modelQuestao.id], {
                state: {
                    model: this.modelQuestao,
                    modelMatrizPlanejamento: this.modelMatrizPlanejamento,
                    modelSubtipoAuditoria: this.modelSubtipoAuditoria,
                    listaProcedimentoAuditoria: this.listaProcedimentoAuditoria,
                    listaQuestao: this.listaQuestao,
                    listaConstatacao: this.listaConstatacao,
                    listaMatrizPlanejamento: this.listaMatrizPlanejamento,
                    rotaPrefixoSubtipoAuditoria: this.rotaPrefixoSubtipoAuditoria,
                    rotaPrefixoMatrizPlanejamento: this.rotaPrefixoMatrizPlanejamento
                }
            });
        }
    } catch (err) {
        this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public vincularConstatacaoRecomendacao(id: number){
    this.constatacaoService.vincularConstatacaoRecomendacao(id, { recomendacoes: this.recomendacaoDataSource.data.map(item => item.recomendacaoId)})
  }

  public incluirRecomendacao() {
    if (this.recomendacaoDataSource.data.findIndex(a => a.recomendacaoId === this.modelConstatacaoRecomendacao.recomendacaoId) >= 0) {
      this.exibirMensagemAlerta('Recomendação já cadastrado nessa constatação');
      return;
    }

    this.recomendacaoDataSource.data.push(cloneDeep(this.modelConstatacaoRecomendacao));
    this.recomendacaoDataSource._updateChangeSubscription();
    this.modelConstatacaoRecomendacao = {} as IConstatacaoRecomendacaoModel;
    this.semDados = false;
  }

  public desvincularRecomendacao(element: IRecomendacaoModel, index: number) {
    if(element?.id) {
      this.constatacaoService.desvincularConstatacaoRecomendacao(this.id, element.id)
      .then((res) => {
        res.sucesso ? this.exibirMensagemSucesso(res.mensagem.descricao) : this.exibirMensagemErro(res.mensagem.descricao);
        this.ngOnInit();
      })
    }
    this.recomendacaoDataSource.data.splice(index, 1);
    this.recomendacaoDataSource._updateChangeSubscription();
    this.semDados = this.recomendacaoDataSource.filteredData.length === 0;
  }

  public async visualizarRecomendacao(ind: number) {
    try {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = ind;
      this.matDialog.open(ModalRecomendacaoComponent, dialogConfig);

    } catch (err) {
      this.exibirMensagemErro(err);
    }
  }

  public async exibirModalRecomendacao() {
    try {
      const res = await this.recomendacaoService.obter('', true);
      if (res.sucesso) {
        const dadosFiltrados = res.dados.filter(recomendacao => {
          const encontrado = this.recomendacaoDataSource.data?.find(item => item.recomendacaoId === recomendacao.id)?.recomendacaoId;
          if(encontrado) {
            return false;
          }
          return true;
        });
        const modal = this.exibirModal('Selecionar Recomendação', dadosFiltrados);
        modal.afterClosed().subscribe(data => {
          if (data) {
            this.modelConstatacaoRecomendacao.recomendacaoId = data.id;
            this.modelConstatacaoRecomendacao.recomendacao = data;
          }
        }
        );
      } else {
        this.exibirMensagemAlerta('Ocorreu um erro ao busca recomendações');
      }
    } catch (err) {
      this.exibirMensagemErro('Ocorreu um erro ao busca recomendações');
    }
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    if(this.isModal) {
      this.dialogRef.close();
      return;
    }
    if (this.modelQuestao) {
      this.router.navigate(['/questao/novo'], {
        state: {
          model: this.modelQuestao,
          modelMatrizPlanejamento: this.modelMatrizPlanejamento,
          modelSubtipoAuditoria: this.modelSubtipoAuditoria,
          listaProcedimentoAuditoria: this.listaProcedimentoAuditoria,
          listaConstatacao: this.listaConstatacao,
          listaQuestao: this.listaQuestao,
          listaMatrizPlanejamento: this.listaMatrizPlanejamento,
          rotaPrefixoSubtipoAuditoria: this.rotaPrefixoSubtipoAuditoria,
          rotaPrefixoMatrizPlanejamento: this.rotaPrefixoMatrizPlanejamento
        }
      });
      } else {
        this.router.navigate(['/constatacao']);
      }
  }

}
