<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i>
							Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item active">Fluxos de Aprovação</li>
				</ol>
			</div>
		</div>
	</div>
</div>

<div class="content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card card-outline card-lightblue">
					<div class="card-header">
						<h3 class="card-title mb-0">
							<i class="fas fa-table pr-2"></i>
							Fluxos de Aprovação
						</h3>
					</div>

					<div class="card-body p-2">
						<div class="mat-elevation-z4">
							<mat-toolbar class="table-header">
								<mat-toolbar-row>
									<button
										type="button"
										class="btn btn-success btn-sm mr-1"
										(click)="novo()"
									>
										<i class="far fa-file"></i> Novo
									</button>
									<button
										type="button"
										class="btn btn-primary btn-sm mr-1"
										[disabled]="!selectedModel"
										(click)="editar(selectedModel.id)"
										[hidden]="!permissoes.editar"
									>
										<i class="far fas fa-pen"></i> Editar
									</button>
									<button
										type="button"
										class="btn btn-warning btn-sm mr-1"
										mwlConfirmationPopover
										popoverTitle="Ativar"
										mwlConfirmationPopover
										popoverTitle="Reativar"
										popoverMessage="Tem certeza que deseja
                  reativar esse registro?"
										confirmButtonType="primary"
										placement="bottom"
										confirmText="Sim"
										cancelText="Não"
										[hidden]="!selectedModel || selectedModel.ativo"
										(confirm)="reativar(selectedModel.id)"
									>
										<i class="far fa-play-circle"></i>
										Reativar
									</button>
									<button
										type="button"
										class="btn btn-warning btn-sm mr-1"
										[disabled]="!selectedModel?.ativo"
										mwlConfirmationPopover
										popoverTitle="Inativar"
										popoverMessage="Tem certeza que deseja
                  inativar esse registro?"
										confirmButtonType="primary"
										placement="bottom"
										confirmText="Sim"
										cancelText="Não"
										[hidden]="(selectedModel && !selectedModel.ativo) " [disabled]="!selectedModel?.ativo"
										(confirm)="inativar(selectedModel.id)"
									>
										<i class="far fa-pause-circle"></i>
										Inativar
									</button>
									<button
										type="button"
										class="btn btn-danger btn-sm mr-1"
										mwlConfirmationPopover
										popoverTitle="Excluir"
										popoverMessage="Deseja realmente excluir o Fluxo?"
										placement="bottom"
										confirmText="Sim"
										confirmButtonType="primary"
										cancelText="Não"
										(confirm)="excluir(selectedModel.id)"
									>
										<i class="fa fa-trash"></i>
										Excluir
									</button>
									<button
										type="button"
										class="btn btn-info btn-sm mr-1"
										(click)="visualizar(selectedModel.id)"
									>
										<i class="far fas fa-eye"></i>
										Visualizar
									</button>
									<span class="fill-remaining-space"></span>
									<div
										class="input-group input-group-sm"
										style="width: 200px"
									>
										<input
											type="text"
											name="table_search"
											class="form-control float-right"
											(keyup.enter)="buscar()"
											placeholder="Pesquisar por nome"
											[(ngModel)]="searchNome"
										/>

										<div class="input-group-append">
											<button
												type="button"
												class="btn btn-info"
												(click)="buscar()"
											>
												<i class="fas fa-search"></i>
											</button>
										</div>
									</div>
								</mat-toolbar-row>
							</mat-toolbar>
							<table
								mat-table
								[dataSource]="dataSource"
								class="w-100"
								matSort
								matSortDisableClear
								class="mat-elevation-z8"
								matSortActive="id"
								matSortDirection="asc"
								[hidden]="semDados"
							>
								<tr
									mat-header-row
									*matHeaderRowDef="displayedColumns"
								></tr>

								<ng-container matColumnDef="selecao">
									<th mat-header-cell *matHeaderCellDef></th>
									<td mat-cell *matCellDef="let element">
										<mat-checkbox
											(click)="$event.stopPropagation()"
											color="primary"
											(change)="
												selectedModel = $event.checked
													? element
													: null
											"
											[checked]="
												selectedModel?.id === element.id
											"
										>
										</mat-checkbox>
									</td>
								</ng-container>

								<ng-container matColumnDef="id">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										Id
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.id }}
									</td>
								</ng-container>

								<ng-container matColumnDef="nome">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
									Documento de Trabalho
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.nome }}
									</td>
								</ng-container>

								<ng-container matColumnDef="ativo">
									<th mat-header-cell *matHeaderCellDef  mat-sort-header> Situação </th>
									<td mat-cell *matCellDef="let element">
									  <span *ngIf="element.ativo" class="badge bg-success text-xs">Ativo</span>
									  <span *ngIf="!element.ativo" class="badge bg-gray-dark text-xs">Inativo</span>
									</td>
								  </ng-container>
								  
								<tr
									mat-row
									*matRowDef="
										let row;
										columns: displayedColumns
									"
									(click)="selecionar(row)"
									class="clickable"
									[ngClass]="{
										'bg-light': selectedModel?.id === row.id
									}"
								></tr>
							</table>
							<h5 [hidden]="!semDados" class="table-no-data">
								Sem dados a exibir
							</h5>
							<mat-paginator
								[pageSizeOptions]="[5, 10, 20]"
								showFirstLastButtons
								(page)="selectedModel = null"
							>
							</mat-paginator>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
