import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { IRolResponsaveisModel } from 'src/app/models/rol-responsaveis.model';
import { AuthService } from 'src/app/services/auth.service';
import { RolResponsaveisService } from 'src/app/services/rol-responsaveis.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-imprimir-rol-responsaveis',
  templateUrl: './imprimir-rol-responsaveis.component.html',
  styleUrls: ['./imprimir-rol-responsaveis.component.scss'],
})
export class ImprimirRolResponsaveisComponent extends BaseListComponent {
  public dataHoje = new Date();
  public dataSource = new MatTableDataSource<IRolResponsaveisModel>([]); 
  public displayedColumns: string[] = [
    'codigoUg', 
    'nome',
    'dataInicio',
    'dataFim',
    'quantidadeResponsaveis',
    'ativo',
  ];

  public searchNome: string;
  public searchAtivo = true;
  public dataInicio: Date;
  public dataFim: Date;

  constructor(
    private rolResponsaveisService: RolResponsaveisService, 
    private router: Router,
    public authService: AuthService
  ) {
    super();
    this.buscar();
  }


  public buscar() {

    this.rolResponsaveisService
      .obter(this.searchNome, this.searchAtivo, this.dataInicio, this.dataFim, true)
      .then((res) => {
        this.dataSource = new MatTableDataSource<IRolResponsaveisModel>(res.dados);
        this.dataSource._updateChangeSubscription();
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: 'error',
          title: 'Atenção',
          text: err.mensagem.descricao,
        });
      })
  }

  print(): void {
    window.print();
  }

  public cancelar() {
    this.router.navigate(['/rolresponsaveis']);
  }
}
