import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuditorCoordenacaoModel } from '../models/auditor-coordenacao.model';
import { IBaseModel } from '../models/base.model';
import { ITipoAuditoriaModel } from '../models/tipo-auditoria.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class TipoAuditoriaService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<ITipoAuditoriaModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<ITipoAuditoriaModel[]>>(`${this.apiBaseUrl}/tipoauditoria`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<ITipoAuditoriaModel>> {
    return this.httpClient
      .get<IBaseModel<ITipoAuditoriaModel>>(`${this.apiBaseUrl}/tipoauditoria/${id}`)
      .toPromise();
  }

  public obterAtivos(): Promise<IBaseModel<ITipoAuditoriaModel[]>> {
    return this.httpClient
      .get<IBaseModel<ITipoAuditoriaModel[]>>(`${this.apiBaseUrl}/tipoauditoria/ativos`)
      .toPromise();
  }

  public async obterCoordenadorTipoAuditoria(id: number): Promise<IBaseModel<IAuditorCoordenacaoModel>> {
    return this.httpClient
      .get<IBaseModel<IAuditorCoordenacaoModel>>(`${this.apiBaseUrl}/tipoauditoria/${id}/coordenador`)
      .toPromise();
  }

  public async obterAuditoresCoordenacaoTipoAuditoria(id: number): Promise<IBaseModel<IAuditorCoordenacaoModel[]>> {
    return this.httpClient
      .get<IBaseModel<IAuditorCoordenacaoModel[]>>(`${this.apiBaseUrl}/tipoauditoria/${id}/auditores`)
      .toPromise();
  }

  public async inserir(data: ITipoAuditoriaModel): Promise<IBaseModel<ITipoAuditoriaModel>> {
    return this.httpClient
      .post<IBaseModel<ITipoAuditoriaModel>>(`${this.apiBaseUrl}/tipoauditoria`, data)
      .toPromise();
  }

  public async atualizar(data: ITipoAuditoriaModel): Promise<IBaseModel<ITipoAuditoriaModel>> {
    return this.httpClient
      .put<IBaseModel<ITipoAuditoriaModel>>(`${this.apiBaseUrl}/tipoauditoria/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/tipoauditoria/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/tipoauditoria/${id}/inativar`, {})
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/tipoauditoria/${id}/excluir`, {})
      .toPromise();
  }
}
