import { ICronogramaModel } from './../../../models/cronograma.model';
import { Component, Input,  OnInit, } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IFasesAtividadeModel } from 'src/app/models/fases-atividade-model';
import { CronogramaService } from 'src/app/services/cronograma.service';
import { FaseAtividadeService } from 'src/app/services/fase-atividade.service';
import { ModalAtividadeSubatividadeComponent } from './modal-atividade-subatividade/modal-atividade-subatividade.component';
import Swal from 'sweetalert2';
import { IAtividadeModel } from 'src/app/models/atividade.model';
import { ISubAtividadeModel } from 'src/app/models/sub-atividade.model';
import { BaseFormComponent } from '../base-form/base-form.component';
import { OsaService } from 'src/app/services/osa.service';
import { IOsaAtividadeModel } from 'src/app/models/osa-atividade.model';
import { IOsaSubAtividadeModel } from 'src/app/models/osa-sub-atividade.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-aba-cronograma',
  templateUrl: './aba-cronograma.component.html',
  styleUrls: ['./aba-cronograma.component.scss']
})

export class AbaCronogramaComponent extends BaseFormComponent implements OnInit {
  public model: IFasesAtividadeModel[];
  public atividadesModel: IAtividadeModel = {} as IAtividadeModel;
  @Input() public tipo!: string;
  @Input() public visualizar;
  public cronograma: ICronogramaModel = {} as ICronogramaModel;
  public submitted: boolean;
  public homologado: boolean = false;


  constructor(
    private faseAtividadeService: FaseAtividadeService,
    private cronogramaService: CronogramaService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public router: Router,
    public matDialog: MatDialog,
    public localeService: BsLocaleService,
    public osaService: OsaService,

  ) {
    super(route, toastr, router, localeService, matDialog);
  }

  public form = new FormGroup({
		prazoEntregaMatriz: new FormControl("", Validators.required),
		prazoRespostaAuditado: new FormControl("", Validators.required),
		prazoEntregaRelatorio: new FormControl("", Validators.required),
	});

  ngOnInit() {
    if (this.tipo === 'planejamento') {
      this.obterCronograma()
    }
    else {
      this.obterCronogramaOsa()
      this.obterCampos()
    }
  }

  public obterCampos() {
    this.osaService.obterPorId(this.id).then((res) => {
      if(res.sucesso){
        this.cronograma.prazoEntregaMatriz = res?.dados?.prazoEntregaMatriz ? new Date(res.dados.prazoEntregaMatriz) : null;
        this.cronograma.prazoEntregaRelatorio = res?.dados?.prazoEntregaRelatorio ? new Date(res.dados.prazoEntregaRelatorio) : null;
        this.cronograma.prazoRespostaAuditado = res?.dados?.prazoRespostaAuditado ? new Date(res.dados.prazoEntregaRelatorio) : null;
        this.homologado = res?.dados?.status?.codigo==4;
      }
      else{
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    })
  }




  obterCronogramaOsa() {
    this.osaService.obterCronogramaOsa(this.id).then(res => {
      if (res.sucesso) {
        this.model = res.dados.fasesAtividade
        this.cronograma = res.dados
      }
      else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
  }

  obterCronograma() {
    this.cronogramaService.obterCronograma(this.id).then(res => {
      if (res.sucesso) {
        this.model = res.dados.fasesAtividade
        this.cronograma = res.dados
      }
      else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })

  }

  public salvar(){
    this.submitted = true
    if(this.form.invalid){
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }
    this.osaService.salvarOsa(this.id, this.cronograma ).then((res) => {
      if(res.sucesso){
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }else{
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    }).catch((err) =>{
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    })
  }

  public excluirAtividade(id: number) {
    Swal.fire({
      title: 'Excluir Atividade',
      text: 'Tem certeza que deseja excluir essa Atividade?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    })
      .then((res) => {
        if (res.value) {
          this.cronogramaService
            .excluirAtividade(id)
            .then((res) => {
              if (res.sucesso) {
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'success',
                  text: 'Atividade excluída com sucesso.',
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                });
              } else {
                this.exibirMensagemAlerta(res.mensagem.descricao);
              }
            })
            .catch((err) => {
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
            })
            .finally(() => {
              this.obterCronograma()
            });
        }
      })
  }

  public excluirSubAtividade(id: number) {
    Swal.fire({
      title: 'Excluir Sub atividade',
      text: 'Tem certeza que deseja excluir essa Sub atividade?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    })
      .then((res) => {
        if (res.value) {
          this.cronogramaService
            .excluirSubAtividade(id)
            .then((res) => {
              if (res.sucesso) {
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'success',
                  text: 'Sub atividade excluída com sucesso.',
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                });
              } else {
                this.exibirMensagemAlerta(res.mensagem.descricao);
              }
            })
            .catch((err) => {
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
            })
            .finally(() => {
              this.obterCronograma()
            });
        }
      }
      )
  }

  public modalIncluir(element?: IAtividadeModel | ISubAtividadeModel, acao?: string) {
    try {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-incluir-atividade-sub';
      dialogConfig.width = '700px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        planejamentoId: this.id,
        acao,
        element
      };
      const modal = this.matDialog.open(ModalAtividadeSubatividadeComponent, dialogConfig);
      modal.afterClosed().subscribe((data) => {
          this.obterCronograma()
      })
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    } finally {
    }
  }


  public async salvarOsaAtividade( element: IOsaAtividadeModel) {
    this.osaService.salvarOsaAtividade(this.id, element.atividadeId, {
      quantidadeDiasRealizado: Number(element.quantidadeDiasRealizado)
    }as IOsaAtividadeModel)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
        else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
      .finally(() => {
        this.obterCronogramaOsa();
      })
  }


  public salvarOsaSubAtividade(element: IOsaSubAtividadeModel) {
    this.osaService.salvarOsaSubAtividade(this.id, element.id, {
      quantidadeDiasRealizado: Number(element.quantidadeDiasRealizado)
    } as IOsaSubAtividadeModel)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
        else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
      .finally(() => {
        this.obterCronogramaOsa();
      })
  }
}

