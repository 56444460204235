<div class="h-100">
  <div class="card text-left h-100">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">{{ titulo }}</h4>
    </div>
    <div class="card-body wrapper p-0">
      <!-- <form id="myForm" #f="ngForm" [formGroup]=""></form> -->
     <app-questao-form></app-questao-form>
    </div>
    <div class="card-footer">
      <div class="d-flex w-100 justify-content-between align-items-center">
        <div class="list1">
          <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
            <i class="fas fa-times"></i> Fechar
          </button>
        </div>
        <div class="list2">
          <button type="submit" form="myForm" class="btn btn-primary btn-sm mr-1">
            <i class="far fa-save"></i> salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
