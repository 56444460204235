import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IConstatacaoRecomendacaoModel } from '../models/constatacao-recomendacao.model';
import { IConstatacaoModel } from '../models/constatacao.model';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root',
})
export class ConstatacaoService extends BaseService {
    constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
        super(httpClient, locationStrategy);
    }

    public obter(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<IConstatacaoModel[]>> {
        let params = new HttpParams();
        params = params.append('pesquisa', pesquisa || '');

        if (ativo !== undefined) {
            params = params.append('ativo', ativo.toString());
        }

        if (simplificado) {
            params = params.append('simplificado', simplificado.toString());
        }

        return this.httpClient
            .get<IBaseModel<IConstatacaoModel[]>>(`${this.apiBaseUrl}/constatacao`, { params })
            .toPromise();
    }

    public async obterPorId(id: number): Promise<IBaseModel<IConstatacaoModel>> {
        return this.httpClient.get<IBaseModel<IConstatacaoModel>>(`${this.apiBaseUrl}/constatacao/${id}`).toPromise();
    }

    public async obterPorRecomendacaoId(id: number): Promise<IBaseModel<IConstatacaoRecomendacaoModel>> {
        return this.httpClient
            .get<IBaseModel<IConstatacaoRecomendacaoModel>>(`${this.apiBaseUrl}/constatacao/${id}/recomendacao`)
            .toPromise();
    }

    public async inserir(data: IConstatacaoModel): Promise<IBaseModel<IConstatacaoModel>> {
        return this.httpClient.post<IBaseModel<IConstatacaoModel>>(`${this.apiBaseUrl}/constatacao`, data).toPromise();
    }

    public async atualizar(data: IConstatacaoModel): Promise<IBaseModel<IConstatacaoModel>> {
        return this.httpClient
            .put<IBaseModel<IConstatacaoModel>>(`${this.apiBaseUrl}/constatacao/${data.id}`, data)
            .toPromise();
    }

    public async reativar(id: number): Promise<IBaseModel<void>> {
        return this.httpClient.post<IBaseModel<void>>(`${this.apiBaseUrl}/constatacao/${id}/reativar`, {}).toPromise();
    }

    public async vincularConstatacaoRecomendacao(
        constatacaoId: number,
        data: { recomendacoes: number[] }
    ): Promise<IBaseModel<void>> {
        return this.httpClient
            .post<IBaseModel<void>>(
                `${this.apiBaseUrl}/Constatacao/${constatacaoId}/recomendacao-constatacao/salvar`,
                data
            )
            .toPromise();
    }

    public async desvincularConstatacaoRecomendacao(constatacaoId: number, recomendacaoId): Promise<IBaseModel<void>> {
        return this.httpClient
            .delete<IBaseModel<void>>(
                `${this.apiBaseUrl}/Constatacao/${constatacaoId}/recomendacao/${recomendacaoId}/excluir`
            )
            .toPromise();
    }

    public async inativar(id: number): Promise<IBaseModel<void>> {
        return this.httpClient.post<IBaseModel<void>>(`${this.apiBaseUrl}/constatacao/${id}/inativar`, {}).toPromise();
    }

    public async excluir(id: number): Promise<IBaseModel<void>> {
        return this.httpClient.delete<IBaseModel<void>>(`${this.apiBaseUrl}/constatacao/${id}`, {}).toPromise();
    }
}
