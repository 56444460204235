<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-12">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/']">
                            <i class="fas fa-home"></i>
                            Página Inicial</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/opa']">OPA - Ordem de Planejamento de Auditoria</a>
                    </li>
                    <li class="breadcrumb-item active">{{ titulo }}</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-lightblue">
                    <div class="card-header d-flex pb-0">
                        <h3 class="card-title mb-0">
                            <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
                            <i *ngIf="!this.id" class="far fa-file pr-2"></i>
                            {{ titulo }}
                        </h3>
                        <ul class="nav nav-tabs ml-auto p-0" style="cursor: pointer">
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{ active: aba === 0 }" (click)="mudarAba(0)">Informações
                                    Básicas</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{ active: aba === 5 }"
                                    (click)="mudarAba(5)">Relatoria</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{ active: aba === 6 }" (click)="mudarAba(6)">Linha do
                                    tempo</a>
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="aba === 0">
                        <form [formGroup]="form" #f="ngForm"
                            (keydown.enter)="$event.preventDefault()">
                            <div class="card-body" *ngIf="model.origem">
                                <div class="row">
                                    <div class="form-group col-md-3">
                                        <label for="txtNumeroOpa" class="mb-1">Número da OPA</label>
                                        <input type="text" id="txtNumeroOpa" class="form-control form-control-sm"
                                            readonly="true" formControlName="numeroOpa" />
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="txtOrigem" class="mb-1">Origem da Auditoria</label>
                                        <input type="text" id="txtOrigem" class="form-control form-control-sm"
                                            readonly="true" formControlName="origemAuditoria" />
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="txtDataElaboracao" class="mb-1">Data de Elaboração</label>
                                        <input type="text" id="txtDataElaboracao" class="form-control form-control-sm"
                                            readonly="true" formControlName="dataElaboracao" />
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="selectTipoAuditoria" class="mb-1">Tipo da Auditoria
                                            <i [hidden]="!model.historicoTipoAuditoria"
                                                [matTooltip]="model.historicoTipoAuditoria"
                                                class="fa fa-info-circle"></i>
                                        </label>
                                        <select id="selectTipoAuditoria" class="form-control form-control-sm"
                                            formControlName="tipoAuditoriaId" (change)="onSelectTipoAuditoria($event)"
                                            [ngClass]="{ 'is-invalid': submit && form.controls['tipoAuditoriaId'].invalid }"
                                            [attr.disabled]="!permiteEdicao ? true : null">
                                            <option *ngFor="let item of tiposAuditoria" [value]="item.id">{{item.nome}}
                                            </option>
                                        </select>

                                        <div *ngIf="submit && form.controls['tipoAuditoriaId'].invalid"
                                            class="invalid-feedback">
                                            <div *ngIf="form.controls['tipoAuditoriaId'].errors.required">Campo
                                                obrigatório</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="txtUnidadeGestora" class="mb-1">Unidade Gestora a ser Auditada
                                            <i [hidden]="!model.historicoUnidadeGestora"
                                                [matTooltip]="model.historicoUnidadeGestora"
                                                class="fa fa-info-circle"></i>
                                        </label>
                                        <div class="input-group input-group-sm" style="width: 100%;">
                                            <input type="text" id="txtUnidadeGestora"
                                                class="form-control form-control-sm" readonly
                                                formControlName="unidadeGestoraNome" [ngClass]="{ 'is-invalid': submit &&
                        form.controls['unidadeGestoraId'].invalid }" />
                                            <div class=" input-group-append" *ngIf="permiteEdicao">
                                                <button type="button" class="btn btn-info"
                                                    (click)="exibirModalUnidadeGestora()">
                                                    <i class="fas fa-search"></i>
                                                </button>
                                            </div>
                                            <div *ngIf="submit && form.controls['unidadeGestoraId'].invalid"
                                                class="invalid-feedback">
                                                <div *ngIf="form.controls['unidadeGestoraId'].errors.required">Campo
                                                    obrigatório</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="txtDataPrevistaInicio" class="mb-1">Data prevista Início</label>
                                        <input disabled type="text" id="txtDataPrevistaInicio"
                                            class="form-control form-control-sm suaClassDatePicker" autocomplete="off"
                                            bsDatepicker formControlName="dataInicioPrevista"
                                         />
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="txtDataPrevistaFim" class="mb-1">Data prevista Fim</label>
                                        <input disabled type="text" id="txtDataPrevistaFim"
                                            class="form-control form-control-sm suaClassDatePicker" autocomplete="off"
                                            bsDatepicker 
                                             formControlName="dataFimPrevista"
                                            />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label for="txtObjeto" class="mb-1">Objeto</label>
                                        <textarea rows="3" id="txtObjeto" [readonly]= "!permiteEdicao"
                                            class="form-control form-control-sm" formControlName="objeto"
                                            [ngClass]="{ 'is-invalid': submit && form.controls['objeto'].invalid }" 

                                            maxlength="500"></textarea>
                                    </div>
                                    <div *ngIf="submit && form.controls['objeto'].invalid" class="invalid-feedback">
                                        <div *ngIf="form.controls['objeto'].errors.required">Campo obrigatório</div>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="form-group col-md-3">
                                        <label for="txtQtdeDiasUteis" class="mb-1">Qtde de Dias Úteis</label>
                                        <input type="text" id="txtQtdeDiasUteis" class="form-control form-control-sm"
                                            readonly="true" formControlName="qtdeDiasUteis" />
                                    </div>

                                    <div class="form-group col-md-3">
                                        <label for="txtDataInicioRealizacao" class="mb-1">Data Início de Realização</label>
                                        <input
                                          type="text"
                                          id="txtDataInicioRealizacao"
                                          class="form-control form-control-sm suaClassDatePicker"
                                          autocomplete="off"
                                          bsDatepicker
                                          formControlName="dataInicioRealizacao"
                                          [attr.disabled]="(versao === false && !novaOpa && permiteEdicao === false ) || visualizar? true : null"
                                          [ngClass]="{ 'is-invalid': submit && form.controls['dataInicioRealizacao'].invalid }"
                                          (bsValueChange)="onDataInicioChange($event)"
                                          (click)="validaCamposNecessarios()"
                                        />
                                        <div *ngIf="submit && form.controls['dataInicioRealizacao'].invalid" class="invalid-feedback">
                                          <div *ngIf="form.controls['dataInicioRealizacao'].errors.required">Campo obrigatório</div>
                                        </div>
                                    </div>
                                                                         
                                    <div class="form-group col-md-3">
                                        <label for="txtDataFimRealizacao" class="mb-1">Data Fim de Realização</label>
                                        <input type="text" id="txtDataFimRealizacao"
                                            class="form-control form-control-sm suaClassDatePicker"
                                            formControlName="dataFimRealizacao" 
                                            [value]="form.controls['dataFimRealizacao'].value" 
                                            disabled />                                        
                                    </div> 

                                    <div class="form-group col-md-3">
                                        <label for="txtDataApresentacaoPlanejamento" class="mb-1">Data p/ Apresentação do Planejamento</label>
                                        <input type="text" id="txtDataApresentacaoPlanejamento"
                                            class="form-control form-control-sm suaClassDatePicker" autocomplete="off"
                                            bsDatepicker formControlName="dataApresentacaoPlanejamento" *ngIf="versao === true"
                                            [hidden] = "permiteEdicao === false"
                                            [ngClass]="{ 'is-invalid': submit && form.controls['dataApresentacaoPlanejamento'].invalid }" />
                                        <input type="text" id="txtdataApresentacaoPlanejamento"
                                            class="form-control form-control-sm suaClassDatePicker" autocomplete="off"
                                            bsDatepicker formControlName="dataApresentacaoPlanejamento"
                                            [hidden] = "versao"
                                            [attr.disabled]="(!novaOpa && !permiteEdicao) || visualizar ? 'true' : null" />

                                        <div *ngIf="submit && form.controls['dataApresentacaoPlanejamento'].invalid"
                                            class="invalid-feedback">
                                            <div *ngIf="form.controls['dataApresentacaoPlanejamentonto'].errors.required">Campo
                                                obrigatório</div>
                                        </div>
                                    </div>                                                                   
                                </div>

                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="selectCoordenadorAuditoria" class="mb-1">Coordenador da
                                            Auditoria</label>
                                        <select id="selectCoordenadorAuditoria" class="form-control form-control-sm"
                                            formControlName="auditorCoordenacaoId"
                                            [ngClass]="{ 'is-invalid': submit && form.controls['auditorCoordenacaoId'].invalid }"
                                            [hidden] = "permiteEdicao === false"
                                            *ngIf="versao === true">
                                            <option *ngFor="let item of coordenadoresAuditoria" [value]="item.id">
                                                {{item.nomeAuditor}}
                                            </option>
                                        </select>
                                        <select id="selectCoordenadorAuditoria" class="form-control form-control-sm"
                                            formControlName="auditorCoordenacaoId"
                                            [ngClass]="{ 'is-invalid': submit && form.controls['auditorCoordenacaoId'].invalid }"
                                            [hidden]="versao"
                                            [attr.disabled]="(!novaOpa && !permiteEdicao)|| visualizar ? 'true' : null">
                                            <option *ngFor="let item of coordenadoresAuditoria" [value]="item.id">
                                                {{item.nomeAuditor}}
                                            </option>
                                        </select>
                                        <div *ngIf="submit && form.controls['auditorCoordenacaoId'].invalid"
                                            class="invalid-feedback">
                                            <div *ngIf="form.controls['auditorCoordenacaoId'].errors.required">Campo
                                                obrigatório</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="selectTipoEnfase" class="mb-1">Tipo da Ênfase
                                        </label>
                                        <select id="selectTipoEnfase" class="form-control form-control-sm"
                                            formControlName="tipoEnfaseId" 
                                            [attr.disabled]="visualizar || !permiteEdicao ? true: null"
                                            >
                                            <option *ngFor="let item of tiposEnfase" [value]="item.id">{{item.nome}}
                                            </option>
                                        </select>

                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label for="txtNotaRodape" class="mb-1">Nota de Rodapé</label>
                                        <textarea rows="3" id="txtNotaRodape" [readonly]="visualizar || permiteEdicao === false"
                                            class="form-control form-control-sm" formControlName="notaRodape"
                                            maxlength="500"></textarea>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mat-elevation-z1">
                                            <table mat-table [dataSource]="subtipoDataSource">
                                                <tr mat-header-row *matHeaderRowDef="['nome', 'actions']"
                                                    class="bg-light"></tr>

                                                <ng-container matColumnDef="nome">
                                                    <th mat-header-cell *matHeaderCellDef> Subtipo(s) </th>
                                                    <td mat-cell *matCellDef="let element">
                                                        {{element.subtipoAuditoria.nome}} </td>
                                                </ng-container>
                                        
                                                <ng-container cdkColumnDef="actions">
                                                    <th mat-header-cell *matHeaderCellDef><button type="button"
                                                            class="btn btn-success btn-xs" *ngIf="permiteEdicao"
                                                            (click)="exibirModalSubtipo()"> Adicionar
                                                        </button></th>
                                                    <td mat-cell *matCellDef="let element; let i = index" class="block">
                                                        <div class="btn-group">
                                                            <button type="button"
                                                                class="btn btn-danger btn-xs text-left"
                                                                (click)="excluirSubtipo(i)" *ngIf="permiteEdicao">
                                                                <i class="far fa-trash-alt"></i> </button>
                                                        </div>
                                                    </td>
                                                </ng-container>

                                                <tr mat-row *matRowDef="let row; columns: ['nome', 'actions'];"></tr>
                                            </table>
                                            <h5 [hidden]="!subtipoSemDados" class="table-no-data">Nenhum Subtipo
                                                selecionado</h5>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="mat-elevation-z1">
                                            <table mat-table [dataSource]="auditoresDataSource">
                                                <tr mat-header-row *matHeaderRowDef="['nome', 'actions']"
                                                    class="bg-light"></tr>

                                                <ng-container matColumnDef="nome">
                                                    <th mat-header-cell *matHeaderCellDef> Equipe de Auditores </th>
                                                    <td mat-cell *matCellDef="let element">
                                                        <span *ngIf="element.indisponivelPeriodo" style="color: red;"><i
                                                                class="fas fa-exclamation-triangle"
                                                                title="Auditor indisponível para o período"></i>
                                                            {{element.nomeAuditor}} </span>
                                                        <span *ngIf="!element.indisponivelPeriodo">
                                                            {{element.nomeAuditor}} </span>
                                                    </td>
                                                </ng-container>

                                                <ng-container cdkColumnDef="actions">
                                                    <th mat-header-cell *matHeaderCellDef><button type="button"
                                                            class="btn btn-success btn-xs" *ngIf="versao || permiteEdicao"
                                                            (click)="exibirModalAlocarEquipe()"> Adicionar
                                                        </button></th>
                                                    <td mat-cell *matCellDef="let element; let i = index" class="block">
                                                        <div class="btn-group">
                                                            <button type="button"
                                                                class="btn btn-danger btn-xs text-left"
                                                                (click)="excluirAuditor(i)" *ngIf="versao || permiteEdicao">
                                                                <i class="far fa-trash-alt"></i> </button>
                                                                
                                                        </div>
                                                    </td>
                                                </ng-container>

                                                <tr mat-row *matRowDef="let row; columns: ['nome', 'actions'];"></tr>
                                            </table>
                                            <h5 [hidden]="!auditoresSemDados" class="table-no-data">Nenhum Auditor
                                                selecionado</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>


                    <div *ngIf="aba === 5" class="p-4">
                        <app-relatoria [aba]="aba" [relatoriaDados]="relatoriaDados" [visualizar]="visualizar"
                            [submit]="submit" [obterDados]="obterOpa.bind(this)" [model]="model" [tipoTrabalhoId]="id">
                        </app-relatoria>
                    </div>

                    <div *ngIf="aba === 6" class="p-4">
                        <app-linha-do-tempo [aba]="aba"></app-linha-do-tempo>
                    </div>

                    <div class="card-footer">
                        <div class="btn-group" *ngIf="permiteEdicao && !visualizar && versao === false">
                            <button class="btn btn-primary btn-sm mr" type="button" (click)="onSubmit(false)">
                                <i class="far fa-save"></i> Salvar
                            </button>
                            <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                            <div class="dropdown-menu" role="menu">
                                <a class="dropdown-item" (click)="onSubmit(false)">Salvar</a>
                                <a class="dropdown-item" (click)="onSubmit(true)">Salvar e fechar</a>
                            </div>
                        </div>

                        <button type="button" class="btn btn-primary btn-sm mr-1" (click)="gerarNovaVersao()"
                            *ngIf="!visualizar && emPlanejamento">
                            <i class="far fa-save"></i> Gerar Nova Versão
                        </button>

                        <button type="button" class="btn btn-warning btn-sm mr-1"
                            *ngIf="!visualizar && aba === 5 && model?.situacao?.codigo === 1"
                            (click)="enviarAprovacao()" [disabled]="!model?.habilitarEnviarAprovacao">
                            <i class="far fa-save"></i> Enviar para Aprovação
                        </button>

                        <button type="button" class="btn btn-default btn-sm" (click)="onSair()">
                            <i class="fas fa-times"></i> Fechar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>