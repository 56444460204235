import { ProcedimentoAuditoriaFormComponent } from "src/app/pages/procedimento-auditoria-form/procedimento-auditoria-form.component";
import { data } from "jquery";
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, MaxLengthValidator, ValidatorFn, Validators } from "@angular/forms";
import {
	MatDialog,
	MatDialogConfig,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import * as cloneDeep from "lodash/cloneDeep";
import { ToastrService } from "ngx-toastr";
import { IBaseModel } from "src/app/models/base.model";
import { IQuestaoModel } from "src/app/models/questao.model";
import { IMatrizPlanejamentoModel } from "src/app/models/matriz-planejamento.model";
import { IQuestaoConstatacaoModel } from "src/app/models/questao-constatacao.model";
import { IQuestaoProcedimentoAuditoriaModel } from "src/app/models/questao-procedimento-auditoria.model";
import { IMatrizPlanejamentoQuestaoModel } from "src/app/models/matriz-planejamento-questao.model";
import { ISubtipoAuditoriaModel } from "src/app/models/subtipo-auditoria.model";
import { ISubtipoAuditoriaMatrizPlanejamentoModel } from "src/app/models/subtipo-auditoria-matriz-planejamento.model";
import { ProcedimentoAuditoriaService } from "src/app/services/procedimento-auditoria.service";
import { QuestaoService } from "src/app/services/questao.service";
import { ConstatacaoService } from "src/app/services/constatacao.service";
import { BaseFormComponent } from "../../shared/components/base-form/base-form.component";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import { ModalConstatacaoComponent } from "../constatacao-form/modal-constatacao/modal-constatacao.component";
import { ModalProcedimentoAuditoriaComponent } from "../procedimento-auditoria-form/modal-procedimento-auditoria/modal-procedimento-auditoria.component";
import Swal from "sweetalert2";
import { ConstatacaoFormComponent } from "../constatacao-form/constatacao-form.component";
import { angularEditorConfig } from "src/app/shared/util/angularEditorConfig";

@Component({
	selector: "app-questao-form",
	templateUrl: "./questao-form.component.html",
	styleUrls: ["./questao-form.component.scss"],
})
export class QuestaoFormComponent extends BaseFormComponent implements OnInit, AfterViewInit {

	@ViewChild("maxLengthEditor") private angularEditor: any;
	@ViewChild("maxLengthEditorLegislacao") private angularEditorLegislacao: any;

	public model: IQuestaoModel = {} as IQuestaoModel;
	public manterMatrizPlanejamento: IQuestaoModel = {} as IQuestaoModel;
	public modelQuestaoConstatacao: IQuestaoConstatacaoModel =
		{} as IQuestaoConstatacaoModel;
	public modelMatrizPlanejamento: IMatrizPlanejamentoModel =
		{} as IMatrizPlanejamentoModel;
	public constatacaoDataSource =
		new MatTableDataSource<IQuestaoConstatacaoModel>([]);
	public listaQuestao =
		new MatTableDataSource<IMatrizPlanejamentoQuestaoModel>([]);
	public listaMatrizPlanejamento: ISubtipoAuditoriaMatrizPlanejamentoModel =
		{} as ISubtipoAuditoriaMatrizPlanejamentoModel;

	public modelSubtipoAuditoria: ISubtipoAuditoriaModel =
		{} as ISubtipoAuditoriaModel;
	public modelQuestaoProcedimentoAuditoria: IQuestaoProcedimentoAuditoriaModel =
		{} as IQuestaoProcedimentoAuditoriaModel;
	public procedimentoAuditoriaDataSource =
		new MatTableDataSource<IQuestaoProcedimentoAuditoriaModel>([]);

	public rotaPrefixoSubtipoAuditoria = undefined;
	public rotaPrefixoMatrizPlanejamento = undefined;
	public semDadosConstatacao = true;
	public semDadosProcedimentoAuditoria = true;
	public salvar: boolean;
	public questaoId: number = null;
	public tipoTrabalho: string;
	public tipoTrabalhoId: number;
    public subTipoAuditoriaId: number;
	public texto: string;

	public form = new FormGroup({
		nome: new FormControl("", Validators.required),
		classificacao: new FormControl("", Validators.required),
		descricao: new FormControl("", [Validators.required, this.editorMaxLength(500)]),
		legislacaoAplicavelCriterio: new FormControl("", Validators.required),
	});

	public isModal: boolean = false;

	public config: AngularEditorConfig = angularEditorConfig(
		"Digite aqui...",
		"150px",
		"100%",
		true
	);

	editorMaxLength(maxlength): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			var regex = /(<([^>]+)>)/ig;
			var value = control.value;
			var result = value.replace(regex, "");
			if (result.length > maxlength) {
				return { 'maxlengthError': true };
			} else {
				return null;
			}
		}
	}

	constructor(
		public route: ActivatedRoute,
		public toastr: ToastrService,
		public router: Router,
		public localeService: BsLocaleService,
		public questaoService: QuestaoService,
		public constatacaoService: ConstatacaoService,
		public procedimentoAuditoriaService: ProcedimentoAuditoriaService,
		public matDialog: MatDialog,
		public dialogRef: MatDialogRef<QuestaoFormComponent>,
		@Inject(MAT_DIALOG_DATA) public dados: any
	) {
		super(route, toastr, router, localeService, matDialog);

		if (!(Object.keys(dados).length === 0)) {
			!(dados?.data === undefined)
				? (this.novoRegistro = false)
				: (this.novoRegistro = true);
			this.isModal = true;
			this.manterMatrizPlanejamento = dados?.data;
			this.id = dados?.data?.id;
			this.tipoTrabalho = dados?.tipoTrabalho?.nome;
			this.tipoTrabalhoId = dados?.tipoTrabalhoId;
            this.subTipoAuditoriaId = dados?.subTipoAuditoriaId;
			this.novoRegistro = this.id == undefined;
			this.visualizar = dados?.visualizar;
			this.config.enableToolbar = !dados?.visualizar;
			this.config.editable = !dados?.visualizar;
			this.config.showToolbar = !dados?.visualizar;
		}

		if (this.novoRegistro) {
			this.titulo = "Nova Questão Auditoria";
			this.texto =
				"Esta questão de auditoria deve ser incluída na base de conhecimento?";
		} else if (this.visualizar) {
			this.titulo = "Visualização Questão Auditoria";
		} else {
			this.titulo = "Editar Questão Auditoria";
			this.texto =
				"Estas alterações na questão de auditoria devem ser realizadas na base de conhecimento?";
		}
	}

	async ngOnInit() {
		this.state();
		try {
			if (window.history.state.model) {
				this.model = window.history.state.model;
			}
			if (window.history.state.constatacaoDataSource) {
				this.constatacaoDataSource =
					new MatTableDataSource<IQuestaoConstatacaoModel>(
						window.history.state.constatacaoDataSource
					);
				this.constatacaoDataSource._updateChangeSubscription();
				this.semDadosConstatacao =
					this.constatacaoDataSource.filteredData.length === 0;
			}

			if (window.history.state.procedimentoAuditoriaDataSource) {
				this.procedimentoAuditoriaDataSource =
					new MatTableDataSource<IQuestaoProcedimentoAuditoriaModel>(
						window.history.state.procedimentoAuditoriaDataSource
					);
				this.procedimentoAuditoriaDataSource._updateChangeSubscription();
				this.semDadosProcedimentoAuditoria =
					this.procedimentoAuditoriaDataSource.filteredData.length ===
					0;
			}

			if (!this.novoRegistro && !window.history.state.model) {
				await this.obter();
			}

			this.form.patchValue(this.model);
		} catch (err) {
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "error",
				text: err?.mensagem?.descricao ?? err,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
		}
	}

	ngAfterViewInit() {
		this.angularEditor?.textArea?.nativeElement.addEventListener(
			"keydown",
			this.maxLengthAngularEditor,
			false
		);

		this.angularEditorLegislacao?.textArea?.nativeElement.addEventListener(
			"keydown",
			this.maxLengthAngularEditor,
			false
		);
	}

	public maxLengthAngularEditor(e: any) {
		var element = e.target as HTMLTextAreaElement;
		let permitirTeclas =
			e.key !== "Backspace" &&
			e.key !== "ArrowLeft" &&
			e.key !== "ArrowRight";
		if (element.innerHTML.length === 500 && permitirTeclas) {
			e.preventDefault();
		}
	}

	public verificarTamanho(e: any) {
		var regex = /(<([^>]+)>)/ig;
		var result = e.replace(regex, "");
		return result.length > 500;
	}

	public async obter() {
		const res = await this.questaoService.obterPorId(
			this.id,
			this.tipoTrabalho
		);
		if (res?.sucesso) {
			this.model = res.dados;

			this.titulo += " - " + this.model.nome;

			// constatação
			this.constatacaoDataSource =
				new MatTableDataSource<IQuestaoConstatacaoModel>(
					this.model.questaoConstatacao as IQuestaoConstatacaoModel[]
				);
			this.constatacaoDataSource._updateChangeSubscription();
			this.semDadosConstatacao =
				this.constatacaoDataSource.filteredData.length === 0;
			// procedimento
			this.procedimentoAuditoriaDataSource =
				new MatTableDataSource<IQuestaoProcedimentoAuditoriaModel>(
					this.model
						.questaoProcedimentoAuditoria as IQuestaoProcedimentoAuditoriaModel[]
				);
			this.procedimentoAuditoriaDataSource._updateChangeSubscription();
			this.semDadosProcedimentoAuditoria =
				this.procedimentoAuditoriaDataSource.filteredData.length === 0;
		} else {
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "error",
				text: res.mensagem.descricao,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
			this.router.navigate(["/questao"]);
			return;
		}
	}

	public async state() {
		// model questao
		if (window.history.state.model) {
			this.model = window.history.state.model;
		}

		// model questao
		if (window.history.state.modelSubtipoAuditoria) {
			this.modelSubtipoAuditoria =
				window.history.state.modelSubtipoAuditoria;
		}

		// matriz planejamento
		if (window.history.state.modelMatrizPlanejamento) {
			this.modelMatrizPlanejamento =
				window.history.state.modelMatrizPlanejamento;
		}

		// constatação
		if (window.history.state.listaConstatacao) {
			this.constatacaoDataSource =
				new MatTableDataSource<IQuestaoConstatacaoModel>(
					window.history.state.listaConstatacao
				);
			this.constatacaoDataSource._updateChangeSubscription();
			this.semDadosConstatacao =
				this.constatacaoDataSource.data.length === 0;
		}

		// procedimento auditoria
		if (window.history.state.listaProcedimentoAuditoria) {
			this.procedimentoAuditoriaDataSource =
				new MatTableDataSource<IQuestaoProcedimentoAuditoriaModel>(
					window.history.state.listaProcedimentoAuditoria
				);
			this.procedimentoAuditoriaDataSource._updateChangeSubscription();
			this.semDadosProcedimentoAuditoria =
				this.procedimentoAuditoriaDataSource.filteredData.length === 0;
		}

		// lista questao
		if (window.history.state.listaQuestao) {
			this.listaQuestao = window.history.state.listaQuestao;
		}

		// lista questao
		if (window.history.state.listaMatrizPlanejamento) {
			this.listaMatrizPlanejamento =
				window.history.state.listaMatrizPlanejamento;
		}

		// rota
		if (window.history.state.rotaPrefixoSubtipoAuditoria) {
			this.rotaPrefixoSubtipoAuditoria =
				window.history.state.rotaPrefixoSubtipoAuditoria;
		}

		if (window.history.state.rotaPrefixoMatrizPlanejamento) {
			this.rotaPrefixoMatrizPlanejamento =
				window.history.state.rotaPrefixoMatrizPlanejamento;
		}
	}

	public async onSubmit(salvarEFechar: boolean = false) {
		this.salvar = true;
		//solicitado pra remover limite dos campos
		/*if (this.verificarTamanho(this.form.controls['descricao'].value) == true){
		  Swal.fire({
					toast: true,
					position: "top-end",
					icon: "warning",
					text: "O campo Descrição não pode ter mais que 500 caracteres",
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
		  return
		}
		if (this.verificarTamanho(this.form.controls['legislacaoAplicavelCriterio'].value) == true){
		  Swal.fire({
					toast: true,
					position: "top-end",
					icon: "warning",
					text: "O campo Legislação Aplicável/Critério não pode ter mais que 500 caracteres",
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
		  return
		}*/

		if (this.form.invalid) {
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "warning",
				text: "Formulário inválido",
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
			return;
		}
		this.atualizarModel(this.form.value);
		this.model.questaoConstatacao = this.constatacaoDataSource.data;
		this.model.questaoProcedimentoAuditoria = this.procedimentoAuditoriaDataSource.data;
	
		try {
			let res: IBaseModel<IQuestaoModel> = null;
	
			this.model.questaoConstatacao = this.model.questaoConstatacao.map(a => a?.constatacao?.id);
			this.model.questaoProcedimentoAuditoria = this.model.questaoProcedimentoAuditoria.map(a => a?.procedimentoAuditoria?.id);
	
			if (!this.novoRegistro) {
				// salva questão tipo de trabalho
				if (this.model.questaoId) {
					res = await this.questaoService.atualizar(this.model, this.tipoTrabalho, this.tipoTrabalhoId);
				} else {
					// salva só a questão
					res = await this.questaoService.atualizar(this.model, null, 0);
				}
				this.executarVinculos(res, this.model.avaliar);
	
			} else if (this.isModal) {
				Swal.fire({
					title: `${this.titulo}`,
					text: `${this.texto}`,
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Sim",
					cancelButtonText: "Não",
				}).then(async (ret) => {
					if (ret.value) {
						this.model.avaliar = true;
					}
					res = await this.questaoService.inserir(this.model, this.tipoTrabalho, this.tipoTrabalhoId, this.subTipoAuditoriaId);
					this.executarVinculos(res, ret.value);
					this.handleSuccess(res, salvarEFechar);
				});
			} else {
				this.model.avaliar = false;
				res = await this.questaoService.inserir(this.model, this.tipoTrabalho, this.tipoTrabalhoId, this.subTipoAuditoriaId);
				this.handleSuccess(res, salvarEFechar);
			}
		} catch (err) {
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "error",
				text: err.mensagem.descricao,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
		}
	}
	
	private handleSuccess(res: IBaseModel<IQuestaoModel>, salvarEFechar: boolean) {
		if (res.sucesso) {
			this.vincularConstatacoes(res.dados.id, this.model.avaliar);
			this.vincularProcedimentos(res.dados.id, this.model.avaliar);
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "success",
				text: "Registro salvo com sucesso!",
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
	
			if (salvarEFechar) {
				this.router.navigate(['/questao']);
			} else
			if(this.novoRegistro){
			  this.router.navigate([`/questao/${res.dados.id}`]);
			  this.ngOnInit();
			} else {
				// Permanecer na mesma tela, talvez atualizar o estado ou indicar que o registro foi salvo
				if (!this.isModal) {
					// Permanecer na mesma página
					this.router.navigate([this.router.url]); // Esta linha força a página a recarregar.
				} else {
					// Fechar o modal
					this.dialogRef.close({ data: res.dados });
				}
			}
		} else {
			this.handleError(res);
		}
	}
	
	private handleError(res: IBaseModel<IQuestaoModel>) {
		const validacoes = this.montarMensagensValidacao(res);
		Swal.fire({
			toast: true,
			position: "top-end",
			icon: "warning",
			text: validacoes,
			showConfirmButton: false,
			timer: 5000,
			timerProgressBar: true,
		});
	}

	public executarVinculos(res, avaliar) {
		if (res.sucesso) {
			this.model = res.dados;
			this.vincularConstatacoes(res.dados.id, avaliar);
			this.vincularProcedimentos(res.dados.id, avaliar);
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "success",
				text: "Registro salvo com sucesso!",
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});

			if (this.rotaPrefixoMatrizPlanejamento) {
				this.router.navigate(
					[this.rotaPrefixoMatrizPlanejamento],
					{
						state: {
							modelMatrizPlanejamento:
								this.modelMatrizPlanejamento,
							modelSubtipoAuditoria:
								this.modelSubtipoAuditoria,
							listaQuestao: this.listaQuestao,
							listaMatrizPlanejamento:
								this.listaMatrizPlanejamento,
							rotaPrefixoSubtipoAuditoria:
								this
									.rotaPrefixoSubtipoAuditoria,
						},
					}
				);
			} else {
				if (!this.isModal)
					this.router.navigate(["/questao"]);
				if (this.isModal) {
					this.dialogRef.close({ data: res.dados });
				}
			}
		} else {
			const validacoes =
				this.montarMensagensValidacao(res);

			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "warning",
				text: validacoes,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
		}
	}

	public async vincularConstatacoes(questaoId: number, isAvaliar: boolean) {
		await this.questaoService.vincularQuestaoConstatacao(
			this.id ?? questaoId,
			{
				constatacoes: this.constatacaoDataSource.data.map(
					(item) => item.constatacaoId
				),
			},
			this.tipoTrabalho
		);
		if (isAvaliar) {
			await this.questaoService.vincularQuestaoConstatacao(
				this.model.questaoId,
				{
					constatacoes: this.constatacaoDataSource.data.map(
						(item) => item.constatacaoId
					),
				},
				null
			);
		}
	}

	public async vincularProcedimentos(questaoId: number, isAvaliar: boolean) {
		await this.questaoService.vincularQuestaoProcedimento(
			this.id ?? questaoId,
			{
				procedimentos: this.procedimentoAuditoriaDataSource.data.map(
					(item) => item.procedimentoAuditoriaId
				),
			},
			this.tipoTrabalho
		);
		if (isAvaliar) {
			await this.questaoService.vincularQuestaoProcedimento(
				this.model.questaoId,
				{
					procedimentos: this.procedimentoAuditoriaDataSource.data.map(
						(item) => item.procedimentoAuditoriaId
					),
				},
				null
			);
		}
	}

	public incluirConstatacao() {
		if (
			this.constatacaoDataSource.data.findIndex(
				(a) =>
					a.constatacaoId ===
					this.modelQuestaoConstatacao.constatacaoId
			) >= 0
		) {
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "warning",
				text: "Constatação já cadastrada nessa questão",
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
			return;
		}

		this.constatacaoDataSource.data.push(
			cloneDeep(this.modelQuestaoConstatacao)
		);
		this.constatacaoDataSource._updateChangeSubscription();
		this.modelQuestaoConstatacao = {} as IQuestaoConstatacaoModel;
		this.semDadosConstatacao = false;
	}

	public excluirConstatacao(
		element: IQuestaoConstatacaoModel,
		index: number
	) {
		if (element.constatacaoId) {
			this.questaoService
				.desVincularQuestaoConstatacao(
					this.id,
					element.constatacaoId,
					this.tipoTrabalho
				)
				.then((res) => {
					res.sucesso
						? this.exibirMensagemSucesso(res.mensagem.descricao)
						: this.exibirMensagemErro(res.mensagem.descricao);
				})
				.finally(() => this.ngOnInit());
			return;
		}

		this.constatacaoDataSource.data.splice(index, 1);
		this.constatacaoDataSource._updateChangeSubscription();
		this.semDadosConstatacao =
			this.constatacaoDataSource.filteredData.length === 0;
	}

	public async exibirModalConstatacao() {
		try {
			const res = await this.constatacaoService.obter("", true);
			if (res.sucesso) {
				const dadosFiltrados = res.dados.filter(
					(constatacao) => constatacao.ativo
				);
				const modal = this.exibirModal(
					"Selecionar Constatação",
					dadosFiltrados
				);
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.modelQuestaoConstatacao.constatacaoId = data.id;
						this.modelQuestaoConstatacao.constatacao = data;
					}
				});
			} else {
				Swal.fire({
					toast: true,
					position: "top-end",
					icon: "error",
					text: res.mensagem.descricao,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
			}
		} catch (err) {
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "error",
				text: err.mensagem.descricao,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
		}
	}

	public incluirProcedimentoAuditoria() {
		if (
			this.procedimentoAuditoriaDataSource.data.findIndex(
				(a) =>
					a.procedimentoAuditoriaId ===
					this.modelQuestaoProcedimentoAuditoria
						.procedimentoAuditoriaId
			) >= 0
		) {
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "warning",
				text: "Procedimento Auditoria já cadastrada nessa questão",
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
			return;
		}

		this.procedimentoAuditoriaDataSource.data.push(
			cloneDeep(this.modelQuestaoProcedimentoAuditoria)
		);
		this.procedimentoAuditoriaDataSource._updateChangeSubscription();
		this.modelQuestaoProcedimentoAuditoria =
			{} as IQuestaoProcedimentoAuditoriaModel;
		this.semDadosProcedimentoAuditoria = false;
	}

	public excluirProcedimentoAuditoria(
		element: IQuestaoProcedimentoAuditoriaModel,
		index: number
	) {
		if (element.procedimentoAuditoriaId) {
			this.questaoService
				.desVincularQuestaoProcedimento(
					this.id,
					element.procedimentoAuditoriaId,
					this.tipoTrabalho
				)
				.then((res) => {
					res.sucesso
						? this.exibirMensagemSucesso(res.mensagem.descricao)
						: this.exibirMensagemErro(res.mensagem.descricao);
				})
				.finally(() => this.ngOnInit());
			return;
		}

		this.procedimentoAuditoriaDataSource.data.splice(index, 1);
		this.procedimentoAuditoriaDataSource._updateChangeSubscription();
		this.semDadosProcedimentoAuditoria =
			this.procedimentoAuditoriaDataSource.filteredData.length === 0;
	}

	public async exibirModalProcedimentoAuditoria() {
		try {
			const res = await this.procedimentoAuditoriaService.obter("", true);
			if (res.sucesso) {
				const dadosFiltrados = res.dados.filter(
					(procedimentoAuditoria) => procedimentoAuditoria.ativo
				);
				const modal = this.exibirModal(
					"Selecionar Procedimento Auditoria",
					dadosFiltrados
				);
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.modelQuestaoProcedimentoAuditoria.procedimentoAuditoriaId =
							data.id;
						this.modelQuestaoProcedimentoAuditoria.procedimentoAuditoria =
							data;
					}
				});
			} else {
				Swal.fire({
					toast: true,
					position: "top-end",
					icon: "error",
					text: res.mensagem.descricao,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
			}
		} catch (err) {
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "error",
				text: err.mensagem.descricao,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
		}
	}

	public async modalVisualizarConstatacao(ind: number) {
		try {
			const dialogConfig = new MatDialogConfig();
			dialogConfig.id = "modal-component";
			dialogConfig.width = "60%";
			dialogConfig.height = "70%";
			dialogConfig.hasBackdrop = true;
			dialogConfig.disableClose = true;
			dialogConfig.data = ind;
			this.matDialog.open(ModalConstatacaoComponent, dialogConfig);
		} catch (err) {
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "error",
				text: err,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
		}
	}

	public async modalVisualizarProcedimentoAuditoria(ind: number) {
		try {
			const dialogConfig = new MatDialogConfig();
			dialogConfig.id = "modal-component";
			dialogConfig.width = "60%";
			dialogConfig.height = "70%";
			dialogConfig.hasBackdrop = true;
			dialogConfig.disableClose = true;
			dialogConfig.data = ind;
			this.matDialog.open(
				ModalProcedimentoAuditoriaComponent,
				dialogConfig
			);
		} catch (err) {
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "error",
				text: err,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
		}
	}

	public async cadastroRapidoConstatacao() {
		this.salvar = true;
		const dados = {
			model: this.model ? this.model : this.form.value,
			modelMatrizPlanejamento: this.modelMatrizPlanejamento,
			modelSubtipoAuditoria: this.modelSubtipoAuditoria,
			listaProcedimentoAuditoria:
				this.procedimentoAuditoriaDataSource.data,
			listaConstatacao: this.constatacaoDataSource.data,
			listaQuestao: this.listaQuestao.data,
			listaMatrizPlanejamento: this.listaMatrizPlanejamento,
			rotaPrefixoSubtipoAuditoria: this.rotaPrefixoSubtipoAuditoria,
			rotaPrefixoMatrizPlanejamento: this.rotaPrefixoMatrizPlanejamento,
		};

		if (this.isModal) {
			const dialogConfig = new MatDialogConfig();
			dialogConfig.id = "modal-constatação";
			dialogConfig.width = "60%";
			dialogConfig.height = "80%";
			dialogConfig.hasBackdrop = true;
			dialogConfig.disableClose = false;
			dialogConfig.data = {
				isModal: this.isModal,
				titulo: `Adicionar questão`,
				state: dados,
				model: this.manterMatrizPlanejamento,
			};

			const modal = this.matDialog.open(
				ConstatacaoFormComponent,
				dialogConfig
			);

			modal.afterClosed().subscribe((data) => {
				if (data) {
				}
			});
		} else {
			this.router.navigate([`/constatacao/novo`], {
				state: dados,
			});
		}
	}

	public async cadastroRapidoProcedimentoAuditoria() {
		this.salvar = true;
		const dados = {
			model: this.form.value,
			modelMatrizPlanejamento: this.modelMatrizPlanejamento,
			modelSubtipoAuditoria: this.modelSubtipoAuditoria,
			listaProcedimentoAuditoria:
				this.procedimentoAuditoriaDataSource.data,
			listaConstatacao: this.constatacaoDataSource.data,
			listaQuestao: this.listaQuestao.data,
			listaMatrizPlanejamento: this.listaMatrizPlanejamento,
			rotaPrefixoSubtipoAuditoria: this.rotaPrefixoSubtipoAuditoria,
			rotaPrefixoMatrizPlanejamento: this.rotaPrefixoMatrizPlanejamento,
		};

		if (this.isModal) {
			const dialogConfig = new MatDialogConfig();
			dialogConfig.id = "modal-cadastroRapidoProcedimentoAuditoria";
			dialogConfig.width = "60%";
			dialogConfig.height = "80%";
			dialogConfig.hasBackdrop = true;
			dialogConfig.disableClose = false;
			dialogConfig.data = {
				isModal: this.isModal,
				titulo: `Editar questão`,
				state: dados,
				model: this.manterMatrizPlanejamento,
			};

			const modal = this.matDialog.open(
				ProcedimentoAuditoriaFormComponent,
				dialogConfig
			);

			modal.afterClosed().subscribe((data) => {
				if (data) {
				}
			});
		} else {
			this.router.navigate([`/procedimento-auditoria/novo`], {
				state: dados,
			});
		}
	}

	private atualizarModel(values: any) {
		Object.assign(this.model, values);
	}

	public aprovarQuestao(questaoId: number) {
		this.questaoService
			.aprovar(questaoId)
			.then((res) => {
				res.sucesso
					? this.exibirMensagemSucesso("Questão de auditoria salva com sucesso!")
					: this.exibirMensagemErro("Erro ao avaliar questão!");
			})
			.then(() => this.obter())
			.catch((err) =>
				this.exibirMensagemErro("Erro ao avaliar questão!")
			);
	}

	public reprovarQuestao(questaoId: number) {
		this.questaoService
			.reprovar(questaoId)
			.then((res) => {
				res.sucesso
					? this.exibirMensagemSucesso("Questão de auditoria reprovada com sucesso!")
					: this.exibirMensagemErro(" A questão foi reprovada!");
			})
			.then(() => this.onBack())
			.catch((err) =>
				this.exibirMensagemErro(" Erro ao reprovar questão!")
			);
	}

	public onBack() {
		if (this.isModal) {
			this.dialogRef.close();
			return;
		}
		if (window.history.state.rotaPrefixoMatrizPlanejamento) {
			this.router.navigate(
				[window.history.state.rotaPrefixoMatrizPlanejamento],
				{
					state: {
						modelMatrizPlanejamento: this.modelMatrizPlanejamento,
						modelSubtipoAuditoria: this.modelSubtipoAuditoria,
						listaQuestao: this.listaQuestao,
						listaMatrizPlanejamento: this.listaMatrizPlanejamento,
						rotaPrefixoSubtipoAuditoria:
							this.rotaPrefixoSubtipoAuditoria,
					},
				}
			);
		} else {
			this.router.navigate(["/questao"]);
		}
	}
}
