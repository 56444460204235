import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as cloneDeep from 'lodash/cloneDeep';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IParametrizacaoModel } from 'src/app/models/parametrizacao.model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { ParametrizacaoService } from 'src/app/services/parametrizacao.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';
import { ModalParametrizacaoFormComponent } from './modal-parametrizacao-form/modal-parametrizacao-form.component';

@Component({
  selector: 'app-parametrizacao-list',
  templateUrl: './parametrizacao-list.component.html',
  styleUrls: ['./parametrizacao-list.component.scss']
})
export class ParametrizacaoListComponent extends BaseListComponent implements OnInit {
  public dataSource = new MatTableDataSource<IParametrizacaoModel>([]);
  public selectedModel: IParametrizacaoModel;

  public displayedColumns: string[] = [
    'selecao',
    'id',
    'descricao',
    'valor',
  ];

  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private paramService: ParametrizacaoService,
    private modalService: BsModalService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.buscar();
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Configuracoes, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Configuracoes, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Configuracoes, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Configuracoes, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Configuracoes, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Configuracoes, EnumAcaoRecurso.Visualizar);
 }

  public buscar() {
    this.selectedModel = null;

    this.paramService
      .obter(this.searchNome)
      .then((res) => {
        this.dataSource = new MatTableDataSource<IParametrizacaoModel>(res.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: 'error',
          title: 'Atenção',
          text: err.mensagem.descricao
        });
      })
  }


  public editar(id: string) {
    const modal = this.modalService.show(ModalParametrizacaoFormComponent, { initialState: { model: cloneDeep(this.selectedModel) } });

    modal.content.onClose.subscribe((data: boolean) => {
      if (data) {
        this.buscar();
      }
    });
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }
}
