<div class="p-2">
  <form [formGroup]="form" #f="ngForm">
    <div class="row mt-2">
      <div class="col-md-12">
        <label for="justificativa" class="form-label">Justificativa*</label>
        <textarea type="text" class="form-control" id="justificativa" maxlength="100" formControlName="justificativa"
          [ngClass]="{ 'is-invalid': submitted && form.controls['justificativa'].invalid }"></textarea>
        <div *ngIf="submitted && form.controls['justificativa'].invalid" class="invalid-feedback">
          <div *ngIf="form.controls['justificativa'].errors.required">Campo obrigatório
        </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card-footer">
  <button class="btn btn-primary btn-sm mr-1" (click)="submit()"> <i class="fas fa-check"></i> Confirmar</button>
  <button class="btn btn-default btn-sm mr-1" (click)="cancelar()">
    <i class="fas fa-times"></i> Fechar
  </button>
</div>
