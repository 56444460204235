import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { ICustoRecursoModel } from 'src/app/models/custo-recurso.model';
import { CustoRecursoService } from 'src/app/services/custo-recurso.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-custo-recurso-form',
  templateUrl: './custo-recurso-form.component.html',
  styleUrls: ['./custo-recurso-form.component.scss']
})
export class CustoRecursoFormComponent extends BaseFormComponent implements OnInit {
  public model: ICustoRecursoModel = {} as ICustoRecursoModel;
  public auditoresDataSource = new MatTableDataSource<ICustoRecursoModel>([]);
  public semDados = true;
  public salvar: boolean;

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    valorMedio: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
  });
  public onAuditoria!: boolean;

  @Input() auditoriaModel!: ICustoRecursoModel;

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private custoRecursoService: CustoRecursoService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Nova Custos e Recursos';
    }

    this.onAuditoria = this.router.url.includes('auditoria')
  }

  config: AngularEditorConfig = {
    editable: !this.visualizar,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async ngOnInit() {
    try {
      if(this.auditoriaModel?.id && this.onAuditoria) {
        this.model = this.auditoriaModel;
      } else if (!this.novoRegistro) {
        const res = await this.custoRecursoService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;
          if (this.visualizar) {
            this.titulo = 'Visualização de Custos e Recursos - ' + this.model.nome;
          } else {
            this.titulo = 'Editar Custos e Recursos - ' + this.model.nome;
          }
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
          this.router.navigate(['/custo-recurso']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
      this.router.navigate(['/custo-recurso']);
    }
  }

  public async onSubmit(salvarEFechar: boolean = false) {
    this.salvar = true
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido!');
      return;
    }

    this.atualizarModel(this.form.value);

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        res = await this.custoRecursoService.atualizar(this.model);
      } else {
        res = await this.custoRecursoService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Registro salvo com sucesso!',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        if (salvarEFechar) {
          this.router.navigate(['/custo-recurso']);
        }else
        if(this.novoRegistro){
          this.router.navigate([`/custo-recurso/${res.dados.id}`]);
          await this.ngOnInit();
        }
      } else {
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(validacoes);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.router.navigate(['/custo-recurso']);
  }

}
