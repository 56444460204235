import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as cloneDeep from 'lodash/cloneDeep';
import { ICriterioModel } from 'src/app/models/criterio.model';

@Component({
  selector: 'app-modal-cadastrar-criterio',
  templateUrl: './modal-cadastrar-criterio.component.html',
  styleUrls: ['./modal-cadastrar-criterio.component.scss']
})
export class ModalCadastrarCriterioComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  public todosCriterios = [] as ICriterioModel[];
  public semDados = true;
  public displayedColumns = ['selecao', 'nome', 'tipoEntrada'];
  public searchNome: string;
  public selectedModel: any;
  public valorExibicao = 1;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<ModalCadastrarCriterioComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.todosCriterios = data;
    this.dataSource.data = this.todosCriterios;
    this.dataSource._updateChangeSubscription();
  }

  ngOnInit(): void {
    this.sort.sort(({ id: 'nome', start: 'asc'}) as MatSortable);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public buscar() {
    
    this.selectedModel = null;
    if(this.searchNome && this.searchNome != ""){
      this.dataSource.data = this
                              .todosCriterios
                              .filter(a => 
                                          a
                                          .nome
                                          .normalize('NFD')
                                          .replace(/\p{Diacritic}/gu, "")
                                          .toLocaleLowerCase()
                                          .includes(this
                                                      .searchNome
                                                      .normalize('NFD')
                                                      .replace(/\p{Diacritic}/gu, "")
                                                      .toLocaleLowerCase())
                                        || a
                                        .tipoEntrada.descricao
                                        .normalize('NFD')
                                        .replace(/\p{Diacritic}/gu, "")
                                        .toLocaleLowerCase()
                                        .includes(this
                                                    .searchNome
                                                    .normalize('NFD')
                                                    .replace(/\p{Diacritic}/gu, "")
                                                    .toLocaleLowerCase()));
    }else{
      this.dataSource.data = this.todosCriterios;
    }

      this.dataSource._updateChangeSubscription();
      this.semDados = false;
    
  }

  public concluir() {
    this.dialogRef.close({criterio: this.selectedModel, valorExibicao: this.valorExibicao});
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel !== item ? item : null;
  }

  public itemAgrupador(index: number, item: any): boolean {
    return item.grupo;
  }

  public cancelar() {
    this.dialogRef.close();
  }
}
