export enum EnumTipoDocumentoAprovacao {
  NaoSelecionado = 0,
  PEAC = 1,
  PAAC = 2,
  OPA = 3,
  OSA = 4,
  MatrizConstatacoes = 5,
  RelatorioAuditoria = 6,
  Planejamento = 7,
  PAACEspecial =8,
  EntendendoEntidade = 9,
  MaturidadeGestaoRisco = 10
}