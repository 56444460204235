import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IConstatacaoRecomendacaoModel } from 'src/app/models/constatacao-recomendacao.model';
import { IRecomendacaoModel } from 'src/app/models/recomendacao.model';
import { RecomendacaoService } from 'src/app/services/recomendacao.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-modal-recomendacao',
  templateUrl: './modal-recomendacao.component.html',
  styleUrls: ['./modal-recomendacao.component.scss']
})

export class ModalRecomendacaoComponent implements OnInit {
  public modelConstatacaoRecomendacao: IConstatacaoRecomendacaoModel = {} as IConstatacaoRecomendacaoModel;
  public model: IRecomendacaoModel = {} as IRecomendacaoModel;

  constructor(
    private dialogRef: MatDialogRef<ModalRecomendacaoComponent>,
    public toastr: ToastrService,
    private recomendacaoService: RecomendacaoService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.modelConstatacaoRecomendacao = data;

  }

  config: AngularEditorConfig = {
    editable: false,
    spellcheck: false,
    sanitize: false,
    height: '10rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [[], []],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async ngOnInit() {
    const res = await this.recomendacaoService.obterPorId(this.modelConstatacaoRecomendacao.recomendacaoId);

    if (res.sucesso) {
      this.model = res.dados;
    }
  }


  public cancelar() {
    this.dialogRef.close(false);
  }
}

