import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IBaseModel } from 'src/app/models/base.model';
import { IOpaModel } from 'src/app/models/opa.model';
import { AprovacaoService } from 'src/app/services/aprovacao.service';
import { OpaService } from 'src/app/services/opa.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';

@Component({
  selector: 'app-opa-relatorio',
  templateUrl: './opa-relatorio.component.html',
  styleUrls: ['./opa-relatorio.component.scss'],
})

export class OpaRelatorioComponent extends BaseListComponent implements OnInit {
  opaId: number;
  homologado = false;
  opas: IOpaModel[] = [];
  idParam: number;
  espelho = false;
  consolidado = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private opaService: OpaService,
    private aprovacaoService: AprovacaoService,
  ) {
    super();
  }

  async ngOnInit() {

    const id = this.route.snapshot.paramMap.get('id');
    const path = this.route.snapshot.url.pop().toString();

    if (id) {
      this.idParam = parseInt(id, 10);
    }
    
    if (path === 'relatorio-consolidado') {
      this.consolidado = true;
    }

    if (path === 'espelho-relatorio') {
      this.espelho = true;
    }

    await this.obterDados();
  }

  public async obterDados() {
    try {
      let res: IBaseModel<IOpaModel []>;
      if(this.consolidado){
        const ids = this.route.snapshot.paramMap.get('id') || '';
         res = await this.opaService.imprimirOpaConsolidado(ids);
        }

      else if (this.espelho) {
        const avaliacao = await this.aprovacaoService.imprimir(this.idParam);
        res = {
          sucesso: avaliacao.sucesso,
          dados: JSON.parse(avaliacao.dados),
          mensagem: avaliacao.mensagem,
          resultadoValidacao: avaliacao.resultadoValidacao
        } as IBaseModel<IOpaModel[]>;
      }
      else {
        const avaliacao = await this.opaService.imprimir(this.idParam);
        res = {
          sucesso: avaliacao.sucesso,
          dados: Array.isArray(avaliacao.dados) ? avaliacao.dados : [avaliacao.dados], 
          mensagem: avaliacao.mensagem,
          situacao: avaliacao.situacao
        } as IBaseModel<IOpaModel[]>;
      }
      this.opas = res.dados;
      this.homologado = this.opas.some(item => item.situacao?.codigo === 4 || item.situacao?.codigo === 5);
    } catch (err) {
      console.log({err})
      this.exibirMensagemErro(err?.mensagem?.descricao);
      this.router.navigate(['/opa']);
    }
  }

  print(): void {
    window.print();
  }

  public cancelar() {
    this.router.navigate(['/opa']);
  }
}
