import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { ICriterioMetricaModel } from 'src/app/models/criterio-metrica.model';
import { ICriterioModel } from 'src/app/models/criterio.model';
import { IEnumModel } from 'src/app/models/enum.model';
import { CriterioService } from 'src/app/services/criterio.service';
import { PerguntaService } from 'src/app/services/pergunta.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { TipoCriterioService } from 'src/app/services/tipo-criterio.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { ModalMetricaNumericoComponent } from './modal-metrica-numerico/modal-metrica-numerico.component';
import { ModalMetricaOpcoesComponent } from './modal-metrica-opcoes/modal-metrica-opcoes.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-criterio-form',
  templateUrl: './criterio-form.component.html',
  styleUrls: ['./criterio-form.component.scss']
})
export class CriterioFormComponent extends BaseFormComponent implements OnInit {
  public enumTipoEntrada = [] as IEnumModel[];
  public enumTipoComparacao = [] as IEnumModel[];

  public model: ICriterioModel = {} as ICriterioModel;
  public metricasDataSource = new MatTableDataSource<ICriterioMetricaModel>([]);
  public semDados = true;
  public permitePergunta = false;

  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    nome: new FormControl('', Validators.required),
    tipoCriterioId: new FormControl(null, Validators.required),
    descricao: new FormControl('', Validators.required),
    tipoEntradaId: new FormControl(null, Validators.required),
    unidade: new FormControl(null, Validators.required),
    perguntaId: new FormControl(null),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private criterioService: CriterioService,
    private tabelaDominioService: TabelaDominioService,
    private tipoCriterioService: TipoCriterioService,
    private perguntaService: PerguntaService,
    public matDialog: MatDialog,
    private modalService: BsModalService
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Critério';
    }
  }

  async ngOnInit() {

    try {
      if (!this.novoRegistro) {
        const res = await this.criterioService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;


          if (this.visualizar) {
            this.titulo = 'Visualizar Critério - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Critério - ' + this.model.nome;
          }

          this.metricasDataSource = new MatTableDataSource<ICriterioMetricaModel>(this.model.criterioMetrica.sort((a, b) => (a.ordem > b.ordem) ? 1 : -1));
          this.metricasDataSource._updateChangeSubscription();
          this.semDados = this.metricasDataSource.filteredData.length === 0;
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
          this.router.navigate(['/criterios']);
          return;
        }
      }

      await Promise.all([
        this.tabelaDominioService.obterTipoEntradaCriterio().then((res) => this.enumTipoEntrada = res.dados),
        this.tabelaDominioService.obterTipoComparacao().then((res) => this.enumTipoComparacao = res.dados),
      ]);

      this.permitePergunta = this.model.tipoEntradaId === 3;
      this.form.updateValueAndValidity();
      this.form.patchValue(this.model);
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
      this.router.navigate(['/criterios']);
    } 
  }

  public onChangeTipoEntrada(event: any) {
    if (event.target.value === '3') {
      this.permitePergunta = true;
      this.form.controls['perguntaId'].setValidators(Validators.required);
    } else {
      this.permitePergunta = false;
      this.form.controls['perguntaId'].setValue(null);
      this.model.pergunta = null;
      this.model.perguntaId = null;
      this.form.controls['perguntaId'].clearValidators();
    }

    if (this.form.controls['tipoEntradaId'].value && (event.target.value === '3') !== (this.model.tipoEntradaId === 3)) {
      this.metricasDataSource = new MatTableDataSource<ICriterioMetricaModel>([]);
      this.metricasDataSource._updateChangeSubscription();
      this.semDados = true;
    }

    this.model.tipoEntradaId = parseInt(event.target.value, 10);

    this.form.controls['perguntaId'].updateValueAndValidity();
  }

  public async onSubmit(salvarEFechar: boolean = false) {
    if (this.form.invalid) {
      this.exibirMensagemAlerta("Formulário invalido");
      return;
    }
    this.submit = true;

    if (this.metricasDataSource.data.length === 0) {
      this.exibirMensagemAlerta("É necessário incluir pelo menos uma métrica");
      return;
    }

    Object.assign(this.model, this.form.value);
    this.model.criterioMetrica = this.metricasDataSource.data;

    if (this.model.tipoEntradaId.toString() !== '3') {
      delete (this.model.perguntaId);
      delete (this.model.pergunta);
    }

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        res = await this.criterioService.atualizar(this.model);
      } else {
        res = await this.criterioService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        if (salvarEFechar) {
        this.router.navigate(['/criterios']);
        }
        else
          if(this.novoRegistro){
            this.router.navigate([`/criterios/${res.dados.id}`]);
            await this.ngOnInit();
          }
      } else {
        this.submit = false;
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    } catch (err) {
      this.submit = false;
      this.exibirMensagemErro(err.mensagem.descricao);
    } 
  }

  public excluirMetrica(ind: number) {
    this.metricasDataSource.data.splice(ind, 1);
    this.metricasDataSource._updateChangeSubscription();
    this.semDados = this.metricasDataSource.filteredData.length === 0;
  }

  public onBack() {
    this.router.navigate(['/criterios']);
  }

  public async exibirModalTipoCriterio() {
    try {
      const res = await this.tipoCriterioService.obter('', true);
      if (res.sucesso) {
        const modal = this.exibirModal('Selecionar Tipo Criterio', res.dados, false, [
          {
            columnDef: 'selecao',
            header: ''
          },
          {
            columnDef: 'nome',
            header: 'Nome'
          }]);
        modal.afterClosed().subscribe(data => {
          if (data) {
            this.model.tipoCriterio = data;
            this.form.controls['tipoCriterioId'].setValue(data.id);
          }
        }
        );
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public async exibirModalPergunta() {
    try {
      const res = await this.perguntaService.obter('', true);
      if (res.sucesso) {
        const modal = this.exibirModal('Selecionar Pergunta', res.dados, false, [
          {
            columnDef: 'selecao',
            header: ''
          },
          {
            columnDef: 'nome',
            header: 'Nome'
          }]);
        modal.afterClosed().subscribe(data => {
          if (data) {
            if (this.model.pergunta?.id !== data.id) {
              this.metricasDataSource = new MatTableDataSource<ICriterioMetricaModel>([]);
              this.metricasDataSource._updateChangeSubscription();
              this.semDados = true;
            }

            this.model.pergunta = data;
            this.form.controls['perguntaId'].setValue(data.id);
            this.form.updateValueAndValidity();
          }
        }
        );
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public async exibirModalMetrica(element?: ICriterioMetricaModel, index?: number) {
    if (!this.model.tipoEntradaId) {
      this.exibirMensagemAlerta('É necessário selecionar o Tipo de Entrada antes de definir as métricas');
      return;
    }

    if (this.model.tipoEntradaId === 3 && !this.model.pergunta) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: 'É necessário selecionar uma pergunta antes de definir as métricas',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return;
    }

    let modal: any;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;

    if (this.model.tipoEntradaId === 3 && this.model.pergunta.tipoEntradaId === 3) {
      const data = {
        listaOpcoes: this.model.pergunta.perguntaOpcao.filter(t => this.metricasDataSource.data.findIndex(d => d.perguntaOpcaoId.toString() === t.id.toString()) < 0),
        element
      };

      if (data.listaOpcoes.length === 0 && !element) {
        this.exibirMensagemAlerta('Todas as perguntas já possuem nota');
        return;
      }

      dialogConfig.data = data;
      modal = this.matDialog.open(
        ModalMetricaOpcoesComponent,
        dialogConfig
      );

    } else {
      const data = {
        tipoEntrada: this.model.pergunta?.tipoEntradaId === 1 ? 'inteiro' : 'decimal',
        enumTipoComparacao: this.enumTipoComparacao,
        element
      };

      dialogConfig.data = data;
      modal = this.matDialog.open(
        ModalMetricaNumericoComponent,
        dialogConfig
      );
    }

    modal.afterClosed().subscribe(data => {
      if (data) {
        if (element) { this.metricasDataSource.data.splice(index, 1); }
        this.metricasDataSource.data.push(data);
        this.metricasDataSource._updateChangeSubscription();
        this.semDados = false;
      }
    });
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.metricasDataSource.data, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.metricasDataSource.data.length; i++) {
      this.metricasDataSource.data[i].ordem = i + 1;
    }

    this.metricasDataSource._updateChangeSubscription();
  }
}
