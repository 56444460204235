<div id="modal-content-wrapper">
	<div class="card card-secondary card-outline">
		<div class="card-header bg-light">
			<h4 class="card-title mb-0">Adicionar Métrica</h4>
			<div class="card-tools">
				<button type="button" class="btn btn-tool" (click)="cancelar()">
					<i class="fas fa-times"></i>
				</button>
			</div>
		</div>
		<div class="card-body p-0">
			<div class="table-container">
				<form
					[formGroup]="formMetrica"
					#f="ngForm"
					(ngSubmit)="concluir()"
					(keydown.enter)="$event.preventDefault()"
				>
					<div class="row p-2">
						<div class="form-group col-md-12">
							<label for="subPerguntas" class="mb-1"
								>Pergunta</label
							>
							<div
								class="input-group input-group-sm"
								style="width: 100%"
							>
								<select
									id="subPerguntas"
									formControlName="subPergunta"
									[attr.disabled]="visualizar ? true : null"
									[ngModel]="model.id"
									class="form-control form-control-sm"
									[ngClass]="{
										'is-invalid':
											f.submitted &&
											form.controls['model.id']?.invalid
									}"
								>
									<option
										*ngFor="let item of perguntas"
										[value]="item.nome"
									>
										{{ item.nome }}
									</option>
								</select>
							</div>
							<div
								*ngIf="
									f.submitted &&
									form.controls['model.id'].invalid
								"
								class="invalid-feedback"
							>
								<div
									*ngIf="
										form.controls['model.id'].errors
											?.required
									"
								>
									Campo obrigatório
								</div>
							</div>
						</div>

						<div class="form-group col-md-3">
							<label for="selectOperacao1" class="mb-1"
								>Operação</label
							>
							<select
								id="selectOperacao1"
								formControlName="operacao1Id"
								class="form-control form-control-sm"
								[ngClass]="{
									'is-invalid':
										formMetrica.controls['operacao1Id']
											.touched &&
										formMetrica.controls['operacao1Id']
											.invalid
								}"
							>
								<option
									*ngFor="let item of enumTipoComparacao"
									[value]="item.codigo"
								>
									{{ item.descricao }}
								</option>
							</select>
						</div>

						<div class="form-group col-md-2">
							<label for="valorNumerico1" class="mb-1"
								>Valor</label
							>
							<input
								*ngIf="tipoEntrada === 'inteiro'"
								type=" text"
								id="valorNumerico1"
								class="form-control form-control-sm"
								data-inputmask="'alias': 'numeric', 'radixPoint': ',',
                  'digits': 2, 'digitsOptional': false, 'placeholder': '0', 'groupSeparator': '.'"
								inputmode="numeric"
								formControlName="valorNumerico1"
								[ngClass]="{
									'is-invalid':
										formMetrica.controls['valorNumerico1']
											.touched &&
										formMetrica.controls['valorNumerico1']
											.invalid
								}"
								maxlength="18"
							/>

							<input
								*ngIf="tipoEntrada === 'decimal'"
								type=" text"
								id="valorNumerico1"
								class="form-control form-control-sm"
								data-inputmask="'alias': 'numeric', 'radixPoint': ',',
                      'digits': 2, 'digitsOptional': false, 'placeholder': '0', 'groupSeparator': '.'"
								formControlName="valorNumerico1"
								[ngClass]="{
									'is-invalid':
										formMetrica.controls['valorNumerico1']
											.touched &&
										formMetrica.controls['valorNumerico1']
											.invalid
								}"
								maxlength="18"
							/>
							<input
								*ngIf="tipoEntrada === 'multiplaEscolha'"
								type=" text"
								id="valorNumerico1"
								class="form-control form-control-sm"
								data-inputmask="'alias': 'numeric', 'radixPoint': ',',
                      'digits': 2, 'digitsOptional': false, 'placeholder': '0', 'groupSeparator': '.'"
								formControlName="valorNumerico1"
								[ngClass]="{
									'is-invalid':
										formMetrica.controls['valorNumerico1']
											.touched &&
										formMetrica.controls['valorNumerico1']
											.invalid
								}"
								maxlength="18"
							/>
						</div>

						<div class="form-group col-md-3">
							<label for="selectOperacao2" class="mb-1"
								>Operação</label
							>
							<select
								id="selectOperacao2"
								formControlName="operacao2Id"
								class="form-control form-control-sm"
								[ngClass]="{
									'is-invalid':
										formMetrica.controls['operacao2Id']
											.touched &&
										formMetrica.controls['operacao2Id']
											.invalid
								}"
							>
								<option
									*ngFor="let item of enumTipoComparacao"
									[value]="item.codigo"
								>
									{{ item.descricao }}
								</option>
							</select>
						</div>

						<div class="form-group col-md-2">
							<label for="valorNumerico2" class="mb-1"
								>Valor</label
							>
							<input
								*ngIf="tipoEntrada === 'inteiro'"
								type=" text"
								id="valorNumerico2"
								class="form-control form-control-sm"
								data-inputmask="'alias': 'numeric', 'radixPoint': ',',
                      'digits': 2, 'digitsOptional': false, 'placeholder': '0', 'groupSeparator': '.'"
								inputmode="numeric"
								formControlName="valorNumerico2"
								[ngClass]="{
									'is-invalid':
										formMetrica.controls['valorNumerico2']
											.touched &&
										formMetrica.controls['valorNumerico2']
											.invalid
								}"
								maxlength="18"
							/>

							<input
								*ngIf="tipoEntrada === 'decimal'"
								type=" text"
								id="valorNumerico2"
								class="form-control form-control-sm"
								data-inputmask="'alias': 'numeric', 'radixPoint': ',',
                      'digits': 2, 'digitsOptional': false, 'placeholder': '0', 'groupSeparator': '.'"
								formControlName="valorNumerico2"
								[ngClass]="{
									'is-invalid':
										formMetrica.controls['valorNumerico2']
											.touched &&
										formMetrica.controls['valorNumerico2']
											.invalid
								}"
								maxlength="18"
							/>
							<input
								*ngIf="tipoEntrada === 'multiplaEscolha'"
								type=" text"
								id="valorNumerico2"
								class="form-control form-control-sm"
								data-inputmask="'alias': 'numeric', 'radixPoint': ',',
                      'digits': 2, 'digitsOptional': false, 'placeholder': '0', 'groupSeparator': '.'"
								formControlName="valorNumerico2"
								[ngClass]="{
									'is-invalid':
										formMetrica.controls['valorNumerico2']
											.touched &&
										formMetrica.controls['valorNumerico2']
											.invalid
								}"
								maxlength="18"
							/>
						</div>

						<div class="form-group col-md-2">
							<label for="inputId" class="mb-1">Nota</label>
							<input
								type="text"
								class="form-control form-control-sm float-right"
								formControlName="nota"
								data-inputmask="'alias': 'numeric', 'radixPoint': ',',
                      'digits': 2, 'digitsOptional': false, 'placeholder': '0', 'groupSeparator': '.'"
								[ngClass]="{
									'is-invalid':
										formMetrica.controls['nota'].touched &&
										formMetrica.controls['nota'].invalid
								}"
								maxlength="18"
							/>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="card-footer">
			<button
				type="button"
				class="btn btn-primary btn-sm mr-1"
				(click)="concluir()"
			>
				<i class="fas fa-check"></i> Adicionar
			</button>

			<button
				type="button"
				class="btn btn-default btn-sm"
				(click)="cancelar()"
			>
				<i class="fas fa-times"></i> Cancelar
			</button>
		</div>
	</div>
</div>
