import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-concordar',
  templateUrl: './modal-concordar.component.html',
  styleUrls: ['./modal-concordar.component.scss']
})
export class ModalConcordarComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalConcordarComponent>) { }

  onConfirm(): void {
    // Ação de confirmação
    this.dialogRef.close(true);
  }

  onCancel(): void {
    // Ação de cancelamento
    this.dialogRef.close(false);
  }

  ngOnInit(): void {
  }

}
