<div #abaTimeLine [hidden]="aba !== 6">
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Linha do Tempo</h1>
                </div>
            </div>
        </div>
    </section>
    <div>
        <div class="timeline m-0" *ngFor="let element of model.datas; let i = index">
            <div class="time-label">
                <span *ngIf="element.data" class="bg-green">{{ element.data | date: 'dd MMM yyyy' }}</span>
            </div>
            <div *ngFor="let item of element.acoes">
                <i *ngIf="item.acao?.codigo === 0" class="fas fa-clock bg-gray"></i>
                <i *ngIf="item.acao?.codigo === 1" class="fas fa-check bg-warning"></i>
                <i *ngIf="item.acao?.codigo === 2" class="fas fa-exclamation-circle bg-pink"></i>
                <i *ngIf="item.acao?.codigo === 3" class="fas fa-envelope bg-info"></i>
                <i *ngIf="item.acao?.codigo === 4" class="fas fa-check bg-success"></i>
                <div class="timeline-item">

                    <span *ngIf="item.data" class="time"><i class="fas fa-clock"></i> {{ item.data | date: 'HH:mm' }}</span>

                    <button *ngIf="item.data" mat-icon-button color="gray" class="time" (click)="imprimir(item.id) " >
                        <mat-icon class="print">print</mat-icon>
                    </button>

                    <button *ngIf="item.data" mat-icon-button color="gray" class="time" (click)="imprimirRelatoria(item.id)">
                        <mat-icon class="print">description</mat-icon>
                    </button>

                    <h3 *ngIf="item.acao?.codigo === 0" class="timeline-header">
                        <a>{{ model?.tipoDocumento?.descricao }} criado com sucesso.</a>
                    </h3>

                    <h3 *ngIf="item.acao?.codigo === 1" class="timeline-header">
                        <a>{{ item.pessoa?.nome }}({{ item.pessoa?.funcao?.descricao }}) aprovou o {{ model?.tipoDocumento?.descricao }}</a>
                    </h3>

                    <h3 *ngIf="item.acao?.codigo === 2" class="timeline-header">
                        <a>{{ item.pessoa?.nome }}({{ item.pessoa?.funcao?.descricao }}) reprovou o {{ model?.tipoDocumento?.descricao }}</a>
                    </h3>

                    <h3 *ngIf="item.acao?.codigo === 3" class="timeline-header">
                        <a>{{ item.pessoa?.nome }}({{ item.pessoa?.funcao?.descricao }}) enviou o {{ model?.tipoDocumento?.descricao }} para aprovação</a>
                    </h3>

                    <h3 *ngIf="item.acao?.codigo === 4" class="timeline-header">
                        <a>{{ model?.tipoDocumento?.descricao }} Homologado!</a>
                    </h3>
                    <div *ngIf="item.comentario && item.acao?.codigo != 4" class="timeline-body">{{ item.comentario }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card-footer" *ngIf="mostrarBotaoFechar()">
    <button
        type="button"
        class="btn btn-default btn-sm mr-1"
        (click)="cancelar()"
    >
        <i class="fas fa-times"></i> Fechar
    </button>
</div>