<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-12">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/']">
                            <i class="fas fa-home"></i> Página Inicial</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/osa']">OSA - Ordem de Serviço de Auditoria</a>
                    </li>
                    <li class="breadcrumb-item active"> {{ titulo }} </li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="content-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-lightblue">
                    <div class="card-header d-flex pb-0 flex-column">
                        <h3 class="card-title mb-0">
                            <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
                            <i *ngIf="!this.id" class="far fa-file pr-2"></i>
                            {{ titulo }}
                          </h3>
                            <!-- Componente abas -->
                            <app-osa-abas [visualizar]="visualizar" [id]="id" [obterDados]="obterPlanejamentoId.bind(this)" (abaEmitter)="mudarAba($event)" [planejamentoId]="planejamentoId"></app-osa-abas>
                    </div>
                    <div class="card-footer">
                        <!-- sibling 1 -->
                        <button *ngIf="!visualizar && aba === 0 && !versao" type="button" class="btn btn-primary btn-sm mr-1" (click)="onSalvarOsa()">
                            <i class="fas fa-save"></i> Salvar
                        </button>

                        <button *ngIf="!visualizar && aba === 5 && !isFilha" [disabled]="!model.habilitarEnviarAprovacao" type="button" class="btn btn-warning btn-sm mr-1" (click)="enviarParaAprovacao()">
                            <i class="far fa-save"></i> Enviar para aprovação
                        </button>

                        <button type="button" class="btn btn-default btn-sm" (click)="onBack()">
                            <i class="fas fa-times"></i> Fechar
                        </button>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
