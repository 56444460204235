import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { IPeaCriterioQuestionarioModel } from 'src/app/models/pea-criterio-questionario.model';
import { IBaseModel } from 'src/app/models/base.model';
import { IQuestionarioModel } from 'src/app/models/questionario.model';
import { QuestionarioService } from 'src/app/services/questionario.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import { IListaQuestionarioRespostaUGModel, IQuestionarioUGModel } from 'src/app/models/questionario-ug.model';
import { EntendendoEntidadeService } from 'src/app/services/entendendo-entidade.service';
import { FiltroQuestionarioUG } from 'src/app/models/filtro-questionario-ug.model';

const sortingDataAccessor = (item, property) => {
  
  switch (property) {
    case 'nomefuncionalidade':
      return item.nomefuncionalidade.trim().toLowerCase();;
    case 'ug':
      return item.ugSigla.trim().toLowerCase();;
    case 'responsavel':
      return item.nomeResponsavel.trim().toLowerCase();;
    case 'dataEnvio':
      return item.dataCriacaoFormatada.trim().toLowerCase();;
    case 'dataPrazo':
      return item.dataPrazo.trim().toLowerCase();;
    default:
      return item[property];
  }
}

@Component({
  selector: 'app-pre-planejamento-list',
  templateUrl: './pre-planejamento-list.component.html',
  styleUrls: ['./pre-planejamento-list.component.scss']
})
export class PrePlanejamentoListComponent extends BaseListComponent {

  public dataSource = new MatTableDataSource<IListaQuestionarioRespostaUGModel>([]);
  public selectedModel: IListaQuestionarioRespostaUGModel;
  public searchNome: string;
  public filtro = {} as FiltroQuestionarioUG;
  public displayedColumns: string[] =  ['selecao', 'nomeFuncionalidade', 'siglaUG', 'responsavel', 'dataEnvio', 'dataPrazo', 'status'];
  public totalItens: number;
  public pageEvent: any;
  public semDados = true;
  private segmentoUrl: string;
  public documento: string;
  public isPEA: boolean;

@ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
@ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private questionarioUGService: EntendendoEntidadeService,
    private router: Router,
    private toastr: ToastrService,
  ) { 
    super();
    this.buscar();
  }

  sortData(sort) {
    this.filtro.colunaOrder = sort.active;
    this.filtro.direcao = sort.direction;
  
    this.buscar();
  }

  public buscar() {
    this.selectedModel = null;
    this.filtro.pesquisa = this.searchNome;
    this.questionarioUGService
      .ObterQuestionarioPendente(this.filtro)
      .then((res) => {
        this.totalItens = res.dados.totalItens;
        this.dataSource = new MatTableDataSource<IListaQuestionarioRespostaUGModel>(res.dados.resultado);
        this.totalItens = res.dados.totalItens;
        this.paginator = this.tableOnePaginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = sortingDataAccessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem);
      })
  }

  public responder(id : number) {
    this.router.navigate([`questionario/pre-planejamento-resposta/${id}`]);
  }
  public print(id : number){
    this.router.navigate([`questionario/pre-planejamento-resposta/${id}/imprimir`]);
  }

  public visualizarQuestionario(id: number) {
    this.router.navigate([`questionario/pre-planejamento-resposta/${id}/visualizar`]);
   }



  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.documentoId ? item : null;
  }

 
 
 
  ngOnInit(): void {
  }

}
