<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item active">{{titulo}}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{titulo}}
            </h3><br />
          </div>
          <div class=" card-body">
            <table #table id="table" mat-table [dataSource]="dataSource" class="w-100" class="mat-elevation-z2"
              cdkDropList [cdkDropListData]="dataSource.data" (cdkDropListDropped)="drop($event)">

              <tr mat-header-row *matHeaderRowDef="['subtitulo']"></tr>

              <ng-container matColumnDef="subtitulo">
                <th mat-header-cell *matHeaderCellDef colspan="3"> Selecione os arquivos PEAC que deseja enviar para o
                  E-Docs e arraste para definir sua ordem</th>
              </ng-container>

              <ng-container matColumnDef="selecao">
                <th mat-header-cell *matHeaderCellDef>Enviar</th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                    (change)="element.enviarParaEdocs = $event.checked" [checked]="element.enviarParaEdocs">
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="documento">
                <th mat-header-cell *matHeaderCellDef>Documento</th>
                <td mat-cell *matCellDef="let element"> {{element?.tipoDocumento?.descricao}} </td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: ['selecao', 'documento'];" cdkDrag></tr>
              </table>

            <br/>

            <table #table id="tablePAA" mat-table [dataSource]="dataSourcePAA" class="w-100" class="mat-elevation-z2"
              cdkDropList [cdkDropListData]="dataSourcePAA.data" (cdkDropListDropped)="dropPAA($event)">

              <tr mat-header-row *matHeaderRowDef="['subtitulo']"></tr>

              <ng-container matColumnDef="subtitulo">
                <th mat-header-cell *matHeaderCellDef colspan="3"> Selecione os arquivos PAAC que deseja enviar para o
                  E-Docs e arraste para definir sua ordem</th>
              </ng-container>

              <ng-container matColumnDef="selecao">
                <th mat-header-cell *matHeaderCellDef>Enviar</th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                    (change)="element.enviarParaEdocs = $event.checked" [checked]="element.enviarParaEdocs">
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="documento">
                <th mat-header-cell *matHeaderCellDef>Documento</th>
                <td mat-cell *matCellDef="let element"> {{element?.tipoDocumento?.descricao}} </td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: ['selecao', 'documento'];" cdkDrag></tr>
            </table>
          </div>

          <div class="card-footer">
            <button type="button" class="btn btn-primary btn-sm mr-1" (click)="onSubmit()">
              <i class="far fa-save"></i> Salvar
            </button>

            <button type="button" class="btn btn-default btn-sm" (click)="onBack()">
              <i class="fas fa-times"></i> Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
