<div id="modal-content-wrapper">
    <div class="card card-secondary card-outline">
      <div class="card-header bg-light">
        <h4 class="card-title mb-0">Selecionar Modelo de Questionário</h4>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" (click)="cancelar()"><i
              class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <mat-toolbar class="table-header">
          <mat-toolbar-row class="px-1">
            <div class="input-group input-group-sm" style="width: 100%;">
              <input type="text" name="table_search" class="form-control
                float-right" placeholder="Filtrar por..."
                (keyup)="filtrar($event)" />
            </div>
          </mat-toolbar-row>
        </mat-toolbar>
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" matSort matSortActive="nome"
            matSortDirection="asc"
            matSortDisableClear [hidden]="semDados">
  

            <ng-container matColumnDef="selecao">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()" 
                        color="primary" 
                        (change)="selecionado= ($event.checked ? element : null)"
                        [checked]="selecionado?.id === element.id">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="nome">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Nome
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.nome}} </td>
            </ng-container>

            <ng-container matColumnDef="tipo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Tipo entrada
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.tipoEntrada.descricao}} </td>
            </ng-container>

            <ng-container matColumnDef="obrigatorio">
                <th mat-header-cell *matHeaderCellDef> Obrigatório </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()" 
                        color="primary" 
                        [(ngModel)]="element.obrigatorio"
                        [checked]="element?.obrigatorio">
                    </mat-checkbox>
                </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              (click)="selecionar(row)" class="clickable"
              [ngClass]="{'bg-light': selecionado?.id === row.id}"></tr>
  
          </table>
          <h5 [hidden]="!semDados" class="table-no-data">Sem dados a exibir</h5>
        </div>
        <mat-paginator [hidden]="semDados" 
            [pageSizeOptions]="[5]"
            showFirstLastButtons
            [length]="totalItens"></mat-paginator>
      </div>
      <div class="card-footer">
        <button type="button" class="btn btn-primary btn-sm mr-1"
          (click)="concluir()" [disabled]="!selecionado">
          <i class="fas fa-check"></i> Selecionar
        </button>
  
        <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
          <i class="fas fa-times"></i> Cancelar
        </button>
      </div>
    </div>
  </div>
  