import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class DateHelper{
    private datePipe = new DatePipe('pt-BR');
    
    toDatePtBr(date: Date){
        if (date) {
            return this.truncateDate(moment(date, 'DD/MM/YYYY').toDate());
        }
        return date;
    }

    toStringPtBr(date: Date) {
        if (date) {
            return this.datePipe.transform(date, 'dd/MM/yyyy');
        } else {
            return '';
        }
    }

    toString(date: Date) {
        if (date) {
            return this.datePipe.transform(date, 'MM/dd/yyyy');
        } else {
            return '';
        }
    }

    truncateDate(date: Date){
        if (date) {
            return new Date(this.datePipe.transform(date, 'yyyy-MM-dd'));
        }
        return date;
    }
}
