<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-12">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/']">
                            <i class="fas fa-home"></i>
                            Página Inicial</a>
                    </li>
                    <li class="breadcrumb-item active">Recomendação</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-lightblue">
                    <div class="card-header">
                        <h3 class="card-title mb-0">
                            <i class="fas fa-table pr-2"></i>
                            Recomendação
                        </h3>
                    </div>

                    <div class="card-body p-2">
                        <div class="mat-elevation-z4">
                            <mat-toolbar class="table-header">
                                <mat-toolbar-row>
                                    <button type="button" class="btn btn-success btn-sm mr-1" (click)="novo()"
                                        [hidden]="!permissoes.incluir">
                                        <i class="far fa-file"></i> Novo
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm mr-1"
                                        [disabled]="!selectedModel || !selectedModel.ativo || !permissoes.inativarReativar"
                                        [hidden]="!permissoes.editar" (click)="editar(selectedModel.id)">
                                        <i class="far fas fa-pen"></i> Editar
                                    </button>
                                    <button type="button" class="btn btn-warning btn-sm mr-1"
                                        [hidden]="!selectedModel || selectedModel.ativo || !permissoes.inativarReativar"
                                        mwlConfirmationPopover popoverTitle="Ativar"
                                        popoverMessage="Tem certeza que deseja reativar essa recomendação"
                                        placement="bottom" confirmText="Sim" cancelText="Não"
                                        (confirm)="reativar(selectedModel.id)">
                                        <i class="far fa-play-circle"></i>
                                        Reativar
                                    </button>
                                    <button type="button" class="btn btn-warning btn-sm mr-1"
                                        [hidden]="(selectedModel && !selectedModel.ativo) || !permissoes.inativarReativar"
                                        [disabled]="!selectedModel?.ativo" mwlConfirmationPopover
                                        popoverTitle="Inativar"
                                        popoverMessage="Tem certeza que deseja inativar essa recomendação?"
                                        placement="bottom" confirmText="Sim" cancelText="Não"
                                        (confirm)="inativar(selectedModel.id)">
                                        <i class="far fa-pause-circle"></i>
                                        Inativar
                                    </button>
                                    <button type="button" class="btn btn-danger btn-sm mr-1" [disabled]="!selectedModel"
                                        mwlConfirmationPopover popoverTitle="Excluir" [hidden]="!permissoes.excluir"
                                        popoverMessage="Tem certeza que deseja excluir essa recomendação?"
                                        placement="bottom" confirmText="Sim" cancelText="Não"
                                        (confirm)="excluir(selectedModel.id)">
                                        <i class="far fa-trash-alt"></i> Excluir
                                    </button>
                                    <button type="button" class="btn btn-info btn-sm mr-1" [disabled]="!selectedModel"
                                        [hidden]="!permissoes.visualizar" (click)="visualizar(selectedModel.id)">
                                        <i class="far fas fa-eye"></i> Visualizar
                                    </button>
                                    <span class="fill-remaining-space"></span>
                                    <div class="input-group input-group-sm" style="width: 250px;">
                                        <input type="text" name="table_search" class="form-control float-right"
                                            (keyup.enter)="buscar()" placeholder="Pesquisar por recomendação"
                                            [(ngModel)]="searchNome" />

                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-info" (click)="buscar()">
                                                <i class="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="btn-group">
                                        <button type="button" data-toggle="dropdown"
                                            class="btn btn-tool btn-default dropdown-toggle">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                        <div role="menu"
                                            class="dropdown-menu dropdown-menu-right dropdown-menu-checkbox">
                                            <a (click)="searchAtivo = undefined; buscar()" class="dropdown-item"><i
                                                    [ngClass]="
                            searchAtivo === undefined
                              ? 'fas fa-check-circle'
                              : 'far fa-circle'
                          "></i>Exibir todos</a>
                                            <a (click)="searchAtivo = true; buscar()" class="dropdown-item"><i
                                                    [ngClass]="
                            searchAtivo === true
                              ? 'fas fa-check-circle'
                              : 'far fa-circle'
                          "></i>Exibir somente ativos</a>
                                            <a (click)="searchAtivo = false; buscar()" class="dropdown-item"><i
                                                    [ngClass]="
                            searchAtivo === false
                              ? 'fas fa-check-circle'
                              : 'far fa-circle'
                          "></i>Exibir somente inativos</a>
                                        </div>
                                    </div>
                                </mat-toolbar-row>
                            </mat-toolbar>
                            <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear
                                class="mat-elevation-z8" matSortActive="id" matSortDirection="asc" [hidden]="semDados">
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                                <ng-container matColumnDef="selecao">
                                    <th mat-header-cell *matHeaderCellDef> </th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                                            (change)="selectedModel = ($event.checked ? element : null);"
                                            [checked]="selectedModel?.id === element.id">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>


                                <ng-container matColumnDef="nome">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Recomendação </th>
                                    <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
                                </ng-container>

                                <ng-container matColumnDef="marco">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Marco </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="element.tipoRecomendacao == 1">Interna</span>
                                        <span *ngIf="element.tipoRecomendacao == 2">Externa</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="ativo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Situação </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="element.ativo" class="badge bg-success text-xs">Ativo</span>
                                        <span *ngIf="!element.ativo" class="badge bg-gray-dark text-xs">Inativo</span>
                                    </td>
                                </ng-container>

                                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selecionar(row)"
                                    class="clickable" [ngClass]="{'bg-light': selectedModel?.id === row.id}"></tr>
                            </table>
                            <h5 [hidden]="!semDados" class="table-no-data">Sem dados a exibir</h5>
                            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                                (page)="selectedModel = null">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>