<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">Visualizar Questionário</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="fechar()"><i
            class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-2">
      <div class="row">
        <div class="form-group col-md-3">
          <label for="prazoDias" class="mb-1">Prazo em dias úteis</label>
          <input
            type="text"
            id="prazoDias"
            class="form-control form-control-sm"
            readonly
            [ngModel]="model?.prazoDias"
            type="number"
            />
        </div>

        <div class="form-group col-md-3">
          <label for="dataEnvio" class="mb-1">Data do Envio</label>
          <input
            type="text"
            id="prazoDias"
            class="form-control form-control-sm"
            readonly
            [ngModel]="model?.dataCriacaoFormatada"
            type="text"
            />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="mat-elevation-z1">
            <table mat-table [dataSource]="criterioDataSource">
              <tr
                mat-header-row
                *matHeaderRowDef="['pergunta', 'tipoEntrada', 'unidade']"
                class="bg-light"
              ></tr>
  
              <ng-container matColumnDef="pergunta">
                <th mat-header-cell *matHeaderCellDef>Pergunta</th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.pergunta?.nome }}
                </td>
              </ng-container>
  
              <ng-container matColumnDef="tipoEntrada">
                <th mat-header-cell *matHeaderCellDef>Tipo de Entrada</th>
                <td mat-cell *matCellDef="let element">
                  {{element?.pergunta?.tipoEntrada?.descricao}}
                </td>
              </ng-container>
  
              <ng-container matColumnDef="unidade">
                <th mat-header-cell *matHeaderCellDef> Unidade de Medida </th>
                <td mat-cell *matCellDef="let element">
                  {{element?.unidade}}
                </td>
              </ng-container>
  
              <tr
                mat-row
                *matRowDef="
                  let row;
                  columns: ['pergunta', 'tipoEntrada', 'unidade']
                "
              ></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>


    <div class="card-footer">
      <button type="button" class="btn btn-default btn-sm" (click)="fechar()">
        <i class="fas fa-times"></i> Fechar
      </button>
    </div>
  </div>
