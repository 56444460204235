import { Component, NgModule, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IEnumModel } from 'src/app/models/enum.model';
import { IQuestionarioUGModel, IVisualizarQuestionarioRespostaUGModel, QuestionarioUGItensResposta } from 'src/app/models/questionario-ug.model';
import { AuthService } from 'src/app/services/auth.service';
import { EntendendoEntidadeService } from 'src/app/services/entendendo-entidade.service';
import { PerguntaService } from 'src/app/services/pergunta.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { IListaQuestionarioRespostaUGModel } from 'src/app/models/questionario-ug.model';
import { QuestionarioUGRespostaService } from 'src/app/services/questionarioug-resposta.service';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-visualizar-entendendo-entidade',
  templateUrl: './visualizar-entendendo-entidade.component.html',
  styleUrls: ['./visualizar-entendendo-entidade.component.scss']
})

export class VisualizarEntendendoEntidadeComponent extends BaseFormComponent implements OnInit {
  public model= {} as IListaQuestionarioRespostaUGModel;
  public tiles = [];
  public idUnidadeGestora : number;
  public semDados: boolean;
  public dataSource = new MatTableDataSource<QuestionarioUGItensResposta>([]);
  displayedColumns: string[] = ['pergunta','tipoAvaliacaoAuditor', 'avaliacaoAuditor', 'tipoReplica', 'replica', 'avaliacaoFinal', 'tipoAvaliacaoFinal'];

  constructor(
    private questionarioUGService: EntendendoEntidadeService,
    toastr: ToastrService,
    route: ActivatedRoute,
    router: Router,
    private authService: AuthService,
    localeService: BsLocaleService,
    private perguntaService: PerguntaService,
    private tabelaDominioService: TabelaDominioService,
    private questionarioService: QuestionarioUGRespostaService,
    public matDialog: MatDialog,
    public matGridListModule: MatGridListModule
  ) { 
    super(route, toastr, router, localeService, matDialog);
  }

  public form = new FormGroup({
    id: new FormControl({value: '', disabled:true}),
    nome: new FormControl('', Validators.required),
    tipoDocumento: new FormControl({value:[] as IEnumModel[]})
  })

  async ngOnInit() {

    if(!this.novoRegistro){
      this.questionarioService.ObterRespostaQuestionario(this.id)
      .then((res) => {
        this.model = res.dados;
        console.log(res.dados);
        this.titulo = "Visualizar Questionário - " + this.model.nomeQuestionario.toString();
        this.dataSource = new MatTableDataSource<QuestionarioUGItensResposta>(res.dados.respostas);
      });
    }
  }

  public onBack() {
    
    this.submit = false;
    
    this.router.navigateByUrl(`/unidades-gestoras/${this.model.idUG}?aba=1`);
  }

}
