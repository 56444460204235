import { Component, OnInit, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { IRelatoriaCapituloModel } from 'src/app/models/relatoria-capitulo.model';
import { IRelatoriaModel } from 'src/app/models/relatoria.model';
import { RelatoriaService } from 'src/app/services/relatoria.service';
import { IEnumModel } from 'src/app/models/enum.model';
import Swal from 'sweetalert2';
import { ModeloRelatorioService } from 'src/app/services/modelo-relatorio.service';
import { BaseFormComponent } from '../base-form/base-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { IModeloRelatorioModel } from 'src/app/models/modelo-relatorio.model';
import { ICapituloRelatorioModel } from 'src/app/models/capitulo-relatorio.model';
import { RelatoriaCapituloService } from 'src/app/services/relatoria-capitulo.service';
import { PaaService } from 'src/app/services/paa.service';
import { AuthService } from 'src/app/services/auth.service';

export type IModeloRelatoria = {
  modeloRelatorio: IModeloRelatorioModel[] | IModeloRelatorioModel | any;
  modeloRelatorioId: number | any;
  habilitarGerarRelatoria: boolean;
  jaEnviadoAprovacao: boolean;
  status: IEnumModel;
  situacao: IEnumModel;

}
@Component({
  selector: 'app-relatoria',
  templateUrl: './relatoria.component.html',
  styleUrls: ['./relatoria.component.scss']
})
export class RelatoriaComponent extends BaseFormComponent implements OnInit, OnChanges{
  @Input() aba: number;
  @Input() relatoriaDados: (IRelatoriaCapituloModel[] | IModeloRelatorioModel[] | ICapituloRelatorioModel[]);
  @Input() visualizar: boolean;
  @Input() submit: boolean;
  @Input() dadosObtidos: IRelatoriaModel;
  @Input() public model: IModeloRelatoria = {} as IModeloRelatoria;
  @Input() tipoTrabalhoId: number;
  @Input() obterDados: () => void;
  @Input() public impressao: boolean;
  @Input() tipoTrabalhoRef: number;
  public reprovado: boolean;
  public homologado: boolean;
  idTipoTrabalhoPaac:number = 2;
  idTipoTrabalhoPaacEspecial:number = 8;

  tipoTrabalho: number;
  public salvarDadosPendente = false;

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private relatoriaService: RelatoriaService,
    private modeloRelatorioService: ModeloRelatorioService,
    private relatoriaCapituloService: RelatoriaCapituloService,
    private paaService: PaaService,
    private authService: AuthService,
    public matDialog: MatDialog,
  ) {
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {
    
    if(!this.tipoTrabalhoRef || this.tipoTrabalhoRef == 0){
      const path = this.route.routeConfig.path?.toString();
      if (path?.includes('pea')) {
        this.tipoTrabalho = 1;
      }
      else if (path?.includes('paa')) {
        this.tipoTrabalho = 2;
      }
      else if (path?.includes('opa')) {
        this.tipoTrabalho = 3;
      }
      else if (path?.includes('osa')) {
        this.tipoTrabalho = 4;
      }
      else if (path?.includes('planejamento')) {
        this.tipoTrabalho = 7;
      }
    }else{
      this.tipoTrabalho = this.tipoTrabalhoRef;
    }
    

    this.habilitarGerar();
    this.obterRelatorio();    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.model?.currentValue !== undefined) {
      this.model = changes.model?.currentValue;
      this.reprovado = this.model?.jaEnviadoAprovacao;
      if(this.model?.situacao?.codigo === 4 || this.model?.status?.codigo === 4){
        this.homologado = true;
      }
    }
  }

  public habilitarGerar(){
    //TODO:SOMENTE PAA POR ENQUANTO
    if(this.id && (this.tipoTrabalho == this.idTipoTrabalhoPaac || this.tipoTrabalho == this.idTipoTrabalhoPaacEspecial)){
      this.relatoriaService.habilitarGerar(this.id, this.tipoTrabalho)
      .then((res) => {
        if(res.sucesso)
          this.model.habilitarGerarRelatoria = res.dados;
      })
    }
  }

  public obterRelatorio() {
    //if (this.relatoriaDados && this.dadosObtidos) { return; }

    if (this.id) {
      this.relatoriaService.obterPorTipoTrabalho(this.id, this.tipoTrabalho)
        .then((res) => {
          if (res.sucesso) {
            // this.relatoriaDados = res.dados.relatoriasCapitulos;
            // this.dadosObtidos = res.dados;
            this.relatoriaService.reltoriaCurrent.subscribe(relatoriaDados => {
              this.relatoriaDados = relatoriaDados?.relatoriasCapitulos;
              this.dadosObtidos = relatoriaDados;
            })
          }
        }).catch(err => {
          this.exibirMensagemErro(err.mensagem.descricao);
        })
    }

  }

  public async gerar() {

    this.relatoriaService.gerar({
      modeloRelatorioId: (this.model.modeloRelatorio as IModeloRelatorioModel).id,
      tipoTrabalhoId: this.id,
      tipoTrabalho: { codigo: this.tipoTrabalho } as IEnumModel
    } as IRelatoriaModel)
      .then(res => {
        if (res.sucesso) {
          this.exibirMensagemSucesso(res.mensagem.descricao);
          this.obterRelatorio();
          this.obterDados();
        }
        if (!res.sucesso) {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      }).catch(err => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public async exibirModalModeloRelatorio() {
    try {
      const res = await this.modeloRelatorioService.obterPorTipoTrabalho(this.tipoTrabalho.toString(), true, true);
      if (res.sucesso) {
        res.dados.sort();
        const modal = this.exibirModal('Selecionar Modelo de Relatório', res.dados);
        modal.afterClosed().subscribe((data) => {
          if (data) {
            this.salvarDadosPendente = true;
            this.model.modeloRelatorio = data;
            this.model.modeloRelatorioId = data.id;
          }
        });
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public cancelar() {
    if(this.tipoTrabalho == 1)
		  this.router.navigate(['/pea']);
    else if(this.tipoTrabalho == this.idTipoTrabalhoPaac || this.tipoTrabalho == this.idTipoTrabalhoPaacEspecial)
      this.router.navigate(['/paa']);
    else if(this.tipoTrabalho == 3)
      this.router.navigate(['/opa']);
    else if(this.tipoTrabalho == 4)
      this.router.navigate(['/osa']);
    else if(this.tipoTrabalho == 7)
      this.router.navigate(['/planejamento']);
	  }

  desabilitarBotaoEnviarAprovacao(): boolean{
    return this.relatoriaDados == null 
            || this.relatoriaDados == undefined 
            || this.dadosObtidos.tipoTrabalhoStatus.codigo != 1;
  }

  public async enviarAprovacao() {
    
    if(this.tipoTrabalho == this.idTipoTrabalhoPaac || this.tipoTrabalho == this.idTipoTrabalhoPaacEspecial){

      const perfis = await this.authService.obterPerfisSimplificado();
      const inputOptions = new Map;

      perfis.dados.forEach(element => {
        inputOptions.set(element.guid, element.nome)
      });

      Swal.fire({
        title: 'Enviar para aprovação',
        text: 'Selecione seu Perfil de envio para aprovação',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        cancelButtonText: 'Cancelar',
        input: 'select',
        inputOptions,
      }).then(res => {
        if(res.isConfirmed){    
            this.paaService.enviarAprovacao(this.id, res.value.toString())
            .then((res) => {
              if (res.sucesso) {
                if (res.mensagem.codigo === 42 || res.mensagem.codigo === 99) {
                  this.exibirMensagemErro(res.mensagem.descricao);
                  return
                }
                this.exibirMensagemSucesso('PAAC enviado para aprovação.');
                this.router.navigate([`/pendencias`]);
              } else {
                this.exibirMensagemErro(res.mensagem.descricao);
              }
            })
            .catch((err) => {
              this.exibirMensagemErro(err.mensagem.descricao);
            })
        }
      })
    }
  }

}
