<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a
            >
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/modelos-relatorio']">Modelos de Relatório</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>
          </div>
          <form
            [formGroup]="form"
            #f="ngForm"
            (keydown.enter)="$event.preventDefault()"
          >
            <div class="card-body">
              <ngx-spinner
                bdColor="rgba(0,0,0,0.3)"
                size="small"
                color="#fff"
                type="line-scale"
                [fullScreen]="false"
              >
                <p style="color: white;">Processando...</p>
              </ngx-spinner>
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="selectTipoTrabalho" class="mb-1"
                    >Tipo de Entrada</label
                  >
                  <select
                    id="selectTipoTrabalho"
                    [attr.disabled]="visualizar ? true : null || model?.id"
                    formControlName="tipoTrabalhoId"
                    class="form-control form-control-sm"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && form.controls['tipoTrabalhoId'].invalid
                    }"
                  >
                    <option
                      *ngFor="let item of tiposTrabalhos"
                      [value]="item?.codigo"
                    >
                      {{ item?.descricao }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      f.submitted && form.controls['tipoTrabalhoId'].invalid
                    "
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="form.controls['tipoTrabalhoId'].errors.required"
                    >
                      Campo obrigatório
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-9">
                  <label for="nome" class="mb-1">Nome</label>
                  <input
                    [attr.disabled]="visualizar ? true : null"
                    type="text"
                    id="nome"
                    class="form-control form-control-sm"
                    formControlName="nome"
                    maxlength="200"
                    [ngClass]="{
                      'is-invalid': f.submitted && form.controls['nome'].invalid
                    }"
                  />
                  <div
                    *ngIf="f.submitted && form.controls['nome'].invalid"
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.controls['nome'].errors.required">
                      Campo obrigatório
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-12">
                  <label for="inputDescricao" class="mb-1">Descrição</label>
                  <textarea
                    [attr.disabled]="visualizar ? true : null"
                    rows="3"
                    id="inputDescricao"
                    class="form-control form-control-sm"
                    formControlName="descricao"
                    maxlength="500"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && form.controls['descricao'].invalid
                    }"
                  >
                  </textarea>
                  <div
                    *ngIf="f.submitted && form.controls['descricao'].invalid"
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.controls['descricao'].errors.required">
                      Campo obrigatório
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="card card-outline card-lightblue col-md-12">
                  <div class="card-header">
                    <h3 class="card-title mb-0">
                      <i class="fas fa-table pr-2"></i>
                      Capítulos de Relatório
                    </h3>
                  </div>

                  <div class="card-body p-2">
                    <div class="mat-elevation-z4">
                      <mat-toolbar class="table-header">
                        <mat-toolbar-row>
                          <button
                            [hidden]="visualizar"
                            type="button"
                            class="btn btn-primary btn-sm mr-1"
                            (click)="exibirModalCapitulo()"
                          >
                            <i class="far fa-file"></i> Adicionar
                          </button>
                        </mat-toolbar-row>
                      </mat-toolbar>
                      <table class="table table-condensed"
                             cdkDropList
                             (cdkDropListDropped)="onDrop($event)">
                        <thead>
                          <tr>
                            <th class="col-md-1">#</th>
                            <th class="col-md-6">Nome</th>
                            <th class="col-md-2">Tipo</th>
                            <th class="col-md-3 text-center">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let element of model.capitulos; let i = index">
                          <tr
                            cdkDrag
                            cdkDragLockAxis="y"
                          >
                            <th>
                              <div class="drag-handle">
                                <ng-container
                                  [ngTemplateOutlet]="dragHandleTmpl"
                                >
                                </ng-container>
                                {{ element?.ordem }}
                              </div>
                            </th>
                            <td>{{ element?.nome }}</td>
                            <td>{{ element?.tipo?.descricao }}</td>
                            <td class="text-center">
                              <div class="btn-group mr-1">
                                <div
                                  class="d-flex align-items-center mr-2"
                                  *ngIf="element.status?.codigo === 2"
                                >
                                  <button
                                    type="button"
                                    data-toggle="dropdown"
                                    class="btn btn-tool btn-green bg-transparent border-0 mb-2-"
                                    (click)="avaliarCapitulo(element.id, true)"
                                  >
                                    <i class="fas fa-check text-success"></i>
                                  </button>
                                  <button
                                    type="button"
                                    data-toggle="dropdown"
                                    class="btn btn-tool btn-danger bg-transparent border-0 mb-2- mr-2"
                                    (click)="avaliarCapitulo(element.id, false)"
                                  >
                                    <i class="fas fa-times text-danger"></i>
                                  </button>
                                  <p class="border-0 mb-0 text-danger">
                                    Aguardando o aceite
                                  </p>
                                </div>
                                <button
                                  [hidden]="
                                    visualizar || element.capituloRelatorioPaiId || element.capituloRelatorioPai
                                  "
                                  type="button"
                                  class="btn btn-success btn-xs text-left"
                                  (click)="
                                    exibirModalCapitulo(false, element, i, true)
                                  "
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                                <button
                                  [hidden]="visualizar"
                                  type="button"
                                  class="btn btn-danger btn-xs text-left"
                                  (click)="excluirCapitulo(element)"
                                >
                                  <i class="far fa-trash-alt"></i>
                                </button>
                                <button
                                  [hidden]="
                                    visualizar || element.capituloRelatorioPaiId
                                  "
                                  type="button"
                                  class="btn btn-success btn-xs text-left"
                                  (click)="
                                    exibirModalCapitulo(true, element, i)
                                  "
                                >
                                  <i class="far fas fa-pen"></i>
                                </button>
                                <button
                                  [hidden]="
                                    visualizar ||
                                    !element.capituloRelatorioPaiId
                                  "
                                  type="button"
                                  class="btn btn-success btn-xs text-left"
                                  (click)="
                                    exibirModalCapitulo(true, element, i, true)
                                  "
                                >
                                  <i class="far fas fa-pen"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr *ngIf="element.subCapitulos.length > 0">
                            <td colspan="4">
                              <table cdkDropList (cdkDropListDropped)="onDropItens($event, element)" class="table table-condensed">
                                <tr id='div-drag' *ngFor="let subCapitulo of element.subCapitulos; let i = index" cdkDrag>
                                  <td class="drag-handle">
                                    <ng-container
                                      [ngTemplateOutlet]="dragHandleTmpl"
                                    >
                                    </ng-container>
                                    {{ subCapitulo?.ordem }}
                                  </td>
                                  <td>
                                    {{ subCapitulo?.nome }}
                                  </td>
                                  <td>
                                    {{ subCapitulo?.tipo?.descricao }}
                                  </td>
                                  <td>
                                    <div class="btn-group mr-1">
                                      <div
                                        class="d-flex align-items-center mr-2"
                                        *ngIf="subCapitulo.status?.codigo === 2"
                                      >
                                        <button
                                          type="button"
                                          data-toggle="dropdown"
                                          class="btn btn-tool btn-green bg-transparent border-0 mb-2-"
                                          (click)="avaliarCapitulo(subCapitulo.id, true)"
                                        >
                                          <i class="fas fa-check text-success"></i>
                                        </button>
                                        <button
                                          type="button"
                                          data-toggle="dropdown"
                                          class="btn btn-tool btn-danger bg-transparent border-0 mb-2- mr-2"
                                          (click)="avaliarCapitulo(subCapitulo.id, false)"
                                        >
                                          <i class="fas fa-times text-danger"></i>
                                        </button>
                                        <p class="border-0 mb-0 text-danger">
                                          Aguardando o aceite
                                        </p>
                                      </div>
                                      <button
                                        [hidden]="
                                    visualizar || subCapitulo.capituloRelatorioPaiId || subCapitulo.capituloRelatorioPai
                                  "
                                        type="button"
                                        class="btn btn-success btn-xs text-left"
                                        (click)="
                                    exibirModalCapitulo(false, subCapitulo, i, true,i)
                                  "
                                      >
                                        <i class="fas fa-plus"></i>
                                      </button>
                                      <button
                                        [hidden]="visualizar"
                                        type="button"
                                        class="btn btn-danger btn-xs text-left"
                                        (click)="excluirCapitulo(subCapitulo)"
                                      >
                                        <i class="far fa-trash-alt"></i>
                                      </button>
                                      <button
                                        [hidden]="
                                    visualizar || subCapitulo.capituloRelatorioPaiId
                                  "
                                        type="button"
                                        class="btn btn-success btn-xs text-left"
                                        (click)="
                                    exibirModalCapitulo(true, subCapitulo, i,true,i)
                                  "
                                      >
                                        <i class="far fas fa-pen"></i>
                                      </button>
                                      <button
                                        [hidden]="
                                    visualizar ||
                                    !subCapitulo.capituloRelatorioPaiId
                                  "
                                        type="button"
                                        class="btn btn-success btn-xs text-left"
                                        (click)="
                                    exibirModalCapitulo(true, subCapitulo, i, true)
                                  "
                                      >
                                        <i class="far fas fa-pen"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>

                        </ng-container>
                        </tbody>
                      </table>

                      <ng-template #dragHandleTmpl>
                        <svg
                          class="bi bi-list handle"
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </ng-template>

                      <h5 [hidden]="!semDados" class="table-no-data">
                        Nenhum registro encontrado.
                      </h5>
                      <mat-paginator
                        [pageSizeOptions]="[15, 30, 50]"
                        showFirstLastButtons
                      >
                      </mat-paginator>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="btn-group" [hidden]="visualizar">
                <button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
                    <i class="far fa-save"></i> Salvar
                </button>
                <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                <div class="dropdown-menu" role="menu">
                <a class="dropdown-item" (click)="salvarDados(false)">Salvar</a>
                <a class="dropdown-item" (click)="salvarDados(true)">Salvar e fechar</a>
                </div>
              </div>

              <button
                [hidden]="visualizar"
                type="button"
                class="btn btn-default btn-sm"
                (click)="onBack()"
              >
                <i class="fas fa-times"></i> Cancelar
              </button>

              <button
                [hidden]="!visualizar"
                type="button"
                class="btn btn-default btn-sm"
                (click)="onBack()"
              >
                <i class="fas fa-times"></i> Sair
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
