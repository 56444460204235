import { ToastrService } from 'ngx-toastr';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { IQuestionarioUGItemModel } from 'src/app/models/questionario-ug.model';

@Component({
  selector: 'app-editar-questionario-modal-secao',
  templateUrl: './modal-secao.component.html',
  styleUrls: ['./modal-secao.component.scss']
})
export class EditarQuestionarioModalSecaoComponent extends BaseFormComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  public model = {} as IQuestionarioUGItemModel;
  public campoObrigatorio = false;

  constructor(
    route: ActivatedRoute,
    private dialogRef: MatDialogRef<EditarQuestionarioModalSecaoComponent>,
    public toastr: ToastrService,
    localeService: BsLocaleService,
    router: Router,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data

  ) { 
    super(route, toastr, router, localeService, matDialog);

    this.model.id = data.id ?? 0;
    this.model.idQuestionarioUG = data.idModeloQuestionario;
    this.model.nome = data.nome;
    this.model.tipoId = 1;
    this.model.tipoDescription = "Seção";
    this.model.ativo = true;
    this.model.nomeAnterior = data.nome;
  }

  ngOnInit(): void {
    
  }
 
  
  ngAfterViewInit(): void {
    
  }

  public async concluir() {
    
    this.dialogRef.close(this.model);

  }

  public cancelar() {
    this.dialogRef.close(null);
  }


}
