<li class="nav-item dropdown user-menu">
  <a class="nav-link dropdown-toggle" data-toggle="dropdown" (click)="toggleDropdownMenu()">
    <img src="assets/img/user.png" class="user-image img-circle elevation-2" alt="User Image" />
  </a>
  <ul #dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
    <!-- User image -->
    <li class="user-header bg-lightblue">
      <img src="assets/img/user.png" class="img-circle elevation-2" alt="User Image" />

      <p>
        {{authService.usuario?.nome}}
        <small>{{authService.usuario?.email}}</small>
      </p>
    </li>
    <!-- Menu Footer-->
    <li class="user-footer">
      <a (click)="logout()" class="btn btn-default btn-flat float-right">Sair</a>
    </li>
  </ul>
</li>
