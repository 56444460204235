import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { TecnicaAuditoriaFormComponent } from 'src/app/pages/tecnica-auditoria-form/tecnica-auditoria-form.component';

@Injectable()
export class TecnicaAuditoriaGuard
  implements CanDeactivate<TecnicaAuditoriaFormComponent> {
  canDeactivate(
    component: TecnicaAuditoriaFormComponent
  ): Observable<boolean> | boolean {
    if (!component.visualizar) {
      if (component?.salvar) { return true; }
        if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    }
    else { return true; }
  }
}
