export enum EnumStatusSolicitacaoTecnica {
    EmElaboracao = 1,
	Finalizada = 2,
    Enviada = 3,
    Cancelada = 4,
    SolicitacaoProrrogacao = 5,
    Prorrogada = 6,
    AtendidaParcialmente = 7,
    AtendidaTotalmente = 8,
    NaoAtendidaParcialmente = 9,
    NaoAtendidaTotalmente = 10,
    Pendente = 11,
    ProrrogacaoRejeitada = 12
}