import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { MatrizPlanejamentoFormComponent } from 'src/app/pages/matriz-planejamento-form/matriz-planejamento-form.component';

@Injectable()
export class MatrizPlanejamentoGuard
  implements CanDeactivate<MatrizPlanejamentoFormComponent> {
  canDeactivate(
    component: MatrizPlanejamentoFormComponent
  ): Observable<boolean> | boolean {
    if (!component.visualizar) {
      if(component?.adicionarQuestão) { return true; }
      if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    }
  }
}
