import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IMatrizPlanejamentoModel } from 'src/app/models/matriz-planejamento.model';
import { MatrizPlanejamentoService } from 'src/app/services/matriz-planejamento.service';

@Component({
  selector: 'app-matriz-planejamento-relatorio',
  templateUrl: './matriz-planejamento-relatorio.component.html',
  styleUrls: ['./matriz-planejamento-relatorio.component.scss'],
})
export class MatrizPlanejamentoRelatorioComponent implements OnInit {
  public model: IMatrizPlanejamentoModel = {} as IMatrizPlanejamentoModel;
  idParam: number;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private localeService: BsLocaleService,
    private matrizPlanejamentoService: MatrizPlanejamentoService
  ) {}

  ngOnInit() {
    this.localeService.use('pt-br');

    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.idParam = parseInt(id, 10);
    }

    this.obterDados();
  }

    public async obterDados() {
      try {
        const id = this.route.snapshot.paramMap.get('id');
        const idParam = parseInt(id, 10);
        const res = await this.matrizPlanejamentoService.obterPorId(idParam);
        this.model = res.dados;

      } catch (err) {
        this.toastr.error(err.mensagem.descricao, 'Atenção');
        this.router.navigate(['/matriz-planejamento']);
      }
    }

  print(): void {
    window.print();
  }

  public cancelar() {
    this.router.navigate(['/matriz-planejamento']);
  }
}
