import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IPeriodoExercicioModel } from '../models/periodo-exercicio.model';
import { BaseService } from './base.service';
@Injectable({
  providedIn: 'root',
})
export class PeriodoExercicioService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy );
  }

  public async obterPorResponsavelId(unidadeGestoraId: number, id: string): Promise<IBaseModel<IPeriodoExercicioModel[]>> {
    return this.httpClient
      .get<IBaseModel<IPeriodoExercicioModel[]>>(`${this.apiBaseUrl}/periodoexercicio/unidade-gestora/${unidadeGestoraId}/responsavel/${id}`)
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IPeriodoExercicioModel>> {
    return this.httpClient
      .get<IBaseModel<IPeriodoExercicioModel>>(`${this.apiBaseUrl}/periodoexercicio/${id}`)
      .toPromise();
  }

  public async inserir(data: IPeriodoExercicioModel): Promise<IBaseModel<IPeriodoExercicioModel>> {
    return this.httpClient
      .post<IBaseModel<IPeriodoExercicioModel>>(`${this.apiBaseUrl}/periodoexercicio`, data)
      .toPromise();
  }

  public async atualizar(data: IPeriodoExercicioModel): Promise<IBaseModel<IPeriodoExercicioModel>> {
    return this.httpClient
      .put<IBaseModel<IPeriodoExercicioModel>>(`${this.apiBaseUrl}/periodoexercicio/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>>{
    return this.httpClient
    .post<IBaseModel<void>>(`${this.apiBaseUrl}/periodoexercicio/${id}/reativar`, {})
    .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>>{
    return this.httpClient
    .post<IBaseModel<void>>(`${this.apiBaseUrl}/periodoexercicio/${id}/inativar`, {})
    .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>>{
    return this.httpClient
    .delete<IBaseModel<void>>(`${this.apiBaseUrl}/periodoexercicio/${id}/excluir`, {})
    .toPromise();
  }
}
