import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IEnumModel } from 'src/app/models/enum.model';
import { ILinhaDoTempoModel } from 'src/app/models/linha-tempo.model';
import { AvaliacaoService } from 'src/app/services/avaliacao.service';
import { BaseFormComponent } from '../base-form/base-form.component';

@Component({
  selector: 'app-linha-do-tempo',
  templateUrl: './linha-do-tempo.component.html',
  styleUrls: ['./linha-do-tempo.component.scss']
})
export class LinhaDoTempoComponent extends BaseFormComponent implements OnInit {
  @Input() aba: number;
  @Input() public model: ILinhaDoTempoModel = {} as ILinhaDoTempoModel;
  @Input() tipoTrabalhoRef: number;

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private avaliacaoService: AvaliacaoService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.visualizar) {
      this.titulo = 'Visualizar Linha do Tempo';
    }
  }

  ngOnInit() {
    this.obterTipoDocumento();

    this.avaliacaoService.obter(this.model?.tipoDocumento?.codigo, this.id)
      .then(_ => {
        this.model = {} as ILinhaDoTempoModel;

        this.model.datas = _?.dados?.datas;
        let tamanhoArray = this.model.datas?.length;
        if (tamanhoArray > 0) {
          this.model.datas[tamanhoArray-1].acoes.push({
            acao: { codigo: 0, nome: 'Criado', descricao: 'Criado com sucesso.' } as IEnumModel
          });
        }
        else {
          this.model.datas = [
            {
              acoes: [{
                acao: { codigo: 0, nome: 'Criado', descricao: 'Criado com sucesso.' } as IEnumModel
              }]
            }
          ]
        }

        this.model.tipoDocumento = _.dados?.tipoDocumento;
      });
  }

  public imprimir(id: number) {
    localStorage.setItem('timelineImprimir', this.router.url)
    switch (this.model?.tipoDocumento.nome) {
      case 'PEAC':
        this.router.navigate([]).then(result => { window.open(`pea/${id}/espelho-relatorio-consolidado`, '_blank') });
        window.close();
        break;
        case 'PAAC':
          this.router.navigate([]).then(result => { window.open(`paa/${id}/espelho-relatorio`, '_blank'); });
        window.close();
        break;
        case 'OPA':
          this.router.navigate([]).then(result => { window.open(`opa/${id}/espelho-relatorio`, '_blank'); });
        window.close();
        break;
        case 'Planejamento':
          this.router.navigate([]).then(result => { window.open(`planejmento/${id}/espelho-relatorio`, '_blank'); });
        window.close();
        break;
        case 'OSA':
          this.router.navigate([]).then(result => { window.open(`osa/${id}/espelho-relatorio`, '_blank'); });
        window.close();
        break;
     }
  }

  public imprimirRelatoria(id: number) {
    localStorage.setItem('timelineImprimir', this.router.url)
    switch (this.model?.tipoDocumento.nome) {
      case 'PEAC':
        this.router.navigate([]).then(result => { window.open(`imprimir-relatoria/${id}/espelho-relatorio?tipoTrabalho=pea`, '_blank'); });
        window.close();
        break;
      case 'PAAC':
        this.router.navigate([]).then(result => { window.open(`imprimir-relatoria/${id}/espelho-relatorio?tipoTrabalho=paa`, '_blank'); });
        window.close();
        break;
      case 'OPA':
        this.router.navigate([]).then(result => { window.open(`imprimir-relatoria/${id}/espelho-relatorio?tipoTrabalho=opa`, '_blank'); });
        window.close();
        break;
      case 'Planejamento':
        this.router.navigate([]).then(result => { window.open(`imprimir-relatoria/${id}/espelho-relatorio?tipoTrabalho=planejamento`, '_blank'); });
        window.close();
        break;
      case 'OSA':
        this.router.navigate([]).then(result => { window.open(`imprimir-relatoria/${id}/espelho-relatorio?tipoTrabalho=osa`, '_blank'); });
        window.close();
        break;
    }
  }

  public obterTipoDocumento() {

    if(!this.tipoTrabalhoRef || this.tipoTrabalhoRef == 0){
      const path = this.route.routeConfig.path?.toString();

      if (path?.includes('pea')) {
        this.model.tipoDocumento = {
          codigo: 1,
          nome: 'PEAC',
          descricao: 'PEAC'
        } as IEnumModel;
      }
      else if (path?.includes('paa')) {
        this.model.tipoDocumento = {
          codigo: 2,
          nome: 'PAAC',
          descricao: 'PAAC'
        } as IEnumModel;
      }
      else if (path?.includes('opa')) {
        this.model.tipoDocumento = {
          codigo: 3,
          nome: 'OPA',
          descricao: 'OPA'
        } as IEnumModel;
      }
      else if (path?.includes('osa')) {
        this.model.tipoDocumento = {
          codigo: 4,
          nome: 'OSA',
          descricao: 'OSA'
        } as IEnumModel;
      }
      else if (path?.includes('auditoria')) {
        this.model.tipoDocumento = {
          codigo: 6,
          nome: 'auditoria',
          descricao: 'auditoria'
        } as IEnumModel;
      }
      else if (path?.includes('planejamento')) {
        this.model.tipoDocumento = {
          codigo: 7,
          nome: 'Planejamento',
          descricao: 'Planejamento'
        } as IEnumModel;
      }
    }else{

      if(this.tipoTrabalhoRef == 2){
        this.model.tipoDocumento = {
          codigo: 2,
          nome: 'PAAC',
          descricao: 'PAAC'
        } as IEnumModel;
      }else if(this.tipoTrabalhoRef == 8){
        this.model.tipoDocumento = {
          codigo: 8,
          nome: 'PAAC - Especial',
          descricao: 'PAAC - Especial'
        } as IEnumModel;
      }
      
    }
    
  }

  mostrarBotaoFechar(): boolean{
    const path = this.route.routeConfig.path?.toString();
    return path?.includes('paa');
  }

  public cancelar() {
    const path = this.route.routeConfig.path?.toString();
    
    if(path?.includes('paa')){
      this.router.navigate(['/paa']);
    }else if(path?.includes('pea')){
      this.router.navigate(['/pea']);
    }else if(path?.includes('opa')){
      this.router.navigate(['/opa']);
    }else if(path?.includes('osa')){
      this.router.navigate(['/osa']);
    }else if(path?.includes('auditoria')){
      this.router.navigate(['/auditoria']);
    }else if(path?.includes('planejamento')){
      this.router.navigate(['/planejamento']);
    }
    
  }
}
