import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/log.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ILogModel } from 'src/app/models/log.model';

@Component({
  selector: 'app-log-form',
  templateUrl: './log-form.component.html',
  styleUrls: ['./log-form.component.scss']
})
export class LogFormComponent extends BaseFormComponent implements OnInit {
  public model: ILogModel = {} as ILogModel;

  constructor(
    route: ActivatedRoute,
    router: Router,
    toastr: ToastrService,
    localeService: BsLocaleService,
    private logService: LogService,
    public authService: AuthService,
    public matDialog: MatDialog,
              
  ) {
    super(route, toastr, router, localeService, matDialog);

    this.titulo = 'Detalhar Log do Sistema #' + this.id;
  }

  ngOnInit(): void {
    this.buscar();
  }

  public buscar() {
    this.logService
    .obterPorId(this.id)
    .then((res) => {
      if (res.sucesso){
        this.model = res.dados;
        this.model.dataRegistro = new Date(this.model.dataRegistro);
      } else {
        this.router.navigate(['/log']);
      }
    })
    .catch((err) => {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
    })
  }

  public cancelar() {
    this.router.navigate(['/log']);
  }
}
