<li class="nav-item" *ngFor = "let item of menuItems" style="padding-left: 10px;">
  
    <a *ngIf="item.url != ''" [routerLink]="[item.url]" [routerLinkActive]="['active']" class="nav-link" role="link">
          <i class="{{item.icone}}"></i>
          <p style="padding-left: 10px;">{{item.nome}}</p>      
    </a>
  
  
    <div *ngIf="item.url == '' && item.itemsFilhos.length > 0" class="nav-link" role="link">
      <i class="{{item.icone}}"></i>
      <a>        
        <p style="padding-left: 10px;">{{item.nome}}</p>  
      </a>
      
    </div>
  
  

    <ul *ngIf="item.url == '' && item.itemsFilhos.length > 0" class="nav nav-treeview" style="display: none" app-menuitem [menuName]="menuName + '/' + item.nome" [menuItems]="item.itemsFilhos">
									
    </ul>
</li>


