import { ToastrService } from 'ngx-toastr';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { IModeloQuestionarioItemModel } from 'src/app/models/modelo-questionario.model';

@Component({
  selector: 'app-modal-modelo-questionario-secao',
  templateUrl: './modal-secao.component.html',
  styleUrls: ['./modal-secao.component.scss'],
})

export class ModalModeloQuestionarioSecaoComponent extends BaseFormComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  public model = {} as IModeloQuestionarioItemModel;
  public campoObrigatorio = false;
  public nomeTitulo = "Inserir Seção";

  constructor(
    route: ActivatedRoute,
    private dialogRef: MatDialogRef<ModalModeloQuestionarioSecaoComponent>,
    public toastr: ToastrService,
    localeService: BsLocaleService,
    router: Router,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super(route, toastr, router, localeService, matDialog);

    this.model.id = data.id ?? 0;
    this.model.idModeloQuestionario = data.idModeloQuestionario;
    this.model.nome = data.nome;
    this.model.tipoId = 1;
    this.model.tipoDescription = "Seção";
    this.model.ativo = true;
    this.model.nomeAnterior = data.nome;

    if(data.id != 0 || data.nome != ""){
      this.nomeTitulo = "Editar Seção";
    }
  
  }

  ngOnInit(): void {
    
  }
 
  
  ngAfterViewInit(): void {
    
  }


  public async concluir() {
    
    this.dialogRef.close(this.model);

  }


  public cancelar() {
    this.dialogRef.close(null);
  }

}

