import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { IImprimirPeaModel } from 'src/app/models/imprimir-pea.model';
import { IPeriodoModel } from 'src/app/models/periodo.model';
import { AprovacaoService } from 'src/app/services/aprovacao.service';
import { PeaService } from 'src/app/services/pea.service';

@Component({
  selector: 'app-pea-relatorio',
  templateUrl: './pea-relatorio.component.html',
  styleUrls: ['./pea-relatorio.component.scss']
})
export class PeaRelatorioComponent implements OnInit {

  homologado = false;
  idParam: number;
  consolidado = false;
  espelho = false;
  dias: number;
  sabadoDomingo: number;
  pontoFacultativo: number;
  feriado: number;
  diasUteis: number;

  reportPEAs: IImprimirPeaModel[] = [];

  periodo: IPeriodoModel;

  public columnsRanking = ['codigoUG', 'siglaUG', 'nomeUG', 'valorRisco'];
  public columnsCapacidade = [
    'coordenacao',
    'diasDisponiveis',
    'equipesDisponiveis',
    'diasPorAuditoria',
    'auditoriasPorEquipe',
    'capacidadeTotalCoordenacao'
  ];
  public capacidadeDataSource = new MatTableDataSource<any>([]);
  public capacidadeSemDados = true;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private localeService: BsLocaleService,
    private peaService: PeaService,
    private aprovacaoService: AprovacaoService,) { }

  ngOnInit() {
    this.localeService.use('pt-br');

    const id = this.route.snapshot.paramMap.get('id');
    const path = this.route.snapshot.url.pop().toString();

    if (id) {
      this.idParam = parseInt(id, 10);
    }

    if (path === 'relatorio-consolidado') {
      this.consolidado = true;
    }

    if (path === 'espelho-relatorio-consolidado') {
      this.espelho = true;
    }

    this.obterDados();
  }

  public async obterDados() {
    try {
      await this.obterDadosPorId();
    } catch (err) {
      this.toastr.error('Ocorreu um erro inesperado, tente novamente mais tarde.', 'Atenção');
      this.router.navigate(['/pea']);
    }
  }

  public agrupadorCapacidade(index: number, item: any): boolean {
    return item.grupo;
  }

  public subTotalCapacidade(index: number, item: any): boolean {
    return item.subTotal;
  }

  public async obterDadosPorId() {
    let res: IBaseModel<IImprimirPeaModel[]>;

    if (this.consolidado) {
      const ids = this.route.snapshot.paramMap.get('id') || '';

      res = await this.peaService.imprimirPeaConsolidado(ids);
    }
    else if (this.espelho) {
      const avaliacao = await this.aprovacaoService.imprimir(this.idParam);
      if (avaliacao.dados != undefined) {
        res = {
          sucesso: avaliacao.sucesso,
          dados: JSON.parse(avaliacao.dados),
          mensagem: avaliacao.mensagem,
          resultadoValidacao: avaliacao.resultadoValidacao
        } as IBaseModel<IImprimirPeaModel[]>;
      }
      else {
        res = {
          sucesso: avaliacao.sucesso,
          dados: avaliacao.dados,
          mensagem: avaliacao.mensagem,
          resultadoValidacao: avaliacao.resultadoValidacao
        } as unknown as IBaseModel<IImprimirPeaModel[]>;
      }
    }
    else {
      const avaliacao = await this.peaService.imprimirPea(this.idParam);

      if(!avaliacao.sucesso){
        this.toastr.error(avaliacao.mensagem.descricao, 'Atenção');
        this.router.navigate(['/pea']);
        return null;  
      }

      res = {
        sucesso: avaliacao.sucesso,
        dados: JSON.parse(avaliacao.dados),
        mensagem: avaliacao.mensagem,
        situacao: avaliacao.situacao
      } as IBaseModel<IImprimirPeaModel[]>;
    }

    res.dados.forEach(reportPEA => {
      reportPEA.capacidadeTrabalho = [];

      let dadosPreparados = [];
      const anos = [...new Set(reportPEA.coordenacaoCapacidade.map((d) => d.ano))];

      for (const ano of anos) {
        // Header
        dadosPreparados.push({
          grupo: true,
          ano,
        });

        // Dados
        dadosPreparados = dadosPreparados.concat(
          reportPEA.coordenacaoCapacidade.filter((c) => c.ano === ano)
        );

        // Footer
        dadosPreparados.push({
          subTotal: true,
          capacidadeTotalAno: reportPEA.coordenacaoCapacidade
            .filter((c) => c.ano === ano)
            .map((c) => c.capacidadeTotalCoordenacao)
            .reduce((sum, record) => sum + record),
        });
        reportPEA.capacidadeTrabalhoTotal = reportPEA.coordenacaoCapacidade.map(_ => _.capacidadeTotalCoordenacao).reduce((sum, current) => sum + current, 0);
      }


      this.capacidadeDataSource = new MatTableDataSource<any>(
        dadosPreparados
      );
      this.capacidadeDataSource._updateChangeSubscription();
      this.capacidadeSemDados =
        this.capacidadeDataSource.filteredData.length === 0;

      reportPEA.unidadeGestora = reportPEA.unidadeGestora.sort((a, b) => a?.sigla.localeCompare(b?.sigla))
      reportPEA.coordenacao = reportPEA.coordenacao.sort((a, b) => a?.sigla.localeCompare(b?.sigla))
      reportPEA.rankingPrioridade = reportPEA.rankingDePrioridade?.filter(_ => !_.unidadeGestora.empresaPublica);
      reportPEA.rankingPrioridadeEmpresasPublicas = reportPEA.rankingDePrioridade?.filter(_ => _.unidadeGestora.empresaPublica);
      reportPEA.dadosDistribuicaoContempladas = [];
      reportPEA.dadosDistribuicaoNaoContempladas = [];
      reportPEA.dadosDistribuicaoContempladasPersonalizado = [];
      reportPEA.dadosDistribuicaoNaoContempladasPersonalizado = [];
      reportPEA.anos = [];

      this.obterValorRiscoPorUg(reportPEA.valorRiscoUnidadeGestoras)

      const anoInicial = new Date(reportPEA.periodo.dataInicio).getFullYear();
      const anoFim = new Date(reportPEA.periodo.dataFim).getFullYear();

      for (let i = anoInicial; i <= anoFim; i++) {
        reportPEA.anos.push(i);
      }

      // Padrao
      // Contempladas

      const distribuicaoContemplada = reportPEA.paineisDistribuicao?.painelDistribuicaoPadrao.painel.filter(d => d.contemplada) ?? [];
      const distribuicaoNaoContemplada = reportPEA.paineisDistribuicao?.painelDistribuicaoPadrao.painel.filter(d => !d.contemplada) ?? [];
      for (const item of distribuicaoContemplada) {
        if (reportPEA.dadosDistribuicaoContempladas.length === 0 || reportPEA.dadosDistribuicaoContempladas.findIndex(r => !r[item.ano.toString()]) < 0) {
          const row = {};
          row[item.ano.toString()] = item.unidadeGestoraSigla;
          row[item.ano.toString() + '_class'] = item.blocoPainel.nome;
          row[item.ano.toString() + '_indice'] = item.indicePriorizacao.toFixed(4);
          reportPEA.dadosDistribuicaoContempladas.push(row);
        } else {
          const row = reportPEA.dadosDistribuicaoContempladas[reportPEA.dadosDistribuicaoContempladas.findIndex(r => !r[item.ano.toString()])];
          row[item.ano.toString()] = item.unidadeGestoraSigla;
          row[item.ano.toString() + '_class'] = item.blocoPainel.nome;
          row[item.ano.toString() + '_indice'] = item.indicePriorizacao.toFixed(4);
        }
      }

      // Não Contempladas
      for (const item of distribuicaoNaoContemplada) {
        if (reportPEA.dadosDistribuicaoNaoContempladas.length === 0 || reportPEA.dadosDistribuicaoNaoContempladas.findIndex(r => !r[item.ano.toString()]) < 0) {
          const row = {};
          row[item.ano.toString()] = item.unidadeGestoraSigla;
          row[item.ano.toString() + '_indice'] = item.indicePriorizacao.toFixed(4);
          reportPEA.dadosDistribuicaoNaoContempladas.push(row);
        } else {
          const row = reportPEA.dadosDistribuicaoNaoContempladas[reportPEA.dadosDistribuicaoNaoContempladas.findIndex(r => !r[item.ano.toString()])];
          row[item.ano.toString()] = item.unidadeGestoraSigla;
          row[item.ano.toString() + '_indice'] = item.indicePriorizacao.toFixed(4);
        }
      }

      // Personalizado
      // Contempladas
      if (reportPEA.classificacaoPersonalizada) {

        const distribuicaoPersonalizadaContemplada = reportPEA.paineisDistribuicao?.painelDistribuicaoPersonalizado?.painel.filter(d => d.contemplada) ?? [];
        const distribuicaoPersonalizadaNaoContemplada = reportPEA.paineisDistribuicao?.painelDistribuicaoPersonalizado?.painel.filter(d => !d.contemplada) ?? [];

        for (const item of distribuicaoPersonalizadaContemplada) {
          if (reportPEA.dadosDistribuicaoContempladasPersonalizado.length === 0 || reportPEA.dadosDistribuicaoContempladasPersonalizado.findIndex(r => !r[item.ano.toString()]) < 0) {
            const row = {};
            row[item.ano.toString()] = item.unidadeGestoraSigla;
            row[item.ano.toString() + '_class'] = item.blocoPainel.nome;
            row[item.ano.toString() + '_indice'] = item.indicePriorizacao.toFixed(4);
            reportPEA.dadosDistribuicaoContempladasPersonalizado.push(row);
          } else {
            const row = reportPEA.dadosDistribuicaoContempladasPersonalizado[reportPEA.dadosDistribuicaoContempladasPersonalizado.findIndex(r => !r[item.ano.toString()])];
            row[item.ano.toString()] = item.unidadeGestoraSigla;
            row[item.ano.toString() + '_class'] = item.blocoPainel.nome;
            row[item.ano.toString() + '_indice'] = item.indicePriorizacao.toFixed(4);
          }
        }

        // Não Contempladas
        for (const item of distribuicaoPersonalizadaNaoContemplada) {
          if (reportPEA.dadosDistribuicaoNaoContempladasPersonalizado.length === 0 || reportPEA.dadosDistribuicaoNaoContempladasPersonalizado.findIndex(r => !r[item.ano.toString()]) < 0) {
            const row = {};
            row[item.ano.toString()] = item.unidadeGestoraSigla;
            row[item.ano.toString() + '_indice'] = item.indicePriorizacao.toFixed(4);
            reportPEA.dadosDistribuicaoNaoContempladasPersonalizado.push(row);
          } else {
            const row = reportPEA.dadosDistribuicaoNaoContempladasPersonalizado[reportPEA.dadosDistribuicaoNaoContempladasPersonalizado.findIndex(r => !r[item.ano.toString()])];
            row[item.ano.toString()] = item.unidadeGestoraSigla;
            row[item.ano.toString() + '_indice'] = item.indicePriorizacao.toFixed(4);
          }
        }
      }
    });

    res.dados.forEach(element => {
      element.anosCalendario.forEach(item => {
        this.dias = item.meses.filter(data => data.totalDias)
          .reduce((sum, current) => sum + current.totalDias, 0);
        this.sabadoDomingo = item.meses.filter(data => data.totalSabadosEDomingos)
          .reduce((sum, current) => sum + current.totalSabadosEDomingos, 0);
        this.pontoFacultativo = item.meses.filter(data => data.totalPontosFacultativos)
          .reduce((sum, current) => sum + current.totalPontosFacultativos, 0);
        this.feriado = item.meses.filter(data => data.totalFeriados)
          .reduce((sum, current) => sum + current.totalFeriados, 0);
        this.diasUteis = item.meses.filter(data => data.totalDiasUteis)
          .reduce((sum, current) => sum + current.totalDiasUteis, 0);
      })
    });
    this.reportPEAs = res.dados;
    
    //this.homologado = res.situacao == "Homologado";
    res.dados.forEach(pea => { 
        if(pea.situacao.descricao != "Homologado") {
          this.homologado = false;
        }
        else{
          this.homologado = true;
        }        
      });
    
  }

  public distribuicaoDataSource = new MatTableDataSource<any>([]);
  public distruibuicaoSemDados = true;
  public columnsDistribuicaoOriginais1 = ['codigoUG', 'unidadeGestora'];
  public columnsDistribuicaoOriginais2 = ['valorRisco'];
  public columnsDistribuicao = [];
  public colunasAdicionarDistribuicao = [];

  public async obterValorRiscoPorUg(lista: any[]) {
    this.colunasAdicionarDistribuicao = [];
    this.columnsDistribuicao = [];
    this.columnsDistribuicao = this.columnsDistribuicao.concat(
      Object.assign([], this.columnsDistribuicaoOriginais1)
    );

    var colunasNomes: any = {};
    if (lista[0] && lista[0].criterio) {
      lista[0].criterio.forEach((element) => {
        const nomeColuna = element.nome;

        colunasNomes[nomeColuna.toString()] = nomeColuna

        this.columnsDistribuicao.push(nomeColuna);
        if (
          !this.colunasAdicionarDistribuicao.some((c) => c === nomeColuna)
        ) {
          this.colunasAdicionarDistribuicao.push(nomeColuna);
        }
      });
    }

    this.columnsDistribuicao = this.columnsDistribuicao.concat(
      Object.assign([], this.columnsDistribuicaoOriginais2)
    );

    this.distribuicaoDataSource = new MatTableDataSource<any>(lista);
    this.distribuicaoDataSource._updateChangeSubscription();
    this.distruibuicaoSemDados =
      this.distribuicaoDataSource.filteredData.length === 0;
  }

  public obterPesoColuna(
    coluna: string
  ): string {
    if (!this.distribuicaoDataSource.data || !coluna) {
      return '';
    }

    const result = this.distribuicaoDataSource.data[0].criterio.find(
      (x) => x.nome == coluna
    );

    if (!result || !result.peso) {
      return '';
    }

    return result.peso.toString();
  }

  public obterValorColuna(
    element: any,
    coluna: string
  ): string {
    if (!element || !coluna) {
      return '';
    }

    const result = element.criterio.find(
      (x) => x.nome == coluna
    );

    if (!result || !result.nota) {
      return '';
    }

    return result.nota.toString();
  }

  print(): void {
    window.print();
  }

  public cancelar() {
    this.router.navigate(['/pea']);
  }
}
