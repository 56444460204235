import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { ICategoriaRiscoModel } from 'src/app/models/categoria-risco.model';
import { CategoriaRiscoService } from 'src/app/services/categoria-risco.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import Swal from 'sweetalert2';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';

@Component({
  selector: 'app-categoria-risco-form',
  templateUrl: './categoria-risco-form.component.html',
  styleUrls: ['./categoria-risco-form.component.scss']
})
export class CategoriaRiscoFormComponent extends BaseFormComponent implements OnInit {
  public model: ICategoriaRiscoModel = {} as ICategoriaRiscoModel;

  public form = new FormGroup({
  id: new FormControl({ value: '', disabled: true }),
  nome: new FormControl('', Validators.required),
  descricao: new FormControl('', Validators.required)
});


  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private categoriaRiscoService: CategoriaRiscoService,
    public matDialog: MatDialog
  ) { 
    super(route, toastr,  router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Categoria de Risco';
    }

  }

  async ngOnInit() {
    try {
      if (!this.novoRegistro) {
        const res = await this.categoriaRiscoService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;

          if (this.visualizar){
            this.titulo = 'Visualizar Categoria de Risco - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Categoria de Risco - ' + this.model.nome;
          }
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
          this.router.navigate(['/categoria-risco']);
          return;
        }
      }
      this.form.patchValue(this.model);
    } catch (err) {
      this.exibirMensagemErro(err.mensagem);
      this.router.navigate(['/tipo-enfase']);
    }
  }

  public async salvarDados(salvarEFechar: boolean){
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }
    this.submit = true;

    this.atualizarModel(this.form.value);

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        res = await this.categoriaRiscoService.atualizar(this.model);
      } else {
        res = await this.categoriaRiscoService.inserir(this.model);
      }

      if (res.sucesso) {
        this.exibirMensagemSucesso(res.mensagem.descricao);
        if(salvarEFechar == true)
          this.router.navigate(['/categoria-risco']);
        else
          if(this.novoRegistro){
            this.router.navigate([`/categoria-risco/${res.dados.id}`]);
            await this.ngOnInit();
          }
      } else {
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem);
    } 

  }

  public async onSubmit() {
    this.salvarDados(false);
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    if(this.form.dirty){
      const dialogConfig = new MatDialogConfig();
      
      const modal = this.matDialog.open(ModalCancelarComponent, dialogConfig);
      modal.afterClosed().subscribe((resultado) => {
        if (resultado == true) {
          this.router.navigate(['/categoria-risco']);
        }
      });

    }else{
      this.router.navigate(['/categoria-risco']);
    }
  }
  
  public cancelar() {
    this.router.navigate(['/categoria-risco']);
  }

}
