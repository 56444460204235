import { UnidadeGestoraService } from './../../services/unidade-gestora.service';
import { CoordenacaoService } from './../../services/coordenacao.service';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { SubtipoAuditoriaService } from './../../services/subtipo-auditoria.service';
import { TipoAuditoriaService } from './../../services/tipo-auditoria.service';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { IPlanejamentoModel } from 'src/app/models/planejamento.model';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ITipoAuditoriaModel } from 'src/app/models/tipo-auditoria.model';
import { IEnumModel } from 'src/app/models/enum.model';
import { ISubtipoAuditoriaModel } from 'src/app/models/subtipo-auditoria.model';
import { IOsaFiltroModel } from 'src/app/models/osa-filtro-model';
import { IAuditorCoordenacaoModel } from 'src/app/models/auditor-coordenacao.model';
import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';
import { FiltroModel } from 'src/app/models/filtro.model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';

@Component({
  selector: 'app-planejamento-list',
  templateUrl: './planejamento-list.component.html',
  styleUrls: ['./planejamento-list.component.scss']
})
export class PlanejamentoListComponent extends BaseListComponent implements AfterViewInit {
  public dataSource = new MatTableDataSource<IPlanejamentoModel>([]);
  public selectedModel: IPlanejamentoModel;
  public displayedColumns: string[] = ['selecao', 'numero', 'orgaoAuditado', 'origem', 'ano', 'dataInicio', 'dataFim', 'status', 'ativo'];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;
  public filtroModel;
  public coordenadores = [] as IAuditorCoordenacaoModel[];
  public tiposAuditoria = [] as ITipoAuditoriaModel[];
  public subtiposAuditoria = [] as ISubtipoAuditoriaModel[];
  public unidadesGestoras = [] as IUnidadeGestoraModel[];
  public situacoes = [{codigo: 1, descricao: 'Todos'}, {codigo: 2, descricao: 'Ativos'}, {codigo: 3, descricao: 'Inativos'}] as IEnumModel[];
  public status = [{codigo: 1, descricao: 'Em Elaboração'}, {codigo: 2, descricao: 'Em Aprovação'}, {codigo: 3, descricao: 'Em Homologacao'}, {codigo: 4, descricao: 'Homologado'}, {codigo: 5, descricao: 'Elaborar'}, {codigo: 6, descricao: 'Em Execução'}]
  public origem = [{codigo: 1, descricao: 'Planejada'}, {codigo: 2, descricao: 'Especial'}]

  public form: FormGroup = this.fb.group({
    numeroOpa: [''],
    origemAuditoria: [''],
    tiposAuditoriaId: [''],
    unidadeGestora: [''],
    dataElaboracao: [''],
    dataInicio: [''],
    dataFim: [''],
    objeto: [''],
    subtipoAuditoria: [''],
    auditor: [''],
    coordenador: [''],
    status: [''],
    situacoes: [''],

  });

  public totalItens: number;
  public filtro: FiltroModel = {} as FiltroModel;
  public pageEvent: any;

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public fb: FormBuilder,
    public planejamentoService: PlanejamentoService,
    public tipoAuditoriaService: TipoAuditoriaService,
    public subtipoAuditoriaService: SubtipoAuditoriaService,
    public coordenacaoService: CoordenacaoService,
    public unidadeGestoraService: UnidadeGestoraService,
    public router: Router,
    public authService: AuthService,
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Planejamento, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Planejamento, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Planejamento, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Planejamento, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Planejamento, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Planejamento, EnumAcaoRecurso.Visualizar);
    this.permissoes.planejar = this.authService.possuiPermissao(EnumRecursoPerfil.Planejamento, EnumAcaoRecurso.Planejar);
 }

 
  ngAfterViewInit(): void {
    this.configFiltro();
    this.buscar();
  }

  public pesquisar() {

    this.planejamentoService.obterFiltrado(this.form.value)
    .then((res) => {
      this.dataSource = new MatTableDataSource<IPlanejamentoModel>(res.dados.resultado);
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = defaultDataAcessor;
      this.dataSource._updateChangeSubscription();
      this.totalItens = res.dados.totalItens;
      this.semDados = this.dataSource.filteredData.length === 0;
    })
    .catch((err) => {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    })
  }

  private obterTiposAuditoria() {
    this.tipoAuditoriaService
    .obterAtivos()
    .then((res) => {
      this.tiposAuditoria = res.dados;
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }

  private obterUnidadesGestoras() {

    this.unidadeGestoraService
    .obterAtivos()
    .then((res) => {
      this.unidadesGestoras = res.dados;
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }

  private obterCoordenadores(){
    this.coordenacaoService
    .obterCoordenadoresAtivos()
    .then((res) => {
      if (res.sucesso) {
        this.coordenadores = res.dados;
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }

  private obterSubtiposAuditoria() {
    this.subtipoAuditoriaService
    .obterAtivos()
    .then((res) => {
      this.subtiposAuditoria = res.dados;
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }


  public buscar() {
    this.obterTiposAuditoria();
    this.obterSubtiposAuditoria();
    this.obterCoordenadores();
    this.obterUnidadesGestoras();

    this.selectedModel = null;

    this.planejamentoService
      .obterFiltrado(this.filtro)
      .then((res) => {
        this.dataSource = new MatTableDataSource<IPlanejamentoModel>(res.dados.resultado);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.totalItens = res.dados.totalItens;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      })
  }

  public visualizar(id: number) {
    this.router.navigate([`/planejamento/${id}/visualizar`]);
  }

  public imprimir(id: number) {
    this.router.navigate([`/planejamento/${id}/imprimir`]);
  }

  public planejar(id: number){
    this.router.navigate([`/planejamento/${id}/planejar`]);
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel !== item ? item : null;
  }

  configFiltro() {
    this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
    this.filtro.itensPorPagina = this.tableOnePaginator?.pageSize;
    this.filtro.colunaOrder = "Id";
    this.filtro.direcao = this.sort?.direction;

    const filtrado = {};

    Object.keys(this.form.value).forEach(a => {
      if (this.form.value[a] && this.form.value[a] !== '' && this.form.value[a] !== null && this.form.value[a] !== undefined && Object.keys(this.form.value[a]).length > 0) {
        filtrado[a] = this.form.value[a];
      }
    });

    Object.assign(this.filtro, filtrado)
  }

  sortData(sort) {
    this.filtro.colunaOrder = sort.active;
    this.filtro.direcao = sort.direction;

    this.buscar();
  }

  pageChanged(e) {
    this.filtro.pagina = e.pageIndex + 1;
    e.pageIndex = this.filtro.pagina;
    this.filtro.itensPorPagina = e.pageSize;

    this.buscar();
  }
}
