import { HttpEvent, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { IBaseModel } from 'src/app/models/base.model';


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  isRefreshingToken = true;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private injector: Injector) { }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const spinner = this.injector.get(NgxSpinnerService);
    spinner.show();

    return next.handle(request).pipe(
      finalize(() => {        
        spinner.hide();
      })
    );
  }
}
