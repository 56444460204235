<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/criterios']">Critérios</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>
          </div>

          <form [formGroup]="form" #f="ngForm"
            (keydown.enter)="$event.preventDefault()">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-md-2">
                </div>
                <div class="form-group col-md-12">
                  <label for="inputNome" class="mb-1">Nome</label>
                  <input [attr.disabled]="visualizar ? true : null" type="text"
                    id="inputNome" class="form-control form-control-sm"
                    formControlName="nome"
                    maxlength="100" [ngClass]="{ 'is-invalid': f.submitted &&
                    form.controls['nome'].invalid }" />
                  <div *ngIf="f.submitted && form.controls['nome'].invalid"
                    class="invalid-feedback">
                    <div *ngIf="form.controls['nome'].errors.required">Campo
                      obrigatório</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputTipoCriterioId" class="mb-1">Tipo de Critério</label>
                  <div class="input-group input-group-sm" style="width: 100%;">
                    <input [attr.disabled]="visualizar ? true : null"
                      type="text" id="inputTipoCriterioNome" class="form-control
                      form-control-sm" readonly
                      [ngModel]="model.tipoCriterio?.nome"
                      [ngModelOptions]="{standalone: true}"
                      [ngClass]="{ 'is-invalid': f.submitted &&
                      form.controls['tipoCriterioId'].invalid }" />
                    <div class="input-group-append">
                      <button [hidden]="visualizar" type="button" class="btn
                        btn-info" (click)="exibirModalTipoCriterio()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="f.submitted &&
                    form.controls['tipoCriterioId'].invalid"
                    class="invalid-feedback">
                    <div
                      *ngIf="form.controls['tipoCriterioId'].errors.required">Campo
                      obrigatório</div>
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label for="inputSelectTipo" class="mb-1">Tipo de Entrada</label>
                  <select [attr.disabled]="visualizar ? true : null"
                    id="inputSelectTipo" #tipoEntradaId
                    formControlName="tipoEntradaId"
                    class="form-control form-control-sm" [ngClass]="{
                    'is-invalid': f.submitted &&
                    form.controls['tipoEntradaId'].invalid }"
                    (change)="onChangeTipoEntrada($event)">
                    <option *ngFor="let item of enumTipoEntrada"
                      [value]="item.codigo">
                      {{item.descricao}}</option>
                  </select>
                  <div *ngIf="f.submitted &&
                    form.controls['tipoEntradaId'].invalid"
                    class="invalid-feedback">
                    <div *ngIf="form.controls['tipoEntradaId'].errors.required">Campo
                      obrigatório</div>
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label for="inputUnidade" class="mb-1">Unidade</label>
                  <input [attr.disabled]="visualizar ? true : null" 
                    type="text"
                    id="inputUnidade" 
                    class="form-control form-control-sm"
                    formControlName="unidade"
                    [ngClass]="{ 'is-invalid': f.submitted &&
                    form.controls['unidade'].invalid }" 
                    pattern="^(?=.*[a-zA-Zà-úÀ-Ú!@#$%^&*()\-_+=\[\]{}|;:',.<>/?])[a-zA-Zà-úÀ-Ú!@#$%^&*()\-_+=\[\]{}|;:',.<>/?\d]*$"
                    maxlength="50" />
                  <div *ngIf="f.submitted && form.controls['unidade'].invalid"
                    class="invalid-feedback">
                    <div>Campo
                      obrigatório (não são permitidos somente números)</div>
                  </div>
                </div>
              </div>

              <div class="row" [hidden]="!permitePergunta">
                <div class="form-group col-md-12">
                  <label for="inputPerguntaId" class="mb-1">Pergunta</label>
                  <div class="input-group input-group-sm" style="width: 100%;">
                    <input [attr.disabled]="visualizar ? true : null"
                      type="text" id="inputPerguntaNome" class="form-control
                      form-control-sm" readonly
                      [ngModel]="model.pergunta?.nome"
                      [ngModelOptions]="{standalone: true}"
                      [ngClass]="{ 'is-invalid': f.submitted &&
                      form.controls['perguntaId'].invalid }" />
                    <div class="input-group-append">
                      <button [hidden]="visualizar" type="button" class="btn
                        btn-info" (click)="exibirModalPergunta()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="f.submitted &&
                    form.controls['perguntaId'].invalid"
                    class="invalid-feedback">
                    <div *ngIf="form.controls['perguntaId'].errors.required">Campo
                      obrigatório</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="inputDescricao" class="mb-1">Descrição</label>
                  <textarea [attr.disabled]="visualizar ? true : null" rows="3"
                    id="inputDescricao" class="form-control form-control-sm"
                    formControlName="descricao" maxlength="500" [ngClass]="{
                    'is-invalid': f.submitted &&
                    form.controls['descricao'].invalid }">
                  </textarea>
                  <div *ngIf="f.submitted && form.controls['descricao'].invalid"
                    class="invalid-feedback">
                    <div *ngIf="form.controls['descricao'].errors.required">Campo
                      obrigatório</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="mat-elevation-z1">
                    <table #table id="table" mat-table
                      [dataSource]="metricasDataSource" cdkDropList
                      [cdkDropListData]="metricasDataSource.data"
                      (cdkDropListDropped)="drop($event)">
                      <tr mat-header-row *matHeaderRowDef="['metrica', 'nota',
                        'actions']" class="bg-light"></tr>

                      <ng-container matColumnDef="metrica">
                        <th mat-header-cell *matHeaderCellDef>Métricas </th>
                        <td mat-cell *matCellDef="let element">
                          <ng-container *ngIf="element.perguntaOpcaoId">
                            {{element.perguntaOpcao.nome}}
                          </ng-container>
                          <ng-container *ngIf="element.operacao1">
                            {{element.operacao1.descricao}}
                            {{element.valorNumerico1 | number:'1.2':'pt'}}
                            {{form.controls['unidade'].value}}
                            <ng-container *ngIf="element.operacao2Id">
                              e {{element.operacao2.descricao}}
                              {{element.valorNumerico2 | number:'1.2':'pt'}}
                              {{form.controls['unidade'].value}}
                            </ng-container>
                          </ng-container>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="nota">
                        <th mat-header-cell *matHeaderCellDef>Nota </th>
                        <td mat-cell *matCellDef="let element"> {{element.nota}}
                        </td>
                      </ng-container>

                      <ng-container cdkColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> <button
                            [hidden]="visualizar" type="button" class="btn
                            btn-success btn-xs"
                            (click)="exibirModalMetrica()"> Adicionar
                          </button></th>
                        <td mat-cell *matCellDef="let element; let i= index"
                          class="block">
                          
                          <div class="btn-group mr-1">
                            <button [hidden]="visualizar" type="button"
                              class="btn btn-primary btn-xs text-left"
                              (click)="exibirModalMetrica(element, i)">
                              <i class="far fas fa-pen"></i> </button>
                          </div>
                          <div class="btn-group mr-1">
                            <button [hidden]="visualizar" type="button"
                              class="btn btn-danger btn-xs text-left"
                              (click)="excluirMetrica(i)">
                              <i class="far fa-trash-alt"></i> </button>
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-row *matRowDef="let row; columns: ['metrica',
                        'nota', 'actions'];" cdkDrag [cdkDragDisabled]="visualizar"></tr>
                    </table>
                    <h5 [hidden]="!semDados" class="table-no-data">Nenhuma
                      métrica cadastrada</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="btn-group" [hidden]="visualizar">
                <button class="btn btn-primary btn-sm mr" (click)="onSubmit(false)">
                    <i class="far fa-save"></i> Salvar
                </button>
                <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                <div class="dropdown-menu" role="menu" >
                    <a class="dropdown-item" (click)="onSubmit(false)">Salvar</a>
                    <a class="dropdown-item" (click)="onSubmit(true)">Salvar e fechar</a>
                </div>
              </div>

              <button [hidden]="visualizar" type="button" class="btn btn-default
                btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Cancelar
              </button>

              <button [hidden]="!visualizar" type="button" class="btn
                btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Sair
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
