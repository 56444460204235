import { Component, Injectable, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { IRolResponsaveisModel } from 'src/app/models/rol-responsaveis.model';
import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';
import { AuthService } from 'src/app/services/auth.service';
import { RolResponsaveisService } from 'src/app/services/rol-responsaveis.service';
import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';
import { ModalSelecionarUgImprimirComponent } from '../rol-responsavel-relatoria-unidade-gestora/imprimir-rol-responsaveis/modal-selecionar-ug-imprimir/modal-selecionar-ug-imprimir.component';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-rol-responsaveis-list',
  templateUrl: './rol-responsaveis-list.component.html',
  styleUrls: ['./rol-responsaveis-list.component.scss'],
})
export class RolResponsaveisListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<IRolResponsaveisModel>([]);
  private unidadesGestorasImprimirRolSource = new BehaviorSubject<number[]>([]);
  unidadesGestorasImprimirRol = this.unidadesGestorasImprimirRolSource.asObservable();
  public ugSemDados = true;
  public selectedModel: IRolResponsaveisModel;
  public displayedColumns: string[] = [
    'selecao',
    'codigoUg',
    'nome',
    'dataInicio',
    'dataFim',
    'quantidadeResponsaveis',
    'ativo',
  ];
  public searchNome: string;
  public searchAtivo = true;
  public dataInicio: Date;
  public dataFim: Date;
  public semDados = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private rolResponsaveisService: RolResponsaveisService,
    private unidadeGestoraService: UnidadeGestoraService,
    private router: Router,
    public matDialog: MatDialog,
    private authService : AuthService
  ) {
    super();
    this.buscar();
  }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Visualizar);
    this.permissoes.imprimir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Imprimir);
 }

  public buscar() {
    this.selectedModel = null;

    if (this.dataInicio  && this.dataFim && this.dataInicio.getTime() > this.dataFim.getTime()) {
      this.exibirMensagemAlerta('Data inicial não pode ser maior que a data final');
      return;
    }

    this.rolResponsaveisService
      .obter(this.searchNome, this.searchAtivo, this.dataInicio, this.dataFim, true)
      .then((res) => {
        this.dataSource = new MatTableDataSource<IRolResponsaveisModel>(res.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: 'error',
          title: 'Atenção',
          text: err.mensagem.descricao,
        });
      })
  }

  public inativar(id: number) {
      this.rolResponsaveisService
        .inativar(id)
        .then((res) => {
          if (res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: 'Rol de Responsável inativado com sucesso.',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          } else {
            this.exibirMensagemAlerta(res.mensagem.descricao);
          }
        })
        .catch((err) => {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: err.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        })
        .finally(() => {
          this.buscar();
        });
  }

  public reativar(id: number) {
  this.rolResponsaveisService
    .reativar(id)
    .then((res) => {
      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Rol de Responsável reativado com sucesso.',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    })
    .finally(() => {
      this.buscar();
    });
  }

  public visualizar(id: number) {
    this.router.navigate([`/rolresponsaveis/${id}/visualizar`]);
  }

  public responsaveis(id: number) {
    this.router.navigate([`/rolresponsaveis/${id}/responsaveis`]);
  }

  public selecionar(item: any) {
    this.selectedModel =
      !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }

  public imprimirRol() {
    this.router.navigate([`/rolresponsaveis/relatorio`]);
  }

  public async exibirModalUgImprimir() {
    try {

      const res = await this.unidadeGestoraService.obter();
      if (res.sucesso) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component';
        dialogConfig.width = '650px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.data = res.dados.resultado;
        
        const modal = this.matDialog.open(
          ModalSelecionarUgImprimirComponent,
          dialogConfig
        );
        modal.afterClosed().subscribe((data: IUnidadeGestoraModel[]) => {
          if (data) {
            let ids = '';
            data.forEach(element => {
              if (element.id) { ids += `${element.id};`; }
            });
            this.router.navigate([`/rolresponsaveis/relatorio/unidades-gestoras`, { ids }]);
          }
        });
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }
}
