import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IParametrizacaoDocumentoEdocsModel } from '../models/parametrizacao-documento-edocs.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ParametrizacaoDocumentoEdocsService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(): Promise<IBaseModel<IParametrizacaoDocumentoEdocsModel[]>> {
    return this.httpClient
      .get<IBaseModel<IParametrizacaoDocumentoEdocsModel[]>>(`${this.apiBaseUrl}/parametrizacaodocumentoedocs`)
      .toPromise();
  }

  public async atualizarTodos(data: IParametrizacaoDocumentoEdocsModel[]): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/parametrizacaodocumentoedocs`, data)
      .toPromise();
  }
}
