<div class="h-100">
	<div class="card text-left h-100 m-0">
		<div class="card-header bg-light">
			<h4 class="card-title mb-0">{{ titulo }}</h4>
		</div>
		<div class="p-4 wrapper">
			<div class="row">
				<div class="col-sm-6">
					<label for="validationCustom01" class="form-label"
						>Nº Solicitação Técnica</label
					>
					<input
						type="text"
						maxlength="15"
						name="nSolicitacaoTecnica"
						class="form-control form-control-sm"
						id="nSolicitacaoTecnica"
						[(ngModel)]="model.numero"
						placeholder="-"
						disabled
						[ngModelOptions]="{ standalone: true }"
					/>
				</div>
				<div class="col-sm-6">
					<label for="validationCustom02" class="form-label"
						>Prazo</label
					>
					<input
						type="text"
						name="prazo"
						class="form-control form-control-sm"
						id="nSolicitacao"
						bsDatepicker
						[bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
						[(ngModel)]="model.prazo"
						disabled
						[ngModelOptions]="{ standalone: true }"
					/>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-sm-12">
					<label for="validationCustom05" class="form-label"
						>Assunto:
					</label>
					<textarea
						rows="5"
						[(ngModel)]="model.assunto"
						class="form-control"
						disabled
						[ngModelOptions]="{ standalone: true }"
					></textarea>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-sm-12">
					<label for="validationCustom06" class="form-label"
						>Visualizar Itens</label
					>
					<div class="mat-elevation-z1">
						<table
							mat-table
							[dataSource]="dataSourceItems"
							matSortDisableClear
							#tabela1="matSort"
							matSort
							matSortDirection="asc"
						>
							<tr
								mat-header-row
								*matHeaderRowDef="[
									'numero',
									'titulo',
									'status',
									'actions'
								]"
								class="bg-light"
							></tr>

							<ng-container matColumnDef="numero">
								<th
									mat-header-cell
									*matHeaderCellDef
									mat-sort-header
								>
									Nº Item
								</th>
								<td
									mat-cell
									*matCellDef="let element"
									class="text"
								>
									{{ element?.numero }}
								</td>
							</ng-container>

							<ng-container matColumnDef="titulo">
								<th
									mat-header-cell
									*matHeaderCellDef
									mat-sort-header
								>
									Titulo
								</th>
								<td
									mat-cell
									*matCellDef="let element"
									class="text"
								>
									{{ element?.titulo }}
								</td>
							</ng-container>

							<ng-container matColumnDef="status">
								<th
									mat-header-cell
									*matHeaderCellDef
									mat-sort-header
								>
									Status
								</th>
								<td mat-cell *matCellDef="let element">
									<span *ngIf="!element?.status">-</span>
                    <span *ngIf="element?.status?.codigo === 1" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 2" class="badge bg-secondary text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 3" class="badge bg-danger text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 4" class="badge bg-info text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 5" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 6" class="badge bg-primary text-xs">{{element?.status?.descricao}}</span>

								</td>
							</ng-container>

							<ng-container cdkColumnDef="actions">
								<th
									mat-header-cell
									*matHeaderCellDef
									style="max-width: 60px"
									class="text-center"
								>
									Ações
								</th>
								<td
									mat-cell
									*matCellDef="let element; let i = index"
									class="block"
								>
									<div
										class="w-100 d-flex justify-content-center"
									>
										<button
											type="button"
											(click)="
												modalVisulizaItem(element?.id)
											"
											class="btn btn-info btn-xs text-left"
										>
											<i class="far fas fa-eye"></i>
										</button>
									</div>
								</td>
							</ng-container>

							<tr
								mat-row
								*matRowDef="
									let row;
									columns: [
										'numero',
										'titulo',
										'status',
										'actions'
									]
								"
							></tr>
						</table>
						<h5
							class="table-no-data"
							[hidden]="dataSourceItems?.data?.length > 0"
						>
							Nenhum registro encontrado
						</h5>
						<mat-paginator
							#TableOnePaginator="matPaginator"
							[pageSizeOptions]="[5]"
							showFirstLastButtons
						>
						</mat-paginator>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer text-right">
					<button
						type="button"
						class="btn btn-default btn-sm"
						(click)="fechar()"
					>
						<i class="fas fa-times"></i> Fechar
					</button>
				</div>
	</div>
</div>
