import { PaginacaoModel } from './../models/paginacao.model';
import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAlocarEquipeObterModel, ISalvarAlocarEquipeModel } from '../models/alocar-equipe-component';
import { IAtividadeModel } from '../models/atividade.model';
import { IBaseModel } from '../models/base.model';
import { ICronogramaModel } from '../models/cronograma.model';
import { IOsaAtividadeModel } from '../models/osa-atividade.model';
import { IOsaFiltroModel } from '../models/osa-filtro-model';
import { IOsaModelImprimir } from '../models/osa-imprimir';
import { IOsaModel } from '../models/osa-model';
import { IOsaSubAtividadeModel } from '../models/osa-sub-atividade.model';
import { ISubAtividadeModel } from '../models/sub-atividade.model';
import { IValidacaoNivelModel } from '../models/validaca-nivel-aprovacao.model';
import { BaseService } from './base.service';
import { IEntranharDocumentoModel } from '../models/edocs/entranhar-documento.model';

@Injectable({
  providedIn: 'root'
})
export class OsaService extends BaseService {
  public osaDate!: IOsaModel[];
  private osaSource = new BehaviorSubject<any>('');
  public currentOsa = this.osaSource.asObservable();

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(filtro?: IOsaFiltroModel): Promise<PaginacaoModel<IOsaModel[]>> {
    const params = this.recuperarParametrosFiltro(filtro);
    return this.httpClient
      .get<IBaseModel<PaginacaoModel<IOsaModel[]>>>(`${this.apiBaseUrl}/osas`, { params })
      .toPromise().then(result => {
        this.osaSource.next(result.dados)
        return result.dados;
      });
  }

  public async obterPorId(id: number): Promise<IBaseModel<IOsaModel>> {
    return this.httpClient
      .get<IBaseModel<IOsaModel>>(`${this.apiBaseUrl}/osas/${id}`)
      .toPromise();
  }

  public async salvarOsa(
    id: number, data: any): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/osas/${id}`, data)
      .toPromise();
  }


  public async gerarNovaVersao(
    id: number, data?: any): Promise<IBaseModel<any>> {
    return this.httpClient
      .post<IBaseModel<any>>(`${this.apiBaseUrl}/osas/${id}/nova-versao`, data)
      .toPromise();
  }


  public async inativar(id: number, justificativa: string): Promise<IBaseModel<void>> {
    return this.httpClient
      .patch<IBaseModel<void>>(`${this.apiBaseUrl}/osas/${id}/inativar`, { justificativa })
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .patch<IBaseModel<void>>(`${this.apiBaseUrl}/osas/${id}/reativar`, {})
      .toPromise();
  }

  public async enviarAprovacao(id: number): Promise<IBaseModel<IOsaModel>> {
    return this.httpClient
      .put<IBaseModel<IOsaModel>>(`${this.apiBaseUrl}/osas/${id}/enviar-aprovacao`, null)
      .toPromise();
  }

  public async getImprimir(id: number): Promise<IBaseModel<IOsaModelImprimir>> {
    return this.httpClient
      .get<IBaseModel<IOsaModelImprimir>>(`${this.apiBaseUrl}/osas/${id}/imprimir`)
      .toPromise();
  }

  private recuperarParametrosFiltro(filtro: IOsaFiltroModel): HttpParams {
    let params = new HttpParams();
    if (filtro) {
      if (filtro.dataInicioRealizacao) {
        params = params.append('dataInicioRealizacao', filtro.dataInicioRealizacao?.toString());
      }
      if (filtro.dataFimRealizacao) {
        params = params.append('dataFimRealizacao', filtro.dataFimRealizacao?.toString());
      }
      if (filtro.dataInicioPrevisto) {
        params = params.append('dataInicioPrevisto', filtro.dataInicioPrevisto?.toString());
      }
      if (filtro.dataFimPrevisto) {
        params = params.append('dataFimPrevisto', filtro.dataFimPrevisto?.toString());
      }
      if (filtro.ativo !== undefined && filtro.ativo !== null) {
        params = params.append('ativo', filtro.ativo?.toString());
      }
      if (filtro.coordenador) {
        params = params.append('coordenador', filtro.coordenador?.toString());
      }
      if (filtro.objeto) {
        params = params.append('objeto', filtro.objeto);
      }
      if (filtro.origemAuditoria) {
        params = params.append('origemAuditoria', filtro.origemAuditoria?.toString());
      }
      if (filtro.ativo) {
        params = params.append('ativo', filtro.ativo?.toString());
      }
      if (filtro.subtipoAuditoriaId) {
        params = params.append('subtipoAuditoria', filtro.subtipoAuditoriaId?.toString());
      }
      if (filtro.tipoAuditoriaId) {
        params = params.append('tipoAuditoria', filtro.tipoAuditoriaId?.toString());
      }
      if (filtro.unidadeGestoraId) {
        params = params.append('unidadeGestoraId', filtro.unidadeGestoraId?.toString());
      }
      if (filtro.numeroOsa) {
        params = params.append('numeroOsa', filtro.numeroOsa?.toString());
      }
      if (filtro.numeroOpa) {
        params = params.append('numeroOpa', filtro.numeroOpa?.toString());
      }
      if (filtro.status) {
        params = params.append('status', filtro.status?.toString());
      }
      if (filtro.pagina) {
        params = params.append('pagina', filtro.pagina?.toString());
      }
      if (filtro.itensPorPagina) {
        params = params.append('itensPorPagina', filtro.itensPorPagina?.toString());
      }
    }
    return params;
  }

  public async salvarOsaAtividade(id: number, atividadeId: number, data: IOsaAtividadeModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/osas/${id}/atividades/${atividadeId}/osaatividade`, data)
      .toPromise();
  }

  public async salvarOsaSubAtividade(id: number, subAtividadeId: number, data: IOsaSubAtividadeModel ): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/osas/${id}/subatividade/${subAtividadeId}/osasubatividade`, data)
      .toPromise();
  }

  public async obterCronogramaOsa(osaId: number): Promise<IBaseModel<ICronogramaModel>> {
    return this.httpClient
      .get<IBaseModel<ICronogramaModel>>(`${this.apiBaseUrl}/osas/cronograma/${osaId}`)
      .toPromise();
  }

  public async alocarEquipeOsa(
    id: number, data: IOsaModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/osas/alocar-equipe`, data)
      .toPromise();
  }

  public async homologar(id: number, entranharDocumento: IEntranharDocumentoModel): Promise<IBaseModel<IOsaModel>> {
    return this.httpClient
      .put<IBaseModel<IOsaModel>>(`${this.apiBaseUrl}/osas/${id}/homologar`, entranharDocumento)
      .toPromise();
  }

  public obterAlocarEquipeId(osaId: number, equipeId: number): Promise<IBaseModel<IAlocarEquipeObterModel[]>> {
    return this.httpClient
    .get<IBaseModel<IAlocarEquipeObterModel[]>>(`${this.apiBaseUrl}/osas/${osaId}/equipe/${equipeId}`)
      .toPromise();
  }

  public obterAlocarEquipeOsaId(osaId: number): Promise<IBaseModel<IAlocarEquipeObterModel[]>> {
    return this.httpClient
      .get<IBaseModel<IAlocarEquipeObterModel[]>>(`${this.apiBaseUrl}/osas/${osaId}/equipes`)
        .toPromise();
  }

  public salvarAlocarEquipeOsaId(osaId: number, data: ISalvarAlocarEquipeModel): Promise<IBaseModel<IAlocarEquipeObterModel[]>> {
    return this.httpClient
      .post<IBaseModel<IAlocarEquipeObterModel[]>>(`${this.apiBaseUrl}/osas/${osaId}/alocar-equipe`, data)
        .toPromise();
  }

  public desvincularAuditorId(osaId: number, equipeId: number) {
    return this.httpClient
    .delete<IBaseModel<IValidacaoNivelModel>>(`${this.apiBaseUrl}/osas/${osaId}/equipe/${equipeId}/desvincular`)
    .toPromise();
  }

  public async verificarNivelAprovacao(id: number): Promise<IBaseModel<IValidacaoNivelModel>> {
    return this.httpClient
      .get<IBaseModel<IValidacaoNivelModel>>(`${this.apiBaseUrl}/osas/${id}/verificar-nivel-aprovacao`)
      .toPromise();
  }

}
