<div class="row">
  <div class="col-sm-12">
    <div class="mat-elevation-z1 ">
      <table mat-table class="w-100" [dataSource]="dataSource" matSortDisableClear #tabela1="matSort" matSort
        matSortDirection="asc">

        <ng-container matColumnDef="ug">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Unidade(s) Gestora(s)
          </th>
          <td mat-cell *matCellDef="let element" class="text limit-text">
            {{element?.unidadeGestora?.sigla}} - {{ element?.unidadeGestora?.nome }}
          </td>
        </ng-container>

        <ng-container matColumnDef="responsavel">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Responsável
          </th>
          <td mat-cell *matCellDef="let element" class="text limit-text">
            {{ element?.responsavel?.nome }}
          </td>
        </ng-container>

        <ng-container matColumnDef="subtipo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Subtipo
          </th>
          <td mat-cell *matCellDef="let element" class="text limit-text">
            {{ element?.subtipoAuditoria?.subTipoAuditoria?.nome }}
            {{ element?.subtipoAuditoria?.nome }}
          </td>
        </ng-container>

        <ng-container cdkColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            <button type="button" class="btn btn-success btn-xs w-100"
            (click)="modalIncluirUg()" *ngIf="!visualizar">
              Adicionar
            </button>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="block">
            <div class="d-flex w-100 justify-content-center align-items-center">
              <button type="button" class="btn btn-danger btn-xs text-left " (click)="excluirUg(element.unidadeGestoraId, element.subTipoAuditoriaId)" *ngIf="!visualizar">
                <i class="far fa-trash-alt"></i>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-light"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <h5 class="table-no-data" [hidden]="dataSource?.data?.length > 0">
        Nenhuma Ug vinculada
      </h5>
      <mat-paginator #TableOnePaginator="matPaginator" [hidden]="!(dataSource?.data?.length > 0)" [pageSizeOptions]="[5]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
