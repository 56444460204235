import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { ModeloRelatorioFormComponent } from 'src/app/pages/modelo-relatorio-form/modelo-relatorio-form.component';

@Injectable()
export class ModeloRelatorioGuard implements CanDeactivate<ModeloRelatorioFormComponent> {
  canDeactivate(component: ModeloRelatorioFormComponent): Observable<boolean> | boolean {
    if (component.novoRegistro) {
      if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    } else { return true; }
  }
}
