import { AngularEditorConfig } from '@kolkov/angular-editor';

export const angularEditorConfig = (placeholder?: string, height?: string, width?: string, enableToolbar?: boolean) => {
	return {
		outline:true,
		editable: true,
		spellcheck: true,
		height: height,
		minHeight: "0",
		maxHeight: "auto",
		width: width,
		minWidth: "0",
		translate: "yes",
		enableToolbar: enableToolbar,
		showToolbar: enableToolbar,
		placeholder: placeholder,
		defaultParagraphSeparator: "",
		defaultFontName: "",
		defaultFontSize: "",
		fonts: [
			{ class: "arial", name: "Arial" },
			{ class: "times-new-roman", name: "Times New Roman" },
			{ class: "calibri", name: "Calibri" },
			{ class: "comic-sans-ms", name: "Comic Sans MS" },
		],
		customClasses: [
			{
				name: "quote",
				class: "quote",
			},
			{
				name: "redText",
				class: "redText",
			},
			{
				name: "titleText",
				class: "titleText",
				tag: "h1",
			},
		],		
		uploadWithCredentials: true,
		sanitize: false,
		toolbarPosition: "top",
		toolbarHiddenButtons: [null, null],
	} as AngularEditorConfig;
};
