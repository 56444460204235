import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';

@Component({
  selector: 'app-lookup-modal',
  templateUrl: './lookup-modal.component.html',
  styleUrls: ['./lookup-modal.component.scss']
})
export class LookupModalComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  public semDados = true;
  public exibirId = true;
  public titulo = '';
  public selecionado: any;
  public displayedColumns: string[];
  public columns: { columnDef: string, header: string }[];
  public customFilterPredicate;
  public pageSizeOptions: string[] = ["5"];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<LookupModalComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      if(data.pageSizeOptions){
        this.pageSizeOptions = [`${data.pageSizeOptions}`];
      }

    if (data.customizeColumns) {
      this.columns = data.customizeColumns;
    }
    else if (data.exibirId) {
      this.columns = [
        {
          columnDef: 'selecao',
          header: ''
        },
        {
          columnDef: 'id',
          header: 'ID'
        },
        {
          columnDef: 'nome',
          header: 'Nome'
        }];
    } else {
      this.columns = [
        {
          columnDef: 'selecao',
          header: ''
        },
        {
          columnDef: 'nome',
          header: 'Nome'
        }];
    }

    this.displayedColumns = this.columns.map(x => x.columnDef);
    this.exibirId = data.exibirId;
    this.titulo = data.titulo;
    this.dataSource = new MatTableDataSource<any>(data.dados);
    this.dataSource._updateChangeSubscription();

    if (data.customFilterPredicate) {
      this.customFilterPredicate = data.customFilterPredicate;
    }
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.customFilterPredicate) {
      this.dataSource.filterPredicate = this.customFilterPredicate;
    }
    else {
      this.dataSource.filterPredicate = (data: { nome: string }, filterValue: string) => {
        return (data.nome || '').trim().toLowerCase().indexOf(filterValue) !== -1;
      };
    }
    this.dataSource.sortingDataAccessor = defaultDataAcessor;
    this.dataSource._updateChangeSubscription();
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public filtrar(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public selecionarItem(item: any) {
    this.selecionado = !this.selecionado || this.selecionado.id !== item.id ? item : null;
  }

  public concluir() {
    this.dialogRef.close(this.selecionado);
  }

  public cancelar() {
    this.dialogRef.close();
  }
}

