<div id="modal-content-wrapper">
    <div class="card card-secondary card-outline">
      <div class="card-header bg-light">
        <h4 class="card-title mb-0" >{{titulo}}</h4>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="card-body p-0">
  
        <div class="table-container p-3">
  
          <div class="row">
            <div class="form-group col-md-12" [formGroup]="form">
              <label for="justificativaTipoAuditoria" class="mb-1">Justificativa</label>
              <textarea rows="5" name="justificativaTipoAuditoria" id="justificativaTipoAuditoria" class="form-control form-control-sm" formControlName="justificativaTipoAuditoria"></textarea>
                <div *ngIf="form.controls['justificativaTipoAuditoria'].touched && form.controls['justificativaTipoAuditoria'].invalid"
                        class="invalid-feedback">
                </div>
            </div>
          </div>
  
        </div>
      </div>
      <div class="card-footer">
        <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()"
        [disabled]="form.controls['justificativaTipoAuditoria'].invalid">
          <i class="fas fa-check"></i> Sim
        </button>
  
        <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
          <i class="fas fa-times"></i> Não
        </button>
      </div>
    </div>
  </div>
  
