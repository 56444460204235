import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-cancelar',
  templateUrl: './modal-cancelar.component.html',
})
export class ModalCancelarComponent implements OnInit {

  constructor(
    public matDialog: MatDialog,
    private dialogRef: MatDialogRef<ModalCancelarComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;
  }

  public data = {} as any;
  ngOnInit(): void { }

  Sim() {
    if (this.data && this.data.interno) {
      this.matDialog.closeAll();
    }
    else {
      this.dialogRef.close(true);
    }
  }

  Nao() {
    this.dialogRef.close(false);
  }
}
