import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-modal-alterar-tipo-auditoria',
  templateUrl: './modal-alterar-tipo-auditoria.component.html',
  styleUrls: ['./modal-alterar-tipo-auditoria.component.scss']
})
export class ModalAlterarTipoAuditoriaComponent implements OnInit {
  public form = new FormGroup({
    justificativaTipoAuditoria: new FormControl('', Validators.required),
  });
  
  public titulo: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<ModalAlterarTipoAuditoriaComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    this.titulo = data.titulo ?? "Tem certeza que deseja reativar esse registro?";
  }

  ngOnInit(): void {
  }

  public concluir() {
    this.dialogRef.close(this.form.get('justificativaTipoAuditoria').value);
  }

  public cancelar() {
    this.dialogRef.close();
  }

}
