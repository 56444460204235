import { Component, OnInit } from "@angular/core";
import {
	MatDialog,
	MatDialogConfig,
	MatDialogRef,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ToastrService } from "ngx-toastr";
import { IBaseModel } from "src/app/models/base.model";
import { IPendenciasModel } from "src/app/models/pendencias.model";
import Swal from "sweetalert2";
import { LookupModalComponent } from "../lookup-modal/lookup-modal.component";
import { LookupMultiselectModalComponent } from "../lookup-multiselect-modal/lookup-multiselect-modal.component";

@Component({
	selector: "app-base-form",
	template: ``,
	styleUrls: ['./base-form.component.scss'],
})
export class BaseFormComponent implements OnInit {
	public id: number;
	public novoRegistro = true;
	public visualizacao = true;
	public submit = false;
	public visualizar = false;
	public prefixo = "novo";
	public titulo: string;
	public responder = false;
	public aba: number = 0;

	constructor(
		public route: ActivatedRoute,
		public toastr: ToastrService,
		public router: Router,
		public localeService: BsLocaleService,
		public matDialog: MatDialog
	  ) {
		this.localeService.use("pt-br");

		const visualizacao =
			this.route.snapshot.routeConfig?.data["visualizacao"];
		const id = this.route.snapshot.paramMap.get("id");
		const path = this.route.snapshot.url.pop()?.toString();
		if (id) {
			this.id = parseInt(id, 10);
			this.novoRegistro = false;
		}

		// TODO REMOVER
		if (visualizacao) {
			this.visualizacao = visualizacao;
		}

		if (path?.includes("edicao")) {
			this.prefixo = "edicao";
		}
		if (path?.includes("visualizar")) {
			this.prefixo = "visualizar";
			this.visualizar = true;
		}
	}

	ngOnInit(): void {}

	public montarMensagensValidacao(model: IBaseModel<any>) {
		let validacaoTexto = "";

		if (model.resultadoValidacao && model.resultadoValidacao.length > 0) {
			validacaoTexto = "";
			for (const val of model.resultadoValidacao) {
				validacaoTexto += ` • ${val.errorMessage} `;

				// validacaoTexto +=
				// 	validacaoTexto === ""
				// 		? `• ${val.errorMessage}`
				// 		: `<br/> • ${val.errorMessage}`;
			}
		} else if (model.mensagem?.descricao) {
			validacaoTexto = '  ' + model.mensagem.descricao;
		}

		return validacaoTexto;
	}

	public exibirMensagemAlerta(mensagem: string, html: boolean = false, titulo: string = "") {
		Swal.fire({
			toast: true,
			position: "top-end",
			icon: "warning",
			html: mensagem,
			showConfirmButton: false,
			showCloseButton: true,
			title: titulo,
		});
	}

	public exibirMensagemSucesso(mensagem: string) {
		Swal.fire({
			toast: true,
			position: "top-end",
			icon: "success",
			text: mensagem,
			showConfirmButton: false,
			timer: 5000,
			timerProgressBar: true,
		});
	}

	public exibirMensagemErro(mensagem: string, html: boolean = false) {
		Swal.fire({
			toast: true,
			position: "top-end",
			icon: "error",
			html: mensagem,
			showConfirmButton: false,
			showCloseButton: true,
		});
	}

	public exibirModal(
		titulo: string,
		dados: any,
		exibirId = true,
		customizeColumns = null,
		customFilterPredicate = null,
		pageSizeOptions = null
	): MatDialogRef<any> {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component";
		dialogConfig.width = "650px";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		dialogConfig.data = {
			titulo,
			dados,
			exibirId,
			customizeColumns,
			customFilterPredicate,
			pageSizeOptions
		};
		return this.matDialog.open(LookupModalComponent, dialogConfig);
	}

	public exibirModalMultiselect(
		titulo: string,
		dados: any,
		botaoOk = "Selecionar",
		exibirId = true
	): MatDialogRef<any> {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component";
		dialogConfig.width = "650px";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		dialogConfig.data = { titulo, dados, botaoOk, exibirId: false };
		return this.matDialog.open(
			LookupMultiselectModalComponent,
			dialogConfig
		);
	}

	public showPendencias(pendencias: IPendenciasModel[]) {
		const list = pendencias?.map(
			(pendencia) =>
				`<div style="text-align: left;"><strong>${pendencia.grupo}:</strong> ${pendencia.descricao}</div>`
		);
		if (list && list.length > 0) {
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "warning",
				html: list.join(""),
				showConfirmButton: false,
				showCloseButton: true,
			});
		}
	}

	public exibirMensagemPadrao(retorno: IBaseModel<any>){
		if(retorno.sucesso){
			this.exibirMensagemSucesso(retorno.mensagem.descricao);
		}else{
			this.exibirMensagemErro(retorno.mensagem.descricao);
		}
	}
}
