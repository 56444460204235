import { IAuditorModel } from './../../../models/alocar-equipe-component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IOsaModelImprimir } from 'src/app/models/osa-imprimir';
import { OsaService } from 'src/app/services/osa.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import Swal from 'sweetalert2';
import { ILinhaDoTempoAvaliacaoModel } from 'src/app/models/linha-tempo-avaliacao.model';

@Component({
  selector: 'app-osa-imprimir',
  templateUrl: './osa-imprimir.component.html',
  styleUrls: ['./osa-imprimir.component.scss']
})
export class OsaImprimirComponent extends BaseFormComponent implements OnInit {

  public model!: IOsaModelImprimir;

  constructor(route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    matDialog: MatDialog,
    private osaService: OsaService) {
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {
    this.buscar();
  }

  buscar() {

    this.osaService.getImprimir(this.id).then(res => {
      if (!res.sucesso) {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
      this.model = res.dados;
    })
  }

  public print() {
    window.print();
  }

  public cancelar() {
    window.history.back()
  }
}
