<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="card-header bg-light" cdkDragHandle>
      <h4 class="card-title mb-0">Visualização de recomendação</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-container p-3">
        <div class="row ">
          <div class="form-group col-md-8">
            <label for="nome" class="mb-1">Recomendação</label>
            <input type="text" disabled  id="nome"
            class="form-control form-control-sm" [(ngModel)]="model.nome" maxlength="100" />
          </div>

          <div class="form-group col-md-4">
            <label for="selectTipo" class="mb-1">Marco </label>
            <select id="selectTipo" disabled  [(ngModel)]="model.tipoRecomendacao" class="form-control form-control-sm">
            <option [ngValue]="1">Interna</option>
            <option [ngValue]="2">Externa</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <label for="descricao" class="mb-1">Descrição</label>
            <angular-editor [config]="config" id="descricao" [(ngModel)]="model.descricao" rows="15" ></angular-editor>
          </div>
        </div>

      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
</div>
