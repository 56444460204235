import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IProcedimentoAuditoriaModel } from '../models/procedimento-auditoria.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ProcedimentoAuditoriaService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<IProcedimentoAuditoriaModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<IProcedimentoAuditoriaModel[]>>(`${this.apiBaseUrl}/procedimentoAuditoria`, { params })
      .toPromise();
  }


  public async obterPorId(id: number): Promise<IBaseModel<IProcedimentoAuditoriaModel>> {
    return this.httpClient
      .get<IBaseModel<IProcedimentoAuditoriaModel>>(`${this.apiBaseUrl}/procedimentoAuditoria/${id}`)
      .toPromise();
  }

  public async inserir(data: IProcedimentoAuditoriaModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/procedimentoAuditoria`, data)
      .toPromise();
  }

  public async atualizar(data: IProcedimentoAuditoriaModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/procedimentoAuditoria/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/procedimentoAuditoria/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/procedimentoAuditoria/${id}/inativar`, {})
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/procedimentoAuditoria/${id}`, {})
      .toPromise();
  }
}
