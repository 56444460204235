import { ActivatedRoute, Router } from '@angular/router';
import { SolicitacaoTecnicaService } from './../../../../services/solicitacao-tecnica.service';
import { ISolicitacaoTecnicaModel } from './../../../../models/solicitacao-tecnica.model';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-imprimir-solicitacao',
  templateUrl: './imprimir-solicitacao.component.html',
  styleUrls: ['./imprimir-solicitacao.component.scss']
})
export class ImprimirSolicitacaoComponent implements OnInit {

	public model: ISolicitacaoTecnicaModel = {} as ISolicitacaoTecnicaModel;
  constructor(
    public serviceSolicitacao: SolicitacaoTecnicaService,
    public route: ActivatedRoute,
    public router: Router,
  ) {
    this.model.id = Number(this.route.snapshot.paramMap.get('solicitacaotecnicaId'));
   }

  ngOnInit(): void {
    this.obterPorId()
  }

  public obterPorId() {
		this.serviceSolicitacao.obterPorId(this.model?.id).then((res) => {
			if (res.sucesso) {
				this.model = res.dados

			} else {
				Swal.fire({
					toast: true,
					position: "top-end",
					icon: "warning",
					text: res.mensagem.descricao,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
			}
		});
	}


  public cancelar() {
    window.history.back()
  }

  public print() {
    window.print();
  }
}
