import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';
import { IAgentePublicoModel } from 'src/app/models/agente-publico.model';
import { IFiltroAgentePublico } from 'src/app/models/filtros/filtro-agente-publico.model';
import { AgentePublicoService } from 'src/app/services/agente-publico.service';
import { IAgentePublicoViewModel } from 'src/app/models/viewmodels/agente-publico-viewmodel';
import { formatDate } from '@angular/common';

const sortingDataAccessor = (item, property) => {
  switch (property) {
    case 'nome':
      return item.pessoa.nome;
    case 'cpfFormatted':
      return item.pessoa.cpf;
    default:
      return item[property];
  }
};

@Component({
  selector: 'app-agente-publico-list',
  templateUrl: './agente-publico-list.component.html',
  styleUrls: ['./agente-publico-list.component.scss']
})
export class AgentePublicoListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<IAgentePublicoModel>([]);
  public selectedModel: IAgentePublicoModel;
  public displayedColumns: string[] = ['selecao', 'nome', 'cpfFormatted', 'numeroFuncional', 'ativo'];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;
  public permissoes: IPermissaoModel;
  public filtro = {} as IFiltroAgentePublico;
  public totalItens: number;
  public pageEvent: any;
  public model : IAgentePublicoViewModel;

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private agentePublicoService: AgentePublicoService,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService
  ) {
    super();
    
  }

  ngOnInit(): void {
    this.configurarFiltro();
    this.buscar();
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Visualizar);
 }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
  }

  public toolTipDataUltimaAtualizacao(){
    let toolTip = 'Última atualização: ';

    if(this.model && this.model.ultimaAtualizacao)
      toolTip += `${formatDate(this.model.ultimaAtualizacao,'dd/MM/yyyy HH:mm','pt-BR')}`;
    return toolTip;
  }

  public buscar() {
    this.selectedModel = null;
    this.agentePublicoService
      .obterViewModel(this.filtro)
      .then((res) => {
        this.model = res.dados;
        this.totalItens = res.dados.totalItens;
        this.dataSource = new MatTableDataSource<any>(res.dados.resultado);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
        this.configurarFiltro();
      })
      .catch((err) => {
        this.exibirMensagemAlerta(err);
      })
  }

  public sincronizar() {
    
        Swal.fire({
          title: 'Atualização de agentes públicos',
          text:
            `${this.toolTipDataUltimaAtualizacao()}. Deseja realmente atualizar a base? (Isso pode levar um tempo).`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then(async (result) => {
          if (result.value) {
            this.agentePublicoService
                      .sincronizar()
                      .then((res) =>{
                        if(res.sucesso){
                          this.exibirMensagemSucesso(res.mensagem.descricao);
                          this.toolTipDataUltimaAtualizacao();
                        }else{
                          this.exibirMensagemErro(res.mensagem.descricao);
                        }
                      })
                      .catch((err)=>{
                        this.exibirMensagemAlerta(err);
                      });
          }
        });
    
  }

  

  public editar(id: number) {
    this.router.navigate([`/agente-publico/${id}`]);
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.idAgentePublico !== item.idAgentePublico ? item : null;
  }


  public visualizar(id: number) {
    this.router.navigate([`/agente-publico/${id}/visualizar`]);
  }

  private configurarFiltro() {
    this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
    this.filtro.itensPorPagina = this.tableOnePaginator.pageSize;
    this.filtro.ativo = this.searchAtivo;
    this.filtro.pesquisa = this.searchNome;
    this.filtro.direcao = this.sort.direction;
    this.filtro.colunaOrder = this.sort.active;
  }
  sortData(sort) {
    this.filtro.colunaOrder = sort.active;
    this.filtro.direcao = sort.direction;

    this.buscar();
}

  pageChanged(e) {
    this.filtro.pagina = e.pageIndex + 1;
    e.pageIndex = this.filtro.pagina;
    this.filtro.itensPorPagina = e.pageSize;
    this.buscar();
  }

  public reativar(id: number) {
    this.agentePublicoService
      .reativar(id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso('Agente público reativado com sucesso.');
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public inativar(id: number) {
    this.agentePublicoService
      .inativar(id)
      .then((res) => {
        if (res.sucesso) {
        this.exibirMensagemSucesso('Agente público inativado com sucesso.');
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err);
      })
      .finally(() => {
        this.buscar();
      });
  }

}
