<div id="modal-content-wrapper">
	<div
		class="card card-secondary card-outline"
		cdkDragBoundary="html"
		cdkDrag
		cdkDragRootElement=".cdk-overlay-pane"
	>
		<div class="card-header bg-light" cdkDragHandle>
			<h4 class="card-title mb-0">Capacidade de Trabalho</h4>
			<div class="card-tools">
				<button type="button" class="btn btn-tool" (click)="fechar()">
					<i class="fas fa-times"></i>
				</button>
			</div>
		</div>
		<div class="card-body p-0">
			<div class="table-container p-3">
				<div class="row">
					<div class="form-group col-md-10">
						<label for="coordenacaoNome" class="mb-1"
							>Coordenação</label
						>
						<input
							type="text"
							id="coordenacaoNome"
							class="form-control form-control-sm"
							disabled
							[ngModel]="model?.nome"
						/>
					</div>
					<div class="form-group col-md-2">
						<label for="coordenacaoAno" class="mb-1">Ano</label>
						<input
							type="text"
							id="coordenacaoAno"
							class="form-control form-control-sm"
							disabled
							[ngModel]="model?.ano"
						/>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<div class="mat-elevation-z1">
							<mat-toolbar class="table-header">
                                <mat-toolbar-row>
									<label class="mb-1" style="font-size: 16px;">Dispensas</label>
								</mat-toolbar-row>
							</mat-toolbar>
							<table mat-table [dataSource]="dispensaDataSource">
								<tr
									mat-header-row
									*matHeaderRowDef="['nomeAuditor','descricaoDispensa','dataInicioFormatted','dataFimFormatted','qtdDias']"
									class="bg-light"
								></tr>

								<ng-container matColumnDef="nomeAuditor">
									<th mat-header-cell *matHeaderCellDef>
										Auditor
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.nomeAuditor }}
									</td>
								</ng-container>
								<ng-container matColumnDef="descricaoDispensa">
									<th mat-header-cell *matHeaderCellDef>
										Descrição
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.descricaoDispensa }}
									</td>
								</ng-container>

								<ng-container matColumnDef="dataInicioFormatted">
									<th mat-header-cell *matHeaderCellDef>
										Data Início
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.dataInicioFormatted }}
									</td>
								</ng-container>
								<ng-container matColumnDef="dataFimFormatted">
									<th mat-header-cell *matHeaderCellDef>
										Data fim
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.dataFimFormatted }}
									</td>
								</ng-container>
								<ng-container matColumnDef="qtdDias">
									<th mat-header-cell *matHeaderCellDef>
										Quantidade de Dias
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.qtdDias }}
									</td>
								</ng-container>

								<tr
									mat-row
									*matRowDef="
										let row;
										columns: ['nomeAuditor','descricaoDispensa','dataInicioFormatted','dataFimFormatted','qtdDias']
									"
								></tr>
							</table>
							<h5
								[hidden]="!dispensaSemDados"
								class="table-no-data"
							>
								Nenhuma dispensa cadastrada
							</h5>
						</div>
					</div>
				</div>

				<div class="row pt-4">
					<div class="col-md-12">
						<div class="mat-elevation-z1">
							<mat-toolbar class="table-header">
                                <mat-toolbar-row>
									<label class="mb-1" style="font-size: 16px;">Disponibilidade</label>
								</mat-toolbar-row>
							</mat-toolbar>
							<table
								mat-table
								[dataSource]="disponibilidadeDataSource"
								class="w-100"
							>
								<ng-container matColumnDef="nomeAuditor">
									<th mat-header-cell *matHeaderCellDef>
										Auditor
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.nomeAuditor }}
									</td>
								</ng-container>

								<div *ngFor="let coluna of colunasAdicionar">
									<ng-container [matColumnDef]="coluna">
										<th
											mat-header-cell
											*matHeaderCellDef
											align="center"
										>
											{{ coluna }}
										</th>
										<td mat-cell *matCellDef="let element">
											{{
												obterValorColunaNovo(
													element,
													coluna
												) 
											}}
										</td>
									</ng-container>
								</div>

								<tr
									mat-header-row
									*matHeaderRowDef="columnsDisponibilidades"
									class="bg-light"
								></tr>
								<tr
									mat-row
									*matRowDef="
										let row;
										columns: columnsDisponibilidades
									"
								></tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<button
				type="button"
				class="btn btn-default btn-sm"
				(click)="fechar()"
			>
				<i class="fas fa-times"></i> Fechar
			</button>
		</div>
	</div>
</div>
