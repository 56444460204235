import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EnumTipoQuestionarioUG } from 'src/app/models/enum/tipo-questionario-ug.enum';
import { EntendendoEntidadeService } from 'src/app/services/entendendo-entidade.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-modal-questionario-importa-dados',
  templateUrl: './modal-questionario-importa-dados.component.html',
  styleUrls: ['./modal-questionario-importa-dados.component.scss']
})
export class ModalQuestionarioImportaDadosComponent  implements OnInit {
  unidadeGestoraId: number;

  constructor(
    private router: Router,
    private questionarioUGService: EntendendoEntidadeService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<ModalQuestionarioImportaDadosComponent>
  ) { }

  ngOnInit(): void {
    this.unidadeGestoraId = this.sharedService.getId();
  }

  public Sim() {
    this.questionarioUGService
    .inserirQuestionarioAPartirDoUltimo(this.unidadeGestoraId, EnumTipoQuestionarioUG.EntendendoEntidade)
    .then((res) => {
      if (res.sucesso) {
        // aqui chamar o pagina principal
        this.dialogRef.close();
        this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=1`);
        window.location.reload();
      } else {
        // this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      // this.exibirMensagemErro(err.mensagem);
    })
    .finally(() => {
      //  this.buscar();
    });
  }

  public Nao() {
    this.dialogRef.close();
    this.router.navigate(['gerar-questionario']);
  }

  public cancelar() {
    this.dialogRef.close();
  }

}
