<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/tipo-universo']">Tipos de universo auditável</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>
          </div>
          <form [formGroup]="form" #f="ngForm"  (keydown.enter)="$event.preventDefault()">
            <div class=" card-body">
              <div class="row">
                <div class="form-group col-md-8">
                  <label for="nome" class="mb-1">Nome</label>
                  <input [attr.disabled]="visualizar ? true : null" type="text" id="nome" class="form-control form-control-sm" formControlName="nome"
                    maxlength="100" [ngClass]="{ 'is-invalid': f.submitted && form.controls['nome'].invalid }" />
                  <div *ngIf="f.submitted && form.controls['nome'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['nome'].errors.required">Campo obrigatório</div>
                  </div>
                </div>

              </div>

              <div class="row">
                <div class="form-group col-md-12">
                  <label for="inputDescricao" class="mb-1">Descrição</label>
                  <textarea [attr.disabled]="visualizar ? true : null" rows="3" id="inputDescricao" class="form-control form-control-sm"
                    formControlName="descricao" maxlength="500" [ngClass]="{ 'is-invalid': f.submitted &&
                    form.controls['descricao'].invalid }">
                  </textarea>
                  <div *ngIf="f.submitted && form.controls['descricao'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['descricao'].errors.required">Campo obrigatório</div>
                  </div>
                </div>
              </div>
            

            <div class="row">
              <div class="form-group col-md-6">
                <label
                for="selectClassificacao"
                class="mb-1"
                >Classificação do Tipo</label>
                <div
											class="input-group input-group-sm"
											style="width: 100%"
										>
                    <select
                    [attr.disabled]="
                      visualizar ? true : null
                    "
                    id="selectClassificacao"
                    [(ngModel)]="model.tipo"
                    [ngModelOptions]="{
                      standalone: true
                    }"
                    class="form-control form-control-sm"
                    [ngClass]="{ 'is-invalid': f.submitted && form.controls['tipo'].invalid }" 
                    [disabled]="visualizar"
                    (change)="onChangeSelectTipo($event)"
                  >
                    <option [value]="1">
                      Padronizado
                    </option>
                    <option [value]="2">
                      Não-padronizado
                    </option>
                  </select>
                  <div *ngIf="f.submitted && form.controls['tipo'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['tipo'].errors.required">Campo obrigatório</div>
                  </div>
                </div>
              </div>
              
              <div class="form-group col-md-6">
                <label for="inputIndicador" class="mb-1">Indicador</label>
                <div class="input-group input-group-sm" style="width: 100%;">
                    <input type="text" id="inputIndicador" class="form-control form-control-sm select-color"
                        readonly [ngModel]="model.indicadorNome"
                        [ngModelOptions]="{ standalone: true }" [ngClass]="{ 'is-invalid': f.submitted && form.controls['indicadorId'].invalid }"  />
                    <div class="input-group-append" *ngIf="!visualizar">
                        <button [hidden]="visualizar" type="button" class="btn btn-info"
                            (click)="exibirModalIndicador()">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <div *ngIf="f.submitted && form.controls['indicadorId'].invalid" class="invalid-feedback">
                      <div *ngIf="form.controls['indicadorId'].errors.required">Campo obrigatório</div>
                    </div>
                </div>
            </div>
          </div>

          <div class="row" [hidden]="novoRegistro">
            <div class="form-group col-md-12">
              <mat-card class="mat-card">
                
                <mat-toolbar class="table-header" [hidden]="idTipoClassificacao != 2">
                  <mat-toolbar-row>
                    <div class="bold">
                      Subtipo de Universo Auditável
                    </div>
                    <span class="fill-remaining-space"></span>
                    <button
                        [hidden]="visualizar"
                        type="button"
                        class="btn btn-primary btn-xs"
                        (click)="abrirModalUG(null, false)"
                      >
                        <i class="fas fa-plus"></i>
                        Adicionar 
                      </button>
                  </mat-toolbar-row>
                </mat-toolbar>
                <mat-toolbar class="table-header" [hidden]="idTipoClassificacao != 2">
                  <mat-toolbar-row>
                    Unidades Gestoras 
                  </mat-toolbar-row>
                </mat-toolbar>
                <table mat-table class="w-100" [dataSource]="ugsDataSource">
                  <tr  mat-header-row
                        *matHeaderRowDef="['nome',  'actions']"
                  ></tr>

                  <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" >
                       {{ idTipoClassificacao == 1 
                                        ? "Subtipo de Universo Auditável" 
                                        : element.sigla + " - " + element.nome }} 
                    </td>
                  </ng-container>
                  
                  <ng-container cdkColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td
                      mat-cell
                      *matCellDef="let element; let i = index"
                      class="block"
                    >
                    <button
                        type="button"
                        class="btn btn-info btn-sm mr-1"
                        (click)="abrirModalUG(element, true)"
                      >
                        <i class="far fas fa-eye"></i>
                      </button>
                      <button
                        [hidden]="visualizar"
                        type="button"
                        class="btn btn-info btn-sm mr-1"
                        (click)="abrirModalUG(element, false)"
                      >
                        <i class="far fas fa-pen"></i>
                      </button>
                      <button
                        [hidden]="visualizar"
                        type="button"
                        class="btn btn-danger btn-sm mr-1"
                        (click)="excluirUG(element)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </td>
                  </ng-container>

                <tr
                  mat-header-row
                  *matRowDef="
                    let row;
                    columns: [
                      'nome',
                      'actions'
                    ]
                  "
                  class="bg-light"
                ></tr>

                </table>
                <h5 [hidden]="!semDados || idTipoClassificacao != 2" class="table-no-data">
                  Sem dados a exibir
                </h5>
              </mat-card>
            </div>
          </div>

        </div>

            <div class="card-footer">
              <div class="btn-group" [hidden]="visualizar">
                <button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
                    <i class="far fa-save"></i> Salvar
                </button>
                <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                <div class="dropdown-menu" role="menu">
                <a class="dropdown-item" (click)="salvarDados(false)">Salvar</a>
                <a class="dropdown-item" (click)="salvarDados(true)">Salvar e fechar</a>
                </div>
            </div>

              <button [hidden]="visualizar" type="button" class="btn btn-default btn-sm" (click)="cancelar()">
                <i class="fas fa-times"></i> Cancelar
              </button>

              <button [hidden]="!visualizar" type="button" class="btn btn-default btn-sm" (click)="cancelar()">
                <i class="fas fa-times"></i> Sair
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
