import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-importacao',
  templateUrl: './modal-importacao.component.html',
  styleUrls: ['./modal-importacao.component.scss']
})
export class ModalImportacaoComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ModalImportacaoComponent>) { }
  public sigla: string;
  ngOnInit(): void {
  }

  public concluir() {
    this.dialogRef.close(this.sigla);
  }

  public cancelar() {
    this.dialogRef.close();
  }
}
