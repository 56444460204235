import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";
import { ModalCancelarComponent } from "src/app/shared/components/modal-cancelar/modal-cancelar-component";
import { ModalConfirmaCancelarComponent } from "src/app/shared/components/modal-confirma-cancelar/modal-confirma-cancelar.component";
import { RiscosFormComponent } from "src/app/pages/riscos-form/riscos-form.component";

@Injectable()
export class RiscosGuard implements CanDeactivate<RiscosFormComponent> {
	canDeactivate(
		component: RiscosFormComponent
	): Observable<boolean> | boolean {
		if (component.novoRegistro) {
			if (component.submit) {
				return true;
			}

			return component.matDialog.open(ModalCancelarComponent).afterClosed();

		} else if (!component.visualizar) {
			if (component.submit) { return true; }
			return component.matDialog.open(ModalConfirmaCancelarComponent).afterClosed();
		}
	}
}
