import { ModalCancelarComponent } from './../modal-cancelar/modal-cancelar-component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-justificativa',
  templateUrl: './modal-justificativa.component.html',
  styleUrls: ['./modal-justificativa.component.scss']
})
export class ModalJustificativaComponent implements OnInit {
  public submitted: boolean
  public justificativa: string;
  public prorrogacao: boolean = false

  public form = new FormGroup({
    justificativa: new FormControl('', Validators.required)
  });

  constructor(
    private dialogRef: MatDialogRef<ModalJustificativaComponent>,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.prorrogacao = data?.prorrogarPrazo
  }

  ngOnInit(): void {
  }

  public submit(){
    this.submitted = true
    if(!this.prorrogacao){
      if(this.form.controls['justificativa'].invalid){
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: 'Atenção',
          text: 'A justificativa é obrigatória',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        return;
      }
      this.justificativa = this.form.get('justificativa').value
      this.dialogRef.close(this.justificativa);
    }else if(this.prorrogacao){
      if(this.form.invalid){
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: 'Atenção',
          text: 'Formulário Inválido',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        return;
      }
      const dados = {
       justificativa: this.form.get('justificativa').value
      }
      this.dialogRef.close(dados);
    }

  }

  public cancelar() {
    this.dialogRef.close(false);
  }
}
