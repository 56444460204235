import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { QuestionarioFormComponent } from 'src/app/pages/questionario-form/questionario-form.component';

@Injectable()
export class QuestionarioGuard
  implements CanDeactivate<QuestionarioFormComponent> {
  canDeactivate(
    component: QuestionarioFormComponent
  ): Observable<boolean> | boolean {
    if (!component.visualizar) {
      if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    }
    else { return true; }
  }
}
