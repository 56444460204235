<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-12">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/']">
                            <i class="fas fa-home"></i>
                            Página Inicial</a>
                    </li>
                    <li class="breadcrumb-item active">OSA - Ordem de Serviço de Auditoria</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-lightblue">
                    <div class="card-header">
                        <h3 class="card-title mb-0">
                            <i class="fas fa-table pr-2"></i>
                            OSA - Ordem de Serviço de Auditoria
                        </h3>
                    </div>
                    <div class="card-body p-2">
                        <app-osa-list>
                            <app-filtro-component showButtonFilter="false"></app-filtro-component>
                        </app-osa-list>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>