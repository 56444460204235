import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { ICalendarioModel } from '../models/calendario.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarioService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<ICalendarioModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<ICalendarioModel[]>>(`${this.apiBaseUrl}/calendario`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<ICalendarioModel>> {
    return this.httpClient
      .get<IBaseModel<ICalendarioModel>>(`${this.apiBaseUrl}/calendario/${id}`)
      .toPromise();
  }

  public async inserir(data: ICalendarioModel): Promise<IBaseModel<ICalendarioModel>> {
    return this.httpClient
      .post<IBaseModel<ICalendarioModel>>(`${this.apiBaseUrl}/calendario`, data)
      .toPromise();
  }

  public async atualizar(data: ICalendarioModel): Promise<IBaseModel<ICalendarioModel>> {
    return this.httpClient
      .put<IBaseModel<ICalendarioModel>>(`${this.apiBaseUrl}/calendario/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/calendario/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/calendario/${id}/inativar`, {})
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/calendario/${id}/excluir`, {})
      .toPromise ();  
  }
}
