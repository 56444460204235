import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Inputmask from 'inputmask';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { toIntlNumberString } from 'src/app/core/helpers/number.helper';
import { IBaseModel } from 'src/app/models/base.model';
import { IFaixaClassificacaoModel } from 'src/app/models/faixa-classificacao';
import { FaixaClassificacaoService } from 'src/app/services/faixa-classificacao.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-faixa-classificacao-form',
  templateUrl: './faixa-classificacao-form.component.html',
  styleUrls: ['./faixa-classificacao-form.component.scss']
})
export class FaixaClassificacaoFormComponent extends BaseFormComponent implements OnInit {
  public model: IFaixaClassificacaoModel = {} as IFaixaClassificacaoModel;
  public salvarEFechar: boolean = false;

  public form = new FormGroup({
    pisoBaixo: new FormControl(null, Validators.required),
    tetoBaixo: new FormControl(null, Validators.required),
    pisoMedio: new FormControl(null, Validators.required),
    tetoMedio: new FormControl(null, Validators.required),
    pisoAlto: new FormControl(null, Validators.required),
    tetoAlto: new FormControl(null, Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private faixaService: FaixaClassificacaoService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    this.titulo = 'Editar Faixa de Classificação';
  }

  async ngOnInit() {

    Inputmask().mask(document.querySelectorAll('input'));

    try {
      const res = await this.faixaService.obter();
      if (res.sucesso) {
        this.model = res.dados;
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
        return;
      }

      this.form.controls['pisoBaixo'].setValue(this.formatarNumero(this.model.pisoBaixo));
      this.form.controls['tetoBaixo'].setValue(this.formatarNumero(this.model.tetoBaixo));
      this.form.controls['pisoMedio'].setValue(this.formatarNumero(this.model.pisoMedio));
      this.form.controls['tetoMedio'].setValue(this.formatarNumero(this.model.tetoMedio));
      this.form.controls['pisoAlto'].setValue(this.formatarNumero(this.model.pisoAlto));
      this.form.controls['tetoAlto'].setValue(this.formatarNumero(this.model.tetoAlto));

    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
      this.router.navigate(['/']);
    } 
  }

  private formatarNumero(n: number) {
    return n.toFixed(4).toString().replace('.', ',');
  }

  public async onSubmitClose(){
    this.salvarEFechar = true;
    this.onSubmit();
  }

  public async onSubmit() {

    this.validarFormulario();

    if (this.form.invalid) {
      
      this.exibirMensagemAlerta("Formulário invalido");

      return;
    }

    try {
      let res: IBaseModel<void> = null;

      this.model.pisoBaixo = parseFloat(toIntlNumberString(this.form.controls['pisoBaixo'].value));
      this.model.tetoBaixo = parseFloat(toIntlNumberString(this.form.controls['tetoBaixo'].value));
      this.model.pisoMedio = parseFloat(toIntlNumberString(this.form.controls['pisoMedio'].value));
      this.model.tetoMedio = parseFloat(toIntlNumberString(this.form.controls['tetoMedio'].value));
      this.model.pisoAlto = parseFloat(toIntlNumberString(this.form.controls['pisoAlto'].value));
      this.model.tetoAlto = parseFloat(toIntlNumberString(this.form.controls['tetoAlto'].value));

      res = await this.faixaService.atualizar(this.model);

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        if (this.salvarEFechar == true) {
          this.router.navigate(['/']);
        }
      } else {
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(validacoes);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
    this.salvarEFechar = false;
  }

  public calcular(field) {
    if (!this.form.controls['pisoBaixo'].value) {
      this.form.controls['pisoBaixo'].setValue('0,0000');
    }
    if (!this.form.controls['tetoBaixo'].value) {
      this.form.controls['tetoBaixo'].setValue('0,0000');
    }
    if (!this.form.controls['pisoMedio'].value) {
      this.form.controls['pisoMedio'].setValue('0,0000');
    }
    if (!this.form.controls['tetoMedio'].value) {
      this.form.controls['tetoMedio'].setValue('0,0000');
    }
    if (!this.form.controls['pisoAlto'].value) {
      this.form.controls['pisoAlto'].setValue('0,0000');
    }
    if (!this.form.controls['tetoAlto'].value) {
      this.form.controls['tetoAlto'].setValue('0,0000');
    }

    this.model.pisoBaixo = parseFloat(toIntlNumberString(this.form.controls['pisoBaixo'].value) || '0,0000');
    this.model.tetoBaixo = parseFloat(toIntlNumberString(this.form.controls['tetoBaixo'].value) || '0,0000');
    this.model.pisoMedio = parseFloat(toIntlNumberString(this.form.controls['pisoMedio'].value) || '0,0000');
    this.model.tetoMedio = parseFloat(toIntlNumberString(this.form.controls['tetoMedio'].value) || '0,0000');
    this.model.pisoAlto = parseFloat(toIntlNumberString(this.form.controls['pisoAlto'].value) || '0,0000');
    this.model.tetoAlto = parseFloat(toIntlNumberString(this.form.controls['tetoAlto'].value) || '0,0000');

    this.validarFormulario();

  }

  public validarFormulario() {

    if (this.model.pisoMedio >= this.model.tetoMedio) {
      this.form.controls['pisoMedio'].setErrors({ invalid: true });
    } else {
      this.form.controls['pisoMedio'].setErrors(null);
    }

    if (this.model.tetoBaixo >= this.model.pisoMedio) {
      this.form.controls['tetoBaixo'].setErrors({ invalid: true });
    } else {
      this.form.controls['tetoBaixo'].setErrors(null);
    }

    if (this.model.pisoBaixo >= this.model.tetoBaixo) {
      this.form.controls['pisoBaixo'].setErrors({ invalid: true });
    } else {
      this.form.controls['pisoBaixo'].setErrors(null);
    }

    if (this.model.tetoMedio >= this.model.pisoAlto) {
      this.form.controls['tetoMedio'].setErrors({ invalid: true });
    } else {
      this.form.controls['tetoMedio'].setErrors(null);
    }

    if (this.model.pisoAlto >= this.model.tetoAlto) {
      this.form.controls['pisoAlto'].setErrors({ invalid: true });
    } else {
      this.form.controls['pisoAlto'].setErrors(null);
    }

    if (this.model.tetoAlto > 10) {
      this.form.controls['tetoAlto'].setErrors({ invalid: true });
    } else {
      this.form.controls['tetoAlto'].setErrors(null);
    }
  }

  public onBack() {
    this.router.navigate(['/']);
  }

}
