import { SolicitacaoTecnicaService } from "./../../../../services/solicitacao-tecnica.service";
import { IArquivoModel } from "./../../../../models/arquivo.model";
import { IArquivoRespostaModel, IItemSolicitacaoTecnicaModel, IRepostaItemModel } from "./../../../../models/item-solicitacao-tecnica.model";
import {
	Component,
	OnInit,
	ViewChild,
	AfterViewInit,
	Inject,
} from "@angular/core";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import Swal from "sweetalert2";

@Component({
	selector: "app-modal-visualizar-item",
	templateUrl: "./modal-visualizar-item.component.html",
	styleUrls: ["./modal-visualizar-item.component.scss"],
})
export class ModalVisualizarItemComponent implements OnInit, AfterViewInit {
	public dataSource = new MatTableDataSource<IArquivoModel>([]);
	public dataSourceResposta = new MatTableDataSource<IArquivoRespostaModel>([]);
	public semDados: boolean = false;
	public semDadosResposta: boolean = false;
	public model: IItemSolicitacaoTecnicaModel =
		{} as IItemSolicitacaoTecnicaModel;
	public solicitacaoId: number;
	public numeroSolicitacao: any;
  public resposta: IItemSolicitacaoTecnicaModel = {} as IItemSolicitacaoTecnicaModel;

	constructor(
		public matDialog: MatDialog,
		private dialogRef: MatDialogRef<ModalVisualizarItemComponent>,
		public solicitacaoService: SolicitacaoTecnicaService,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.model.id = data?.id;
		this.solicitacaoId = data?.solicitacaoTecnicaId;
		this.numeroSolicitacao = data?.numeroSolicitacao;
	}

	@ViewChild("tabelaItemPaginator", { static: true })
	tabelaItemPaginator: MatPaginator;
	@ViewChild("tabelaItemSort", { static: true }) tabelaItemSort: MatSort;

	@ViewChild("tabelaRespostaPaginator", { static: true })
	tabelaRespostaPaginator: MatPaginator;
	@ViewChild("tabelaRespostaSort", { static: true })
	tabelaRespostaSort: MatSort;

	ngOnInit(): void {
		this.obterPorId();

    if(this.dataSource.data?.length > 0) {
      this.dataSource.paginator = this.tabelaItemPaginator;
      this.dataSource.sort = this.tabelaItemSort;
      this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    }

    if(this.dataSourceResposta.data?.length > 0) {
      this.dataSourceResposta.sortingDataAccessor = this.sortingDataAccessor;
      this.dataSourceResposta.sort = this.tabelaRespostaSort;
      this.dataSourceResposta.paginator = this.tabelaRespostaPaginator;
    }
	}

	ngAfterViewInit(): void {}

	public obterPorId() {
		this.solicitacaoService
			.obterItemId(this.solicitacaoId, this.model?.id)
			.then((res) => {
				if (res.sucesso) {
					this.model = res.dados;
					this.dataSource.data = res?.dados?.anexo;
          if(this.dataSource.data?.length > 0) {
            this.dataSource.paginator = this.tabelaItemPaginator;
            this.dataSource.sort = this.tabelaItemSort;
            this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
          }
          this.obterResposta()
				} else {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "warning",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
				}
			});
	}

  public obterResposta() {
    this.solicitacaoService.obterRespostaItem(this.solicitacaoId, this.model?.id).then((res) => {
      if (res.sucesso) {
        this.resposta.respostaItemSolicitacaoTecnica = res?.dados?.respostaItemSolicitacaoTecnica
        this.dataSourceResposta.data = res?.dados?.respostaItemSolicitacaoTecnica?.anexos
        if( this.dataSourceResposta.data?.length > 0) {
          this.dataSourceResposta.sortingDataAccessor = this.sortingDataAccessor;
          this.dataSourceResposta.sort = this.tabelaRespostaSort;
          this.dataSourceResposta.paginator = this.tabelaRespostaPaginator;
        }
        }
  })}

	public Download(arquivo: IArquivoModel) {
    this.solicitacaoService.downloadAnexo(this.model.id, arquivo.id).then((res) =>{
      if (res.sucesso) {
        const link = document.createElement("a");
        link.href = res.dados.toString();
        link.download = arquivo.nomeArquivo;
        link.click();
      } else {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }})
	}

  public DownloadResposta(arquivo: IArquivoRespostaModel) {
    this.solicitacaoService.downloadArquivoRespostaItem(arquivo).then((res) =>{
      if (res.sucesso) {
        const link = document.createElement("a");
        link.href = res.dados.toString();
        link.download = arquivo.nomeArquivo;
        link.click();
      } else {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }})
	}

	public sortingDataAccessor = (item, property) => {
		switch (property) {
			case "arquivo":
				return item.nomeArquivo;
			default:
				return item[property];
		}
	};

	public fechar() {
		this.dialogRef.close();
	}
}
