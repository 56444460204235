import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import Inputmask from 'inputmask';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { toIntlNumberString } from 'src/app/core/helpers/number.helper';
import { IPeaCriterioQuestionarioModel } from 'src/app/models/pea-criterio-questionario.model';
import { IPerguntaModel } from 'src/app/models/pergunta.model';
import { IBaseModel } from 'src/app/models/base.model';
import { IQuestionarioModel } from 'src/app/models/questionario.model';
import { QuestionarioService } from 'src/app/services/questionario.service';
import Swal from 'sweetalert2';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { ModalDelegarQuestionarioComponent } from './modal-delegar/modal-delegar-component';
import { PeaService } from 'src/app/services/pea.service';
import { ResponsaveisService } from 'src/app/services/responsaveis.service ';
import moment, { Moment } from 'moment';
import { IQuestionarioPaaModel } from 'src/app/models/questionario.paa.model';
import { IPaaCriterioQuestionarioModel } from 'src/app/models/paa-criterio-questionario.model';

@Component({
  selector: 'app-questionario-form',
  templateUrl: './questionario-form.component.html',
  styleUrls: ['./questionario-form.component.scss'],
})
export class QuestionarioFormComponent extends BaseFormComponent
  implements OnInit {
  public model: any = {} as any;
  public pergunta: IPerguntaModel;
  public dataSource = new MatTableDataSource<any>([]);
  private segmentoUrl: string;
  public documento: string;
  public expirado: boolean;
  public isPea: boolean;

  public displayedColumns: string[] = [
    'selecao',
    'pergunta',
    'tipo',
    'resposta',
    'unidadeMedida',
    'delegadoA',
  ];

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private questionarioService: QuestionarioService,
    private peaService: PeaService,
    private responsaveisService: ResponsaveisService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    this.titulo = 'Responder Questionário';
    this.segmentoUrl = this.recuperarSegmento();
    this.documento = this.segmentoUrl.toUpperCase();

    this.criterioId = parseInt(this.route.snapshot.paramMap.get('criterioId'), 10) as number;
    this.unidadeGestoraId = parseInt(this.route.snapshot.paramMap.get('unidadeGestoraId'), 10) as number;
    this.expirado = false;
  }

  private criterioId = null;
  private unidadeGestoraId = null;

  async ngOnInit() {
    Inputmask().mask(document.querySelectorAll('input'));
    this.obterPerguntasQuestionario();
  }

  obterPerguntasQuestionario() {
    switch (this.segmentoUrl) {
      case 'pea':
        this.obterQuestionarioPendentePorPeaId();
        this.isPea = true;
        break;
      case 'paa':
        this.obterQuestionarioPendentePorPaaId();
        break;
      default:
        this.obterQuestionarioPendentePorPeaId();
        break;
    }
  }

  public async onSalvar() {

    try {
      for (const item of this.dataSource.data) {
        if (item.valorNumerico) {
          item.valorNumerico = parseFloat(
            toIntlNumberString(item.valorNumerico.toString())
          );
        }
      }

      switch (this.segmentoUrl) {
        case 'pea':
          await this.salvarQuestionarioPea();
          break;
        case 'paa':
          await this.salvarQuestionarioPaa();
          break;
        default:
          await this.salvarQuestionarioPea();
          break;
      }

    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public async onEnviar() {
    let naoRespondidos = this.dataSource.data.filter(
      (d) =>
        d.criterio.pergunta.tipoEntrada.nome === 'MultiplaEscolha' &&
        !d.perguntaOpcaoId
    ).length;
    naoRespondidos += this.dataSource.data.filter(
      (d) =>
        d.criterio.pergunta.tipoEntrada.nome !== 'MultiplaEscolha' &&
        !d.valorNumerico
    ).length;

    if (naoRespondidos > 0) {
      this.exibirMensagemAlerta('Existem perguntas não respondidas. Por favor, revise todas as respostas antes de enviar!');
      return;
    }

    Swal.fire({
      title: 'Responder Questionário',
      text:
        'Deseja enviar as respostas desse questionário? Não será possível editar as respostas após o envio.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
    }).then(async (result) => {
      if (result.value) {

        try {
          switch (this.segmentoUrl) {
            case 'pea':
              await this.enviarQuestionarioPea();
              break;
            case 'paa':
              await this.enviarQuestionarioPaa();
              break;
            default:
              await this.enviarQuestionarioPea();
              break;
          }
        } catch (err) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: err.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      }
    });
  }

  public onCancelar() {
    this.navegarLista();
  }

  private obterQuestionarioPendentePorPeaId() {
    this.tratarObterQuestionarioPendente(this.questionarioService
      .obterQuestionarioPendentePorPeaId(this.id, this.criterioId, this.unidadeGestoraId));
  }

  private obterQuestionarioPendentePorPaaId() {
    this.tratarObterQuestionarioPaaPendente(this.questionarioService
      .obterQuestionarioPendentePorPaaId(this.id, this.criterioId, this.unidadeGestoraId));
  }

  private tratarObterQuestionarioPendente(obter: Promise<IBaseModel<any>>) {
    obter.then((res) => {
      if (res.sucesso) {
        let hoje = moment(new Date());
        let data = moment(res.dados.dataPrazo, "DD-MM-YYYY");
        if (data.diff(hoje) <= 0) {
          this.expirado = true;
        }
        else {
          this.expirado = false;
        }
        this.model = res.dados;
        this.dataSource = new MatTableDataSource<IPeaCriterioQuestionarioModel>(res.dados.questionarios);
        setTimeout(() => Inputmask().mask(document.querySelectorAll('input')), 500);
      } else {
        this.toastr.error(res.mensagem.descricao, 'Atenção');
        this.navegarLista();
        return;
      }
    })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        this.navegarLista();
      })
  }
  private tratarObterQuestionarioPaaPendente(obter: Promise<IBaseModel<any>>) {
    obter.then((res) => {
      if (res.sucesso) {
        let hoje = moment(new Date());
        let data = moment(res.dados.dataPrazo, "DD-MM-YYYY");
        if (data.diff(hoje) <= 0) {
          this.expirado = true;
        }
        else {
          this.expirado = false;
        }
        this.model = res.dados;
        this.dataSource = new MatTableDataSource<IPaaCriterioQuestionarioModel>(res.dados.questionarios);
        setTimeout(() => Inputmask().mask(document.querySelectorAll('input')), 500);
      } else {
        this.toastr.error(res.mensagem.descricao, 'Atenção');
        this.navegarLista();
        return;
      }
    })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        this.navegarLista();
      })
  }

  private async salvarQuestionarioPea() {
    this.dataSource.data.forEach(element => {
      if (element.valorNumerico == null || element.valorNumerico == undefined) {
        element.valorNumerico = 0;
      }
    });
    const res = await this.questionarioService.salvarRespostasQuestionarioPorPeaId(this.id, this.dataSource.data);
    this.tratarSalvarQuestionario(res);
  }

  private async salvarQuestionarioPaa() {
    const res = await this.questionarioService.salvarRespostasQuestionarioPorPaaId(this.id, this.dataSource.data);
    this.tratarSalvarQuestionario(res);
  }

  private tratarSalvarQuestionario(res: IBaseModel<void>) {
    if (res.sucesso) {
      this.submit = true;
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'success',
        text: res.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.navegarLista();
    } else {
      this.submit = false;
      const validacoes = this.montarMensagensValidacao(res);
      this.exibirMensagemAlerta(res.mensagem.descricao);
    }
  }

  private async enviarQuestionarioPea() {
    const res = await this.questionarioService.enviarRespostasQuestionarioPorPeaId(this.id, this.dataSource.data);
    this.tratarEnviarQuestionario(res);
  }

  private async enviarQuestionarioPaa() {
    const res = await this.questionarioService.enviarRespostasQuestionarioPorPaaId(this.id, this.dataSource.data);
    this.tratarEnviarQuestionario(res);
  }

  private tratarEnviarQuestionario(res: IBaseModel<void>) {
    if (res.sucesso) {
      this.submit = true;
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'success',
        text: 'Respostas enviadas com sucesso.',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.navegarLista();
    } else {
      this.submit = false;
      const validacoes = this.montarMensagensValidacao(res);
      this.exibirMensagemAlerta(res.mensagem.descricao);
    }
  }

  private navegarLista() {
    this.router.navigate([`/questionario/${this.segmentoUrl}`]);
  }

  private recuperarSegmento() {
    let segmento = '';
    if (this.router.url.indexOf('/questionario/pea') == 0) {
      segmento = 'pea';
    }
    else if (this.router.url.indexOf('/questionario/paa') == 0) {
      segmento = 'paa';
    }
    if (this.visualizar) {
      this.route.url.subscribe(val => segmento = val[val.length - 6].path);
    }
    else {
      this.route.url.subscribe(val => segmento = val[val.length - 5].path);
    }

    return segmento;
  }

  async modalDelegar() {
    const res = await this.responsaveisService.obter(
      this.model.questionarios[0].unidadeGestoraId, '','','', true
    );

    if (res.sucesso) {
      try {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component';
        dialogConfig.width = '500px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          peaId: this.id,
          criterioId: this.criterioId,
          unidadeGestoraId: this.unidadeGestoraId,
          dados: res.dados,
        };

        const modal = this.matDialog.open(
          ModalDelegarQuestionarioComponent,
          dialogConfig
        );

        modal.afterClosed().subscribe(async () => {
          this.submit = true;
          this.navegarLista();
        });
      } catch (err) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    }
  }

  retornaNomeTipoTrabalho(): string{
    return this.isPea ? "PEAC" : "PAAC";
  }
}
