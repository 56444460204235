import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ICriterioModel } from 'src/app/models/criterio.model';

@Component({
  selector: 'app-modal-visualizar-respostas',
  templateUrl: './modal-visualizar-respostas.component.html',
  styleUrls: ['./modal-visualizar-respostas.component.scss']
})
export class ModalVisualizarRespostasComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  public criterio = {} as ICriterioModel;
  public displayedColumns = ['ug', 'valor'];
  public semDados = true;

  constructor(
    private dialogRef: MatDialogRef<ModalVisualizarRespostasComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.dataSource = new MatTableDataSource<any>(data);
    this.criterio = data.find((_: any) => _).criterio;
  }

  ngOnInit(): void {
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public fechar() {
    this.dialogRef.close();
  }
}

