<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i> Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item active">
						Importacao Questionário
					</li>
				</ol>
			</div>
		</div>
	</div>
</div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i class="fas fa-table pr-2"></i>
              Entendendo a Entidade
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
                  <div class="form-group d-flex align-items-center mb-0 w-100">
                   <div class="row">
                    <div class="form-group col-md-4">
                    <label for="fileInput" class="mr-2 font-weight-bold">Arquivo</label>
                    </div>
                    <div class="form-group col-md-12">
                      <input type="text" [value]="arquivo?.name || ''" readonly class="form-control nome-arquivo mr-2 flex-grow-1"/>
                      <input type="file" id="fileInput" (change)="onFileSelected($event)" class="form-control-file selecionar-arquivo mr-2"/>
                    </div>
                    <button type="button" class="btn btn-success btn-sm" (click)="enviarArquivo()">
                      <i class="far fa-file"></i> Enviar
                    </button>
                  </div>
                  </div>
                    <div class="form-group d-flex align-items-center mb-0 w-100">
                    <a [href]="arquivoUrl" download="{{ nomeArquivo }}" class="link-download">
                      <i class="fa fa-paperclip"></i> {{ nomeArquivo }}
                    </a>
                    </div>
      </div>               


      
    
  </div>
  <div class="card-footer">
    <button [hidden]="visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
      <i class="fas fa-times"></i> Fechar
    </button> 
    <button
    type="button"
    class="btn btn-primary btn-sm mr-1"
    (click)="onEncerrarAvaliacao()"
    [disabled]="!botaoEncerrarHabilitado">
    <i class=""></i> Encerrar Avaliação
    </button>
  </div>
