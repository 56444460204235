import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { EnumStatusAvaliacaoQuestionarioUG } from 'src/app/models/enum/status-avaliacao-questionario-ug.enum';
import { EnumTipoAvaliacaoQuestionarioUG } from 'src/app/models/enum/tipo-avaliacao-questionario-ug.enum';
import { AvaliacaoRequest } from 'src/app/models/questionario-ug.model';
import { QuestionarioUGRespostaService } from 'src/app/services/questionarioug-resposta.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EnumTipoQuestionarioUG } from 'src/app/models/enum/tipo-questionario-ug.enum';
import { IPerguntaOpcaoModel } from 'src/app/models/pergunta-opcao.model';

@Component({
  selector: 'app-modal-avaliar-questionario',
  templateUrl: './modal-avaliar-questionario.component.html',
  styleUrls: ['./modal-avaliar-questionario.component.scss']
})
export class ModalAvaliarQuestionarioComponent extends BaseFormComponent implements OnInit {
  form: FormGroup;  // Declarando o FormGroup
  opcoesAvaliacao = [
    { value: EnumStatusAvaliacaoQuestionarioUG.concordo, label: 'Concordo' },
    { value: EnumStatusAvaliacaoQuestionarioUG.concordoParcialmente, label: 'Concordo Parcialmente' },
    { value: EnumStatusAvaliacaoQuestionarioUG.discordo, label: 'Discordo' }
  ];
  avaliacao: AvaliacaoRequest;
  reavaliar: boolean;
  tipoAvaliacao: number;
  htmlResposta: SafeHtml | undefined;
  titulo: string = "";
  perguntaOpcao: any;
  
  constructor(
    public route: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    public localeService: BsLocaleService,
    private tabelaDominioService: TabelaDominioService,
    private dialogRef: MatDialogRef<ModalAvaliarQuestionarioComponent>,
    public matDialog: MatDialog,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private questionarioUGRespostaService: QuestionarioUGRespostaService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { 
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {
    this.reavaliar = this.data.reavaliar;
    this.form = this.fb.group({
      pergunta: [this.data.pergunta],
      resposta: [this.data.resposta],
      tipoEntrada: [this.data.tipoEntrada],
      respostaRevisada: [''] ,  
      statusAvaliacao: [null]
    });
    this.perguntaOpcao = this.data.perguntaOpcao;
    console.log(this.data.perguntaOpcao);
    const respostaHTML = this.data.resposta;
    
    // Atualize o controle do formulário
    this.form.get('resposta')?.setValue(respostaHTML);

    // Sanitizar o HTML para exibição segura
    this.htmlResposta = this.sanitizer.bypassSecurityTrustHtml(respostaHTML);

    if(this.data.tipoQuestionario == EnumTipoQuestionarioUG.EntendendoEntidade){
      this.titulo = "Entendendo a Entidade";
    }else if (this.data.tipoQuestionario == EnumTipoQuestionarioUG.MaturidadeRisco){
      this.titulo = "Maturidade da Gestão de Risco";
    }

    if(this.reavaliar){
      this.titulo += " - Reavaliar Resposta";
    }else{
      this.titulo += " - Avaliar Resposta";
    }

  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      // ['bold']
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  
  cancelar(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { interno: true };
    this.matDialog.open(ModalCancelarComponent, dialogConfig).afterClosed();

  }
  salvar(){
    console.log(this.form.value.texto);

    if(this.reavaliar)
    {
      this.tipoAvaliacao = EnumTipoAvaliacaoQuestionarioUG.avaliacaoFinal;
    }
    else
    {
      this.tipoAvaliacao = EnumTipoAvaliacaoQuestionarioUG.avaliacao;
    }

    const idsSelecionadosString: string = [this.data.ids].join(',');
    this.avaliacao = {
      statusAvaliacao: this.form.value.statusAvaliacao,
      texto: this.form.value.respostaRevisada,
      tipoAvaliacao: this.tipoAvaliacao
    };

    this.questionarioUGRespostaService.fazerAvaliacaoOuReplica(idsSelecionadosString, this.avaliacao)
    .then((res) => {
      if (res.sucesso) {
        this.dialogRef.close();
        this.exibirMensagemSucesso('Avaliacao Realizada com Sucesso');
      }
      else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })

  }
  


}
