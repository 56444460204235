import { Component, OnInit, ChangeDetectorRef  } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ToastrService } from "ngx-toastr";
import { IBaseModel } from "src/app/models/base.model";
import { IPerguntaMetricaModel } from "src/app/models/pergunta-metrica.model";
import { IEnumModel } from "src/app/models/enum.model";
import { IPerguntaOpcaoModel } from "src/app/models/pergunta-opcao.model";
import { IPerguntaModel } from "src/app/models/pergunta.model";
import { PerguntaService } from "src/app/services/pergunta.service";
import { TabelaDominioService } from "src/app/services/tabela-dominio.service";
import Swal from "sweetalert2";
import { BaseFormComponent } from "../../shared/components/base-form/base-form.component";
import { ModalMetricaPerguntaComponent } from "./modal-metrica-pergunta/modal-metrica-pergunta.component";

import {
	CdkDragDrop,
	moveItemInArray,
	transferArrayItem,
} from "@angular/cdk/drag-drop";
import { ModalExcluirMetricaComponent } from "./modal-metrica-pergunta/modal-excluir-metrica/modal-excluir-metrica.component";

@Component({
	selector: "app-pergunta-form",
	templateUrl: "./pergunta-form.component.html",
	styleUrls: ["./pergunta-form.component.scss"],
})
export class PerguntaFormComponent extends BaseFormComponent implements OnInit {
	public enumTipoEntrada = [] as IEnumModel[];
	public enumTipoComparacao = [] as IEnumModel[];

	public model: IPerguntaModel = {} as IPerguntaModel;
	public metricasDataSource = new MatTableDataSource<IPerguntaMetricaModel>(
		[]
	);
	public opcoesDataSource = [] as IPerguntaOpcaoModel[];

	public semDados = true;
	public semDadosMetrica = true;
	public permiteCadastroOpcoes = false;

	public form = new FormGroup({
		id: new FormControl({ value: "", disabled: true }),
		nome: new FormControl("", Validators.required),
		descricao: new FormControl("", Validators.required),
		tipoEntradaId: new FormControl(null, Validators.required),
		novaOpcao: new FormControl(""),
		subPergunta: new FormControl(""),
	});

	constructor(
		route: ActivatedRoute,
		toastr: ToastrService,
		router: Router,
		localeService: BsLocaleService,
		private perguntaService: PerguntaService,
		private tabelaDominioService: TabelaDominioService,
		public matDialog: MatDialog,
		private dialog: MatDialog,
		private cdr: ChangeDetectorRef
	) {
		super(route, toastr, router, localeService, matDialog);

		if (this.novoRegistro) {
			this.titulo = "Nova Pergunta";
		}
	}

	onDrop(event: CdkDragDrop<string[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				this.opcoesDataSource,
				event.previousIndex,
				event.currentIndex
			);
		}
	}

	async ngOnInit() {
		try {
			if (!this.novoRegistro) {
				const res = await this.perguntaService.obterPorId(this.id);
				if (res.sucesso) {
					this.model = res.dados;

					if (this.visualizar) {
						this.titulo =
							"Visualizar Pergunta - " + this.model.nome;
					} else {
						this.titulo = "Editar Pergunta - " + this.model.nome;
					}
					this.metricasDataSource =
						new MatTableDataSource<IPerguntaMetricaModel>(
							this.model.perguntaMetrica.sort((a, b) =>
								a.ordem > b.ordem ? 1 : -1
							)
						);

					this.metricasDataSource._updateChangeSubscription();
					this.semDados =
						this.metricasDataSource.filteredData.length === 0;
					this.semDadosMetrica = this.model.perguntaMetrica.length ? false : true;
					this.opcoesDataSource = this.model.perguntaOpcao.sort(
						(a, b) =>
							a["ordem"]
								.toString()
								.localeCompare(b["ordem"].toString())
					);
					this.semDados = this.opcoesDataSource.length === 0;
					this.form.patchValue(this.model);
				} else {
					this.exibirMensagemErro(res.mensagem.descricao);
					this.router.navigate(["/perguntas"]);
					return;
				}
			}

			await Promise.all([
				this.tabelaDominioService
					.obterTipoComparacao()
					.then((res) => (this.enumTipoComparacao = res.dados)),
			]);

			// Tabelas de domínio
			await this.tabelaDominioService
				.obterTipoEntradaPergunta()
				.then((res) => (this.enumTipoEntrada = res.dados));
			this.permiteCadastroOpcoes = this.model.tipoEntradaId === 3;
			this.form.patchValue(this.model);
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
			this.router.navigate(["/perguntas"]);
		}
		this.form.patchValue(this.model);
	}

	public cancelar() {
		this.submit = false;
		this.router.navigate(["/perguntas"]);
	}

	public onChangeTipoEntrada(event: any) {
		this.permiteCadastroOpcoes = event.target.value === "3";
		this.model.tipoEntradaId = parseInt(event.target.value, 10);
	}

	public async salvarDados(salvarEFechar: boolean) {
		this.submit = true;
		if (this.form.invalid) {
			Swal.fire({
				toast: true,
				position: "top-end",
				icon: "warning",
				text: "Formulário Inválido",
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			});
			return;
		}

		Object.assign(this.model, this.form.value);
		this.model.perguntaMetrica = this.metricasDataSource.data;
		this.atualizarModel(this.form.value);

		this.model.perguntaOpcao = [];
		if (this.model.tipoEntradaId.toString() === "3") {
			this.model.perguntaOpcao = this.opcoesDataSource.map((_, i) => ({
				..._,
				ordem: i,
			}));
		}
		if (this.model.tipoEntradaId.toString() !== "3"){
			this.model.perguntaOpcao = [];
			this.opcoesDataSource = [];
		}

		try {
			let res: IBaseModel<IPerguntaModel>;

			if (!this.novoRegistro) {
				res = await this.perguntaService.atualizar(this.model);
			} else {
				res = await this.perguntaService.inserir(this.model);
			}

			if (res.sucesso) {
				if (res.mensagem.nome === "PerguntaEmUsoAlteradaNovasOpcoes") {
					Swal.fire({
						title: "A pergunta foi alterada com sucesso!",
						html:
							"O(s) Critério(s) abaixo precisa(m) ser alterado(s) com métricas para a(s) nova(s) resposta(s): <br>" +
							this.montarMensagensValidacao(res),
						icon: "success",
					}).then(() => {
						Swal.fire({
							toast: true,
							position: "top-end",
							icon: "success",
							text: res.mensagem.descricao,
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
						this.router.navigate(["/perguntas"]);
					});
				} else {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "success",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});

					if (salvarEFechar) {
						this.router.navigate(["/perguntas"]);
					} else if (this.novoRegistro) {
						this.router.navigate([`/perguntas/${res.dados.id}`]);
					}
				}
			} else {
				const validacoes = this.montarMensagensValidacao(res);
				Swal.fire({
					toast: true,
					position: "top-end",
					icon: "warning",
					text: `${res.mensagem.descricao}${validacoes}`,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public async onSubmit() {
		this.salvarDados(false);
	}

	public incluirNovaOpcao() {
		const novaOpcao = this.form.controls["novaOpcao"].value;
		if (novaOpcao && novaOpcao.length > 0) {
			this.opcoesDataSource.push({
				nome: novaOpcao,
			} as IPerguntaOpcaoModel);
			this.semDados = false;

			this.form.controls["novaOpcao"].setValue("");
		}
	}

	public excluirOpcao(ind: number) {
		const index = this.opcoesDataSource.findIndex(item => item.id === ind);
		if (index !== -1) {
			this.opcoesDataSource.splice(index, 1);
		}
	}

	private atualizarModel(values: any) {
		Object.assign(this.model, values);
	}

	public onBack() {
		this.submit = false;
		this.router.navigate(["/perguntas"]);
	}
	public excluirMetrica(i: number) {
		const dialogRef = this.dialog.open(ModalExcluirMetricaComponent, {
			width: "650px",
			data: { message: "Confirmação de Exclusão" },
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.metricasDataSource.data.splice(i, 1);
				this.metricasDataSource._updateChangeSubscription();
				this.semDados =
					this.metricasDataSource.filteredData.length === 0;
			}
		});
	}

	public async exibirModalMetrica(
		element?: IPerguntaMetricaModel,
		index?: number
	) {
		if (!this.model.tipoEntradaId) {
			this.exibirMensagemAlerta(
				"É necessário selecionar o Tipo de Entrada antes de definir as métricas"
			);
			return;
		}

		let modal: any;
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component";
		dialogConfig.width = "650px";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;

		if (
			this.model.tipoEntradaId === 1 ||
			this.model.tipoEntradaId === 2 ||
			this.model.tipoEntradaId === 3
		) {
			let defineEntrada: any;

			switch (this.model.tipoEntradaId) {
				case 1:
					defineEntrada = "inteiro";
					break;

				case 2:
					defineEntrada = "decimal";
					break;

				case 3:
					defineEntrada = "multiplaEscolha";
					break;

				default:
					defineEntrada = "inteiro";
			}

			const data = {
				tipoEntrada: defineEntrada,
				enumTipoComparacao: this.enumTipoComparacao,
				element,
			};

			dialogConfig.data = data;
			modal = this.matDialog.open(
				ModalMetricaPerguntaComponent,
				dialogConfig
			);
		} else {
			this.exibirMensagemAlerta(
				"As subperguntas só podem ser atribuídas as perguntas do tipo decimal, numérica e multipla escolha."
			);
		}

		modal.afterClosed().subscribe((data) => {
			if (data) {
				if (element) {
					this.metricasDataSource.data.splice(index, 1);
				}
				this.metricasDataSource.data.push(data);
				this.metricasDataSource._updateChangeSubscription();
				this.semDadosMetrica = false;
			}
		});
		this.cdr.detectChanges();
	}

	public drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(
			this.metricasDataSource.data,
			event.previousIndex,
			event.currentIndex
		);
		for (let i = 0; i < this.metricasDataSource.data.length; i++) {
			this.metricasDataSource.data[i].ordem = i + 1;
		}

		this.metricasDataSource._updateChangeSubscription();
	}
}
