<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i>
							Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item">
						<a [routerLink]="['/fluxos-aprovacao']"
							>Fluxos de Aprovação</a
						>
					</li>
					<li class="breadcrumb-item active">{{ titulo }}</li>
				</ol>
			</div>
		</div>
	</div>
</div>

<div class="content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card card-outline card-lightblue">
					<div class="card-header">
						<h3 class="card-title mb-0">
							<i
								*ngIf="this.id"
								class="fas fa-pencil-alt pr-2"
							></i>
							<i *ngIf="!this.id" class="far fa-file pr-2"></i>
							{{ titulo }}
						</h3>
					</div>

					<form
						[formGroup]="form"
						#f="ngForm"
						(keydown.enter)="$event.preventDefault()"
					>
						<div class="card-body">
							<div class="row">
								<div
									class="form-group col-md-2"
									[hidden]="true"
								>
									<label for="inputId" class="mb-1">Id</label>
									<input
										type="text"
										id="inputId"
										class="form-control form-control-sm"
										formControlName="id"
									/>
								</div>
								<div class="col-md-4 form-group">
									<label
										for="selectTipoAuditoria"
										class="mb-1"
										>Documento de Trabalho</label
									>
									<select
										formControlName="tipoTrabalho"
										(change)="exibirOrigem()"
										class="form-control form-control-sm"
									>
										<option [ngValue]="">Selecione</option>
										<option
											*ngFor="
												let element of tiposTrabalho
											"
											[value]="element.codigo"
										>
											{{ element.codigo }} -
											{{ element.descricao }}
										</option>
									</select>
								</div>
								<div
									class="col-md-4 form-group"
									[hidden]="origemHidden() && exibirOrigem()"
								>
									<label for="selectOrigem" class="mb-1"
										>Origem</label
									>
									<select
										formControlName="origem"
										class="form-control form-control-sm"
									>
										<option [ngValue]="">Selecione</option>
										<option
											*ngFor="let element of origens"
											[value]="element.codigo"
										>
											{{ element.descricao }}
										</option>
									</select>
								</div>
							</div>

							<div class="row">
								<div class="form-group col-md-6">
									<label for="selectPerfilCargo" class="mb-1"
										>Novo Perfil/Cargo</label
									>
									<div
										class="input-group input-group-sm"
										style="width: 100%"
									>
										<select
											formControlName="perfilCargo"
											id="selectPerfilCargo"
											class="form-control form-control-sm"
											[(ngModel)]="
												novoNivel.codigoPerfil
											"
										>
											<option
												*ngFor="
													let item of tabelaPerfilCargoFiltro
												"
												[ngValue]="item.guid"
											>
												{{ item.nome }}
											</option>
										</select>

										<div class="input-group-append">
											<button
												type="button"
												class="btn btn-success ml-1"
												(click)="incluirNivel()"
												[disabled]="
													!novoNivel.codigoPerfil
												"
											>
												<i class="fas fa-plus"></i>
												Adicionar
											</button>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="mat-elevation-z1">
										<table
											#table
											id="table"
											mat-table
											[dataSource]="dataSource"
											cdkDropList
											[cdkDropListData]="dataSource.data"
											(cdkDropListDropped)="drop($event)"
										>
											<tr
												mat-header-row
												*matHeaderRowDef="[
													'ordem',
													'nome',
													'actions'
												]"
												class="bg-light"
											></tr>

											<ng-container matColumnDef="ordem">
												<th
													mat-header-cell
													*matHeaderCellDef
												>
													Ordem
												</th>
												<td
													mat-cell
													*matCellDef="let element"
												>
													{{ element.ordem }}
												</td>
											</ng-container>

											<ng-container matColumnDef="nome">
												<th
													mat-header-cell
													*matHeaderCellDef
												>
													Perfil/Cargo
												</th>
												<td
													mat-cell
													*matCellDef="let element"
												>
													{{
														element.nomePerfil
													}}
												</td>
											</ng-container>

											<ng-container
												cdkColumnDef="actions"
											>
												<th
													mat-header-cell
													*matHeaderCellDef
												></th>
												<td
													mat-cell
													*matCellDef="
														let element;
														let i = index
													"
													class="block"
												>
													<div class="btn-group">
														<button
															type="button"
															class="
																btn
																btn-danger
																btn-xs
																text-left
															"
															(click)="
																excluirNivel(i)
															"
															[disabled]="visualizar"
														>
															<i
																class="
																	far
																	fa-trash-alt
																"
															></i>
														</button>
													</div>
												</td>
											</ng-container>

											<tr
												mat-row
												*matRowDef="
													let row;
													columns: [
														'ordem',
														'nome',
														'actions'
													]
												"
												cdkDrag
											></tr>
										</table>
										<h5
											[hidden]="!semDados"
											class="table-no-data"
										>
											Nenhum membro cadastrado
										</h5>
									</div>
								</div>
							</div>
						</div>

						<div class="card-footer">
							<div class="btn-group" [hidden]="visualizar" >
                                <button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
                                    <i class="far fa-save"></i> Salvar
                                </button>
                                <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                                <div class="dropdown-menu" role="menu">
                                <a class="dropdown-item" (click)="salvarDados(false)">Salvar</a>
                                <a class="dropdown-item" (click)="salvarDados(true)">Salvar e fechar</a>
                                </div>
                            </div>

							<button
								type="button"
								class="btn btn-default btn-sm"
								(click)="onBack()"
							>
								<i class="fas fa-times"></i> Cancelar
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
