
import { SolicitacaoTecnicaService } from 'src/app/services/solicitacao-tecnica.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

export interface IAprovarModel {
  prazo: Date;
  avaliacao: boolean | number;
}
@Component({
  selector: 'app-modal-avaliar-prorrogacao',
  templateUrl: './modal-avaliar-prorrogacao.component.html',
  styleUrls: ['./modal-avaliar-prorrogacao.component.scss']
})
export class ModalAvaliarProrrogacaoComponent implements OnInit {
  public submitted: boolean = false;
  public model: IAprovarModel = {} as IAprovarModel;
  public solicitacaoId: number;

  constructor(
    private dialogRef: MatDialogRef<ModalAvaliarProrrogacaoComponent>,
    public service: SolicitacaoTecnicaService,
		@Inject(MAT_DIALOG_DATA) public data
  ) {
    this.solicitacaoId = data?.solicitacaoId
  }

  public form = new FormGroup({
		prazo: new FormControl("", Validators.required),
    justificativa: new FormControl("", Validators.required)
	});

  ngOnInit(): void {
    this.model.avaliacao = 1
    this.form.controls['prazo'].setValue(new Date(this.data?.data))
  }

  public salvar(){
    this.submitted = true;
    if(+this.model.avaliacao === 1){
      if(this.form.controls['prazo'].invalid){
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: 'Favor preencher o Prazo.',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        return
      }
    }

    const data = new Date(this.form.get('prazo').value);
    const justificativa = this.form.get('justificativa').value;

    const dados = {
      avaliacao: this.model?.avaliacao
    } as any;

    (this.model?.avaliacao === false  || this.model.avaliacao ===  0) ? dados.justificativa = justificativa : dados.data = data;

    if(this.form.valid) {
      this.service.avaliarProrrogacao(this.solicitacaoId, dados).then((res) => {
        if(res.sucesso){
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.dialogRef.close();
        }else{
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "warning",
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      }).catch((err) => {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
    }
  }

  public cancelar() {
		this.dialogRef.close();
	}

}
