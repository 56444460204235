<div class="header-imprimir">
	<img
		src="assets/img/logo_es_color.png"
		alt="SECONT-SIAC"
		class="brand-image"
		style="opacity: 0.8"
	/>
	<div class="title">
		<h4><strong>Governo do Estado do Espírito Santo</strong></h4>
		<h5>Secretaria de Estado de Controle e Transparência (SECONT)</h5>
	</div>
</div>
<div [class]="homologado ? '' : 'homologado'">
  <div
  *ngFor="let opa of opas"
  [style.pageBreakBefore]="
    opas.indexOf(opa) > 0 ? 'always' : 'avoid'
  "
  >
    <div class="relatorio content" *ngIf="opa">
      <div class="card card-outline">
      <div class="card-body">
        <table>
          <tr>
            <th>
              <section id="header">
              <h3>Ordem de Planejamento de Auditoria nº {{ opa?.sequencial | number: '3.' }}/{{ opa?.ano }}</h3>
              </section>
            </th>
          </tr>
        <!-- <div class="line"></div> -->

        <!-- <section id="introducao"> -->
          
       
              <tr>
                  <td>
                  
                      <label>Origem:&nbsp;</label>
                      <span [innerHTML]="opa?.origem?.nome"></span>
                  
                  </td>
                  <td>
                    
                      <label>Tipo:&nbsp;</label>
                      <span [innerHTML]="opa?.tipoAuditoria?.nome"></span>
                    
                  </td>
              </tr>
      
          
          <!-- <div class="line"></div> -->
          <tr>
            <td colspan="2">
              <label>Órgão a ser Auditado:&nbsp;</label>
              <span [innerHTML]="opa?.unidadeGestora?.nome"></span>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <label>Objeto:&nbsp;</label>
              <span [innerHTML]="opa?.objeto"></span>
            </td>
          </tr>
          </table>
          
        <!-- </section> -->

        <hr />
        <section id="periodoPlanejamento">
          
          <div class="row">
            <div class="col-md-12">
              <table>
                <tr>
                  <th>
                    Periodo de Planejamento 
                  </th>
                  <th>Data para apresentação do planejamento</th>
                  <th>Total de dias úteis para planejamento</th>
                </tr>
                <tr class="dataInicioFim">
                  <td>
                    <tr>
                      <div class="row">
                        <div class="col-md-6">
                          Inicio: <br><span>{{ (opa?.dataInicioRealizacao ? opa?.dataInicioRealizacao : opa?.dataInicioPrevista) | date:'dd/MM/yyyy' }}</span>
                        </div>
                        <div class="col-md-6">
                          Fim: <br><span>{{ (opa?.dataFimRealizacao ? opa?.dataFimRealizacao : opa?.dataFimPrevista) | date:'dd/MM/yyyy' }}</span>
                        </div>
                      </div>
                     
                    </tr>
                  </td>
                  <td>
                    {{ opa?.dataApresentacaoPlanejamento | date:'dd/MM/yyyy' }}
                  </td>
                  <td>{{opa?.tipoAuditoria.quantidadeDias }} dia(s)</td>
                </tr>
              </table>

            </div>
            
          </div>

        </section>

        <hr />
        <section id="equipePlanejamento">
          <h4>Equipe de Planejamento</h4>

          <div class="row">
            <div class="col-md-12">
              <table>
                <tr>
                  <th>Nome</th>
                  <th>Cargo</th>
                  <th>Matrícula</th>
                </tr>
  
                <tr *ngFor="let auditor of opa?.opaEquipe?.auditores">
                  <td>{{auditor.nomeAuditor}}</td>
                  <td>
                    <span *ngIf="auditor.cargo === 1" class="text-xs"
                    >Coordenador Titular</span
                    >
                    <span *ngIf="auditor.cargo === 2" class="text-xs"
                    >Coordenador Substituindo</span
                    >
                    <span *ngIf="auditor.cargo === 3" class="text-xs"
                    >Coordenador Respondendo</span
                    >
                    <span *ngIf="auditor.cargo === 4" class="text-xs"
                    >Auditor do Estado</span
                    >
                  </td>
                  <td> {{opa?.numeroFuncional}} </td>
                </tr>
              </table>
            </div>
          </div>
        </section>

        <hr />
        <section id="elaboracao">
          <h4>Coordenador da Equipe</h4>

          <div class="row">
            <div class="col-md-12">
              <label><b>{{opa?.auditorCoordenacao?.nomeAuditor}} </b></label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                    <span *ngIf= "opa?.auditorCoordenacao?.cargo === 1" class="text-xs"
                    >Coordenador Titular</span
                    >
                    <span *ngIf="opa?.auditorCoordenacao?.cargo === 2" class="text-xs"
                    >Coordenador Substituindo</span
                    >
                    <span *ngIf="opa?.auditorCoordenacao?.cargo === 3" class="text-xs"
                    >Coordenador Respondendo</span
                    >
                    <span *ngIf="opa?.auditorCoordenacao?.cargo === 4" class="text-xs"
                    >Auditor do Estado</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <span>Coordenador da {{opa?.auditorCoordenacao?.coordenacao?.sigla}} - {{ opa?.auditorCoordenacao?.coordenacao?.nome }}</span>     
            </div>
          </div>

        </section>

        <hr />
        <section id="aprovacoes">
          <h4>Aprovações</h4>

          <div class="cards">
            <div class="card" *ngFor="let item of opa?.historicoAprovacoes">
                <div class="row">
                  <div class="form-group">
                    <label>{{item?.nomeUsuario}}</label>
                  </div>
                </div>
      
                <div class="row">
                  <div class="form-group">
                    <span>{{item?.cargo}}</span>
                  </div>
                </div>
            </div>
          </div>
        </section>
        <hr *ngIf="opa.notaRodape"/>
        <section id="rodape" *ngIf="opa?.notaRodape">
          <span>{{opa?.notaRodape}}</span>
        </section>
      </div>
        <div class="card-footer no-print">
          <button
            type="button"
            class="btn btn-default btn-sm mr-1"
            (click)="cancelar()"
          >
            <i class="fas fa-times"></i> Fechar
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm mr-1"
            (click)="print()"
          >
            <i class="fas fa-print"></i> Imprimir
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="rodape container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-10">
      <p>
        Av. João Batista Parra, nº. 600, Ed. Aureliano Hoffman, 10º andar, Enseada do Suá, Vitória /ES - Cep: 29050-375
        Tel: (27) 3636-5352<br>
        www.secont.es.gov.br
                </p>
    </div>
  </div>
</div>
