import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IBaseModel } from 'src/app/models/base.model';
import { ICriterioQuestionarioModel } from 'src/app/models/criterio-questionario.model';
import { ICriterioModel } from 'src/app/models/criterio.model';
import { IPerguntaModel } from 'src/app/models/pergunta.model';

@Component({
  selector: 'app-modal-visualizar-questionario-criterio',
  templateUrl: './modal-visualizar-questionario-criterio.component.html',
  styleUrls: ['./modal-visualizar-questionario-criterio.component.scss']
})
export class ModalVisualizarQuestionarioCriterioComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public model: ICriterioQuestionarioModel = {} as ICriterioQuestionarioModel;
  public criterioDataSource = new MatTableDataSource<ICriterioModel>([]);
  public semDados = true;

  constructor(
    private dialogRef: MatDialogRef<ModalVisualizarQuestionarioCriterioComponent>,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: IBaseModel<ICriterioQuestionarioModel[]>) {
    if (data) {
      this.model = data.dados.find(_ => _);
      this.criterioDataSource = new MatTableDataSource<ICriterioModel>(data.dados.map(_ => _.criterio));
      this.criterioDataSource._updateChangeSubscription();
    }
  }

  ngOnInit(): void {
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public fechar() {
    this.dialogRef.close();
  }
}

