<nav class="main-header navbar navbar-expand navbar-dark navbar-lightblue">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a
        class="nav-link"
        (click)="toggleMenuSidebar.emit()"
        data-widget="pushmenu"
        href="#"
        role="button"
        ><i class="fas fa-bars"></i
      ></a>
    </li>
  </ul>

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <a class="nav-link" data-widget="fullscreen" href="#" role="button">
        <i class="fas fa-expand-arrows-alt"></i>
      </a>
    </li>
    <app-user-dropdown-menu></app-user-dropdown-menu>
  </ul>
</nav>
