import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { FiltroModel } from 'src/app/models/filtro.model';
import { IOsaFiltroModel } from 'src/app/models/osa-filtro-model';
import { IOsaModel } from 'src/app/models/osa-model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { OsaService } from 'src/app/services/osa.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-osa-list',
  templateUrl: './osa-list.component.html',
  styleUrls: ['./osa-list.component.scss']
})
export class OsaListComponent implements OnInit, AfterViewInit {
  public dataSource = new MatTableDataSource<IOsaModel>()
  public selectedModel: any;
  public semDados = false;
  public filtro: IOsaFiltroModel = {} as IOsaFiltroModel;
  public pageEvent: any;
  public totalItens: number;
  public numeroOsa: number;
  public displayedColumn: string[] = ['id', 'numeroOsa', 'numeroOpa', 'ano', 'ug', 'origemAuditoria', 'coordenacao', 'dataInicio', 'dataFim', 'status', 'situacao'];

  public permissoes: IPermissaoModel;

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild('tabela1') public tabela1: MatSort;


  constructor(
    private osaService: OsaService,
    public route: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    public localeService: BsLocaleService,
    public matDialog: MatDialog,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOSA, EnumAcaoRecurso.Consultar);
    this.permissoes.elaborar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOSA, EnumAcaoRecurso.Elaborar);
    this.permissoes.imprimir = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOSA, EnumAcaoRecurso.Imprimir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOSA, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.enviarParaAprovacao = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOSA, EnumAcaoRecurso.EnviarParaAprovacao);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOSA, EnumAcaoRecurso.Visualizar);
    this.permissoes.gerarNovaVersao = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOSA, EnumAcaoRecurso.GerarNovaVersao);
  }

  ngAfterViewInit() {
    this.configurarFiltro()
    this.obter();
  }

  sortData(sort) {
  }

  public visualizar(id: number) {
    this.router.navigate([`/osa/${id}/visualizar`]);
  }

  public elaborar(id: number) {
    this.router.navigate([`/osa/${id}/elaborar`]);
  }

  public inativar(id: number): void {
    Swal.fire({
      text: 'Informe uma justificativa para inativar.',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Inativar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: (justificativa) => {
        if (!justificativa) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: 'A justificativa não pode ser vazia!',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          return
        }
        this.osaService.inativar(id, justificativa).then(res => {
          if (!res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'error',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.obter()
        }).catch(error => {
          Swal.showValidationMessage(
            `Ocorreu um erro ao inativar`
          )
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {

    })
  }

  public novaVersao(id: number) {
    this.osaService.gerarNovaVersao(id).then((res) => {
      if (res.sucesso) {
        this.router.navigate([`/osa/${res?.dados?.id}/elaborar`]);
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    }).catch((err) => {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }).finally(() => {
      this.obter()
    })
  }

  public reativar(id: number) {
    Swal.fire({
      title: "Reativar Registro",
      text: "Tem certeza que deseja reativar esse registro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((res) => {
      if (res.value) {
        this.osaService
          .reativar(id)
          .then((res) => {
            if (res.sucesso) {
              Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                icon: 'success',
                text: res.mensagem.descricao,
              });
              this.obter()
            } else {
              {
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: 'warning',
                  text: res.mensagem.descricao,
                });
              }
            }
          })
          .catch((err) => {
            {
              Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                icon: 'error',
                text: err.mensagem.descricao,
              });
            }
          })
      }
    })

  }

  public filtrarPorNumero() {
    this.selectedModel = null;
    var filtro: IOsaFiltroModel = { numeroOsa: this.numeroOsa } as IOsaFiltroModel;
    this.osaService.obter(filtro);
  }
  
  public obter(): void {
    this.selectedModel = null
    this.osaService.currentOsa.subscribe(res => {
      this.dataSource = new MatTableDataSource<IOsaModel>(res.resultado);

      this.dataSource._updateChangeSubscription();
      this.totalItens = res.totalItens

      this.dataSource.sortingDataAccessor = (item: IOsaModel, property) => {
        switch (property) {
          case 'ano':
            return item?.ano;
          case 'numeroOsa':
            return item?.numero;
          case 'numeroOpa':
            return item?.planejamento?.opa?.numero;
          case 'origemAuditoria':
            return item?.planejamento?.origem?.descricao;
          case 'ug':
            return item?.planejamento?.opa?.unidadeGestora?.sigla;
          case 'coordenacao':
            return item?.planejamento?.opa?.auditorCoordenacao?.coordenacao?.sigla;
          case 'dataInicio':
            return item?.planejamento?.dataInicio;
          case 'dataFim':
            return item?.planejamento?.dataFim;
          case 'status':
            return item?.status?.nome;
          case 'situacao':
            return item?.ativo;
          default:
            return item[property];
        }
      };

      this.dataSource.sort = this.tabela1;

      this.semDados = this.dataSource.filteredData.length === 0;
    });
  }

  private configurarFiltro() {
    this.filtro.pagina = this.paginator.pageIndex + 1;
    this.filtro.itensPorPagina = this.paginator.pageSize;
  }


  pageChanged(e) {
    this.filtro.pagina = e.pageIndex + 1;
    e.pageIndex = this.filtro.pagina;
    this.filtro.itensPorPagina = e.pageSize;
    this.obter()
  }


  public selecionar(item: any): void {
    this.selectedModel = !this.selectedModel || this.selectedModel !== item ? item : null;
  }

  public imprimir(id: number) {
    this.router.navigateByUrl(`osa/${id}/imprimir`)
  }

}
