import { ManterMatrizPlanejamentoService } from 'src/app/services/manter-matriz-planejamento.service';
import { IPlanejamentoUGSubtipoSalvarModel, IUgSubtipoModel } from './../../models/planejamento-subtipo-auditoria.model';
import { Component, OnInit, ViewChild, AfterViewInit, Output, EventEmitter, AfterContentChecked } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IPlanejamentoModel } from 'src/app/models/planejamento.model';
import { IPlanejamentoSubtipoAuditoriaModel } from 'src/app/models/planejamento-subtipo-auditoria.model';
import { IPlanejamentoUnidadeGestoraModel } from 'src/app/models/planejamento-unidade-gestora.model';
import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';
import { IPlanejamentoEquipeModel } from 'src/app/models/planejamento-equipe.model';
import { ISubtipoAuditoriaModel } from 'src/app/models/subtipo-auditoria.model';
import { IAuditorCoordenacaoModel } from 'src/app/models/auditor-coordenacao.model';
import { ICustoRecursoPlanejamentoModel } from 'src/app/models/custo-recurso-planejamento.model';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { CoordenacaoService } from 'src/app/services/coordenacao.service';
import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
import { SubtipoAuditoriaService } from 'src/app/services/subtipo-auditoria.service';
import { TipoAuditoriaService } from 'src/app/services/tipo-auditoria.service';
import { PaaService } from 'src/app/services/paa.service';
import { IPlanejamentoEquipeAuditorModel } from 'src/app/models/planejamento-equipe-auditor.model';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { ModalConfirmarInativacaoComponent } from 'src/app/shared/components/modal-confirmar-inativacao/modal-confirmar-inativacao.component';
import { ModalUgPlanejamentoComponent } from './modal-ug-planejamento/modal-ug-planejamento.component';
import { ModalAlocarEquipePlanejamentoComponent } from 'src/app/pages/planejamento-form/modal-alocar-equipe/modal-alocar-equipe-planejamento.component';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import Swal from 'sweetalert2';
import { toFormatValue, toRemoveCharacters} from 'src/app/shared/util/toFormatValue';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { IRelatoriaCapituloModel } from 'src/app/models/relatoria-capitulo.model';
import { IModeloRelatoria } from 'src/app/shared/components/relatoria/relatoria.component';
import { AuthService } from 'src/app/services/auth.service';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { AbaMatrizPlanejamentoComponent } from 'src/app/shared/components/aba-matriz-planejamento/aba-matriz-planejamento.component';

@Component({
  selector: 'app-planejamento-form',
  templateUrl: './planejamento-form.component.html',
  styleUrls: ['./planejamento-form.component.scss'],
})
export class PlanejamentoFormComponent extends BaseFormComponent implements OnInit, AfterViewInit {
  //Informações Básicas
  public model: IPlanejamentoModel = {} as IPlanejamentoModel;
  public modelPlanejamentoSubtipoAuditoria: IPlanejamentoSubtipoAuditoriaModel = {} as IPlanejamentoSubtipoAuditoriaModel;
  public modelPlanejamentoUnidadeGestora: IPlanejamentoUnidadeGestoraModel = {} as IPlanejamentoUnidadeGestoraModel;
  public coordenadoresAuditoria = [] as IAuditorCoordenacaoModel[];
  public subtipoDataSource = new MatTableDataSource<IPlanejamentoSubtipoAuditoriaModel>([]);
  public unidadeGestoraDataSource = new MatTableDataSource<IPlanejamentoUnidadeGestoraModel>([]);
  public auditoresDataSource = new MatTableDataSource<IPlanejamentoEquipeAuditorModel>([]);
  public subtipoSemDados = true;
  public unidadeGestoraSemDados = true;
  public auditoresSemDados = true;
  public numeroOpa: string;
  public displayedColumnsUg: string[] = ['ug', 'subtipo', 'actions'];
  public dadosObtidosPlanejamento: IPlanejamentoEquipeModel = {} as IPlanejamentoEquipeModel;
  public hiddenEdicao: boolean = false;

  public relatoriaDados: IRelatoriaCapituloModel[] = null;
  public modelRelatoria: IModeloRelatoria = {} as IModeloRelatoria;
  @Output() public handlerInit: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('TableOnePaginator', {static: true}) tableOnePaginator: MatPaginator;
  @ViewChild('tabela1', {static: true}) tabela1: MatSort;

  @ViewChild('TableTwoPaginator', {static: true}) tableTwoPaginator: MatPaginator;
  @ViewChild('tabela2', {static: true}) tabela2: MatSort;
  @ViewChild(AbaMatrizPlanejamentoComponent, { static: true }) abaMatrizPlanejamento : AbaMatrizPlanejamentoComponent;

  // Custos e Recursos
  public modelCustoRecursoPlanejamento: ICustoRecursoPlanejamentoModel = {} as ICustoRecursoPlanejamentoModel;
  public custoRecursoColunas = ['selecao', 'nome', 'valorUnitario', 'quantidade', 'valorTotal'];
  public custoRecursoPlanejamentoDataSource = new MatTableDataSource<ICustoRecursoPlanejamentoModel>([]);
  public custoRecursoPlanejamentoSemDados = true;
  public searchAtivo = true;
  public permissoes: IPermissaoModel;
  public statusPlanejamento: number;
  

  public formCustoRecurso = new FormGroup({
    nome: new FormControl('', Validators.required),
    valorUnitario: new FormControl('', Validators.required),
    quantidade: new FormControl('', Validators.required),
  });

  public aba = 0;

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    periodoId: new FormControl(null, Validators.required),
    origemAuditoria: new FormControl('', Validators.required),
    indicadorId: new FormControl(null, Validators.required)
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private planejamentoService: PlanejamentoService,
    private subtipoAuditoriaService: SubtipoAuditoriaService,
    private coordenacaoService: CoordenacaoService,
    private tipoAuditoriaService: TipoAuditoriaService,
    private unidadeGestoraService: UnidadeGestoraService,
    private paaService: PaaService,
    public matDialog: MatDialog,
    private dateHelper: DateHelper,
    private serviceMatriz: ManterMatrizPlanejamentoService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    super(route, toastr, router, localeService, matDialog);

    this.titulo = 'Planejar';
  }


  async ngOnInit() {
    this.setarPermissoes();
    await this.obterDados();
    const numeroAba = this.activatedRoute.snapshot.queryParamMap.get('aba');
    if (numeroAba) { this.mudarAba(Number(numeroAba)); }
    this.router.url.includes("aba=6") ? this.mudarAba(6) : null;
    if(window?.history?.state?.aba) this.mudarAba(window.history.state.aba)
  }
  
  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.enviarParaAprovacao = this.authService.possuiPermissao(EnumRecursoPerfil.Planejamento, EnumAcaoRecurso.EnviarParaAprovacao);

 }

  ngAfterViewInit(): void {

  }

  // Informações Básicas
  public async obterDados() {

    try {
      this.obterCoordenadoresAuditoria();
      this.planejamentoService.obterPorId(this.id).then((res) => {
        if (res.sucesso) {
          this.model = res.dados;
          this.numeroOpa = res.dados.opa.numero;
          this.statusPlanejamento = res.dados.status.codigo;
          if(this.statusPlanejamento == 2 || this.statusPlanejamento == 4)
            this.hiddenEdicao = true;
          this.popularListasInformacoesBasicas(this.model);

          // listagem subtipos
          this.subtipoDataSource = new MatTableDataSource<IPlanejamentoSubtipoAuditoriaModel>(this.model.planejamentoSubTipoAuditoria);
          this.subtipoDataSource.sort = this.tabela1;
          this.subtipoDataSource.paginator = this.tableOnePaginator;
          this.subtipoDataSource._updateChangeSubscription();
          this.subtipoSemDados = this.subtipoDataSource.filteredData.length === 0;

        } else {
          this.submit = false;
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.router.navigate(['/planejamento']);
          return;
        }
      });
      this.obterAlocarEquipe();
    } catch (err) {
      this.submit = false;
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem?.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.router.navigate(['/planejamento']);
    }
  }

  public obterAlocarEquipe() {
    this.planejamentoService.obterAlocarEquipe(this.id).then(res =>  {
      var result: any[] = res.dados as unknown as any[]
      var auditores = [];
      if(result.length > 0) {
        result.forEach(a=> auditores.push({ auditorId: a?.auditorId, nomeAuditor: a?.nomeAuditor ?? a.id, id: a?.id }));
      }
      this.model.planejamentoEquipe = {dataInicio: res.dados[0]?.dataInicio, dataFim: res.dados[0]?.dataFim, auditores} as IPlanejamentoEquipeModel;
      this.dadosObtidosPlanejamento = {dataInicio: res.dados[0]?.dataInicio, dataFim: res.dados[0]?.dataFim, auditores} as IPlanejamentoEquipeModel;

      this.auditoresDataSource = new MatTableDataSource<IPlanejamentoEquipeAuditorModel>(this.model.planejamentoEquipe.auditores);
      this.auditoresDataSource.sort = this.tabela2;
      this.auditoresDataSource.paginator = this.tableTwoPaginator;
      this.auditoresDataSource._updateChangeSubscription();
      this.auditoresSemDados = this.auditoresDataSource.filteredData.length === 0;

      if (this.model.planejamentoEquipe.auditores.length > 0) {
        this.obterDispensasPorPeriodo(false);
      }

  });
  }

  formatarInput(value) {
    return toFormatValue(value);
  }

  public popularListasInformacoesBasicas(model: IPlanejamentoModel){
    this.model.unidadeGestora = [] as IPlanejamentoUnidadeGestoraModel[];
    this.model.planejamentoEquipe = { auditores: [] as IPlanejamentoEquipeAuditorModel[] } as IPlanejamentoEquipeModel;
    if (model.unidadeGestora) model.unidadeGestora = [] as IPlanejamentoUnidadeGestoraModel[];
    if (!model.planejamentoEquipe) model.planejamentoEquipe = { auditores: [] as IPlanejamentoEquipeAuditorModel[] } as IPlanejamentoEquipeModel;
  }


  public obterCoordenadoresAuditoria(){
    this.coordenacaoService
    .obterCoordenadoresAtivos()
    .then((res) => {
      if (res.sucesso) {
        this.coordenadoresAuditoria = res.dados;
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }

  public async onSubmitInformacoesBasicas() {
    this.submit = true;
    this.model.planejamentoSubTipoAuditoria = this.subtipoDataSource.data;
    this.model.unidadeGestora = this.unidadeGestoraDataSource.data;
    try{
    const res = await this.planejamentoService.atualizar(this.model)
    if(res.sucesso){
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'success',
        text: res.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }else{
      this.exibirMensagemAlerta(res.mensagem.descricao);
    };}catch(err){
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public excluirElementoSubtipo(indice: number) {
    this.auditoresDataSource.data.splice(indice, 1);
    this.auditoresDataSource._updateChangeSubscription();
    this.auditoresSemDados = this.auditoresDataSource.filteredData.length === 0;
  }

  public excluirSubtipo(element: number, subtipoId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = { titulo: 'Tem certeza que deseja excluir esse registro?' };
    const modal = this.matDialog.open(
      ModalConfirmarInativacaoComponent,
      dialogConfig
    );
    modal.afterClosed().subscribe((data) => {
      if (data) {
        const subtipo = this.subtipoDataSource.data[element];
        subtipo.justificativa = data;
        subtipo.excluido = true;
        this.planejamentoService.excluirSubtipoAuditoria(this.id, subtipoId, { Justificativa: data }).then(res => {
          if (res.sucesso) {
             this.abaMatrizPlanejamento.excluirRegistro(subtipoId);
             this.exibirMensagemSucesso(' Subtipo, desvinculado com sucesso!');             
           } else {
             this.exibirMensagemErro(' Ocorreu um erro!');
           }
        })
        this.handlerInit.next(true);
        this.atualizarSubtipos();
      }
    });
  }

  private atualizarSubtipos(){
    this.subtipoDataSource = new MatTableDataSource<IPlanejamentoSubtipoAuditoriaModel>(this.model.planejamentoSubTipoAuditoria);
    this.subtipoDataSource.sort = this.tabela1;
    this.subtipoDataSource.paginator = this.tableOnePaginator;
    this.subtipoDataSource._updateChangeSubscription();
    this.subtipoSemDados = this.subtipoDataSource.filteredData.length === 0;
  }

  public async exibirModalSubtipo(){
    try {

      const res = await this.subtipoAuditoriaService.obterAtivos();
      if (res.sucesso) {

        // Deixar subtipos selecionados
        const getSubtiposSelecionados: ISubtipoAuditoriaModel[] =  res.dados.map(a => {
          let subtipoSelecionado = this.subtipoDataSource.data.find(b => b?.subTipoAuditoria?.id === a?.id);
          let subtipoJustificativa = this.subtipoDataSource.data.find(b => b?.subTipoAuditoria?.id === a?.id)?.justificativa;
          if(subtipoJustificativa) a.justificativa = subtipoJustificativa;
          if(subtipoSelecionado?.subTipoAuditoria?.id) a.selecionado = true;
          return a;
        });

        // Remover subtipos da listagens quando tiver justificativa
        const getSubtiposSemJustificativa: ISubtipoAuditoriaModel[] = getSubtiposSelecionados.filter(a => {
          if (!a?.justificativa) {
            return a;
          }
        })

        const modal = this.exibirModalMultiselect('Adicionar Subtipo', getSubtiposSemJustificativa, 'Salvar');
        modal.afterClosed().subscribe((data: ISubtipoAuditoriaModel[])  => {
          if (data) {
            if (Array.isArray(data)) {
              let subtiposAuditoriaSelecionados: ISubtipoAuditoriaModel[] = data;
              var subtipoAuditoria: number[] = subtiposAuditoriaSelecionados.map(x =>  x.id);
              this.planejamentoService.salvarSubtipoAuditoria(this.id, { subtipoAuditoria }).then(res => {
                if (res.sucesso) {
                   this.exibirMensagemSucesso(' Subtipo, vinculado com sucesso!');
                   this.abaMatrizPlanejamento.obter();

                 } else {
                   this.exibirMensagemErro(' Ocorreu um erro!');
                 }
              });
              this.obterDados();
              this.atualizarSubtipos();
              this.ngOnInit();
            }
          }
        });
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public async exibirModalUnidadeGestora() {
    try {

      const res = await this.unidadeGestoraService.obterAtivos();
      if (res.sucesso) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component';
        dialogConfig.width = '650px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.data = res.dados;
        const modal = this.matDialog.open(
          ModalUgPlanejamentoComponent,
          dialogConfig
        );
        modal.afterClosed().subscribe((data) => {
          if (data) {
            let unidadeGestoraSelecionados = [] as IUnidadeGestoraModel[];
            unidadeGestoraSelecionados = data.map(d => (d as IUnidadeGestoraModel));
            unidadeGestoraSelecionados.forEach(x => this.model.unidadeGestora.push({unidadeGestoraId: x.id, unidadeGestora: x} as IPlanejamentoUnidadeGestoraModel));

            if (this.unidadeGestoraDataSource.data.map(x=> unidadeGestoraSelecionados.find(z=> z.id == x.unidadeGestoraId)).length > 0) {
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'warning',
                text: 'Unidade gestora já cadastrada nesse planejamento',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
              return;
            }
          }
          this.atualizarUnidadeGestora();
        });
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public unidadeGestoraSelecionada(event: any[]) {
    let UgSubTipo: IPlanejamentoUGSubtipoSalvarModel = {
      ugSubTipo: event.map<IUgSubtipoModel>(a => { return { unidadeGestoraId: a?.unidadeGestora?.id, subTipoAuditoriaId: a?.subtipoAuditoria?.subTipoAuditoria?.id ??  a?.subtipoAuditoria?.id}})
    } as IPlanejamentoUGSubtipoSalvarModel;

    (async () => {
        const res = await this.planejamentoService.salvarUgSubtipo(this.id, UgSubTipo);
        res.sucesso ? this.exibirMensagemSucesso(' Item adicionado com sucesso!') : this.exibirMensagemErro(' Ocorreu um erro!');
        this.obterDados();
      })()
  }

  public excluirUg(event: any) {
    this.planejamentoService.desvincularUgSubtipo(this.id, event.subtipoId, event.ugId).then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso(' UG desvinculada com sucesso!')
      } else {
        this.exibirMensagemErro(' Ocorreu um erro!')
      }
    }).catch(() => this.exibirMensagemErro(' Ocorreu um erro!'))
    this.obterDados();
  }

  private atualizarAuditores(salvar: boolean){
    this.auditoresDataSource = new MatTableDataSource<IPlanejamentoEquipeAuditorModel>(this.model.planejamentoEquipe.auditores);
    this.auditoresDataSource.sort = this.tabela2;
    this.auditoresDataSource.paginator = this.tableTwoPaginator;
    this.auditoresDataSource._updateChangeSubscription();
    this.auditoresSemDados = this.auditoresDataSource.filteredData.length === 0;

    if (salvar)this.planejamentoService
      .salvarAlocarEquipe(this.id, { auditorId: this.auditoresDataSource.data.map(a => a.auditorId ), dataFim: this.model.planejamentoEquipe.dataFim , dataInicio: this.model.planejamentoEquipe.dataInicio })
        .then(res => {
          res.sucesso ? this.exibirMensagemSucesso(' Equipe, vinculada com sucesso!') : this.exibirMensagemErro(' Ocorreu um erro!');
        }).finally(() => this.obterAlocarEquipe());
  }

  public obterDispensasPorPeriodo(salvar: boolean) {
    this.paaService
    .obterDispensasPeriodo(this.dateHelper.toString(this.model.planejamentoEquipe.dataInicio), this.dateHelper.toString(this.model.planejamentoEquipe.dataFim))
    .then((res) => {
      if (res.sucesso) {
        this.model.planejamentoEquipe.auditores.forEach(auditor => {
          auditor.indisponivelPeriodo = res.dados.filter(a => a.auditor.auditorId === auditor.auditorId).length > 0;
        });
      }
    })
    .finally(() => {
      this.atualizarAuditores(salvar);
    });
  }

  public async exibirModalAlocarEquipe() {
    try {
      const auditoresTipoAuditoria = this.model.idTipoAuditoria === 0 ? [] as IAuditorCoordenacaoModel[] : (
        await this.tipoAuditoriaService.obterAuditoresCoordenacaoTipoAuditoria(this.model.idTipoAuditoria)
      ).dados;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '650px';
      dialogConfig.maxHeight = '80vh';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;

      dialogConfig.data = { equipe: this.dadosObtidosPlanejamento, auditores: this.coordenadoresAuditoria, tipoAuditoria: this.model.opa?.tipoAuditoria, auditoresTipoAuditoria };
      const modal = this.matDialog.open(
        ModalAlocarEquipePlanejamentoComponent,
        dialogConfig
      );
      modal.afterClosed().subscribe((data) => {
        if (data) {
          this.model.planejamentoEquipe = data;
          if (this.model.planejamentoEquipe.auditores.length > 0) {
            this.model.planejamentoEquipe.auditores = this.model.planejamentoEquipe.auditores.map(a => {
              let buscarAuditores = this.dadosObtidosPlanejamento.auditores.find(b => b.auditorId === a.auditorId);
              if (buscarAuditores.id) a.id = buscarAuditores.id;
              return a;
            })
            this.obterDispensasPorPeriodo(true);
          }
        }
      });
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public excluirAuditor(indice: number) {
    this.auditoresDataSource.data.splice(indice, 1);
    this.auditoresDataSource._updateChangeSubscription();
    this.auditoresSemDados = this.auditoresDataSource.filteredData.length === 0;
  }

  public desvincularAlocarEquipe(idEquipeAlocada: number) {
    Swal.fire({
      title: "Excluir Registro",
      text: "Tem certeza que deseja excluir esse Registro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((res) => {
      if (res.value) {
        this.planejamentoService.desvincularAlocarEquipe(this.id, idEquipeAlocada)
        .then(res => {
          if(res.sucesso) {
            this.exibirMensagemSucesso(' Equipe, desvinculada com sucesso!');
          } else {
            this.exibirMensagemErro(' Ocorreu um erro!')
          }
        }).then(() => this.obterDados());
      }})
  }

  public excluirUnidadeGestora(indice: number) {
    this.unidadeGestoraDataSource.data.splice(indice, 1);
    this.unidadeGestoraDataSource._updateChangeSubscription();
    this.unidadeGestoraSemDados = this.unidadeGestoraDataSource.filteredData.length === 0;
  }

  private atualizarUnidadeGestora(){
    this.unidadeGestoraDataSource = new MatTableDataSource<IPlanejamentoUnidadeGestoraModel>(this.model.unidadeGestora);
    this.unidadeGestoraDataSource._updateChangeSubscription();
    this.unidadeGestoraSemDados = this.unidadeGestoraDataSource.filteredData.length === 0;
  }
  // fim informações básicas


  public mudarAba(ind: number) {
    this.aba = ind;
  }

  public enviarParaAprovacao() {
    this.planejamentoService.enviarAprovacao(this.id).then((res) => {
      if(res.sucesso){
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        this.router.navigate(['/planejamento']);
      }else{
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    }).catch((err) => {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    })

  }

  public onBack() {
    window.history.back()
  }

}
