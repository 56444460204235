<div>
  <table mat-table [dataSource]="dataSource" *ngIf="dataSource?.data?.length !== 0" class="w-100" matSort>
    <ng-container matColumnDef="questao">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Questão</th>
      <td mat-cell *matCellDef="let element as ">{{ element?.questao?.nome}}</td>
    </ng-container>

    <ng-container matColumnDef="legislacao">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Legislação</th>
      <td mat-cell *matCellDef="let element" ><div style="overflow: hidden;" [innerHTML]="element?.questao?.legislacaoAplicavelCriterio"></div></td>
    </ng-container>

    <ng-container matColumnDef="acoes">
      <th mat-header-cell *matHeaderCellDef class="text-center">Ações</th>
      <td mat-cell *matCellDef="let element; let index">
        <div class="w-100 d-flex justify-content-center">
          <button class="icon-btn btn btn-info btn-xs" (click)="modalEditarQuestao(element?.questao, true)">
            <i class="far fas fa-eye"></i>
          </button>
          <button type="button" *ngIf="isPlanejamento && !visualizar && !element?.readonly" class="btn btn-primary btn-xs ml-1" (click)="modalEditarQuestao(element?.questao)">
            <i class="fas fa-pen"></i>
          </button>

          <button type="button" *ngIf="isPlanejamento && !visualizar && !element?.readonly" class="btn btn-danger btn-xs ml-1" (click)="excluirQuestao(element?.questao?.id)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <h5 *ngIf="dataSource?.data?.length === 0" class="table-no-data">
    Nenhum registro encontrado.
  </h5>

  <mat-paginator [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons>
  </mat-paginator>
</div>
