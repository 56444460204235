import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { CapituloRelatorioFormComponent } from "./pages/capitulo-relatorio-form/capitulo-relatorio-form.component";
import { CapituloRelatorioListComponent } from "./pages/capitulo-relatorio-list/capitulo-relatorio-list.component";
import { CoordenacaoFormComponent } from "./pages/coordenacao-form/coordenacao-form.component";
import { CoordenacaoListComponent } from "./pages/coordenacao-list/coordenacao-list.component";
import { CalendarioFormComponent } from "./pages/calendario-form/calendario-form.component";
import { CalendarioListComponent } from "./pages/calendario-list/calendario-list.component";
import { CriterioFormComponent } from "./pages/criterio-form/criterio-form.component";
import { CriterioListComponent } from "./pages/criterio-list/criterio-list.component";
import { FaixaClassificacaoFormComponent } from "./pages/faixa-classificacao-form/faixa-classificacao-form.component";
import { FluxoAprovacaoFormComponent } from "./pages/fluxo-aprovacao-form/fluxo-aprovacao-form.component";
import { FluxoAprovacaoListComponent } from "./pages/fluxo-aprovacao-list/fluxo-aprovacao-list.component";
import { IndicadorFormComponent } from "./pages/indicador-form/indicador-form.component";
import { IndicadorListComponent } from "./pages/indicador-list/indicador-list.component";
import { InicialComponent } from "./pages/inicial/inicial.component";
import { ModeloRelatorioFormComponent } from "./pages/modelo-relatorio-form/modelo-relatorio-form.component";
import { ModeloRelatorioListComponent } from "./pages/modelo-relatorio-list/modelo-relatorio-list.component";
import { ParametrizacaoDocumentoEdocsFormComponent } from "./pages/parametrizacao-documento-edocs-form/parametrizacao-documento-edocs-form.component";
import { ParametrizacaoListComponent } from "./pages/parametrizacao-list/parametrizacao-list.component";
import { PeaFormComponent } from "./pages/pea-form/pea-form.component";
import { PeaListComponent } from "./pages/pea-list/pea-list.component";
import { PeaRelatorioComponent } from "./pages/pea-relatorio/pea-relatorio.component";
import { PaaRelatorioComponent } from "./pages/paa-relatorio/paa-relatorio.component";
import { PerguntaFormComponent } from "./pages/pergunta-form/pergunta-form.component";
import { PerguntaListComponent } from "./pages/pergunta-list/pergunta-list.component";
import { PeriodoFormComponent } from "./pages/periodo-form/periodo-form.component";
import { PeriodoListComponent } from "./pages/periodo-list/periodo-list.component";
import { QuestionarioFormComponent } from "./pages/questionario-form/questionario-form.component";
import { QuestionarioListComponent } from "./pages/questionario-list/questionario-list.component";
import { TipoAuditoriaFormComponent } from "./pages/tipo-auditoria-form/tipo-auditoria-form.component";
import { TipoAuditoriaListComponent } from "./pages/tipo-auditoria-list/tipo-auditoria-list.component";
import { TipoCriterioFormComponent } from "./pages/tipo-criterio-form/tipo-criterio-form.component";
import { TipoCriterioListComponent } from "./pages/tipo-criterio-list/tipo-criterio-list.component";
import { TipoDispensaFormComponent } from "./pages/tipo-dispensa-form/tipo-dispensa-form.component";
import { TipoDispensaListComponent } from "./pages/tipo-dispensa-list/tipo-dispensa-list.component";
import { UnidadeGestoraFormComponent } from "./pages/unidade-gestora-form/unidade-gestora-form.component";
import { UnidadeGestoraListComponent } from "./pages/unidade-gestora-list/unidade-gestora-list.component";
import { MainComponent } from "./shared/layout/main/main.component";
import { PaaListComponent } from "./pages/paa-list/paa-list.component";
import { PaaElaborarComponent } from "./pages/paa-elaborar/paa-elaborar.component";
import { PendenciaListComponent } from "./pages/pendencia-list/pendencia-list.component";
import { OpaListComponent } from "./pages/opa/list/opa-list.component";
import { TecnicaAuditoriaListComponent } from "./pages/tecnica-auditoria-list/tecnica-auditoria-list.component";
import { TecnicaAuditoriaFormComponent } from "./pages/tecnica-auditoria-form/tecnica-auditoria-form.component";
import { LogListComponent } from "./pages/log-sistema-list/log-list.component";
import { CustoRecursoListComponent } from "./pages/custo-recurso-list/custo-recurso-list.component";
import { CustoRecursoFormComponent } from "./pages/custo-recurso-form/custo-recurso-form.component";
import { ProcedimentoAuditoriaListComponent } from "./pages/procedimento-auditoria-list/procedimento-auditoria-list.component";
import { ProcedimentoAuditoriaFormComponent } from "./pages/procedimento-auditoria-form/procedimento-auditoria-form.component";
import { LogFormComponent } from "./pages/log-sistema-form/log-form.component";
import { RecomendacaoListComponent } from "./pages/recomendacao-list/recomendacao-list.component";
import { RecomendacaoFormComponent } from "./pages/recomendacao-form/recomendacao-form.component";
import { ConstatacaoListComponent } from "./pages/constatacao-list/constatacao-list.component";
import { ConstatacaoFormComponent } from "./pages/constatacao-form/constatacao-form.component";
import { QuestaoListComponent } from "./pages/questao-list/questao-list.component";
import { QuestaoFormComponent } from "./pages/questao-form/questao-form.component";
import { OpaFormComponent } from "./pages/opa/form/opa-form.component";
import { MatrizPlanejamentoListComponent } from "./pages/matriz-planejamento-list/matriz-planejamento-list.component";
import { MatrizPlanejamentoFormComponent } from "./pages/matriz-planejamento-form/matriz-planejamento-form.component";
import { MatrizPlanejamentoRelatorioComponent } from "./pages/matriz-planejamento-relatorio/matriz-planejamento-relatorio.component";
import { PeaGuard } from "./core/guards/pea.guard";
import { QuestionarioGuard } from "./core/guards/questionario.guard";
import { PeriodoGuard } from "./core/guards/periodo.guard";
import { TipoCriterioGuard } from "./core/guards/tipoCriterio.guard";
import { CriterioGuard } from "./core/guards/criterio.guard";
import { IndicadorGuard } from "./core/guards/Indicador.guard";
import { TipoAuditoriaGuard } from "./core/guards/tipoAuditoria.guard";
import { TipoDispensaGuard } from "./core/guards/tipoDispensa.guard";
import { UnidadeGestoraGuard } from "./core/guards/tipoDispensa.guard";
import { CoordenacaoGuard } from "./core/guards/coordenacao.guard";
import { ModeloRelatorioGuard } from "./core/guards/modeloRelatorio.guard";
import { PerguntaGuard } from "./core/guards/pergunta.guard";
import { ImprimirRolResponsaveisComponent } from "./pages/rol-responsavel-relatoria/imprimir-rol-responsaveis/imprimir-rol-responsaveis.component";
import { RolResponsaveisFormComponent } from "./pages/rol-responsaveis-form/rol-responsaveis-form.component";
import { NaturezaResponsabilidadeListComponent } from "./pages/natureza-responsabilidade-list/natureza-responsabilidade-list.component";
import { NaturezaResponsabilidadeFormComponent } from "./pages/natureza-responsabilidade-form/natureza-responsabilidade-form.component";
import { NaturezaResponsabilidadeGuard } from "./core/guards/naturezaResponsabilidade.guard";
import { CalendarioGuard } from "./core/guards/Calendario.guard";
import { SubtipoAuditoriaListComponent } from "./pages/subtipo-auditoria-list/subtipo-auditoria-list.component";
import { SubtipoAuditoriaFormComponent } from "./pages/subtipo-auditoria-form/subtipo-auditoria-form.component";
import { TecnicaAuditoriaGuard } from "./core/guards/tecnicaAuditoria.guard";
import { CustoRecursoGuard } from "./core/guards/custoRecurso.guard";
import { ProcedimentoAuditoriaGuard } from "./core/guards/procedimentoAuditoria.guard";
import { QuestaoGuard } from "./core/guards/questao.guard";
import { MatrizPlanejamentoGuard } from "./core/guards/matrizPlanejamento.guard";
import { RecomendacaoGuard } from "./core/guards/recomendacao.guard";
import { SubtipoAuditoriaGuard } from "./core/guards/subtipoAuditoria.guard";
import { PlanejamentoFormComponent } from "./pages/planejamento-form/planejamento-form.component";
import { OpaRelatorioComponent } from "./pages/opa/relatorio/opa-relatorio.component";
import { RolResponsaveisListComponent } from "./pages/rol-responsaveis-list/rol-responsaveis-list.component";
import { ImprimirRolResponsavelUnidadeGestoraComponent } from "./pages/rol-responsavel-relatoria-unidade-gestora/imprimir-rol-responsaveis/imprimir-rol-responsavel-unidade-gestora.component";
import { PlanejamentoListComponent } from "./pages/planejamento-list/planejamento-list.component";
import { FaseAtividadeListComponent } from "./pages/fase-atividade-list/fase-atividade-list.component";
import { OsaComponent } from "./pages/osa/osa.component";
import { OsaFormComponent } from "./pages/osa/osa-form/osa-form.component";
import { FaseAtividadeFormComponent } from "./pages/fase-atividade-form/fase-atividade-form.component";
import { FaseAtividadeGuard } from "./core/guards/faseAtividade.guard";
import { OsaImprimirComponent } from "./pages/osa/osa-imprimir/osa-imprimir.component";
import { ImprimirAmostraComponent } from "./shared/components/aba-amostra/imprimir-amostra/imprimir-amostra.component";
import { AprovacaoListComponent } from "./pages/aprovacao-list/aprovacao-list.component";
import { ImprimirRelatoriaComponent } from "./shared/components/relatoria/imprimir-relatoria/imprimir-relatoria.component";
import { AuditoriaListComponent } from "./pages/auditoria-list/auditoria-list.component";
import { SolicitacaoTecnicaListComponent } from "./pages/solicitacao-tecnica/list/solicitacao-tecnica-list.component";
import { SolicitacaoTecnicaFormComponent } from "./pages/solicitacao-tecnica/solicitacao-tecnica-form/solicitacao-tecnica-form.component";
import { ImprimirSolicitacaoComponent } from "./shared/components/aba-solicitacao-tecnica/imprimir-solicitacao/imprimir-solicitacao.component";
import { ImprimirComponent } from "./pages/planejamento-form/imprimir/imprimir.component";
import { EnumRecursoPerfil } from "./models/enum/recurso-perfil.enum";
import { EnumAcaoRecurso } from "./models/enum/acao-recurso.enum";
import { MatrizPlanejamentoVisualizarComponent } from "./pages/planejamento-form/matriz-planejamento-visualizar/matriz-planejamento-visualizar.component";
import { TipoEnfaseListComponent } from "./pages/tipo-enfase-list/tipo-enfase-list.component";
import { TipoEnfaseFormComponent } from "./pages/tipo-enfase-form/tipo-enfase-form.component";
import { TipoEnfaseGuard } from "./core/guards/tipoEnfase.guard";
import { AgentePublicoListComponent } from "./pages/agente-publico-list/agente-publico-list.component";
import { AgentePublicoFormComponent } from "./pages/agente-publico-form/agente-publico-form.component";
import { PaaGuard } from "./core/guards/paa.guard";
import { CategoriaRiscoListComponent } from "./pages/categoria-risco-list/categoria-risco-list.component";
import { CategoriaRiscoFormComponent } from "./pages/categoria-risco-form/categoria-risco-form.component";
import { RiscosFormComponent } from "./pages/riscos-form/riscos-form.component";
import { CategoriaRiscoGuard } from "./core/guards/categoriaRisco.guard";
import { ModeloQuestionarioListComponent } from "./pages/modelo-questionario-list/modelo-questionario-list.component";
import { ModeloQuestionarioFormComponent } from "./pages/modelo-questionario-form/modelo-questionario-form.component";
import { GerarQuestionarioComponent } from "./pages/unidade-gestora-form/aba-entendendo-entidade/gerar-questionario/gerar-questionario.component";
import { AbaEntendendoEntidadeComponent } from "./pages/unidade-gestora-form/aba-entendendo-entidade/aba-entendendo-entidade.component";
import { EditarEntendendoEntidadeComponent } from "./pages/unidade-gestora-form/aba-entendendo-entidade/editar-entendendo-entidade/editar-entendendo-entidade.component";
import { VisualizarEntendendoEntidadeComponent } from "./pages/unidade-gestora-form/aba-entendendo-entidade/visualizar-entendendo-entidade/visualizar-entendendo-entidade.component";
import { ImprimirEntendendoEntidadeComponent } from "./pages/unidade-gestora-form/aba-entendendo-entidade/imprimir-entendendo-entidade/imprimir-entendendo-entidade.component";
import { ImportacaoArquivoQuestionarioComponent } from "./pages/unidade-gestora-form/aba-entendendo-entidade/importacao-arquivo-questionario/importacao-arquivo-questionario.component";

import { PrePlanejamentoListComponent } from "./pages/pre-planejamento-list/pre-planejamento-list.component";
import { PrePlanejamentoRespostaFormComponent } from "./pages/pre-planejamento-resposta-form/pre-planejamento-resposta-form.component";

import { RiscosGuard } from "./core/guards/riscos.guard";
import { RiscosListComponent } from "./pages/riscos-list/riscos-list.component";
import { AbaMaturidadeRiscoComponent } from "./pages/unidade-gestora-form/aba-maturidade-risco/aba-maturidade-risco.component";
import { GerarQuestionarioSharedComponent } from "./pages/unidade-gestora-form/shared/gerar-questionario/gerar-questionario.component";
import { EditarQuestionarioComponent } from "./pages/unidade-gestora-form/shared/editar-questionario/editar-questionario.component";
import { VisualizarQuestionarioComponent } from "./pages/unidade-gestora-form/shared/visualizar-questionario/visualizar-questionario.component";
import { ImportacaoArquivoQuestionarioSharedComponent } from "./pages/unidade-gestora-form/shared/importacao-arquivo-questionario/importacao-arquivo-questionario.component";
import { ImprimirQuestionarioComponent } from "./pages/unidade-gestora-form/shared/imprimir-questionario/imprimir-questionario.component";
import { AvaliarQuestionarioComponent } from "./pages/unidade-gestora-form/shared/avaliar-questionario/avaliar-questionario.component";
import { VisualizarRelatoriaComponent } from "./shared/components/relatoria/visualizar-relatoria/visualizar-relatoria.component";
import { imprimirLinhaDoTempoComponent } from "./shared/components/relatoria/imprimir-linha-do-tempo/imprimir-linha-do-tempo.component";
import { TipoUniversoListComponent } from "./pages/tipo-universo-list/tipo-universo-list.component";
import { TipoUniversoFormComponent } from "./pages/tipo-universo-form/tipo-universo-form.component";
import { VisualizarMaturidadeDeRiscoComponent } from "./pages/unidade-gestora-form/aba-maturidade-risco/visualizar-maturidade-de-risco/visualizar-maturidade-de-risco.component";


//A ordem das rotas importa: primeiro /novo, depois os próximos

const routes: Routes = [
	{
		path: "",
		component: MainComponent,
		canActivateChild: [AuthGuard],
		children: [
			{
				path: "osa",
				data: {
					recurso: EnumRecursoPerfil.ManterOSA,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: OsaComponent,
			},
			{
				path: "osa/:id/elaborar",
				data: {
					recurso: EnumRecursoPerfil.ManterOSA,
					acao: EnumAcaoRecurso.Elaborar,
				},
				component: OsaFormComponent,
			},
			{
				path: "osa/:id",
				data: {
					recurso: EnumRecursoPerfil.ManterOSA,
					acao: EnumAcaoRecurso.Editar,
				},
				component: OsaFormComponent,
			},
			{
				path: "osa/:id/imprimir",
				data: {
					recurso: EnumRecursoPerfil.ManterOSA,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: OsaImprimirComponent,
			},
			{
				path: "osa/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.ManterOSA,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: OsaFormComponent,
			},
			{
				path: "imprimir-relatoria/:id/tipo-trabalho/:tipoTrabalho",
				data: {
					recurso: EnumRecursoPerfil.Aprovacoes,
					acao: EnumAcaoRecurso.Relatoria,
				},
				component: ImprimirRelatoriaComponent,
			},
			{
				path: "imprimir-relatoria/:id/espelho-relatorio",
				data: {
					recurso: EnumRecursoPerfil.Aprovacoes,
					acao: EnumAcaoRecurso.Relatoria,
				},
				component: ImprimirRelatoriaComponent,
			},
			{
				path: "visualizar-relatoria/:id/tipo-trabalho/:tipoTrabalho",
				data: {
					recurso: EnumRecursoPerfil.Aprovacoes,
					acao: EnumAcaoRecurso.Relatoria,
				},
				component: VisualizarRelatoriaComponent,
			},
			{
				path: "imprimir-linha-do-tempo/:id/tipo-trabalho/:tipoTrabalho",
				data: {
					recurso: EnumRecursoPerfil.Aprovacoes,
					acao: EnumAcaoRecurso.Relatoria,
				},
				component: imprimirLinhaDoTempoComponent,
			},
			{
				path: "faixa-classificacao",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: FaixaClassificacaoFormComponent,
			},

			{
				path: "indicadores",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: IndicadorListComponent,
			},
			{
				path: "indicadores/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: IndicadorFormComponent,
				canDeactivate: [IndicadorGuard],
			},
			{
				path: "indicadores/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: IndicadorFormComponent,
			},
			{
				path: "indicadores/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: IndicadorFormComponent,
			},
			{
				path: "tipos-dispensa",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: TipoDispensaListComponent,
			},
			{
				path: "tipos-dispensa/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: TipoDispensaFormComponent,
				canDeactivate: [TipoDispensaGuard],
			},
			{
				path: "tipos-dispensa/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: TipoDispensaFormComponent,
			},
			{
				path: "tipos-dispensa/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: TipoDispensaFormComponent,
			},
			{
				path: "periodos",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: PeriodoListComponent,
			},
			{
				path: "periodos/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: PeriodoFormComponent,
				canDeactivate: [PeriodoGuard],
			},
			{
				path: "periodos/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: PeriodoFormComponent,
			},
			{
				path: "criterios",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: CriterioListComponent,
			},
			{
				path: "criterios/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: CriterioFormComponent,
				canDeactivate: [CriterioGuard],
			},
			{
				path: "criterios/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: CriterioFormComponent,
			},
			{
				path: "criterio/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: CriterioFormComponent,
			},
			{
				path: "tipos-criterio",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: TipoCriterioListComponent,
			},
			{
				path: "tipos-criterio/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: TipoCriterioFormComponent,
				canDeactivate: [TipoCriterioGuard],
			},
			{
				path: "tipos-criterio/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: TipoCriterioFormComponent,
			},
			{
				path: "tipos-criterio/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: TipoCriterioFormComponent,
			},
			{
				path: "aprovacao",
				data: {
					recurso: EnumRecursoPerfil.Aprovacoes,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: AprovacaoListComponent,
			},
			{
				path: "aprovacao/:tipoDocumentoAprovacao/:id",
				data: {
					recurso: EnumRecursoPerfil.Aprovacoes,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: AprovacaoListComponent,
			},
			{
				path: "perguntas",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: PerguntaListComponent,
			},
			{
				path: "perguntas/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: PerguntaFormComponent,
				canDeactivate: [PerguntaGuard],
			},
			{
				path: "perguntas/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: PerguntaFormComponent,
			},
			{
				path: "perguntas/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: PerguntaFormComponent,
			},
			{
				path: "coordenacoes",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: CoordenacaoListComponent,
			},
			{
				path: "coordenacoes/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: CoordenacaoFormComponent,
				canDeactivate: [CoordenacaoGuard],
			},
			{
				path: "coordenacoes/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: CoordenacaoFormComponent,
			},
			{
				path: "coordenacoes/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: CoordenacaoFormComponent,
			},
			{
				path: "fase-atividade",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: FaseAtividadeListComponent,
			},
			{
				path: "fase-atividade/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: FaseAtividadeFormComponent,
				canDeactivate: [FaseAtividadeGuard],
			},
			{
				path: "fase-atividade/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: FaseAtividadeFormComponent,
			},
			{
				path: "fase-atividade/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: FaseAtividadeFormComponent,
			},
			{
				path: "natureza-responsabilidade",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: NaturezaResponsabilidadeListComponent,
			},
			{
				path: "natureza-responsabilidade/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: NaturezaResponsabilidadeFormComponent,
				canDeactivate: [NaturezaResponsabilidadeGuard],
			},
			{
				path: "natureza-responsabilidade/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: NaturezaResponsabilidadeFormComponent,
			},
			{
				path: "natureza-responsabilidade/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: NaturezaResponsabilidadeFormComponent,
			},
			{
				path: "calendario",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: CalendarioListComponent,
			},
			{
				path: "calendario/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: CalendarioFormComponent,
				canDeactivate: [CalendarioGuard],
			},
			{
				path: "calendario/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: CalendarioFormComponent,
			},
			{
				path: "calendario/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: CalendarioFormComponent,
			},
			{
				path: "tecnica-auditoria",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: TecnicaAuditoriaListComponent,
			},
			{
				path: "tecnica-auditoria/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: TecnicaAuditoriaFormComponent,
			},
			{
				path: "tecnica-auditoria/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: TecnicaAuditoriaFormComponent,
				canDeactivate: [TecnicaAuditoriaGuard],
			},
			{
				path: "tecnica-auditoria/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: TecnicaAuditoriaFormComponent,
			},
			{
				path: "recomendacao",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: RecomendacaoListComponent,
			},
			{
				path: "recomendacao/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: RecomendacaoFormComponent,
			},
			{
				path: "recomendacao/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: RecomendacaoFormComponent,
				canDeactivate: [RecomendacaoGuard],
			},
			{
				path: "recomendacao/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: RecomendacaoFormComponent,
			},
			{
				path: "custo-recurso",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: CustoRecursoListComponent,
			},
			{
				path: "custo-recurso/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: CustoRecursoFormComponent,
			},
			{
				path: "custo-recurso/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: CustoRecursoFormComponent,
				canDeactivate: [CustoRecursoGuard],
			},
			{
				path: "custo-recurso/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: CustoRecursoFormComponent,
			},
			{
				path: "procedimento-auditoria",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: ProcedimentoAuditoriaListComponent,
			},
			{
				path: "procedimento-auditoria/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: ProcedimentoAuditoriaFormComponent,
			},
			{
				path: "procedimento-auditoria/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: ProcedimentoAuditoriaFormComponent,
				canDeactivate: [ProcedimentoAuditoriaGuard],
			},
			{
				path: "procedimento-auditoria/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: ProcedimentoAuditoriaFormComponent,
			},
			{
				path: "tipos-auditoria",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: TipoAuditoriaListComponent,
			},
			{
				path: "tipos-auditoria/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: TipoAuditoriaFormComponent,
				canDeactivate: [TipoAuditoriaGuard],
			},
			{
				path: "tipos-auditoria/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: TipoAuditoriaFormComponent,
			},
			{
				path: "tipos-auditoria/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: TipoAuditoriaFormComponent,
			},
			{
				path: "capitulos-relatorio",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: CapituloRelatorioListComponent,
			},
			{
				path: "capitulos-relatorio/novo",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: CapituloRelatorioFormComponent,
			},
			{
				path: "capitulos-relatorio/:id",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: CapituloRelatorioFormComponent,
			},
			{
				path: "parametrizacao-documento-edocs",
				data: {
					recurso: EnumRecursoPerfil.Configuracoes,
					acao: EnumAcaoRecurso.Editar,
				},
				component: ParametrizacaoDocumentoEdocsFormComponent,
			},
			{
				path: "modelos-relatorio",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: ModeloRelatorioListComponent,
			},
			{
				path: "modelos-relatorio/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: ModeloRelatorioFormComponent,
				canDeactivate: [ModeloRelatorioGuard],
			},
			{
				path: "modelos-relatorio/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: ModeloRelatorioFormComponent,
			},
			{
				path: "modelos-relatorio/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: ModeloRelatorioFormComponent,
			},
			{
				path: "pea",
				data: {
					recurso: EnumRecursoPerfil.ManterPEA,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: PeaListComponent,
			},
			{
				path: "pea/novo",
				data: {
					recurso: EnumRecursoPerfil.ManterPEA,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: PeaFormComponent,
				canDeactivate: [PeaGuard],
			},
			{
				path: "pea/:id",
				data: {
					recurso: EnumRecursoPerfil.ManterPEA,
					acao: EnumAcaoRecurso.Editar,
				},
				component: PeaFormComponent,
			},
			{
				path: "pea/:id/editar-relatoria",
				data: {
					recurso: EnumRecursoPerfil.ManterPEA,
					acao: EnumAcaoRecurso.Editar,
				},
				component: PeaFormComponent,
			},
			{
				path: "pea/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.ManterPEA,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: PeaFormComponent,
			},
			{
				path: "fluxos-aprovacao",
				data: {
					recurso: EnumRecursoPerfil.Configuracoes,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: FluxoAprovacaoListComponent,
			},
			{
				path: "fluxos-aprovacao/novo",
				data: { recurso: EnumRecursoPerfil.Configuracoes },
				component: FluxoAprovacaoFormComponent,
			},
			{
				path: "fluxos-aprovacao/:id",
				data: {
					recurso: EnumRecursoPerfil.Configuracoes,
					acao: EnumAcaoRecurso.Editar,
				},
				component: FluxoAprovacaoFormComponent,
			},
			{
				path: "fluxos-aprovacao/:id/visualizar",
				data: { recurso: EnumRecursoPerfil.Configuracoes },
				component: FluxoAprovacaoFormComponent,
			},
			{
				path: "pea/:id/relatorio-simplificado",
				data: {
					recurso: EnumRecursoPerfil.ManterPEA,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: PeaRelatorioComponent,
			},
			{
				path: "pea/:id/relatorio-consolidado",
				data: {
					recurso: EnumRecursoPerfil.ManterPEA,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: PeaRelatorioComponent,
			},
			{
				path: "pea/:id/espelho-relatorio-consolidado",
				data: {
					recurso: EnumRecursoPerfil.ManterPEA,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: PeaRelatorioComponent,
			},
			{
				path: "rolresponsaveis",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: RolResponsaveisListComponent,
			},
			{
				path: "rolresponsaveis/relatorio",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: ImprimirRolResponsaveisComponent,
			},
			{
				path: "rolresponsaveis/relatorio/unidades-gestoras",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: ImprimirRolResponsavelUnidadeGestoraComponent,
			},
			{
				path: "rolresponsaveis/:id/responsaveis",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: RolResponsaveisFormComponent,
			},
			{
				path: "rolresponsaveis/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: RolResponsaveisFormComponent,
			},
			{
				path: "unidades-gestoras",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: UnidadeGestoraListComponent,
			},
			{
				path: "unidades-gestoras/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: UnidadeGestoraFormComponent,
				canDeactivate: [UnidadeGestoraGuard],
			},
			{
				path: "unidades-gestoras/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acoes: [EnumAcaoRecurso.Editar, EnumAcaoRecurso.Visualizar, , EnumAcaoRecurso.Consultar]
				},
				component: UnidadeGestoraFormComponent,
			},
			{
				path: "unidades-gestoras/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: UnidadeGestoraFormComponent,
			},
			{
				path: "questionario/pea",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: QuestionarioListComponent,
			},
			{
				path: "questionario/pea/:id/criterio/:criterioId/ug/:unidadeGestoraId",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: QuestionarioFormComponent,
				canDeactivate: [QuestionarioGuard],
			},
			{
				path: "questionario/pea/:id/criterio/:criterioId/ug/:unidadeGestoraId/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: QuestionarioFormComponent,
			},
			{
				path: "questionario/paa",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: QuestionarioListComponent,
			},
			{
				path: "questionario/paa/:id/criterio/:criterioId/ug/:unidadeGestoraId",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: QuestionarioFormComponent,
				canDeactivate: [QuestionarioGuard],
			},
			{
				path: "questionario/paa/:id/criterio/:criterioId/ug/:unidadeGestoraId/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: QuestionarioFormComponent,
			},
			{
				path: "parametrizacoes",
				data: {
					recurso: EnumRecursoPerfil.Configuracoes,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: ParametrizacaoListComponent,
			},
			{
				path: "",
				component: InicialComponent,
			},
			{
				path: "paa",
				data: {
					recurso: EnumRecursoPerfil.ManterPAA,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: PaaListComponent,
			},
			{
				path: "paa/novo",
				data: {
					recurso: EnumRecursoPerfil.ManterPAA,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: PaaElaborarComponent,
				canDeactivate: [PaaGuard],
			},
			{
				path: "paa/:id",
				data: {
					recurso: EnumRecursoPerfil.ManterPAA,
					acao: EnumAcaoRecurso.Editar,
				},
				component: PaaElaborarComponent,
			},
			{
				path: "paa/:id/editar-relatoria",
				data: {
					recurso: EnumRecursoPerfil.ManterPAA,
					acao: EnumAcaoRecurso.Editar,
				},
				component: PaaElaborarComponent,
			},
			{
				path: "paa/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.ManterPAA,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: PaaElaborarComponent,
			},
			{
				path: "pendencias",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: PendenciaListComponent,
			},
			{
				path: "opa",
				data: {
					recurso: EnumRecursoPerfil.ManterOPA,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: OpaListComponent,
			},
			{
				path: "opa/novo",
				data: {
					recurso: EnumRecursoPerfil.ManterOPA,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: OpaFormComponent,
			},
			{
				path: "opa/:id",
				data: {
					recurso: EnumRecursoPerfil.ManterOPA,
					acao: EnumAcaoRecurso.Editar,
				},
				component: OpaFormComponent,
			},
			{
				path: "opa/:id/nova-versao",
				data: {
					recurso: EnumRecursoPerfil.ManterOPA,
					acao: EnumAcaoRecurso.GerarNovaVersao,
				},
				component: OpaFormComponent,
			},
			{
				path: "opa/:id/editar-relatoria",
				data: {
					recurso: EnumRecursoPerfil.ManterOPA,
					acao: EnumAcaoRecurso.Editar,
				},
				component: OpaFormComponent,
			},
			{
				path: "opa/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.ManterOPA,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: OpaFormComponent,
			},
			{
				path: "opa/:id/relatorio",
				data: {
					recurso: EnumRecursoPerfil.ManterOPA,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: OpaRelatorioComponent,
			},
			{
				path: "opa/:id/relatorio-consolidado",
				data: {
					recurso: EnumRecursoPerfil.ManterOPA,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: OpaRelatorioComponent,
			},
			{
				path: "paa/:id/relatorio",
				data: {
					recurso: EnumRecursoPerfil.ManterPAA,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: PaaRelatorioComponent,
			},
			{
				path: "log",
				data: {
					recurso: EnumRecursoPerfil.LogsDoSistema,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: LogListComponent,
			},
			{
				path: "log/:id",
				data: {
					recurso: EnumRecursoPerfil.LogsDoSistema,
					acao: EnumAcaoRecurso.Detalhar,
				},
				component: LogFormComponent,
			},
			{
				path: "constatacao",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: ConstatacaoListComponent,
			},
			{
				path: "constatacao/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: ConstatacaoFormComponent,
			},
			{
				path: "constatacao/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: ConstatacaoFormComponent,
			},
			{
				path: "constatacao/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: ConstatacaoFormComponent,
			},
			{
				path: "questao",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: QuestaoListComponent,
			},
			{
				path: "questao/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: QuestaoFormComponent,
			},
			{
				path: "questao/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: QuestaoFormComponent,
				canDeactivate: [QuestaoGuard],
			},
			{
				path: "questao/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: QuestaoFormComponent,
			},
			{
				path: "paa/:id/espelho-relatorio",
				data: {
					recurso: EnumRecursoPerfil.ManterPAA,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: PaaRelatorioComponent,
			},
			{
				path: "opa/:id/espelho-relatorio",
				data: {
					recurso: EnumRecursoPerfil.ManterOPA,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: OpaRelatorioComponent,
			},
			{
				path: "osa/:id/espelho-relatorio",
				data: {
					recurso: EnumRecursoPerfil.ManterOSA,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: OsaImprimirComponent,
			},

			{
				path: "periodos/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: PeriodoFormComponent,
			},
			{
				path: "matriz-planejamento",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: MatrizPlanejamentoListComponent,
			},
			{
				path: "matriz-planejamento/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: MatrizPlanejamentoFormComponent,
			},
			{
				path: "matriz-planejamento/:id/edicao",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: MatrizPlanejamentoFormComponent,
				canDeactivate: [MatrizPlanejamentoGuard],
			},
			{
				path: "matriz-planejamento/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: MatrizPlanejamentoFormComponent,
			},
			{
				path: "matriz-planejamento/relatorio/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: MatrizPlanejamentoRelatorioComponent,
			},
			{
				path: "subtipo-auditoria",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: SubtipoAuditoriaListComponent,
			},
			{
				path: "subtipo-auditoria/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: SubtipoAuditoriaFormComponent,
			},
			{
				path: "subtipo-auditoria/:id/edicao",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: SubtipoAuditoriaFormComponent,
				canDeactivate: [SubtipoAuditoriaGuard],
			},
			{
				path: "subtipo-auditoria/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: SubtipoAuditoriaFormComponent,
			},
			{
				path: "planejamento",
				data: {
					recurso: EnumRecursoPerfil.Planejamento,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: PlanejamentoListComponent,
			},
			{
				path: "planejamento/:id/planejar",
				data: {
					recurso: EnumRecursoPerfil.Planejamento,
					acao: EnumAcaoRecurso.Planejar,
				},
				component: PlanejamentoFormComponent,
			},
			{
				path: "planejamento/:id/editar-relatoria",
				data: {
					recurso: EnumRecursoPerfil.Planejamento,
					acao: EnumAcaoRecurso.Planejar,
				},
				component: PlanejamentoFormComponent,
			},
			{
				path: "planejamento/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Planejamento,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: PlanejamentoFormComponent,
			},
			{
				path: "planejamento/:id/imprimir",
				data: {
					recurso: EnumRecursoPerfil.Planejamento,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: ImprimirComponent,
			},
			{
				path: "planejamento/:id/planejar/amostra/:amostraId/imprimir",
				data: {
					recurso: EnumRecursoPerfil.Planejamento,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: ImprimirAmostraComponent,
			},
			{
				path: ":tipoTrabalho/:id/solicitacao-tecnica/:solicitacaotecnicaId/imprimir",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: ImprimirSolicitacaoComponent,
			},
			{
				path: "osa/:id/editar-relatoria",
				data: {
					recurso: EnumRecursoPerfil.ManterOSA,
					acao: EnumAcaoRecurso.Editar,
				},
				component: ImprimirAmostraComponent,
			},
			{
				path: "osa/:id/planejar/amostra/:amostraId/imprimir",
				data: {
					recurso: EnumRecursoPerfil.ManterOSA,
					acao: EnumAcaoRecurso.Imprimir,
				},
				component: ImprimirAmostraComponent,
			},
			{
				path: "auditoria",
				data: {
					recurso: EnumRecursoPerfil.Auditoria,
					acao: EnumAcaoRecurso.Consultar,
				},
				component: AuditoriaListComponent,
			},
			{
				path: "solicitacao-tecnica",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: SolicitacaoTecnicaListComponent,
			},
			{
				path: "solicitacao-tecnica/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: SolicitacaoTecnicaFormComponent,
			},
			{
				path: "solicitacao-tecnica/:id/responder",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: SolicitacaoTecnicaFormComponent,
			},
			{
				path: "matriz-planejamento-visualizar/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: MatrizPlanejamentoVisualizarComponent,
			},
			{
				path: "tipo-enfase",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: TipoEnfaseListComponent,
			},
			{
				path: "tipo-enfase/novo",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: TipoEnfaseFormComponent,
				canDeactivate: [TipoEnfaseGuard],
			},
			{
				path: "tipo-enfase/:id",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: TipoEnfaseFormComponent,
			},
			{
				path: "tipo-enfase/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: TipoEnfaseFormComponent,
			},
			{
				path: "agente-publico",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: AgentePublicoListComponent,
			},
			{
				path: "agente-publico/:id",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: AgentePublicoFormComponent,
			},
			{
				path: "agente-publico/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: AgentePublicoFormComponent,
			},
			{
				path: "categoria-risco",
				data: {
					recurso: EnumRecursoPerfil.CadastroCategoriaRisco,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: CategoriaRiscoListComponent,
			},
			{
				path: "categoria-risco/novo",
				data: {
					recurso: EnumRecursoPerfil.CadastroCategoriaRisco,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: CategoriaRiscoFormComponent,
				canDeactivate: [CategoriaRiscoGuard],
			},
			{
				path: "categoria-risco/:id",
				data: {
					recurso: EnumRecursoPerfil.CadastroCategoriaRisco,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: CategoriaRiscoFormComponent,
			},
			{
				path: "categoria-risco/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.CadastroCategoriaRisco,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: CategoriaRiscoFormComponent,
			},

			{
				path: "riscos",
				data: {
					recurso: EnumRecursoPerfil.CadastroRiscos,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: RiscosListComponent,
			},
			{
				path: "riscos/novo",
				data: {
					recurso: EnumRecursoPerfil.CadastroRiscos,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: RiscosFormComponent,
				canDeactivate: [RiscosGuard],
			},
			{
				path: "riscos/:id",
				data: {
					recurso: EnumRecursoPerfil.CadastroRiscos,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: RiscosFormComponent,
				canDeactivate: [RiscosGuard],
			},
			{
				path: "riscos/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.CadastroRiscos,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: RiscosFormComponent,
			},
			{
				path: "modelo-questionario",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: ModeloQuestionarioListComponent,
			},
			{
				path: "modelo-questionario/novo",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: ModeloQuestionarioFormComponent,
			},
			{
				path: "modelo-questionario/:id",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: ModeloQuestionarioFormComponent,
			},
			{
				path: "modelo-questionario/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: ModeloQuestionarioFormComponent,
			},
			{
				path: "gerar-questionario",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: GerarQuestionarioComponent,
			},
			{
				path: "gerar-questionario-shared",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: GerarQuestionarioSharedComponent,
			},
			{
				path: "editar-entendendo-entidade/:id",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: EditarEntendendoEntidadeComponent,
			},
			{
				path: "visualizar-entendendo-entidade/:id",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: VisualizarEntendendoEntidadeComponent,
			},
			{
				path: "imprimir-entendendo-entidade/:id",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: ImprimirEntendendoEntidadeComponent,
			},
			{
				path: "importacao-arquivo-questionario",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: ImportacaoArquivoQuestionarioComponent,
			},
			{
				path: "unidades-gestoras/:id",
				data: {
					recurso: EnumRecursoPerfil.Cadastros,
					acao: EnumAcaoRecurso.Editar,
				},
				component: UnidadeGestoraFormComponent
			},
			{
				path: "visualizar-maturidade-de-risco/:id",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: VisualizarMaturidadeDeRiscoComponent,
			},
			{
				path: "questionario/pre-planejamento",
				data: { recurso: EnumRecursoPerfil.PrePlanejamento, acao: EnumAcaoRecurso.Visualizar},
				component: PrePlanejamentoListComponent
			},
			{
				path: "questionario/pre-planejamento-resposta/:id",
				data: { recurso: EnumRecursoPerfil.PrePlanejamento, acao: EnumAcaoRecurso.Editar},
				component: PrePlanejamentoRespostaFormComponent
			},
			{
				path: "questionario/pre-planejamento-resposta/:id/visualizar",
				data: { recurso: EnumRecursoPerfil.PrePlanejamento, acao: EnumAcaoRecurso.Visualizar},
				component: PrePlanejamentoRespostaFormComponent
			},
			{
				path: "questionario/pre-planejamento-resposta/:id/imprimir",
				data: { recurso: EnumRecursoPerfil.PrePlanejamento, 
					     acao: EnumAcaoRecurso.Imprimir,
						 imprimir: true},
				component: PrePlanejamentoRespostaFormComponent
			},
			{
				path: "editar-questionario/:id",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: EditarQuestionarioComponent,
			},
			{
				path: "visualizar-questionario/:id",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: VisualizarQuestionarioComponent,
			},
			{
				path: "importacao-arquivo-questionario-shared",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: ImportacaoArquivoQuestionarioSharedComponent,
			},
			{
				path: "imprimir-questionario/:id",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: ImprimirQuestionarioComponent,
			},
			{
				path: "avaliar-questionario/:id",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: AvaliarQuestionarioComponent,
			},
			{
				path: "tipo-universo",
				data: {
					recurso: EnumRecursoPerfil.Aberto,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: TipoUniversoListComponent,
			},
			{
				path: "tipo-universo/novo",
				data: {
					recurso: EnumRecursoPerfil.TipoUniversoAuditavel,
					acao: EnumAcaoRecurso.Inserir,
				},
				component: TipoUniversoFormComponent
			},
			{
				path: "tipo-universo/:id",
				data: {
					recurso: EnumRecursoPerfil.TipoUniversoAuditavel,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: TipoUniversoFormComponent
			},
			{
				path: "tipo-universo/:id/visualizar",
				data: {
					recurso: EnumRecursoPerfil.TipoUniversoAuditavel,
					acao: EnumAcaoRecurso.Visualizar,
				},
				component: TipoUniversoFormComponent
			},
		],
	},
	{ path: "**", redirectTo: "" },
];

@NgModule({
	declarations: [],
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
