import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IMatrizPlanejamentoModel } from 'src/app/models/matriz-planejamento.model';
import { ISubtipoAuditoriaModel } from 'src/app/models/subtipo-auditoria.model';
import { MatrizPlanejamentoService } from 'src/app/services/matriz-planejamento.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';

@Component({
  selector: 'app-matriz-planejamento-visualizar',
  templateUrl: './matriz-planejamento-visualizar.component.html',
  styleUrls: ['./matriz-planejamento-visualizar.component.scss'],
})
export class MatrizPlanejamentoVisualizarComponent implements OnInit {
  idParam: number;
  public lista: ISubtipoAuditoriaModel[] = new Array();

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private localeService: BsLocaleService,
    private planejamentoService: PlanejamentoService
  ) { }

  async ngOnInit() {
    this.localeService.use('pt-br');

    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.idParam = parseInt(id, 10);
    }

    await this.obterDados();
  }

  public async obterDados() {
    try {
      const id = this.route.snapshot.paramMap.get('id');
      const idParam = parseInt(id, 10);
      await this.planejamentoService.obterAbaMatrizPorIdImprimir(7, idParam).then((res) => {
        if (res.sucesso) {
          this.lista = res.dados;
          console.log(this.lista);
        }
      }
      );
    } catch (err) {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
      this.router.navigate([`/planejamento/${this.idParam}/planejar`]);
    }
  }


  print(): void {
    window.print();
  }

  public cancelar() {
    this.router.navigate([`/planejamento/${this.idParam}/planejar`]);
  }
}
