<div id="modal-content-a-equipe">
  <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="card-header bg-light" cdkDragHandle>
      <h4 class="card-title mb-0">Alocar Equipe</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-container p-3">
        <div class="row">
          <div class="form-group col-md-12">
            <label for="nome" class="mb-1">Unidade Gestora</label>
            <input type="text" id="nome" class="form-control form-control-sm" disabled [value]="
                concatUg(
                  model?.unidadeGestora?.sigla,
                  model?.unidadeGestora?.nome
                )
              " />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <label for="nome" class="mb-1">Tipo de Auditoria</label>
            <input type="text" id="nome" class="form-control form-control-sm" disabled
              [ngModel]="model?.tipoAuditoria?.nome" />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 was-validated" [ngClass]="{ 'was-validated': dataObrigatoria === true }">
            <label for="dataInicio" class="mb-1">Data Ínicio</label>
            <input id="dataInicio" [(ngModel)]="model.dataInicio" required bsDatepicker
              class="form-control form-control-sm suaClassDatePicker " [ngClass]="{'border border-danger': !dataValida() && model?.dataInicio && model?.dataFim}" [minDate]="minDate" [maxDate]="maxDate"
              maxlength="10" />
            <small class="text-danger" *ngIf="!dataValida() && model?.dataInicio && model?.dataFim">
              A data inicio não pode ser maior que a data fim
            </small>
          </div>
          <div class="form-group col-md-6 was-validated" [ngClass]="{ 'was-validated': dataObrigatoria === true }">
            <label for="dataFim" class="mb-1">Data Fim</label>
            <input id="dataFim" [(ngModel)]="model.dataFim" required bsDatepicker
              class="form-control form-control-sm suaClassDatePicker" [minDate]="minDate" [maxDate]="maxDate" [ngClass]="{'border border-danger': !dataValida() && model?.dataInicio && model?.dataFim}"
              maxlength="10" />
            <small class="text-danger" *ngIf="!dataValida() && model?.dataInicio && model?.dataFim">
              A data fim não pode ser menor que a data inicio
            </small>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mat-elevation-z1">
              <table mat-table [dataSource]="auditorDataSource" class="w-100" #tabela1="matSort"  matSort matSortDisableClear [hidden]="auditorSemDados">
                <tr mat-header-row *matHeaderRowDef="['selecao', 'nome']" class="bg-light"></tr>

                <ng-container matColumnDef="selecao">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()" color="primary" (click)="setDataObrigatoria()"
                      (change)="element.selecionado = $event.checked" [checked]="element.selecionado">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="nome">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Auditores da {{ model.tipoAuditoria?.coordenacao?.nome }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.nomeAuditor }}
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: ['selecao', 'nome']"></tr>
              </table>
              <h5 [hidden]="!auditorSemDados" class="table-no-data">
                Nenhum Auditor cadastrado
              </h5>
              <mat-paginator #TableOnePaginator="matPaginator" [hidden]="auditorSemDados" [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mat-elevation-z1">
              <table mat-table [dataSource]="auditorOutraCoordenacaoDataSource" #tabela2="matSort" matSort matSortDisableClear [hidden]="auditorOutraCoordenacaoSemDados">
                <tr mat-header-row *matHeaderRowDef="['selecao', 'nome']" class="bg-light"></tr>

                <ng-container matColumnDef="selecao">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation(); setDataObrigatoria()" color="primary"
                      (change)="element.selecionado = $event.checked" [checked]="element.selecionado">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="nome">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Auditores de outras Coordenações
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.nomeAuditor }}
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: ['selecao', 'nome']"></tr>
              </table>
              <h5 [hidden]="!auditorOutraCoordenacaoSemDados" class="table-no-data">
                Nenhuma Auditor cadastrado
              </h5>
              <mat-paginator #TableTwoPaginator="matPaginator" [hidden]="auditorOutraCoordenacaoSemDados" [pageSizeOptions]="[5]" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
          <i class="fas fa-check"></i> Ok
        </button>

        <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
          <i class="fas fa-times"></i> Cancelar
        </button>
      </div>
    </div>
  </div>
</div>
