import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-descricao-dias-uteis',
  templateUrl: './modal-descricao-dias-uteis.component.html',
  styleUrls: ['./modal-descricao-dias-uteis.component.scss']
})
export class ModalDescricaoDiasUteisComponent implements OnInit {
  ano: number;
  totalDiasAno: number;
  sabDom: number;
  ferNac: number;
  ferEst: number;
  ferMun: number;
  pontoFac: number;
  outros: number;
  totalDNU: number;
  diasUteis: number;
  treinamento: number;
  reunioes: number;
  licencasMedicas: number;
  ferias: number;
  abonos: number;
  outrasTarefas: number;
  totalDispensasPadrao: number;
  diasDisponiveisTotais: number;
  diasReservadosAuditoriasEspeciais: number;
  diasDisponiveis: number;
  auditoriasEspeciaisValor: number;
  auditoriaEspecialDD: number;
  du: number;
  public dispensasPadroes = [] as any[];

  constructor(
    private dialogRef: MatDialogRef<ModalDescricaoDiasUteisComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    // Inicialização das propriedades com os dados recebidos
    debugger
    this.ano = data.capacidade.ano;
    this.totalDiasAno = data.capacidade.totalDiasAno;
    this.sabDom = data.capacidade.diasNaoUteis.sabDom;
    this.ferNac = data.capacidade.diasNaoUteis.ferNac;
    this.ferEst = data.capacidade.diasNaoUteis.ferEst;
    this.ferMun = data.capacidade.diasNaoUteis.ferMun;
    this.pontoFac = data.capacidade.diasNaoUteis.pontoFac;
    this.outros = data.capacidade.diasNaoUteis.outros;
    this.totalDNU = data.capacidade.diasNaoUteis.totalDNU;
    this.diasUteis = data.capacidade.diasUteis;
    // this.treinamento = data.capacidade.dispensaPadrao.treinamento;
    // this.reunioes = data.capacidade.dispensaPadrao.reunioes;
    // this.licencasMedicas = data.capacidade.dispensaPadrao.licencasMedicas;
    // this.ferias = data.capacidade.dispensaPadrao.ferias;
    // this.abonos = data.capacidade.dispensaPadrao.abonos;
    // this.outrasTarefas = data.capacidade.dispensaPadrao.outrasTarefas;
    this.dispensasPadroes = data.capacidade.tipoDispensa;
    this.totalDispensasPadrao = data.capacidade.totalDispensasPadrao;
    this.diasDisponiveisTotais = data.capacidade.diasDisponiveisTotais;
    this.diasReservadosAuditoriasEspeciais = data.capacidade.diasReservadosAuditoriasEspeciais;
    this.diasDisponiveis = data.capacidade.diasDisponiveis;
    this.auditoriasEspeciaisValor = data.capacidade.auditoriasEspeciais.valor;
    this.du = data.capacidade.du;
    this.auditoriaEspecialDD = data.capacidade.auditoriaEspecialDD;
  }

  ngOnInit(): void {
    // Aqui você pode adicionar lógica adicional de inicialização, se necessário    
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  // Exemplo de método para verificar se um objeto está vazio
  public isObjectEmpty(obj: any): boolean {
    return obj === null || obj === undefined || Object.keys(obj).length === 0;
  }
}
