<!-- RANKING PRIORIDADE -->
<div class="row">
  <div class="col-md-12">
    <div class="mat-elevation-z1">
      <table
        mat-table
        [dataSource]="rankingPrioridadeDataSource"
        class="w-100"
        matSort
        #tabela1="matSort"
        matSortDisableClear
        [hidden]="semDadosRanking"
      >
        <ng-container matColumnDef="codigoUg">
          <th
            colspan="2"
            mat-header-cell
            class="text-left"
            *matHeaderCellDef
            mat-sort-header
          >
            Código UG
          </th>
          <td colspan="2" mat-cell *matCellDef="let element" class="text-left">
            {{ element?.codigoUg }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sigla">
          <th
            colspan="2"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-left"
          >
            Sigla UG
          </th>
          <td colspan="2" mat-cell *matCellDef="let element" class="text-left">
            {{ element?.sigla }}
          </td>
        </ng-container>

        <ng-container matColumnDef="nome">
          <th
            colspan="6"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-left"
          >
            Nome UG
          </th>
          <td colspan="6" mat-cell *matCellDef="let element" class="text-left">
            {{ element?.nome }}
          </td>
        </ng-container>

        <ng-container matColumnDef="valorRisco">
          <th
            colspan="2"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-center"
          >
            Valor Risco Inerente
          </th>
          <td colspan="2" mat-cell *matCellDef="let element" class="text-center">
            {{ element?.valorRisco }}
          </td>
        </ng-container>

        <ng-container matColumnDef="fatorRedutor">
          <th
            colspan="2"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-center"
          >
            Fator redutor
          </th>
          <td colspan="2" mat-cell *matCellDef="let element" class="text-center">
            {{ element?.fatorRedutor }}
          </td>
        </ng-container>
        <ng-container matColumnDef="valorRiscoResidual">
          <th
            colspan="2"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-center"
          >
            Valor risco residual
          </th>
          <td colspan="2" mat-cell *matCellDef="let element" class="text-center">
            {{ element?.valorRiscoResidual }}
          </td>
        </ng-container>


        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              type="button"
              class="btn btn-info btn-xs"
              (click)="exibirModalDetalharValorRisco(element)"
            >
              <i class="fas fa-eye"></i>
            </button>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsRanking"
          class="bg-light"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnsRanking"
          [className]="'ranking-' + row?.blocoPainel?.nome"
        ></tr>
      </table>
      <h5 [hidden]="!semDadosRanking" class="table-no-data">
        Sem Registros de Ranking
      </h5>
    </div>

    <h4 class="my-2">Empresas Públicas</h4>

    <div class="mat-elevation-z1">
      <table
        mat-table
        [dataSource]="rankingPrioridadeEmpresasPublicasDataSource"
        class="w-100"
        #tabela2="matSort"
        matSort
        matSortDisableClear
        matSortDirection="asc"
        [hidden]="semDadosRankingEmpresaPublica"
      >
        <ng-container matColumnDef="codigoUg">
          <th
            colspan="2"
            mat-header-cell
            class="text-left"
            *matHeaderCellDef
            mat-sort-header
          >
            Código UG
          </th>
          <td colspan="2" mat-cell *matCellDef="let element" class="text-left">
            {{ element?.codigoUg }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sigla">
          <th
            colspan="2"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-center"
          >
            Sigla UG
          </th>
          <td
            colspan="2"
            mat-cell
            mat-sort-header
            *matCellDef="let element"
            class="text-left"
          >
            {{ element?.sigla }}
          </td>
        </ng-container>

        <ng-container matColumnDef="nome">
          <th
            colspan="6"
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            class="text-center"
          >
            Nome UG
          </th>
          <td colspan="6" mat-cell *matCellDef="let element" class="text-left">
            {{ element?.nome }}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="valorRisco">
          <th
            colspan="2"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-right"
          >
            Valor Risco Inerente
          </th>
          <td colspan="2" 
              mat-cell 
              *matCellDef="let element" 
              style="text-align: end !important;">
            {{ element?.valorRisco }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              type="button"
              class="btn btn-info btn-xs"
              (click)="exibirModalDetalharValorRisco(element)"
            >
              <i class="fas fa-eye"></i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="fatorRedutor">
          <th
            colspan="2"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-right"
          >
            Fator redutor
          </th>
          <td colspan="2" mat-cell *matCellDef="let element" class="text-right">
            {{ element?.fatorRedutor }}
          </td>
        </ng-container>
        <ng-container matColumnDef="valorRiscoResidual">
          <th
            colspan="2"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-right"
          >
            Valor risco residual
          </th>
          <td colspan="2" mat-cell *matCellDef="let element" class="text-right">
            {{ element?.valorRiscoResidual }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsRanking"
          class="bg-light"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnsRanking"
          [className]="'ranking-' + row?.blocoPainel?.nome"
        ></tr>
      </table>
      <h5 [hidden]="!semDadosRankingEmpresaPublica" class="table-no-data">
        Sem Registros de Ranking
      </h5>
    </div>
  </div>
</div>
<div class="row p-2">
  <table class="legenda">
    <tr >
      <td class="pr-2"><strong>Legenda do risco:</strong></td>
      <td class="ranking-RiscoAlto"> Risco Alto </td>
      <td class="ranking-RiscoMedio"> Risco Médio </td>
      <td class="ranking-RiscoBaixo">Risco Baixo </td>
    </tr>
  </table>
</div>
<!-- Fim -->
