import { Component, OnInit, Inject } from '@angular/core';
import { ICustoRecursoPlanejamentoModel } from 'src/app/models/custo-recurso-planejamento.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import Inputmask from 'inputmask';
import { CustoRecurso } from './custo-recurso';
import { toFormatValue, toRemoveCharacters} from 'src/app/shared/util/toFormatValue';

@Component({
  selector: 'app-modal-custo-recurso-planejamento',
  templateUrl: './modal-custo-recurso-planejamento.component.html',
  styleUrls: ['./modal-custo-recurso-planejamento.component.scss']
})
export class ModalCustoRecursoPlanejamentoComponent implements OnInit {
  public modelCustoRecursoPlanejamento!: any;

  constructor(
    private dialogRef: MatDialogRef<ModalCustoRecursoPlanejamentoComponent>,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) private data) {
    this.modelCustoRecursoPlanejamento = new CustoRecurso(this.data.id, this.data.nome, this.data.valorUnitario, Number(this.data.quantidade));
  }

  ngOnInit(): void {
  }

  formatarInput(value) {
    return toFormatValue(value);
  }

  public concluir() {
    this.dialogRef.close(this.modelCustoRecursoPlanejamento);
  }

  public cancelar() {
    this.dialogRef.close(false);
  }
}

