import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Inputmask from 'inputmask';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ModalVisualizarHistoricoComponent } from './modal-visualizar-historico/modal-visualizar-historico.component';
import { IHistoricoRelatoriaCapituloModel } from 'src/app/models/historico-relatoria-capitulo.model';
import { RelatoriaCapituloService } from 'src/app/services/relatoria-capitulo.service';

@Component({
  selector: 'app-modal-historico-relatoria',
  templateUrl: './modal-historico-relatoria.component.html',
  styleUrls: ['./modal-historico-relatoria.component.scss'],
})

export class ModalHistoricoRelatoriaComponent
  implements OnInit, AfterViewInit {

  constructor(
    private dialogRef: MatDialogRef<ModalHistoricoRelatoriaComponent>,
    public matDialog: MatDialog,
    public relatoriaCapituloService: RelatoriaCapituloService,

    @Inject(MAT_DIALOG_DATA) public data) {
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    Inputmask().mask(document.querySelectorAll('input'));
  }

  public modalVisualizarHistorico(element: IHistoricoRelatoriaCapituloModel) {

    try {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-visualizar-historico';
      dialogConfig.width = '800px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        historico: element,
      };

      const modal = this.matDialog.open(ModalVisualizarHistoricoComponent, dialogConfig);
      modal.afterClosed().subscribe((data) => {
        if (data) {
        }
      });
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    } 
  }

  validarExibicao(element: IHistoricoRelatoriaCapituloModel): boolean{
    return element.status.descricao != 'Inclusão';
  }


  public fechar() {
    this.dialogRef.close();
  }

}
