import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { IFasesAtividadeModel } from 'src/app/models/fases-atividade-model';
import { FaseAtividadeService } from 'src/app/services/fase-atividade.service';
import Swal from 'sweetalert2';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';

@Component({
  selector: 'app-fase-atividade-form',
  templateUrl: './fase-atividade-form.component.html',
  styleUrls: ['./fase-atividade-form.component.scss']
})
export class FaseAtividadeFormComponent extends BaseFormComponent implements OnInit {
  public model:IFasesAtividadeModel = {} as IFasesAtividadeModel;
  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
    quantidadeDias: new FormControl('', Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private faseAtividadeService: FaseAtividadeService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Fase de Atividade';
    }
  }


  async ngOnInit() {
    try {
      if (!this.novoRegistro) {
        const res = await this.faseAtividadeService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;

          if (this.visualizar) {
            this.titulo = 'Visualizar Fase de atividade - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Fase de atividade - ' + this.model.nome;
          }

        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
          this.router.navigate(['/fase-atividade']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
      this.router.navigate(['/fase-atividade']);
    } 
  }

  public async salvarDados(salvarEFechar: boolean)
  {
    this.submit = true;
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }

    this.atualizarModel(this.form.value);

    try {
      let res: IBaseModel<void> = null;

      if (!this.novoRegistro) {
        res = await this.faseAtividadeService.atualizar(this.model);
      } else {
        res = await this.faseAtividadeService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: 'success',
          text: res.mensagem.descricao
        });

        if(salvarEFechar == true)
          this.router.navigate(['/fase-atividade']);
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    }
    catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public async onSubmit() {
    this.salvarDados(false);
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  
  }

  public onBack() {
    this.submit = false;
    this.router.navigate(['/fase-atividade']);
  }

  public cancelar() {
    this.router.navigate(['/fase-atividade']);
  }
}
