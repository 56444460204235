import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { ICapituloRelatorioModel } from 'src/app/models/capitulo-relatorio.model';
import { IEnumModel } from 'src/app/models/enum.model';
import { CapituloRelatorioService } from 'src/app/services/capitulo-relatorio.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-modal-capitulo',
  templateUrl: './modal-capitulo.component.html',
  styleUrls: ['./modal-capitulo.component.scss'],
})
export class ModalCapituloComponent implements OnInit {
  public model: ICapituloRelatorioModel = {} as ICapituloRelatorioModel;
  public enumTipo = [] as IEnumModel[];
  public novoRegistro = true;
  public id: number;
  public keywords = ['Período', 'Indicador', 'UGs', 'Coordenações', 'Capacidade de trabalho', 'Auditores da Coordenação', 'Distribuição Contempladas', 'Ranking de Distribuição', 'Dados', 'Carga dos Critérios', 'Ranking de Distribuição'] as string[];
  public keywordsPaa = ['Período do PEAC', 'Indicadores do PAAC corrente', 'UGs do PAAC corrente', 'Coordenações do PAAC corrente', 'Capacidade de trabalho do PAAC corrente',
    'Auditores da Coordenação do PAAC corrente', 'Distribuição Contempladas do PEAC', 'Ranking de Distribuição do PAAC corrente', 'Indicadores e Critérios do PAAC corrente',
    'Painel de Distribuição do PAAC corrente', 'Auditorias inativas no Painel de Distribuição com respectiva justificativa', 'Auditorias por Tipo e Coordenação e UGs',
    'Alocação da Equipe', 'Calendário do PAAC corrente', 'Capacidade total PAAC', 'Capacidade total PEAC'] as string[];
  public keywordsOpa = ['Tipo de auditoria', 'Origem', 'Unidade auditada', 'Objeto', 'Período', 'Coordenador', 'Auditores do OPA', 'Subtipo de Auditoria do OPA', 'Nota de rodapé'] as string[];
  public keywordsPlanejamento = ['Auditores do Planejamento', 'Subtipo de Auditoria do Planejamento', 'UGs do Planejamento', 'Informações Básicas'] as string[];
  public keywordsOsa = ['Auditores do OSA', 'Subtipo de Auditoria do OSA'] as string[];
  public titulo;

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    tipoId: new FormControl(null, Validators.required),
    conteudo: new FormControl('', Validators.required),
    tags: new FormControl(null)
  });

  constructor(
    public route: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    public localeService: BsLocaleService,
    private tabelaDominioService: TabelaDominioService,
    private capituloRelatorioService: CapituloRelatorioService,
    private dialogRef: MatDialogRef<ModalCapituloComponent>,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (data.editar && data.element) {
      this.model = data.element;
      this.form.patchValue(this.model);
    }

    if (data.subCapitulo) {
      this.titulo = 'Subcapítulo';
    }
    else {
      this.titulo = 'Capítulo';
    }
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      // ['bold']
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async ngOnInit() {
    try {
      if (!this.novoRegistro) {
        const res = await this.capituloRelatorioService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;
        } else {
          this.toastr.error(res.mensagem.descricao, 'Atenção');
          this.router.navigate(['/capitulos-relatorio']);
          return;
        }
      }

      await this.tabelaDominioService.obterTipoCapituloRelatorio().then((res) => this.enumTipo = res.dados),

        this.form.patchValue(this.model);
    } catch (err) {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
      this.router.navigate(['/capitulos-relatorio']);
    }
  }

  public concluir() {
    if(this.form.invalid)
      return;

    this.atualizarModel(this.form.value);
    this.model.tipo = this.enumTipo.find(_ => _?.codigo === parseInt(this.model.tipoId.toString(), null));
    this.dialogRef.close(this.model);
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public cancelar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { interno: true };
    this.matDialog.open(ModalCancelarComponent, dialogConfig).afterClosed();
  }

  addKeywordFromInput(event: MatChipInputEvent) {
    if (event.value) {
      if (this.keywords.includes(event.value)) {
        this.toastr.error('Tag já adicionada ao capítulo.', 'Atenção');
      } else {
        this.keywords.push(event.value);
      }
    }
  }

  addTag(keyword: string) {
    this.atualizarModel(this.form.value);
    this.model.conteudo += `{{${keyword}}}`;
    this.form.patchValue(this.model);
  }
}
