import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { CapituloRelatorioService } from 'src/app/services/capitulo-relatorio.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { MatChipInputEvent } from '@angular/material/chips';
import { IRelatoriaCapituloModel } from 'src/app/models/relatoria-capitulo.model';
import { RelatoriaCapituloService } from 'src/app/services/relatoria-capitulo.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-relatoria-capitulo',
  templateUrl: './modal-relatoria-capitulo.component.html',
  styleUrls: ['./modal-relatoria-capitulo.component.scss'],
})
export class ModalRelatoriaCapituloComponent implements OnInit {
  public keywords = ['Período', 'Indicador', 'UGs', 'Coordenações', 'Capacidade de trabalho', 'Auditores da Coordenação', 'Distribuição Contempladas', 'Ranking de Distribuição'] as string[];
  public keywordsPaaPadrao = ['Período do PEAC', 'Indicadores do PAAC corrente', 'UGs do PAAC corrente', 'Coordenações do PAAC corrente', 'Capacidade de trabalho do PAAC corrente',
    'Auditores da Coordenação do PAAC corrente', 'Distribuição Contempladas do PEAC', 'Ranking de Distribuição do PAAC corrente', 'Indicadores e Critérios do PAAC corrente',
    'Painel de Distribuição do PAAC corrente', 'Auditorias inativas no Painel de Distribuição com respectiva justificativa', 'Auditorias por Tipo e Coordenação e UGs',
    'Alocação da Equipe', 'Calendário do PAAC corrente', 'Capacidade total PAAC', 'Capacidade total PEAC'] as string[];
  public keywordsOpa = ['Tipo de auditoria', 'Origem', 'Unidade auditada', 'Objeto', 'Período', 'Coordenador', 'Auditores do OPA', 'Subtipo de Auditoria do OPA', 'Tipo de Ênfase',
     'Data da Apresentação do Planejamento', 'Quantidade de dias Úteis', 'Data prevista Início', 'Data prevista Fim', 'Data Início de Realização', 'Data Fim de Realização', 'Número da Opa'] as string[];
  public keywordsOpaSub = ['Tipo de auditoria', 'Origem', 'Unidade auditada', 'Objeto', 'Período', 'Coordenador', 'Auditores do OPA', 'Subtipo de Auditoria do OPA'] as string[];
  public keywordsPlanejamento = ['Auditores do Planejamento', 'Subtipo de Auditoria do Planejamento', 'UGs do Planejamento'] as string[];
  public keywordsOsa = ['Auditores do OSA', 'Subtipo de Auditoria do OSA'] as string[];
  public titulo;
  public keywordsPaa = this.keywordsPaaPadrao;
  public keywordsPaaEspecial = this.keywordsPaaPadrao;
  public model: IRelatoriaCapituloModel = {} as IRelatoriaCapituloModel;
  public subCapituloPodeInserirNaBase = false;
  public isFormValid: boolean = false; // Variável para controlar a habilitação do botão de envio

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    conteudo: new FormControl('', Validators.required),
    tags: new FormControl(null)
  });

  constructor(
    public route: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    public localeService: BsLocaleService,
    private tabelaDominioService: TabelaDominioService,
    private capituloRelatorioService: CapituloRelatorioService,
    private relatoriaCapituloService: RelatoriaCapituloService,
    private dialogRef: MatDialogRef<ModalRelatoriaCapituloComponent>,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (data.subCapitulo) {
      this.titulo = 'Subcapítulo';
      this.subCapituloPodeInserirNaBase = data.element?.capituloRelatorioId > 0;
    }
    else {
      this.titulo = 'Capítulo';
    }

  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      // ['bold']
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async ngOnInit() {
    this.form.get('nome').valueChanges.subscribe(() => this.updateFormValidity());
    this.form.get('conteudo').valueChanges.subscribe(() => this.updateFormValidity());

    this.updateFormValidity(); // Certifique-se de que a validade do formulário seja atualizada inicialmente
  }

  private updateFormValidity() {
    this.isFormValid = this.form.controls['nome'].valid && this.form.controls['conteudo'].valid;
  }

  public concluir() {

    if (!this.isFormValid) {
      this.toastr.warning('Por favor, preencha todos os campos obrigatórios!', 'Atenção');
      return;
    }
    
    if (!this.data) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'Capítulo/Sub-Capítulo de referência não encontrado.',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }

    if (this.titulo == 'Subcapítulo' && !this.subCapituloPodeInserirNaBase) {
      this.salvar(false);
    }
    if(this.titulo == 'Subcapítulo'){
      Swal.fire({
        title: 'Inclusão de SubCapítulo!',
        text: 'Deseja incluir o SubCapítulo no modelo padrão do relatório? \nAo confirmar, sua sugestão será enviada ao usuário responsável para avaliação.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(res => {
        this.salvar(res.value);
      })
    }
    else {
      Swal.fire({
        title: 'Inclusão de capítulo!',
        text: 'Deseja incluir o Capítulo no modelo padrão do relatório? \nAo confirmar, sua sugestão será enviada ao usuário responsável para avaliação.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(res => {
        this.salvar(res.value);
      })
    }
  }

  public salvar(incluirNaBase) {
    this.atualizarModel(this.form.value);
    const eSubCapitulo = this.data?.element?.relatoriaCapituloPaiId > 0;

    const newCapitulo = {
      conteudo: this.model.conteudo,
      nome: this.model.nome,
      numero: this.data.element.numero + 1,
      relatoriaId: this.data?.element.relatoriaId ?? null,
    } as IRelatoriaCapituloModel;

    if (this.data.subCapitulo) {
      newCapitulo.relatoriaCapituloPaiId = eSubCapitulo ? this.data.element.relatoriaCapituloPaiId : this.data.element.id;
      newCapitulo.relatoriaId = null;
      if (this.data.element.relatoriaCapituloPaiId) {
        newCapitulo.numero = (this.data.element?.numero ?? 0) + 1;
      } else {
        newCapitulo.numero = (this.data.element?.subRelatoriaCapitulos[this.data.element.subRelatoriaCapitulos.length - 1]?.numero ?? 0) + 1;
      }
    }

    if (incluirNaBase) {
      newCapitulo.novoCapituloModeloRelatorio = true;
    }

    this.relatoriaCapituloService.salvar(newCapitulo, this.data.tipoTrabalho, this.data.id)
      .then(res => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.dialogRef.close(this.model);
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      }).catch(err => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
  }

  public atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public cancelar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { interno: true };
    this.matDialog.open(ModalCancelarComponent, dialogConfig).afterClosed();
  }

  addKeywordFromInput(event: MatChipInputEvent) {
    if (event.value) {
      if (this.keywords.includes(event.value)) {
        this.toastr.error('Tag já adicionada ao capítulo.', 'Atenção');
      } else {
        this.keywords.push(event.value);
      }
    }
  }

  addTag(keyword: string) {
    this.atualizarModel(this.form.value);
    this.model.conteudo += `{{${keyword}}}`;
    this.form.patchValue(this.model);
  }
}
