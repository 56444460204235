import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-editar-questao',
  templateUrl: './modal-editar-questao.component.html',
  styleUrls: ['./modal-editar-questao.component.scss']
})
export class ModalEditarQuestaoComponent implements OnInit {

  public titulo: string = 'Editar questão';

  constructor() { }

  ngOnInit(): void {
  }

  public cancelar() {

  }




}
