import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { PaginacaoModel } from '../models/paginacao.model';
import { FiltroModel } from '../models/filtro.model';
import { BaseService } from './base.service';
import { filterBy } from '../core/helpers/filterby';
import { IEntendendoEntidadeListaModel } from '../models/entendendo-entidade-lista.model';
import { FiltroQuestionarioUG } from '../models/filtro-questionario-ug.model';
import { AvaliacaoRequest, IImprimirQuestionarioRespostaUGModel, IListaQuestionarioRespostaUGModel, IQuestionarioUGModel, IVisualizarQuestionarioRespostaUGModel } from '../models/questionario-ug.model';
import { IArquivoModel } from '../models/arquivo.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionarioUGRespostaService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public async ObterRespostaQuestionario(id: number): Promise<IBaseModel<IListaQuestionarioRespostaUGModel>>{
    return this.httpClient
                .get<IBaseModel<IListaQuestionarioRespostaUGModel>>(`${this.apiBaseUrl}/QuestionarioUGResposta/resposta/${id}`)
                .toPromise();
  }

  public async salvarRespostasQuestionarioPorId(id: number, data: IListaQuestionarioRespostaUGModel[]): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/QuestionarioUGResposta/salvar/${id}`, data)
      .toPromise();
  }

  public async enviarRespostasQuestionarioPorId(id: number, data: IListaQuestionarioRespostaUGModel[]): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/QuestionarioUGResposta/enviar/${id}`, data)
      .toPromise();
  }

  public async anexarArquivo(idItemQuestionarioUG: number,  anexo : IArquivoModel): Promise<IBaseModel<void>> {
    const formData: FormData = new FormData();

    formData.append('id', anexo.id.toString());
    formData.append('nomeArquivo', anexo.nomeArquivo);
    formData.append('entidadeArquivo', anexo.entidadeArquivo.toString());
    formData.append('arquivo', anexo.arquivo);

    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/QuestionarioUGResposta/anexarArquivo/${idItemQuestionarioUG}`,formData, {})
      .toPromise();
  }

  public DelegarQuestionario(
    questionarioItemsId: string,
    idResponsavel: string
  ): Promise<IBaseModel<any>> {
    return this.httpClient
      .put<IBaseModel<boolean>>(
        `${this.apiBaseUrl}/QuestionarioUGResposta/questionario/${questionarioItemsId}/responsavel/${idResponsavel}`,
        {}
      )
      .toPromise();
  }

  public FinalizarPerguntaQuestionario(
    questionarioItemsId: string
  ): Promise<IBaseModel<any>> {
    return this.httpClient
      .put<IBaseModel<boolean>>(
        `${this.apiBaseUrl}/QuestionarioUGResposta/finalizarperguntaquestionario/${questionarioItemsId}`,
        {}
      )
      .toPromise();
  }

  public EnviarReplicaQuestionario(
    questionarioItemsId: string,
    replica: string,
    tiporeplica: number
  ): Promise<IBaseModel<any>> {
    return this.httpClient
      .put<IBaseModel<boolean>>(
        `${this.apiBaseUrl}/QuestionarioUGResposta/responder-replica/${questionarioItemsId}/replica/${replica}/tiporeplica/${tiporeplica}`,
        {}
      )
      .toPromise();
  }

  fazerAvaliacaoOuReplica(
    questionarioItemsId: string,
    avaliacaoDto: AvaliacaoRequest
  ): Promise<IBaseModel<any>> {
    return this.httpClient.put<IBaseModel<boolean>>(
      `${this.apiBaseUrl}/QuestionarioUGResposta/avaliacao/${questionarioItemsId}`,
      avaliacaoDto
    ).toPromise();
  }

  enviarParaReplica(
    questionarioId: number
  ): Promise<IBaseModel<any>> {
    return this.httpClient.put<IBaseModel<boolean>>(
      `${this.apiBaseUrl}/QuestionarioUGResposta/enviar-para-replica/${questionarioId}`, null
    ).toPromise();
  }

  FinalizarQuestionarioAvaliacao(
    questionarioId: number
  ): Promise<IBaseModel<any>> {
    return this.httpClient.put<IBaseModel<boolean>>(
      `${this.apiBaseUrl}/QuestionarioUGResposta/finalizar-questionario-avaliacao/${questionarioId}`, null
    ).toPromise();
  }


}
