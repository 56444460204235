import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { IPaaGraficoCoordenacaoModel } from 'src/app/models/paa-grafico-coordenacao.model';
import { IPaaGraficoModel } from 'src/app/models/paa-grafico.model';
import { TemplateImpressao } from './template-impressao';

@Component({
  selector: 'app-modal-grafico-distribuicao',
  templateUrl: './modal-grafico-distribuicao.component.html',
  styleUrls: ['./modal-grafico-distribuicao.component.scss'],
})
export class ModalGraficoDistribuicaoComponent implements  OnChanges, OnInit {
  public dados = [] as IPaaGraficoModel[];
  public coordenacoes = [];
  @Input() public dadosImprimir: IPaaGraficoModel[] = [] as IPaaGraficoModel[];

  constructor(
    public matDialog: MatDialog,
    private dialogRef: MatDialogRef<ModalGraficoDistribuicaoComponent>,
    @Inject(MAT_DIALOG_DATA) data: IPaaGraficoModel[]
  ) {
    if (data[0]) {
      this.dados = data.sort(function (a, b) {
      return a.siglaUnidadeGestora.toUpperCase() < b.siglaUnidadeGestora.toUpperCase() ? -1 : a.siglaUnidadeGestora.toUpperCase() > b.siglaUnidadeGestora.toUpperCase() ? 1 : 0;
    });}
  }

  ngOnInit(): void {
    if (this.dados[0]) {
      this.ordenarCoordenacoes();
    }
  }

  ngOnChanges(): void {
    if (Object.keys(this.dadosImprimir[0] ?? {}).length !== 0) {
      this.dados = this.dadosImprimir.sort(function (a, b) {
        return a.siglaUnidadeGestora.toUpperCase() < b.siglaUnidadeGestora.toUpperCase() ? -1 : a.siglaUnidadeGestora.toUpperCase() > b.siglaUnidadeGestora.toUpperCase() ? 1 : 0;
      });
    }
    if (this.dados[0]) {
      this.ordenarCoordenacoes();
    }
  }


  public ordenarCoordenacoes() {
    this.coordenacoes = this.removeDuplicates(this.dados.map(_ => _.paaGraficoCoordenacoes).reduce((a, b) => a.concat(b)), 'siglaCoordenacao');
  }

  public removeDuplicates(originalArray: IPaaGraficoCoordenacaoModel[], prop: string) {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  public ObterEstiloCoordenacao(
    coordenacao: IPaaGraficoCoordenacaoModel
  ): string {
    const dataInicio = new Date(coordenacao?.dataInicio);
    const dataFim = new Date(coordenacao?.dataFim);

    const coordColor = this.stringToColor(coordenacao?.nomeCoordenacao);

    if (dataInicio.getFullYear() < dataFim.getFullYear()) {
      return (
        'grid-column: ' +
        (dataInicio.getMonth() + 1) +
        '/13' +
        '; background-color: ' +
        coordColor +
        ';'
      );
    }

    return (
      'grid-column: ' +
      (dataInicio.getMonth() + 1) +
      '/' +
      (dataFim.getMonth() + 2) +
      '; background-color: ' +
      coordColor +
      ';'
    );
  }

  public ObterEstiloLegendaCoordenacao(
    coordenacao: IPaaGraficoCoordenacaoModel
  ): string {
    const coordColor = this.stringToColor(coordenacao.nomeCoordenacao);

    return (
      'background-color: ' +
      coordColor +
      '; margin-bottom: 0.5rem; width: 50%'
    );
  }

  public obterDescricaoCoordenacao(
    coordenacao: IPaaGraficoCoordenacaoModel
  ): string {
    if (!coordenacao) {
      return '';
    }

    const dataInicio = new Date(coordenacao.dataInicio);
    const dataFim = new Date(coordenacao.dataFim);

    return (
      'Coordenação: ' +
      coordenacao?.nomeCoordenacao +
      ' \n' +
      'Data Início: ' +
      dataInicio?.toLocaleDateString() +
      ' \n' +
      'Data Fim: ' +
      dataFim?.toLocaleDateString() +
      ' \n' +
      'Auditores: ' +
      coordenacao?.nomeAuditores +
      ' \n' +
      'Tipo de Auditoria:' +
      coordenacao?.tipoAuditoria
    );
  }

  public obterDescricaoCoordenacaoLegenda(
    coordenacao: IPaaGraficoCoordenacaoModel
  ): string {
    if (!coordenacao) {
      return '';
    }

    return (
      'Coordenação: ' +
      coordenacao?.nomeCoordenacao 
    );
  }

  public stringToColor(text: string): string {
    let hash = 0;
    if (!text || text.length === 0) {
      return hash.toString();
    }
    for (let i = 0; i < text.length; i++) {
      // tslint:disable-next-line: no-bitwise
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
      // tslint:disable-next-line: no-bitwise
      hash = hash & hash;
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      // tslint:disable-next-line: no-bitwise
      const value = (hash >> (i * 8)) & 255;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }

  public imprimir(idHtmlName) {
    var conteudo = document.getElementById(idHtmlName).innerHTML, tela_impressao = window.open('about:blank');

    let template = TemplateImpressao(conteudo)

    tela_impressao.document.write(template);
    tela_impressao.window.print();
    tela_impressao.window.close();
  }

  public cancelar() {
    this.dialogRef.close();
  }
}
