export class CustoRecurso{ 
  public nome!: string; 
  public valorUnitario!: number;
  public quantidade!: number;
  public id!: number;

  constructor(id,  nome, valorUnitario, quantidade){
    this.id = id;
    this.nome = nome
    this.valorUnitario = Number(valorUnitario);
    this.quantidade = quantidade;
  }
}