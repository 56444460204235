import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { ITipoEnfaseModel } from 'src/app/models/tipo-enfase.model';
import { AuthService } from 'src/app/services/auth.service';
import { TipoEnfaseService } from 'src/app/services/tipo-enfase.service';
import { FiltroModel } from 'src/app/models/filtro.model';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';

const sortingDataAccessor = (item, property) => {
  switch (property) {
    case 'quantDiasPadrao':
      return item.quantidadeDiasPadrao;
    case 'nome': return item.nome.trim().toLowerCase();    
    default:
      return item[property];
  }
};

@Component({
  selector: 'app-tipo-enfase-list',
  templateUrl: './tipo-enfase-list.component.html',
  styleUrls: ['./tipo-enfase-list.component.scss']
})
export class TipoEnfaseListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<ITipoEnfaseModel>([]);
  public selectedModel: ITipoEnfaseModel;
  public displayedColumns: string[] = ['selecao', 'nome', 'ativo'];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;
  public permissoes: IPermissaoModel;
  public filtro = {} as FiltroModel;
  public totalItens: number;
  public pageEvent: any;

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private tipoEnfaseService: TipoEnfaseService,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService
  ) {
    super();
    this.buscar();
  }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Visualizar);
 }

  ngAfterViewInit() {
    this.configurarFiltro();
    this.buscar();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
  }

  public novo() {
    this.router.navigate(['/tipo-enfase/novo']);
  }

  public atualizarFiltroStatus(valor) {
    this.filtro = {} as FiltroModel;
    this.searchAtivo = valor;
    this.buscar();
  }

  public buscar() {
    this.selectedModel = null;
    this.filtro.pesquisa = this.searchNome;
    this.tipoEnfaseService
      .obter(this.filtro)
      .then((res) => {
        this.totalItens = res.dados.totalItens;
        this.dataSource = new MatTableDataSource<any>(res.dados.resultado);
        this.totalItens = res.dados.totalItens;
        this.paginator = this.tableOnePaginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = sortingDataAccessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
        this.configurarFiltro();
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
  }

  public reativar(id: number) {
    this.tipoEnfaseService
      .reativar(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Tipo de ênfase reativado com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
      .finally(() => {
        this.buscar();
      });
  }

  public inativar(id: number) {
    this.tipoEnfaseService
      .inativar(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Tipo de ênfase inativado com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
      .finally(() => {
        this.buscar();
      });
  }

  public editar(id: number) {
    this.router.navigate([`/tipo-enfase/${id}`]);
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }

  public excluir(id: number) {
    this.tipoEnfaseService
      .excluir(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Tipo de ênfase excluído com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          showConfirmButton: false,
          timer: 5000
        });
      })
      .finally(() => {
        this.buscar();
      });
  }

  public visualizar(id: number) {
    this.router.navigate([`/tipo-enfase/${id}/visualizar`]);
  }

  private configurarFiltro() {
    debugger
    this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
    this.filtro.itensPorPagina = this.tableOnePaginator.pageSize;
    this.filtro.colunaOrder = this.sort.active;
    this.filtro.ativo = this.searchAtivo;
    this.filtro.pesquisa = this.searchNome;
    this.filtro.direcao = this.sort.direction;
  }
  sortData(sort) {
    this.filtro.colunaOrder = sort.active;
    this.filtro.direcao = sort.direction;

    this.buscar();
}

  pageChanged(e) {
    this.filtro.pagina = e.pageIndex + 1;
    e.pageIndex = this.filtro.pagina;
    this.filtro.itensPorPagina = e.pageSize;
    this.buscar();
  }
}
