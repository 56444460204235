<div id="modal-content-wrapper">
	<div class="card card-secondary card-outline">
		<div class="card-header bg-light">
			<h4 class="card-title mb-0">Definir Capacidade de Trabalho</h4>
			<div class="card-tools">
				<button type="button" class="btn btn-tool" (click)="cancelar()">
					<i class="fas fa-times"></i>
				</button>
			</div>
		</div>
		<div class="card-body p-0">
			<div class="table-container p-3">
				<div class="row">
					<div class="form-group col-md-12">
						<label for="coordenacaoNome" class="mb-1"
							>Coordenação</label
						>
						<input
							type="text"
							id="coordenacaoNome"
							class="form-control form-control-sm"
							disabled
							[ngModel]="model?.coordenacaoNome"
						/>
					</div>
				</div>

				<div class="row">
					<div class="form-group col-md-6">
						<label for="selectAuditor" class="mb-1">Auditor</label>
						<select
							id="selectAuditor"
							#selectAuditor
							[(ngModel)]="modelNovaDispensa.auditorId"
							class="form-control form-control-sm"
						>
							<option
								*ngFor="let item of tabelaAuditores"
								[ngValue]="item.id"
							>
								{{ item.nomeAuditor }}
							</option>
						</select>
					</div>

					<div class="form-group col-md-6">
						<label for="selectTipoDispensa" class="mb-1"
							>Tipo de Dispensa</label
						>
						<select
							id="selectTipoDispensa"
							#selectTipoDispensa
							[(ngModel)]="modelNovaDispensa.tipoDispensaId"
							class="form-control form-control-sm"
						>
							<option
								*ngFor="let item of tabelaTipoDispensa"
								[ngValue]="item.id"
							>
								{{ item.nome }}
							</option>
						</select>
					</div>
				</div>

				<div class="row">
					<div class="form-group col-md-4">
						<label for="dataInicio" class="mb-1">Data Início</label>
						<input
							[(ngModel)]="modelNovaDispensa.dataInicio"
							type="text"
							id="dataInicio"
							required
							bsDatepicker
							class="
								form-control form-control-sm
								suaClassDatePicker
								dateFormat
							"
							[bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
							(bsValueChange)="UpdateDataInicio($event)"
						/>
					</div>

					<div class="form-group col-md-4">
						<label for="dataFim" class="mb-1">Data Fim</label>
						<input
							[(ngModel)]="modelNovaDispensa.dataFim"
							type="text"
							required
							id="dataFim"
							bsDatepicker
							[bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
							class="
								form-control form-control-sm
								suaClassDatePicker
								dateFormat
							"
							(bsValueChange)="UpdateDataFim($event)"
						/>
					</div>
					<div class="form-group col-md-4">
						<button
							type="button"
							class="btn btn-info ml-1 col-md-12 mt-4"
							(click)="incluirDispensa()"
							[disabled]="
								!modelNovaDispensa.auditorId ||
								!modelNovaDispensa.tipoDispensaId ||
								!modelNovaDispensa.dataInicio ||
								!modelNovaDispensa.dataFim ||
								modelNovaDispensa.dataFim <=
									modelNovaDispensa.dataInicio
							"
						>
							<i class="fas fa-plus"></i> Adicionar
						</button>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<div class="mat-elevation-z1">
							<table mat-table [dataSource]="dispensaDataSource">
								<tr
									mat-header-row
									*matHeaderRowDef="[
										'auditor',
										'nome',
										'dias',
										'periodo',
										'actions'
									]"
									class="bg-light"
								></tr>

								<ng-container matColumnDef="auditor">
									<th mat-header-cell *matHeaderCellDef>
										Auditor
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.nomeAuditor }}
									</td>
								</ng-container>

								<ng-container matColumnDef="nome">
									<th mat-header-cell *matHeaderCellDef>
										Descrição
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.tipoDispensa.nome }}
									</td>
								</ng-container>

								<ng-container matColumnDef="dias">
									<th mat-header-cell *matHeaderCellDef>
										Dias
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.dias }}
									</td>
								</ng-container>

								<ng-container matColumnDef="periodo">
									<th mat-header-cell *matHeaderCellDef>
										Período
									</th>
									<td mat-cell *matCellDef="let element" >
										{{getPeriodoFormatado(element.dataInicio, element.dataFim)}}
									</td>
								</ng-container>

								<ng-container cdkColumnDef="actions">
									<th mat-header-cell *matHeaderCellDef></th>
									<td
										mat-cell
										*matCellDef="let element; let i = index"
										class="block"
									>
										<div class="btn-group">
											<button
												type="button"
												class="
													btn btn-danger btn-xs
													text-left
												"
												(click)="excluirDispensa(i)"
											>
												<i class="far fa-trash-alt"></i>
											</button>
										</div>
									</td>
								</ng-container>

								<tr
									mat-row
									*matRowDef="
										let row;
										columns: [
											'auditor',
											'nome',
											'dias',
											'periodo',
											'actions'
										]
									"
								></tr>
							</table>
							<h5
								[hidden]="!dispensaSemDados"
								class="table-no-data"
							>
								Nenhuma dispensa cadastrada
							</h5>
						</div>
					</div>
				</div>
				<div class="row pt-4">
					<div class="col-md-12">
						<div class="mat-elevation-z1">
							<table
								mat-table
								[dataSource]="auditorDataSource"
								class="w-100"
							>
								<tr
									mat-header-row
									*matHeaderRowDef="['selecao', 'nome']"
									class="bg-light"
								></tr>

								<ng-container matColumnDef="selecao">
									<th mat-header-cell *matHeaderCellDef></th>
									<td mat-cell *matCellDef="let element">
										<mat-checkbox
											(click)="$event.stopPropagation()"
											color="primary"
											(change)="
												element.selecionado =
													$event.checked
											"
											[checked]="element.selecionado"
										>
										</mat-checkbox>
									</td>
								</ng-container>

								<ng-container matColumnDef="nome">
									<th mat-header-cell *matHeaderCellDef>
										Auditor
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.nomeAuditor }}
									</td>
								</ng-container>

								<tr
									mat-row
									*matRowDef="
										let row;
										columns: ['selecao', 'nome']
									"
								></tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<button
				type="button"
				class="btn btn-primary btn-sm mr-1"
				(click)="concluir()"
			>
				<i class="fas fa-check"></i> Ok
			</button>

			<button
				type="button"
				class="btn btn-default btn-sm"
				(click)="cancelar()"
			>
				<i class="fas fa-times"></i> Cancelar
			</button>
		</div>
	</div>
</div>
