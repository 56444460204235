import { PendenciaService } from "src/app/services/pendencia.service";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
	selector: "app-inicial",
	templateUrl: "./inicial.component.html",
	styleUrls: ["./inicial.component.scss"],
})
export class InicialComponent implements OnInit {
	public count: number;

	constructor(
		public authService: AuthService,
		public pendenciaService: PendenciaService
	) {}

	ngOnInit(): void {
    this.obter()
	}

	public obter() {
		this.pendenciaService.obterPendencias().then((res) => {
			if (res.sucesso) {
				this.count = res.dados.count;
			}
		});
		
	}
}
