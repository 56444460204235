import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';

@Component({
  selector: 'app-lookup-multiselect-modal',
  templateUrl: './lookup-multiselect-modal.component.html',
  styleUrls: ['./lookup-multiselect-modal.component.scss']
})
export class LookupMultiselectModalComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  public semDados = true;
  public exibirId = true;
  public titulo = '';
  public botaoOk = '';
  public displayedColumns: string[];
  public todosSelecionados = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<LookupMultiselectModalComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    if (data.exibirId) {
      this.displayedColumns = ['selecao', 'id', 'nome'];
    } else {
      this.displayedColumns = ['selecao', 'nome'];
    }

    this.botaoOk = data.botaoOk;
    this.exibirId = data.exibirId;
    this.titulo = data.titulo;
    this.dataSource = new MatTableDataSource<any>(data.dados);
    this.dataSource._updateChangeSubscription();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: { nome: string }, filterValue: string) => {
      return (data.nome || '').trim().toLowerCase().indexOf(filterValue) !== -1;
    };
    this.dataSource.sortingDataAccessor = defaultDataAcessor;
    this.dataSource._updateChangeSubscription();
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public filtrar(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public selecionarTodos() {
    this.dataSource.data.forEach(d => d.selecionado = !this.todosSelecionados);
    this.todosSelecionados = !this.todosSelecionados;
  }

  public verificarItensSelecionados(){
    return this.dataSource.data.filter(d => d.selecionado).length > 0;
  }

  public concluir() {
    this.dialogRef.close(this.dataSource.data.filter(d => d.selecionado));
  }

  public cancelar() {
    this.dialogRef.close();
  }
}

