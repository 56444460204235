<!-- ABA INFORMAÇÕES BÁSICAS -->
<form [formGroup]="form" #f="ngForm" >
<div class="card-body">
	<div class="row">
		<div class="form-group col-md-6">
			<label for="nome" class="mb-1"
				>Nome</label
			>
			<input
				[attr.disabled]="true"
				type="text"
				id="nome"
				class="form-control form-control-sm"
				formControlName="nome"
				maxlength="100"
				[readonly]="true"
			/>
		</div>
<div class="form-group col-md-3">
			<label for="cpf" class="mb-1"
				>CPF</label
			>
			<input
				[attr.disabled]="true"
				type="text"
				id="cpf"
				class="form-control form-control-sm"
				formControlName="cpf"
				maxlength="100"
				[readonly]="true"
			/>
		</div>
<div class="form-group col-md-2">
			<label for="numeroFuncional" class="mb-1"
				>Nº Funcional</label
			>
			<input
				[attr.disabled]="true"
				type="text"
				id="nome"
				class="form-control form-control-sm"
				formControlName="numeroFuncional"
				maxlength="100"
				[readonly]="true"
			/>
		</div>
    </div>
	
	<div  class="row">
		<div class="col-md-12">
			<div class="mat-elevation-z1">
				<mat-toolbar class="table-header">
					<mat-toolbar-row>
						<label class="mb-1" >Coordenações</label>
					</mat-toolbar-row>
				</mat-toolbar>
				<table mat-table
						[dataSource]="auditorCoordenacoesDataSource" class="w-100">
						<tr	mat-header-row	*matHeaderRowDef="displayedColumns"></tr>
						
						<ng-container matColumnDef="nome">
							<th	mat-header-cell	*matHeaderCellDef>	Nome	</th>
							<td	mat-cell	*matCellDef="let element">
								{{ element.coordenacao.nome }}
							</td>
						</ng-container>

						<ng-container matColumnDef="cargo">
							<th mat-header-cell *matHeaderCellDef>Cargo</th>
							<td mat-cell *matCellDef="let element">
							  <span *ngIf="element.cargo === 1" class="text-xs"
								>Coordenador Titular</span
							  >
							  <span *ngIf="element.cargo === 2" class="text-xs"
								>Coordenador Substituindo</span
							  >
							  <span *ngIf="element.cargo === 3" class="text-xs"
								>Coordenador Respondendo</span
							  >
							  <span *ngIf="element.cargo === 4" class="text-xs"
								>Auditor do Estado</span
							  >
							</td>
						  </ng-container>

						  <ng-container matColumnDef="situacao">
							<th mat-header-cell *matHeaderCellDef>Situação</th>
							<td mat-cell *matCellDef="let element">
							  <span
								*ngIf="element.ativo"
								class="badge bg-success text-xs"
								>Ativo</span
							  >
							  <span
								*ngIf="!element.ativo"
								class="badge bg-gray-dark text-xs"
								>Inativo</span
							  >
							</td>
						  </ng-container>

						<tr
										mat-header-row
										*matRowDef="
										let row;
										columns:[
											'nome',
											'cargo',
											'situacao'
										]"
										class="bg-light"
									></tr>
				</table>
				<h5
									[hidden]="!auditorCoordenacaoSemDados"
									class="table-no-data"
								>
									Auditor sem coordenações
								</h5>
			</div>
		</div>
	</div>

	</div>
</form>
