import { SolicitacaoTecnicaService } from './../../../../services/solicitacao-tecnica.service';
import { ISolicitacaoTecnicaModel } from './../../../../models/solicitacao-tecnica.model';
import { IItemSolicitacaoTecnicaModel, IRepostaItemModel } from './../../../../models/item-solicitacao-tecnica.model';
import { angularEditorConfig } from './../../../util/angularEditorConfig';
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalIncluirItemComponent } from '../modal-incluir-item/modal-incluir-item.component';
import { ModalVisualizarItemComponent } from '../modal-visualizar-item/modal-visualizar-item.component';
import Swal from 'sweetalert2';

@Component({
	selector: "app-modal-visualizar-resposta",
	templateUrl: "./modal-visualizar-resposta.component.html",
	styleUrls: ["./modal-visualizar-resposta.component.scss"],
})
export class ModalVisualizarRespostaComponent implements OnInit {

	public titulo: string = "Visualizar Resposta";
  public dataSourceItems = new MatTableDataSource<IItemSolicitacaoTecnicaModel>();
  public editorConfig = angularEditorConfig(null, '180px', '100%', false);
  public model: ISolicitacaoTecnicaModel = {} as ISolicitacaoTecnicaModel


  @ViewChild("TableOnePaginator", { static: true })
	tableOnePaginator: MatPaginator;
	@ViewChild("tabela1", { static: true }) tabela1: MatSort;

	constructor(private fb: FormBuilder,
     private dialogRef: MatDialog,
     public matDialog: MatDialog,
		public serviceSolicitacao: SolicitacaoTecnicaService,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.model.id = data?.id
    }

	ngOnInit(): void {
    if(this.model?.id){
      this.obterPorId()
    }
    this.dataSourceItems.sort = this.tabela1;
    this.dataSourceItems.paginator = this.tableOnePaginator;
    this.dataSourceItems.sortingDataAccessor = this.sortingDataAccessor
  }

  public obterPorId() {
		this.serviceSolicitacao.obterPorId(this.model?.id).then((res) => {
			if (res.sucesso) {
        this.model = res.dados
        this.model.prazo = new Date(res.dados.prazo)
				this.dataSourceItems = new MatTableDataSource<IItemSolicitacaoTecnicaModel>(res?.dados?.item)

        this.dataSourceItems.sort = this.tabela1;
        this.dataSourceItems.paginator = this.tableOnePaginator;
        this.dataSourceItems.sortingDataAccessor = this.sortingDataAccessor
			} else {
				Swal.fire({
					toast: true,
					position: "top-end",
					icon: "warning",
					text: res.mensagem.descricao,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
			}
		});
	}





	fechar() {
    this.dialogRef.closeAll();
  }

  public modalVisulizaItem(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '45vw';
    dialogConfig.maxHeight = '90vh';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      solicitacaoTecnicaId: this.model?.id,
      id,
      numeroSolicitacao: this.model.numero
    }
    const modal = this.matDialog.open(ModalVisualizarItemComponent, dialogConfig);
    modal.afterClosed().subscribe(data => {
    });
  }

  public sortingDataAccessor = (item: IItemSolicitacaoTecnicaModel, property) => {
		switch (property) {
			case "prazo":
				return item?.solicitacaoTecnica?.prazo;
			case "status":
				return item?.status?.descricao;
			case "numero":
				return item?.numero;
			default:
				return item[property];
		}
	};
}
