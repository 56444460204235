import { Component, OnInit, Inject } from '@angular/core';
import { IPaaCoordenacaoCapacidadeModel } from 'src/app/models/paa-coordenacao-capacidade.model';
import { IPaaCoordenacaoDispensaModel } from 'src/app/models/paa-coordenacao-dispensa.model';
import { MatTableDataSource } from '@angular/material/table';
import { ITipoDispensaModel } from 'src/app/models/tipo-dispensa.model';
import { IAuditorCoordenacaoModel } from 'src/app/models/auditor-coordenacao.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PaaService } from 'src/app/services/paa.service';
import { ICoordenacaoModel } from 'src/app/models/coordenacao.model';
import * as cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-modal-capacidade-dispensa',
    templateUrl: './modal-capacidade-dispensa.component.html',
    styleUrls: [ './modal-capacidade-dispensa.component.scss' ],
})
export class ModalCapacidadeDispensaComponent implements OnInit {
    public model: IPaaCoordenacaoCapacidadeModel;
    public minDate: Date;
    public maxDate: Date;

    public modelNovaDispensa = {} as IPaaCoordenacaoDispensaModel;
    public dispensaDataSource = new MatTableDataSource<any>([]);
    public auditorDataSource = new MatTableDataSource<any>([]);

    public dispensaSemDados = true;
    public auditorSemDados = true;

    public tabelaTipoDispensa = [] as ITipoDispensaModel[];
    public tabelaAuditores = [] as IAuditorCoordenacaoModel[];

    constructor(
        private dialogRef: MatDialogRef<ModalCapacidadeDispensaComponent>,
        public toastr: ToastrService,
        private paaService: PaaService,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.minDate = new Date(data.ano, 1 - 1, 1);
        this.maxDate = new Date(data.ano, 12 - 1, 31);
        this.model = data.capacidade;
        this.model.auditor = this.model.auditor || [];

        this.modelNovaDispensa.dataFim = this.maxDate;
        this.modelNovaDispensa.dataInicio = this.minDate;

        this.tabelaTipoDispensa = data.tiposDispensas;
        this.tabelaAuditores = (data.coordenacao as ICoordenacaoModel).auditorCoordenacao;

        this.dispensaDataSource = new MatTableDataSource<any>(this.model.dispensa);
        this.dispensaSemDados = this.dispensaDataSource.data.length === 0;

        // Montar lista de Auditores
        for (const auditor of this.tabelaAuditores) {
            this.auditorDataSource.data.push({
                paaId: this.model.paaId,
                coordenacaoId: this.model.coordenacaoId,
                ano: this.model.ano,
                auditorId: auditor.auditorId,
                nomeAuditor: auditor.nomeAuditor,
                selecionado:
                    this.model.auditor.findIndex((a) => a.auditorId === auditor.auditorId && a.selecionado) >= 0,
            });
        }

        this.auditorSemDados = this.auditorDataSource.data.length === 0;
        this.auditorDataSource._updateChangeSubscription();
    }

    ngOnInit(): void {
        Inputmask().mask(document.querySelectorAll('input'));
    }

    public incluirDispensa() {
        
        this.modelNovaDispensa.paaId = this.model.paaId;
        this.modelNovaDispensa.coordenacaoId = this.model.coordenacaoId;
        this.modelNovaDispensa.ano = this.model.ano;
        this.modelNovaDispensa.nomeAuditor = this.tabelaAuditores.find(
            (f) => f.id === this.modelNovaDispensa.auditorId
        ).nomeAuditor;
        this.modelNovaDispensa.tipoDispensa = this.tabelaTipoDispensa.find(
            (f) => f.id === this.modelNovaDispensa.tipoDispensaId
        );

        var duplicada = this.dispensaDataSource.data.filter(
            (_) =>
                _.auditorId == this.modelNovaDispensa.auditorId &&
                ((this.modelNovaDispensa.dataInicio >= _.dataInicio && this.modelNovaDispensa.dataFim <= _.dataFim) ||
                    (this.modelNovaDispensa.dataFim >= _.dataInicio && this.modelNovaDispensa.dataFim <= _.dataFim) ||
                    (this.modelNovaDispensa.dataInicio >= _.dataInicio &&
                        this.modelNovaDispensa.dataInicio <= _.dataFim))
        );

        if (duplicada.length > 0) {
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                text: 'Existe uma dispensa já cadastrada neste período para este auditor!',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            });
        } else {
            this.dispensaDataSource.data.push(cloneDeep(this.modelNovaDispensa));
            this.dispensaDataSource._updateChangeSubscription();
            this.modelNovaDispensa = {} as IPaaCoordenacaoDispensaModel;
            this.dispensaSemDados = false;
        }
    }

    public excluirDispensa(ind: number) {
        Swal.fire({
            title: 'Excluir Registro',
            text: 'Tem certeza que deseja excluir esse Registro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((res) => {
            if (res.value) {
                this.dispensaDataSource.data.splice(ind, 1);
                this.dispensaDataSource._updateChangeSubscription();
                this.dispensaSemDados = this.dispensaDataSource.filteredData.length === 0;
            }
        });
    }

    public concluir() {
        this.model.dispensa = this.dispensaDataSource.data;
        this.model.auditor = this.auditorDataSource.data.filter((d) => d.selecionado);

        this.paaService
            .atualizarCapacidadeTrabalho(this.model.paaId, this.model)
            .then((res) => {
                if (res.sucesso) {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'success',
                        text: 'Registro salvo com sucesso!',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.dialogRef.close(true);
                } else {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'warning',
                        text: res.mensagem.descricao,
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                }
            })
            .catch((err) => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    text: err.mensagem.descricao,
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                });
            });
    }

    public cancelar() {
        this.dialogRef.close(false);
    }

    public UpdateDataInicio(event: any) {
        this.modelNovaDispensa.dataInicio = event.targt.value;
    }
    public UpdateDataFim(event: any) {
        this.modelNovaDispensa.dataFim = event.targt.value;
    }

    public getPeriodoFormatado(dtInicio, dtFim) {
        var dataInicio = new Date(dtInicio);
        var dataFim = new Date(dtFim);
        var strDataInicio = dataInicio.getDate() + '/' + dataInicio.getMonth() + '/' + dataInicio.getFullYear();
        var strDataFim = dataFim.getDate() + '/' + dataFim.getMonth() + '/' + dataFim.getFullYear();
        
        return strDataInicio + ' a ' + strDataFim;
        }
}
