<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i> Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item active">
						Lista de Questionários
					</li>
				</ol>
			</div>
		</div>
	</div>
</div>

<div class="content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card card-outline card-lightblue">
					<div class="card-header">
						<h3 class="card-title mb-0">
							<i class="fas fa-table pr-2"></i> Lista de
							Questionários
						</h3>
					</div>

					<div class="card-body p-2">
						<div class="mat-elevation-z4">
							<mat-toolbar class="table-header">
								<mat-toolbar-row>
									<button
										type="button"
										class="btn btn-success btn-sm mr-1"
										[disabled]="
											!selectedModel ||
											selectedModel?.status != 'Pendente'
										"
										(click)="
											responder()
										"
									>
										<i class="far fas fa-pen"></i> Responder
									</button>
									<button
										type="button"
										class="btn btn-info btn-sm mr-1"
										[disabled]="!selectedModel"
										(click)="
											visualizarQuestionario(
												selectedModel
											)
										"
									>
										<i class="far fa-eye"></i> Visualizar
									</button>

									<span class="fill-remaining-space"></span>
									<div
										class="input-group input-group-sm"
										style="width: 200px"
									>
										<input
											type="text"
											name="table_search"
											class="form-control float-right"
											(keyup.enter)="buscar()"
											placeholder="Pesquisar por Nome"
											[(ngModel)]="searchNome"
										/>

										<div class="input-group-append">
											<button
												type="button"
												class="btn btn-info"
												(click)="buscar()"
											>
												<i class="fas fa-search"></i>
											</button>
										</div>
									</div>
								</mat-toolbar-row>
							</mat-toolbar>
							<table
								mat-table
								[dataSource]="dataSource"
								class="w-100"
								matSort
								matSortDisableClear
								class="mat-elevation-z8"
								matSortActive="id"
								matSortDirection="asc"
								[hidden]="semDados"
								(matSortChange)="sortData($event)"
							>
								<tr
									mat-header-row
									*matHeaderRowDef="displayedColumns"
								></tr>
								<ng-container matColumnDef="selecao">
									<th mat-header-cell *matHeaderCellDef></th>
									<td mat-cell *matCellDef="let element">
										<mat-checkbox
											(click)="$event.stopPropagation()"
											color="primary"
											(change)="
												selectedModel = $event.checked
													? element
													: null
											"
											[checked]="
												selectedModel?.peaId ===
													element.peaId &&
												selectedModel?.paaId ===
													element.paaId &&	
												selectedModel?.criterioId ===
													element.criterioId &&
												selectedModel?.unidadeGestoraId ===
													element.unidadeGestoraId
											"
										>
										</mat-checkbox>
									</td>
								</ng-container>

								<ng-container matColumnDef="pea">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										PEAC
									</th>
									<td mat-cell *matCellDef="let element">
										{{
											retornaNomePea(element)
										}}
									</td>
								</ng-container>

								<ng-container matColumnDef="paa" *ngIf="mostrarColunaPAAC">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										PAAC
									</th>
									<td mat-cell *matCellDef="let element">
										{{
											element.paaIndiceCriterio.paa.nome
										}}
									</td>
								</ng-container>

								<ng-container matColumnDef="ug">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										UG
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.unidadeGestora.sigla }}
									</td>
								</ng-container>

								<ng-container matColumnDef="responsavel">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										Responsável
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.responsavelId }}
									</td>
								</ng-container>

								<ng-container matColumnDef="dataEnvio">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										Data Envio
									</th>
									<td mat-cell *matCellDef="let element">
										{{
											element.dataCriacao
												| date: "dd/MM/yyyy"
										}}
									</td>
								</ng-container>

								<ng-container matColumnDef="dataPrazo">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										Data Prazo
									</th>
									<td mat-cell *matCellDef="let element">
										{{
											element.dataLimite
												| date: "dd/MM/yyyy"
										}}
									</td>
								</ng-container>

								<ng-container matColumnDef="status">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										Status
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.status }}
									</td>
								</ng-container>

								<tr
									mat-row
									*matRowDef="
										let row;
										columns: displayedColumns
									"
									(click)="selecionar(row)"
									class="clickable"
									[ngClass]="{
										'bg-light':
											(selectedModel?.peaId === row.peaId || selectedModel?.paaId === row.paaId) 
											&&
											selectedModel?.criterioId ===
												row.criterioId &&
											selectedModel?.unidadeGestoraId ===
												row.unidadeGestoraId
									}"
								></tr>
							</table>
							<h5 [hidden]="!semDados" class="table-no-data">
								Nenhum questionário disponível
							</h5>
							<mat-paginator
								[pageSizeOptions]="[15, 30, 50]"
								showFirstLastButtons
								(page)="selectedModel = null"
							>
							</mat-paginator>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
