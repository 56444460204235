import { LocationStrategy } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IBaseModel } from "../models/base.model";
import { IFasesAtividadeModel } from "../models/fases-atividade-model";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: 'root',
})
export class FaseAtividadeService extends BaseService {


    constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
        super(httpClient, locationStrategy);
    }

    public obter(pesquisa?: string, ativo?: boolean, simplificado = false): Promise<IBaseModel<IFasesAtividadeModel[]>> {
        let params = new HttpParams();
        params = params.append('pesquisa', pesquisa || '');

        if (ativo !== undefined) {
            params = params.append('ativo', ativo.toString());
        }

        if (simplificado) {
            params = params.append('simplificado', simplificado.toString());
        }

        return this.httpClient
            .get<IBaseModel<IFasesAtividadeModel[]>>(`${this.apiBaseUrl}/fasesatividades`, { params })
            .toPromise();
    }

    public async inserir(model: IFasesAtividadeModel): Promise<IBaseModel<void>> {
        return this.httpClient
            .post<IBaseModel<void>>(`${this.apiBaseUrl}/fasesatividades`, model)
            .toPromise();
    }

    public async obterPorId(id: number): Promise<IBaseModel<IFasesAtividadeModel>> {
        return this.httpClient
            .get<IBaseModel<IFasesAtividadeModel>>(`${this.apiBaseUrl}/fasesatividades/${id}`)
            .toPromise();
    }

    public async atualizar(data: IFasesAtividadeModel): Promise<IBaseModel<void>> {
        return this.httpClient
          .put<IBaseModel<void>>(`${this.apiBaseUrl}/fasesatividades/${data.id}`, data)
          .toPromise();
      }

    public async reativar(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
            .patch<IBaseModel<void>>(`${this.apiBaseUrl}/fasesatividades/${id}/reativar`, {})
            .toPromise();
    }

    public async inativar(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
            .patch<IBaseModel<void>>(`${this.apiBaseUrl}/fasesatividades/${id}/inativar`, {})
            .toPromise();
    }

    public async excluir(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
            .delete<IBaseModel<void>>(`${this.apiBaseUrl}/fasesatividades/${id}/excluir`, {})
            .toPromise();
    }
}