import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { IConstatacaoModel } from 'src/app/models/constatacao.model';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { ConstatacaoService } from 'src/app/services/constatacao.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';

@Component({
  selector: 'app-constatacao-list',
  templateUrl: './constatacao-list.component.html',
  styleUrls: ['./constatacao-list.component.scss']
})
export class ConstatacaoListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<IConstatacaoModel>([]);
  public selectedModel: IConstatacaoModel;
  public displayedColumns: string[] = ['selecao', 'nome', 'ativo'];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;
  public permissoes: IPermissaoModel;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private constatacaoService: ConstatacaoService,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService
  ) {
    super();
    this.buscar();
  }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Visualizar);
 }

  public novo() {
    this.router.navigate(['/constatacao/novo']);
  }

  public buscar() {
    this.selectedModel = null;

    this.constatacaoService
      .obter(this.searchNome, this.searchAtivo, false)
      .then((res) => {
        this.dataSource = new MatTableDataSource<IConstatacaoModel>(res.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao)
      })
  }

  public reativar(id: number) {
    this.constatacaoService
      .reativar(id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso('Registro reativado com sucesso!')
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public  inativar(id: number){
    this.constatacaoService
      .inativar(id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso('Registro inativado com sucesso!');
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public editar(id: number) {
    this.router.navigate([`/constatacao/${id}`]);
  }

  public visualizar(id: number) {
    this.router.navigate([`/constatacao/${id}/visualizar`]);
  }

  public excluir(id: number) {
    this.constatacaoService
      .excluir(id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso('Registro excluído com sucesso!');
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel !== item ? item : null;
  }
}
