//BASE
import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { formatDate } from '@angular/common';
import { MatDialog,	MatDialogConfig } from "@angular/material/dialog";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import Swal from 'sweetalert2';


//COMPONENTS
import { ModalImportarArquivoCriterioPaaComponent } from "../modal-importar-arquivo-criterio-paa/modal-importar-arquivo-criterio-paa.component";
import { ModalPreencherFormularioPaaComponent } from "../modal-preencher-formulario/modal-preencher-formulario-paa.component";
import { ModalRankingPrioridadeComponent } from "../modal-ranking-prioridade/modal-ranking-prioridade.component";
import { ModalEnviarQuestionarioCriterioPaaComponent } from "../modal-enviar-questionario-criterio/modal-enviar-questionario-criterio.component";
import { ModalVisualizarQuestionarioCriterioPaaComponent } from "../modal-visualizar-questionario-criterio/modal-visualizar-questionario-criterio.component";
import { ModalCadastrarIndicadorComponent } from "../modal-cadastrar-indicador/modal-cadastrar-indicador.component";
import { ModalCadastrarCriterioComponent } from "../modal-cadastrar-criterio/modal-cadastrar-criterio.component";

//MODELS
import { IPaaAbaIndicesCriteriosModel } from 'src/app/models/paa-aba-indices-criterios.model';
import { IPaaAbaICIndicadorModel } from "src/app/models/paa-aba-indices-criterios.model";
import { IPaaAbaICCriterioModel } from "src/app/models/paa-aba-indices-criterios.model";
import { ITipoAuditoriaModel } from "src/app/models/tipo-auditoria.model";
import { FiltroModel } from "src/app/models/filtro.model";
import { IPaaDistribuicaoColunasModel } from "src/app/models/paa-distribuicao-colunas.model";
import { ITipoEnfaseModel } from "src/app/models/tipo-enfase.model";
import { IEnviarQuestionarioModel } from "src/app/models/enviar-questionario.model";
import { IPaaIndiceCriterioModel } from "src/app/models/paa-indice-criterio.model";
import { IPaaCriterioRespostaModel } from "src/app/models/paa-criterio-resposta.model";
import { IEnumModel } from "src/app/models/enum.model";



//SERVICES
import { PaaAbaIndicesCriteriosService } from "src/app/services/paa/paa-aba-indices-criterios.service";
import { PaaService } from "src/app/services/paa.service";
import { IPaaDistribuicaoModel } from "src/app/models/paa-distribuicao.model";
import { TipoAuditoriaService } from "src/app/services/tipo-auditoria.service";
import { TipoEnfaseService } from "src/app/services/tipo-enfase.service";
import { CriterioService } from "src/app/services/criterio.service";
import { IndicadorService } from "src/app/services/indicador.service";

@Component({
	selector: "app-paa-aba-indices-criterios",
	templateUrl: "./paa-aba-indices-criterios.component.html",
	styleUrls: ["./paa-aba-indices-criterios.component.scss"],
})

export class PaaAbaIndicesCriteriosComponent extends BaseFormComponent{
	
	public model = {} as IPaaAbaIndicesCriteriosModel;
	public aba = 0;
	public paaHomologado = true;
	public visualizarBotaoFormulario = false;
	public emAprovacao = false;
	public capacidadeSemDados = true;
	public distruibuicaoSemDados = true;
	public historico:boolean;
	public indicadoresDataSource = new MatTableDataSource<IPaaAbaICIndicadorModel | IPaaAbaICCriterioModel>([]);
	public distribuicaoDataSource = new MatTableDataSource<IPaaDistribuicaoModel>([]);
	public capacidadeDataSource = new MatTableDataSource<any>([]);
	public indicadoresDataSourceSemDados = true;
	public criteriosDataSource = new MatTableDataSource<IPaaAbaICCriterioModel>([]);
	public criteriosDataSourceSemDados = true;
	public _alldata: IPaaAbaICIndicadorModel[];
	@ViewChild('tabela1') public tabela1: MatSort;
	@ViewChild('tabela2') public tabela2: MatSort;
	@ViewChild('tabela3') public tabela3: MatSort;
	public mostrarCapacidadeTotal: number;
  	public mostrarCapacidadeTotalPea: number;
	displayedColumns: string[] = ['nomeCriterio', 'unidadeMedida', 'tipoEntrada', 'actions'];
	public columnsDistribuicaoOriginais1 = ['unidadeGestora', 'valorRisco'];
	public columnsDistribuicaoOriginais2 = ['ativo', 'tipoAuditoria','tipoEnfase', 'actions'];
	public columnsDistribuicao = [];
	public colunasAdicionarDistribuicao = [];
	public tiposAuditorias: ITipoAuditoriaModel[];
	public tiposEnfase: ITipoEnfaseModel[];
	public filtroEnfase = {} as FiltroModel;

	constructor(
		public route: ActivatedRoute,
		public toastr: ToastrService,
		public router: Router,
		public localeService: BsLocaleService,
		public matDialog: MatDialog,
		private paaService: PaaService,
		private tipoAuditoriaService: TipoAuditoriaService,
		private tipoEnfaseService: TipoEnfaseService,
		public criterioService: CriterioService,
		public indicadorService: IndicadorService,
		public paaAbaIndicesCriteriosService: PaaAbaIndicesCriteriosService
		
	) {
		super(route, toastr, router, localeService, matDialog);
	}

	async ngOnInit(): Promise<void> {
		await this.buscar();
		
		//TODO: VERIFICAR PQ A VARIÁVEL VISUALIZAR ESTÁ SENDO RESETADA
		this.visualizar = this.route.routeConfig.path?.includes("visualizar");
	}

	async buscar(){
		this.paaAbaIndicesCriteriosService.obter(this.id)
			.then((res) => {
				this.model = res.dados;
				if(this.model.idSituacaoPaa == 2)
					this.emAprovacao = true;
				var dados = [];
				res.dados.indicadores.map((i) => {
						dados.push(i);
						dados = dados.concat(i.criterios);
						}
					);
				
				if(res.dados.criterios.length > 0){
					const agrupadorCriterios = {} as IPaaAbaICIndicadorModel;
					agrupadorCriterios.idIndicador = 0;
					agrupadorCriterios.isGrouper = true;
					agrupadorCriterios.nomeIndicador = "Critérios";
					agrupadorCriterios.peaRelacionado = true;
					agrupadorCriterios.criterios = {} as IPaaAbaICCriterioModel[];
					
					dados.push(agrupadorCriterios);

					dados = dados.concat(res.dados.criterios);
				}

				this.indicadoresDataSource = new MatTableDataSource<IPaaAbaICIndicadorModel | IPaaAbaICCriterioModel>(dados);
				this.criteriosDataSourceSemDados = dados.length == 0;
			})
			
	}

	public async importarArquivoCriterioPaa(id: number, visualizar?: boolean) {
		try {
			
		  const respostas = this.distribuicaoDataSource.data.map(
			(u) =>
			({
			  paaIndiceCriterioId: id,
			  unidadeGestoraId: u.unidadeGestoraId,
			  unidadeGestora: u.unidadeGestora,
			} as IPaaCriterioRespostaModel)
		  );
	
		  const dialogConfig = new MatDialogConfig();
		  dialogConfig.id = 'modal-component';
		  dialogConfig.width = '450px';
		  dialogConfig.hasBackdrop = true;
		  dialogConfig.disableClose = true;
		  dialogConfig.data = {
			respostas,
			visualizar,
		  };
		  const modal = this.matDialog.open(
			ModalImportarArquivoCriterioPaaComponent,
			dialogConfig
		  );
		  modal.afterClosed().subscribe(async (data) => {
			if (data) {
			  try {
				if (!this.novoRegistro) {
				  const res = await this.paaAbaIndicesCriteriosService.enviarRespostasCriterio(
					this.id,
					respostas
				  );
				  if (res.sucesso) {
					await Promise.all([
					  this.obterPainelDistribuicao(),
					  this.buscar(),
					]);
					this.exibirMensagemSucesso('Respostas gravadas com sucesso!');
				  } else {
					this.exibirMensagemErro(res.mensagem.descricao);
				  }
				}
			  } catch (err) {
				this.exibirMensagemErro(err.mensagem.descricao);
			  }
			}
		  });
		} catch (err) {
		  this.exibirMensagemErro(err.mensagem.descricao);
		}
	  }

	  public async preencherFormularioCriterioPaa(id: number, visualizar?: boolean) {
		try {
			await this.obterPainelDistribuicao();

		  this.paaAbaIndicesCriteriosService.obterCriterioResposta(id).then((resCriterioRespostas) => {
			const criterioRespostas =
			  resCriterioRespostas.sucesso && resCriterioRespostas.dados
				? resCriterioRespostas.dados
				: [];
	
			const respostas = this.distribuicaoDataSource.data.map((u) => {
			  return (
				criterioRespostas.find((r) => r.paaDistribuicaoId === u.id) ||
				({
				  paaDistribuicaoId: u.id,
				  paaIndiceCriterioId: id,
				  unidadeGestoraId: u.unidadeGestoraId,
				  unidadeGestora: u.unidadeGestora,
				} as IPaaCriterioRespostaModel)
			  );
			});
	
			const dialogConfig = new MatDialogConfig();
			dialogConfig.id = 'modal-component';
			dialogConfig.width = '650px';
			dialogConfig.hasBackdrop = true;
			dialogConfig.disableClose = true;
			dialogConfig.data = {
			  respostas,
			  visualizar,
			};
			const modal = this.matDialog.open(
			  ModalPreencherFormularioPaaComponent,
			  dialogConfig
			);
			modal.afterClosed().subscribe(async (data) => {
	
			  if (data) {
				try {
				  if (!this.novoRegistro) {
					const res = await this.paaAbaIndicesCriteriosService.enviarRespostasCriterio(
					  this.id,
					  respostas
					);
					if (res.sucesso) {
					  await Promise.all([this.obterPainelDistribuicao()]);
					  this.exibirMensagemSucesso('Respostas gravadas com sucesso!');
					} else {
					  this.exibirMensagemErro(res.mensagem.descricao);
					  this.router.navigate(['/paa']);
					  return;
					}
				  }
				} catch (err) {
				  this.exibirMensagemErro(err.mensagem.descricao);
				  this.router.navigate(['/paa']);
				}
			  }
			});
		  });
		} catch (err) {
		  this.exibirMensagemErro(err.mensagem.descricao);
		}
	  }

	  public async abrirModalRankingPrioridade(element: IPaaAbaICIndicadorModel) {
		try {
		  const dialogConfig = new MatDialogConfig();
		  dialogConfig.id = 'modal-component-ranking';
		  dialogConfig.width = '800px';
		  dialogConfig.maxHeight = '700px';
		  dialogConfig.hasBackdrop = true;
		  dialogConfig.disableClose = true;
		  dialogConfig.data = {
			indicadorId: element.idIndicador,
			id: this.id,
			nomeIndicador: element.nomeIndicador
		  };
		  const modal = this.matDialog.open(
			ModalRankingPrioridadeComponent,
			dialogConfig
		  );
		} catch (err) {
		  this.exibirMensagemErro(err.mensagem.descricao);
		}
	  }

	  public excluirCriterio(idPaaIndiceCriterio: number) {
		Swal.fire({
		  title: 'Excluir Registro',
		  text: 'Tem certeza que deseja excluir esse Registro?',
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonText: 'Sim',
		  cancelButtonText: 'Não',
		}).then((res) => {
		  if (res.value) {
			this.paaAbaIndicesCriteriosService
			  .removerIndiceCriterio(idPaaIndiceCriterio)
			  .then((resAssociar) => {
				if (resAssociar.sucesso) {
				  this.exibirMensagemSucesso('Critério removido com sucesso!');
					this.buscar();
				} else {
				  this.exibirMensagemErro(resAssociar.mensagem.descricao);
				}
			  })
			  .catch((errAssociar) =>
				this.exibirMensagemErro(errAssociar.mensagem.descricao)
			  )
		  }
		});
	  }

	  public excluirIndicador(idIndicador: number){
		Swal.fire({
			title: 'Excluir Registro',
			text: 'Tem certeza que deseja excluir esse Registro?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Sim',
			cancelButtonText: 'Não',
		  }).then((res) => {
			if (res.value) {
			  this.paaAbaIndicesCriteriosService
				.removerIndiceCriterios(this.id, idIndicador)
				.then((resAssociar) => {
				  if (resAssociar.sucesso) {
					this.exibirMensagemSucesso('Indicador removido com sucesso!');
					this.buscar();
				  } else {
					this.exibirMensagemErro(resAssociar.mensagem.descricao);
				  }
				})
				.catch((errAssociar) =>
				  this.exibirMensagemErro(errAssociar.mensagem.descricao)
				)
			}
		  });
	  }

	public exibirRespostas(id: number) {    
	}

	public onBack() {
		this.router.navigate(['/paa']);
	  }
	  
	  public fechar() {
		this.router.navigate(['/paa']);
	  }

	
	  isGroup(index, item): boolean {
		return item.idIndicador || item.isGrouper;
	  }
	

	  public async obterPainelDistribuicao() {
		if (this.capacidadeDataSource.data.length == 0) {
		  await this.obterCapacidadeTrabalhoPor(this.id, this.model.anoPaa);
		}
	
		return this.paaService.obterPainelDistribuicao(this.id).then((res) => {
		  this.colunasAdicionarDistribuicao = [];
		  this.columnsDistribuicao = [];
		  this.columnsDistribuicao = this.columnsDistribuicao.concat(
			Object.assign([], this.columnsDistribuicaoOriginais1)
		  );
		  var colunasNomes: any = {};
		  if (res && res.dados[0] && res.dados[0].colunasValores) {
			res.dados[0].colunasValores.forEach((element) => {
			  const nomeColuna = this.obterNomeColuna(element);
	
			  colunasNomes[nomeColuna.toString()] = nomeColuna;
	
			  this.columnsDistribuicao.push(nomeColuna);
			  if (
				!this.colunasAdicionarDistribuicao.some((c) => c === nomeColuna)
			  ) {
				this.colunasAdicionarDistribuicao.push(nomeColuna);
			  }
			});
		  }
	
		  this.columnsDistribuicao = this.columnsDistribuicao.concat(
			Object.assign([], this.columnsDistribuicaoOriginais2)
		  );
	
		  var dados = res.dados.map((item) => {
			if (item.tipoAuditoria?.id == 0)
			  item.tipoAuditoriaId = null;
			item.unidadeGestora.sigla = item.unidadeGestora.sigla.toUpperCase();
			return item;
		  });

		  dados = dados.filter(x => !x.auditoriaObrigatoria);
	
		  this.distribuicaoDataSource =
			new MatTableDataSource<IPaaDistribuicaoModel>(dados);
		  this.distribuicaoDataSource._updateChangeSubscription();
	
		  this.distribuicaoDataSource.sortingDataAccessor = (
			item: IPaaDistribuicaoModel,
			property
		  ) => {
			switch (property) {
			  case 'unidadeGestora':
				return item.unidadeGestora.sigla;
	
			  case 'valorRisco':
				return item.valorRisco;
	
			  case colunasNomes[property]:
				return parseFloat(
				  this.obterValorColuna(item, property)
					.replace(/[.]/g, '')
					.replace(/[,]/g, '.') == ''
					? '0'
					: this.obterValorColuna(item, property)
					  .replace(/[.]/g, '')
					  .replace(/[,]/g, '.')
				);
	
			  case 'ativo':
				return item.ativo.toString();
	
			  case 'tipoAuditoria':
				return item.tipoAuditoria.nome;
	
			  default:
				return item[property];
			}
		  };
	
		  this.distribuicaoDataSource.sort = this.tabela3;
		  this.distruibuicaoSemDados =
			this.distribuicaoDataSource.filteredData.length === 0;
	
		  res.dados.forEach((item) => {
			this.obterHistoricoDistribuicao(item);
		  });
		});
	  }

	  public obterCapacidadeTrabalhoPor(paaId: number, ano: number): Promise<void> {
		return this.paaService
		  .obterCapacidadeTrabalhoPorIdAndAno(paaId, ano)
		  .then((resCapacidade) => {
			this.capacidadeDataSource = new MatTableDataSource<any>(
			  resCapacidade.dados
			);
	
			localStorage.setItem('auditores', JSON.stringify(resCapacidade.dados.map(a => a.auditor)))
			this.capacidadeDataSource._updateChangeSubscription();
	
			this.capacidadeDataSource.sortingDataAccessor = (item, property) => {
			  switch (property) {
				case 'coordenacao':
				  return item?.coordenacaoNome;
				case 'auditores':
				  return item?.auditor;
				default:
				  return item[property];
			  }
			};
	
			this.capacidadeDataSource.sort = this.tabela2;
	
			this.mostrarCapacidadeTotal = this.capacidadeDataSource.data
			  .map((item) => item.capacidadeTotalCoordenacao)
			  .reduce((total, currentElement) => total + currentElement);
	
			this.mostrarCapacidadeTotalPea = this.capacidadeDataSource.data
			  .map((item) => item.capacidadeTotalCoordenacaoPea)
			  .reduce((total, currentElement) => total + currentElement);
	
	
			this.capacidadeSemDados =
			  this.capacidadeDataSource.filteredData.length === 0;
		  }).then(() => this.obterTiposAuditorias()).then(() => this.obterTiposEnfase());
	  }

	  public async obterTiposAuditorias() {
		return this.tipoAuditoriaService.obter('', true).then((res) => {
		  this.tiposAuditorias = res?.dados.filter(a => {
			const res = this.capacidadeDataSource.data.find(b => a?.coordenacaoId === b?.coordenacaoId);
			if (res?.coordenacaoId) {
			  return a;
			}
		  });
		});
	  }
	
	  public async obterTiposEnfase(){
		this.filtroEnfase.itensPorPagina = 99;
		this.filtroEnfase.ativo = true;
		return this.tipoEnfaseService.obter(this.filtroEnfase).then((res) =>
		{
		  this.tiposEnfase = res?.dados.resultado;
		})
	  }

	  public obterHistoricoDistribuicao(
		element: IPaaDistribuicaoModel
	  ): Promise<void> {
		return this.paaService
		  .obterHistoricoDistribuicao(this.id, element.unidadeGestoraId)
		  .then((historico) => {
			if (historico.sucesso) {
			  let tooltip = 'Histórico da distribuição\n\n';
			  if (historico.dados.length > 0) {
				historico.dados.forEach((item) => {
				  tooltip += `${item.usuario} - ${formatDate(
					item.data,
					'dd/MM/yyyy',
					'pt-BR'
				  )}, Ação: ${item.acao}, Justificativa: ${item.justificativa}\n\n`;
				});
				this.historico = true;
			  } else {
				tooltip += 'Nenhum dado encontrado.';
				this.historico = false;
			  }
			  element.tooltipHistorico = tooltip;
			} else {
			  Swal.fire({
				toast: true,
				position: 'top-end',
				icon: 'error',
				text: historico.mensagem.descricao,
				showConfirmButton: false,
				timer: 2000,
				timerProgressBar: true,
			  });
			}
		  });
	  }

	  public obterValorColuna(
		element: IPaaDistribuicaoModel,
		coluna: string
	  ): string {
		if (!element || !coluna) {
		  return '';
		}
	
		const result = element.colunasValores.find(
		  (x) => this.obterNomeColuna(x) === coluna
		);
	
		if (!result || !result.valorNumerico) {
		  return '';
		}
	
		return result.valorNumerico.toFixed(2);
	  }

	  private obterNomeColuna(element: IPaaDistribuicaoColunasModel) {
		return element.nomeIndicador && element.nomeIndicador !== ''
		  ? element.nomeIndicador + ' / ' + element.nomeCriterio
		  : element.nomeCriterio;
	  }

	  public adicionarCriterio() {
		this.criterioService
		  .obter('', true)
		  .then((res) => {
			if (res.sucesso) {
			  const dialogConfig = new MatDialogConfig();
			  dialogConfig.id = 'modal-component';
			  dialogConfig.width = '650px';
			  dialogConfig.hasBackdrop = true;
			  dialogConfig.disableClose = true;
			  dialogConfig.data = res.dados;
			  const modal = this.matDialog.open(
				ModalCadastrarCriterioComponent,
				dialogConfig
			  );
	
			  modal.afterClosed().subscribe((data) => {
				if (data) {
				  const criterio = {} as IPaaIndiceCriterioModel;
				  criterio.criterioId = data.criterio.id;
				  criterio.paaId = this.id;
				  criterio.valorExibicao = {} as IEnumModel;
				  criterio.valorExibicao.codigo = data.valorExibicao;
	
				  this.paaAbaIndicesCriteriosService
					.adicionarIndiceCriterio(criterio)
					.then((resAssociar) => {
					  if (resAssociar.sucesso) {
						this.exibirMensagemSucesso('Critério adicionado com sucesso');
						 this.buscar();
					  } else {
						this.exibirMensagemErro(resAssociar.mensagem.descricao);
					  }
					})
					.catch((errAssociar) => {
					  this.exibirMensagemErro(errAssociar.mensagem.descricao);
					});
				}
			  });
			} else {
			  this.exibirMensagemErro(res.mensagem.descricao);
			}
		  })
		  .catch((err) => {
			this.exibirMensagemErro(err.mensagem.descricao);
		  })
	  }

	  public adicionarIndicador() {

		this.indicadorService
		  .obter('', true)
		  .then((res) => {
			if (res.sucesso) {
			  const dialogConfig = new MatDialogConfig();
			  dialogConfig.id = 'modal-component';
			  dialogConfig.width = '650px';
			  dialogConfig.hasBackdrop = true;
			  dialogConfig.disableClose = true;
			  dialogConfig.data = res.dados;
			  const modal = this.matDialog.open(
				ModalCadastrarIndicadorComponent,
				dialogConfig
			  );
	
			  modal.afterClosed().subscribe((data) => {
				if (data) {
				  const indicador = {} as IPaaIndiceCriterioModel;
				  indicador.indicadorId = data.id;
				  indicador.paaId = this.id;
	
				  this.paaAbaIndicesCriteriosService
					.adicionarIndiceCriterio(indicador)
					.then((resAssociar) => {
					  if (resAssociar.sucesso) {
						this.exibirMensagemSucesso('Indicador adicionado com sucesso');
						this.buscar();
					  } else {
						this.exibirMensagemErro(resAssociar.mensagem.descricao);
					  }
					})
					.catch((errAssociar) => {
					  this.exibirMensagemErro(errAssociar.mensagem.descricao);
					});
				}
			  });
			} else {
			  this.exibirMensagemErro(res.mensagem.descricao);
			}
		  })
		  .catch((err) => {
			this.exibirMensagemErro(err.mensagem.descricao);
		  })
	  }

	  public async exibirModalVisualizarQuestionarioCriterio() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = 'modal-component';
		dialogConfig.width = '850px';
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		const res = await this.paaAbaIndicesCriteriosService.obterVisualizacaoQuestionario(this.id);
		
		dialogConfig.data = res;
		const modal = this.matDialog.open(ModalVisualizarQuestionarioCriterioPaaComponent, dialogConfig);
	  }
	
	  public async exibirModalEnviarQuestionarioCriterio() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = 'modal-component';
		dialogConfig.width = '650px';
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		const modal = this.matDialog.open(ModalEnviarQuestionarioCriterioPaaComponent, dialogConfig);
		modal.afterClosed().subscribe(data => {
		  if (data) {
			this.enviarQuestionario(data);
		  }
		});
	  }

	  public async confirmarCancelamento() {
		const res = await this.paaAbaIndicesCriteriosService.questionariosRespondidos(this.id);
		if (res.sucesso) {
		  if(res.dados){
			Swal.fire({
			  title: 'Cancelar Questionário',
			  text: `${res.mensagem?.descricao}. Deseja cancelar o envio mesmo assim?`,
			  icon: 'warning',
			  showCancelButton: true,
			  confirmButtonText: 'Sim',
			  cancelButtonText: 'Não'
			}).then(resConfirm => {
			  if (resConfirm.isConfirmed) {
				this.cancelarEnvioQuestionario();
			  }
			});
		  }else{
		  Swal.fire({
			title: 'Cancelar Questionário',
			text: 'Deseja cancelar o envio dos questionários?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Sim',
			cancelButtonText: 'Não',
		  }).then(resConfirm => {
			if (resConfirm.isConfirmed) {
			  this.cancelarEnvioQuestionario();
			}
		  });
		}
		}
		else {
		  this.exibirMensagemErro(res.mensagem.descricao);
		}
	  }
	
	  public async cancelarEnvioQuestionario() {
		try {
		  const res = await this.paaAbaIndicesCriteriosService.cancelarEnvioQuestionario(this.id);
		  if (res.sucesso) {
			this.exibirMensagemSucesso('Envio cancelado');
			this.buscar();
		  } else {
			this.exibirMensagemAlerta(res.mensagem.descricao);
		  }
		} catch (err) {
		  this.exibirMensagemErro(err.mensagem.descricao);
		}
	  }
	
	  public async enviarQuestionario(modelEnviarQuestionario: IEnviarQuestionarioModel) {
		try {
		  if (!this.novoRegistro) {
			const res = await this.paaAbaIndicesCriteriosService.enviarQuestionarios(this.id, modelEnviarQuestionario);
			if (res.sucesso) {
			  this.exibirMensagemSucesso(res.mensagem.descricao);
			  this.buscar();
			} else {
			  this.exibirMensagemAlerta(res.mensagem.descricao);
			  return;
			}
		  }
		} catch (err) {
		  this.exibirMensagemErro(err.mensagem.descricao);
		}
	  }

	    public async enviarQuestionarioPaa() {
    try {
      if (!this.novoRegistro) {
        const res = await this.paaAbaIndicesCriteriosService.enviarQuestionariosPaa(this.id);
        if (res.sucesso) {
          this.exibirMensagemSucesso('Questionários enviados com sucesso');
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
   }


	  //COMPUTED
	  mostrarBotaoPreencherFormulario(element: any): boolean{
		return  !this.visualizar && this.model.idSituacaoPaa === 1 && element.tipoEntrada === 'Formulario';
	  }

	  mostrarBotaoImportarArquivo(element: any): boolean{
		return !this.visualizar && this.model.idSituacaoPaa === 1 && element.tipoEntrada  ===	'ImportacaoArquivo';
	  }

	  mostrarBotaoVisualizarResposta(element: any): boolean{
		return this.model.idSituacaoPaa === 1 || this.model.idSituacaoPaa === 4;
	  }

	  mostrarBotaoExcluirCriterio(element: any): boolean{
		return !this.visualizar && 
				this.model.idSituacaoPaa === 1 && 
				element.peaRelacionado == false
				&& element.possuiIndicador === false;
	  }

	  mostrarBotaoExcluirIndicador(element: any): boolean{
		return !this.visualizar && this.model.idSituacaoPaa === 1 && element.peaRelacionado == false;
	  }

	  mostrarBotaoAdicionarCriterio(): boolean{
		return this.model.idSituacaoPaa === 1 && !this.visualizar
	  }

	  mostrarBotaoAdicionarIndicador(): boolean{
		return this.model.idSituacaoPaa === 1 && !this.visualizar
	  }

	  mostrarBotaoQuestionario(): boolean{
		return this.model.idSituacaoPaa === 1 && !this.visualizar
	  }

	  mostrarBotaoAbrirModalRankingPrioridade(element: IPaaAbaICIndicadorModel): boolean{
		return element.idIndicador != 0;
	  }

	  
}

