import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { IAgentePublicoModel } from "src/app/models/agente-publico.model";
import { IAuditorCoordenacaoModel } from "src/app/models/auditor-coordenacao.model";
import {
	MatDialog,
	MatDialogConfig,
} from "@angular/material/dialog";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import { defaultDataAcessor } from "src/app/core/helpers/mat-sort-data-accessor";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AgentePublicoService } from "src/app/services/agente-publico.service";
import Swal from 'sweetalert2';

const sortingDataAccessor = (item, property) => {
	switch (property) {
	  case 'nome':
		return item.pessoa.nome;
	  case 'cpfFormatted':
		return item.pessoa.cpf;
	  default:
		return item[property];
	}
  };

@Component({
	selector: "app-aba-dados-basicos",
	templateUrl: "./aba-dados-basicos.component.html",
	styleUrls: ["./aba-dados-basicos.component.scss"],
})
export class AgentePublicoAbaDadosBasicosComponent extends BaseFormComponent{
	@Input() public visualizar;

	public model : IAgentePublicoModel;
	public aba = 0;
	public auditorCoordenacoesDataSource = new MatTableDataSource<IAuditorCoordenacaoModel>([]);
	public auditorCoordenacaoSemDados = true;
	public displayedColumns: string[] = ['nome', 'cargo', 'situacao'];
  
	public form = new FormGroup({
	  nome: new FormControl(null, Validators.required),
	  cpf: new FormControl(null, Validators.required),
	  numeroFuncional: new FormControl(null, Validators.required)
	});


	constructor(
		public route: ActivatedRoute,
		public toastr: ToastrService,
		public router: Router,
		public localeService: BsLocaleService,
		public matDialog: MatDialog,
		private agentePublicoService: AgentePublicoService,
		
	) {
		super(route, toastr, router, localeService, matDialog);
		this.titulo = 'Agente público';
		
	}

  
	async ngOnInit(): Promise<void> {
		await this.buscar();
	}


  public async buscar() {

	const res = await this.agentePublicoService.getDadosBasicos(this.id);
	if (res.sucesso) {
	  this.model = res.dados;
	  this.auditorCoordenacoesDataSource = new MatTableDataSource<IAuditorCoordenacaoModel>(this.model.auditorCoordenacoes);
	  this.auditorCoordenacaoSemDados = this.auditorCoordenacoesDataSource.filteredData.length === 0;
	} else {
		this.exibirMensagemErro(res.mensagem.descricao);
    	this.router.navigate(['/tipo-enfase']);
	  return;
	}

	this.form.controls['nome'].setValue(this.model.pessoa.nome);
	this.form.controls['cpf'].setValue(this.model.pessoa.cpfFormatted);
	this.form.controls['numeroFuncional'].setValue(this.model.numeroFuncional);

	}

	private atualizarModel(values: any) {
		Object.assign(this.model, values);
	  }
	
	  public onBack() {
		this.router.navigate(['/agente-publico']);
	  }
	  
	  public cancelar() {
		this.router.navigate(['/agente-publico']);
	  }


}
