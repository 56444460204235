import { IOsaModel } from 'src/app/models/osa-model';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { OsaService } from 'src/app/services/osa.service';

@Component({
  selector: 'app-osa-abas',
  templateUrl: './osa-abas.component.html',
  styleUrls: ['./osa-abas.component.scss']
})
export class OsaAbasComponent implements OnInit, OnChanges {
  public aba = 0;
  @Input() public visualizar: boolean;
  @Input() public numeroOpa: any;
  @Input() public planejamentoId: number = null;
  @Output() public abaEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Input() public id: number;
  @Input() obterDados: () => void;
  public model: IOsaModel = {} as IOsaModel

  constructor(
    public router: Router,
    private osaService: OsaService,
    private activatedRoute: ActivatedRoute,
    ) {}

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    const numeroAba = this.activatedRoute.snapshot.queryParamMap.get('aba');
    if (numeroAba) { this.mudarAba(Number(numeroAba)); }

    this.router.url.includes("aba=6") ? this.mudarAba(6) : null;
    this.obter()
  }

  public obter(){
    this.osaService.obterPorId(this.id).then((res) => {
      this.model = res.dados
    })
    this.obterDados()
  }

  public mudarAba(ind: number) {
    this.aba = ind;
    this.abaEmitter.next(this.aba);
  }
}
