import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { ITipoCriterioModel } from 'src/app/models/tipo-criterio.model';
import { TipoCriterioService } from 'src/app/services/tipo-criterio.service';
import Swal from 'sweetalert2';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';

@Component({
  selector: 'app-tipo-criterio-form',
  templateUrl: './tipo-criterio-form.component.html',
  styleUrls: ['./tipo-criterio-form.component.scss'],
})
export class TipoCriterioFormComponent extends BaseFormComponent implements OnInit {
  public model: ITipoCriterioModel = {} as ITipoCriterioModel;

  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private tipoCriterioService: TipoCriterioService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Tipo de Critério';
    }
  }

  async ngOnInit() {

    try {
      if (!this.novoRegistro) {
        const res = await this.tipoCriterioService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;
          if (this.visualizar) {
            this.titulo = 'Visualizar Tipo de Critério - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Tipo de Critério - ' + this.model.nome;
          }
        } else {
          this.toastr.error(res.mensagem.descricao, 'Atenção');
          this.router.navigate(['/tipos-criterio']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
      this.router.navigate(['/tipos-criterio']);
    } 
  }

  public async onSubmit(salvarEFechar: boolean = false) {
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }

    this.submit = true;

    this.atualizarModel(this.form.value);

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        res = await this.tipoCriterioService.atualizar(this.model);
      } else {
        res = await this.tipoCriterioService.inserir(this.model);
      }

      if (res.sucesso == false) 
      {
        this.exibirMensagemAlerta(res.mensagem.descricao);
        return;  
      }  

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 15000,
          timerProgressBar: true,
          icon: 'success',
          text: res.mensagem.descricao
        });
        if (salvarEFechar) {
          this.router.navigate(['/tipos-criterio']);
        } else
        if(this.novoRegistro){
          this.router.navigate([`/tipos-criterio/${res.dados.id}`]);
          await this.ngOnInit();
        }
      } else {
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta('Formulário inválido');
      }
    }
    catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 15000,
        timerProgressBar: true,
        icon: 'error',
        text: err.mensagem.descricao
      });
    } 
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.submit = false;
    this.router.navigate(['/tipos-criterio']);
  }

  public cancelar() {
    this.router.navigate(['/tipo-criterio']);
  }
}
