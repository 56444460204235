import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { ICapituloRelatorioModel } from 'src/app/models/capitulo-relatorio.model';
import { IModeloRelatorioModel } from 'src/app/models/modelo-relatorio.model';
import { ModeloRelatorioService } from 'src/app/services/modelo-relatorio.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import Swal from 'sweetalert2';
import { ModalCapituloComponent } from './modal-capitulo/modal-capitulo.component';
import { IFluxoAprovacaoModel } from 'src/app/models/fluxo-aprovacao.model';
import { FluxoAprovacaoService } from 'src/app/services/fluxo-aprovacao.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CapituloRelatorioService } from 'src/app/services/capitulo-relatorio.service';
@Component({
  selector: 'app-modelo-relatorio-form',
  templateUrl: './modelo-relatorio-form.component.html',
  styleUrls: ['./modelo-relatorio-form.component.scss']
})
export class ModeloRelatorioFormComponent extends BaseFormComponent implements OnInit {
  public model: IModeloRelatorioModel = {} as IModeloRelatorioModel;
  public capitulosDataSource = new MatTableDataSource<ICapituloRelatorioModel>([]);
  public capitulosRelatorio = [] as ICapituloRelatorioModel[];
  public tiposTrabalhos = [
    {codigo: 1, nome:'PEAC', descricao: 'PEAC'},
    {codigo: 2, nome:'PAAC', descricao: 'PAAC'},
    {codigo: 8, nome:'PAAC - Especial', descricao: 'PAAC - Especial'},
    {codigo: 3, nome:'OPA', descricao: 'OPA'},
    {codigo: 7, nome:'Planejamento', descricao: 'Planejamento'},
    {codigo: 4, nome:'OSA', descricao: 'OSA'},
  ] ;
  public semDados = true;

  public selectedModel: IModeloRelatorioModel;
  public searchNome: string;
  public searchAtivo = true;
  public displayedColumns: string[] = [
    'ordem', 'nome', 'tipo', 'actions'
  ];

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
    tipoTrabalhoId: new FormControl(null, Validators.required)
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    spinner: NgxSpinnerService,
    router: Router,
    localeService: BsLocaleService,
    private modeloRelatorioService: ModeloRelatorioService,
    private capituloRelatorioService: CapituloRelatorioService,
    private fluxoAprovacaoService: FluxoAprovacaoService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Modelo de Relatório';
    }
  }

  async ngOnInit() {
    this.buscar();
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }

  public ordenaCapitulos(){

    //Ordena capitulos pai
    this.model.capitulos.sort((capituloA, capitulob) => {
      console.log(capituloA);
      console.log(capitulob);
      const ordemA = capituloA.ordem.split('.').map(num => parseFloat(num));
      const ordemB = capitulob.ordem.split('.').map(num => parseFloat(num));

      // Compara cada parte da ordem
      for (let i = 0; i < Math.max(ordemA.length, ordemB.length); i++) {
        const partA = ordemA[i] || 0;
        const partB = ordemB[i] || 0;

        if (partA < partB) return -1;
        if (partA > partB) return 1;
      }
      return 0;
    });

    //ordenacapito filhos

    this.model.capitulos.forEach(subCapitulos =>{
      if(subCapitulos.subCapitulos.length){
        subCapitulos.subCapitulos.sort((capituloA, capitulob)=>{
          const ordemA = capituloA.ordem.split('.').map(num => parseFloat(num));
          const ordemB = capitulob.ordem.split('.').map(num => parseFloat(num));
          // Compara cada parte da ordem
          for (let i = 0; i < Math.max(ordemA.length, ordemB.length); i++) {
            const partA = ordemA[i] || 0;
            const partB = ordemB[i] || 0;

            if (partA < partB) return -1;
            if (partA > partB) return 1;
          }
          return 0;
        });
      }
    });
  }
  public async buscar() {
    try {
      if (!this.novoRegistro) {
        const res = await this.modeloRelatorioService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;
          this.ordenaCapitulos();
          if (this.visualizar) {
            this.titulo = 'Visualizar Modelo de Relatório - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Modelo de Relatório - ' + this.model.nome;
          }
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
          this.router.navigate(['/modelos-relatorio']);
          return;
        }
      }
      this.form.patchValue(this.model);
      this.semDados = this.model.capitulos.length == 0;
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
      this.router.navigate(['/modelos-relatorio']);
    } finally {
    }
  }

  public async salvarDados(salvarEFechar: boolean)
  {
    if (this.form.invalid) {
      this.exibirMensagemAlerta("Formulário invalido");
      return;
    }
    this.submit = true;

    this.model.capitulos.forEach(c => {
      if(c.subCapitulos){
        c.subCapitulos.forEach(sc => {
          sc.capituloRelatorioPai = null;
        });
      }
    });
    this.atualizarModel(this.form.value);

    try {
      let res: IBaseModel<IModeloRelatorioModel> = null;

      this.model.capitulos.forEach((element, idx) => {
          element.ordem = (idx+1).toString();
          if(element.subCapitulos){
            element.subCapitulos.forEach((subCapitulo, subIdx) => {
              subCapitulo.ordem = `${element.ordem}.${(subIdx).toString()}`;
            });
          }
      });

      if (!this.novoRegistro) {
        res = await this.modeloRelatorioService.atualizar(this.model);
      } else {
        res = await this.modeloRelatorioService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });

        if(salvarEFechar == true){
          this.router.navigate(['/modelos-relatorio']);
        }else{
          if(this.novoRegistro){
            this.router.navigate([`/modelos-relatorio/${res.dados.id}`]);
            this.buscar();
          }
        }

      } else {
        this.submit = false;
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    } catch (err) {
      this.submit = false;
      this.exibirMensagemErro(err.mensagem.descricao);
    } finally {
    }
  }

 public async onSubmit() {
    this.salvarDados(false);
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public excluirCapitulo(element: ICapituloRelatorioModel) {

    if (element.capituloRelatorioPaiId) {
      this.model.capitulos.forEach(capitulo => {
          if(capitulo.id == element.capituloRelatorioPaiId){
            capitulo.subCapitulos.splice(capitulo.subCapitulos.findIndex(x => x.ordem == element.ordem), 1);
            return;
          }
      });

    }
    else {

      if(element.capituloRelatorioPai){

        this.model.capitulos.forEach(capitulo => {
          if(capitulo.ordem == element.capituloRelatorioPai.ordem){
            capitulo.subCapitulos.splice(capitulo.subCapitulos.findIndex(x => x.ordem == element.ordem), 1);
            return;
          }
        });

      }else{
        this.model.capitulos.splice(this.model.capitulos.findIndex(_ => _.ordem === element.ordem), 1);
      }
    }

    this.semDados = this.model.capitulos.length === 0;
    this.reordenar();
  }

  public async exibirModalCapitulo(editar?: boolean, element?: ICapituloRelatorioModel, indice?: number, subCapitulo?: boolean, indexValue?: number) {
    try {
      if(this.form.invalid){
        this.exibirMensagemAlerta("Salve os dados anteriores");
        return;
      }

      const tipoDocumento = this.form.controls["tipoTrabalhoId"].value;

      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '1200px';
      dialogConfig.height = '600px';
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        element,
        indice,
        subCapitulo,
        editar,
        tipoDocumento
      };
      const modal = this.matDialog.open(ModalCapituloComponent, dialogConfig);

      modal.afterClosed().subscribe(async (data: ICapituloRelatorioModel) => {
        if (data) {
          try {
            if(!this.model){
              this.model = {} as IModeloRelatorioModel;
            }
            if(!this.model.capitulos){
              this.model.capitulos = [] as ICapituloRelatorioModel[];
            }

            if (data.id) {
              if (element && subCapitulo || data.capituloRelatorioPaiId) {
                const capituloPai = this.model.capitulos.find(_ => _.id === element.capituloRelatorioPaiId);
                capituloPai.subCapitulos[indice] = data ;
              }
              return;
            }

            if (element && subCapitulo) {

              if (!element.subCapitulos) { element.subCapitulos = [] as ICapituloRelatorioModel[]; }

              const capituloPai = this.model.capitulos.find(_ => _.id === element.capituloRelatorioPaiId);
              const indexUpdate = capituloPai?.subCapitulos?.findIndex(_ => _.ordem === element.ordem);
              if (indexUpdate >= 0) {
                capituloPai.subCapitulos.splice(indexUpdate, 1);
                data.capituloRelatorioPaiId = capituloPai.id;
                data.modeloRelatorioId = capituloPai.modeloRelatorioId;
                capituloPai.subCapitulos.push(data);
              }
              else {
                data.ordem = `${element.ordem}.${(element.subCapitulos.length + 1).toString()}`;
                data.capituloRelatorioPaiId = element.id;
                data.modeloRelatorioId = element.modeloRelatorioId;
                data.capituloRelatorioPai = element;
                element.subCapitulos.push(data);
              }
            }
            else if (element) {
              this.model.capitulos.splice(indice, 1);
              data.ordem = (this.model.capitulos.length + 1).toString();
              data.modeloRelatorioId = element.modeloRelatorioId;
              this.model.capitulos.push(data);
            }
            else {
              data.ordem = (this.model.capitulos.length + 1).toString();
              this.model.capitulos.push(data);
            }

          } catch (error) {
            this.exibirMensagemErro("Ocorreu um erro inesperado. Entre em contato com o administrador.");
          } finally {
          }
        }
      });

    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    } finally {
    }
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if(event.previousContainer === event.container){
      moveItemInArray(this.model.capitulos, event.previousIndex, event.currentIndex);
    }else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.model.capitulos.forEach((element, idx) => {
      element.ordem = (idx+1).toString();
      if(element.subCapitulos){
        element.subCapitulos.forEach((subCapitulo, subIdx) => {
          subCapitulo.ordem = `${element.ordem}.${(subIdx).toString()}`;
        });
      }

    });
  }
  onDropItens(event: CdkDragDrop<string[]>,_elememt:any) {
    if(event.previousContainer === event.container){
      moveItemInArray(_elememt.subCapitulos, event.previousIndex, event.currentIndex);
    }else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.model.capitulos.forEach((element, idx) => {
      element.ordem = (idx + 1).toString();
      if(element.subCapitulos){
        element.subCapitulos.forEach((subCapitulo, subIdx) => {
          subCapitulo.ordem = `${element.ordem}.${(subIdx + 1).toString()}`;
        });
      }

    });
  }
  public retornaCapitulosArray(): ICapituloRelatorioModel[]{
    let itens= [] as ICapituloRelatorioModel[];

    this.model.capitulos.forEach(capitulo => {
      itens.push(capitulo);
      if(capitulo.subCapitulos){
        capitulo.subCapitulos.forEach(subCapitulo => {
          subCapitulo.subCapitulos = null;
          itens.push(subCapitulo);
        });
      }
    });

    return itens;
  }

  public reordenar() {

    this.model.capitulos.forEach((element, idx) => {
      element.ordem = (idx + 1).toString();
      if(element.subCapitulos){
        element.subCapitulos.forEach((subCapitulo, subIdx) => {
          subCapitulo.ordem = `${element.ordem}.${(subIdx + 1).toString()}`;
        });
      }

    });
  }

  public avaliarCapitulo(id: number, status: boolean) {
    var name: string = "";
    if (status) {
      name = 'Aprovar'
    } else {
      name = 'Reprovar'
    }
    Swal.fire({
      title: name,
      text: 'Tem certeza que deseja ' + name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(res => {
      if (res.value) {
        this.capituloRelatorioService.aprovarReprovarCapitulo(id, status).then(res => {
          if (res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
            this.buscar();
          } else {
            this.exibirMensagemErro(res.mensagem.descricao);
          }
        }).catch(err => {
          this.exibirMensagemErro(err.mensagem.descricao);
        })
      }
    })
  }

  public onBack() {
    this.router.navigate(['/modelos-relatorio']);
  }
}
