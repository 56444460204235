<div [ngClass]="{'emAprovacao': removeMinuta }">
    <div class="content-header doNotPrint">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb float-sm-left">
                        <li class="breadcrumb-item">
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-outline card-lightblue">
                        <div class="card-header doNotPrint">
                            <h3 class="card-title mb-0 ">
                                <i
								*ngIf="this.id"
								class="fas fa-pencil-alt pr-2"
							></i> Visualizar Relatoria {{dadosObtidos?.tipoTrabalho.nome}} - {{dadosObtidos?.nomeDocumento}}
                            </h3>
                        </div>
                        <form class="px-3">
                            <app-relatoria 
                            [impressao]="true" 
                            [aba]="5" visualizar="true" 
                            [dadosObtidos]="dadosObtidos" 
                            [relatoriaDados]="relatoriaDados"
                            [visualizarRelatoriaHidden]="visualizarRelatoriaHidden">
                            </app-relatoria>
                        </form>
                        <div class="card-footer doNotPrint">
                            <button type="button" class="btn btn-default btn-sm mr-1" (click)="backClicked()">
                                <i class="fas fa-times"></i> Fechar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>
