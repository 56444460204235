<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i> Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item active">
						Lista de Questionários
					</li>
				</ol>
			</div>
		</div>
	</div>
</div>

<div class="content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card card-outline card-lightblue">
					<div class="card-header">
						<h3 class="card-title mb-0">
							<i class="fas fa-table pr-2"></i> Lista de
							Questionários
						</h3>
					</div>

					<div class="card-body p-2">
						<div class="mat-elevation-z4">
							<mat-toolbar class="table-header">
								<mat-toolbar-row>
									<button
										type="button"
										class="btn btn-success btn-sm mr-1"
										[disabled]="!selectedModel || selectedModel.status === 'Enviado' || selectedModel.status === 'Expirado'" 
										(click)="
											responder(selectedModel.id)
										"
									>
										<i class="far fas fa-pen"></i> Responder
									</button>
									<button
										type="button"
										class="btn btn-info btn-sm mr-1"
										[disabled]="!selectedModel"
										(click)="
											visualizarQuestionario(
												selectedModel.id
											)
										"
									>
										<i class="far fa-eye"></i> Visualizar
									</button>
									<button
									type="button"
									class="btn btn-primary btn-sm mr-1"
									[disabled]="!selectedModel"
									(click)="print(selectedModel.id)">
									<i class="fas fa-print"></i> Imprimir
								  	</button>
									
									<span class="fill-remaining-space"></span>
									<div class="input-group input-group-sm" style="width: 200px;">
										<input type="text" name="table_search" class="form-control float-right"
											(keyup.enter)="buscar()" placeholder="Pesquisar" [(ngModel)]="searchNome" />
										<div class="input-group-append">
											<button type="button" class="btn btn-info" (click)="buscar()">
												<i class="fas fa-search"></i>
											</button>
										</div>
									</div>
								  	<div class="btn-group">
										<button type="button" data-toggle="dropdown"
										class="btn btn-tool btn-default dropdown-toggle">
										<i class="fas fa-filter"></i>
										</button>
										<div role="menu" class="dropdown-menu dropdown-menu-right dropdown-menu-checkbox">
											<a (click)="filtro.situacao = 'Todos'; buscar()" class="dropdown-item"><i 
												[ngClass]="filtro.situacao === 'Todos'
														? 'fas fa-check-circle'
														: 'far fa-circle'
													"></i>Exibir Todos</a>

											<a (click)="filtro.situacao = 'Pendente'; buscar()" class="dropdown-item"><i 
											[ngClass]="filtro.situacao === 'Pendente'
													? 'fas fa-check-circle'
													: 'far fa-circle'
												"></i>Exibir somente Pendentes</a>

											<a (click)="filtro.situacao = 'Enviado'; buscar()" class="dropdown-item"><i 
											[ngClass]="filtro.situacao === 'Enviado'
														? 'fas fa-check-circle'
														: 'far fa-circle'
													"></i>Exibir somente Enviado</a>

											<a (click)="filtro.situacao = 'Em réplica'; buscar()" class="dropdown-item"><i 
											[ngClass]="filtro.situacao === 'Em réplica'
														? 'fas fa-check-circle'
														: 'far fa-circle'
													"></i>Exibir somente Em Réplica</a>

											<a (click)="filtro.situacao = 'Finalizado'; buscar()" class="dropdown-item"><i 
											[ngClass]="filtro.situacao === 'Finalizado'
														? 'fas fa-check-circle'
														: 'far fa-circle'
													"></i>Exibir somente Finalizado</a>

											<a (click)="filtro.situacao = 'Expirado'; buscar()" class="dropdown-item"><i 
											[ngClass]="filtro.situacao === 'Expirado'
														? 'fas fa-check-circle'
														: 'far fa-circle'
													"></i>Exibir somente Expirado</a>
										</div>
									</div>																
								</mat-toolbar-row>
							</mat-toolbar>
                            <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear matSortActive="id"
                            matSortDirection="asc" class="mat-elevation-z8" [hidden]="semDados" (matSortChange)="sortData($event)">
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns:
                                                displayedColumns; " (click)="selecionar(row)" class="clickable"
                                                [ngClass]="{'bg-light': row.selecionado}">
                                            </tr>
                            <ng-container matColumnDef="selecao">
                              <th mat-header-cell *matHeaderCellDef> </th>
                              <td mat-cell *matCellDef="let element">
                                <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                                  (change)="selectedModel = ($event.checked ? element : null)"
                                  [checked]="selectedModel?.id === element.id">
                                </mat-checkbox>
                              </td>
                            </ng-container>
            
                            <ng-container matColumnDef="nomeFuncionalidade">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome da Funcionalidade </th>
                              <td mat-cell *matCellDef="let element"> {{element.nomeFuncionalidade}} </td>
                            </ng-container>
            
                            <ng-container matColumnDef="siglaUG">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> UG </th>
                              <td mat-cell *matCellDef="let element"> {{element.siglaUG}} </td>
                            </ng-container>
          
                            <ng-container matColumnDef="responsavel">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Responsável </th>
                              <td mat-cell *matCellDef="let element"> {{element.responsavel}} </td>
                            </ng-container>

                            <ng-container matColumnDef="dataEnvio">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data de Envio </th>
                                <td mat-cell *matCellDef="let element"> {{element.dataEnvio}} </td>
                              </ng-container>

                              <ng-container matColumnDef="dataPrazo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Prazo </th>
                                <td mat-cell *matCellDef="let element"> {{element.dataPrazo}} </td>
                              </ng-container>

                              <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                              </ng-container>
          

            
                          </table>
							<h5 [hidden]="!semDados" class="table-no-data">
								Nenhum questionário disponível
							</h5>
							<mat-paginator
								[pageSizeOptions]="[15, 30, 50]"
								showFirstLastButtons
								(page)="selectedModel = null"
							>
							</mat-paginator>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
