<div id="modal-content-wrapper"  role="dialog" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
    <div class="modal-content">
      <h5 class="modal-title">Incluir responsável</h5>
      <div class="modal-body">
        <div>
          <div class="row">
            <div class="form-group col-md-3">
              <label for="" class="mb-1">CPF*</label>
              <div class="input-group input-group-sm" style="width: 100%">
                <input
                  type="text"
                  [readonly]="visualizar ? true : null"
                  [(ngModel)]="model.cpf"
                  class="form-control form-control-sm"
                  data-inputmask="'mask': '999.999.999-99'"
                  maxlength="14"
                  [ngClass]="{ 'is-invalid': submited && !model.cpf }"
                />
                <div class="input-group-append">
                  <button
                    [hidden]="visualizar ? true : null"
                    type="button"
                    class="btn btn-info"
                    (click)="obterPorCpf()"
                  >
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
              <div *ngIf="submited && !model.cpf" class="invalid-feedback">
                <div>Campo obrigatório</div>
              </div>
            </div>
            <div class="form-group col-md-9">
              <label for="" class="mb-1">Nome*</label>
              <div class="input-group input-group-sm" style="width: 100%">
                <input
                  [(ngModel)]="model.nome"
                  disabled
                  type="text"
                  class="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="" class="mb-1">E-mail</label>
              <div class="input-group input-group-sm" style="width: 100%">
                <input
                  [(ngModel)]="model.email"
                  disabled
                  type="text"
                  id=""
                  class="form-control form-control-sm"
                />
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="rg" class="mb-1">RG*</label>
              <div class="input-group input-group-sm" style="width: 100%">
                <input
                  [(ngModel)]="model.rg"
                  [readonly]="visualizar ? true : null"
                  type="text"
                  id="rg"
                  class="form-control form-control-sm"
                  maxlength="50"
                  [ngClass]="{ 'is-invalid': submited && !model.rg }"
                />
                <div *ngIf="submited && !model.rg" class="invalid-feedback">
                  <div>Campo obrigatório</div>
                </div>
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="" class="mb-1">Cargo</label>
              <div class="input-group input-group-sm" style="width: 100%">
                <input
                  [(ngModel)]="model.tipoAgente"
                  disabled
                  type="text"
                  id=""
                  class="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-2">
              <label for="" class="mb-1">CEP</label>
              <div class="input-group input-group-sm" style="width: 100%">
                <input
                  [(ngModel)]="model.cep"
                  disabled
                  type="text"
                  id=""
                  class="form-control form-control-sm"
                />
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="" class="mb-1">Logradouro</label>
              <div class="input-group input-group-sm" style="width: 100%">
                <input
                  [(ngModel)]="model.logradouro"
                  disabled
                  type="text"
                  id=""
                  class="form-control form-control-sm"
                />
              </div>
            </div>
            <div class="form-group col-md-2">
              <label for="" class="mb-1">N°</label>
              <div class="input-group input-group-sm" style="width: 100%">
                <input
                  [(ngModel)]="model.numero"
                  disabled
                  type="text"
                  id=""
                  class="form-control form-control-sm"
                  maxlength="10"
                />
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="" class="mb-1">Bairro</label>
              <div class="input-group input-group-sm" style="width: 100%">
                <input
                  [(ngModel)]="model.bairro"
                  disabled
                  type="text"
                  id=""
                  class="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="" class="mb-1">Complemento</label>
              <div class="input-group input-group-sm" style="width: 100%">
                <input
                  [(ngModel)]="model.complemento"
                  disabled
                  type="text"
                  id=""
                  class="form-control form-control-sm"
                />
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="" class="mb-1">Município</label>
              <div class="input-group input-group-sm" style="width: 100%">
                <input
                  [(ngModel)]="model.municipio"
                  disabled
                  type="text"
                  id=""
                  class="form-control form-control-sm"
                />
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="" class="mb-1">Estado</label>
              <div class="input-group input-group-sm" style="width: 100%">
                <input
                  [(ngModel)]="model.estado"
                  disabled
                  type="text"
                  id=""
                  class="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card card-outline">
          <div class="card-header">
            <h3 class="card-title mb-0">Períodos de Exercício</h3>
          </div>
          <div class="card-body p-0">
            <div>
              <mat-toolbar class="table-header">
                <mat-toolbar-row>
                  <button
                    [hidden]="visualizar"
                    type="button"
                    class="btn btn-success btn-sm mr-1"
                    (click)="modalIncluirPeriodoExercicio()"
                  >
                    <i class="fas fa-plus"></i> Adicionar
                  </button>
                </mat-toolbar-row>
              </mat-toolbar>
              <table class="table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th colspan="3"></th>
                    <th colspan="4" class="text-center bg-light">
                      Ato de Nomeação/Designação
                    </th>
                    <th></th>
                    <th colspan="4" class="text-center bg-light">
                      Ato de Exoneração
                    </th>
                    <th></th>
                  </tr>
                  <tr>
                    <th class="bg-light">Tipo de Agente</th>
                    <th class="bg-light">Natureza da responsabilidade</th>
                    <th class="bg-light">Data Início</th>
                    <th class="bg-light">Tipo de Ato</th>
                    <th class="bg-light">Número</th>
                    <th class="bg-light">Data do Ato</th>
                    <th class="bg-light">Data da Publicação</th>
                    <th class="bg-light">Data Fim</th>
                    <th class="bg-light">Tipo de Ato</th>
                    <th class="bg-light">Número</th>
                    <th class="bg-light">Data do Ato</th>
                    <th class="bg-light">Data da Publicação</th>
                    <th class="bg-light">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let element of dataSource.data; let i = index">
                    <td>{{ element.tipoAgente?.descricao }}</td>
                    <td>{{ element.naturezaResponsabilidade?.nome }}</td>
                    <td>{{ element.dataInicio | date: "dd/MM/yyyy" }}</td>
                    <td>{{ element.atoNomeacao?.tipoAto?.descricao }}</td>
                    <td>{{ element.atoNomeacao?.numero }}</td>
                    <td>
                      {{ element.atoNomeacao?.dataAto | date: "dd/MM/yyyy" }}
                    </td>
                    <td>
                      {{
                        element.atoNomeacao?.dataPublicacao | date: "dd/MM/yyyy"
                      }}
                    </td>
                    <td>{{ element.dataFim | date: "dd/MM/yyyy" }}</td>
                    <td>{{ element.atoExoneracao?.tipoAto?.descricao }}</td>
                    <td>{{ element.atoExoneracao?.numero }}</td>
                    <td>
                      {{ element.atoExoneracao?.dataAto | date: "dd/MM/yyyy" }}
                    </td>
                    <td>
                      {{
                        element.atoExoneracao?.dataPublicacao | date: "dd/MM/yyyy"
                      }}
                    </td>
                    <td>
                      <button
                        [hidden]="visualizar"
                        type="button"
                        class="btn btn-primary btn-xs text-left"
                        (click)="modalIncluirPeriodoExercicio(true, element, i)"
                      >
                        <i class="far fas fa-pen"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group" [hidden]="visualizar">
          <button class="btn btn-primary btn-sm mr" (click)="concluir(false)">
            <i class="far fa-save"></i> Salvar
          </button>
          <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
          <div class="dropdown-menu" role="menu">
            <a class="dropdown-item" (click)="concluir(false)">Salvar</a>
            <a class="dropdown-item" (click)="concluir(true)">Salvar e fechar</a>
          </div>
        </div>
  
        <button
          [hidden]="visualizar ? true : null"
          type="button"
          class="btn btn-default btn-sm"
          (click)="cancelar()"
        >
          <i class="fas fa-times"></i> Cancelar
        </button>
  
        <button
          [hidden]="!visualizar ? true : null"
          type="button"
          class="btn btn-default btn-sm"
          (click)="fechar()"
        >
          <i class="fas fa-times"></i> Fechar
        </button>
      </div>
    </div>
  </div>
</div>
