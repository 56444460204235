import { Component, OnInit } from '@angular/core';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-base-list',
  template: ``
})
export class BaseListComponent implements OnInit {

  public permissoes: IPermissaoModel;
  
  constructor() { }

  ngOnInit(): void {
  }

  public exibirMensagemAlerta(mensagem: string, html: boolean = false){
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'warning',
      text: html ? '' : mensagem,
      html: html ? mensagem : '',
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  public exibirMensagemSucesso(mensagem: string){
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'success',
      text: mensagem,
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
    });
  }

  public exibirMensagemErro(mensagem: string){
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      text: mensagem,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

}
