import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IParametrizacaoModel } from '../models/parametrizacao.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ParametrizacaoService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string): Promise<IBaseModel<IParametrizacaoModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    return this.httpClient
      .get<IBaseModel<IParametrizacaoModel[]>>(`${this.apiBaseUrl}/parametrizacao`, { params })
      .toPromise();
  }

  public async obterPorId(id: string): Promise<IBaseModel<IParametrizacaoModel>> {
    return this.httpClient
      .get<IBaseModel<IParametrizacaoModel>>(`${this.apiBaseUrl}/parametrizacao/${id}`)
      .toPromise();
  }

  public async inserir(data: IParametrizacaoModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/parametrizacao`, data)
      .toPromise();
  }

  public async atualizar(data: IParametrizacaoModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/parametrizacao/${data.id}`, data)
      .toPromise();
  }

  public async atualizaTodos(data: IParametrizacaoModel[]): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/parametrizacao`, data)
      .toPromise();
  }

  public async excluir(id: string): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/parametrizacao/${id}`)
      .toPromise();
  }
}
