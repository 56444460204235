import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { IAgentePublicoModel } from "src/app/models/agente-publico.model";
import { IAuditorCoordenacaoModel } from "src/app/models/auditor-coordenacao.model";
import {
	MatDialog,
	MatDialogConfig,
} from "@angular/material/dialog";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import { defaultDataAcessor } from "src/app/core/helpers/mat-sort-data-accessor";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AgentePublicoService } from "src/app/services/agente-publico.service";
import Swal from 'sweetalert2';
import { IAgentePublicoDisponibilidadeModel } from "src/app/models/agente-publico-disponibilidade.model";
import { SharedService } from "src/app/services/shared.service";
import { Subscription } from "rxjs";

const sortingDataAccessor = (item, property) => {
	switch (property) {
	  case 'nome':
		return item.pessoa.nome;
	  case 'cpfFormatted':
		return item.pessoa.cpf;
	  default:
		return item[property];
	}
  };

@Component({
	selector: "app-aba-disponibilidade",
	templateUrl: "./aba-disponibilidade.component.html",
	styleUrls: ["./aba-disponibilidade.component.scss"],
})
export class AgentePublicoAbaDisponibilidadeComponent extends BaseFormComponent{
	
	@Input() public visualizar;

	clickEventSubscription: Subscription;
	public model : IAgentePublicoModel;
	public aba = 0;
	public selectedModelYears: Array<number> = [];
	public selectedYear: number;
	public tiposTrabalhosInputs: IAgentePublicoDisponibilidadeModel[] = [] as IAgentePublicoDisponibilidadeModel[];
	public disponibilidadeDataSource = new MatTableDataSource<IAgentePublicoDisponibilidadeModel>([]);
  
	public form =  new FormGroup({
		nome: new FormControl(null, Validators.required),
	});


	constructor(
		public route: ActivatedRoute,
		public toastr: ToastrService,
		public router: Router,
		public localeService: BsLocaleService,
		public matDialog: MatDialog,
		private agentePublicoService: AgentePublicoService,
		private sharedService: SharedService,
		
	) {
		super(route, toastr, router, localeService, matDialog);
		this.titulo = 'Agente público';
		this.clickEventSubscription = this.sharedService
			.getEvent()
			.subscribe(() => {
				this.onSalvar();
			});
	}

  
	async ngOnInit(): Promise<void> {
		await this.popularComboAnos();
		this.buscar();
	}


  public async buscar() {

	const res = await this.agentePublicoService.getDadosDisponibilidade(this.id, this.selectedYear);
	if(res.sucesso){
		this.model = res.dados;
		this.titulo = "Agente Público: " + this.model.pessoa.nome;
		this.tiposTrabalhosInputs = this.model.disponibilidades;
		this.disponibilidadeDataSource = new MatTableDataSource<IAgentePublicoDisponibilidadeModel>(this.model.disponibilidades);
	}

	}

	private atualizarModel(values: any) {
		Object.assign(this.model, values);
	  }
	
	  public onBack() {
		this.router.navigate(['/agente-publico']);
	  }
	  
	  public cancelar() {
		this.router.navigate(['/agente-publico']);
	  }

	  public async popularComboAnos(){
		const anoAtual = new Date().getFullYear();
		this.selectedModelYears.push(anoAtual + 4);
		this.selectedModelYears.push(anoAtual + 3);
		this.selectedModelYears.push(anoAtual + 2);
		this.selectedModelYears.push(anoAtual + 1);
		this.selectedModelYears.push(anoAtual);
		this.selectedModelYears.push(anoAtual - 1);
		this.selectedModelYears.push(anoAtual - 2);
		this.selectedYear = anoAtual;
	  }

	  public calculateTotal() {
		
		let total = 0;
		if(this.disponibilidadeDataSource.data.length > 0){
			total = this.disponibilidadeDataSource.data
			.map((item) => item.percentual)
			.reduce((acum, currentElement) => {
				return acum + currentElement;
			});
		}

		return total;
	  }

	  public async onSalvar(){
		
		try{
			const res = await this.agentePublicoService.salvarDadosDisponibilidade(this.model.disponibilidades);
			if(res.sucesso)
				this.exibirMensagemSucesso(res.mensagem.descricao);
			else
				this.exibirMensagemErro(res.mensagem.descricao);
		}catch (err){
			this.exibirMensagemErro(err);
		}

	  }

	  public async onSelectTipoAuditoria(element) {
		this.buscar();
	  }

}

