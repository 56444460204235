<div class="header-imprimir">
	<img
		src="assets/img/logo_es_color.png"
		alt="SECONT-SIAC"
		class="brand-image"
		style="opacity: 0.8"
	/>
	<div class="title">
		<h4><strong>Governo do Estado do Espírito Santo</strong></h4>
		<h5>Secretaria de Estado de Controle e Transparência (SECONT)</h5>
	</div>
</div>
<div [ngClass]="{'emAprovacao': removeMinuta}">
    <div class="content-header doNotPrint">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb float-sm-left">
                        <li class="breadcrumb-item"></li> 
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-11">
                    <div class="card card-outline card-lightblue">
                        <div class="card-header doNotPrint">
                            <h3 class="card-title mb-0 ">
                                <i *ngIf="this.id" class="card-title mb-0"></i>
                                Relatoria - {{tipoTrabalhoDescricao}} - {{dadosObtidos?.nomeDocumento}}
                            </h3>
                        </div>
                        <form class="px-3">
                            <app-relatoria 
                                [impressao]="true" 
                                [aba]="5" 
                                visualizar="true" 
                                [dadosObtidos]="dadosObtidos" 
                                [relatoriaDados]="relatoriaDados"
                                [visualizarRelatoriaHidden]="visualizarRelatoriaHidden">
                            </app-relatoria>
                        </form>
                        <div>
                            <div *ngFor="let element of datas; let i = index">
                                <div *ngFor="let item of element.acoes">
                                    <div class="final-section px-3">
                                        <p *ngIf="item.acao?.codigo === 3" >
                                            <span>{{ item.pessoa?.nome }}</span><br>
                                            <span>Elaborador</span>
                                        </p>

                                        <p *ngIf="item.acao?.codigo === 1" >
                                            <span>{{ item.pessoa?.nome }}</span><br/>
                                            <span>{{ item.pessoa?.funcao?.descricao }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                        <div class="rodape container-fluid">
                            <hr class="linha-rodape">
                            <div class="row justify-content-center">
                                <div class="col-md-10">
                                    <p>
                                        Av. João Batista Parra, nº. 600, Ed. Aureliano Hoffman, 10º andar, Enseada do Suá, Vitória /ES - Cep: 29050-375
                                        Tel: (27) 3636-5352<br>
                                        www.secont.es.gov.br
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer doNotPrint">
                            <button type="button" class="btn btn-default btn-sm mr-1" (click)="backClicked()">
                                <i class="fas fa-times"></i> Fechar
                            </button>
                            <button type="button" class="btn btn-primary btn-sm mr-1" (click)="print()">
                                <i class="fas fa-print"></i> Imprimir
                            </button>
                        </div>
                    </div>                  
                </div>
            </div>
        </div>
    </div> 
</div>
