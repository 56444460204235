import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { PeaFormComponent } from 'src/app/pages/pea-form/pea-form.component';

@Injectable()
export class PeaGuard implements CanDeactivate<PeaFormComponent> {
  canDeactivate(component: PeaFormComponent): Observable<boolean> | boolean {
    if (component.novoRegistro) {
      if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    } else { return true; }
  }
}
