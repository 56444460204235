import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { ISubtipoAuditoriaModel } from '../models/subtipo-auditoria.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class SubtipoAuditoriaService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa?: string, ativo?: boolean, simplificado = false): Promise<IBaseModel<ISubtipoAuditoriaModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<ISubtipoAuditoriaModel[]>>(`${this.apiBaseUrl}/subtipoauditoria`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<ISubtipoAuditoriaModel>> {
    return this.httpClient
      .get<IBaseModel<ISubtipoAuditoriaModel>>(`${this.apiBaseUrl}/subtipoauditoria/${id}`)
      .toPromise();
  }

  public async obterPorIds(ids: number[]): Promise<IBaseModel<ISubtipoAuditoriaModel>> {
    return this.httpClient
      .get<IBaseModel<ISubtipoAuditoriaModel>>(`${this.apiBaseUrl}/subtipoauditoria/${ids}/obterporids`)
      .toPromise();
  }

  public async inserir(data: ISubtipoAuditoriaModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/subtipoauditoria`, data)
      .toPromise();
  }

  public async atualizar(data: ISubtipoAuditoriaModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/subtipoauditoria/${data.id}`, data)
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/subtipoauditoria/${id}`, {})
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/subtipoauditoria/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/subtipoauditoria/${id}/inativar`, {})
      .toPromise();
  }

  public obterAtivos(): Promise<IBaseModel<ISubtipoAuditoriaModel[]>> {
    return this.httpClient
      .get<IBaseModel<ISubtipoAuditoriaModel[]>>(`${this.apiBaseUrl}/subtipoauditoria/ativos`)
      .toPromise();
  }
}
