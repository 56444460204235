<!-- ABA INFORMAÇÕES BÁSICAS -->
<!-- <form [formGroup]="form" #f="ngForm" > -->
<div class="card-body">
	<div class="row">
		<div class="col-md-6">
			<table mat-table 
			[dataSource]="disponibilidadeDataSource"
			class="w-100">
			<tr	mat-header-row	*matHeaderRowDef="['tipoTrabalho', 'percentual']"></tr>
			
			<ng-container matColumnDef="tipoTrabalho">
				<th	mat-header-cell	*matHeaderCellDef>	Tipo Trabalho	</th>
				<td	mat-cell	*matCellDef="let element">
					{{ element.tipoTrabalho.nome }}
				</td>
				<td mat-footer-cell *matFooterCellDef>TOTAL</td>
			</ng-container>

			<ng-container matColumnDef="percentual">
				<th	mat-header-cell	*matHeaderCellDef>	Percentual	</th>
				<td	mat-cell	*matCellDef="let element">
					<mat-form-field floatLabel="never">
						<input matInput type="number" min="0" max="100" [value]="element.percentual" [(ngModel)]="element.percentual">
					</mat-form-field>
				</td>
				<td mat-footer-cell *matFooterCellDef>{{ calculateTotal() }}</td>
			</ng-container>
			<tr
										mat-header-row
										*matRowDef="
										let row;
										columns:['tipoTrabalho', 'percentual']"
										class="bg-light"
									></tr>
									<tr mat-footer-row class="sticky-footer" *matFooterRowDef="['tipoTrabalho','percentual']"></tr>
		</table>
		</div>
		
		<div class="col-sm-6">
			<label >Ano</label>
			<select  id="selectUnidade" 
				[(ngModel)]="selectedYear"
				(change)="onSelectTipoAuditoria($event)"
				[ngModelOptions]="{standalone: true}" class="mat-form-ano form-control
				form-control-sm">
					<option *ngFor="let item of selectedModelYears" 
						[ngValue]="item">
												{{item}}
					</option>
			</select>
		</div>


	</div>
	</div>
<!-- </form> -->
