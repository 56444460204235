import { IAssinaturaModel } from './../../../models/assinatura.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPlanejamentoImprimirModel } from 'src/app/models/planejamento-imprimir.model';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import Swal from 'sweetalert2';
import { toFormatValue, toRemoveCharacters } from 'src/app/shared/util/toFormatValue';

@Component({
  selector: 'app-imprimir',
  templateUrl: './imprimir.component.html',
  styleUrls: ['./imprimir.component.scss']
})
export class ImprimirComponent implements OnInit {

  public model: IPlanejamentoImprimirModel = {} as IPlanejamentoImprimirModel;
  public homologado = false;
  public id: number


  constructor(
    private planejamentoService: PlanejamentoService,
    public route: ActivatedRoute,
  ) {
    this.id = +this.route.snapshot.paramMap.get('id');
   }

  ngOnInit(): void {
  this.obter()
  }


  public obter(){
    this.planejamentoService.imprimir(this.id).then((res) => {
      if(res.sucesso){
        this.model = res.dados
        if (res?.dados?.status?.codigo === 4) {
          this.homologado = true;
        }
      }else{
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    })
  }

  public cancelar() {
    window.history.back()
  }

  public print() {
    window.print();
  }

  public formatarInput(value) {
    return toFormatValue(value);
  }
}
