import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { ICriterioQuestionarioModel } from '../models/criterio-questionario.model';
import { IQuestionarioModel } from '../models/questionario.model';
import { BaseService } from './base.service';
import { IQuestionarioPaaModel } from '../models/questionario.paa.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionarioService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public async enviarQuestionariosPea(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/pea/${id}/enviar-questionario`, {})
      .toPromise();
  }

  public async obterQuestionariosPendentePea(pesquisa: string): Promise<IBaseModel<IQuestionarioModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    return this.httpClient
      .get<IBaseModel<IQuestionarioModel[]>>(`${this.apiBaseUrl}/questionario/pea`, { params })
      .toPromise();
  }

  public async obterQuestionarioPendentePorPeaId(id: number, criterioId: number, unidadeGestoraId: number): Promise<IBaseModel<IQuestionarioModel>> {
    return this.httpClient
      .get<IBaseModel<IQuestionarioModel>>(`${this.apiBaseUrl}/questionario/pea/${id}/criterio/${criterioId}/ug/${unidadeGestoraId}`)
      .toPromise();
  }

  public async salvarRespostasQuestionarioPorPeaId(id: number, data: ICriterioQuestionarioModel[]): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/questionario/pea/${id}`, data)
      .toPromise();
  }

  public async enviarRespostasQuestionarioPorPeaId(id: number, data: ICriterioQuestionarioModel[]): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/questionario/pea/${id}/enviar`, data)
      .toPromise();
  }

  public async obterQuestionariosPendentePaa(pesquisa: string): Promise<IBaseModel<IQuestionarioModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    return this.httpClient
      .get<IBaseModel<IQuestionarioModel[]>>(`${this.apiBaseUrl}/questionario/paa`, { params })
      .toPromise();
  }

  public async obterQuestionarioPendentePorPaaId(id: number, idCriterio: number, unidadeGestoraId: number): Promise<IBaseModel<IQuestionarioPaaModel>> {
    return this.httpClient
      .get<IBaseModel<IQuestionarioPaaModel>>(`${this.apiBaseUrl}/questionario/paa/${id}/criterio/${idCriterio}/ug/${unidadeGestoraId}`)
      .toPromise();
  }

  public async salvarRespostasQuestionarioPorPaaId(id: number, data: ICriterioQuestionarioModel[]): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/questionario/paa/${id}`, data)
      .toPromise();
  }

  public async enviarRespostasQuestionarioPorPaaId(id: number, data: ICriterioQuestionarioModel[]): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/questionario/paa/${id}/enviar`, data)
      .toPromise();
  }
}
