import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ICalculoPeaRankingPrioridadeModel } from 'src/app/models/calculo-pea-ranking-prioridade.model';
import { IPeaRankingPrioridadeModel } from 'src/app/models/pea-ranking-prioridade.model';

@Component({
  selector: 'app-modal-detalhar-valor-risco',
  templateUrl: './modal-detalhar-valor-risco.component.html',
  styleUrls: ['./modal-detalhar-valor-risco.component.scss']
})
export class ModalDetalharValorRiscoComponent implements OnInit {
  public model = {} as IPeaRankingPrioridadeModel;
  public dataSource = new MatTableDataSource<ICalculoPeaRankingPrioridadeModel>([]);
  public displayedColumns = ['criterio', 'peso', 'nota', 'total'];
  public semDados = true;

  constructor(
    private dialogRef: MatDialogRef<ModalDetalharValorRiscoComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.model = data;
    this.dataSource = new MatTableDataSource<ICalculoPeaRankingPrioridadeModel>(data.calculos);
  }

  ngOnInit(): void {
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public fechar() {
    this.dialogRef.close();
  }
}

