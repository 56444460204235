import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IRecomendacaoModel } from '../models/recomendacao.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class RecomendacaoService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean): Promise<IBaseModel<IRecomendacaoModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    return this.httpClient
      .get<IBaseModel<IRecomendacaoModel[]>>(`${this.apiBaseUrl}/recomendacao`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IRecomendacaoModel>> {
    return this.httpClient
      .get<IBaseModel<IRecomendacaoModel>>(`${this.apiBaseUrl}/recomendacao/${id}`)
      .toPromise();
  }

  public async inserir(data: IRecomendacaoModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/recomendacao`, data)
      .toPromise();
  }

  public async atualizar(data: IRecomendacaoModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/recomendacao/${data.id}`, data)
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/recomendacao/${id}`, {})
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/recomendacao/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/recomendacao/${id}/inativar`, {})
      .toPromise();
  }
}
