<aside class="main-sidebar sidebar-dark-primary elevation-4">
	<!-- Brand Logo -->
	<a [routerLink]="['/']" class="brand-link">
		<img src="assets/img/logo_es.png" alt="SECONT-SIAC" class="brand-image" style="opacity: 0.8" />
		<span class="brand-text font-weight-light">SECONT-SIAC</span>
	</a>

	<!-- Sidebar -->
	<div
		class="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-transition os-host-overflow-x">
		<div class="os-padding">
			<div class="os-viewport os-viewport-native-scrollbars-invisible">
				<div class="os-content">
					<!-- SidebarSearch Form -->

					<nav class="mt-2">
						<ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
							data-accordion="false">
							
							<!-- Link da home -->
							<li class="nav-item">
								<a [routerLink]="['/']" [routerLinkActive]="['active']"
									[routerLinkActiveOptions]="{ exact: true }" class="nav-link">
									<i class="fas fa-home nav-icon"></i>
									<p>Home</p>
								</a>
							</li>

							<!--<app-menuitem [menuItems]="model.items"></app-menuitem>-->
							<li class="nav-item" *ngFor = "let item of model.items" style="padding-left: 10px;">
							
								<a *ngIf="item.url != ''" [routerLink]="[item.url]" [routerLinkActive]="['active']" class="nav-link" role="link">
									<i class="{{item.icone}}"></i>
									<p style="padding-left: 10px;">{{item.nome}}</p>
								</a>
							
							
								<div *ngIf="item.url == '' && item.itemsFilhos.length > 0" class="nav-link" role="link">
									<i class="{{item.icone}}"></i>
									<a>        
										<p style="padding-left: 10px;">{{item.nome}}</p>  
									</a>
								</div>
							
							

								<ul *ngIf="item.url == ''  && item.itemsFilhos.length > 0" class="nav nav-treeview" style="display: none" app-menuitem [menuName]="item.nome" [menuItems]="item.itemsFilhos">
									
								</ul>
							</li>							
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</div>
	<!-- /.sidebar -->
</aside>