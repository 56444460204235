<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/subtipo-auditoria']">Subtipo de auditoria</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>
          </div>
          <form [formGroup]="form" #f="ngForm" >
            <div class=" card-body">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="nome" class="mb-1">Nome</label>
                  <input [attr.disabled]="visualizar ? true : null" type="text" id="nome" class="form-control form-control-sm" formControlName="nome"
                    maxlength="100" [ngClass]="{ 'is-invalid': f.submitted && form.controls['nome'].invalid }" />
                  <div *ngIf="f.submitted && form.controls['nome'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['nome'].errors.required">Campo obrigatório</div>
                  </div>
                </div>

              </div>

              <div class="row">
                <div class="form-group col-md-12">
                  <label for="inputDescricao" class="mb-1">Descrição</label>
                  <angular-editor [config]="config" id="descricao" rows="20" formControlName="descricao" [ngClass]="{
                    'is-invalid':
                      f.submitted && form.controls['descricao'].invalid
                  }"></angular-editor>
                  <div *ngIf="f.submitted && form.controls['descricao'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['descricao'].errors.required">Campo obrigatório</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">

                    <div class="form-group col-md-12">
                      <label for="inputMatrizPlanejamento" class="mb-1">Matriz de planejamento</label>
                      <div  *ngIf="!visualizar" class="input-group input-group-sm" style="width: 100%;">
                        <input type="text" id="inputMatrizPlanejamento" class="form-control form-control-sm" readonly
                          [ngModel]="modelSubtipoAuditoriaMatrizPlanejamento.matrizPlanejamento?.nome" [ngModelOptions]="{standalone: true}" />
                        <div class=" input-group-append">
                          <button type="button" class="btn btn-info" (click)="exibirModalMatrizPlanejamento()">
                            <i class="fas fa-search"></i>
                          </button>
                          <button type="button" class="btn btn-success" (click)="incluirMatrizPlanejamento()"
                              [disabled]="!(modelSubtipoAuditoriaMatrizPlanejamento.matrizPlanejamentoId)">
                            <i class="fas fa-plus"></i>
                          </button>
                          <button type="button" class="btn btn-success ml-1" (click)="cadastroRapidoMatrizPlanejamento()">
                            <i class="fas fa-plus"></i> Adicionar
                          </button>
                        </div>
                      </div>
                    </div>

                  <div class="row">
                    <div class="form-group col-md-12">
                      <div class="mat-elevation-z1">
                        <table mat-table [dataSource]="matrizPlanejamentoDataSource">
                          <tr mat-header-row *matHeaderRowDef="['nome', 'actions']" class="bg-light"></tr>

                          <ng-container matColumnDef="nome">
                            <th mat-header-cell *matHeaderCellDef>Nome da matriz </th>
                            <td mat-cell *matCellDef="let element"> {{ element.matrizPlanejamento.nome }}</td>
                          </ng-container>

                          <ng-container cdkColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element; let i = index" class="block">
                              <div class="btn-group">
                                <button type="button" class="btn btn-info btn-xs text-left"
                                (click)="visualizarMatrizPlanejamento(element.matrizPlanejamento.id)">
                                <i class="far fas fa-eye"></i> </button>
                                <button *ngIf="!visualizar" type="button" class="btn btn-danger btn-xs text-left"
                                  (click)="excluirMatrizPlanejamento(i)">
                                  <i class="fa fa-trash"></i></button>
                              </div>
                            </td>
                          </ng-container>

                          <tr mat-row *matRowDef="let row; columns: ['nome','actions'];"></tr>
                        </table>
                        <h5 [hidden]="!semDados" class="table-no-data">Nenhuma matriz de planejamento cadastrada</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="card-footer">
              <div class="btn-group" [hidden]="visualizar">
                  <button class="btn btn-primary btn-sm mr" (click)="onSubmit(false)">
                      <i class="far fa-save"></i> Salvar
                  </button>
                  <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                  <div class="dropdown-menu" role="menu">
                      <a class="dropdown-item" (click)="onSubmit(false)">Salvar</a>
                      <a class="dropdown-item" (click)="onSubmit(true)">Salvar e fechar</a>
                  </div>
              </div>
          
              <button type="button" class="btn btn-default btn-sm" (click)="onBack()">
                  <i class="fas fa-times"></i> {{visualizar ? 'Sair' : 'Cancelar' }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
