<div class="card-body">
  <div [hidden]="aba !== 5 || relatoriaDados || visualizar">
    <div class="row">
      <div class="form-group col-md-6">
        <label for="modeloRelatorioId" class="mb-1">Modelo de Relatório</label>
        <div class="input-group input-group-sm" style="width: 100%;">
          <input type="text" id="modeloRelatorioId" class="form-control form-control-sm" readonly
            [ngModel]="model?.modeloRelatorio?.nome" [ngModelOptions]="{ standalone: true }" />
          <div class="input-group-append">
            <button [hidden]="visualizar" [disabled]="!model?.habilitarGerarRelatoria" type="button" class="btn btn-info mr-1"
              (click)="exibirModalModeloRelatorio()">
              <i class="fas fa-search"></i>
            </button>
          </div>
          <button [hidden]="visualizar" type="button" class="btn btn-success btn-sm" (click)="gerar()"  [disabled]="!model?.habilitarGerarRelatoria && !model?.modeloRelatorio?.nome">
            Gerar
          </button>
        </div>
      </div>
    </div>
  </div>
  <div  [hidden]="aba !== 5 || relatoriaDados !== null">
    <p>Ainda não existe um relatorio gerado</p>
  </div>

  <div  [hidden]="aba !== 5 || !relatoriaDados">
    <div class="row">
      <div  class="form-group col-md-6">
        <label class="mb-1"> Modelo de Relatório : {{this.dadosObtidos?.modeloRelatorio?.nome}}</label>
      </div>
    </div>
    <app-capitulo 
      [impressao]="impressao"  
      [relatoriaDados]="relatoriaDados" 
      [somenteLeitura]="visualizar" 
      [id]="id" 
      [dadosObtidos]="dadosObtidos"  
      [obterDados]="obterDados"
      [model]="model" 
      [reprovado]="reprovado" 
      [homologado]="homologado"
      [obterRelatorio]="obterRelatorio"
      [tipoTrabalhoRef]="tipoTrabalhoRef"></app-capitulo>
  </div>
</div>

<div class="card-footer">
  <button
								[hidden]="tipoTrabalho != 2"
								type="button"
								class="btn btn-default btn-sm mr-1"
								(click)="cancelar()"
							>
								<i class="fas fa-times"></i> Fechar
							</button>
  <button
              [hidden]="visualizar || (tipoTrabalho != 2 &&  tipoTrabalho != 8)"
              type="button"
              class="btn btn-warning btn-sm mr-1"
              (click)="enviarAprovacao()"
              [disabled]="desabilitarBotaoEnviarAprovacao()"
            >
              <i class="far fa-save"></i> Enviar para
              Aprovação
            </button>
</div>