<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-12">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/']">
                            <i class="fas fa-home"></i> Página Inicial</a>
                    </li>
                    <li class="breadcrumb-item active">PEAC - Plano Estratégico
                        de Auditoria e Controles</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-lightblue">
                    <div class="card-header">
                        <h3 class="card-title mb-0">
                            <i class="fas fa-table pr-2"></i> PEAC - Plano
                            Estratégico de Auditoria e Controles
                        </h3>
                    </div>

                    <div class="card-body p-2">
                        <div class="mat-elevation-z4">
                            <mat-toolbar class="table-header">
                                <mat-toolbar-row>
                                    <button type="button" class="btn btn-success
                                        btn-sm mr-1" (click)="novo()" [hidden]="!permissoes.incluir">
                                        <i class="far fa-file"></i> Novo
                                    </button>
                                    <button type="button" class="btn btn-primary
                                        btn-sm mr-1" (click)="editar()"
                                        [hidden]="botaoEditarHidden()">
                                        <i class="far fas fa-pen"></i> Editar
                                    </button>
                                    <button type="button" class="btn btn-info
                                        btn-sm mr-1" [hidden]="botaoImpessaoIndividualHidden()"
                                        (click)="relatorioSimplificado()">
                                        <i class="far fas fa-print"></i>
                                        Imprimir Individual
                                    </button>
                                    <button type="button" class="btn btn-info
                                        btn-sm mr-1" [hidden]="botaoImpressaoColetivaHidden()"
                                        (click)="relatorioConsolidado()">
                                        <i class="far fas fa-print"></i>
                                        Imprimir Consolidado
                                    </button>
                                    <button type="button" class="btn btn-warning
                                        btn-sm mr-1" [hidden]="botaoReativarHidden()" mwlConfirmationPopover popoverTitle="Reativar"
                                        popoverMessage="Tem certeza que deseja
                                        reativar esse registro?" confirmButtonType="primary" placement="bottom"
                                        confirmText="Sim" cancelText="Não" (confirm)="reativar()">
                                        <i class="far fa-play-circle"></i>
                                        Reativar
                                    </button>
                                    <button type="button" class="btn btn-warning
                                        btn-sm mr-1"
                                        [hidden]="botaoInativarHidden()"
                                        mwlConfirmationPopover popoverTitle="Inativar" popoverMessage="Tem certeza que deseja
                                        inativar esse registro?" confirmButtonType="primary" placement="bottom"
                                        confirmText="Sim" cancelText="Não" (confirm)="inativar()">
                                        <i class="far fa-pause-circle"></i>
                                        Inativar
                                    </button>
                                    <button type="button" class="btn btn-danger btn-sm mr-1" 
                                        [hidden]="botaoExcluirHidden()" mwlConfirmationPopover
                                        popoverTitle="Excluir" popoverMessage="Deseja realmente excluir o período?"
                                        placement="bottom" confirmText="Sim" confirmButtonType="primary"
                                        cancelText="Não" (confirm)="excluir()">
                                        <i class="fa fa-trash"></i>
                                        Excluir
                                    </button>
                                    <button type="button" class="btn btn-info
                                        btn-sm mr-1" [hidden]="botaoVisualizarHidden()"
                                        (click)="visualizar()">
                                        <i class="far fas fa-eye"></i>
                                        Visualizar
                                    </button>
                                    <select [hidden]="selectAnoHidden()" id="selectUnidade" [(ngModel)]="selectedYear"
                                        [ngModelOptions]="{standalone: true}" class="mat-form-ano form-control
                                        form-control-sm">
                                        <option [ngValue]="undefined">Selecione
                                            um ano</option>
                                        <option *ngFor="let item of
                                            selectedModelYears" [ngValue]="item">
                                            {{item}}
                                        </option>
                                    </select>
                                    <button type="button" class="btn btn-success
                                        btn-sm mr-1" [hidden]="botaoGerarPAAHidden()" (click)="gerarPaa()">
                                        <i class="far fas fa-print"></i> Gerar
                                        PAAC
                                    </button>
                                    <span class="fill-remaining-space"></span>
                                    <div class="input-group input-group-sm" style="width: 200px;">
                                        <input type="text" name="table_search" class="form-control float-right"
                                            (keyup.enter)="buscar()" placeholder="Pesquisar"
                                            [(ngModel)]="searchNome" />

                                        <div class="input-group-append">
                                            <button type="button" class="btn
                                                btn-info" (click)="buscar()">
                                                <i class="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="btn-group">
                                        <button type="button" data-toggle="dropdown" class="btn
                                            btn-tool btn-default
                                            dropdown-toggle">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                        <div role="menu" class="dropdown-menu
                                            dropdown-menu-right
                                            dropdown-menu-checkbox">
                                            <a (click)="atualizarFiltroStatus(undefined)" class="dropdown-item"><i [ngClass]="searchAtivo ===
                                                    undefined
                                                    ? 'fas fa-check-circle'
                                                    : 'far fa-circle'"></i>Exibir
                                                todos</a>
                                            <a (click)="atualizarFiltroStatus(true)" class="dropdown-item"><i [ngClass]="searchAtivo ===
                                                    true
                                                    ? 'fas fa-check-circle'
                                                    : 'far fa-circle'"></i>Exibir
                                                somente ativos</a>
                                            <a (click)="atualizarFiltroStatus(false)" class="dropdown-item"><i [ngClass]="searchAtivo ===
                                                    false
                                                    ? 'fas fa-check-circle'
                                                    : 'far fa-circle'"></i>Exibir
                                                somente inativos</a>
                                        </div>
                                    </div>
                                </mat-toolbar-row>
                            </mat-toolbar>
                            <table mat-table [dataSource]="dataSource"  matSort matSortDisableClear class="w-100 mat-elevation-z8"
                            matSortActive="nome" matSortDirection="desc" [hidden]="semDados" (matSortChange)="sortData($event)">

                                <ng-container matColumnDef="selecao">
                                    <th mat-header-cell *matHeaderCellDef> </th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-checkbox (click)="$event.stopPropagation()" 
                                            color="primary" 
                                            (change)="selecionar(element);" 
                                            [checked]="element?.selecionado">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="nome">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Nome
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.nome}} </td>
                                </ng-container>

                                <ng-container matColumnDef="periodo">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                        Periodo
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.periodo?.nome}} </td>
                                </ng-container>

                                <ng-container matColumnDef="indicador">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                        Indicador
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.indicador?.nome}} </td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                        Status </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="element.ativo" class="badge
                                            bg-success text-xs">Ativo</span>
                                        <span *ngIf="!element.ativo" class="badge bg-gray-dark text-xs">Inativo</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="situacao">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                        Situação
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="element.situacao?.nome
                                            === 'Homologado'" class="badge bg-primary text-xs">Homologado</span>
                                        <span *ngIf="element.situacao?.nome
                                            === 'EmAprovacao'" class="badge bg-warning text-xs">Em
                                            Aprovação</span>
                                        <span *ngIf="element.situacao?.nome
                                            === 'EmElaboracao'" class="badge bg-success text-xs">Em
                                            Elaboração</span>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns:
                                    displayedColumns; " (click)="selecionar(row)" class="clickable"
                                    [ngClass]="{'bg-light': row.selecionado}">
                                </tr>

                                <!-- Group header -->
                                <ng-container matColumnDef="groupHeader">
                                    <td colspan="999" mat-cell *matCellDef="let
                                        groupBy"><strong>Período
                                            {{groupBy.nome}} -
                                            {{groupBy.dataInicio |
                                            date:'dd/MM/yyyy'}} a
                                            {{groupBy.dataFim |
                                            date:'dd/MM/yyyy'}} </strong>
                                    </td>
                                </ng-container>

                                <!-- <tr mat-row (click)="selecionar(row)" class="clickable" *matRowDef="let row;
                                    columns: ['selecao', 'groupHeader']; when:
                                    itemAgrupador"> </tr> -->
                            </table>
                            <h5 [hidden]="!semDados" class="table-no-data">Nenhum
                                registro encontrado.</h5>
                            <mat-paginator #TableOnePaginator="matPaginator" class="mt-4"
                                [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons [length]="totalItens"
                                (page)="pageEvent = pageChanged($event)">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>