import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IRelatoriaCapituloModel } from 'src/app/models/relatoria-capitulo.model';
import { IRelatoriaModel } from 'src/app/models/relatoria.model';
import { RelatoriaService } from 'src/app/services/relatoria.service';
import { Location } from '@angular/common';
import { BaseFormComponent } from '../../base-form/base-form.component';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { AprovacaoService } from 'src/app/services/aprovacao.service';
import { IBaseModel } from 'src/app/models/base.model';

@Component({
  selector: 'app-imprimir-relatoria',
  templateUrl: './imprimir-relatoria.component.html',
  styleUrls: ['./imprimir-relatoria.component.scss']
})

export class ImprimirRelatoriaComponent extends BaseFormComponent implements OnInit {
  relatoriaDados: IRelatoriaCapituloModel[];
  public dadosObtidos: IRelatoriaModel;
  espelho = false;
  tipoTrabalho;
  activatedRoute: any;
  @Output() public abaRelatoria: EventEmitter<number> = new EventEmitter<number>();
  @Output() public voltarAbaRelatoria: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private relatoriaService: RelatoriaService,
    private aprovacaoService: AprovacaoService,
    public matDialog: MatDialog,
  ) {
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {

    const path = this.route.routeConfig.path?.toString();
    if (path.includes('espelho-relatorio')) {
      this.espelho = true;
    }

    const tipoTrabalho = this.route.snapshot.paramMap.get('tipoTrabalho');
    switch (tipoTrabalho) {
      case '1':
        this.tipoTrabalho = 1;
        break;
      case '2':
        this.tipoTrabalho = 2;
        break;
      case '3':
        this.tipoTrabalho = 3;
        break;
      case '4':
        this.tipoTrabalho = 4;
        break;
      case '7':
        this.tipoTrabalho = 7;
        break;
        case '8':
          this.tipoTrabalho = 8;
          break;
    }    

    this.obter();
  }

  public async obter() {
    let res: IBaseModel<IRelatoriaModel>;

    if (this.id) {

      if (this.espelho) {
        const avaliacao = await this.aprovacaoService.imprimirRelatoria(this.id);
        res = {
          sucesso: avaliacao.sucesso,
          dados: JSON.parse(avaliacao.dados),
          mensagem: avaliacao.mensagem,
          resultadoValidacao: avaliacao.resultadoValidacao
        } as IBaseModel<IRelatoriaModel>;
      }
      else {
        res = await this.relatoriaService.obterPorTipoTrabalho(this.id, this.tipoTrabalho);
      }

      if (res.sucesso) {        
        this.relatoriaDados = res.dados.relatoriasCapitulos;
        this.dadosObtidos = res.dados;
      }
      else {
        this.toastr.error(res.mensagem.descricao, 'Atenção');
        return null;
      }
    }
  }

  print(): void {
    window.print();
  }

  backClicked() {
    window.history.back()
  }

  VoltarRelatoria() {
    this.abaRelatoria.emit(5);
    this.voltarAbaRelatoria.emit(true);
    this.router.navigate(['/pea/', this.id]);    
  }
}
