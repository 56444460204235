<div id="modal-content-wrapper" class="modal-dialog-scrollable">
  <div class="card card-secondary card-outline w-100">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">Incluir Período de exercício</h4>
      <div class="card-tools"></div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-md-6">
          <label for="selectTipo" class="mb-1">Tipo de Agente</label>
          <select
            id="selectTipo"
            [attr.disabled]="visualizar ? true : null"
            [ngModel]="model.tipoAgente?.codigo"
            class="form-control form-control-sm"
            [ngClass]="{ 'is-invalid': submited && !model.tipoAgente?.codigo }"
            (change)="onTipoAgenteChange($event)"
          >
            <option *ngFor="let item of enumTipoAgente" [value]="item.codigo">
              {{ item.descricao }}
            </option>
          </select>
          <div
            *ngIf="submited && !model.tipoAgente?.codigo"
            class="invalid-feedback"
          >
            <div>Campo obrigatório</div>
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="naturezaResponsabilidadeNome" class="mb-1"
            >Natureza da Responsabilidade</label
          >
          <div class="input-group input-group-sm" style="width: 100%">
            <input
              type="text"
              id="naturezaResponsabilidadeNome"
              class="form-control form-control-sm"
              readonly
              [ngModel]="model.naturezaResponsabilidade?.nome"
              [ngClass]="{
                'is-invalid': submited && !model.naturezaResponsabilidade?.nome
              }"
            />
            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-info"
                (click)="exibirModalNaturezaResponsabilidade()"
              >
                <i class="fas fa-search"></i>
              </button>
            </div>
            <div
              *ngIf="submited && !model.naturezaResponsabilidade?.nome"
              class="invalid-feedback"
            >
              <div>Campo obrigatório</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-secondary card-outline">
        <div class="card-header bg-light">
          <h4 class="card-title mb-0">Ata de Nomeação/Designação</h4>
          <div class="card-tools"></div>
        </div>
        <div class="card-body">
          <div class="row">

            <div class="form-group col-md-2">
              <label for="data" class="mb-1">Data início</label>
              <input
              *ngIf="dataInicio"
                type="text"
                id="data"
                class="form-control form-control-sm suaClassDatePicker"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                [(ngModel)]="dataInicio"
                (bsValueChange)="onDataInicioChange($event)"
              />
              <input
                *ngIf="!dataInicio"
                type="text"
                id="data"
                class="form-control form-control-sm suaClassDatePicker"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                [ngClass]="{ 'is-invalid': submited && !model.atoNomeacao?.dataPublicacao }"
                (bsValueChange)="onDataInicioChange($event)"
              />
            </div>

            <div class="form-group col-md-2">
              <label for="selectTipo" class="mb-1">Tipo de Ato</label>
              <select
                id="selectTipo"
                [attr.disabled]="visualizar ? true : null"
                [ngModel]="model.atoNomeacao?.tipoAto?.codigo"
                class="form-control form-control-sm"
                [ngClass]="{
                  'is-invalid': submited && !model.tipoAgente?.codigo
                }"
                (change)="onTipoAtoNomeacaoChange($event)"
              >
                <option *ngFor="let item of enumTipoAto" [value]="item.codigo">
                  {{ item.descricao }}
                </option>
              </select>
              <div
                *ngIf="submited && !model.tipoAgente?.codigo"
                class="invalid-feedback"
              >
                <div>Campo obrigatório</div>
              </div>
            </div>

            <div class="form-group col-md-2">
              <label for="numeroAtoNomeacao" class="mb-1">Número</label>
              <input
                type="text"
                id="codigoUg"
                class="form-control form-control-sm"
                [attr.disabled]="visualizar ? true : null"
                [ngClass]="{ 'is-invalid': submited && !model.atoNomeacao?.numero }"
                [ngModel]="model.atoNomeacao?.numero"
                (change)="onNumeroNomeacaoChange($event.target.value)"
                maxlength="10"
                type="number"
              />
              <div *ngIf="submited && !model.atoNomeacao?.numero" class="invalid-feedback">
                <div>Campo obrigatório</div>
              </div>
            </div>

            <div class="form-group col-md-3">
              <label for="data" class="mb-1">Data do Ato</label>
              <input
              *ngIf="dataAtoNomeacao"
                type="text"
                id="data"
                class="form-control form-control-sm suaClassDatePicker"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                [ngClass]="{ 'is-invalid': submited && !model.atoNomeacao?.dataAto }"
                [(ngModel)]="dataAtoNomeacao"
                (bsValueChange)="onDataAtoNomeacaoChange($event)"
              />
              <input
                *ngIf="!dataAtoNomeacao"
                type="text"
                id="data"
                class="form-control form-control-sm suaClassDatePicker"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                [ngClass]="{ 'is-invalid': submited && !model.atoNomeacao?.dataPublicacao }"
                (bsValueChange)="onDataAtoNomeacaoChange($event)"
              />
              <div *ngIf="submited && !model.atoNomeacao?.dataAto" class="invalid-feedback">
                <div>Campo obrigatório</div>
              </div>
            </div>

            <div class="form-group col-md-3">
              <label for="data" class="mb-1">Data da Publicação</label>
              <input
              *ngIf="dataPublicacaoNomeacao"
                type="text"
                id="data"
                class="form-control form-control-sm suaClassDatePicker"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                [ngClass]="{ 'is-invalid': submited && !model.atoNomeacao?.dataPublicacao }"
                [(ngModel)]="dataPublicacaoNomeacao"
                (bsValueChange)="onDataPublicacaoNomeacaoChange($event)"
              />
              <input
                *ngIf="!dataPublicacaoNomeacao"
                type="text"
                id="data"
                class="form-control form-control-sm suaClassDatePicker"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                [ngClass]="{ 'is-invalid': submited && !model.atoNomeacao?.dataPublicacao }"
                (bsValueChange)="onDataPublicacaoNomeacaoChange($event)"
              />
              <div *ngIf="submited && !model.atoNomeacao?.dataPublicacao" class="invalid-feedback">
                <div>Campo obrigatório</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-secondary card-outline">
        <div class="card-header bg-light">
          <h4 class="card-title mb-0">Ato de Exoneração</h4>
          <div class="card-tools"></div>
        </div>
        <div class="card-body">          
          <div class="row">

            <div class="form-group col-md-2">
              <label for="dataFim" class="mb-1">Data Fim</label>
              <input
              *ngIf="dataFim"
                type="text"
                id="dataFim"
                class="form-control form-control-sm suaClassDatePicker"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                [ngClass]="{ 'is-invalid': submited && !model.dataFim }"
                [(ngModel)]="dataFim"
                (bsValueChange)="onDataFimChange($event)"
              />
              <input
                *ngIf="!dataFim"
                type="text"
                id="dataFim"
                class="form-control form-control-sm suaClassDatePicker"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                (bsValueChange)="onDataFimChange($event)"
              />
            </div>

            <div class="form-group col-md-2">
              <label for="selectTipo" class="mb-1">Tipo de Ato</label>
              <select
                id="selectTipo"
                [attr.disabled]="visualizar ? true : null"
                [ngModel]="model.atoExoneracao?.tipoAto?.codigo"
                class="form-control form-control-sm"
                [ngClass]="{
                  'is-invalid': submited && model.dataFim && !model.atoExoneracao?.tipoAto?.codigo
                }"
                (change)="onTipoAtoExoneracaoChange($event)"
              >
                <option *ngFor="let item of enumTipoAto" [value]="item.codigo">
                  {{ item.descricao }}
                </option>
              </select>
              <div
                *ngIf="submited && model.dataFim && !model.atoExoneracao?.tipoAto?.codigo"
                class="invalid-feedback"
              >
                <div>Campo obrigatório</div>
              </div>
            </div>

            <div class="form-group col-md-2">
              <label for="numeroAtoExoneracao" class="mb-1">Número</label>
              <input
                type="text"
                id="numeroAtoExoneracao"
                class="form-control form-control-sm"
                [attr.disabled]="visualizar ? true : null"
                [ngClass]="{ 'is-invalid': submited && model.dataFim && !model.atoExoneracao?.numero }"
                [ngModel]="model.atoExoneracao?.numero"
                (change)="onNumeroExoneracaoChange($event.target.value)"
                maxlength="10"
                type="number"
              />
              <div *ngIf="submited && model.dataFim && !model.atoExoneracao?.numero" class="invalid-feedback">
                <div>Campo obrigatório</div>
              </div>
            </div>

            <div class="form-group col-md-3">
              <label for="dataAtoExoneracao" class="mb-1">Data do Ato</label>
              <input
              *ngIf="dataAtoExoneracao"
                type="text"
                id="dataAtoExoneracao"
                class="form-control form-control-sm suaClassDatePicker"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                [ngClass]="{ 'is-invalid': submited && model.dataFim && !model.atoExoneracao?.dataAto }"
                [(ngModel)]="dataAtoExoneracao"
                (bsValueChange)="onDataAtoExoneracaoChange($event)"
              />
              <input
                *ngIf="!dataAtoExoneracao"
                type="text"
                id="dataAtoExoneracao"
                class="form-control form-control-sm suaClassDatePicker"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                [ngClass]="{ 'is-invalid': submited && model.dataFim && !model.atoExoneracao?.dataAto }"
                (bsValueChange)="onDataAtoExoneracaoChange($event)"
              />
              <div *ngIf="submited && model.dataFim && !model.atoExoneracao?.dataAto" class="invalid-feedback">
                <div>Campo obrigatório</div>
              </div>
            </div>

            <div class="form-group col-md-3">
              <label for="dataPublicacaoExoneracao" class="mb-1">Data da Publicação</label>
              <input
                *ngIf="dataPublicacaoExoneracao"
                type="text"
                id="dataPublicacaoExoneracao"
                class="form-control form-control-sm suaClassDatePicker"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                [ngClass]="{ 'is-invalid': submited && model.dataFim && !model.atoExoneracao?.dataPublicacao }"
                [(ngModel)]="dataPublicacaoExoneracao"
                (bsValueChange)="onDataPublicacaoExoneracaoChange($event)"
              />
              <input
                *ngIf="!dataPublicacaoExoneracao"
                type="text"
                id="dataPublicacaoExoneracao"
                class="form-control form-control-sm suaClassDatePicker"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                [ngClass]="{ 'is-invalid': submited && model.dataFim && !model.atoExoneracao?.dataPublicacao }"
                (bsValueChange)="onDataPublicacaoExoneracaoChange($event)"
              />
              <div *ngIf="submited && model.dataFim && !model.atoExoneracao?.dataPublicacao" class="invalid-feedback">
                <div>Campo obrigatório</div>
              </div>
            </div>
          </div>          
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="btn-group" [hidden]= "visualizar ? true : null">
        <button class="btn btn-primary btn-sm mr" (click)="concluir(false)">
          <i class="far fa-save"></i> Salvar
        </button>
        <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
        <div class="dropdown-menu" role="menu">
          <a class="dropdown-item" (click)="concluir(false)">Salvar</a>
          <a class="dropdown-item" (click)="concluir(true)">Salvar e fechar</a>
        </div>
      </div>

      <button
        [hidden]="visualizar ? true : null"
        type="button"
        class="btn btn-default btn-sm"
        (click)="cancelar()"
      >
        <i class="fas fa-times"></i> Cancelar
      </button>

      <button
        [hidden]="!visualizar ? true : null"
        type="button"
        class="btn btn-default btn-sm"
        (click)="fechar()"
      >
        <i class="fas fa-times"></i> Fechar
      </button>
    </div>
  </div>
</div>
