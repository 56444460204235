<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/unidades-gestoras']">Unidades Gestoras</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<ul class="nav nav-tabs ml-auto p-0">
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{ active: aba === 0 }" (click)="mudarAba(0)">
      Dados
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{ active: aba === 1 }" (click)="mudarAba(1)">
      Entendendo a Entidade
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{ active: aba === 2 }" (click)="mudarAba(2)">
      Maturidade de Risco
    </a>
  </li>
</ul>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <app-aba-dados *ngIf="aba === 0"></app-aba-dados>
        <app-aba-entendendo-entidade *ngIf="aba === 1"></app-aba-entendendo-entidade>
        <app-aba-maturidade-risco *ngIf="aba === 2"></app-aba-maturidade-risco>
      </div>
    </div>
  </div>
</div>
