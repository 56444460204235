import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { ILocalAtuacaoModel } from '../models/edocs/local-atuacao.model';
import { IPapelModel } from '../models/edocs/papel.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class EdocsService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public async obterPapeis(): Promise<IBaseModel<IPapelModel[]>> {
    return this.httpClient
      .get<IBaseModel<IPapelModel[]>>(`${this.apiBaseUrl}/edocs/papeis`)
      .toPromise();
  }

  public async obterLocaisAtuacao(idPapel: string): Promise<IBaseModel<ILocalAtuacaoModel[]>> {
    return this.httpClient
      .get<IBaseModel<ILocalAtuacaoModel[]>>(`${this.apiBaseUrl}/edocs/${idPapel}/locais-atuacao`)
      .toPromise();
  }
}
