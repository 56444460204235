import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {
  transform(dados: any[]): any {
    if (!dados  || !Array.isArray(dados)) {
      return [];
    }

    const dados2 = [];

    dados.forEach(element => {
        if (element && element.paaIndiceCriterio && element.paaIndiceCriterio.indicador && element.paaIndiceCriterio.indicador.nome) {
            if (!dados2[element.paaIndiceCriterio.indicador.nome]) {
                dados2[element.paaIndiceCriterio.indicador.nome] = [];
            }

            dados2[element.paaIndiceCriterio.indicador.nome].push(element);
        }
    });
    
    return dados2;
  }
}