import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IImprimirResponsavelUnidadeGestoraModel } from 'src/app/models/imprimir-responsavel-unidade-gestora-pai.model';
import { AuthService } from 'src/app/services/auth.service';
import { ResponsaveisService } from 'src/app/services/responsaveis.service ';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';
import { RolResponsaveisListComponent } from '../../rol-responsaveis-list/rol-responsaveis-list.component';

@Component({
  selector: 'app-imprimir-rol-responsavel-unidade-gestora',
  templateUrl: './imprimir-rol-responsavel-unidade-gestora.component.html',
  styleUrls: ['./imprimir-rol-responsavel-unidade-gestora.component.scss'],
})
export class ImprimirRolResponsavelUnidadeGestoraComponent extends BaseListComponent {
  public dataHoje = new Date();
  public unidadesGestoras = [] as IImprimirResponsavelUnidadeGestoraModel[];
  public displayedColumns: string[] = [
    'nome',
    'nomeNaturezaResponsabilidade',
    'tipoAgente',
    'dataInicio',
    'dataFim',
    'ativo',
  ];

  public searchNome: string;
  public searchAtivo = undefined;
  public dataInicio: Date;
  public dataFim: Date;

  constructor(
    private responsaveisService: ResponsaveisService,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    public rolResponsavelComponent: RolResponsaveisListComponent
  ) {
    super();
    this.buscar();
  }


  public buscar() {
    const ugs = this.route.snapshot.paramMap.get('ids') || '';

    this.responsaveisService
      .imprimirResponsaveisUg(ugs.split(';').map(_ => parseInt(_, null)), this.searchNome, this.searchAtivo, this.dataInicio, this.dataFim)
      .then((res) => {
        this.unidadesGestoras = res.dados.unidadesGestoras;
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: 'error',
          title: 'Atenção',
          text: err.mensagem.descricao,
        });
      })
  }

  print(): void {
    window.print();
  }

  public cancelar() {
    this.router.navigate(['/rolresponsaveis']);
  }
}
