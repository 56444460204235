<div id="modal-content-a-equipe">
  <div
    class="card card-secondary card-outline"
    cdkDragBoundary="html"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
  >
    <div class="card-header bg-light" cdkDragHandle>
      <h4 class="card-title mb-0">Histórico</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="fechar()">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>

    <div class="card-body p-0">
      <div class="table-container">
        <table class="table table-bordered" style="text-align: center;">
          <thead class="bg-light">
            <tr>
              <th scope="col">Capítulo/Subcapítulo</th>
              <th scope="col">Realizado</th>
              <th scope="col">Tipo</th>
              <th scope="col">Realizado por</th>
              <th scope="col">Consultar</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of data.capitulo.historicosRelatoriasCapitulos; let i = index">
              <th *ngIf="data.capitulo.numeroPai > 0" scope="row">{{ data.capitulo.numeroPai }}.{{ data.capitulo.numero }}</th>
              <th *ngIf="!data.capitulo.numeroPai" scope="row">{{ data.capitulo.numero }}</th>
              <td>
                {{ element.dataAlteracao | date: "dd/MM/yyyy" }} às
                {{ element.dataAlteracao | date: "HH:mm" }}
              </td>
              <td>{{ element.status.descricao }}</td>
              <td>{{ element.usuario }}</td>
              <td>
                <button *ngIf="validarExibicao(element)"
                  mat-icon-button
                  color="black"
                  class="search"
                  (click)="modalVisualizarHistorico(element)"
                >
                  <mat-icon class="search">search</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-default btn-sm" (click)="fechar()">
        <i class="fas fa-times"></i> Fechar
      </button>
    </div>
  </div>
</div>
