import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { IPeriodoModel } from 'src/app/models/periodo.model';
import { PeriodoService } from 'src/app/services/periodo.service';
import Swal from 'sweetalert2';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';

@Component({
  selector: 'app-periodo-form',
  templateUrl: './periodo-form.component.html',
  styleUrls: ['./periodo-form.component.scss'],
})
export class PeriodoFormComponent extends BaseFormComponent implements OnInit {
  public model: IPeriodoModel = {} as IPeriodoModel;
  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
    dataInicio: new FormControl('', Validators.required),
    dataFim: new FormControl('', Validators.required)
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private periodoService: PeriodoService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Período';
    }
  }

  async ngOnInit() {
    try {
      if (!this.novoRegistro) {
        const res = await this.periodoService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;
          
          if (this.visualizar) {
            this.titulo = 'Visualizar Período - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Período - ' + this.model.nome;
          }

          this.model.dataInicio = new Date(this.model.dataInicio);
          this.model.dataFim = new Date(this.model.dataFim);
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.router.navigate(['/periodos']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.router.navigate(['/periodos']);
    } 
  }

  public async onSubmit(salvarEFechar: boolean = false) {
    if (this.form.invalid) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: 'Formulário invalido',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return;
    }

    this.submit = true;

    this.atualizarModel(this.form.value);

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        res = await this.periodoService.atualizar(this.model);
      } else {
        res = await this.periodoService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        if (salvarEFechar) {
          this.router.navigate(['/periodos']);
        }
        else
        if(this.novoRegistro){
          this.router.navigate([`/periodos/${res.dados.id}`]);
          await this.ngOnInit();
        }
      } else {
        this.submit = false;
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(validacoes, true);
      }
    } catch (err) {
      this.submit = false;
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.router.navigate(['/periodos']);
  }
}
