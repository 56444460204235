import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { IndicadorFormComponent } from 'src/app/pages/indicador-form/indicador-form.component';

@Injectable()
export class IndicadorGuard implements CanDeactivate<IndicadorFormComponent> {
  canDeactivate(component: IndicadorFormComponent): Observable<boolean> | boolean {
    if (component.novoRegistro) {
      if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    } else { return true; }
  }
}
