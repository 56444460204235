<div class="header-imprimir">
	<img
		src="assets/img/logo_es_color.png"
		alt="SECONT-SIAC"
		class="brand-image"
		style="opacity: 0.8"
	/>
	<div class="title">
		<h4><strong>Governo do Estado do Espírito Santo</strong></h4>
		<h5>Secretaria de Estado de Controle e Transparência (SECONT)</h5>
	</div>
</div>
<div class="subtitulo">
	<strong>{{ titulo }}</strong><br />
	<strong>Versäo: {{ versao }} </strong>
	<br />
	<strong>Data: {{ dataFormatada }}</strong>
</div>

<div class="container-fluid">
	<div class="row">
		<div class="col-md-10 mx-auto header-questionario">
			<h6>Unidade a ser auditada: {{ nomeUnidadeGestora }}</h6>
		</div>
	</div>
	<div class="row">
		<div class="col-md-10 mx-auto">
			<div class="component data component-card">
				<mat-card class="mat-card">
					<mat-table [dataSource]="dataSource" class="table">
						<ng-container class="container" matColumnDef="pergunta">
							<mat-cell class="cell" *matCellDef="let element">
								<span>
									{{ element.pergunta }}
								</span>
								<br />
								<span
									class="resposta-pergunta"
									*ngIf="
										element.tipoEntrada &&
										element.tipoAvaliacaoAuditor ==
											'Concordo'
									"
								>
									{{ element.resposta }}
								</span>
								<span
									class="resposta-pergunta"
									*ngIf="
										element.tipoEntrada &&
										element.tipoAvaliacaoAuditor !=
											'Concordo'
									"
								>
									<div [innerHtml]="element.avaliacaoAuditor"></div>
								</span>
							</mat-cell>
						</ng-container>

						<mat-row
							class="row"
							*matRowDef="let row; columns: displayedColumns"
							[ngClass]="{
								'tipo-secao': row.tipoEntrada === '',
								'tipo-pergunta': row.tipoEntrada !== ''
							}"
						>
						</mat-row>
					</mat-table>
				</mat-card>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-10 mx-auto">
			<button
				type="button"
				class="btn btn-primary btn-sm mr-1 no-print"
				(click)="print()"
			>
				<i class="fas fa-print"></i> Imprimir
			</button>
			<button
				[hidden]="visualizar"
				type="button"
				class="btn btn-default btn-sm no-print"
				(click)="onBack()"
			>
				<i class="fas fa-times"></i> Fechar
			</button>
		</div>
	</div>
</div>
