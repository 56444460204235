import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PaaService } from 'src/app/services/paa.service';
import { IPaaModel } from 'src/app/models/paa.model';
import { AuthService } from 'src/app/services/auth.service';
import * as cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2';
import { FiltroPaa } from 'src/app/models/filtro-paa.model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';

@Component({
  selector: 'app-paa-list',
  templateUrl: './paa-list.component.html',
  styleUrls: ['./paa-list.component.scss']
})
export class PaaListComponent extends BaseListComponent implements OnInit, AfterViewInit {
  public dataSource = new MatTableDataSource<any>([]);
  public dataOriginal = [];
  public selectedModel: any;
  public semDados = true;
  public paa = {} as IPaaModel;
  public searchNome: string;
  public searchAtivo = true;
  public filtro: FiltroPaa = {} as FiltroPaa;
  public pageEvent: any;
  public totalItens: number;

  public displayedColumns: string[] = [
    'selecao',
    'ano',
    'pea',
    'paa',
    'origem',
    'ativo',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('tabela1') public tabela1: MatSort;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private toastr: ToastrService,
              private router: Router,
              private paaService: PaaService,
              public authService: AuthService,) {
    super();
  }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPAA, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPAA, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPAA, EnumAcaoRecurso.Excluir);
    this.permissoes.imprimir = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPAA, EnumAcaoRecurso.Imprimir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPAA, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPAA, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPAA, EnumAcaoRecurso.Visualizar);
    this.permissoes.enviarParaAprovacao = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPAA, EnumAcaoRecurso.EnviarParaAprovacao);
 }

  ngAfterViewInit(): void {
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.configurarFiltro()
    this.buscar();
  }

  public async buscar() {
    this.selectedModel = null;
    
    this.paaService
      .obter(this.searchNome, this.searchAtivo, false, this.filtro)
      .then((res) => {
        this.totalItens = res.dados.totalItens;
        this.dataSource = new MatTableDataSource<any>(res.dados.resultado);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
        this.configurarFiltro();
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err?.mensagem?.descricao ?? "Ocorreu um erro inesperado. Tente novamente mais tarde.",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
  }

  

  private configurarFiltro() {
    this.filtro.pagina = this.paginator.pageIndex + 1;
    this.filtro.itensPorPagina = this.paginator.pageSize;
    this.filtro.colunaOrder = this.sort.active;
    this.filtro.direcao = this.sort.direction;
  }

  pageChanged(e) {
    this.filtro.pagina = e.pageIndex + 1;
    e.pageIndex = this.filtro.pagina;
    this.filtro.itensPorPagina = e.pageSize;
    this.buscar()
  }

sortData(sort) {
    this.filtro.colunaOrder = sort.active;
    this.filtro.direcao = sort.direction;

    this.buscar();
}

  public sortingDataAccessor = (item: any, property: string) => {
    switch (property) {
      case 'pea':
        return item?.pea?.nome.trim().toLowerCase();
        case 'paa':
          return item?.nome.trim().toLowerCase();        
      case 'origem':
        return item.origem.descricao.trim().toLowerCase();
      case 'ativo':
        return item?.situacao?.nome.trim().toLowerCase();
      default:
        return item[property];
    }
  };

  elaborar(id: number){
    this.router.navigate([`/paa/${id}`]);
  }

  public relatorio(id: number) {
    this.router.navigate([`/paa/${id}/relatorio`]);
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel !== item ? item : null;
  }

  public visualizar(id: number) {
    this.router.navigate([`/paa/${id}/visualizar`]);
  }

  public itemAgrupador(index: number, item: any): boolean {
    return item.grupo;
  }

  public novo() {
    if (!this.permissoes.incluir) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: "Você não possui permissão para incluir um PAAC.",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      }
      );
      return;
    }    
    this.router.navigate(['/paa/novo']);
  }

  public excluir() {    
    let id = this.selectedModel.id;
    if (!this.permissoes?.excluir) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Você não tem permissão para excluir PAAC.',
      });
      return;
    }

    this.paaService
      .excluir(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'PAAC excluído com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'warning',
            title: 'Atenção',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Erro',
          showConfirmButton: false,
          timer: 5000
        });
      })
      .finally(() => {
        this.buscar();
      });
  }

  public botaoExcluirHidden() : boolean {
    if (this.selectedModel !== undefined && this.selectedModel !== null) {
      return  !this.permissoes.excluir  
      || this.selectedModel.situacao.nome === 'Homologado'
      || this.selectedModel.situacao.nome === 'EmAprovacao'
      || this.selectedModel.origem.nome === 'Planejada';
    } else {
      return true;
    } 
  }
}
