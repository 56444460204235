import { Component, ViewChild, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IAgentePublicoModel } from 'src/app/models/agente-publico.model';
import { AgentePublicoService } from 'src/app/services/agente-publico.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { SharedService } from 'src/app/services/shared.service';


@Component({
  selector: 'app-agente-publico-form',
  templateUrl: './agente-publico-form.component.html',
  styleUrls: ['./agente-publico-form.component.scss']
})
export class AgentePublicoFormComponent extends BaseFormComponent {
  public model : IAgentePublicoModel;
  public aba = 0;
  public showBtnSalvar = false;
  @Output() public abaEmitter: EventEmitter<number> = new EventEmitter<number>();
  
  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    cpf: new FormControl('', Validators.required),
    numeroFuncional: new FormControl('', Validators.required)
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private agentePublicoService: AgentePublicoService,
    public matDialog: MatDialog,
    private sharedService: SharedService
  ) {
    super(route, toastr,  router, localeService, matDialog);
    this.titulo = 'Agente público';
  }

  async ngOnInit() {
    await this.agentePublicoService.obterPorId(this.id).then(res => {
      if(res.sucesso){
        this.model = res.dados;
        this.titulo = `Agente público: ${this.model.pessoa.nome}`
      }
      })
  }

  public salvar(){
    this.sharedService.sendSalvarEvent();
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public mudarAba(ind: number) {
    this.aba = ind;
    this.abaEmitter.next(this.aba);
    this.showBtnSalvar = this.aba === 1 ? true : false;
  }

  public onBack() {
    this.router.navigate(['/agente-publico']);
  }
  
  public cancelar() {
    this.router.navigate(['/agente-publico']);
  }


}
