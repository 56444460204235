import { Component, Inject, OnInit, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IHistoricoModel } from 'src/app/models/historico.model';
import { AvaliacaoService } from 'src/app/services/avaliacao.service';
import { BaseFormComponent } from '../base-form/base-form.component';
import { IUnidadeGestoraMaturidadeGRHistoricoModel } from 'src/app/models/unidade-gestora-maturidadeGRHistorico.model'; 
import { UnidadeGestoraMaturidadeGRHistoricoService } from 'src/app/services/unidade-gestora-maturidadeGRHistorico.service';
import { IFiltroUnidadeGestoraMaturidadeGRHistoricoModel } from 'src/app/models/filtro-unidade-gestora-maturidadegrhistorico.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-historico-modal',
  templateUrl: './historico-modal.component.html',
  styleUrls: ['./historico-modal.component.scss']
})
export class HistoricoModalComponent extends BaseFormComponent implements OnInit {
  public historicoDados: IUnidadeGestoraMaturidadeGRHistoricoModel[];
  public dataSource = new MatTableDataSource<IUnidadeGestoraMaturidadeGRHistoricoModel>([]);
  public tituloModal: string = `Histórico de Alteração da Avaliação da Maturidade da Gestão de Riscos`;
  public semDados = true;
  public displayedColumns: string[] = ['pessoaNome','dataInclusao', 'maturidade'];
  public codigo: string;
  public sigla: string;
  public nome: string;

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private avaliacaoService: AvaliacaoService,
    public matDialog: MatDialog,
    private unidadeGestoraMaturidadeGRHistoricoService: UnidadeGestoraMaturidadeGRHistoricoService,
    private dialogRef: MatDialogRef<HistoricoModalComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(route, toastr, router, localeService, matDialog);

    
    this.semDados = data.historicoDados.length == 0;
    this.dataSource = new MatTableDataSource<IUnidadeGestoraMaturidadeGRHistoricoModel>(data.historicoDados);

    this.codigo = data.codigo;
    this.sigla = data.sigla;
    this.nome = data.nome;

    
  }

  ngOnInit() {
    
    
  }


  
  

  public cancelar() {
    this.dialogRef.close(false);
  }
}
