import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { NaturezaResponsabilidadeFormComponent } from 'src/app/pages/natureza-responsabilidade-form/natureza-responsabilidade-form.component';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';

@Injectable()
export class NaturezaResponsabilidadeGuard implements CanDeactivate<NaturezaResponsabilidadeFormComponent> {
  canDeactivate(component: NaturezaResponsabilidadeFormComponent): Observable<boolean> | boolean {
    if (component.novoRegistro) {
      if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    } else { return true; }
  }
}

