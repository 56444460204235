import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { PeriodoFormComponent } from 'src/app/pages/periodo-form/periodo-form.component';

@Injectable()
export class PeriodoGuard implements CanDeactivate<PeriodoFormComponent> {
  canDeactivate(component: PeriodoFormComponent): Observable<boolean> | boolean {
    if (component.novoRegistro) {
      if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    } else { return true; }
  }
}
