import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import Inputmask from 'inputmask';
import { toIntlNumberString } from 'src/app/core/helpers/number.helper';
import { ICriterioModel } from 'src/app/models/criterio.model';
import { ModalCancelarComponent } from '../../../shared/components/modal-cancelar/modal-cancelar-component';

@Component({
  selector: 'app-modal-preencher-formulario',
  templateUrl: './modal-preencher-formulario.component.html',
  styleUrls: ['./modal-preencher-formulario.component.scss'],
})
export class ModalPreencherFormularioComponent
  implements OnInit, AfterViewInit {
  public dataSource = new MatTableDataSource<any>([]);
  public criterio = {} as ICriterioModel;
  public displayedColumns = ['sigla', 'valor'];
  public semDados = true;
  public descricaoCriterioFormulario = '';

  constructor(
    private dialogRef: MatDialogRef<ModalPreencherFormularioComponent>,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {
    this.dataSource = new MatTableDataSource<any>(data);
    this.criterio = data.find((_: any) => _).criterio;
  }

  ngOnInit(): void {
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  ngAfterViewInit(): void {
    Inputmask().mask(document.querySelectorAll('input'));
  }

  public concluir() {
    const respostas = this.dataSource.data;
    for (const item of respostas) {
      item.valorNumerico = item.valorNumerico
        ? parseFloat(toIntlNumberString(item.valorNumerico.toString()))
        : undefined;
    }
    this.dialogRef.close(respostas);
  }

  public cancelar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { interno: true };
    this.matDialog.open(ModalCancelarComponent, dialogConfig).afterClosed();
  }
}
