import { CoordenacaoService } from "src/app/services/coordenacao.service";
import { IAuditorModel } from "./../../../models/alocar-equipe-component";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { IAlocarEquipeModel } from "src/app/models/alocar-equipe-component";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { IAuditorCoordenacaoModel } from "src/app/models/auditor-coordenacao.model";

@Component({
	selector: "app-alocar-equipe",
	templateUrl: "./alocar-equipe.component.html",
	styleUrls: ["./alocar-equipe.component.scss"],
})
export class AlocarEquipeComponent implements OnInit {
	public model: IAlocarEquipeModel = {} as IAlocarEquipeModel;
	public dataObrigatoria!: any;
	public auditorDataSource =
		new MatTableDataSource<IAuditorCoordenacaoModel>();
	public auditorSemDados: boolean = true;
	public auditorOutraCoordenacaoDataSource =
		new MatTableDataSource<IAuditorCoordenacaoModel>();
	public auditorOutraCoordenacaoSemDados: boolean = true;
	public minDate!: Date;
	public maxDate!: Date;
	public novaListaAuditores = [] as IAuditorModel[];

	@ViewChild("TableOnePaginator", { static: true })
	tableOnePaginator: MatPaginator;
	@ViewChild("tabela1", { static: true }) tabela1: MatSort;

	@ViewChild("TableTwoPaginator", { static: true })
	tableTwoPaginator: MatPaginator;
	@ViewChild("tabela2", { static: true }) tabela2: MatSort;

	constructor(
		private dialogRef: MatDialogRef<AlocarEquipeComponent>,
		public coordenacaoService: CoordenacaoService,
		@Inject(MAT_DIALOG_DATA) public data: IAlocarEquipeModel
	) {
		if (data) {
			this.model = data;
		}
	}

	public obterCoordenadoresAuditoria() {
		return new Promise((resolve) => {
			this.coordenacaoService
				.obterCoordenadoresAtivos()
				.then((res) => {
					if (res.sucesso) {
						resolve(res.dados);
					} else {
						Swal.fire({
							toast: true,
							position: "top-end",
							icon: "error",
							text: res.mensagem.descricao,
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
					}
				})
				.catch((err) => {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "error",
						text: err.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
				});
		});
	}

	ngOnInit(): void {
		this.obterCoordenadoresAuditoria().then((res) => {
			this.minDate = new Date(this.model.minDataInicio, 1 - 1, 1);
			this.maxDate = new Date(this.model.maxDataFim, 12 - 1, 31);

      // Auditor da própria coordenação
      this.auditorDataSource.data = ((res as IAuditorCoordenacaoModel[])
      ?.filter(a => a.coordenacao.id === this.model.tipoAuditoria?.coordenacao?.id))
        ?.map((item) => {
          const selecionarAuditor = this.model.auditoresSelecionados?.find(a => a?.auditorId === item?.auditorId);
          if(selecionarAuditor?.auditorId) item.selecionado = true;
          return item
        })
			this.auditorDataSource.paginator = this.tableOnePaginator;
			this.auditorDataSource.sort = this.tabela2;
      this.auditorSemDados = this.auditorDataSource.data.length === 0;
      // Fim auditor própria coordenacão

      // Auditor de outra coordenação
      this.auditorOutraCoordenacaoDataSource.data = (res as IAuditorCoordenacaoModel[])
        ?.filter(a => a.coordenacao.id !== this.model.tipoAuditoria?.coordenacao?.id)
          ?.map((item) => {
          const selecionarAuditor = this.model.auditoresSelecionados?.find(a => a?.auditorId === item?.auditorId);
          if(selecionarAuditor?.auditorId) item.selecionado = true;
          return item
        })
        this.auditorOutraCoordenacaoDataSource.paginator = this.tableTwoPaginator;
        this.auditorOutraCoordenacaoDataSource.sort = this.tabela1;
      this.auditorOutraCoordenacaoSemDados = this.auditorOutraCoordenacaoDataSource.data.length === 0;
      // Fim auditor outra coordenação
		});
	}

	setDataObrigatoria() {}

  public dataValida(): boolean {
    if (new Date(this.model.dataFim) >= new Date(this.model.dataInicio)) {
      return true;
    }
    return false;
  }

	concatUg(a: string, b: string) {
		if (a && b) {
			return a + " - " + b;
		}
		return "";
	}

	selecionarAuditores(a: IAuditorModel[] | IAuditorCoordenacaoModel[]) {
		a.forEach((item) => {
			if (item.selecionado === true) {
				this.novaListaAuditores.push(item);
			}
		});
	}

	concluir() {
		this.selecionarAuditores(this.auditorDataSource.data);
		this.selecionarAuditores(this.auditorOutraCoordenacaoDataSource.data);

		this.model.auditoresSelecionados = this.novaListaAuditores;

    if(!this.dataValida()) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: 'A data fim não pode ser maior que a data inicio',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return;
    } else if (this.model.auditoresSelecionados.length === 0) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: 'Selecione ao menos 1 auditor',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return;
    } else {
      this.dialogRef.close(this.model);
      return;
    }

	}

	cancelar() {
		this.dialogRef.close(false);
	}
}
