import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IPeriodoModel } from '../models/periodo.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class PeriodoService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<IPeriodoModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<IPeriodoModel[]>>(`${this.apiBaseUrl}/periodo`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IPeriodoModel>> {
    return this.httpClient
      .get<IBaseModel<IPeriodoModel>>(`${this.apiBaseUrl}/periodo/${id}`)
      .toPromise();
  }

  public async inserir(data: IPeriodoModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/periodo`, data)
      .toPromise();
  }

  public async atualizar(data: IPeriodoModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/periodo/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/periodo/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/periodo/${id}/inativar`, {})
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/periodo/${id}/excluir`, {})
      .toPromise();
  }
}
