<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i> Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/questionarios']">Questionários</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i> {{ titulo }}
            </h3>
          </div>

          <div class="card-body">

            <div class="row">
              <div class="form-group col-md-4">
                <label for="pea" class="mb-1">{{retornaNomeTipoTrabalho()}}</label>
                <input
                  type="text"
                  disabled
                  id="pea"
                  class="form-control form-control-sm"
                  [ngModel]="model.documentoNome"
                  />
              </div>

              <div class="form-group col-md-2">
                <label for="siglaUg" class="mb-1">Sigla UG</label>
                <input
                  type="text"
                  disabled
                  id="siglaUg"
                  class="form-control form-control-sm"
                  [ngModel]="model.ug"
                  />
              </div>

              <div class="form-group col-md-2">
                <label for="responsavel" class="mb-1">Responsável</label>
                <input
                  type="text"
                  disabled
                  id="responsavel"
                  class="form-control form-control-sm"
                  [ngModel]="model.responsavel"
                  />
              </div>

              <div class="form-group col-md-2">
                <label for="dataEnvio" class="mb-1">Data Envio</label>
                <input
                  type="text"
                  disabled
                  id="dataEnvio"
                  class="form-control form-control-sm"
                  [ngModel]="model.dataEnvio"
                  />
              </div>

              <div class="form-group col-md-2">
                <label for="dataPrazo" class="mb-1">Data Prazo</label>
                <input
                  type="text"
                  disabled
                  id="dataPrazo"
                  class="form-control form-control-sm"
                  [ngModel]="model.dataPrazo"
                  />
              </div>
            </div>

            <div class="row">
              <table
                mat-table
                [dataSource]="dataSource"
                class="w-100"
                class="mat-elevation-z8">
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <ng-container matColumnDef="selecao">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      [disabled]="visualizar"
                      color="primary"
                      (change)="pergunta= $event.checked ? element : null"
                      [checked]="model?.perguntaId ===
                      element.criterio.perguntaId">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="pergunta">
                  <th mat-header-cell *matHeaderCellDef>Pergunta</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.criterio.pergunta.nome }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="tipo">
                  <th mat-header-cell *matHeaderCellDef>Tipo de Entrada</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.criterio.pergunta.tipoEntrada.descricao }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="resposta">
                  <th mat-header-cell *matHeaderCellDef>Resposta</th>
                  <td mat-cell *matCellDef="let element" class="p-1">
                    <input
                      *ngIf="element.criterio.pergunta.tipoEntrada.nome ===
                      'NumericoInteiro'"[disabled]="expirado"
                      type="text"
                      class="form-control form-control-sm"
                      [(ngModel)]="element.valorNumerico"
                      data-inputmask="'alias': 'integer'"
                      inputmode="numeric"
                      />

                    <input
                      *ngIf="element.criterio.pergunta.tipoEntrada.nome ===
                      'NumericoDecimal'"[disabled]="expirado"
                      type="text"
                      class="form-control form-control-sm mask-number"
                      [(ngModel)]="element.valorNumerico"
                      data-inputmask="'alias': 'numeric', 'radixPoint': ',',
                      'digits': 2, 'digitsOptional': false, 'placeholder': '0'"
                      />

                    <select
                      *ngIf="element.criterio.pergunta.tipoEntrada.nome ===
                      'MultiplaEscolha'"[disabled]="expirado"
                      class="form-control form-control-sm"
                      [(ngModel)]="element.perguntaOpcaoId">
                      <option
                        *ngFor="let item of
                        element.criterio.pergunta.perguntaOpcao"[value]="item.id">
                        {{ item.nome }}
                      </option>
                    </select>
                  </td>
                </ng-container>

                <ng-container matColumnDef="unidadeMedida">
                  <th mat-header-cell *matHeaderCellDef>Unidade de Medida</th>
                  <td class="text-center" mat-cell *matCellDef="let element">
                    {{ element.criterio.unidade }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="delegadoA">
                  <th mat-header-cell *matHeaderCellDef>Delegado à</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.delegadoA }}
                  </td>
                </ng-container>

                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
          </div>

          <div class="card-footer">
            <button
              type="button"
              *ngIf="!visualizar"
              class="btn btn-primary btn-sm mr-1"
              (click)="onSalvar()">
              <i class="far fa-save"></i> Salvar
            </button>

            <button
              type="button"
              *ngIf="!visualizar"
              class="btn btn-warning btn-sm mr-1"
              (click)="onEnviar()">
              <i class="fas fa-paper-plane"></i> Enviar
            </button>

            <button
              type="button"
              *ngIf="!visualizar"
              class="btn btn-default btn-sm"
              (click)="onCancelar()">
              <i class="fas fa-times"></i> Cancelar
            </button>

            <button
              type="button"
              *ngIf="!visualizar"
              [disabled]="!pergunta"
              class="btn btn-danger btn-sm"
              (click)="modalDelegar()">
              <i class="fas fa-share-square"></i> Delegar
            </button>

            <button
              type="button"
              *ngIf="visualizar"
              class="btn btn-default btn-sm"
              (click)="onCancelar()">
              <i class="fas fa-times"></i> Sair
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
