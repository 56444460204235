<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">Ocultar e Exibir Colunas</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">

      <div class="table-container p-3">

        <div class="row">
          <table mat-table [dataSource]="dataSource" matSort matSortDisableClear [hidden]="semDados">
            <ng-container matColumnDef="selecao">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                  (change)="element.ativo = ($event.checked ? true : false);"
                  [checked]="element.ativo">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Critério/Índice </th>
              <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; when: !itemAgrupador"
            class="clickable" [ngClass]="{'bg-light': true}">
            </tr>

          </table>
          <h5 [hidden]="!semDados" class="table-no-data">Nenhuma Coluna a exibir.</h5>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
        <i class="fas fa-check"></i> Concluir
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
</div>
