<div [class]="homologado ? '' : 'homologado'">
	<div class="p-4 content">
		<div class="card card-outline">
			<div class="card-body p-2">
				<div class="header-imprimir">
					<img
						src="assets/img/logo_es_color.png"
						alt="SECONT-SIAC"
						class="brand-image"
						style="opacity: 0.8"
					/>
					<div class="title">
						<h5>
							<strong>Governo do Estado do Espírito Santo</strong>
						</h5>
						<h3>
							Secretaria de Estado de Controle e Transparência
							(SECONT)
						</h3>
					</div>
				</div>

				<div>
					<h4 class="mt-2">Informações Básicas &nbsp;</h4>
					<div class="row border border-dark m-0">
						<div class="col-3">
							<strong>Nº OPA:</strong>
							<p>{{ model?.opa?.numero }}</p>
						</div>
						<div
							class="
								col-3
								border border-top-0 border-bottom-0 border-dark
							"
						>
							<strong>Origem da Auditoria:</strong>
							<p>{{ model?.opa?.origem?.descricao }}</p>
						</div>
						<div
							class="
								col-3
								border
								border-top-0
								border-bottom-0
								border-left-0
								border-dark
							"
						>
							<strong>Data de Elaboração:</strong>
							<p>
								{{
									model?.opa?.dataElaboracao
										| date: "shortDate"
								}}
							</p>
						</div>
						<div class="col-3">
							<strong>Tipo de Auditoria:</strong>
							<p>{{ model?.opa?.nomeTipoAuditoria }}</p>
						</div>
					</div>
					<div class="row border border-top-0 border-dark m-0">
						<div class="col-3">
							<strong>Data prevista para Início:</strong>
							<p>
								{{
									model?.opa?.dataInicioPrevista
										| date: "shortDate"
								}}
							</p>
						</div>
						<div
							class="
								col-3
								border border-top-0 border-bottom-0 border-dark
							"
						>
							<strong>Data prevista para Fim:</strong>
							<p>
								{{
									model?.opa?.dataFimPrevista
										| date: "shortDate"
								}}
							</p>
						</div>
						<div
							class="
								col-3
								border
								border-top-0
								border-bottom-0
								border-left-0
								border-dark
							"
						>
							<strong>Data início de realização:</strong>
							<p>
								{{
									model?.opa?.dataInicioRealizacao
										| date: "shortDate"
								}}
							</p>
						</div>
						<div class="col-3">
							<strong>Data fim de realização:</strong>
							<p>
								{{
									model?.opa?.dataFimRealizacao
										| date: "shortDate"
								}}
							</p>
						</div>
					</div>
					<div class="row border border-top-0 border-dark m-0">
						<div class="col-3">
							<strong>UG a ser Auditado:</strong>
							<p>
								{{ model?.opa?.siglaUg }}-{{
									model?.opa?.nomeUg
								}}
							</p>
						</div>
						<div
							class="
								col-3
								border border-top-0 border-bottom-0 border-dark
							"
						>
							<strong>Coordenador da auditoria:</strong>
							<p>
								{{
									model?.opa?.auditorCoordenacao?.nomeAuditor
								}}
							</p>
						</div>
						<div
							class="
								col-3
								border
								border-top-0
								border-bottom-0
								border-left-0
								border-dark
							"
						>
							<strong>Objeto:</strong>
							<p>{{ model?.opa?.objeto }}</p>
						</div>
						<div class="col-3">
							<strong>Status:</strong>
							<p>{{ model?.status?.descricao }}</p>
						</div>
					</div>
					<div class="row border border-top-0 border-dark m-0">
						<div class="col-12">
							<strong>Subtipos:</strong>
							<p
								*ngFor="
									let item of model?.planejamentoSubTipoAuditoria
								"
							>
								{{ item?.subTipoAuditoria?.nome }}
							</p>
						</div>
					</div>
					<div class="row border border-top-0 border-dark m-0">
						<div
							class="
								col-12
								border
								border-top-0
								border-bottom-0
								border-left-0
								border-right-0
								border-dark
							"
						>
							<strong>Auditores:</strong>
							<p *ngFor="let item of model?.planejamentoEquipe">
								{{ item?.nomeAuditor }}
							</p>
						</div>
					</div>
					<div class="row border border-top-0 border-dark m-0">
						<div class="col-12">
							<strong>Unidades Gestoras:</strong>
							<p
								*ngFor="
									let item of model?.planejamentoUgSubtipo
								"
							>
								{{ item?.unidadeGestora?.sigla }}-{{
									item?.unidadeGestora?.nome
								}}
							</p>
						</div>
					</div>

					<h4 class="mt-2">Matriz de planejamento &nbsp;</h4>
					<span
						class="text-center d-block p-2"
						*ngIf="
							model?.subtiposAuditorias?.length <= 0
						"
					>
						Nenhum registro encontrado</span
					>
					<div
						*ngFor="
							let subtipo of model?.subtiposAuditorias
						"
					>
						<div class="row border border-dark m-0">
							<div class="col-12">
								<strong>Subtipo:</strong>
								<p>{{ subtipo?.nome }}</p>
							</div>
              <div *ngFor="let matriz of subtipo?.subtipoAuditoriaMatrizPlanejamento">
							<div class="col-12 ml-2">
								<strong>Matriz:</strong>
								<p >
									{{ matriz?.matrizPlanejamento?.nome }}
								</p>
							</div>
							<div class="col-12 ml-4" *ngFor="let item of matriz?.matrizPlanejamento?.matrizPlanejamentoQuestao">
								<strong>Questão:</strong>
								<p >
									{{ item?.questao?.nome }}
								</p>
							</div>
            </div>
						</div>
					</div>
					<h4 class="mt-2">Custos e Recursos &nbsp;</h4>
					<span
						class="text-center d-block p-2"
						*ngIf="model?.custoRecursoPlanejamento?.length <= 0"
					>
						Nenhum registro encontrado</span
					>
					<div
						class="row border border-dark m-0"
						*ngFor="let item of model?.custoRecursoPlanejamento"
					>
						<div class="col-3">
							<strong>Item:</strong>
							<p>{{ item?.nome }}</p>
						</div>
						<div class="col-3">
							<strong>Valor Unitário:</strong>
							<p>{{ formatarInput(item?.valorUnitario) }}</p>
						</div>
						<div class="col-3">
							<strong>Quantidade:</strong>
							<p>{{ item?.quantidade }}</p>
						</div>
						<div class="col-3">
							<strong> Valor Total:</strong>
							<p>
								{{
									formatarInput(
										item?.quantidade * item?.valorUnitario
									)
								}}
							</p>
						</div>
					</div>
					<h4 class="mt-2">Cronograma &nbsp;</h4>
					<span
						*ngIf="model?.fasesAtividade?.length <= 0"
						class="text-center d-block p-2"
					>
						Nenhum registro encontrado</span
					>
					<div
						class="border border-dark"
						*ngFor="let fase of model?.fasesAtividade"
					>
						<div class="row m-0">
							<div class="col-6">
								<strong>Fase de Atividade:</strong>
								<p>{{ fase?.nome }}</p>
							</div>
							<div class="col-6">
								<strong>Dias planejados:</strong>
								<p>{{ fase?.quantidadeDias }}</p>
							</div>
						</div>
						<div *ngFor="let atividade of fase?.atividades">
							<div class="row m-0">
								<div class="col-6">
									<strong>Atividade:</strong>
									<p>{{ atividade?.nome }}</p>
								</div>
								<div class="col-6">
									<strong>Dias Planejados:</strong>
									<p>{{ atividade?.quantidadeDias }}</p>
								</div>
							</div>
							<div
								class="row m-0"
								*ngFor="let sub of atividade?.subAtividades"
							>
								<div class="col-6">
									<strong>SubAtividade:</strong>
									<p>{{ sub?.nome }}</p>
								</div>
								<div class="col-6">
									<strong>Dias Planejados:</strong>
									<p>{{ sub?.quantidadeDias }}</p>
								</div>
							</div>
						</div>
					</div>
					<h4 class="mt-2">Amostra &nbsp;</h4>
					<span
						*ngIf="model?.planejamentoAmostra?.length <= 0"
						class="text-center d-block p-2"
					>
						Nenhum registro encontrado</span
					>
					<div
						class="row border border-dark m-0"
						*ngFor="let amostra of model?.planejamentoAmostra"
					>
						<div class="col-3">
							<strong>Nome:</strong>
							<p>{{ amostra?.nome }}</p>
						</div>
						<div class="col-3">
							<strong>Descrição:</strong>
							<p>
								{{ amostra?.descricao }}
							</p>
						</div>
						<div class="col-3">
							<strong>Unidade(s) gestora(s):</strong>
							<p
								*ngFor="
									let ug of amostra?.amostraUnidadeGestoras
								"
							>
								{{ ug?.unidadeGestora?.sigla }}
							</p>
						</div>
						<div class="col-3">
							<strong>Arquivos:</strong>
							<p *ngFor="let arquivo of amostra?.amostraArquivos">
								{{ arquivo?.nome }}
							</p>
						</div>
					</div>

					<div class="page-break">
						<h4 class="mt-2">Solicitação técnica &nbsp;</h4>
						<span
							*ngIf="model?.solicitacaoTecnica?.length <= 0"
							class="text-center d-block p-2"
						>
							Nenhum registro encontrado</span
						>
						<div
							*ngFor="
								let solicitacao of model?.solicitacaoTecnica
							"
						>
							<div class="row border border-dark m-0">
								<div class="col-3">
									<strong>Associado a:</strong>
									<p>{{ model?.opa?.numero }}</p>
								</div>
								<div
									class="
										col-3
										border
										border-top-0
										border-bottom-0
										border-dark
									"
								>
									<strong>Nº Solicitação Técnica:</strong>
									<p *ngIf="solicitacao?.numero">
										{{ solicitacao?.numero }}
									</p>
									<p *ngIf="!solicitacao?.numero">-</p>
								</div>
								<div
									class="
										col-3
										border
										border-top-0
										border-left-0
										border-bottom-0
										border-dark
									"
								>
									<strong>Prazo:</strong>
									<p>
										{{
											solicitacao?.prazo
												| date: "shortDate"
										}}
									</p>
								</div>

								<div class="col-3">
									<strong>Status:</strong>
									<p>{{ solicitacao?.status?.descricao }}</p>
								</div>
							</div>
							<div
								class="
									row
									p-2
									border border-top-0 border-dark
									d-block
									m-0
								"
							>
								<div class="col-12">
									<strong>Assunto:</strong>
									<p>{{ solicitacao?.assunto }}</p>
								</div>
							</div>
							<div
								class="
									row
									p-2
									border border-top-0 border-dark
									m-0
								"
								*ngFor="
									let ug of solicitacao?.solicitacaoTecnicaUgResponsavel
								"
							>
								<div class="col-6">
									<strong>Unidade Gestora:</strong>
									<p>{{ ug?.siglaUg }} - {{ ug?.nomeUg }}</p>
								</div>
								<div class="col-6">
									<strong>Responsável:</strong>
									<p>{{ ug?.nomeAuditor }}</p>
								</div>
							</div>
							<div
								*ngFor="let item of solicitacao?.item"
								class="
									row
									p-2
									border border-top-0 border-dark
									d-block
									m-0
								"
							>
								<div class="col-12">
									<strong>Item Nº: </strong>
									<p>{{ item?.numero }}</p>
								</div>
								<div class="row p-2 d-block">
									<div class="col-12">
										<strong>Titulo:</strong>
										<p>{{ item?.titulo }}</p>
									</div>
								</div>
								<div class="row p-2 d-block">
									<div class="col-12">
										<strong>Descrição:</strong>
										<p>{{ item?.descricao }}</p>
									</div>
								</div>
								<div class="row p-2 d-block">
									<div class="col-12">
										<strong>Arquivos do Item:</strong>
										<div *ngFor="let anexo of item?.anexo">
											<p>{{ anexo?.nomeArquivo }}</p>
										</div>
									</div>
								</div>
								<div class="row p-2 d-block">
									<div class="col-12">
										<strong>Descrição da Resposta:</strong>
										<p *ngIf="item?.resposta">
											{{ item?.resposta?.descricao }}
										</p>
										<p *ngIf="!item?.resposta">
											NÃO RESPONDIDO
										</p>
									</div>
								</div>
								<div class="row p-2 d-block">
									<div class="col-12">
										<strong>Arquivos da Resposta:</strong>
										<div
											*ngFor="
												let anexo of item?.resposta
													?.anexo
											"
										>
											<p>{{ anexo?.nomeArquivo }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<h4 class="mt-2">Aprovações &nbsp;</h4>
					<span
						*ngIf="model?.assinaturas?.length <= 0"
						class="text-center d-block p-2"
					>
						Nenhum registro encontrado</span
					>
					<div
						class="row p-2 border border-dark m-0"
						*ngIf="model?.assinaturas?.length > 0"
					>
						<div class="col-12">
							<ol>
								<li *ngFor="let item of model?.assinaturas">
									<p *ngIf="item?.situacao === 1">
										{{ item?.nome }}({{ item?.papel }})
										aprovou.
									</p>

									<p *ngIf="item?.situacao === 2">
										{{ item?.nome }}({{ item?.papel }})
										reprovou.
									</p>

									<p *ngIf="item?.situacao === 3">
										{{ item?.nome }}({{ item?.papel }})
										enviou para aprovação.
									</p>

									<p *ngIf="item.situacao === 4">
										{{ item?.nome }}({{ item?.papel }})
										homologou.
									</p>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer doNotPrint">
				<button
					type="button"
					class="btn btn-default btn-sm mr-1"
					(click)="cancelar()"
				>
					<i class="fas fa-times"></i> Fechar
				</button>
				<button
					type="button"
					class="btn btn-primary btn-sm mr-1"
					(click)="print()"
				>
					<i class="fas fa-print"></i> Imprimir
				</button>
			</div>
		</div>
	</div>
</div>
