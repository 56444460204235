import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Inputmask from 'inputmask';
import { toIntlNumberString } from 'src/app/core/helpers/number.helper';
import { ICriterioMetricaModel } from 'src/app/models/criterio-metrica.model';
import { IPerguntaOpcaoModel } from 'src/app/models/pergunta-opcao.model';

@Component({
  selector: 'app-modal-metrica-opcoes',
  templateUrl: './modal-metrica-opcoes.component.html',
  styleUrls: ['./modal-metrica-opcoes.component.scss']
})
export class ModalMetricaOpcoesComponent implements AfterViewInit {
  public listaOpcoes = [] as IPerguntaOpcaoModel[];
  public element = {} as ICriterioMetricaModel;

  constructor(private dialogRef: MatDialogRef<ModalMetricaOpcoesComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.element = data.element;
    this.listaOpcoes = data.listaOpcoes;

    this.formMetrica = new FormGroup({
      opcaoId: new FormControl(this.element?.perguntaOpcaoId, Validators.required),
      nota: new FormControl(this.element?.nota, Validators.required),
    });
  }

  public formMetrica: FormGroup;

  ngAfterViewInit() {
    Inputmask().mask(document.querySelectorAll('input'));
  }


  public concluir() {
    this.formMetrica.markAllAsTouched();

    if (!this.formMetrica.valid) {
      return;
    }

    const modelMetrica = {
      perguntaOpcaoId: this.formMetrica.controls['opcaoId'].value,
      perguntaOpcao: this.listaOpcoes.find(t => t.id.toString() === this.formMetrica.controls['opcaoId'].value),
      nota: parseFloat(toIntlNumberString(this.formMetrica.controls['nota'].value.toString())),
    } as ICriterioMetricaModel;

    this.dialogRef.close(modelMetrica);
  }

  public cancelar() {
    this.dialogRef.close(null);
  }
}
