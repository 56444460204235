export enum EnumAcaoRecurso
{
    Inserir = 'Inserir',
    Consultar = 'Consultar',
    Editar = 'Editar',
    Visualizar = 'Visualizar',
    AtivarInativar = 'Ativar-Inativar',
    Excluir = 'Excluir',
    Imprimir='Imprimir',
    EnviarParaAprovacao='Enviar para Aprovação',
    Planejar='Planejar',
    Elaborar='Elaborar',
    GerarNovaVersao='Gerar Nova Versão',
    Relatoria='Relatoria',
    Avaliar='Avaliar',
    Detalhar='Detalhar',
    Exportar='Exportar',
    GerarPAA='Gerar PAA',
    Importar='Importar'
}