<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item active">Logs do Sistema</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i class="fas fa-table pr-2"></i>
              Logs do Sistema
            </h3>
          </div>

          <div class="card-body p-2">
            <div class="row">
              <div class="col-md-3 form-group">
                <label for="txtDataInicial" class="mb-1">Período Inicial</label>
                <div class="input-group input-group-sm" style="width: 100%;">
                  <input type="date" id="txtDataInicial" placeholder="Datepicker" bsdatepicker=""
                    class="form-control ng-pristine ng-valid ng-touched" [(ngModel)]="filtroModel.dataInicial">
                </div>
              </div>
              <div class="col-md-3 form-group">
                <label for="txtDataFinal" class="mb-1">Período Final</label>
                <div class="input-group input-group-sm" style="width: 100%;">
                  <input type="date" id="txtDataFinal" placeholder="Datepicker" bsdatepicker=""
                    class="form-control ng-pristine ng-valid ng-touched" [(ngModel)]="filtroModel.dataFinal">
                </div>
              </div>
              <div class="col-md-3 form-group">
                <label for="selectFuncionalidade" class="mb-1">Funcionalidade</label>
                <div class="input-group input-group-sm" style="width: 100%;">
                  <select id="selectFuncionalidade" #selectFuncionalidade [(ngModel)]="filtroModel.funcionalidade" class="form-control form-control-sm">
                    <option *ngFor="let item of funcionalidades" [ngValue]="item.codigo">
                      {{item.descricao}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 form-group">
                <label for="selectTipoLog" class="mb-1">Ação Realizada</label>
                <div class="input-group input-group-sm" style="width: 100%;">
                  <select id="selectTipoLog" #selectTipoLog [(ngModel)]="filtroModel.tipoLog" class="form-control form-control-sm">
                    <option *ngFor="let item of tiposLog" [ngValue]="item.codigo">
                      {{item.descricao}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="mat-elevation-z4">
              <mat-toolbar class="table-header">
                <mat-toolbar-row>
                  <!--Botões de ações -->
                  <button type="button" class="btn btn-primary btn-sm mr-1" [disabled]="semDados" [hidden]="!permissoes.exportar" (click)="exportar()">
                    <i class="far fas fa-file-pdf"></i> Exportar
                  </button>
                  <button type="button" class="btn btn-info btn-sm mr-1" [disabled]="!selectedModel" [hidden]="!permissoes.detalhar" (click)="detalhar()">
                    <i class="far fas fa-search-plus"></i> Detalhar
                  </button>

                  <span class="fill-remaining-space"></span>

                  <div class="input-group input-group-sm" style="width: 200px;">
                    <input type="text" name="table_search" class="form-control float-right" (keyup.enter)="buscarComFiltro()"
                      placeholder="Pesquisar por usuário" [(ngModel)]="filtroModel.nomeUsuario" />

                    <div class="input-group-append">
                      <button type="button" class="btn btn-info" (click)="buscarComFiltro()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </mat-toolbar-row>
              </mat-toolbar>
              <table mat-table [dataSource]="dataSource" #tabela="matSort" matSort matSortDisableClear class="w-100 mat-elevation-z8"
                (matSortChange)="sortData($event)" matSortActive="id" matSortDirection="desc" [hidden]="semDados">

                <ng-container matColumnDef="selecao">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                      (change)="selectedModel = ($event.checked ? element : null);"
                      [checked]="selectedModel?.id === element.id">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                  <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <ng-container matColumnDef="dataRegistro">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Data</th>
                  <td mat-cell *matCellDef="let element"> {{element.dataRegistro | date:'dd/MM/yyyy HH:mm:ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="funcionalidade">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Funcionalidade </th>
                  <td mat-cell *matCellDef="let element"> {{element.funcionalidade.descricao}} </td>
                </ng-container>

                <ng-container matColumnDef="tipoLog">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ação Realizada </th>
                  <td mat-cell *matCellDef="let element"> {{element.tipoLog.descricao}} </td>
                </ng-container>

                <ng-container matColumnDef="nomeUsuario">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuário </th>
                  <td mat-cell *matCellDef="let element"> {{element.nomeUsuario}} </td>
                </ng-container>

                <ng-container matColumnDef="ip">
                  <th mat-header-cell *matHeaderCellDef> IP </th>
                  <td mat-cell *matCellDef="let element"> {{element.ip}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selecionar(row)" class="clickable"></tr>

              </table>
              <h5 [hidden]="!semDados" class="table-no-data">Sem dados a exibir</h5>
              <mat-paginator [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons  [length]="totalItens" (page)="selectedModel = null; pageEvent = pageChanged($event)">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
