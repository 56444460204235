<div style="text-align:right;">
  <button class="btn btn-info btn-xs mr-2" aria-expanded="false"
    (click)="visualizarMatriz()">
    <i class="far fas fa-eye mr-1"></i> Visualizar </button>
</div>
<div class="card-body p-0" *ngIf="dataSource?.data?.length > 0; else content">
  <div>
    <div class="collapse-tamanho" *ngFor="let item of dataSource?.data; index as i">
      <div id="accordion-{{i}}">
        <div class="card p-0">
          <div class="card-header" id="headingOne-{{i}}">
            <h5 class="mb-0">
              <div class="d-flex justify-content-between align-items-center">
                <button class="btn btn-link limit-text" data-toggle="collapse" attr.data-target="#collapseOne-{{i}}"
                  aria-expanded="true" attr.aria-controls="collapseOne-{{i}}">
                  {{item?.nome}}
                </button>
                <div>
                  <button class="btn btn-info btn-xs mr-2" aria-expanded="false"
                    (click)="modalUnidadeGestora(item)">
                    <i class="far fas fa-eye mr-1"></i> Unidades Gestoras
                  </button>
                </div>
              </div>
            </h5>
          </div>
          <div id="collapseOne-{{i}}" class="collapse p-0" attr.aria-labelledby="headingOne-{{i}}"
            attr.data-parent="#accordion-{{i}}">

            <h5 *ngIf="!item?.subtipoAuditoriaMatrizPlanejamento || item?.subtipoAuditoriaMatrizPlanejamento.length === 0" class="table-no-data">
              Nenhum registro encontrado.
            </h5>

            <div *ngFor="let matriz of item?.subtipoAuditoriaMatrizPlanejamento; index as j">
              <div id="accordion">
                <div class="card mb-0">
                  <div class="card-header" id="heading-{{j}}{{i}}-matriz">
                    <h5 class="mb-0">
                      <div class="d-flex justify-content-between align-items-center ml-3">
                        <button class="btn btn-link" data-toggle="collapse" attr.data-target="#collapseOne-{{j}}{{i}}-matriz"
                          aria-expanded="true" attr.aria-controls="collapseOne-{{j}}{{i}}-matriz">
                          {{matriz.matrizPlanejamento.nome}}
                        </button>
                        <div>
                          <button *ngIf="!visualizar && tipoTrabalho.nome==='planejamento'" class="btn btn-primary btn-xs mr-2" aria-expanded="false"
                            (click)="abrirModalAdicionarItem(matriz, matriz?.matrizPlanejamento?.matrizPlanejamentoQuestao, item.id)">
                            <i class="fas fa-plus"></i> Adicionar Questão
                          </button>
                        </div>
                      </div>
                    </h5>
                  </div>

                  <div id="collapseOne-{{j}}{{i}}-matriz" class="collapse" attr.aria-labelledby="heading-{{j}}{{i}}-matriz"
                    data-parent="#accordion">
                    <div class="card-body p-0">
                      <div class="card-body p-0 mat-elevation-z1">
                        <div class="rounded-2">

                          <app-listagem-questoes [visualizar]="visualizar" (excluirItemEvento)="obter()"
                            [planejamentoId]="id" [subtipoId]="item?.id"
                            [matrizPlanejamento]="matriz?.matrizPlanejamento"
                            [listaDados]="matriz.matrizPlanejamento.matrizPlanejamentoQuestao"
                            [displayedColumns]="displayedColumns"></app-listagem-questoes>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card-body p-0">
  <ng-template #content>
    <h5 class="table-no-data">
      Nenhum registro encontrado.
    </h5>
  </ng-template>
</div>
