import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { ICapituloRelatorioModel } from 'src/app/models/capitulo-relatorio.model';
import { CapituloRelatorioService } from 'src/app/services/capitulo-relatorio.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';

@Component({
  selector: 'app-capitulo-relatorio-list',
  templateUrl: './capitulo-relatorio-list.component.html',
  styleUrls: ['./capitulo-relatorio-list.component.scss']
})
export class CapituloRelatorioListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<ICapituloRelatorioModel>([]);
  public selectedModel: ICapituloRelatorioModel;
  public displayedColumns: string[] = ['selecao', 'nome', 'ativo'];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private capituloRelatorioService: CapituloRelatorioService,
    private toastr: ToastrService,
    private router: Router,
  ) {
    super();
    this.buscar();
  }

  public novo() {
    this.router.navigate(['/capitulos-relatorio/novo']);
  }

  public buscar() {
    this.selectedModel = null;

    this.capituloRelatorioService
      .obter(this.searchNome, this.searchAtivo, true)
      .then((res) => {
        this.dataSource = new MatTableDataSource<ICapituloRelatorioModel>(res.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.toastr.error(err.mensagem.descricao, 'Atenção');
      })
  }

  public reativar(id: number) {
    this.capituloRelatorioService
      .reativar(id)
      .then((res) => {
        if (res.sucesso) {
          this.toastr.success('Registro reativado com sucesso!', 'Sucesso');
        } else {
          this.toastr.warning(res.mensagem.descricao, 'Atenção');
        }
      })
      .catch((err) => {
        this.toastr.error(err.mensagem.descricao, 'Atenção');
      })
      .finally(() => {
        this.buscar();
      });
  }

  public inativar(id: number) {
    this.capituloRelatorioService
      .inativar(id)
      .then((res) => {
        if (res.sucesso) {
          this.toastr.success('Registro inativado com sucesso!', 'Sucesso');
        } else {
          this.toastr.warning(res.mensagem.descricao, 'Atenção');
        }
      })
      .catch((err) => {
        this.toastr.error(err.mensagem.descricao, 'Atenção');
      })
      .finally(() => {
        this.buscar();
      });
  }

  public editar(id: number) {
    this.router.navigate([`/capitulos-relatorio/${id}`]);
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }
}
