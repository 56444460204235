<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="card-header bg-light" cdkDragHandle>
      <h4 class="card-title mb-0">Visualizar Respostas</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="fechar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" [hidden]="semDados">
          <ng-container matColumnDef="ug">
            <th mat-header-cell *matHeaderCellDef> Unidade Gestora </th>
            <td mat-cell *matCellDef="let element"> {{element.unidadeGestora.nome}} </td>
          </ng-container>

          <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef> Valor ({{ criterio?.unidade }})</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="!element.perguntaOpcaoId">
                {{element.valorNumerico | number:'1.2':'pt'}}
              </ng-container>
              <ng-container *ngIf="element.perguntaOpcaoId">
                {{element.perguntaOpcao.nome}}
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

        </table>
        <h5 [hidden]=" !semDados" class="table-no-data">Sem dados a exibir</h5>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-default btn-sm" (click)="fechar()">
        <i class="fas fa-times"></i> Fechar
      </button>
    </div>
  </div>
</div>
