import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IBaseModel } from '../models/base.model';
import { IModeloRelatorioModel } from '../models/modelo-relatorio.model';
import { IRelatoriaModel } from '../models/relatoria.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class RelatoriaService extends BaseService {
  private relatoriaSource = new BehaviorSubject<any>('');
  public reltoriaCurrent = this.relatoriaSource.asObservable();

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy); 
  }

  public async excluirRelatoria(id: number): Promise<IBaseModel<IRelatoriaModel>> {
    return this.httpClient
      .delete<IBaseModel<IRelatoriaModel>>(`${this.apiBaseUrl}/relatorias/${id}/excluir`)
      .toPromise().then(res => {
        if(res.sucesso) this.relatoriaSource.next('');
        return res;
      });
  }

  public async gerar(relatoriaModel: (IRelatoriaModel | IModeloRelatorioModel)): Promise<IBaseModel<IRelatoriaModel | IModeloRelatorioModel>> {
    return this.httpClient
      .post<IBaseModel<IRelatoriaModel | IModeloRelatorioModel>>(`${this.apiBaseUrl}/relatorias/gerar-relatoria`, relatoriaModel )
      .toPromise();
  }

  public async obterPorTipoTrabalho(tipoTrabalhoId: number, tipoTrabalho: number): Promise<IBaseModel<IRelatoriaModel>> {
    return this.httpClient
      .get<IBaseModel<IRelatoriaModel>>(`${this.apiBaseUrl}/relatorias/${tipoTrabalhoId}/tipo-trabalho/${tipoTrabalho}`, {})
      .toPromise().then(res => {
        this.relatoriaSource.next(res.dados);
        return res;
      });
  } 

  public async habilitarGerar(idTrabalho: number, tipoTrabalho: number): Promise<IBaseModel<boolean>> {
    return this.httpClient
      .get<IBaseModel<boolean>>(`${this.apiBaseUrl}/relatorias/habilitar-gerar/${idTrabalho}/${tipoTrabalho}`, {})
      .toPromise();
  } 
  
}

