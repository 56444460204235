<div class="content-header" [hidden]="isModal">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/questao']">Questão Auditoria</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>


<div class="content-questoes" [ngClass]="{'h-100': isModal}">
  <div class="card" [ngClass]="{'text-left h-100 w-100': isModal, 'card-outline card-lightblue': !isModal}">
    <div class="card-header" [ngClass]="{'bg-light': isModal}">
      <h3 class="card-title mb-0">
        <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
        <i *ngIf="!this.id" class="far fa-file pr-2"></i>
        {{ titulo }}
      </h3>
    </div>

    <div class="card-body wrapper">
      <form [formGroup]="form" #f="ngForm" id="formQuestoes" 
        >
        <div class="row">

          <div class="form-group col-md-8">
            <label for="nome" class="mb-1">Questão Auditoria</label>
            <input type="text" id="nome" [attr.disabled]="visualizar ? true : null" class="form-control form-control-sm"
              formControlName="nome" maxlength="100"
              [ngClass]="{ 'is-invalid': f.submitted && form.controls['nome'].invalid }" />
            <div *ngIf="f.submitted && form.controls['nome'].invalid" class="invalid-feedback">
              <div *ngIf="form.controls['nome'].errors.required">Campo obrigatório</div>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label for="selectTipo" class="mb-1">Classificação da Questão </label>
            <select id="selectTipo" formControlName="classificacao" [attr.disabled]="visualizar ? true : null"
              class="form-control form-control-sm" [ngClass]="{ 'is-invalid': f.submitted &&
              form.controls['classificacao'].invalid }">
              <option [ngValue]="1">1</option>
              <option [ngValue]="2">2</option>
              <option [ngValue]="3">3</option>
              <option [ngValue]="4">4</option>
              <option [ngValue]="5">5</option>
            </select>
            <div *ngIf="f.submitted && form.controls['classificacao'].invalid" class="invalid-feedback">
              <div *ngIf="form.controls['classificacao'].errors.required">Campo obrigatório</div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <label for="descricao" class="mb-1">Descrição</label>
            <angular-editor [config]="config" #maxLengthEditor  formControlName="descricao" [ngClass]="{
                  'is-invalid':
                    f.submitted && form.controls['descricao'].invalid
                }"></angular-editor>

            <div *ngIf="f.submitted && form.controls['descricao'].invalid" class="invalid-feedback">
              <div *ngIf="form.controls['descricao'].errors.required">
                Campo obrigatório
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6">
            <div *ngIf="!visualizar" class="row">

              <div class="form-group col-md-12">
                <label for="inputConstatacao" class="mb-1">Constatação</label>
                <div class="input-group input-group-sm" style="width: 100%;">
                  <input type="text" id="inputConstatacao" class="form-control form-control-sm" readonly
                    [ngModel]="modelQuestaoConstatacao.constatacao?.nome" [ngModelOptions]="{standalone: true}" />
                  <div class=" input-group-append">
                    <button type="button" class="btn btn-info" (click)="exibirModalConstatacao()">
                      <i class="fas fa-search"></i>
                    </button>
                    <button type="button" class="btn btn-success" (click)="incluirConstatacao()"
                      [disabled]="!(modelQuestaoConstatacao.constatacaoId)">
                      <i class="fas fa-plus"></i>
                    </button>
                    <button type="button" class="btn btn-success ml-1" (click)="cadastroRapidoConstatacao()">
                      <i class="fas fa-plus"></i> Adicionar
                    </button>
                  </div>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-md-12" [ngClass]="{'form-group': visualizar}">
                <label for="inputConstatacao" class="mb-1" [hidden]="!visualizar">Constatação</label>
                <div class="mat-elevation-z1">
                  <table mat-table [dataSource]="constatacaoDataSource">
                    <tr mat-header-row *matHeaderRowDef="['nome', 'actions']" class="bg-light"></tr>

                    <ng-container matColumnDef="nome">
                      <th mat-header-cell *matHeaderCellDef>Nome </th>
                      <td mat-cell *matCellDef="let element"> {{ element.constatacao.nome }}</td>
                    </ng-container>

                    <ng-container cdkColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element; let i = index" class="block">
                        <div class="btn-group">
                          <button type="button" class="btn btn-info btn-xs text-left"
                            (click)="modalVisualizarConstatacao(element)">
                            <i class="far fas fa-eye"></i> </button>
                          <button *ngIf="!visualizar" type="button" class="btn btn-danger btn-xs text-left"
                            (click)="excluirConstatacao(element, i)">
                            <i class="far fa-trash-alt"></i></button>
                        </div>
                      </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: ['nome', 'actions'];"></tr>
                  </table>
                  <h5 [hidden]="!semDadosConstatacao" class="table-no-data">Nenhuma constatação cadastrada</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6">

            <div *ngIf="!visualizar" class="row">

              <div class="form-group col-md-12">
                <label for="inputProcedimento" class="mb-1">Procedimento</label>
                <div class="input-group input-group-sm" style="width: 100%;">
                  <input type="text" id="inputProcedimento" class="form-control form-control-sm" readonly
                    [ngModel]="modelQuestaoProcedimentoAuditoria.procedimentoAuditoria?.nome"
                    [ngModelOptions]="{standalone: true}" />
                  <div class=" input-group-append">
                    <button type="button" class="btn btn-info" (click)="exibirModalProcedimentoAuditoria()">
                      <i class="fas fa-search"></i>
                    </button>
                    <button type="button" class="btn btn-success" (click)="incluirProcedimentoAuditoria()"
                      [disabled]="!(modelQuestaoProcedimentoAuditoria.procedimentoAuditoriaId)">
                      <i class="fas fa-plus"></i>
                    </button>
                    <button type="button" class="btn btn-success ml-1" (click)="cadastroRapidoProcedimentoAuditoria()">
                      <i class="fas fa-plus"></i> Adicionar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" [ngClass]="{'form-group': visualizar}">
                <label for="inputConstatacao" class="mb-1" [hidden]="!visualizar">Procedimento</label>
                <div class="mat-elevation-z1">
                  <table mat-table [dataSource]="procedimentoAuditoriaDataSource">
                    <tr mat-header-row *matHeaderRowDef="['nome', 'actions']" class="bg-light"></tr>

                    <ng-container matColumnDef="nome">
                      <th mat-header-cell *matHeaderCellDef>Nome </th>
                      <td mat-cell *matCellDef="let element"> {{ element.procedimentoAuditoria.nome }}</td>
                    </ng-container>

                    <ng-container cdkColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element; let i = index" class="block">
                        <div class="btn-group">
                          <button type="button" class="btn btn-info btn-xs text-left"
                            (click)="modalVisualizarProcedimentoAuditoria(element)">
                            <i class="far fas fa-eye"></i> </button>
                          <button *ngIf="!visualizar" type="button" class="btn btn-danger btn-xs text-left"
                            (click)="excluirProcedimentoAuditoria(element, i)">
                            <i class="far fa-trash-alt"></i> </button>
                        </div>
                      </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: ['nome', 'actions'];"></tr>
                  </table>
                  <h5 [hidden]="!semDadosProcedimentoAuditoria" class="table-no-data">Nenhum procedimento auditoria
                    cadastrada</h5>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <label for="legislacaoAplicavelCriterio" class="mb-1">Legislação Aplicável/Critério</label>
            <angular-editor [config]="config" #maxLengthEditorLegislacao id="legislacaoAplicavelCriterio" rows="20"
              formControlName="legislacaoAplicavelCriterio" [ngClass]="{
                  'is-invalid':
                    f.submitted && form.controls['legislacaoAplicavelCriterio'].invalid
                }"></angular-editor>

            <div *ngIf="f.submitted && form.controls['legislacaoAplicavelCriterio'].invalid" class="invalid-feedback">
              <div *ngIf="form.controls['legislacaoAplicavelCriterio'].errors.required">
                Campo obrigatório
              </div>
            </div>
            <div *ngIf="form.controls?.description?.hasError('maxlengthError')" class="invalid-feedback">

                Ultrapassou o limite de 500 caracteres

            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="card-footer">
      <div class="d-flex justify-content-between w-100">
        <div class="btn-group" [hidden]="visualizar">
          <button class="btn btn-primary btn-sm mr" (click)="onSubmit(false)">
            <i class="far fa-save"></i> Salvar
          </button>
          <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
          <div class="dropdown-menu" role="menu">
            <a class="dropdown-item" (click)="onSubmit(false)">Salvar</a>
            <a class="dropdown-item" (click)="onSubmit(true)">Salvar e fechar</a>
          </div>
        </div>
    
        <button type="button" class="btn btn-default btn-sm" (click)="onBack()" [hidden]="visualizar">
          <i class="fas fa-times"></i> Cancelar
        </button>
    
        <button type="button" class="btn btn-default btn-sm" (click)="onBack()" [hidden]="!visualizar">
          <i class="fas fa-times"></i> Sair
        </button>
      </div>
    </div>      
    <div class="mostra-avaliacao">
      <input type="checkbox" name="avaliar-checkbox" id="avaliar-checkbox" hidden>
      <div class="avaliacoes" *ngIf="isModal === false && model?.status?.codigo === 2">
        <label for="avaliar-checkbox">
          <small> Avaliar questão</small>
        </label>
        <label for="avaliar-checkbox" class="avaliar-checkbox mt-2" (click)="aprovarQuestao(model.id)"><i class="fas fa-check text-success"></i></label>
        <label for="avaliar-checkbox" class="avaliar-checkbox" (click)="reprovarQuestao(model.id)"><i class="fas fa-times text-danger"></i></label>
      </div>
    </div>
  </div>
</div>
