import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { ITipoCriterioModel } from '../models/tipo-criterio.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class TipoCriterioService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<ITipoCriterioModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<ITipoCriterioModel[]>>(`${this.apiBaseUrl}/tipocriterio`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<ITipoCriterioModel>> {
    return this.httpClient
      .get<IBaseModel<ITipoCriterioModel>>(`${this.apiBaseUrl}/tipocriterio/${id}`)
      .toPromise();
  }

  public async inserir(data: ITipoCriterioModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/tipocriterio`, data)
      .toPromise();
  }

  public async atualizar(data: ITipoCriterioModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/tipocriterio/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/tipocriterio/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/tipocriterio/${id}/inativar`, {})
      .toPromise();
  }

  public async excluirTipoCriterio(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/tipocriterio/${id}/excluir`, {})
      .toPromise();
  }
}
