import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirma-cancelar',
  templateUrl: './modal-confirma-cancelar.component.html',
  styleUrls: ['./modal-confirma-cancelar.component.scss']
})
export class ModalConfirmaCancelarComponent implements OnInit {

  constructor(
    public matDialog: MatDialog,
    private dialogRef: MatDialogRef<ModalConfirmaCancelarComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;
  }

  public data = {} as any;
  ngOnInit(): void { }

  Sim() {
    if (this.data && this.data.interno) {
      this.matDialog.closeAll();
    }
    else {
      this.dialogRef.close(true);
    }
  }

  Nao() {
    this.dialogRef.close(false);
  }
}
