import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IResponsavelUnidadeGestoraModel } from 'src/app/models/responsavel-unidade-gestora.model';
import { PeaService } from 'src/app/services/pea.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-delegar',
  templateUrl: './modal-delegar-component.html',
})
export class ModalDelegarQuestionarioComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ModalDelegarQuestionarioComponent>,
    private peaService: PeaService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.model = data.dados;
    this.peaId = data.peaId;
    this.criterioId = data.criterioId;
    this.unidadeGestoraId = data.unidadeGestoraId;
  }

  public model: IResponsavelUnidadeGestoraModel;
  private peaId: number;
  private criterioId: number;
  private unidadeGestoraId: number;

  public idResponsavel;

  ngOnInit(): void {}

  cancelar() {
    this.dialogRef.close(true);
  }

  delegar() {
    this.peaService
      .DelegarQuestionario(this.peaId, this.criterioId, this.idResponsavel, this.unidadeGestoraId)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Delegação de responsabilidade realizada com sucesso!',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.dialogRef.close(true);
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'warning',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
  }
}
