<div id="modal-content-a-equipe">
    <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <div class="card-header bg-light" cdkDragHandle>
            <h4 class="card-title mb-0">Alocar Equipe</h4>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="cancelar()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-container p-3">
                <div class="row">
                    <div class="form-group col-md-12">
                        <label for="nome" class="mb-1">Unidade Gestora</label>
                        <input type="text" id="nome" class="form-control form-control-sm" disabled [value]="
                concatUg(
                  model?.paaDistribuicao?.unidadeGestora?.sigla,
                  model?.paaDistribuicao?.unidadeGestora?.nome
                )
              " />
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-12">
                        <label for="nome" class="mb-1">Tipo de Auditoria</label>
                        <input type="text" id="nome" class="form-control form-control-sm" disabled
                            [ngModel]="model?.paaDistribuicao?.tipoAuditoria?.nome" />
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6 was-validated"
                        [ngClass]="{ 'was-validated': dataObrigatoria === true }">
                        <label for="dataInicio" class="mb-1">Data Ínicio</label>
                        <input id="dataInicio" [(ngModel)]="model.dataInicio" required bsDatepicker
                            class="form-control form-control-sm suaClassDatePicker" [minDate]="minDate"
                            [maxDate]="maxDate" maxlength="10" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                            [daysDisabled]="[0,6]"
                            (ngModelChange)="ngOnChangeDataInicio($event);" />
                        <div class="invalid-feedback">
                            Insira uma data de ínicio.
                        </div>
                    </div>
                    <div class="form-group col-md-6 was-validated"
                        [ngClass]="{ 'was-validated': dataObrigatoria === true }">
                        <label for="dataFim" class="mb-1">Data Fim</label>
                        <input id="dataFim" [(ngModel)]="model.dataFim" required bsDatepicker
                            class="form-control form-control-sm suaClassDatePicker" [minDate]="minDate"
                            [maxDate]="maxDate" maxlength="10" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                            [daysDisabled]="[0,6]"
                            (ngModelChange)="ngOnChangeDataFim($event);" />
                        <div class=" invalid-feedback">
                            Insira uma data de fim.
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="mat-elevation-z1">
                            <table mat-table [dataSource]="auditorDataSource" class="w-100" #tabela1="matSort" matSort
                                matSortDisableClear>
                                <tr mat-header-row *matHeaderRowDef="['selecao', 'nome']" class="bg-light"></tr>

                                <ng-container matColumnDef="selecao">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                                            (click)="setDataObrigatoria()"
                                            (change)="element.selecionado = $event.checked"
                                            [checked]="element.selecionado">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="nome">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Auditores da {{ coordenadorNome }}
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.nomeAuditor }}
                                    </td>
                                </ng-container>

                                <tr mat-row *matRowDef="let row; columns: ['selecao', 'nome']"></tr>
                            </table>
                            <h5 [hidden]="!auditorSemDados" class="table-no-data">
                                Nenhum Auditor cadastrado
                            </h5>
                            <mat-paginator [hidden]="auditorSemDados" [pageSizeOptions]="[5]" showFirstLastButtons>
                            </mat-paginator>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="mat-elevation-z1">
                            <table mat-table [dataSource]="auditorOutraCoordenacaoDataSource" #tabela2="matSort" matSort
                                matSortDisableClear [hidden]="auditorOutraCoordenacaoSemDados">
                                <tr mat-header-row *matHeaderRowDef="['selecao', 'nome']" class="bg-light"></tr>

                                <ng-container matColumnDef="selecao">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-checkbox (click)="$event.stopPropagation(); setDataObrigatoria()"
                                            color="primary" (change)="element.selecionado = $event.checked"
                                            [checked]="element.selecionado">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="nome">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Auditores de outras Coordenações
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.nomeAuditor }}
                                    </td>
                                </ng-container>

                                <tr mat-row *matRowDef="let row; columns: ['selecao', 'nome']"></tr>
                            </table>
                            <h5 [hidden]="!auditorOutraCoordenacaoSemDados" class="table-no-data">
                                Nenhuma Auditor cadastrado
                            </h5>
                            <mat-paginator [hidden]="auditorOutraCoordenacaoSemDados" [pageSizeOptions]="[5]"
                                showFirstLastButtons>
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
                    <i class="fas fa-check"></i> Ok
                </button>

                <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
                    <i class="fas fa-times"></i> Cancelar
                </button>
            </div>
        </div>
    </div>
</div>