<div style="background-color: white" id="modal-visualizar-historico">
	<div
		class="card card-secondary card-outline"
		cdkDragBoundary="html"
		cdkDrag
		cdkDragRootElement=".cdk-overlay-pane"
	></div>

	<div class="card-header bg-light" cdkDragHandle>
		<h4 class="card-title mb-0">Capítulo/Subcapítulo</h4>
		<div class="card-tools">
			<button type="button" class="btn btn-tool" (click)="fechar()">
				<i class="fas fa-times"></i>
			</button>
		</div>
	</div>

	<div class="card-body">
		<div class="row">
			<div class="form-group col-md-3">
				<label for="dataAlteracao" class="mb-1"
					>Data da Alteração</label
				>
				<input
					disabled
					type="text"
					id="dataAlteracao"
					class="form-control form-control-sm"
					maxlength="100"
					[value]="getFormattedDate(historico.dataAlteracao)"
				/>
			</div>
			<div class="form-group col-md-9">
				<label for="realizadoPor" class="mb-1">Realizado Por</label>
				<input
					disabled
					type="text"
					id="realizadoPor"
					class="form-control form-control-sm"
					maxlength="100"
					[value]="
						historico?.usuario === undefined
							? ''
							: historico.usuario
					"
				/>
			</div>
			<!-- <div class="row">
				<label for="dadosCapitulo">Dados do Capítulo Anterior</label>
				<p [innerHTML]="textoRemovido"></p>
			</div>
			<div class="row">
				<label for="alteracao">Alteração</label>
				<p [innerHTML]="textoDestacado"></p>
			</div> -->
		</div>
		<div class="row">
			<label for="dadosCapitulo">Dados do Capítulo Anterior</label>
			<div class="capituloAnterior form-group col-md-12 row">
				<span
					class="row"
					id="dadosCapitulo"
					[innerHTML]="historico.conteudoAnterior"
				></span>
			</div>
			<div class="capituloAtual form-group col-md-12 row">
				<label class="row" [hidden]="removido == ''" for="alteracao"
					>Informações removidas:</label
				>
			</div>
			<div class="capituloAtual form-group col-md-12 row">
				<span
					[hidden]="removido == ''"
					[class.destaqueSublinhado]="removido != ''"
					class="row"
					[innerHTML]="removido"
				></span>
			</div>
			<div class="capituloAtual form-group col-md-12 row">
				<div class="col">
					<label [hidden]="removido == ''">Data Alteração: </label>
				</div>
				<div class="col">
					<label
						[hidden]="removido == ''"
						class="row"
						[innerHTML]="dataFormatada"
					></label>
				</div>
			</div>
		</div>
		<div class="row">
			<label for="alteracao">Alteração</label>
			<div class="capituloAtual form-group col-md-12 row">
				<span
					class="row"
					id="alteracao"
					[innerHTML]="historico.conteudoAtual"
				>
				</span>
			</div>
			<div class="capituloAtual form-group col-md-12 row">
				<label class="row" [hidden]="adicionado == ''" for="alteracao"
					>Informações adicionadas:</label
				>
			</div>
			<div class="capituloAtual form-group col-md-12 row">
				<span
					[hidden]="adicionado == ''"
					[class.destaqueTexto]="adicionado != ''"
					class="row"
					[innerHTML]="adicionado"
				>
				</span>
			</div>
			<div
				[hidden]="adicionado == ''"
				class="capituloAtual form-group col-md-12 row"
			>
				<div class="col">
					<label>Data Alteração: </label>
				</div>
				<div class="col">
					<label
						[hidden]="adicionado == ''"
						class="row"
						[innerHTML]="dataFormatada"
					></label>
				</div>
			</div>
		</div>
	</div>
	<div class="card-footer">
		<button type="button" class="btn btn-default btn-sm" (click)="fechar()">
			<i class="fas fa-times"></i> Fechar
		</button>
	</div>
</div>
