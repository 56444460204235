import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IAtividadeModel } from 'src/app/models/atividade.model';
import { ISubAtividadeModel } from 'src/app/models/sub-atividade.model';
import { CronogramaService } from 'src/app/services/cronograma.service';
import Swal from 'sweetalert2';
import { ModalCancelarComponent } from '../../modal-cancelar/modal-cancelar-component';

@Component({
  selector: 'app-modal-atividade-subatividade',
  templateUrl: './modal-atividade-subatividade.component.html',
  styleUrls: ['./modal-atividade-subatividade.component.scss']
})
export class ModalAtividadeSubatividadeComponent implements OnInit {
  public model: IAtividadeModel = {} as IAtividadeModel;
  public modelSub: ISubAtividadeModel = {} as ISubAtividadeModel
  public id: number;
  public novoRegistro = true;
  public titulo;
  public acao!: string;
  public editar: boolean = false;
  public valido: boolean = false

  public form = new FormGroup({
    nome: new FormControl(null, Validators.required),
    quantidadeDias: new FormControl(null, Validators.required,),
  });

  constructor(
    public matDialog: MatDialog,
    public route: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    public localeService: BsLocaleService,
    private dialogRef: MatDialogRef<ModalAtividadeSubatividadeComponent>,
    private cronogramaService: CronogramaService,
    @Inject(MAT_DIALOG_DATA) public data,

  ) {
    this.id = data.planejamentoId
    this.acao = data.acao;

    if (data.element.fasesAtividadeId) {
      this.titulo = 'Inserir SubAtividade';
      this.model = data.element;
    } else {
      this.titulo = 'Inserir Atividade';
      this.model = data.element
    }

    if (this.acao === 'editar') {
      this.titulo = 'Editar Atividade'
      this.model = data.element
      this.editar = true;
    }

    if (this.acao === 'editarSub') {
      this.titulo = 'Editar sub Atividade'
      this.modelSub = data.element
      this.editar = true;
    }

  }

  async ngOnInit() {

    try {
      if (this.editar) {
        this.form.patchValue(this.model);
      }
    } catch (err) {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
    }
  }

  public concluir() {
    if (this.form.invalid) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: 'Formulário Inválido',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.valido = true;
      return;
    }
    this.atualizarModel(this.form.value);
    this.model.quantidadeDias = Number(this.model.quantidadeDias)
    if (this.titulo === 'Inserir Atividade') {

      const atividade = {
        fasesAtividadeId: this.model.id,
        planejamentoId: this.id,
        nome: this.model.nome,
        quantidadeDias: Number(this.form.get('quantidadeDias').value),
      } as IAtividadeModel

      this.cronogramaService.inserirAtividade(atividade)
        .then((res) => {
          if (res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
          else {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'error',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: err.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        })
        .finally(() => {
          this.dialogRef.close(this.model);
        })
    }

    if (this.titulo === "Editar Atividade") {
      this.cronogramaService.atualizarAtividade({
        nome: this.model.nome,
        quantidadeDias: Number(this.form.get('quantidadeDias').value),
         }, this.model.id)
        .then((res) => {
          if (res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
          else {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'error',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
        }).catch((err) => {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: err.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        })
      this.dialogRef.close(this.model);
    }

    if (this.titulo === "Editar sub Atividade") {

      this.modelSub.quantidadeDias = Number(this.form.get("quantidadeDias").value);
      this.modelSub.nome = this.form.get('nome').value;

      this.cronogramaService.atualizarSubAtividade(this.modelSub, this.modelSub.id)
        .then((res) => {
          if (res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
          else {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'error',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: err.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        })
      this.dialogRef.close(this.model);
    }

    if (this.titulo === "Inserir SubAtividade") {

      this.modelSub.atividadeId = this.model.id;
      this.modelSub.nome = this.model.nome;
      this.modelSub.quantidadeDias = this.model.quantidadeDias;

      this.cronogramaService.inserirsubAtividade(this.modelSub)
        .then((res) => {
          if (res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
          else {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'error',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: err.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        })
        .finally(() => {
          this.dialogRef.close(this.modelSub);
        })
    }
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public cancelar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { interno: true };
    this.matDialog.open(ModalCancelarComponent, dialogConfig).afterClosed();
  }
}
