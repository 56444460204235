<div class="content">
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <form [formGroup]="form" #f="ngForm" (keydown.enter)="$event.preventDefault()">
            <div class="mt-4 mb-4">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="tetoBaixo" class="mb-1">Baixo</label>
                    <div class="input-group">
                      <input [readonly]="desabilitado" (change)="enviarClassificacao()" type="text" id="pisoBaixo"
                        class="form-control form-control-sm" formControlName="pisoBaixo" data-inputmask="'alias': 'numeric',
                      'radixPoint': ',', 'digits': 4, 'digitsOptional': false, 'placeholder': '0'" maxlength="6" />
                      <div class="input-group-prepend input-group-append">
                        <span class="input-group-text form-control-sm">a</span>
                      </div>
                      <input [readonly]="desabilitado" (change)="enviarClassificacao()" type="text" id="tetoBaixo"
                        class="form-control form-control-sm" formControlName="tetoBaixo"
                        (keyup)="calcular($event.target)" data-inputmask="'alias': 'numeric',
                      'radixPoint': ',', 'digits': 4, 'digitsOptional': false, 'placeholder': '0'" maxlength="6" />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="pisoMedio" class="mb-1">Médio</label>
                    <div class="input-group">
                      <input [readonly]="desabilitado" (change)="enviarClassificacao()" type="text" id="pisoMedio"
                        class="form-control form-control-sm mask-number" formControlName="pisoMedio"
                        (keyup)="calcular($event.target)" data-inputmask="'alias': 'numeric',
                        'radixPoint': ',', 'digits': 4, 'digitsOptional': false, 'placeholder': '0'" [ngClass]="{
                        'is-invalid': form.controls['pisoMedio'].invalid }" maxlength="6" />
                      <div class="input-group-prepend input-group-append">
                        <span class="input-group-text form-control-sm">a</span>
                      </div>
                      <input [readonly]="desabilitado" (change)="enviarClassificacao()" type="text" id="tetoMedio"
                        class="form-control form-control-sm mask-number" formControlName="tetoMedio"
                        (keyup)="calcular($event.target)" data-inputmask="'alias': 'numeric',
                        'radixPoint': ',', 'digits': 4, 'digitsOptional': false, 'placeholder': '0'" [ngClass]="{
                        'is-invalid': form.controls['tetoMedio'].invalid }" maxlength="6" />
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="pisoAlto" class="mb-1">Alto</label>
                    <div class="input-group">
                      <input [readonly]="desabilitado" (change)="enviarClassificacao()" type="text" id="pisoAlto"
                        class="form-control form-control-sm" formControlName="pisoAlto"
                        (keyup)="calcular($event.target)" data-inputmask="'alias': 'numeric',
                      'radixPoint': ',', 'digits': 4, 'digitsOptional': false, 'placeholder': '0'" maxlength="6" />
                      <div class="input-group-prepend input-group-append">
                        <span class="input-group-text form-control-sm">a</span>
                      </div>
                      <input [readonly]="desabilitado" (change)="enviarClassificacao()" type="text" id="tetoAlto"
                        class="form-control form-control-sm" formControlName="tetoAlto" data-inputmask="'alias': 'numeric',
                      'radixPoint': ',', 'digits': 4, 'digitsOptional': false, 'placeholder': '0'" maxlength="7" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>