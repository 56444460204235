import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { ITipoCriterioModel } from 'src/app/models/tipo-criterio.model';
import { AuthService } from 'src/app/services/auth.service';
import { TipoCriterioService } from 'src/app/services/tipo-criterio.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tipo-criterio-list',
  templateUrl: './tipo-criterio-list.component.html',
  styleUrls: ['./tipo-criterio-list.component.scss'],
})
export class TipoCriterioListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<ITipoCriterioModel>([]);
  public selectedModel: ITipoCriterioModel;
  public displayedColumns: string[] = ['selecao', 'nome', 'ativo'];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private tipoCriterioService: TipoCriterioService,
    private router: Router,
    private authService: AuthService
  ) {
    super();
    this.buscar();
  }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Visualizar);
 }


  public novo() {
    if (this.permissoes.incluir) {
      this.router.navigate(['/tipos-criterio/novo']);
    } else {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 15000,
        timerProgressBar: true,
        icon: 'error',
        title: 'Erro',
        text: 'Você não tem permissão para realizar esta ação.',
      });
    }
  }


  public buscar() {
    this.selectedModel = null;
    
    this.tipoCriterioService
      .obter(this.searchNome, this.searchAtivo, true)
      .then((res) => {
        this.dataSource = new MatTableDataSource<ITipoCriterioModel>(res.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 15000,
          timerProgressBar: true,
          icon: 'success',
          title: 'Atenção',
          text: err.mensagem.descricao,
        });
      })
  }

  public reativar(id: number) {
    if (!this.permissoes.inativarReativar) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 15000,
        timerProgressBar: true,
        icon: 'error',
        title: 'Erro',
        text: 'Você não tem permissão para realizar esta ação.',
      });
      return;
    }

    this.tipoCriterioService
      .reativar(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 15000,
            timerProgressBar: true,
            icon: 'success',
            text: res.mensagem.descricao,
          });
        } else {
          {
            this.exibirMensagemAlerta(res.mensagem.descricao);
          }
        }
      })
      .catch((err) => {
        {
          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 15000,
            timerProgressBar: true,
            icon: 'error',
            text: err.mensagem.descricao,
          });
        }
      })
      .finally(() => {
        this.buscar();
      });
  }

  public inativar(id: number) {
    if (this.permissoes.inativarReativar) {
      this.tipoCriterioService
        .inativar(id)
        .then((res) => {
          if (res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 15000,
              timerProgressBar: true,
              icon: 'success',
              text: res.mensagem.descricao,
            });
          } else {
            {
              this.exibirMensagemAlerta('Formulário inválido');
            }
          }
        })
        .catch((err) => {
          {
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              icon: 'error',
              text: err.mensagem.descricao,
            });
          }
        })
        .finally(() => {
          this.buscar();
        });
    } else {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'Você não tem permissão para inativar/reativar este item.',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }

  public editar(id: number) {
    if (this.permissoes.editar) {
      this.router.navigate([`/tipos-criterio/${id}`]);
    } else {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'Você não tem permissão para editar este item.',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }

  public selecionar(item: any) {
    this.selectedModel =
      !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }

  public visualizar(id: number) {
    if (this.permissoes.visualizar) {
      this.router.navigate([`/tipos-criterio/${id}/visualizar`]);
    } else {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'Você não tem permissão para visualizar este item.',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }

  public excluir(id: number) {
    if (this.permissoes.excluir) {
      this.tipoCriterioService
        .excluirTipoCriterio(id)
        .then((res) => {
          if (res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 15000,
              timerProgressBar: true,
            });
          } else {
            this.exibirMensagemAlerta(res.mensagem.descricao);
          }
        })
        .catch((err) => {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: err.mensagem.descricao,
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .finally(() => {
          this.buscar();
        });
    } else {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'Você não tem permissão para excluir este item.',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }
}
