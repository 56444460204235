import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  public apiBaseUrl = '';

  constructor(public httpClient: HttpClient, private locationStrategy: LocationStrategy) {
    const baseHref = this.locationStrategy.getBaseHref();
    const apiUrl = baseHref.endsWith('/') ? baseHref + 'api' : baseHref + '/api';
    this.apiBaseUrl = `${window.location.origin}${apiUrl}`;
  }
}
