import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAmostraModel } from 'src/app/models/amostra.model';
import { AmostraService } from 'src/app/services/amostra.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-imprimir-amostra',
  templateUrl: './imprimir-amostra.component.html',
  styleUrls: ['./imprimir-amostra.component.scss']
})
export class ImprimirAmostraComponent implements OnInit {
  public model = {} as IAmostraModel;
  public planejamentoId!: number;
  public amostraId!: number;
  public tipoAmostra!: string;
  public numeroOpa: string;
  public tituloImpressao: string;
  public statusPlanejamento: number;
  constructor(
    private amostraService: AmostraService,
    public route: ActivatedRoute,
    public router: Router,
  ) {
    this.planejamentoId = Number(this.route.snapshot.paramMap.get('id'));
    this.amostraId = Number(this.route.snapshot.paramMap.get('amostraId'));

    if (this.router.url.includes("planejamento")) {
      this.tipoAmostra = 'planejamento';
      var extras =this.router.getCurrentNavigation().extras;
      this.numeroOpa = extras.state.numeroOpa;
      this.tituloImpressao = `Amostra – OPA Nº ${this.numeroOpa}`;
      this.statusPlanejamento = extras.state.statusPlanejamento;
    }
    if (this.router.url.includes("osa")) {
      this.tipoAmostra = 'osa';
      this.tituloImpressao = `Relatório Amostra`;
    }
  }

  ngOnInit(): void {
    this.buscar();
  }

  public buscar() {
    this.amostraService.obterAmostraId(this.planejamentoId, this.amostraId, this.tipoAmostra).then(res => {
      if (res.sucesso) {
        this.model = res.dados;
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    }).catch(err => {

    }).finally(() => '')
  }

  public cancelar() {
    this.router.navigate([`planejamento/${window.history.state.planejamentoId}/planejar`], { state: { aba: 3 }})
  }

  public print() {
    window.print();
  }

}
