import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { IPlanejamentoEquipeModel } from 'src/app/models/planejamento-equipe.model';
import { IAuditorCoordenacaoModel } from 'src/app/models/auditor-coordenacao.model';
import { ITipoAuditoriaModel } from 'src/app/models/tipo-auditoria.model';
import { IPlanejamentoEquipeAuditorModel } from 'src/app/models/planejamento-equipe-auditor.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-alocar-equipe-planejamento',
  templateUrl: './modal-alocar-equipe-planejamento.component.html',
  styleUrls: ['./modal-alocar-equipe-planejamento.component.scss'],
})
export class ModalAlocarEquipePlanejamentoComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('tabela1') public tabela1: MatSort;
  @ViewChild('tabela2') public tabela2: MatSort;

  public model = {} as IPlanejamentoEquipeModel;
  public auditores = [] as IAuditorCoordenacaoModel[];
  public tipoAuditoria = {} as ITipoAuditoriaModel;
  public auditoresTipoAuditoria = [] as IAuditorCoordenacaoModel[];
  public coordenacaoNome: string;
  public auditoresTipoAuditoriaDataSource = new MatTableDataSource<IAuditorCoordenacaoModel>([]);
  public auditoresTipoAuditoriaSemDados = true;
  public submit = false;
  public novoAuditor: string;

  public auditoresOutrasCoordenacoesDataSource = new MatTableDataSource<IAuditorCoordenacaoModel>([]);
  public auditoresOutrasCoordenacoesSemDados = true;

  public auditoresConflito: IAuditorCoordenacaoModel[];

  constructor(
    private dialogRef: MatDialogRef<ModalAlocarEquipePlanejamentoComponent>,
    public toastr: ToastrService,
    private dateHelper: DateHelper,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.model = data.equipe;
    this.auditores = data.auditores;
    this.tipoAuditoria = data.tipoAuditoria;
    this.auditoresTipoAuditoria = data.auditoresTipoAuditoria;

    this.coordenacaoNome = this.tipoAuditoria?.coordenacao?.nome;

    if (this.model.dataInicio != null) {
      this.model.dataInicio = new Date(this.model.dataInicio);
    }

    if (this.model.dataFim != null) {
      this.model.dataFim = new Date(this.model.dataFim);
    }

    // remover os auditores da coordenação do tipo da auditoria da listagem completa de auditores
    this.auditoresTipoAuditoria.forEach(auditorTipoAuditoria => {
      this.auditores = this.auditores.filter(auditor => {
        return auditor.auditorId !== auditorTipoAuditoria.auditorId;
      });
    });

    this.marcarComoSelecionado(this.auditoresTipoAuditoria, this.model.auditores);
    this.marcarComoSelecionado(this.auditores, this.model.auditores);

    this.auditoresTipoAuditoria = this.auditoresTipoAuditoria.map(a => {
      var result = this.model.auditores.find(b =>  b.auditorId === a.auditorId)
      if(result?.auditorId) {
        a.selecionado = true;
      }
      return a;
    })

    this.auditoresTipoAuditoriaDataSource = new MatTableDataSource<IAuditorCoordenacaoModel>(this.auditoresTipoAuditoria);
    this.auditoresTipoAuditoriaSemDados = this.auditoresTipoAuditoriaDataSource.data.length === 0;
    this.auditoresTipoAuditoriaDataSource._updateChangeSubscription();


    this.auditoresOutrasCoordenacoesDataSource = new MatTableDataSource<IAuditorCoordenacaoModel>(this.auditores);
    this.auditoresOutrasCoordenacoesSemDados = this.auditoresOutrasCoordenacoesDataSource.data.length === 0;
    this.auditoresOutrasCoordenacoesDataSource._updateChangeSubscription();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.tabela1.sort({ id: 'nome', start: 'asc' } as MatSortable);
    this.auditoresTipoAuditoriaDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'nome':
          return item.nomeAuditor;
        default:
          return item[property];
      }
    };
    this.auditoresTipoAuditoriaDataSource.sort = this.tabela1;

    this.tabela2.sort({ id: 'nome', start: 'asc' } as MatSortable);
    this.auditoresOutrasCoordenacoesDataSource.sortingDataAccessor = (
      item,
      property
    ) => {
      switch (property) {
        case 'nome':
          return item.nomeAuditor;
        default:
          return item[property];
      }
    };
    this.auditoresOutrasCoordenacoesDataSource.sort = this.tabela2;
  }

  public async concluir() {
    this.submit = true;
    this.model.dataInicio = this.dateHelper.truncateDate(this.model.dataInicio);
    this.model.dataFim = this.dateHelper.truncateDate(this.model.dataFim);

    if (!this.model.dataInicio  || !this.model.dataFim) {
      return;
    }

    if (this.model.dataInicio.getTime() > this.model.dataFim.getTime()) {
      this.exibirMensagemAlerta('Data inicial não pode ser maior que a data final');
      return;
    }

    let auditoresSelecionados  = this.auditoresTipoAuditoriaDataSource.data.filter(d => d.selecionado);
    auditoresSelecionados = auditoresSelecionados.concat(this.auditoresOutrasCoordenacoesDataSource.data.filter(d => d.selecionado));

    // verifica se os auditores anteriores continuam selecionados
    this.model.auditores = this.model.auditores.filter(auditor => {
      return auditoresSelecionados.filter(as => as.auditorId === auditor.auditorId).length > 0;
    });

    // remove os autidores que já estavam na equipe
    auditoresSelecionados = auditoresSelecionados.filter(auditor => {
      return this.model.auditores.filter(a => a.auditorId === auditor.auditorId).length === 0;
    });

    // contem algum auditor selecionado
    let auditoresQueEstavamAnteriormente = this.model.auditores.filter(auditor => {
      return auditoresSelecionados.filter(a => a.auditorId === auditor.auditorId).length === 0;
    });

    if (auditoresSelecionados.length === 0 && auditoresQueEstavamAnteriormente.length == 0) {
      this.exibirMensagemAlerta('Informe algum Auditor');
      return;
    }
    if (auditoresSelecionados.length !== 0) {
      auditoresSelecionados.forEach(auditor => {
        const novoAuditor = { auditorId: auditor.auditorId, nomeAuditor: auditor.nomeAuditor, planejamentoEquipeId: this.model.planejamentoId } as IPlanejamentoEquipeAuditorModel;
        this.model.auditores.push(novoAuditor);
      });
    }
    this.dialogRef.close(this.model);
  }

  public cancelar() {
    this.dialogRef.close();
  }

  private marcarComoSelecionado(auditores: IAuditorCoordenacaoModel[], auditoresSelecionados: IPlanejamentoEquipeAuditorModel[]){
    auditores.forEach(auditor => {
      auditor.selecionado = auditoresSelecionados.filter(as => as.auditorId === auditor.auditorId).length > 0;
    });
  }

  private exibirMensagemAlerta(mensagem: string) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'warning',
      text: mensagem,
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
    });
  }
}
