import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import * as cloneDeep from 'lodash/cloneDeep';
import { IBaseModel } from 'src/app/models/base.model';
import { ISubtipoAuditoriaModel } from 'src/app/models/subtipo-auditoria.model';
import { ISubtipoAuditoriaMatrizPlanejamentoModel } from 'src/app/models/subtipo-auditoria-matriz-planejamento.model';
import { SubtipoAuditoriaService } from 'src/app/services/subtipo-auditoria.service';
import { MatrizPlanejamentoService } from 'src/app/services/matriz-planejamento.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subtipo-auditoria-form',
  templateUrl: './subtipo-auditoria-form.component.html',
  styleUrls: ['./subtipo-auditoria-form.component.scss']
})
export class SubtipoAuditoriaFormComponent extends BaseFormComponent implements OnInit {
  public model: ISubtipoAuditoriaModel = {} as ISubtipoAuditoriaModel;
  public modelSubtipoAuditoriaMatrizPlanejamento: ISubtipoAuditoriaMatrizPlanejamentoModel = {} as ISubtipoAuditoriaMatrizPlanejamentoModel;
  public matrizPlanejamentoDataSource = new MatTableDataSource<ISubtipoAuditoriaMatrizPlanejamentoModel>([]);
  public subTipoAuditoriaCancelar: boolean;

  public semDados = true;

  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private subtipoAuditoriaService: SubtipoAuditoriaService,
    private matrizPlanejamentoService: MatrizPlanejamentoService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Subtipo de Auditoria';
    }
  }

  config: AngularEditorConfig = {
    editable: !this.visualizar,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async ngOnInit() {
    try {
      if (!this.novoRegistro) {
        const res = await this.subtipoAuditoriaService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;

          if (this.visualizar){
            this.titulo = 'Visualizar Subtipo de Auditoria - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Subtipo de Auditoria - ' + this.model.nome;
          }

          // matriz planejamento
          this.matrizPlanejamentoDataSource = new MatTableDataSource<ISubtipoAuditoriaMatrizPlanejamentoModel>
          (
            this.model.subtipoAuditoriaMatrizPlanejamento
          );
          this.matrizPlanejamentoDataSource._updateChangeSubscription();
          this.semDados = this.matrizPlanejamentoDataSource.filteredData.length === 0;
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.router.navigate(['/subtipo-auditoria']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.router.navigate(['/subtipo-auditoria']);
    } 
    this.state();
    this.form.patchValue(this.model);
  }

  public state() {
    // Subtipo Auditoria
    if (window.history.state.modelSubtipoAuditoria) {
      this.model = window.history.state.modelSubtipoAuditoria;
    }

    // Matriz Planejamento
    if (window.history.state.listaMatrizPlanejamento){
      this.matrizPlanejamentoDataSource = new MatTableDataSource<ISubtipoAuditoriaMatrizPlanejamentoModel>
      (
        window.history.state.listaMatrizPlanejamento
      );
      this.matrizPlanejamentoDataSource._updateChangeSubscription();
      this.semDados = this.matrizPlanejamentoDataSource.filteredData.length === 0;
    }
  }

  public async onSubmit(salvarEFechar: boolean = false) {
    if (this.form.invalid) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: 'Formulário invalido',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return;
    }
    this.submit = true;

    this.atualizarModel(this.form.value);

    this.model.subtipoAuditoriaMatrizPlanejamento = this.matrizPlanejamentoDataSource.data;

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        res = await this.subtipoAuditoriaService.atualizar(this.model);
      } else {
        res = await this.subtipoAuditoriaService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        if (salvarEFechar) {
          this.router.navigate(['/subtipo-auditoria']);
        }else
        if(this.novoRegistro){
          this.router.navigate([`/subtipo-auditoria/${res.dados.id}/edicao`]);
          await this.ngOnInit();
        }
      } else {
        const validacoes = this.montarMensagensValidacao(res);

        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public async exibirModalMatrizPlanejamento() {
    this.subTipoAuditoriaCancelar = true;
    try {
      const res = await this.matrizPlanejamentoService.obter('', true);
      if (res.sucesso) {
        const dadosFiltrados = res.dados.filter(questao => questao.ativo);
        const modal = this.exibirModal('Selecionar Matriz de planejamento', dadosFiltrados);
        modal.afterClosed().subscribe(data => {
          if (data) {
            this.modelSubtipoAuditoriaMatrizPlanejamento.matrizPlanejamentoId = data.id;
            this.modelSubtipoAuditoriaMatrizPlanejamento.matrizPlanejamento = data;
          }
        }
        );
      } else {
        this.toastr.error(res.mensagem.descricao, 'Atenção');
      }
    } catch (err) {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
    }
  }

  public incluirMatrizPlanejamento() {
    if (this.matrizPlanejamentoDataSource.data.findIndex(a => a.matrizPlanejamentoId === this.modelSubtipoAuditoriaMatrizPlanejamento.matrizPlanejamentoId) >= 0) {
      this.toastr.warning('Matriz de planejamento já cadastrada nesse subtipo de auditoria', 'Atenção');
      return;
    }

    this.matrizPlanejamentoDataSource.data.push(cloneDeep(this.modelSubtipoAuditoriaMatrizPlanejamento));
    this.matrizPlanejamentoDataSource._updateChangeSubscription();
    this.modelSubtipoAuditoriaMatrizPlanejamento = {} as ISubtipoAuditoriaMatrizPlanejamentoModel;
    this.semDados = false;
  }

  public excluirMatrizPlanejamento(ind: number) {
    this.matrizPlanejamentoDataSource.data.splice(ind, 1);
    this.matrizPlanejamentoDataSource._updateChangeSubscription();
    this.semDados = this.matrizPlanejamentoDataSource.filteredData.length === 0;
  }

  public visualizarMatrizPlanejamento(ind: number) {
    this.subTipoAuditoriaCancelar = true;
    this.atualizarModel(this.form.value);
    this.router.navigate([`/matriz-planejamento/${ind}/visualizar`], {
      state: {
        modelSubtipoAuditoria: this.model,
        listaMatrizPlanejamento: this.matrizPlanejamentoDataSource.data,
        rotaPrefixoSubtipoAuditoria: `/subtipo-auditoria/${this.id !== undefined ? this.id + '/' : ''}${this.prefixo}`
      },
    });
  }

  public async cadastroRapidoMatrizPlanejamento() {
    this.subTipoAuditoriaCancelar = true;
    this.atualizarModel(this.form.value);
    this.router.navigate([`/matriz-planejamento/novo`], {
      state: {
        modelSubtipoAuditoria: this.model,
        listaMatrizPlanejamento: this.matrizPlanejamentoDataSource.data,
        rotaPrefixoSubtipoAuditoria: `/subtipo-auditoria/${this.id !== undefined ? this.id + '/' : ''}${this.prefixo}`
      },
    });
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.router.navigate(['/subtipo-auditoria']);
  }

  public cancelar() {
    this.router.navigate(['/subtipo-auditoria']);
  }
}
