import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { SubtipoAuditoriaFormComponent } from 'src/app/pages/subtipo-auditoria-form/subtipo-auditoria-form.component';

@Injectable()
export class SubtipoAuditoriaGuard
  implements CanDeactivate<SubtipoAuditoriaFormComponent> {
  canDeactivate(
    component: SubtipoAuditoriaFormComponent
  ): Observable<boolean> | boolean {
    if (!component.visualizar) {
      if (component.submit) { return true; }
        if(component?.subTipoAuditoriaCancelar) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    }
    else { return true; }
  }
}
