<div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item">
              <a [routerLink]="['/']">
                <i class="fas fa-home"></i>
                Página Inicial</a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/categoria-risco']">Categoria de Risco</a>
            </li>
            <li class="breadcrumb-item active">{{ titulo }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-outline card-lightblue">
            <div class="card-header">
              <h3 class="card-title mb-0">
                <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
                <i *ngIf="!this.id" class="far fa-file pr-2"></i>
                {{ titulo }}
              </h3>
            </div>
            <form [formGroup]="form" #f="ngForm"  (keydown.enter)="$event.preventDefault()">
              <div class=" card-body">
                <div class="row">
                  <div class="form-group col-md-8">
                    <label for="nome" class="mb-1">Nome</label>
                    <input [attr.disabled]="visualizar ? true : null" type="text" id="nome" class="form-control form-control-sm" formControlName="nome"
                      maxlength="100" [ngClass]="{ 'is-invalid': f.submitted && form.controls['nome'].invalid }" />
                    <div *ngIf="f.submitted && form.controls['nome'].invalid" class="invalid-feedback">
                      <div *ngIf="form.controls['nome'].errors.required">Campo obrigatório</div>
                    </div>
                  </div>
  
                </div>
  
                <div class="row">
                  <div class="form-group col-md-12">
                    <label for="inputDescricao" class="mb-1">Descrição</label>
                    <textarea [attr.disabled]="visualizar ? true : null" rows="3" id="inputDescricao" class="form-control form-control-sm"
                      formControlName="descricao" maxlength="500" [ngClass]="{ 'is-invalid': f.submitted &&
                      form.controls['descricao'].invalid }">
                    </textarea>
                    <div *ngIf="f.submitted && form.controls['descricao'].invalid" class="invalid-feedback">
                      <div *ngIf="form.controls['descricao'].errors.required">Campo obrigatório</div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="card-footer">
                <div class="btn-group" [hidden]="visualizar">
                  <button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
                      <i class="far fa-save"></i> Salvar
                  </button>
                  <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                  <div class="dropdown-menu" role="menu">
                  <a class="dropdown-item" (click)="salvarDados(false)">Salvar</a>
                  <a class="dropdown-item" (click)="salvarDados(true)">Salvar e fechar</a>
                  </div>
              </div>
  
                <button [hidden]="visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                  <i class="fas fa-times"></i> Cancelar
                </button>
  
                <button [hidden]="!visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                  <i class="fas fa-times"></i> Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  