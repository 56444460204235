import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { defaultDataAcessor } from "src/app/core/helpers/mat-sort-data-accessor";
import { EnumAcaoRecurso } from "src/app/models/enum/acao-recurso.enum";
import { EnumRecursoPerfil } from "src/app/models/enum/recurso-perfil.enum";
import { IPermissaoModel } from "src/app/models/permissao.model";
import { IRiscosModel } from "src/app/models/riscos.model";
import { AuthService } from "src/app/services/auth.service";
import { RiscosService } from "src/app/services/riscos.service";
import { FiltroModel } from "src/app/models/filtro.model";
import { TipoEnfaseService } from "src/app/services/tipo-enfase.service";
import { SubtipoAuditoriaService } from "src/app/services/subtipo-auditoria.service";
import { BaseListComponent } from "src/app/shared/components/base-list/base-list.component";
import { CategoriaRiscoService } from "src/app/services/categoria-risco.service";
import { ICategoriaRiscoModel } from "src/app/models/categoria-risco.model";
import Swal from "sweetalert2";

const sortingDataAccessor = (item, property) => {
	switch (property) {
		case "nome":
			return item.nome.trim().toLowerCase();
		case "categoriaRisco":
			return item.categoriaRisco.trim().toLowerCase;
		case "tipoEnfase":
			return item.tipoEnfase.trim().toLowerCase;
		case "subtipoAuditoria":
			return item.subtipoAuditoria.trim().toLowerCase;
		case "ativo":
			return item.ativo;
		default:
			return item.nome.trim().toLowerCase();
	}
};

@Component({
	selector: "app-riscos-list",
	templateUrl: "./riscos-list.component.html",
	styleUrls: ["./riscos-list.component.scss"],
})
export class RiscosListComponent extends BaseListComponent implements  OnInit{
	// declarar aqui as variavis
	public dataSource = new MatTableDataSource<IRiscosModel>([]);
	public selectedModel: IRiscosModel;
	public displayedColumns: string[] = [
		"selecao",
		"nome",
		"categoriaRisco",
		"tipoEnfase",
		"subtipoAuditoria",
		"ativo",
	];
	public searchNome: string;
	public searchAtivo = true;
	public semDados = true;
	public permissoes: IPermissaoModel;
	public filtro = {} as FiltroModel;
	public totalItens: number;
	public pageEvent: any;
	public categoriaRisco: { resultado: ICategoriaRiscoModel[] } = {
		resultado: [],
	};
	public riscos: any;
	public categorias: { nome: string }[] = [];
	public filtroSubTipoAuditoria: string | null = null;
	public filtroCategoriaRisco: string | null = null;
	public filtroTipoEnfase: string | null = null;

	@ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		private authService: AuthService,
		private riscosService: RiscosService,
		private router: Router,
		public tipoEnfaseService: TipoEnfaseService,
		public subtipoAuditoriaService: SubtipoAuditoriaService,
		public categoriaRiscoService: CategoriaRiscoService,
		private toastr: ToastrService
	) {
		super();
	}

  async ngOnInit() {
		this.setarPermissoes();
		this.configurarFiltro();		
    await  this.buscar();
		this.dataSource.sort = this.sort;
	}

	private setarPermissoes(): void {
		this.permissoes = new IPermissaoModel();
		this.permissoes.consultar = this.authService.possuiPermissao(
			EnumRecursoPerfil.Cadastros,
			EnumAcaoRecurso.Consultar
		);
		this.permissoes.editar = this.authService.possuiPermissao(
			EnumRecursoPerfil.Cadastros,
			EnumAcaoRecurso.Editar
		);
		this.permissoes.excluir = this.authService.possuiPermissao(
			EnumRecursoPerfil.Cadastros,
			EnumAcaoRecurso.Excluir
		);
		this.permissoes.inativarReativar = this.authService.possuiPermissao(
			EnumRecursoPerfil.Cadastros,
			EnumAcaoRecurso.AtivarInativar
		);
		this.permissoes.incluir = this.authService.possuiPermissao(
			EnumRecursoPerfil.Cadastros,
			EnumAcaoRecurso.Inserir
		);
		this.permissoes.visualizar = this.authService.possuiPermissao(
			EnumRecursoPerfil.Cadastros,
			EnumAcaoRecurso.Visualizar
		);
	}

	ngAfterViewInit() {
		this.configurarFiltro();
		
		this.getCategoriaRisco();
		this.getSubtiposAuditoria();
		this.getTipoEnfase();

		this.buscar();
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;
		this.dataSource.sortingDataAccessor = sortingDataAccessor;
	}

	public novo() {
		this.router.navigate(["/riscos/novo"]);
	}

  async	 buscar() {
		this.selectedModel = null;
		this.riscosService
			.obter(this.filtro)
			.then((res) => {
				this.totalItens = res.dados.totalItens;
				this.dataSource = new MatTableDataSource<any>(res.dados.resultado);
				this.riscos = res.dados.resultado;
				this.paginator = this.tableOnePaginator;
				this.paginator.length = this.totalItens;
				this.dataSource.sort = this.sort;
				this.dataSource.sortingDataAccessor = sortingDataAccessor;
				this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
					switch (sortHeaderId) {
						case "categoriaRisco":
							return data.nomeCategoriaRisco?.toLowerCase() || "";
						case "nome":
							return data.nome.toLowerCase();
						case "tipoEnfase":
							return data.nomeTipoEnfase.toLowerCase();
						case "subtipoAuditoria":
							return data.nomeSubTipoAuditoria.toLowerCase();
						case "ativo":
							return data.ativo;

						default:
							return data[sortHeaderId];
					}
				  };
				this.dataSource._updateChangeSubscription();
				this.semDados = this.dataSource.filteredData.length === 0;				
			})
			.catch((err) => {
				this.exibirMensagemErro(err.mensagem);
			});
	}

	public reativar(id: number) {
		this.riscosService
			.reativar(id)
			.then((res) => {
				if (res.sucesso) {
					this.exibirMensagemSucesso(" Risco reativado com sucesso.");
				} else {
					this.exibirMensagemAlerta(res.mensagem.descricao);
				}
			})
			.catch((err) => {
				this.exibirMensagemErro(err);
			})
			.finally(() => {
				this.buscar();
			});
	}

	public inativar(id: number) {
		this.riscosService
			.inativar(id)
			.then((res) => {
				if (res.sucesso) {
					this.exibirMensagemSucesso("Riscos inativado com sucesso.");
				} else {
					this.exibirMensagemAlerta(res.mensagem.descricao);
				}
			})
			.catch((err) => {
				this.exibirMensagemErro(err.mensagem);
			})
			.finally(() => {
				this.buscar();
			});
	}

	public editar(id: number) {
		this.router.navigate([`/riscos/${id}`]);
	}

	public selecionar(item: any) {
		this.selectedModel =
			!this.selectedModel || this.selectedModel.id !== item.id
				? item
				: null;
	}

	public excluir(id: number) {
		this.riscosService
			.excluir(id)
			.then((res) => {
				if (res.sucesso) {
					this.exibirMensagemSucesso("Risco excluído com sucesso.");
				} else {
					this.exibirMensagemAlerta(res.mensagem.descricao);
				}
			})
			.catch((err) => {
				this.exibirMensagemErro(err.mensagem);
			})
			.finally(() => {
				this.buscar();
			});
	}

	public visualizar(id: number) {
		this.router.navigate([`/riscos/${id}/visualizar`]);
	}

	private configurarFiltro() {
		this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
		this.filtro.itensPorPagina = this.tableOnePaginator.pageSize;
		this.filtro.colunaOrder = this.sort.active;
		this.filtro.ativo = this.searchAtivo;
		this.filtro.pesquisa = this.searchNome;
		this.filtro.direcao = this.sort.direction;
	}

	sortData(sort) {
		this.filtro.colunaOrder = sort.active;
		this.filtro.direcao = sort.direction;

		this.buscar();
	}

	pageChanged(e) {
		this.filtro.pagina = e.pageIndex + 1;
		e.pageIndex = this.filtro.pagina;
		this.filtro.itensPorPagina = e.pageSize;
		this.buscar();
	}

	//funções do filtro para filtrar por cada tipo

	private uniqueFilters: Set<string> = new Set<string>();

	public selecionarCategoria(nomeCategoria: string) {
		this.filtroCategoriaRisco = nomeCategoria;
		this.dataSource.filter = nomeCategoria.trim().toLowerCase() || "";
		this.filtroSubTipoAuditoria = "";
		this.filtroTipoEnfase = "";

		this.uniqueFilters.clear();
		if (nomeCategoria) {
			this.uniqueFilters.add(nomeCategoria.trim().toLowerCase());

			this.dataSource.filterPredicate = (
				data: IRiscosModel,
				filter: string
			) => {
				const filterValues = filter
					.split("|")
					.map((value) => value.trim().toLowerCase());
				return filterValues.includes(
					data.nomeCategoriaRisco.trim().toLowerCase()
				);
			};

			this.dataSource.filter = Array.from(this.uniqueFilters).join("|");
		} else {
			this.uniqueFilters.clear();
			this.dataSource.filter = "";
		}
	}

	public selecionarTipoEnfase(nomeTipoEnfase: string) {
		this.filtroTipoEnfase = nomeTipoEnfase;
		this.dataSource.filter = nomeTipoEnfase.trim().toLowerCase() || "";
		this.filtroCategoriaRisco = "";
		this.filtroSubTipoAuditoria = "";

		this.uniqueFilters.clear();
		if (nomeTipoEnfase) {
			this.uniqueFilters.add(nomeTipoEnfase.trim().toLowerCase());

			this.dataSource.filterPredicate = (
				data: IRiscosModel,
				filter: string
			) => {
				const filterValues = filter
					.split("|")
					.map((value) => value.trim().toLowerCase());
				return filterValues.includes(
					data.nomeTipoEnfase.trim().toLowerCase()
				);
			};

			this.dataSource.filter = Array.from(this.uniqueFilters).join("|");
		} else {
			this.uniqueFilters.clear();
			this.dataSource.filter = "";
		}
	}

	public selecionarSubTipoAuditoria(nomeSubTipoAuditoria: string) {
		this.filtroSubTipoAuditoria = nomeSubTipoAuditoria;
		this.dataSource.filter =
			nomeSubTipoAuditoria.trim().toLowerCase() || "";
		this.filtroCategoriaRisco = "";
		this.filtroTipoEnfase = "";

		this.uniqueFilters.clear();

		if (nomeSubTipoAuditoria) {
			this.uniqueFilters.add(nomeSubTipoAuditoria.trim().toLowerCase());

			this.dataSource.filterPredicate = (
				data: IRiscosModel,
				filter: string
			) => {
				const filterValues = filter
					.split("|")
					.map((value) => value.trim().toLowerCase());
				return filterValues.includes(
					data.nomeSubTipoAuditoria.trim().toLowerCase()
				);
			};

			this.dataSource.filter = Array.from(this.uniqueFilters).join("|");
		} else {
			this.uniqueFilters.clear();
			this.dataSource.filter = "";
		}
	}

	// funções para popular filtro e retirar duplicatas
	listaCategoria:any[] = [];
	listaTipoEnfase:any[] = [];
	listaSubTipoAuditoria:any[] = [];

	public getSubtiposAuditoria() {
		this.riscosService
		.obterSubTipoAuditoria()
		.then((res) => {
		  this.listaSubTipoAuditoria = res.dados;
		})
		.catch((err) => {
		  this.toastr.error(err.mensagem.descricao, 'Atenção');
		})
	}

	public getTipoEnfase() {
		this.riscosService
		.obterTipoEnfase()
		.then((res) => {
		  this.listaTipoEnfase = res.dados;
		})
		.catch((err) => {
		  this.toastr.error(err.mensagem.descricao, 'Atenção');
		})
	}

	public getCategoriaRisco() {
		this.riscosService
		.obterCategorias()
		.then((res) => {
		  this.listaCategoria = res.dados;
		})
		.catch((err) => {
		  this.toastr.error(err.mensagem.descricao, 'Atenção');
		})	  
	}
}
