<div class="card-header bg-light">
  <h4 class="card-title mb-0">Incluir UG</h4>
</div>
<div class="card-body wrapper">

  <div class="row">
    <div class="col-sm-5">
      <label for="ugs" class="form-label">Selecionar UG</label>
        <ng-select placeholder="Selecionar unidade gestora" [ngModelOptions]="{ standalone: true }" [(ngModel)]="ugSelecionada">
          <ng-option *ngFor="let item of unidadesGestoras" [value]="item">
            {{ item?.sigla }}-{{ item?.nome }}
          </ng-option>
       </ng-select>
    </div>
    <div class="col-sm-5" [hidden]="!incluiColuna('responsavel')">
      <div class="form-group">
        <label for="responsavel" class="form-label">Selecionar Responsável</label>
        <ng-select placeholder="Selecionar responsável"  [ngModelOptions]="{ standalone: true }" [ngModelOptions]="{ standalone: true }" [(ngModel)]="responsavelSelecionado">
          <ng-option  *ngFor="let item of responsaveis" [value]="item">
            {{ item?.nome }}
          </ng-option>
       </ng-select>
      </div>
    </div>
    <div class="col-sm-5" [hidden]="!incluiColuna('subtipo')">
      <div class="form-group">
        <label for="subtipo-input" class="form-label">Subtipo</label>
        <ng-select placeholder="Selecionar subtipo" [ngModelOptions]="{ standalone: true }" [ngModelOptions]="{ standalone: true }" [(ngModel)]="subtipoSelecionado">
          <ng-option  *ngFor="let item of subtipoList" [value]="item">
            {{ item?.subTipoAuditoria?.nome}}
          </ng-option>
       </ng-select>
      </div>
    </div>
    <div class="col-sm-2">
      <label class="form-label">ㅤ</label>
      <button  type="button" class="btn btn-success btn-sm form-control form-control-sm" (click)="adicionarUg()">
        Adicionar
      </button>
    </div>
  </div>

  <div class="row mt-2" [hidden]="dataSourceUG.data.length === 0">
    <div class="col-sm-12">
      <div class="mat-elevation-z1">
        <table mat-table [dataSource]="dataSourceUG" matSortDisableClear #tabela1="matSort" matSort
          matSortDirection="asc">

          <ng-container matColumnDef="ug">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Unidade(s) Gestora(s)
            </th>
            <td mat-cell *matCellDef="let element" class="text limit-text">
              {{ element?.unidadeGestora?.sigla }} -
              {{ element?.unidadeGestora?.nome }}
            </td>
          </ng-container>

          <ng-container matColumnDef="responsavel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Responsável
            </th>
            <td mat-cell *matCellDef="let element" class="text limit-text">
              {{ element?.responsavel?.nome }}
            </td>
          </ng-container>

          <ng-container matColumnDef="subtipo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Subtipo
            </th>
            <td mat-cell *matCellDef="let element" class="text limit-text">
              {{ element?.subtipoAuditoria.subTipoAuditoria?.nome}}
            </td>
          </ng-container>

          <ng-container cdkColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              Ação
            </th>
            <td mat-cell *matCellDef="let element; let i = index" class="block">
              <div class="
										d-flex
										w-100
										justify-content-center
										align-items-center
									">
                <button type="button" class="btn btn-danger btn-xs text-left" (click)="excluirUg(i)">
                  <i class="far fa-trash-alt"></i>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-light"></tr>

          <tr mat-row *matRowDef="
								let row;
								columns: displayedColumns
							"></tr>
        </table>
        <h5 class="table-no-data" [hidden]="dataSourceUG?.data?.length > 0">
          Nenhum registro encontrado
        </h5>
        <mat-paginator #TableOnePaginator="matPaginator" [pageSizeOptions]="[5]" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </div>

</div>
<div class="card-footer">
  <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="dataSourceUG?.data?.length === 0"
    (click)="salvar()">
    <i class="far fa-save"></i> Salvar
  </button>
  <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
    <i class="fas fa-times"></i> Cancelar
  </button>
</div>
