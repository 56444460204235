<div class="card-header bg-light">
  <h4 class="card-title mb-0">
    <i class="far fa-file"></i>
   {{titulo}} Item
  </h4>
</div>
<div class="p-2 wrapper">
  <form [formGroup]="form" #f="ngForm" >
    <div class="col-md-12 mb-2">
      <label class="form-label">Nº do Item</label>
      <input type="text" class="form-control form-control-sm" [(ngModel)]="nrItem" [ngModelOptions]="{standalone: true}" readonly>
    </div>
    <div class="col-md-12 mb-2">
      <label class="form-label">Título</label>
    <input type="text" class="form-control form-control-sm" formControlName="titulo"  name="titulo"
        [ngClass]="{ 'is-invalid': submitted && form.controls['titulo'].invalid }" maxlength="100">
      <div *ngIf="submitted && form.controls['titulo'].invalid" class="invalid-feedback">
        <div *ngIf="form.controls['titulo'].errors.required">Campo obrigatório</div>
      </div>
    </div>
    <div class="col-md-12 mb-2">
      <label class="form-label" for="descricao">Descrição</label>
      <angular-editor *ngIf="!visualizar; else visualizarDescricao" #myDiv [config]="config" id="descricao" rows="40" formControlName="descricao"
        [ngClass]="{ 'is-invalid': submitted && form.controls['descricao'].invalid }"></angular-editor>
        <ng-template #visualizarDescricao>
          <textarea formControlName="descricao" class="form-control" cols="30" rows="8" disabled></textarea>
        </ng-template>
      <div *ngIf="submitted && form.controls['descricao'].invalid" class="invalid-feedback">
        <div *ngIf="form.controls['descricao'].errors.required">Campo obrigatório</div>
      </div>
    </div>
  </form>
  <div class="col-md-12 mt-2">
    <div class="mat-elevation-z4">
      <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear>
        <tr mat-header-row *matHeaderRowDef="['arquivo', 'acoes']" class="bg-light"></tr>
        <ng-container matColumnDef="arquivo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Arquivos</th>
          <td mat-cell *matCellDef="let element">
            <small (click)="Download(element)" class="download">{{ element?.nomeArquivo }}</small>
          </td>
        </ng-container>
        <ng-container matColumnDef="acoes">
          <th mat-header-cell *matHeaderCellDef>
            <button type="button" class="btn btn-success btn-xs" (click)="modalIncluirArquivo()" *ngIf="!visualizar">
              Adicionar
            </button>
          </th>
          <div class="w-100 d-flex justify-content-center">
            <td mat-cell *matCellDef="let element; let i = index" class="block">
              <div class="w-100 d-flex justify-content-center">
                <button type="button" class="btn btn-danger btn-xs text-left" (click)="excluirArquivo(element?.id)" *ngIf="!visualizar">
                  <i class="far fa-trash-alt"></i>
                </button>
              </div>
            </td>
          </div>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: ['arquivo', 'acoes']"></tr>
      </table>
      <h5 [hidden]="dataSource?.data?.length > 0" class="table-no-data">
        Nenhum registro encontrado
      </h5>
      <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
<div class="card-footer">
  <button type="submit" class="btn btn-primary btn-sm mr-1" (click)="salvar(false, true)" *ngIf="!visualizar">
    <i class="far fa-save"></i> Salvar
  </button>
  <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
    <i class="fas fa-times"></i> Fechar
  </button>
</div>
