<p [hidden]="showButtonFilter">
	<button
		class="btn btn-default"
		type="button"
		data-toggle="collapse"
		data-target="#collapseExample"
		aria-expanded="false"
		aria-controls="collapseExample"
	>
		<i class="fas fa-filter"></i> Filtrar
	</button>
</p>
<div class="collapse" id="collapseExample">
	<div class="content">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">
					<div class="card card-outline card-lightblue">
						<div class="card-body p-2">

							<div class="row">
								<div class="form-group col-md-3">
									<label for="numeroOsa" class="mb-1"
										>Número da OSA</label
									>
									<input
										type="text"
										id="numeroOsa"
										[(ngModel)]="filtroModel.numeroOsa"
										class="form-control form-control-sm"
									/>
								</div>

								<div class="form-group col-md-3">
									<label for="numeroOpa" class="mb-1"
										>Número da OPA</label
									>
									<input
										type="text"
										id="numeroOpa"
										[(ngModel)]="filtroModel.numeroOpa"
										class="form-control form-control-sm"
									/>
								</div>

								<div class="col-md-3 form-group">
									<label for="objeto" class="mb-1"
										>Objeto</label
									>
									<input
										type="text"
										id="objeto"
										[(ngModel)]="filtroModel.objeto"
										class="form-control form-control-sm"
									/>
								</div>

								<div class="col-md-3 form-group">
									<label for="selectSituacao" class="mb-1"
										>Situação</label
									>
									<ng-select
										[(ngModel)]="situacaoSelecionada"
									>
										<ng-option
											*ngFor="let situacao of situacoes"
											[value]="situacao.codigo"
											>{{ situacao.descricao }}</ng-option
										>
									</ng-select>
								</div>

								<!-- 							<div class="col-md-3 form-group">
									<label for="coordenacao" class="mb-1"
										>Coordenação</label
									>
									<ng-select [(ngModel)]="">
										<ng-option
											*ngFor="let element of coordenacoes"
											[value]="element.id"
											>{{ element.sigla }}</ng-option
										>
									</ng-select>
								</div> -->
							</div>

							<div class="row">
								<div class="form-group col-md-3">
									<label for="dataInicio" class="mb-1"
										>Data Início</label
									>
									<input
										[(ngModel)]="
											filtroModel.dataInicioRealizacao
										"
										type="date"
										id="dataInicio"
										class="form-control form-control-sm"
									/>
								</div>

								<div class="form-group col-md-3">
									<label for="dataFim" class="mb-1"
										>Data Fim</label
									>
									<input
										[(ngModel)]="
											filtroModel.dataFimRealizacao
										"
										type="date"
										id="dataFim"
										class="form-control form-control-sm"
									/>
								</div>

								<div class="form-group col-md-3">
									<label for="dataInicioPrevisto" class="mb-1"
										>Data Início Prevista</label
									>
									<input
										[(ngModel)]="filtroModel.dataInicioPrevisto"
										type="date"
										id="dataInicioPrevisto"
										class="form-control form-control-sm"
									/>
								</div>

								<div class="form-group col-md-3">
									<label for="dataFimPrevisto" class="mb-1"
										>Data Fim Prevista</label
									>
									<input
										[(ngModel)]="
											filtroModel.dataFimPrevisto
										"
										type="date"
										id="dataFimPrevisto"
										class="form-control form-control-sm"
									/>
								</div>

								
							</div>

							<div class="row">
								<div class="col-md-3 form-group">
									<label
										for="selectSubtipoAuditoria"
										class="mb-1"
										>Subtipo de Auditoria</label
									>
									<ng-select
										[(ngModel)]="
											filtroModel.subtipoAuditoriaId
										"
									>
										<ng-option
											*ngFor="
												let element of subtiposAuditoria
											"
											[value]="element.id"
											>{{ element.nome }}</ng-option
										>
									</ng-select>
								</div>

								<div class="col-md-3 form-group">
									<label
										for="selectUnidadeGestora"
										class="mb-1"
										>Unidade Gestora a ser Auditada</label
									>
									<ng-select
										[(ngModel)]="
											filtroModel.unidadeGestoraId
										"
									>
										<ng-option
											*ngFor="
												let element of unidadesGestoras
											"
											[value]="element.id"
											>{{ element.sigla }}</ng-option
										>
									</ng-select>
								</div>

								<div class="col-md-3 form-group">
									<label
										for="selectTipoAuditoria"
										class="mb-1"
										>Tipo de Auditoria</label
									>
									<ng-select
										[(ngModel)]="
											filtroModel.tipoAuditoriaId
										"
									>
										<ng-option
											*ngFor="
												let element of tiposAuditoria
											"
											[value]="element.id"
											>{{ element.nome }}</ng-option
										>
									</ng-select>
								</div>

								<div class="col-md-3 form-group">
									<label for="selectCoordenador" class="mb-1"
										>Coordenador da Auditoria</label
									>
									<ng-select
										[(ngModel)]="filtroModel.coordenador"
									>
										<ng-option
											*ngFor="
												let auditor of coordenadores
											"
											[value]="auditor.id"
											>{{
												auditor.nomeAuditor
											}}</ng-option
										>
									</ng-select>
								</div>
							</div>

							<div class="row">
								<div class="col-md-3 form-group">
									<label for="status" class="mb-1"
										>Status</label
									>
									<ng-select [(ngModel)]="filtroModel.status">
										<ng-option
											*ngFor="let status of status"
											[value]="status.codigo"
											>{{ status.descricao }}</ng-option
										>
									</ng-select>
								</div>
								<div class="col-md-3 form-group">
									<label for="selectOrigem" class="mb-1"
										>Origem</label
									>
									<ng-select
										[(ngModel)]="
											filtroModel.origemAuditoria
										"
									>
										<ng-option
											*ngFor="let element of origem"
											[value]="element.codigo"
											>{{ element.descricao }}</ng-option
										>
									</ng-select>
								</div>
							</div>

							<mat-toolbar>
								<mat-toolbar-row
									style="
										padding: 0px;
										justify-content: flex-end;
									"
								>
									<button
										type="button"
										class="btn btn-success btn-sm mr-3"
										(click)="buscarComFiltro()"
									>
										<i class="fas fa-search"></i> Pesquisar
									</button>
								</mat-toolbar-row>
							</mat-toolbar>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
