import { IEnumModel } from 'src/app/models/enum.model';

import { ModalVisualizarRespostaComponent } from './modal-visualizar-resposta/modal-visualizar-resposta.component';
import { IncluirSolicitacaoComponent } from './modal-incluir-solicitacao/modal-incluir-solicitacao.component';
import { ModalJustificativaComponent } from './../modal-justificativa/modal-justificativa.component';
import { SolicitacaoTecnicaService } from "./../../../services/solicitacao-tecnica.service";
import { ISolicitacaoTecnicaModel } from "./../../../models/solicitacao-tecnica.model";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, Router } from '@angular/router';
@Component({
	selector: "app-aba-solicitacao-tecnica",
	templateUrl: "./aba-solicitacao-tecnica.component.html",
	styleUrls: ["./aba-solicitacao-tecnica.component.scss"],
})
export class AbaSolicitacaoTecnicaComponent implements OnInit {
	public displayedColumns: string[] = [
		"selecao",
		"numero",
		"assunto",
		"prazo",
		"responsavel",
		"status",
		"resposta",
	];
	public dataSource = new MatTableDataSource<ISolicitacaoTecnicaModel>([]);
	public semDados = true;
	public selectedModel: ISolicitacaoTecnicaModel;
	public permitido: boolean = false;
	public cancelada: boolean;
	public dataVencida: boolean;
	public tipotrabalhoId: number;
	@Input() numeroOpa: string;
	public tipoTrabalho: IEnumModel = {} as IEnumModel;
	@Input() visualizar: boolean;
	@Input() planejamentoId: number;

	constructor(
		private solicitacaoTecnica: SolicitacaoTecnicaService,
		private matDialog: MatDialog,
		public router: Router,
		public route: ActivatedRoute) {
		this.tipotrabalhoId = +this.route.snapshot.paramMap.get("id");

		if (this.router.url.includes("planejamento")) {
			this.tipoTrabalho.nome = 'planejamento';
			this.tipoTrabalho.codigo = 7;
		}
		if (this.router.url.includes("osa")) {
			this.tipoTrabalho.nome = 'osa';
			this.tipoTrabalho.codigo = 4;
		}
	}

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;



	ngOnInit(): void {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.obter()
	}

	public obter() {
		this.solicitacaoTecnica
			.obter(this.tipotrabalhoId, this.tipoTrabalho?.nome)
			.then((res) => {
				if (res.dados) {
					this.dataSource = new MatTableDataSource<ISolicitacaoTecnicaModel>(res.dados);
					this.semDados = this.dataSource.filteredData.length === 0;
					this.dataSource.paginator = this.paginator;
					this.dataSource.sort = this.sort;
					this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
					this.selectedModel = null
				}
			})
			.catch((err) => {
				Swal.fire({
					toast: true,
					position: "top-end",
					icon: "error",
					text: err.mensagem.descricao,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
			});
	}


	public selecionar(item: any) {
		this.selectedModel =
			!this.selectedModel || this.selectedModel.id !== item.id
				? item
				: null;
		if (this.selectedModel?.status?.codigo === 4 || this.selectedModel?.status?.codigo !== 3) {
			this.cancelada = true
		} else {
			this.cancelada = false
		}

		if (this.selectedModel?.status?.codigo === 1 || this.selectedModel?.status?.codigo === 2) {
			this.permitido = true;
		} else {
			this.permitido = false;
		}

		var dataAtual = new Date()

		if (new Date(this.selectedModel?.prazo) < dataAtual) {
			this.dataVencida = true
		}
	}

	public habilitarResposta(id: number, prazo: Date) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = 'modal-component';
		dialogConfig.width = '45vw';
		dialogConfig.maxHeight = '85vh';
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = false;
		dialogConfig.data = {
		}
		const modal = this.matDialog.open(ModalJustificativaComponent, dialogConfig);
		modal.afterClosed().subscribe(data => {
			if (data) {
				this.solicitacaoTecnica.habilitarRespostaSolicitacao(id, data, prazo)
					.then((res) => {
						if (res.sucesso) {
							Swal.fire({
								toast: true,
								position: "top-end",
								icon: "success",
								text: res.mensagem.descricao,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							});
						} else {
							Swal.fire({
								toast: true,
								position: "top-end",
								icon: "warning",
								text: res.mensagem.descricao,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							});
						}
					}).catch((err) => {
						Swal.fire({
							toast: true,
							position: "top-end",
							icon: "error",
							text: err.mensagem.descricao,
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
					}).finally(() => {
						this.obter()
					});
			}
		});
	}

	public imprimir(element: ISolicitacaoTecnicaModel) {
		this.router.navigateByUrl(`${this.tipoTrabalho}/${this.tipotrabalhoId}/solicitacao-tecnica/${element?.id}/imprimir`)
	}


	public sortingDataAccessor = (item, property) => {
		switch (property) {
			case "prazo":
				return item?.prazo;
			case "status":
				return item?.status?.descricao;
			default:
				return item[property];
		}
	};

	public incluirSolicitacaoTecnica(titulo: string, visualizar?: boolean, selectedModel?: ISolicitacaoTecnicaModel,): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = 'modal-component-solicitacao-tecnica';
		dialogConfig.width = '80%';
		dialogConfig.height = '90%';
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		dialogConfig.data = {
			titulo,
			element: selectedModel,
			id: selectedModel?.id,
			status: selectedModel?.status,
			visualizar,
			tipoTrabalhoId: this.tipotrabalhoId,
			planejamentoId: this.planejamentoId,
			numeroOpa: this.numeroOpa,
			tipoTrabalho: this.tipoTrabalho,
			numeroST: selectedModel?.numero
		};

		const modal = this.matDialog.open(
			IncluirSolicitacaoComponent,
			dialogConfig
		);

		modal.afterClosed().subscribe((data) => {
			if (data) {
			}
			this.obter()
		});
	}

	public modalVisualizarResposta(id: number): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = 'modal-component-resposta';
		dialogConfig.width = '60%';
		dialogConfig.height = 'auto';
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = false;
		dialogConfig.data = {
			titulo: 'Visualizar Resposta',
			id: id,
		};

		const modal = this.matDialog.open(
			ModalVisualizarRespostaComponent,
			dialogConfig
		);

		modal.afterClosed().subscribe((data) => {
			if (data) {
			}
		});
	}

	public cancelarSolicitacao(id: number) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = 'modal-component';
		dialogConfig.width = '45vw';
		dialogConfig.maxHeight = '85vh';
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = false;
		dialogConfig.data = {
		}
		const modal = this.matDialog.open(ModalJustificativaComponent, dialogConfig);
		modal.afterClosed().subscribe(data => {
			if (data) {
				this.solicitacaoTecnica.cancelarSolicitacao(id, data)
					.then((res) => {
						if (res.sucesso) {
							Swal.fire({
								toast: true,
								position: "top-end",
								icon: "success",
								text: res.mensagem.descricao,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							});
						} else {
							Swal.fire({
								toast: true,
								position: "top-end",
								icon: "warning",
								text: res.mensagem.descricao,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							});
						}
					}).catch((err) => {
						Swal.fire({
							toast: true,
							position: "top-end",
							icon: "error",
							text: err.mensagem.descricao,
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
					}).finally(() => {
						this.obter()
					});
			}
		});

	}

	public excluir(id: number) {
		Swal.fire({
			title: "Excluir Registro",
			text: "Tem certeza que deseja excluir esse Registro?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then((res) => {
			if (res.value) {
				this.solicitacaoTecnica
					.excluir(id)
					.then((res) => {
						if (res.sucesso) {
							Swal.fire({
								toast: true,
								position: "top-end",
								icon: "success",
								text: res.mensagem.descricao,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							});
						} else {
							Swal.fire({
								toast: true,
								position: "top-end",
								icon: "warning",
								text: res.mensagem.descricao,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							});
						}
					})
					.catch((err) => {
						Swal.fire({
							toast: true,
							position: "top-end",
							text: err.mensagem.descricao,
							icon: "error",
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
					}).finally(() => {
						this.obter()
					}
					);
			}
		});
	}
}
