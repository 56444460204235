<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">{{titulo}}</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <mat-toolbar class="table-header">
        <mat-toolbar-row class="px-1">
          <div class="input-group input-group-sm" style="width: 100%;">
            <input type="text" name="table_search" class="form-control float-right" placeholder="Filtrar por..."
              (keyup)="filtrar($event)" />
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="nome" matSortDirection="asc"
          matSortDisableClear [hidden]="semDados">

          <ng-container matColumnDef="selecao">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (click)="$event.stopPropagation()" color="primary" (change)="selecionarTodos()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                (change)="element.selecionado = $event.checked" [checked]="element.selecionado">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
            <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="row.selecionado = !row.selecionado" class="clickable"
            [ngClass]="{'bg-light': row.selecionado}"></tr>

        </table>
        <h5 [hidden]="!semDados" class="table-no-data">Sem dados a exibir</h5>
      </div>
      <mat-paginator [hidden]="semDados" [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()" [disabled]="!verificarItensSelecionados()">
        <i class="fas fa-check"></i> {{ botaoOk }}
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
</div>
