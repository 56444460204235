<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i> Página Inicial</a
            >
          </li>
          <li class="breadcrumb-item active">
            Responder Questionário
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div [class]="model.status == 'Enviado' ? '' : 'minuta'">
  <div class="p-4 content">
    <div class="card card-outline">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i> Responder Questionário
            </h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group col-md-4">
                <label for="pea" class="mb-1">Nome da Funcionalidade </label>
                <input
                  type="text"
                  disabled
                  id="pea"
                  class="form-control form-control-sm"
                  [ngModel]="model.nomeFuncionalidade"
                />
              </div>

              <div class="form-group col-md-2">
                <label for="siglaUg" class="mb-1">Sigla UG</label>
                <input
                  type="text"
                  disabled
                  id="siglaUg"
                  class="form-control form-control-sm"
                  [ngModel]="model.siglaUG"
                />
              </div>

              <div class="form-group col-md-2">
                <label for="responsavel" class="mb-1">Responsável</label>
                <input
                  type="text"
                  disabled
                  id="responsavel"
                  class="form-control form-control-sm"
                  [ngModel]="model.responsavel"
                />
              </div>

              <div class="form-group col-md-2">
                <label for="dataEnvio" class="mb-1">Data Envio</label>
                <input
                  type="text"
                  disabled
                  id="dataEnvio"
                  class="form-control form-control-sm"
                  [ngModel]="model.dataEnvio"
                />
              </div>

              <div class="form-group col-md-2">
                <label for="dataPrazo" class="mb-1">Data Prazo</label>
                <input
                  type="text"
                  disabled
                  id="dataPrazo"
                  class="form-control form-control-sm"
                  [ngModel]="model.dataPrazo"
                />
              </div>
            </div>
            <div class="row">
              <button
                type="button"
                *ngIf="exibeCamposStatusEmReplica"
                class="btn btn-danger btn-sm mr-1"
                [hidden]="visualizar || imprimir"
                (click)="onContestar()">
                <i class="fas fa-check"></i> Contestar
              </button>
              <button
                type="button"
                *ngIf="exibeCamposStatusEmReplica"
                class="btn btn-primary btn-sm mr-1"
                [hidden]="visualizar || imprimir"
                (click)="onConcordar()">
                <i class="fas fa-pencil-alt"></i> Concordar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container>
      <form [formGroup]="formGroup">
        <div formArrayName="respostas">
          <table class="mat-table mat-elevation-z8" style="margin: 5px;">
            <thead class="mat-header-row">
            <th class="mat-header-cell" style="padding: 10px;">
              <mat-checkbox (change)="toggleAll($event)" [checked]="selection.hasValue() && isAllSelected()"
                            [disabled]="visualizar || imprimir"></mat-checkbox>
            </th>
            <th *ngFor="let coluna of displayedColumns; index as index"
                class="mat-header-cell">{{ displayedColumnsName[index] }}
            </th>
            </thead>
            <tbody *ngFor="let element of dataSource2; index as index">
            <tr class="mat-row" [class]="index % 2 === 0  ? '' : 'background-cinza'"
                style="margin-top: 10px; border-top-color: #000; border-top-width: thin; border-top-style: solid;">
              <td class="mat-cell" style="padding-left: 10px; padding-top: 10px;">
                <mat-checkbox *ngIf="element.idTipoSecaoOuPergunta == 2"
                              [checked]="selection.isSelected(element)"
                              [formControl]="getCheckBoxFormControl(index)"
                              (change)="onCheckboxChange($event, element)"
                              [disabled]="visualizar || imprimir"></mat-checkbox>
              </td>
              <td *ngFor="let coluna of displayedColumns" class="mat-cell"
                  style="padding-top: 5px; white-space: 'pre-line'; word-wrap: break-word; " [innerHTML]="element[coluna]"></td>
            </tr>
            <tr class="mat-row" [class]="index % 2 === 0  ? '' : 'background-cinza'">
              <td class="mat-cell mb-3" colspan="5" style="padding-left: 10px;">
                <ng-container [ngSwitch]="element.tipoEntrada">
                  <div *ngSwitchCase="'Somente texto'" class="form-groupt-3 mb-3">
                    <label for="txtResposta{{index}}">Resposta</label>
                    <angular-editor id="txtResposta{{index}}" [config]="config"
                                    [formControl]="getRespostaFormControl(index)" 
                                    [disabled]= "contestado"
                                    rows="10"></angular-editor>
                  </div>
                  <mat-form-field *ngSwitchCase="'Numérico Inteiro'" appearance="outline"
                                  class="example-form-field mt-3 mb-3">
                    <label>Resposta</label>
                    <input matInput type="number" [formControl]="getRespostaFormControl(index)"
                           [disabled]="isDisabledStatusEmReplica || contestado" />
                  </mat-form-field>
                  <mat-form-field *ngSwitchCase="'Numérico Decimal'" appearance="outline"
                                  class="example-form-field mt-3 mb-3">
                    <label>Resposta</label>
                    <input matInput type="number" [formControl]="getRespostaFormControl(index)"
                           [disabled]="isDisabledStatusEmReplica || contestado" />
                  </mat-form-field>
                  <div *ngSwitchCase="'Texto com anexo opcional'" class="example-form-field mt-3 mb-3">
                    <label for="txtResposta{{index}}">Resposta</label>
                    <angular-editor id="txtResposta{{index}}" [config]="config"
                                    [formControl]="getRespostaFormControl(index)"
                                   
                                    rows="10" [ngClass]="{ 'editor-disabled': !config.editable }"></angular-editor>
                    <br />
                    <input type="file" (change)="onFileSelected($event, index)"
                           class="form-control-file selecionar-arquivo mr-2"
                           [disabled]="visualizar || imprimir || contestado" />
                    <div *ngIf="element.arquivo" class="file-attachment">
                      <a [href]="element.urlArquivo" download="{{element.arquivo?.nomeArquivo}}"
                         class="link-download">
                        {{ element.arquivo?.nomeArquivo }}
                      </a>
                    </div>
                  </div>
                  <div *ngSwitchCase="'Texto com anexo obrigatório'" class="example-form-field mt-3 mb-3">
                    <label for="txtResposta{{index}}">Resposta</label>
                    <angular-editor id="txtResposta{{index}}" [config]="config"
                                    [formControl]="getRespostaFormControl(index)" rows="10"></angular-editor>
                    <br />
                    <input type="file" (change)="onFileSelected($event, index)"
                           class="form-control-file selecionar-arquivo mr-2"
                           [disabled]="visualizar || imprimir || contestado" />
                    <div *ngIf="element.arquivo" class="file-attachment">
                      <a [href]="element.urlArquivo" download="{{element.arquivo?.nomeArquivo}}"
                         class="link-download">
                        {{ element.arquivo?.nomeArquivo }}
                      </a>
                    </div>
                  </div>
                  <mat-form-field *ngSwitchCase="'Múltipla Escolha'" appearance="outline"
                                  class="example-form-field mt-3 mb-3">
                    <label>Resposta</label>
                    <mat-select [formControl]="getRespostaFormControl(index)">
                      <mat-option *ngFor="let item of element.perguntaOpcao" [value]="item.nome"
                                  [disabled]="isDisabledStatusEmReplica || contestado">
                        {{ item.nome }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="card-footer">
            <div class="btn-group" [hidden]="visualizar || imprimir">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                (click)="onSalvar(false)">
                <i class="far fa-save"></i> Salvar
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split"
                data-toggle="dropdown"
                aria-expanded="false">
              </button>
              <div class="dropdown-menu" role="menu">
                <a class="dropdown-item" (click)="onSalvar(false)">Salvar</a>
                <a class="dropdown-item" (click)="onSalvar(true)">Salvar e fechar</a>
              </div>
            </div>

            <button
              type="button"
              *ngIf="!visualizar && !imprimir"
              class="btn btn-warning btn-sm mr-1"
              (click)="onEnviar()"
              [disabled]="!isAllFinalized()">
              <i class="fas fa-paper-plane"></i> Enviar
            </button>

            <button
              type="button"
              *ngIf="!visualizar && !imprimir"
              class="btn btn-warning btn-sm mr-1"
              (click)="onFinalizar()"
              [disabled]="!isHabilitaBotaoFinalizar()">
              <i class="fas fa-list"></i> Finalizar
            </button>

            <button
              type="button"
              *ngIf="!visualizar"
              class="btn btn-default btn-sm"
              (click)="onCancelar()">
              <i class="fas fa-times"></i> Fechar
            </button>

            <button
              type="button"
              *ngIf="!visualizar && !imprimir"
              class="btn btn-danger btn-sm"
              (click)="modalDelegar()"
              [disabled]="DesabilitaBotaoDelegar()">
              <i class="fas fa-share-square"></i> Delegar
            </button>

            <button
              type="button"
              *ngIf="visualizar"
              class="btn btn-default btn-sm"
              (click)="onCancelar()">
              <i class="fas fa-times"></i> Sair
            </button>


            <button
              type="button"
              *ngIf="imprimir"
              class="btn btn-primary btn-sm mr-1"
              (click)="print()"
            >
              <i class="fas fa-print"></i> Imprimirr
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
