<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i> Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item">
						<a [routerLink]="['/solicitacao-tecnica']">
							Solicitação Técnica</a
						>
					</li>
					<li class="breadcrumb-item active">{{ tituloPagina }}</li>
				</ol>
			</div>
		</div>
	</div>
</div>

<div class="content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card card-outline card-lightblue">
					<div class="card-header">
						<h3 class="card-title mb-0">
							<i
								*ngIf="this.id"
								class="fas fa-pencil-alt pr-2"
							></i>
							<i *ngIf="!this.id" class="far fa-file pr-2"></i>
							{{ tituloPagina }}
						</h3>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="form-group col-md-3">
								<label for="numSolicitacao" class="mb-1"
									>Nº Doc. Associado</label
								>
								<input
									disabled
									type="text"
									id="numSolicitacao"
									placeholder="-"
									[ngModel]="model?.documentoAssociado"
									class="form-control form-control-sm"
								/>
							</div>
							<div class="form-group col-md-3">
								<label for="numSolicitacao" class="mb-1"
									>Nº da Solicitação</label
								>
								<input
									disabled
									type="text"
									id="numSolicitacao"
									placeholder="-"
									[ngModel]="model?.numero"
									class="form-control form-control-sm"
								/>
							</div>
							<div class="form-group col-md-3">
								<label for="numSolicitacao" class="mb-1"
									>Status</label
								>
								<input
									disabled
									type="text"
									placeholder="-"
									[ngModel]="model?.status?.descricao"
									class="form-control form-control-sm"
								/>
							</div>
							<div class="form-group col-md-3">
								<label for="prazo" class="mb-1"
									>Prazo de Resposta</label
								>
								<input
									disabled
									type="text"
									id="prazo"
									[ngModel]="
										model?.prazo | date: 'dd/MM/yyyy'
									"
									class="form-control form-control-sm"
								/>
							</div>
						</div>
						<div class="row mt-2">
							<div class="col-sm-12">
								<div class="mat-elevation-z1">
									<table
										mat-table
										[dataSource]="dataSourceUG"
										matSortDisableClear
										#tabela1="matSort"
										matSort
										matSortDirection="asc"
									>
										<tr
											mat-header-row
											*matHeaderRowDef="[
												'ug',
												'responsavel'
											]"
											class="bg-light"
										></tr>

										<ng-container matColumnDef="ug">
											<th
												mat-header-cell
												*matHeaderCellDef
												mat-sort-header
											>
												Unidade(s) Gestora(s)
											</th>
											<td
												mat-cell
												*matCellDef="let element"
												class="text"
											>
												{{ element?.siglaUg }} -
												{{ element?.nomeUg }}
											</td>
										</ng-container>

										<ng-container
											matColumnDef="responsavel"
										>
											<th
												mat-header-cell
												*matHeaderCellDef
												mat-sort-header
											>
												Responsável
											</th>
											<td
												mat-cell
												*matCellDef="let element"
												class="text"
											>
												{{ element?.nomeAuditor }}
											</td>
										</ng-container>
										<tr
											mat-row
											*matRowDef="
												let row;
												columns: ['ug', 'responsavel']
											"
										></tr>
									</table>
									<h5
										class="table-no-data"
										[hidden]="
											dataSourceUG?.data?.length > 0
										"
									>
										Nenhum registro encontrado
									</h5>
									<mat-paginator
										#TableOnePaginator="matPaginator"
										[pageSizeOptions]="[5]"
										showFirstLastButtons
									>
									</mat-paginator>
								</div>
							</div>
						</div>

						<div class="row mt-2">
							<div class="form-group col-md-12">
								<label for="assunto" class="mb-1"
									>Assunto da Solicitação</label
								>
								<textarea
									rows="3"
									disabled
									type="text"
									id="assunto"
									[ngModel]="model?.assunto"
									class="form-control form-control-sm"
								></textarea>
							</div>
						</div>

						<label class="mt-4"> Itens da Solicitação </label>

						<div class="mat-elevation-z4 mb-5">
							<table
								[hidden]="semDadosItens"
								class="mat-elevation-z2"
								mat-table
								[dataSource]="itensSolicitacaoDataSource"
								matSort
								#tabela2="matSort"
								matSortDirection="asc"
							>
								<ng-container matColumnDef="numero">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										Nº do Item
									</th>
									<td mat-cell *matCellDef="let element">
										<span *ngIf="!element?.numero">-</span>
										<span *ngIf="element?.numero">{{
											element.numero
										}}</span>
									</td>
								</ng-container>

								<ng-container matColumnDef="titulo">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										Titulo
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element?.titulo }}
									</td>
								</ng-container>

								<ng-container matColumnDef="descricao">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										Descrição
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element?.descricao }}
									</td>
								</ng-container>

								<ng-container
									matColumnDef="responsavelDelegado"
								>
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										Responsável Delegado
									</th>
									<td mat-cell *matCellDef="let element">
										<span *ngIf="element?.nomeAuditor">{{
											element?.nomeAuditor
										}}</span>
										<span *ngIf="!element?.nomeAuditor"
											>-</span
										>
									</td>
								</ng-container>

								<ng-container matColumnDef="status">
									<th
										mat-header-cell
										*matHeaderCellDef
										mat-sort-header
									>
										Status
									</th>
									<td mat-cell *matCellDef="let element">
										<span *ngIf="!element?.status">-</span>
										<span
											*ngIf="
												element?.status?.codigo === 1
											"
											class="badge bg-success text-xs"
											>{{
												element?.status?.descricao
											}}</span
										>
										<span
											*ngIf="
												element?.status?.codigo === 2
											"
											class="badge bg-secondary text-xs"
											>{{
												element?.status?.descricao
											}}</span
										>
										<span
											*ngIf="
												element?.status?.codigo === 3
											"
											class="badge bg-danger text-xs"
											>{{
												element?.status?.descricao
											}}</span
										>
										<span
											*ngIf="
												element?.status?.codigo === 4
											"
											class="badge bg-success text-xs"
											>
												Enviado
											</span
										>
										<span
											*ngIf="
												element?.status?.codigo === 5
											"
											class="badge bg-warning text-xs"
											>{{
												element?.status?.descricao
											}}</span
										>
										<span
											*ngIf="
												element?.status?.codigo === 6
											"
											class="badge bg-primary text-xs"
											>{{
												element?.status?.descricao
											}}</span
										>
									</td>
								</ng-container>

								<ng-container matColumnDef="actions">
									<th mat-header-cell *matHeaderCellDef>
										Ação
									</th>
									<th mat-header-cell *matHeaderCellDef></th>
									<td
										mat-cell
										*matCellDef="let element; let i = index"
									>
										<button
											*ngIf="
												!visualizar &&
												(element?.status?.codigo ===
													1 ||
													element?.status?.codigo ===
														4 ||
													element?.status?.codigo ===
														5)
											"
											type="button"
											(click)="responderItem(element.id)"
											class="btn btn-primary btn-xs mr-1"
										>
											<i class="far fas fa-pen"></i>
										</button>
										<button
											type="button"
											(click)="visualizarItem(element.id)"
											class="btn btn-info btn-xs mr-1"
										>
											<i class="far fas fa-eye"></i>
										</button>
									</td>
								</ng-container>

								<tr
									mat-header-row
									*matHeaderRowDef="displayedColumnsItems"
								></tr>
								<tr
									mat-row
									*matRowDef="
										let row;
										columns: displayedColumnsItems
									"
								></tr>
							</table>
							<h5 [hidden]="!semDadosItens" class="table-no-data">
								Nenhum registro encontrado.
							</h5>
							<mat-paginator
								#TableTwoPaginator="matPaginator"
								[pageSizeOptions]="[5, 10, 20]"
								showFirstLastButtons
							></mat-paginator>
						</div>
					</div>
					<div class="card-footer">
						<div
							class="
								d-flex
								w-100
								justify-content-between
								align-items-center
							"
						>
							<div class="list2">
								<button
									*ngIf="
										!visualizar &&
										model?.status?.codigo === 3
									"
									type="button"
									class="btn btn-warning btn-sm"
									(click)="prorrogarPrazo()"
								>
									<i class="fas fa-hourglass"></i>
									Prorrogar Prazo
								</button>
							</div>
							<div class="list1">
								<button
									*ngIf="!visualizar"
									type="button"
									class="btn btn-default btn-sm"
									(click)="onBack()"
								>
									<i class="fas fa-times"></i> Cancelar
								</button>

								<button
									*ngIf="visualizar"
									type="button"
									class="btn btn-default btn-sm"
									(click)="onBack()"
								>
									<i class="fas fa-times"></i> Sair
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
