<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
      cdkDragHandle>
      <h4 class="card-title mb-0">Edição custo e recurso</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-container p-3">
        <div class="row ">
          <div class="form-group col-md-12">
            <label for="nome" class="mb-1">Custos e Recursos</label>
            <input type="text" id="nome" class="form-control form-control-sm"
              [(ngModel)]="modelCustoRecursoPlanejamento.nome" maxlength="100"/>
          </div>
        </div>

        <div class="row ">
          <div class="form-group col-md-12">
            <label for="valorUnitario" class="mb-1">Valor</label>
            <input type="text" id="valorUnitario" class="form-control form-control-sm"
              [(ngModel)]="modelCustoRecursoPlanejamento.valorUnitario"
              (keyup)="modelCustoRecursoPlanejamento.valorUnitario = formatarInput($event.target.value)"
              maxlength="10" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
          </div>
        </div>

        <div class="row ">
          <div class="form-group col-md-12">
            <label for="quantidade" class="mb-1">Quantidade</label>
            <input type="text" id="quantidade" class="form-control form-control-sm"
              [(ngModel)]="modelCustoRecursoPlanejamento.quantidade" maxlength="5" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
          </div>
        </div>

      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
        <i class="fas fa-check"></i> Ok
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
</div>
