import { IPaaCoordenacaoCapacidadeModel } from 'src/app/models/paa-coordenacao-capacidade.model';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';
import { IBaseModel } from 'src/app/models/base.model';
import { IPaaAbaCapacidadeModalCapacidade, IPaaAbaCapacidadeModel } from 'src/app/models/paa-aba-capacidade.model';

@Injectable({
    providedIn: 'root'
  })
  export class PaaAbaCapacidadeService extends BaseService{
    constructor(httpclient: HttpClient, locationStrategy: LocationStrategy){
        super(httpclient, locationStrategy);
    }

    public async atualizarCapacidadeTrabalho(id: number, item: IPaaCoordenacaoCapacidadeModel): Promise<IBaseModel<IPaaCoordenacaoCapacidadeModel[]>> {
        return this.httpClient
          .post<IBaseModel<IPaaCoordenacaoCapacidadeModel[]>>(`${this.apiBaseUrl}/paaAbaCapacidade/${id}/capacidade-trabalho`, item)
          .toPromise();
      }
    


      public async obter(id: number): Promise<IBaseModel<IPaaAbaCapacidadeModel>> {
        return this.httpClient
          .get<IBaseModel<IPaaAbaCapacidadeModel>>(`${this.apiBaseUrl}/paaAbaCapacidade/${id}/capacidade-trabalho`)
          .toPromise();
      } 

      public async obterDetalheCoordenacao(id: number, idCoordenacao: number, ano: number): Promise<IBaseModel<IPaaAbaCapacidadeModalCapacidade>> {
        return this.httpClient
          .get<IBaseModel<IPaaAbaCapacidadeModalCapacidade>>(`${this.apiBaseUrl}/paaAbaCapacidade/${id}/detalha-coordenacao/${idCoordenacao}/${ano}`)
          .toPromise();
      } 
  }