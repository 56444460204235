
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import Inputmask from 'inputmask';
import { ModalCancelarComponent } from '../../../shared/components/modal-cancelar/modal-cancelar-component';
import Swal from 'sweetalert2';
import { PeriodoExercicioService } from 'src/app/services/periodo-exercicio.service';
import { ModalSelecionarResponsavelComponent } from '../modal-selecionar-responsavel/modal-selecionar-responsavel.component';
import { IPeriodoExercicioModel } from 'src/app/models/periodo-exercicio.model';
import { ResponsaveisService } from 'src/app/services/responsaveis.service ';
import { IResponsavelModel } from 'src/app/models/responsaveis.model';
import { ModalIncluirPeriodoExercicioComponent } from '../modal-incluir-periodo-exercicio/modal-incluir-periodo-exercicio.component';

@Component({
  selector: 'app-modal-incluir-responsavel',
  templateUrl: './modal-incluir-responsavel.component.html',
  styleUrls: ['./modal-incluir-responsavel.component.scss'],
})
export class ModalIncluirResponsavelComponent
  implements OnInit, AfterViewInit {

  public model: IResponsavelModel = {} as IResponsavelModel;
  public dataSource = new MatTableDataSource<IPeriodoExercicioModel>([]);
  public searchCpf: string;
  public searchNome: string;
  public searchAtivo = true;
  public visualizar = true;
  public semDados = true;
  public submited = false;
  public responsavelId;
  public unidadeGestoraId;

  constructor(
    private dialogRef: MatDialogRef<ModalIncluirResponsavelComponent>,
    public matDialog: MatDialog,
    private responsaveisService: ResponsaveisService,
    private periodoExercicioService: PeriodoExercicioService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.visualizar = data.visualizar;
    this.unidadeGestoraId = data.unidadeGestoraId;
    if (!data.element?.idResponsavel) {
      if (data.editar) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: 'Responsável não encontrado.',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        this.dialogRef.close(true);
      }
    }
    else {
      this.obterResponsavelPorId(data.element.idResponsavel);
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    Inputmask().mask(document.querySelectorAll('input'));
  }

  public obterResponsavelPorId(idResponsavel: string) {
    this.responsaveisService.obterPorResponsavelId(this.unidadeGestoraId, idResponsavel)
      .then((res) => {
        if (res.sucesso) {
          this.model = res.dados;
          this.responsavelId = res.dados.id;
          this.model.cpf = res.dados.cpf.padStart(11, '0');
          this.dataSource = new MatTableDataSource<IPeriodoExercicioModel>(this.model.periodoExercicio);
          this.dataSource._updateChangeSubscription();
          this.semDados = this.dataSource.filteredData.length === 0;
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'warning',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
  }

  public async buscar() {

    this.periodoExercicioService.obterPorResponsavelId(this.unidadeGestoraId, this.model.responsavelId)
      .then((res) => {
        this.dataSource = new MatTableDataSource<IPeriodoExercicioModel>(res.dados);
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      });
  }

  public async obterPorCpf() {

    const cpfValido = this.isValidCPF(this.model.cpf);

    if (!cpfValido) {

      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        icon: 'warning',
        title: 'Atenção',
        text: 'CPF inválido, informe um CPF válido!',
      });

      return '';
    }

    await this.responsaveisService
      .ObterAgentePorCpf(this.unidadeGestoraId, this.model.cpf)
      .then((res) => {
        if (!res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            icon: 'warning',
            title: 'Atenção',
            text: res.mensagem.descricao,
          });
          return;
        }
        this.model = res.dados;
        this.responsavelId = res.dados.id;
        this.model.cpf = res.dados.cpf.padStart(11, '0');
        this.dataSource = new MatTableDataSource<IPeriodoExercicioModel>(res.dados.periodoExercicio);
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: 'error',
          title: 'Atenção',
          text: err.mensagem.descricao,
        });
      })
  }

  public async concluir(salvar?: boolean) {

    this.submited = false;
    if (!this.model.cpf || !this.model.rg) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: 'Formulário inválido',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.submited = true;
      return;
    }

    let res;
    if (this.model.id) {
      res = await this.responsaveisService.atualizar(this.model);
    }
    else {
      res = await this.responsaveisService.inserir(this.model);
    }

    try {
      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        if (salvar) { this.dialogRef.close(true); }
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: error.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }


  public modalSelecionarResponsavel() {

    try {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component-selecionar-responsavel';
      dialogConfig.width = '700px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;

      const modal = this.matDialog.open(ModalSelecionarResponsavelComponent, dialogConfig);
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public fechar() {
    this.dialogRef.close();
  }

  public cancelar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { interno: true };
    this.matDialog.open(ModalCancelarComponent, dialogConfig).afterClosed();
  }

  public async modalIncluirPeriodoExercicio(editar?: boolean, element?: IPeriodoExercicioModel, indice?: number, visualizar?: boolean) {
    try {
      await this.concluir();
      if (!this.responsavelId) {
        await this.obterPorCpf();
      }

      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component-incluir-periodo';
      dialogConfig.width = '1380px';
      dialogConfig.maxHeight = '700px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        element,
        editar,
        indice,
        visualizar,
        responsavelId: this.responsavelId,
        unidadeGestoraId: this.unidadeGestoraId,
      };
      const modal = this.matDialog.open(ModalIncluirPeriodoExercicioComponent, dialogConfig);
      modal.afterClosed().subscribe((sucesso) => {
        if (sucesso) {
          this.obterPorCpf();
        }
      });
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    } 
  }

  public isValidCPF(cpf) {
    // Validar se é String
    if (typeof cpf !== 'string') return false

    // Tirar formatação
    cpf = cpf.replace(/[^\d]+/g, '')

    // Validar se tem tamanho 11 ou se é uma sequência de digitos repetidos
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false

    // String para Array
    cpf = cpf.split('')

    const validator = cpf
      // Pegar os últimos 2 digitos de validação
      .filter((digit, index, array) => index >= array.length - 2 && digit)
      // Transformar digitos em números
      .map(el => +el)

    const toValidate = pop => cpf
      // Pegar Array de items para validar
      .filter((digit, index, array) => index < array.length - pop && digit)
      // Transformar digitos em números
      .map(el => +el)

    const rest = (count, pop) => (toValidate(pop)
      // Calcular Soma dos digitos e multiplicar por 10
      .reduce((soma, el, i) => soma + el * (count - i), 0) * 10)
      // Pegar o resto por 11
      % 11
      // transformar de 10 para 0
      % 10

    return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1])
  }

}
