import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAprovacaoModel } from '../models/aprovacao.model';
import { IBaseModel } from '../models/base.model';
import { ILinhaDoTempoModel } from '../models/linha-tempo.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class AvaliacaoService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(tipoDocumento: number, tipoDocumentoId: number): Promise<IBaseModel<ILinhaDoTempoModel>> {
    return this.httpClient
      .get<IBaseModel<ILinhaDoTempoModel>>(`${this.apiBaseUrl}/avaliacao/${tipoDocumento}/tipoTrabalho/${tipoDocumentoId}`, { })
      .toPromise();
  }
}
