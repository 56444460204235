<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-12">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/']">
                            <i class="fas fa-home"></i>
                            Página Inicial</a>
                    </li>
                    <li class="breadcrumb-item active">PAAC - Plano Anual de Auditoria e Controles</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-lightblue">
                    <div class="card-header">
                        <h3 class="card-title mb-0">
                            <i class="fas fa-table pr-2"></i>
                            PAAC - Plano Anual de Auditoria e Controles
                        </h3>
                    </div>

                    <div class="card-body p-2">
                        <div class="mat-elevation-z4">
                            <mat-toolbar class="table-header">
                                <mat-toolbar-row>
                                    <button type="button" class="btn btn-success
                                        btn-sm mr-1" (click)="novo()" >
                                        <i class="far fa-file"></i> Novo
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm mr-1"
                                        [hidden]="selectedModel?.situacao?.codigo === 2 || !permissoes.editar"
                                        [disabled]="!selectedModel || selectedModel.situacao.codigo !== 1"
                                        (click)="elaborar(selectedModel.id)">
                                        <i class="far fas fa-pen"></i> Editar
                                    </button>
                                    <button type="button" class="btn btn-info btn-sm mr-1" [disabled]="!selectedModel"
                                        (click)="visualizar(selectedModel.id)" [hidden]="!permissoes.visualizar">
                                        <i class="far fas fa-eye"></i> Visualizar
                                    </button>
                                    <!-- <button type="button" class="btn btn-warning btn-sm mr-1"
                                        (click)="enviarAprovacao(selectedModel.id)"
                                        *ngIf="selectedModel && selectedModel.ativo && selectedModel.situacao.codigo === 1 && permissoes.enviarParaAprovacao">
                                        <i class="far fa-file"></i> Enviar para Aprovação
                                    </button> -->
                                    

                                    <button type="button" class="btn btn-danger btn-sm mr-1" 
                                        [hidden]="botaoExcluirHidden()" mwlConfirmationPopover
                                        popoverTitle="Excluir" popoverMessage="Deseja realmente excluir o PAAC?"
                                        placement="bottom" confirmText="Sim" confirmButtonType="primary"
                                        cancelText="Não" (confirm)="excluir()">
                                        <i class="fa fa-trash"></i> Excluir
                                    </button>

                                    <button type="button" class="btn btn-info btn-sm mr-1" *ngIf="permissoes.imprimir"
                                        [disabled]="!selectedModel || selectedModel.grupo"
                                        (click)="relatorio(selectedModel.id)">
                                        <i class="far fas fa-print"></i> Imprimir
                                    </button>

                                    <span class="fill-remaining-space"></span>

                                    <div class="input-group input-group-sm" style="width: 200px;">
                                        <input type="text" name="table_search" class="form-control float-right"
                                            (keyup.enter)="buscar()" placeholder="Pesquisar por nome"
                                            [(ngModel)]="filtro.pesquisa" />

                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-info" (click)="buscar()">
                                                <i class="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="btn-group">
                                        <button type="button" data-toggle="dropdown"
                                            class="btn btn-tool btn-default dropdown-toggle">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                        <div role="menu" class="dropdown-menu dropdown-menu-right
                    dropdown-menu-checkbox">
                                            <a (click)="filtro.idSituacao= undefined; buscar()" class="dropdown-item">
                                                <i [ngClass]="filtro?.idSituacao === undefined ? 'fas fa-check-circle'
                                                                                            : 'far fa-circle'">
                                                </i>Exibir todos</a>

                                            <a (click)="filtro.idSituacao = 1; buscar()" class="dropdown-item">
                                                <i [ngClass]="filtro?.idSituacao === 1 ? 'fas fa-check-circle'
                                                                                  : 'far fa-circle'">
                                                </i>
                                                    Exibir somente em elaboração</a>

                                            <a (click)="filtro.idSituacao= 2; buscar()" class="dropdown-item">
                                                <i [ngClass]="filtro?.idSituacao === 2 ? 'fas fa-check-circle'
                                                                                        : 'far fa-circle'">
                                                </i>Exibir somente em aprovação</a>

                                            <a (click)="filtro.idSituacao= 4; buscar()" class="dropdown-item">
                                                <i [ngClass]="filtro?.idSituacao === 4 ? 'fas fa-check-circle'
                                                                                        : 'far fa-circle'">
                                                </i>Exibir somente homologado</a>

                                        </div>
                                    </div>
                                </mat-toolbar-row>
                            </mat-toolbar>
                            <table mat-table [dataSource]="dataSource" #tabela1="matSort" matSort matSortDisableClear
                                class="w-100 mat-elevation-z8" [hidden]="semDados" (matSortChange)="sortData($event)">

                                <ng-container matColumnDef="selecao">
                                    <th mat-header-cell *matHeaderCellDef> </th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                                            (change)="selectedModel = ($event.checked ? element : null);"
                                            [checked]="selectedModel?.id === element.id">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="ano">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ano </th>
                                    <td mat-cell *matCellDef="let element"> {{element.ano}} </td>
                                </ng-container>

                                <ng-container matColumnDef="pea">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> PEAC </th>
                                    <td mat-cell *matCellDef="let element"> {{element.pea?.nome}} </td>
                                </ng-container>

                                <ng-container matColumnDef="paa">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> PAAC </th>
                                    <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
                                </ng-container>

                                <ng-container matColumnDef="origem">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Origem</th>
                                    <td mat-cell *matCellDef="let element"> {{element?.origem?.descricao}} </td>
                                </ng-container>

                                <ng-container matColumnDef="ativo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Situação </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="element.situacao?.nome
                        === 'Homologado'" class="badge bg-primary text-xs">Homologado</span>
                                        <span *ngIf="element.situacao?.codigo
                        === 2" class="badge bg-warning text-xs">Em
                                            Aprovação</span>
                                        <span *ngIf="element.situacao?.nome
                        === 'EmElaboracao'" class="badge bg-success text-xs">Em
                                            Elaboração</span>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns; when: !itemAgrupador"
                                    (click)="selecionar(row)" class="clickable"
                                    [ngClass]="{'bg-light': selectedModel?.id === row.id && selectedModel?.grupo === row.grupo}">
                                </tr>

                                <!-- Group header -->
                                <ng-container matColumnDef="groupHeader">
                                    <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>Período
                                            {{groupBy.nome}} -
                                            {{groupBy.dataInicio | date:'dd/MM/yyyy'}} a {{groupBy.dataFim |
                                            date:'dd/MM/yyyy'}} </strong>
                                    </td>
                                </ng-container>

                                <tr mat-row (click)="selecionar(row)" class="clickable"
                                    *matRowDef="let row; columns: ['selecao', 'groupHeader']; when: itemAgrupador">
                                </tr>
                            </table>
                            <h5 [hidden]="!semDados" class="table-no-data">Sem dados a exibir</h5>
                            <mat-paginator [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons [length]="totalItens"
                                (page)="selectedModel = null; pageEvent = pageChanged($event)">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>