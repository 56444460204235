import { IUnidadeGestoraModel } from './../models/unidade-gestora.model';
import { IQuestaoModel } from 'src/app/models/questao.model';
import { BaseService } from './base.service';
import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { Subject } from 'rxjs';
import { ISubtipoAuditoriaModel } from '../models/subtipo-auditoria.model';

type subjectOfArray = any | any[] | Subject<any>;

@Injectable({
  providedIn: 'root'
})
export class ManterMatrizPlanejamentoService extends BaseService {

  private _dados: Subject<any> = new Subject();

  public get dados(): subjectOfArray {
    return this._dados.asObservable() as Subject<any>;
  }

  public set dados(value: subjectOfArray) {
    this._dados.next(value);
  }

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public async excluirQuestao(planejamentoId: number, idSubtipo: number, idQuestao: number, tipoTrabalho: string): Promise<IBaseModel<any>> {
    if (tipoTrabalho === 'planejamento') {
      return this.httpClient
        .delete<IBaseModel<void>>(`${this.apiBaseUrl}/planejamento/${planejamentoId}/questao/${idQuestao}/subTipo/${idSubtipo}/excluir`, {})
        .toPromise();
    } else if (tipoTrabalho === 'osa') {
      return this.httpClient
        .delete<IBaseModel<void>>(`${this.apiBaseUrl}/osas/${planejamentoId}/questao/${idQuestao}/subTipo/${idSubtipo}/excluir`, {})
        .toPromise();
    }

  }

  public async obter(): Promise<void> {
    this.httpClient.get<IBaseModel<ISubtipoAuditoriaModel[]>>(`${this.apiBaseUrl}/subtipoauditoria`).toPromise().then(x => this.dados = x.dados)
  }

  public async vincularQuestao(tipoTrabalho: number, matrizPlanejamentoId: number, questaoId: number, isQuestaoPlanejamento: boolean, relacionamentoId: number, subTipoAuditoriaId: number): Promise<IBaseModel<any>> {
    if (isQuestaoPlanejamento) {
      return this.httpClient
        .put<IBaseModel<void>>(`${this.apiBaseUrl}/matrizPlanejamento/${matrizPlanejamentoId}/questaoEspecifica/${questaoId}/${tipoTrabalho}/${relacionamentoId}/${subTipoAuditoriaId}`, {})
        .toPromise();
    }
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/matrizPlanejamento/${matrizPlanejamentoId}/questao/${questaoId}/${tipoTrabalho}/${relacionamentoId}/${subTipoAuditoriaId}`, {})
      .toPromise();
  }

  public obterQuestaoSubtipo(subtipoAuditoriaId: number, descricao: string): Promise<IBaseModel<IQuestaoModel[]>> {
    let query = new HttpParams();
    query = query.append("subtipoAuditoriaId", subtipoAuditoriaId.toString());
    query = query.append("descricao", descricao);
    return this.httpClient.get<IBaseModel<IQuestaoModel[]>>(`${this.apiBaseUrl}/subtipoAuditoria/questoes`, { params: query }).toPromise();
  }

  public obterUgSubtipo(id: number, subtipoAuditoriaId: number): Promise<IBaseModel<IUnidadeGestoraModel[]>> {
    return this.httpClient.get<IBaseModel<IUnidadeGestoraModel[]>>(`${this.apiBaseUrl}/planejamento/${id}/subTipoAuditoria/${subtipoAuditoriaId}/unidades-gestoras`).toPromise();
  }

}
