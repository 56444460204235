import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { LocationStrategy } from "@angular/common";
import { ITipoTrabalhoModel } from "../models/tipo-trabalho.model";
import { IBaseModel } from "../models/base.model";

@Injectable({
    providedIn: 'root',
  })

  export class TipoTrabalhoService extends BaseService{
    constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
        super(httpClient, locationStrategy);
      }

      public obter(): Promise<IBaseModel<ITipoTrabalhoModel[]>> {
        return this.httpClient
          .get<IBaseModel<ITipoTrabalhoModel[]>>(`${this.apiBaseUrl}/tipoTrabalho`)
          .toPromise();
      }

  }

