import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IIndicadorModel } from 'src/app/models/indicador.model';

@Component({
  selector: 'app-modal-cadastrar-indicador',
  templateUrl: './modal-cadastrar-indicador.component.html',
  styleUrls: ['./modal-cadastrar-indicador.component.scss']
})
export class ModalCadastrarIndicadorComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  public todosIndicadores = [] as IIndicadorModel[];
  public semDados = true;
  public displayedColumns = ['selecao',  'nome'];
  public searchNome: string;
  public selectedModel: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<ModalCadastrarIndicadorComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.todosIndicadores = data;
    this.dataSource.data = this.todosIndicadores;
    this.dataSource._updateChangeSubscription();
  }

  ngOnInit(): void {
    this.sort.sort(({ id: 'nome', start: 'asc'}) as MatSortable);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public buscar() {
    this.selectedModel = null;
    
    if(this.searchNome && this.searchNome != ""){
      this.dataSource.data = this
                              .todosIndicadores
                              .filter(a => 
                                          a
                                          .nome
                                          .normalize('NFD')
                                          .replace(/\p{Diacritic}/gu, "")
                                          .toLocaleLowerCase()
                                          .includes(this.searchNome.toLocaleLowerCase()));
    }else{
      this.dataSource.data = this.todosIndicadores;
    }

    this.dataSource._updateChangeSubscription();
    this.semDados = false;
  }

  public concluir() {
    this.dialogRef.close(this.selectedModel);
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel !== item ? item : null;
  }

  public itemAgrupador(index: number, item: any): boolean {
    return item.grupo;
  }

  public cancelar() {
    this.dialogRef.close();
  }
}
