<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLongTitle">Exibição do Gráfico de Distribuição</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" matTooltipPosition="above"
  matTooltip="Voltar" (click)="cancelar()" [hidden]="dadosImprimir.length > 0">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [ngClass]="{'normal': dadosImprimir?.length === 0, 'imprimir-relatorio-paa': dadosImprimir?.length > 0}">
  <div class="table-container p-3" id="imprimir-table">
    <div class="row">
      <div class="form-group m-0 col-md-12">
        <div class="wrapper p-0" style="overflow-y: auto;">
          <div class="gantt">
            <div class="gantt__row gantt__row--months">
              <div class="gantt__row-first">Unidade Gestora</div>
              <span>Jan</span><span>Fev</span><span>Mar</span>
              <span>Abr</span><span>Mai</span><span>Jun</span>
              <span>Jul</span><span>Ago</span><span>Set</span>
              <span>Out</span><span>Nov</span><span>Dez</span>
            </div>
            <div class="gantt__row gantt__row--lines" data-month="5">
              <span></span><span></span><span></span> <span></span
              ><span></span><span></span> <span></span><span></span
              ><span></span> <span></span><span></span><span></span>
            </div>
            <div class="gantt__row" *ngFor="let un of dados">
              <div class="gantt__row-first">
                {{ un.siglaUnidadeGestora }}
              </div>
              <ul class="gantt__row-bars">
                <li
                  [matTooltip]="obterDescricaoCoordenacao(coord)"
                  *ngFor="let coord of un.paaGraficoCoordenacoes"
                  [style]="ObterEstiloCoordenacao(coord)"
                >
                  {{ coord.siglaCoordenacao }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group col-md-12 m-0 mt-2 bg-white">
            <label>Legenda:</label>
            <ul class="d-block gantt__row-bars">
              <li
                [matTooltip]="obterDescricaoCoordenacaoLegenda(coord)"
                *ngFor="let coord of coordenacoes"
                [style]="ObterEstiloLegendaCoordenacao(coord)"
              >
                {{ coord.siglaCoordenacao }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer"  [hidden]="dadosImprimir.length > 0">
  <button
  type="button"
  class="btn btn-default btn-sm"
  (click)="cancelar()"
>
  <i class="fas fa-times"></i> Fechar
</button>
<button
  type="button"
  class="btn btn-primary ml-1 btn-sm"
  (click)="imprimir('imprimir-table')"
>
  <i class="fas fa-print"></i> Imprimir
</button>
</div>
