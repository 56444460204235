<div class="card-header bg-light">
    <h4 class="card-title mb-0">
        <i class="far fa-file"></i> Delegar Item
    </h4>
</div>
<div class="card-body">
  <div class="wrapper">
    <div class="row">
        <div class="col-md-12 mb-2">
            <label class="form-label">Responsável</label>
            <input type="text" class="form-control form-control-sm" disabled placeholder="Ainda não existe um responsável" [value]="responsavelDelegado" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mb-4">
            <label class="form-label">Delegar Responsável</label>
            <ng-select [(ngModel)]="responsavelDelegacao">
                <ng-option *ngFor="let responsavel of responsaveis" [value]="responsavel.id">{{responsavel.nome}}</ng-option>
            </ng-select>
        </div>
    </div>
</div>
</div>


<div class="card-footer" >
    <button type="button" class="btn btn-default btn-sm mr-1" (click)="fechar()">
      <i class="fas fa-times"></i> Fechar
    </button>

    <button [disabled]="!responsavelDelegacao" type="button" class="btn btn-success btn-sm" (click)="delegar()">
        <i class="fas fa-check"></i> Delegar
      </button>
</div>
