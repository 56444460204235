import { ToastrService } from 'ngx-toastr';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { ICoordenacaoTipoTrabalhoModel } from 'src/app/models/coordenacao-tipo-trabalho.model';
import { TipoTrabalhoService } from 'src/app/services/tipo-trabalho.service';
import { ITipoTrabalhoModel } from 'src/app/models/tipo-trabalho.model';
import { CoordenacaoService } from 'src/app/services/coordenacao.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { IBaseModel } from 'src/app/models/base.model';

@Component({
  selector: 'app-modal-tipo-trabalho',
  templateUrl: './modal-tipo-trabalho.component.html',
  styleUrls: ['./modal-tipo-trabalho.component.scss'],
})

export class ModalCoordenacaoTipoTrabalhoComponent extends BaseFormComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  public model = {} as ICoordenacaoTipoTrabalhoModel;
  public anoSelecionado: number;
  public tabelaTipoTrabalho = [] as ITipoTrabalhoModel[];
  public selectedTipoTrabalho = {} as ITipoTrabalhoModel;
  public txtBtnConcluir: string;
  public campoObrigatorio = false;

  constructor(
    route: ActivatedRoute,
    private dialogRef: MatDialogRef<ModalCoordenacaoTipoTrabalhoComponent>,
    public toastr: ToastrService,
    localeService: BsLocaleService,
    router: Router,
    private tipoTrabalhoService: TipoTrabalhoService,
    private coordenacaoService: CoordenacaoService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super(route, toastr, router, localeService, matDialog);
    this.model.id = data?.id;
    this.model.ano = data?.anoSelecionado;
    this.model.coordenacaoId = data?.idCoordenacao;
    this.model.qtdMediaDias = data?.qtdMediaDias;
    this.model.qtdMembros = data?.qtdMembros;
    this.model.tipoTrabalhoId = data?.tipoTrabalhoId;

    if(!this.model.id || this.model.id==0)
      this.txtBtnConcluir = "Inserir"
    else
      this.txtBtnConcluir = "Atualizar"
  }

  ngOnInit(): void {
    this.buscarTiposTrabalho();
  }
 
  
  ngAfterViewInit(): void {
    
  }


  public async concluir() {
    try{
      this.campoObrigatorio = true;

      if(!this.model.tipoTrabalhoId 
          || this.model.tipoTrabalhoId== 0
          || !this.model.qtdMediaDias
          || !this.model.qtdMembros)
        return false;

      let res: IBaseModel<void> = null;

      if(!this.model.id || this.model.id == 0){
          res = await this.coordenacaoService.inserirTipoTrabalho(this.model);
      }else{
          res = await this.coordenacaoService.atualizarTipoTrabalho(this.model);
      }

      if(res.sucesso){
        this.exibirMensagemSucesso(res.mensagem.descricao);
        this.dialogRef.close(true);
        }
      else
        this.exibirMensagemErro(res.mensagem.descricao);

      
    }catch(err){
      this.exibirMensagemErro(err);
    }
  }

  public async buscarTiposTrabalho(){
    const tipos = await this.tipoTrabalhoService
                        .obter()
                        .then(res => {
                            if(res.sucesso)
                              this.tabelaTipoTrabalho = res.dados;
                        });
    
  }

  public cancelar() {
    this.dialogRef.close(false);
  }

}

