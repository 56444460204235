import { MatSort, MatSortable } from '@angular/material/sort';
import { AfterViewInit, Component, Output, OnInit, ViewChild, EventEmitter } from "@angular/core";
import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { MatDialog,	MatDialogConfig } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators  } from '@angular/forms';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { Location } from '@angular/common';

//COMPONENTS
import { ModalAlocarEquipeComponent } from '../modal-alocar-equipe/modal-alocar-equipe.component';
import { ModalGraficoDistribuicaoComponent } from '../modal-grafico-distribuicao/modal-grafico-distribuicao.component';

//MODELS
import { IPaaEquipeModel } from 'src/app/models/paa-equipe.model';
import { IPaaInformacoesBasicasModel } from 'src/app/models/paa-informacoes-basicas.model';

//SERVICES
import { PaaService } from 'src/app/services/paa.service';


@Component({
	selector: "app-paa-aba-alocacao",
	templateUrl: "./paa-aba-alocacao.component.html",
	styleUrls: ["./paa-aba-alocacao.component.scss"],
})
export class PaaAbaAlocacaoComponent extends BaseFormComponent implements OnInit{

    public paaInformacoesBasicas: IPaaInformacoesBasicasModel = {} as IPaaInformacoesBasicasModel;
    public equipeAlocacaoDataSource = new MatTableDataSource<IPaaEquipeModel>([]);
    public equipeAlocacaoSemDados = true;
    @ViewChild('tabela4') public tabela4: MatSort;

    constructor(route: ActivatedRoute,
		toastr: ToastrService,
		router: Router,
		localeService: BsLocaleService,
		private _location: Location,
        public matDialog: MatDialog,
        private paaService: PaaService
    ){
        super(route, toastr, router, localeService, matDialog);
    }

    async ngOnInit() {
        await this.buscarInformacoesBasicas();
        this.tabela4?.sort({ id: 'nome', start: 'asc' } as MatSortable);
        this.buscar();

        //TODO: VERIFICAR PQ A VARIÁVEL VISUALIZAR ESTÁ SENDO RESETADA
			this.visualizar = this.route.routeConfig.path?.includes("visualizar");
    }

    public async buscar(): Promise<void> {
        
        var paaId = this.route?.snapshot?.params?.id;
        return this.paaService
          .obterAlocacoesEquipe(this.id ?? paaId)
          .then((resEquipes) => {
            var dados = resEquipes.dados.map((item) => {
              item.paaDistribuicao.unidadeGestora.sigla =
                item.paaDistribuicao.unidadeGestora.sigla.toUpperCase();
              return item;
            });
    
            this.equipeAlocacaoDataSource = new MatTableDataSource<IPaaEquipeModel>(
              dados
            );
            this.equipeAlocacaoDataSource._updateChangeSubscription();
    
            this.equipeAlocacaoDataSource.sortingDataAccessor = (
              item,
              property
            ) => {
              switch (property) {
                case 'nome':
                  return item?.paaDistribuicao?.unidadeGestora?.sigla;
                case 'tipoAuditoria':
                  return item?.paaDistribuicao?.tipoAuditoria?.nome;
                case 'tipoEnfase':
                  return item?.paaDistribuicao?.tipoEnfase?.nome;
                case 'equipeAlocada':
                  return item?.paaEquipeAuditor[0]?.nomeAuditor;
                default:
                  return item[property];
              }
            };
    
            this.equipeAlocacaoDataSource.sort = this.tabela4;
    
            this.equipeAlocacaoSemDados =
              this.equipeAlocacaoDataSource.filteredData.length === 0;
          });
      }

      public async alocarEquipe(equipeAlocacao: IPaaEquipeModel) {
        try {
          const equipe = (
            await this.paaService.obterEquipe(equipeAlocacao.paaDistribuicaoId)
          ).dados;
          const dialogConfig = new MatDialogConfig();
          dialogConfig.id = 'modal-component';
          dialogConfig.width = '650px';
          dialogConfig.maxHeight = '80vh';
          dialogConfig.hasBackdrop = true;
          dialogConfig.disableClose = true;
          dialogConfig.data = {
            equipe,
            ano: this.paaInformacoesBasicas.ano,
          };
          const modal = this.matDialog.open(
            ModalAlocarEquipeComponent,
            dialogConfig
          );
    
          modal.afterClosed().subscribe((sucesso) => {
            if (sucesso) {
              this.buscar();
            }
          });
        } catch (err) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: err.mensagem.descricao,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        }
      }

      public async buscarInformacoesBasicas(){

		await this.paaService.buscarInformacoesBasicas(this.id)
		  .then((res)=> {
			if(res.sucesso)
			  this.paaInformacoesBasicas = res.dados;
		  })
	  }

      public lowerCase(nome: string) {
        return nome.toLowerCase().replace(/(?:^|\s)\S/g, (X) => X.toUpperCase());
      }

mostrarBotaoAlocarEquipe(): boolean{
    return !this.visualizar;
}

public visualizarGrafico() {

  this.paaService
    .obterGrafico(this.id)
    .then((res) => {
      if (res.sucesso) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component';
        // dialogConfig.width = '650px';
        dialogConfig.maxHeight = '100%';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.data = res.dados;
        const modal = this.matDialog.open(
          ModalGraficoDistribuicaoComponent,
          dialogConfig
        );
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })
    .catch((err) =>
      this.exibirMensagemErro(err.mensagem.descricao)
    )
}
}