import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import Inputmask from 'inputmask';
import { ModalCancelarComponent } from '../../../shared/components/modal-cancelar/modal-cancelar-component';
import Swal from 'sweetalert2';
import { ISelecionarResponsavelModel } from 'src/app/models/selecionar-responsavel';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-modal-selecionar-responsavel',
  templateUrl: './modal-selecionar-responsavel.component.html',
  styleUrls: ['./modal-selecionar-responsavel.component.scss'],
})
export class ModalSelecionarResponsavelComponent
  implements OnInit, AfterViewInit {

  public displayedColumns = [
    'cpf', 'nome', 'email',
  ];
  public dataSource = new MatTableDataSource<ISelecionarResponsavelModel>([]);
  public searchNome: string;
  public seacrhEmail: string;
  public seacrCpf: number;
  public semDados = true;
  model: ISelecionarResponsavelModel;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<ModalSelecionarResponsavelComponent>,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {
    this.dataSource = new MatTableDataSource<any>(data);
  }

  ngOnInit(): void {
    this.buscar();
  }

  ngAfterViewInit(): void {
    Inputmask().mask(document.querySelectorAll('input'));
  }

  public async selecionar() {
    {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        icon: 'warning',
        text: 'Formulário inválido'
      });
      return;
    }
  }

  public async buscar() {
    this.dataSource = new MatTableDataSource<ISelecionarResponsavelModel>([]);
    this.dataSource._updateChangeSubscription();
    this.semDados = this.dataSource.filteredData.length === 0;
  }


  public cancelar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { interno: true };
    this.matDialog.open(ModalCancelarComponent, dialogConfig).afterClosed();
  }
}
