import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAprovacaoModel } from '../models/aprovacao.model';
import { IAvaliacaoModel } from '../models/avaliacao.model';
import { IBaseModel } from '../models/base.model';
import { IEnumModel } from '../models/enum.model';
import { PaginacaoModel } from '../models/paginacao.model';
import { filterBy } from './auditoria.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class AprovacaoService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(filtro?: any): Promise<IBaseModel<PaginacaoModel<IAprovacaoModel[]>>> {
    return this.httpClient
      .get<IBaseModel<PaginacaoModel<IAprovacaoModel[]>>>(filterBy(filtro, `${this.apiBaseUrl}/aprovacao`) )
      .toPromise();
  }

  public async aprovarReprovar(id: number, tipoDocumento: number, situacaoAvaliacao: number, comentario: string): Promise<IBaseModel<void>> {
    const request = {
      tipoTrabalho: {
        codigo: tipoDocumento
      },
      situacao: {
        codigo: situacaoAvaliacao
      },
      comentario
    } as IAvaliacaoModel;

    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/aprovacao/${id}/aprovar-reprovar`, request)
      .toPromise();
  }

  public async imprimir(id: number): Promise<IBaseModel<string>> {

    return this.httpClient
      .get<IBaseModel<string>>(`${this.apiBaseUrl}/avaliacao/${id}/imprimir`, {})
      .toPromise();
  }

  public async imprimirRelatoria(id: number): Promise<IBaseModel<string>> {

    return this.httpClient
      .get<IBaseModel<string>>(`${this.apiBaseUrl}/avaliacao/${id}/imprimir-relatoria`, {})
      .toPromise();
  }
}
