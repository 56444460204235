import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { IMenuModel } from '../models/menu.model';

@Injectable({
    providedIn: 'root'
  })
  export class MenuService extends BaseService {
    constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
        super(httpClient, locationStrategy);
      }

      public async obter(): Promise<IBaseModel<IMenuModel>>{
        return this.httpClient
                .get<IBaseModel<IMenuModel>>(`${this.apiBaseUrl}/menu/`)
                .toPromise();
      }
  }  