<div class="card-header bg-light">
	<h4 class="card-title mb-0">Inserir amostra</h4>
</div>
<div class="p-3 ">
	<form
		class="row needs-validation d-flex justify-content-center wrapper"
		[ngClass]="{'was-validated': validar}"
		novalidate
	>
		<div class="col-md-12">
			<label for="validationCustom01" class="form-label">Nome *</label>
			<input
				[disabled]="visualizar"
				type="text"
				class="form-control"
				id="validationCustom01"
				required
				[ngModelOptions]="{ standalone: true }"
				[(ngModel)]="dados.nome"
				maxlength="100"
			/>
		</div>
		<div class="col-md-12">
			<label for="validationCustom02" class="form-label">Descrição *</label>
			<textarea [disabled]="visualizar"
				class="form-control"
				id="validationCustom02"
				[ngModelOptions]="{ standalone: true }"
				[(ngModel)]="dados.descricao"
				required
				maxlength="200"
			>
			</textarea>
		</div>

		<div class="col-md-12 ">
			<label for="validationCustom02" class="form-label"
				>Selecionar UG associada</label
			>
			<div class="mat-elevation-z1">
				<table
					mat-table
					[dataSource]="ugDataSource"
					matSort
					matSortDisableClear
					matSort
					matSortActive="nome"
					matSortDirection="asc"
				>
					<tr
						mat-header-row
						*matHeaderRowDef="['nome', 'actions']"
						class="bg-light"
					></tr>

					<ng-container matColumnDef="nome">
						<th mat-header-cell *matHeaderCellDef>
							Unidade(s) Gestora(s)
						</th>
						<td mat-cell *matCellDef="let element" class="text">
							{{ element.text }}
						</td>
					</ng-container>

					<ng-container cdkColumnDef="actions">
						<th mat-header-cell *matHeaderCellDef>
							<div class="w-100 d-flex justify-content-end">
								<button
									type="button" *ngIf="!visualizar"
									class="btn btn-success btn-xs m-0"
									(click)="exibirModalUg()"
								>
									Adicionar
									<i class="far fas fa-plus fa-sm"></i>
								</button>
							</div>
						</th>
						<td
							mat-cell
							*matCellDef="let element; let i = index"
							class="block"
						>
							<div class="btn-group">
								<button *ngIf="!visualizar"
									type="button"
									class="btn btn-danger btn-xs text-left"
									(click)="excluirUg(i)"
								>
									<i class="far fa-trash-alt"></i>
								</button>
							</div>
						</td>
					</ng-container>

					<tr
						mat-row
						*matRowDef="let row; columns: ['nome', 'actions']"
					></tr>
				</table>
				<small *ngIf="ugSemDados" class="w-100 d-flex justify-content-center align-items-center">
					Nenhuma Unidade Gestora selecionada
				</small>
				<mat-paginator
					[hidden]="ugSemDados"
					[pageSizeOptions]="[5, 10, 15]"
					showFirstLastButtons
				></mat-paginator>
			</div>
		</div>

		<div class="col-md-12 ">
			<div class="input-group">
				<label for="validationCustom02" class="form-label"
					>Importar arquivo</label
				>
				<input [disabled]="visualizar"
					type="file"
					class="form-control-file"
					id="file"
					name="file[]"
					multiple="multiple"
					(change)="uploadAmostra($event)"
					class="inputfile"
				/>
				<label for="file" class="button-style btn btn-success btn-sm"
					><i class="fa fa-upload" aria-hidden="true"></i>ㅤEscolha os
					arquivos</label
				>
				<small *ngIf="!dados.arrayFiles[0]" class="ml-1 mt-3 w-100 d-flex justify-content-center align-items-center"> Ainda não possui nenhum arquivo adicionado</small>
				<ul class="list-group w-100" *ngIf="dados.arrayFiles">
					<li
						class="list-group-item d-flex justify-content-between align-items-center"
						*ngFor="let file of dados.arrayFiles; let i = index"
					>
					<p class="d-flex justify-content-between align-items-center m-0">{{ file?.name }}{{ file?.nome }}</p>
					<button type="button" class="btn btn-danger btn-xs text-left ml-1" (click)="deleteFile(i)">
							<i class="far fa-trash-alt"></i>
					</button>
					</li>
				</ul>
			</div>
		</div>
	</form>
</div>
<div class="card-footer">
	<button class="btn btn-primary btn-sm" *ngIf="!visualizar" (click)="salvar()">Salvar</button>
	<button class="btn btn-default btn-sm ml-2" (click)="cancelar()">
		Cancelar
	</button>
</div>
