import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IPaaRankingPrioridadeModel } from 'src/app/models/paa-ranking-prioridade.model';
import { IPeaRankingPrioridadeModel } from 'src/app/models/pea-ranking-prioridade.model';
import { ModalDetalharValorRiscoComponent } from 'src/app/pages/pea-form/modal-detalhar-valor-risco/modal-detalhar-valor-risco.component';
import { RankingDistribuicaoService } from 'src/app/services/ranking-prioridade.service';

@Component({
  selector: 'app-ranking-prioridade',
  templateUrl: `./ranking-prioridade.component.html`,
  styleUrls: ['./ranking-prioridade.component.scss'],
})
export class RankingPrioridadeComponent implements OnInit, AfterViewInit, OnChanges {
  public columnsRanking = ['codigoUg', 'sigla', 'nome', 'valorRisco', 'actions','fatorRedutor', 'valorRiscoResidual'];
  @Input() public rankingPrioridadeDataSource: MatTableDataSource<IPeaRankingPrioridadeModel | IPaaRankingPrioridadeModel>;
  @Input() public rankingPrioridadeEmpresasPublicasDataSource: MatTableDataSource<IPeaRankingPrioridadeModel | IPaaRankingPrioridadeModel>;
  @Input() public semDadosRanking: boolean;
  @Input() public semDadosRankingEmpresaPublica: boolean;

  @ViewChild("tabela1", { static: true }) tabela1: MatSort;

  @ViewChild("tabela2", { static: true }) tabela2: MatSort;
  constructor(
    public route: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    public localeService: BsLocaleService,
    public matDialog: MatDialog,
    public rankingService: RankingDistribuicaoService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rankingPrioridadeDataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.rankingPrioridadeDataSource.sort = this.tabela1

    this.rankingPrioridadeEmpresasPublicasDataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.rankingPrioridadeEmpresasPublicasDataSource.sort = this.tabela2
  }

  ngAfterViewInit(): void {
    this.rankingPrioridadeDataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.rankingPrioridadeDataSource.sort = this.tabela1

    this.rankingPrioridadeEmpresasPublicasDataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.rankingPrioridadeEmpresasPublicasDataSource.sort = this.tabela2
  }


  ngOnInit(): void {
    this.rankingPrioridadeDataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.rankingPrioridadeDataSource.sort = this.tabela1

    this.rankingPrioridadeEmpresasPublicasDataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.rankingPrioridadeEmpresasPublicasDataSource.sort = this.tabela2
  }

  public async exibirModalDetalharValorRisco(element: IPeaRankingPrioridadeModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '850px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = element;
    this.matDialog.open(
      ModalDetalharValorRiscoComponent,
      dialogConfig
    );
  }

  public sortingDataAccessor = (item: any, property: string) => {
    switch (property) {
      case 'codigoUg':
        return item?.codigoUg;
      case 'sigla':
        return item?.sigla;
      case 'nome':
        return item?.nome;
      case 'indicador':
        return item?.indicador.nome;
      case 'valorRisco':
        return item?.valorRisco;
      case 'fatorRedutor':
        return item?.fatorRedutor;
      case 'valorRiscoResidual':
        return item?.valorRiscoResidual;
      default:
        return item[property];
    }
  };



}
