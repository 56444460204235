import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';
import { IPaaAbaIndicesCriteriosModel } from '../../models/paa-aba-indices-criterios.model';
import { IBaseModel } from '../../models/base.model';
import { IPaaCriterioRespostaModel } from '../../models/paa-criterio-resposta.model';
import { IPaaIndiceCriterioModel } from '../../models/paa-indice-criterio.model';
import { IPaaCriterioQuestionarioModel } from '../../models/paa-criterio-questionario.model';
import { IEnviarQuestionarioModel } from '../../models/enviar-questionario.model';


@Injectable({
    providedIn: 'root'
  })
export class PaaAbaIndicesCriteriosService extends BaseService{
    constructor(httpClient: HttpClient, locationStrategy: LocationStrategy){
        super(httpClient, locationStrategy);
    }

    public obter(id: number): Promise<IBaseModel<IPaaAbaIndicesCriteriosModel>> {
    
        return this.httpClient
        .get<IBaseModel<IPaaAbaIndicesCriteriosModel>>(`${this.apiBaseUrl}/paaabaindicescriterios/${id}/obterPorId`)
        .toPromise();
    }

    public obterCriterioResposta(id: number): Promise<IBaseModel<IPaaCriterioRespostaModel[]>> {
        return this.httpClient
          .get<IBaseModel<IPaaCriterioRespostaModel[]>>(`${this.apiBaseUrl}/paaabaindicescriterios/indices-criterios/respostas/${id}`)
          .toPromise();
      }

      public async adicionarIndiceCriterio(item: IPaaIndiceCriterioModel): Promise<IBaseModel<IPaaIndiceCriterioModel>> {
        return this.httpClient
          .post<IBaseModel<IPaaIndiceCriterioModel>>(`${this.apiBaseUrl}/paaabaindicescriterios/indices-criterios/adicionar`, item)
          .toPromise();
      }

      public async removerIndiceCriterio(id: number): Promise<IBaseModel<boolean>> {
        return this.httpClient
          .delete<IBaseModel<boolean>>(`${this.apiBaseUrl}/paaabaindicescriterios/indices-criterios/remover/${id}`)
          .toPromise();
      }
    
      public async removerIndiceCriterios(paaId: number, indicadorId: number): Promise<IBaseModel<boolean>> {
        return this.httpClient
          .delete<IBaseModel<boolean>>(`${this.apiBaseUrl}/paaabaindicescriterios/indices-criterios/remover/paa/${paaId}/indicador/${indicadorId}`)
          .toPromise();
      }

      public async enviarQuestionariosPaa(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/paaabaindicescriterios/${id}/enviar-questionario`, {})
          .toPromise();
      }

      public async enviarRespostasCriterio(id: number, dados: IPaaCriterioRespostaModel[]): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/paaabaindicescriterios/${id}/responder-criterio`, dados)
          .toPromise();
      }

      public async obterVisualizacaoQuestionario(
        id: number
      ): Promise<IBaseModel<IPaaCriterioQuestionarioModel[]>> {
        return this.httpClient
          .get<IBaseModel<IPaaCriterioQuestionarioModel[]>>(
            `${this.apiBaseUrl}/paaabaindicescriterios/${id}/visualizar-questionario`,
            {}
          )
          .toPromise();
      }

      public async questionariosRespondidos(
        id: number
      ): Promise<IBaseModel<boolean>> {
        return this.httpClient
          .get<IBaseModel<boolean>>(
            `${this.apiBaseUrl}/paaabaindicescriterios/${id}/questionario-respondido`,
            {}
          )
          .toPromise();
      }

      public async cancelarEnvioQuestionario(
        id: number
      ): Promise<IBaseModel<boolean>> {
        return this.httpClient
          .get<IBaseModel<boolean>>(
            `${this.apiBaseUrl}/paaabaindicescriterios/${id}/cancelar-questionario`
          )
          .toPromise();
      }

      public async enviarQuestionarios(id: number, model: IEnviarQuestionarioModel): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(
            `${this.apiBaseUrl}/paaabaindicescriterios/${id}/enviar-questionario`,
            model
          )
          .toPromise();
      }
}

