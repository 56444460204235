
  <div class="card text-left h-100">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">{{ titulo }} Solicitação Técnica</h4>
        <div class="text-right" *ngIf="model?.status?.codigo === 5" >
          <button type="button" class="btn btn-warning btn-sm " (click)="avaliar()"> Avaliar Prorrogação</button>
        </div>
    </div>
    <div class="card-body wrapper m-0">
      <form class="needs-validation" novalidate id="myForm" #f="ngForm" [formGroup]="incluirSolicitacao">
        <div class="row">
          <div class="col-sm-4">
            <label for="validationCustom01" class="form-label">Associado à:</label>
            <input type="text" maxlength="100" name="associado" class="form-control form-control-sm" id="associadoAId"
              disabled placeholder="-" [(ngModel)]="model.associado" [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-sm-4" *ngIf="model.numero">
            <label for="validationCustom02" class="form-label">Nº Solicitação:</label>
            <input type="text" name="nSolicitacaoTecnica" class="form-control form-control-sm" id="nSolicitacao"
              disabled placeholder="-" [ngModel]="model?.numero" maxlength="15"
              [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-sm-4">
            <label for="validationCustom01" class="form-label">Prazo Resposta: </label>
            <input *ngIf="!visualizar; else inputVisualizar" type="text"  class="form-control form-control-sm"
              id="prazoResposta" formControlName="prazoResposta" placeholder="DD/MM/AAAA" [(ngModel)]="model.prazo"
              bsDatepicker
              [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
              [ngClass]="{ 'is-invalid': submitted && incluirSolicitacao.controls['prazoResposta'].invalid }" />
            <ng-template #inputVisualizar>
              <input type="text" class="form-control form-control-sm" id="prazoResposta" [(ngModel)]="model.prazo"
                formControlName="prazoResposta" disabled bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" />
            </ng-template>
            <div *ngIf="submitted &&  incluirSolicitacao.controls['prazoResposta'].invalid" class="invalid-feedback">
              <div *ngIf="incluirSolicitacao.controls['prazoResposta'].errors.required">Campo obrigatório</div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-12">
            <div class="mat-elevation-z1 ">
              <table mat-table [dataSource]="dataSourceUG" matSortDisableClear #tabela1="matSort" matSort
                matSortDirection="asc" >
                <tr mat-header-row *matHeaderRowDef="['ug', 'responsavel', 'actions']" class="bg-light">
                </tr>

                <ng-container matColumnDef="ug">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Unidade(s) Gestora(s)
                  </th>
                  <td mat-cell *matCellDef="let element" class="text">
                    {{element?.unidadeGestora?.text}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="responsavel">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Responsável
                  </th>
                  <td mat-cell *matCellDef="let element" class="text">
                    {{ element?.responsavel?.text }}
                  </td>
                </ng-container>

                <ng-container cdkColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef style="max-width: 90px;">
                    <button type="button" class="btn btn-success btn-xs w-100"
                    (click)="modalIncluirUg()" *ngIf="!visualizar">
                      Adicionar
                    </button>
                  </th>
                  <td mat-cell *matCellDef="let element; let i = index" class="block">
                    <div class="d-flex w-100 justify-content-center align-items-center">
                      <button type="button" class="btn btn-danger btn-xs text-left " (click)="excluirUg(i, element)" *ngIf="!visualizar">
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: ['ug', 'responsavel', 'actions']"></tr>
              </table>
              <h5 class="table-no-data" [hidden]="dataSourceUG?.data?.length > 0">
                Nenhum registro encontrado
              </h5>
              <mat-paginator #TableOnePaginator="matPaginator" [pageSizeOptions]="[5]" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-12">
            <label for="validationCustom05" class="form-label">Assunto: </label>
            <div [ngClass]="{ 'border border-danger': submitted && incluirSolicitacao.controls['assunto'].invalid }">
              <angular-editor *ngIf="!visualizar; else assuntoVisualizar" #maxLengthEditor formControlName="assunto"
                appKeyboardlock [config]="editorConfig">
              </angular-editor>
              <ng-template #assuntoVisualizar>
                <textarea cols="30" rows="10" formControlName="assunto" class="form-control" disabled></textarea>
              </ng-template>

            </div>
            <div *ngIf="submitted &&  incluirSolicitacao.controls['assunto'].invalid" class="text-danger text-xs mt-1">
              <div>Campo obrigatório</div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-12">
            <label for="validationCustom06" class="form-label">Itens</label>
            <div class="mat-elevation-z1">
              <table mat-table [dataSource]="dataSourceItems" matSortDisableClear #tabela3="matSort" matSort
                 matSortDirection="asc">
                <tr mat-header-row *matHeaderRowDef="['numero', 'tituloItem','status', 'actions']" class="bg-light">
                </tr>

                <ng-container matColumnDef="numero">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Nº
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="!element?.numero">-</span>
                    <span *ngIf="element?.numero">{{ element.numero }}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="tituloItem">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Titulo do item
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.titulo }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Status
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="!element?.status">-</span>
                    <span *ngIf="element?.status?.codigo === 1" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 2" class="badge bg-secondary text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 3" class="badge bg-danger text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 4" class="badge bg-info text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 5" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 6" class="badge bg-primary text-xs">{{element?.status?.descricao}}</span>
                  </td>
                </ng-container>


                <ng-container cdkColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef style="max-width: 90px;">
                    <button type="button" class="btn btn-success btn-xs w-100"
                      (click)="modalIncluiritem('Incluir', false)" *ngIf="!visualizar">
                      Adicionar
                    </button>
                  </th>
                  <td mat-cell *matCellDef="let element; let i = index" class="block">
                    <div class="d-flex w-100 justify-content-center align-items-center">
                      <button type="button" class="btn btn-warning btn-xs text-left ml-1"
                        [disabled]="model?.status?.codigo !== 3" *ngIf="element?.status?.codigo === 1 || element?.status?.codigo === 2" (click)="cancelarItem(element.id)">
                        <i class="fas fa-ban"></i>
                      </button>
                      <button type="button" class="btn btn-primary btn-xs text-left ml-1" *ngIf="!visualizar"
                        (click)="modalIncluiritem('Editar', false, element)">
                        <i class="fas fa-pen"></i>
                      </button>
                      <button type="button" class="btn btn-info btn-xs text-left ml-1"
                        (click)="modalIncluiritem('Visualizar', true, element)">
                        <i class="far fas fa-eye"></i>
                      </button>
                      <button type="button" class="btn btn-danger btn-xs text-left ml-1" *ngIf="!visualizar" (click)="excluirItem(element.id)" [disabled]="model?.status?.codigo !== 1 && model?.status?.codigo !== 2">
                        <i class="far fa-trash-alt" ></i>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: ['numero', 'tituloItem', 'status','actions']"></tr>
              </table>
              <h5 class="table-no-data" [hidden]="dataSourceItems?.data?.length > 0">
                Nenhum registro encontrado
              </h5>
              <mat-paginator #TableThreePaginator="matPaginator" [pageSizeOptions]="[5]" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="d-flex w-100 justify-content-between align-items-center">

        <div class="list2">
          <button *ngIf="!visualizar && model?.status?.codigo === 2" type="button" class="btn btn-success btn-sm mr-1" [disabled]="model?.status?.codigo !==2 || dataSourceItems?.data?.length === 0" (click)="enviar()">
            <i class="fas fa-paper-plane"></i> Enviar
          </button>
          <button *ngIf="!visualizar && model?.status?.codigo === 1" type="button" class="btn btn-warning btn-sm mr-1" (click)="finalizar()" [disabled]="!model?.id || dataSourceItems?.data?.length === 0">
            <i class="fas fa-check"></i> Finalizar
          </button>
          <button *ngIf="!visualizar" type="button" form="myForm" class="btn btn-primary btn-sm mr-1 "
            (click)="salvar()">
            <i class="far fa-save"></i> Salvar
          </button>
          <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
            <i class="fas fa-times"></i> Fechar
          </button>
        </div>
      </div>
    </div>
  </div>

