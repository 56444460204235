<div id="modal-content-a-equipe">
  <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag
    cdkDragRootElement=".cdk-overlay-pane">
    <div class="card-header bg-light" cdkDragHandle>
      <h4 class="card-title mb-0">Alocar Equipe</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i
            class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-container p-3">
        <div class="row">
          <div class="form-group col-md-12">
            <label for="nome" class="mb-1">Tipo de Auditoria</label>
            <input type="text" id="nome" class="form-control form-control-sm"
              disabled
              [ngModel]="tipoAuditoria?.nome" />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6">
            <label for="dataInicio" class="mb-1">Data Ínicio</label>
            <input type="text" id="dataInicio" class="form-control
              form-control-sm suaClassDatePicker"
              bsDatepicker [(ngModel)]="model.dataInicio"
              [ngClass]="{ 'is-invalid': submit && !model.dataInicio }" />

            <div *ngIf="submit && !model.dataInicio" class="invalid-feedback">
              <div>Campo obrigatório</div>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="dataFim" class="mb-1">Data Fim</label>
            <input type="text" id="dataFim" class="form-control
              form-control-sm suaClassDatePicker"
              bsDatepicker [(ngModel)]="model.dataFim"
              [ngClass]="{ 'is-invalid': submit && !model.dataFim }" />

              <div *ngIf="submit && !model.dataFim" class="invalid-feedback">
              <div>Campo obrigatório</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mat-elevation-z1">
              <table mat-table [dataSource]="auditoresTipoAuditoriaDataSource" class="w-100"
                #tabela1="matSort" matSort matSortDisableClear>
                <tr mat-header-row *matHeaderRowDef="['selecao', 'nome']"
                  class="bg-light"></tr>

                <ng-container matColumnDef="nome">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Auditores da {{coordenacaoNome}} </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.nomeAuditor}} </td>
                </ng-container>

                <ng-container matColumnDef="selecao">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()"
                      color="primary"
                      (change)="element.selecionado= $event.checked"
                      [checked]="element.selecionado">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: ['selecao',
                  'nome'];"></tr>
              </table>
              <h5 [hidden]="!auditoresTipoAuditoriaSemDados" class="table-no-data">Nenhum
                Auditor para o Tipo de Auditoria</h5>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mat-elevation-z1">

              <table mat-table
                [dataSource]="auditoresOutrasCoordenacoesDataSource"
                #tabela2="matSort" matSort matSortDisableClear>
                <tr mat-header-row *matHeaderRowDef="['selecao', 'nome']"
                  class="bg-light"></tr>

                <ng-container matColumnDef="selecao">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()"
                      color="primary"
                      (change)="element.selecionado= $event.checked"
                      [checked]="element.selecionado">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="nome">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Auditores de outras Coordenações </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.nomeAuditor}} </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: ['selecao',
                  'nome'];"></tr>
              </table>
              <h5 [hidden]="!auditoresOutrasCoordenacoesSemDados"
                class="table-no-data">Nenhum Auditor de outras Coordenações</h5>
            </div>
          </div>

        </div>
      </div>
      <div class="card-footer">
        <button type="button" class="btn btn-primary btn-sm mr-1"
          (click)="concluir()">
          <i class="fas fa-check"></i> Salvar
        </button>

        <button type="button" class="btn btn-default btn-sm"
          (click)="cancelar()">
          <i class="fas fa-times"></i> Cancelar
        </button>
      </div>
    </div>
  </div>
