<!-- COMPONENTE ABAS -->

<div class="card-header d-flex p-0 flex-column">
	<ul class="nav nav-tabs ml-auto p-0">
		<li class="nav-item">
			<a
				class="nav-link"
				[ngClass]="{ active: aba === 0 }"
				(click)="mudarAba(0)"
				>Informações Básicas
			</a>
		</li>
    <li class="nav-item">
			<a
				class="nav-link"
				[ngClass]="{ active: aba === 7 }"
				(click)="mudarAba(7)"
				>Matriz de Planejamento
			</a>
		</li>
    <li class="nav-item">
			<a
				class="nav-link"
				[ngClass]="{ active: aba === 4 }"
				(click)="mudarAba(4)"
				>Custos e Recursos
			</a>
		</li>
		<li class="nav-item">
			<a
				class="nav-link"
				[ngClass]="{ active: aba === 1 }"
				(click)="mudarAba(1)"
				>Cronograma
			</a>
		</li>
		<li class="nav-item">
			<a
				class="nav-link"
				[ngClass]="{ active: aba === 2 }"
				(click)="mudarAba(2)"
				>Amostra
			</a>
		</li>
		<li class="nav-item">
			<a
				class="nav-link"
				[ngClass]="{ active: aba === 3 }"
				(click)="mudarAba(3)"
				>Solicitação Técnica
			</a>
		</li>

		<li class="nav-item">
			<a
				class="nav-link"
				[ngClass]="{ active: aba === 5 }"
				(click)="mudarAba(5)"
				>Relatoria
			</a>
		</li>
		<li class="nav-item">
			<a
				class="nav-link"
				[ngClass]="{ active: aba === 6 }"
				(click)="mudarAba(6)"
				>Linha do Tempo
			</a>
		</li>
	</ul>
</div>
<div>
	<!-- Aba Informações Básicas -->
	<div #abaDados [hidden]="aba !== 0">
		<div>
			<app-aba-informacoes-basicas
				[visualizar]="visualizar"
			></app-aba-informacoes-basicas>
		</div>
	</div>



	<!-- Aba cronograma -->
	<div #abaDados [hidden]="aba !== 1">
		<div class="card-body">
			<app-aba-cronograma [visualizar]="visualizar" [tipo]="'osa'"></app-aba-cronograma>
		</div>
	</div>

	<!-- Aba Amostra -->
	<div #abaDados [hidden]="aba !== 2">
		<div class="card-body">
			<app-aba-amostra [planejamentoId]="this.model?.planejamentoId"></app-aba-amostra>
		</div>
	</div>

	<!-- Aba Solicitacao Técnica -->
	<div #abaDados [hidden]="aba !== 3">
		<div class="card-body">
			<app-aba-solicitacao-tecnica [numeroOpa]="numeroOpa" [visualizar]="visualizar"[planejamentoId]="planejamentoId"></app-aba-solicitacao-tecnica>
		</div>
	</div>

	<!-- Aba Custos e Recursos -->
	<div #abaDados [hidden]="aba !== 4">
		<div class="card-body">
			<app-custos-recursos [visualizar]="visualizar"></app-custos-recursos>
		</div>
	</div>

	<!-- Relatoria -->
	<div #abaDados [hidden]="aba !== 5">
		<div class="card-body">
			<app-relatoria [visualizar]="visualizar" [tipoTrabalhoId]="id" [aba]="aba" [model]="model" [obterDados]="obter.bind(this)"></app-relatoria>
		</div>
	</div>

   <!-- Aba Matriz de planejamento -->
	<div #abaDados [hidden]="aba !== 7">
		<div>
      <app-aba-matriz-planejamento [visualizar]="visualizar" [id]="planejamentoId"></app-aba-matriz-planejamento>
		</div>
	</div>

  <!-- Aba Linha do tempo -->
  <div *ngIf="aba === 6" class="p-4">
    <app-linha-do-tempo [aba]="aba"></app-linha-do-tempo>
  </div>
</div>
