import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IImprimirResponsavelUnidadeGestoraPaiModel } from '../models/imprimir-responsavel-unidade-gestora.model';
import { IResponsavelModel } from '../models/responsaveis.model';
import { BaseService } from './base.service';
import { IListaResponsavelModel } from '../models/lista-responsaveis.model';
@Injectable({
  providedIn: 'root',
})
export class ResponsaveisService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(unidadeGestoraId: number, pesquisa: string,naturezaResponsabilidade: string,tipoAgente: string, ativo: boolean, dataInicio: Date = null, dataFim: Date = null, simplificado = false): Promise<IBaseModel<IResponsavelModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (naturezaResponsabilidade !== undefined) {
      params = params.append('naturezaResponsabilidade', naturezaResponsabilidade.toString());
    }

    if (tipoAgente !== undefined) {
      params = params.append('tipoAgente', tipoAgente.toString());
    }

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (dataInicio) {
      params = params.append('dataInicio', dataInicio.toDateString());
    }

    if (dataFim) {
      params = params.append('dataFim', dataFim.toDateString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<IResponsavelModel[]>>(`${this.apiBaseUrl}/responsavel/unidades-gestora/${unidadeGestoraId}`, { params })
      .toPromise();
  }

  public obterListaResponsaveis(unidadeGestoraId: number, pesquisa: string,naturezaResponsabilidade: string,tipoAgente: string, ativo: boolean, dataInicio: Date = null, dataFim: Date = null, simplificado = false): Promise<IBaseModel<IListaResponsavelModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (naturezaResponsabilidade !== undefined) {
      params = params.append('naturezaResponsabilidade', naturezaResponsabilidade.toString());
    }

    if (tipoAgente !== undefined) {
      params = params.append('tipoAgente', tipoAgente.toString());
    }

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (dataInicio) {
      params = params.append('dataInicio', dataInicio.toDateString());
    }

    if (dataFim) {
      params = params.append('dataFim', dataFim.toDateString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<IListaResponsavelModel[]>>(`${this.apiBaseUrl}/responsavel/lista-responsaveis/${unidadeGestoraId}`, { params })
      .toPromise();
  }

  public async ObterAgentePorCpf(unidadeGestoraId: number, cpf: string): Promise<IBaseModel<IResponsavelModel>> {
    return this.httpClient
      .get<IBaseModel<IResponsavelModel>>(`${this.apiBaseUrl}/responsavel/unidade-gestora/${unidadeGestoraId}/cpf/${cpf}`)
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IResponsavelModel>> {
    return this.httpClient
      .get<IBaseModel<IResponsavelModel>>(`${this.apiBaseUrl}/responsavel/${id}`)
      .toPromise();
  }

  public async obterPorResponsavelId(unidadeGestoraId: number, responsavelId: string): Promise<IBaseModel<IResponsavelModel>> {
    return this.httpClient
      .get<IBaseModel<IResponsavelModel>>(`${this.apiBaseUrl}/responsavel/${responsavelId}/unidade-gestora/${unidadeGestoraId}`)
      .toPromise();
  }

  public async inserir(data: IResponsavelModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/responsavel`, data)
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/responsavel/${id}/inativar`, {})
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/responsavel/${id}/reativar`, {})
      .toPromise();
  }

  public async atualizar(data: IResponsavelModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/responsavel/${data.id}`, data)
      .toPromise();
  }

  public async excluir(unidadeGestoraId: number, id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/responsavel/${id}/unidades-gestora/${unidadeGestoraId}/excluir`, {})
      .toPromise ();  
  }

  public imprimirResponsaveisUg(ids: number[], pesquisa: string, ativo: boolean, dataInicio: Date = null, dataFim: Date = null): Promise<IBaseModel<IImprimirResponsavelUnidadeGestoraPaiModel>> {
    let params = new HttpParams();

    ids.forEach(id => { if (id) { params = params.append('ids', id.toString() || ''); } });

    params = params.append('pesquisa', pesquisa || '');
    
    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (dataInicio) {
      params = params.append('dataInicio', dataInicio.toDateString());
    }

    if (dataFim) {
      params = params.append('dataFim', dataFim.toDateString());
    }

    return this.httpClient
      .get<IBaseModel<IImprimirResponsavelUnidadeGestoraPaiModel>>(`${this.apiBaseUrl}/responsavel/imprimir-responsaveis-por-ugs`, { params })
      .toPromise();
  }
}
