import { IOsaModel } from './../../../models/osa-model';
import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { OsaService } from 'src/app/services/osa.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { SharedService } from 'src/app/services/shared.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-osa-form',
  templateUrl: './osa-form.component.html',
  styleUrls: ['./osa-form.component.scss']
})
export class OsaFormComponent extends BaseFormComponent implements OnInit {

  public elaborar = true;
  public numeroOpa: string;
  public osa: any;
  public planejamentoId: number = null;
  public model: IOsaModel = {} as IOsaModel;
  public versao: boolean;
  public isFilha: boolean = false;

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private planejamentoService: PlanejamentoService,
    public matDialog: MatDialog,
    private osaService: OsaService,
    private sharedService: SharedService
  ) {
    super(route, toastr, router, localeService, matDialog);
    if (this.elaborar) {
      this.titulo = 'Elaborar OSA';
    } 
    if (this.router.url.includes("nova-versao")) {
      this.versao = true
    }
    this.obterPlanejamentoId()
  }

  ngOnInit(): void {
  }

  public mudarAba(e: number) { this.aba = e };

  public obterPlanejamentoId() {
    this.osaService.obterPorId(this.id).then(res => {
      if (res?.dados) {
        this.model = res.dados;


        if (this.visualizar) { 
          this.titulo = 'Visualizar OSA' + (this.model.numero ? ' - ' + this.model.numero : '');
        }
        else {
          this.titulo = 'Editar OSA'  + (this.model.numero ? ' - ' +  this.model.numero : '');
        }
        

        this.isFilha = this.model?.numero.length > 8;
        this.planejamentoId = res.dados.planejamentoId;
        this.visualizar = res.dados.status.codigo == 4;
        this.planejamentoService.obterPorId(this.planejamentoId);
      }
    })
  }

  public onSalvarOsa() {
    this.sharedService.sendSalvarEvent();
    this.router.navigate(['/osa']);
  }

  public enviarParaAprovacao() {
    this.osaService.enviarAprovacao(this.id).then(res => {
      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        this.router.navigate(['/osa']);
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    }).catch(err => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }

  public onBack() {
    window.history.back()
  }

}
