import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { ActivatedRoute, Router } from '@angular/router';
import * as cloneDeep from 'lodash/cloneDeep';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { IIndicadorCriterioModel } from 'src/app/models/indicador-criterio.model';
import { IIndicadorModel } from 'src/app/models/indicador.model';
import { CriterioService } from 'src/app/services/criterio.service';
import { IndicadorService } from 'src/app/services/indicador.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-indicador-form',
  templateUrl: './indicador-form.component.html',
  styleUrls: ['./indicador-form.component.scss']
})
export class IndicadorFormComponent extends BaseFormComponent implements OnInit {
  public model: IIndicadorModel = {} as IIndicadorModel;
  public modelIndicadorCriterio: IIndicadorCriterioModel = {} as IIndicadorCriterioModel;
  public indicadorCriteriosDataSource = new MatTableDataSource<IIndicadorCriterioModel>([]);
  public semDados = true;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private indicadorService: IndicadorService,
    private criterioService: CriterioService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Indicador';
    }
  }

  async ngOnInit() {

    try {
      if (!this.novoRegistro) {
        const res = await this.indicadorService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;

          if (this.visualizar) {
            this.titulo = 'Visualizar Indicador - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Indicador - ' + this.model.nome;
          }

          this.model.dataInicio = new Date(this.model.dataInicio);
          this.model.dataFim = new Date(this.model.dataFim);
          this.indicadorCriteriosDataSource = new MatTableDataSource<IIndicadorCriterioModel>(this.model.indicadorCriterio);
          this.indicadorCriteriosDataSource.sort = this.sort;
          this.indicadorCriteriosDataSource.sortingDataAccessor = (item, property) =>{
            switch(property){
              case 'nome': return item.criterio.nome;
              default: return item[property];
            }
          };
          this.indicadorCriteriosDataSource._updateChangeSubscription();
          this.semDados = this.indicadorCriteriosDataSource.filteredData.length === 0;
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
          this.router.navigate(['/indicadores']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
      this.router.navigate(['/indicadores']);
    } 
  }

  public async onSubmit(salvarEFechar: boolean = false) {
    if (this.form.invalid) {
      this.exibirMensagemAlerta("Formulário invalido");
      return;
    }
    this.submit = true;
   

    this.atualizarModel(this.form.value);
    this.model.indicadorCriterio = this.indicadorCriteriosDataSource.data;

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        res = await this.indicadorService.atualizar(this.model);
      } else {
        res = await this.indicadorService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        if (salvarEFechar) {
          this.router.navigate(['/indicadores']);
        } else
        if(this.novoRegistro){
          this.router.navigate([`/indicadores/${res.dados.id}`]);
          await this.ngOnInit();
        }
      } else {
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(validacoes, true);
      }
      
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    } 
  }

  public incluirNovoIndicadorCriterio() {
    if (this.indicadorCriteriosDataSource.data.find(i => i.criterioId === this.modelIndicadorCriterio.criterioId)) {
      this.exibirMensagemAlerta("Não é possivel salvar pois existe critérios duplicados");
    }
    else {
      this.indicadorCriteriosDataSource.data.push(cloneDeep(this.modelIndicadorCriterio));
      this.indicadorCriteriosDataSource._updateChangeSubscription();
      this.modelIndicadorCriterio = {} as IIndicadorCriterioModel;
      this.semDados = false;
    }
  }

  public excluirIndicadorCriterio(ind: number) {
    this.indicadorCriteriosDataSource.data.splice(ind, 1);
    this.indicadorCriteriosDataSource._updateChangeSubscription();
    this.semDados = this.indicadorCriteriosDataSource.filteredData.length === 0;
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.router.navigate(['/indicadores']);
  }

  public async exibirModalCriterio() {
    try {
      const res = await this.criterioService.obter('', true);
      if (res.sucesso) {
        const modal = this.exibirModal('Selecionar Critério', res.dados.filter(_ => !this.indicadorCriteriosDataSource.data.find(i => i.criterioId === _.id)).map(c => ({ ...c, descricaoTipoEntrada: c.tipoEntrada.descricao })), false, [
          {
            columnDef: 'selecao',
            header: ''
          },
          {
            columnDef: 'descricaoTipoEntrada',
            header: 'Tipo de Entrada'
          },
          {
            columnDef: 'nome',
            header: 'Nome'
          }]);
        modal.afterClosed().subscribe(data => {
          if (data) {
            this.modelIndicadorCriterio.criterio = data;
            this.modelIndicadorCriterio.criterioId = data.id;
          }
        }
        );
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }
}
