<div class="card-header bg-light">
  <h4 class="card-title mb-0">
    <i class="far fa-file"></i>
    Visualizar Resposta
  </h4>
</div>
<div class="p-2 wrapper">
  <div class="row">
    <div class="col-md-6 mb-2">
      <label class="form-label">Nº do Item</label>
      <input type="text" class="form-control form-control-sm" disabled placeholder="-"  [ngModelOptions]="{standalone: true}" [(ngModel)]="model.numero"/>
    </div>
    <div class="col-md-6 mb-2">
      <label class="form-label">Nº da Solicitação Técnica</label>
      <input type="text" class="form-control form-control-sm" disabled [(ngModel)]="numeroSolicitacao" [ngModelOptions]="{standalone: true}"  placeholder="-" />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mb-2">
      <label class="form-label">Descrição do Item</label>
      <textarea type="text" class="form-control" rows="5" disabled [(ngModel)]="model.descricao" placeholder="-"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="mat-elevation-z4">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 w-100 " #tabelaItemSort="matSort" matSort matSortDisableClear>
          <tr mat-header-row *matHeaderRowDef="['arquivo']" class="bg-light"></tr>
          <ng-container matColumnDef="arquivo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Arquivos do Item</th>
            <td mat-cell *matCellDef="let element">
              <small (click)="Download(element)" class="download">{{ element?.nomeArquivo }}</small>
            </td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: ['arquivo']"></tr>
        </table>
        <h5 [hidden]="dataSource?.data?.length > 0" class="table-no-data">
          Nenhum registro encontrado
        </h5>
        <mat-paginator #tabelaItemPaginator ="matPaginator"  [pageSizeOptions]="[5]" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2">
      <label class="form-label">Descrição da Resposta</label>
      <textarea [ngModel]="resposta?.respostaItemSolicitacaoTecnica?.descricao" type="text" class="form-control" rows="5" readonly placeholder="-"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="mat-elevation-z4">
        <table mat-table [dataSource]="dataSourceResposta" class="mat-elevation-z1 w-100 "  #tabelaRespostaSort="matSort" matSort matSortDisableClear>
          <tr mat-header-row *matHeaderRowDef="['arquivo']" class="bg-light"></tr>
          <ng-container matColumnDef="arquivo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Arquivos da Resposta</th>
            <td mat-cell *matCellDef="let element">
              <small (click)="DownloadResposta(element)" class="download">{{ element?.nomeArquivo }}</small>
            </td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: ['arquivo']"></tr>
        </table>
        <h5 [hidden]="dataSourceResposta?.data?.length > 0" class="table-no-data">
          Nenhum registro encontrado
        </h5>
        <mat-paginator #tabelaRespostaPaginator ="matPaginator"  [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
<div class="card-footer text-right">
  <button type="button" class="btn btn-default btn-sm" (click)="fechar()">
    <i class="fas fa-times"></i> Fechar
  </button>
</div>
