export enum EQuestionarioUGSituacao {
    EmElaboracao = 1,
    Pendente = 2,
    Avocado = 3,
    EmValidacao = 4,
    EmReplica = 5,
    Expirado = 6,
    Finalizado = 7,
    EmPreenchimento = 8,
    Enviado = 9,
    Contestado = 10
  }

  export const EQuestionarioUGSituacaoDescricao = {
    [EQuestionarioUGSituacao.EmElaboracao]: 'Em Elaboração',
    [EQuestionarioUGSituacao.Pendente]: 'Pendente',
    [EQuestionarioUGSituacao.Avocado]: 'Avocado',
    [EQuestionarioUGSituacao.EmValidacao]: 'Em validação',
    [EQuestionarioUGSituacao.EmReplica]: 'Em réplica',
    [EQuestionarioUGSituacao.Expirado]: 'Expirado',
    [EQuestionarioUGSituacao.Finalizado]: 'Finalizado',
    [EQuestionarioUGSituacao.EmPreenchimento]: 'Em preenchimento',
    [EQuestionarioUGSituacao.Enviado]: 'Enviado',
    [EQuestionarioUGSituacao.Contestado]: 'Contestado'
  };
  