<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item active">Planejamento</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i class="fas fa-table pr-2"></i>
              Planejamento
            </h3>
          </div>

          <div class="card-body p-2">
            <div class="mat-elevation-z4">
              <mat-toolbar class="table-header">
                <mat-toolbar-row>

                  <button type="button" class="btn btn-success btn-sm mr-1"
                    [disabled]="!selectedModel || selectedModel.status.codigo !== 1"  [hidden]="!permissoes.planejar" (click)="planejar(selectedModel.id)">
                    <i class="far fas fa-pen"></i> Planejar
                  </button>
                  <button type="button" class="btn btn-info btn-sm mr-1"  [hidden]="!permissoes.visualizar"
                    [disabled]="!selectedModel" (click)="visualizar(selectedModel.id)">
                    <i class="far fas fa-eye"></i> Visualizar
                  </button>
                  <button type="button" class="btn btn-info btn-sm mr-1"  [hidden]="!permissoes.imprimir"
                    [disabled]="!selectedModel" (click)="imprimir(selectedModel.id)">
                    <i class=" fas fa-print"></i> Imprimir
                  </button>

                  <span class="fill-remaining-space"></span>
                  <div class="input-group input-group-sm" style="width: 250px;">
                    <input type="text" name="table_search" class="form-control float-right" (keyup.enter)="buscar()"
                      placeholder="Pesquisar por planejamento" [(ngModel)]="searchNome" />

                    <div class="input-group-append">
                      <button type="button" class="btn btn-info" (click)="buscar()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>

                  <button class="btn  btn-tool btn-default btn-sm " type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Filtro avançado <i class="fas fa-filter"></i>
                  </button>

                </mat-toolbar-row>
              </mat-toolbar>

              <div class="collapse card card-outline card-lightblue p-2 m-2" id="collapseExample">
                <form [formGroup]="form" (ngSubmit)="pesquisar()">
                  <div class="row">

                  <div class="col-md-3 form-group">
                    <label for="selectOrigem" class="mb-1">Numero OPA</label>
                    <input type="text" id="numeroOpa" formControlName="numeroOpa" class="form-control form-control-sm"/>
                  </div>

                  <div class="col-md-3 form-group">
                    <label for="selectTipoAuditoria" class="mb-1">Origem da auditoria</label>
                    <ng-select formControlName="origemAuditoria">
                    <ng-option *ngFor="let element of origem" [value]="element.codigo" >{{ element.descricao }}</ng-option>
                  </ng-select>
                  </div>

                  <div class="col-md-3 form-group">
                    <label for="selectTipoAuditoria" class="mb-1">Tipo de auditoria</label>
                    <ng-select formControlName="tiposAuditoriaId">
                    <ng-option *ngFor="let element of tiposAuditoria" [value]="element.id">{{ element.nome }}</ng-option>
                  </ng-select>
                  </div>

                  <div class="col-md-3 form-group">
                    <label for="selectTipoAuditoria" class="mb-1">Órgão a ser auditado</label>
                    <ng-select formControlName="unidadeGestora">
                      <ng-option *ngFor="let unidade of unidadesGestoras" [value]="unidade.id">{{unidade.sigla}}</ng-option>
                    </ng-select>
                  </div>

                  <div class="col-md-3 form-group">
                    <label for="txtDataFinal" class="mb-1">Data de Elaboração</label>
                    <div class="input-group input-group-sm" style="width: 100%;">
                      <input type="date" id="txtDataFinal" placeholder="Datepicker" bsdatepicker=""
                        class="form-control ng-pristine ng-valid ng-touched" formControlName="dataElaboracao">
                    </div>
                  </div>


                  <div class="col-md-3 form-group">
                    <label for="txtDataInicial" class="mb-1">Data inicio</label>
                    <div class="input-group input-group-sm" style="width: 100%;">
                      <input type="date" id="txtDataInicial" placeholder="Datepicker" bsdatepicker=""
                        class="form-control ng-pristine ng-valid ng-touched" formControlName="dataInicio">
                    </div>
                    </div>

                    <div class="col-md-3 form-group">
                      <label for="txtDataFinal" class="mb-1">Data fim</label>
                      <div class="input-group input-group-sm" style="width: 100%;">
                        <input type="date" id="txtDataFinal" placeholder="Datepicker" bsdatepicker=""
                          class="form-control ng-pristine ng-valid ng-touched" formControlName="dataFim">
                      </div>
                    </div>

                    <div class="col-md-3 form-group">
                      <label for="objeto" class="mb-1">Objeto</label>
                      <input type="text" id="objeto" formControlName="objeto" class="form-control form-control-sm"/>
                    </div>
                  <div class="col-md-3 form-group">
                    <label for="selectTipoAuditoria" class="mb-1">Subtipo de auditoria</label>
                    <ng-select formControlName="subtipoAuditoria">
                      <ng-option *ngFor="let item of subtiposAuditoria" [value]="item.codigo" ><div [innerHtml]="item.nome"></div></ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-3 form-group">
                    <label for="selectTipoAuditoria" class="mb-1">Equipe de auditoria</label>
                    <ng-select formControlName="auditor">
                      <ng-option *ngFor="let auditor of coordenadores" [value]="auditor.auditorId">{{auditor.nomeAuditor}}</ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-3 form-group">
                    <label for="selectTipoAuditoria" class="mb-1">Coordenador da auditoria</label>
                    <ng-select formControlName="coordenador">
                      <ng-option *ngFor="let auditor of coordenadores" [value]="auditor.auditorId">{{auditor.nomeAuditor}}</ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-3 form-group">
                    <label for="selectTipoAuditoria" class="mb-1">Status</label>
                    <ng-select formControlName="status">
                      <ng-option *ngFor="let status of status" [value]="status.codigo" >{{ status.descricao }}</ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-3 form-group">
                    <label for="selectTipoAuditoria" class="mb-1">Situação</label>
                    <ng-select formControlName="situacoes">
                      <ng-option *ngFor="let situacao of situacoes" [value]="situacao.codigo" >{{ situacao.descricao }}</ng-option>
                    </ng-select>
                  </div>
                  </div>
                  <mat-toolbar>
                    <mat-toolbar-row
                      style="padding: 0px;margin: 10px 0;justify-content: flex-end;">
                      <button
                        type="button"
                        class="btn btn-success btn-sm mr-3"
                        (click)="pesquisar()"
                      >
                        <i class="fas fa-search"></i> Pesquisar
                      </button>
                    </mat-toolbar-row>
                  </mat-toolbar>
                </form>
              </div>

              <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear
                class="mat-elevation-z8" matSortActive="id" matSortDirection="asc" [hidden]="semDados">
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                <ng-container matColumnDef="selecao">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                      (change)="selectedModel = ($event.checked ? element : null)"
                      [checked]="selectedModel?.id === element.id">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="numero">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº OPA </th>
                  <td mat-cell *matCellDef="let element"> {{element?.numero}} </td>
                </ng-container>

                <ng-container matColumnDef="orgaoAuditado">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>UG Auditada </th>
                  <td mat-cell *matCellDef="let element"> {{element?.orgaoAuditado?.sigla}} </td>
                </ng-container>

                <ng-container matColumnDef="origem">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Origem </th>
                  <td mat-cell *matCellDef="let element"> {{element?.origem?.descricao}} </td>
                </ng-container>

                <ng-container matColumnDef="ano">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ano </th>
                  <td mat-cell *matCellDef="let element"> {{element?.ano}} </td>
                </ng-container>

                <ng-container matColumnDef="dataInicio">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Inicio </th>
                  <td mat-cell *matCellDef="let element"> {{element?.dataInicio | date:'dd/MM/yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="dataFim">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Fim </th>
                  <td mat-cell *matCellDef="let element"> {{element?.dataFim | date:'dd/MM/yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.status?.codigo === 1" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 2" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 3" class="badge bg-primary text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 4" class="badge bg-primary text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 5" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 6" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 7" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 8" class="badge bg-danger text-xs">{{element?.status?.descricao}}</span>
                    <span *ngIf="element?.status?.codigo === 9" class="badge bg-info text-xs">{{element?.status?.descricao}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="ativo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Situação </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.ativo" class="badge bg-success text-xs">Ativo</span>
                    <span *ngIf="!element?.ativo" class="badge bg-gray-dark text-xs">Inativo</span>
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selecionar(row)" class="clickable"
                  [ngClass]="{'bg-light': selectedModel?.id === row.id}"></tr>
              </table>
              <h5 [hidden]="!semDados" class="table-no-data">Sem dados a exibir</h5>
                <mat-paginator
                #TableOnePaginator="matPaginator"
                [pageSizeOptions]="[15, 30, 50]"
                showFirstLastButtons
                [length]="totalItens"
                (page)="selectedModel = null; pageEvent = pageChanged($event)">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
