<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item active">Avaliação do Tipo de Trabalho</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i class="fas fa-table pr-2"></i>
              Avaliação do Tipo de Trabalho
            </h3>
          </div>

          <div class="card-body p-2">
            <div class="mat-elevation-z4">
              <mat-toolbar class="table-header">
                <mat-toolbar-row>
                  <button type="button" class="btn btn-success btn-sm mr-1"
                    [disabled]="!selectedModel || selectedModel?.situacao?.codigo === 4"
                    [hidden]="!permissoes.relatoria"
                    (click)="relatoria(selectedModel.id)">
                    <i class="fas fa-file-invoice"></i> Relatoria
                  </button>
                  <button
                    [disabled]="!selectedModel || selectedModel?.situacao?.codigo === 4"
                    type="button"
                    [hidden]="!permissoes.avaliar"
                    class="btn btn-success btn-sm mr-1"
                    (click)="modalAvaliar()">
                    <i class="far fas fa-check"></i> Avaliar
                  </button>
                  <button type="button" class="btn btn-info btn-sm mr-1"
                    [disabled]="!selectedModel"
                    [hidden]="!permissoes.visualizar"
                    (click)="linhaTempo(selectedModel.id)">
                    <i class="far fas fa-eye"></i> Visualizar
                  </button>
                  <span class="fill-remaining-space"></span>
                  <div class="input-group input-group-sm mr-1" style="width:
                    250px;">
                    <input type="text" name="table_search" class="form-control
                      float-right" (keyup.enter)="buscar()"
                      placeholder="Pesquisar"
                      [(ngModel)]="searchNome" />

                    <div class="input-group-append">
                      <button type="button" class="btn btn-info"
                        (click)="buscar()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  <div class="btn-group mr-1">
                    <button type="button" data-toggle="dropdown" class="btn
                      btn-tool btn-default dropdown-toggle">
                      Tipo de trabalho
                      <i class="fas fa-filter"></i>
                    </button>
                    <div role="menu" class="dropdown-menu dropdown-menu-right
                      dropdown-menu-checkbox">

                      <a (click)="searchTipoDocumento= null; buscar();"
                        class="dropdown-item"><i [ngClass]="searchTipoDocumento
                          === null
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'"></i>Todos</a>

                      <a (click)="searchTipoDocumento= 1; buscar();"
                        class="dropdown-item"><i [ngClass]="searchTipoDocumento
                          === 1
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'"></i>PEAC</a>

                      <a (click)="searchTipoDocumento= 2; buscar();"
                        class="dropdown-item"><i [ngClass]="searchTipoDocumento
                          === 2
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'"></i>PAAC</a>

                      <a (click)="searchTipoDocumento= 3; buscar();"
                        class="dropdown-item"><i [ngClass]="searchTipoDocumento
                          === 3
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'"></i>OPA</a>

                      <a (click)="searchTipoDocumento= 4; buscar();"
                        class="dropdown-item"><i [ngClass]="searchTipoDocumento
                          === 4
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'"></i>OSA</a>

                      <a (click)="searchTipoDocumento= 7; buscar();"
                        class="dropdown-item"><i [ngClass]="searchTipoDocumento
                          === 7
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'"></i>Planejamento</a>

                    </div>
                  </div>
                  <div class="btn-group">
                    <button type="button" data-toggle="dropdown" class="btn
                      btn-tool btn-default dropdown-toggle">
                      Situação
                      <i class="fas fa-filter"></i>
                    </button>
                    <div role="menu" class="dropdown-menu dropdown-menu-right
                      dropdown-menu-checkbox">

                      <a (click)="searchSituacaoDocumento= null; buscar();"
                        class="dropdown-item"><i [ngClass]="searchSituacaoDocumento
                          === null
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'"></i>Todos</a>

                      <a (click)="searchSituacaoDocumento= 2; buscar();"
                        class="dropdown-item"><i [ngClass]="searchSituacaoDocumento
                          === 2
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'"></i>Em aprovação</a>

                      <a (click)="searchSituacaoDocumento= 4; buscar();"
                        class="dropdown-item"><i [ngClass]="searchSituacaoDocumento
                          === 4
                          ? 'fas fa-check-circle'
                          : 'far fa-circle'"></i>Homologados</a>

                    </div>
                  </div>
                </mat-toolbar-row>
              </mat-toolbar>
              <table mat-table [dataSource]="dataSource" class="w-100" matSort
                matSortDisableClear matSortActive="id"
                matSortDirection="asc" class="mat-elevation-z8"
                [hidden]="semDados">
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <ng-container matColumnDef="selecao">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()"
                      color="primary"
                      (change)="selectedModel=($event.checked ? element : null)"
                      [checked]="((selectedModel?.nome === element.nome) && (selectedModel?.id === element.id))"
                     >
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="tipoTrabalho">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de
                    Trabalho </th>
                  <td mat-cell *matCellDef="let element">
                    {{element?.tipoDocumentoAprovacao?.descricao}} </td>
                </ng-container>

                <ng-container matColumnDef="nome">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome
                  </th>
                  <td mat-cell *matCellDef="let element"> {{element?.nome}} </td>
                </ng-container>

                <ng-container matColumnDef="situacao">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Situação </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.situacao?.nome === 'Homologado'"
                      class="badge bg-primary text-xs">Homologado</span>
                    <span *ngIf="element.situacao?.nome === 'EmAprovacao'"
                      class="badge bg-warning text-xs">Em
                      Aprovação</span>
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                  (click)="selecionar(row)" class="clickable"
                  [ngClass]="{'bg-light': ((selectedModel?.nome === row.nome) && (selectedModel?.id === row.id))}"></tr>
              </table>
              <h5 [hidden]="!semDados" class="table-no-data">Nenhum registro
                encontrado.</h5>
              <mat-paginator 
                #TableOnePaginator="matPaginator"
                [pageSizeOptions]="[15, 30, 50]" 
                showFirstLastButtons 
                [length]="totalItens"
                (page)="selectedModel = null; pageEvent = pageChanged($event)">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
