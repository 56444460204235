<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/indicadores']">Indicadores</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>
          </div>

          <form [formGroup]="form" #f="ngForm" (keydown.enter)="$event.preventDefault()">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="nome" class="mb-1">Nome</label>
                  <input [attr.disabled]="visualizar ? true : null" type="text" id="nome" class="form-control form-control-sm" formControlName="nome"
                    maxlength="100" [ngClass]="{ 'is-invalid': f.submitted && form.controls['nome'].invalid }" />
                  <div *ngIf="f.submitted && form.controls['nome'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['nome'].errors.required">Campo obrigatório</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-12">
                  <label for="inputDescricao" class="mb-1">Descrição</label>
                  <textarea [attr.disabled]="visualizar ? true : null" rows="3" id="inputDescricao" class="form-control form-control-sm"
                    formControlName="descricao" maxlength="500" [ngClass]="{ 'is-invalid': f.submitted &&
                    form.controls['descricao'].invalid }">
                  </textarea>
                  <div *ngIf="f.submitted && form.controls['descricao'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['descricao'].errors.required">Campo obrigatório</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div *ngIf="visualizar == false" class="form-group col-md-8">
                  <label for="inputCriterio" class="mb-1">Critério</label>
                  <div class="input-group input-group-sm" style="width: 100%;">
                    <input type="text" id="inputCriterio" class="form-control form-control-sm" readonly
                      [ngModel]="modelIndicadorCriterio.criterio?.nome" [ngModelOptions]="{standalone: true}" />
                    <div class=" input-group-append">
                      <button  [hidden]="visualizar" type="button" class="btn btn-info" (click)="exibirModalCriterio()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div *ngIf="visualizar == false" class="form-group col-md-4">
                  <label for="inputPeso" class="mb-1">Peso do Critério</label>
                  <div class="input-group input-group-sm" style="width: 100%;">
                    <select  [attr.disabled]="visualizar ? true : null" id="inputPeso" [(ngModel)]="modelIndicadorCriterio.peso"
                      [ngModelOptions]="{standalone: true}" class="form-control form-control-sm">
                      <option *ngFor="let number of [1,2,3,4,5]" [value]="number">
                        {{number}}</option>
                    </select>
                    <div class="input-group-append">
                      <button [hidden]="visualizar" type="button" class="btn btn-success ml-1" (click)="incluirNovoIndicadorCriterio()"
                        [disabled]="!(modelIndicadorCriterio.criterioId && modelIndicadorCriterio.peso)">
                        <i class="fas fa-plus"></i> Adicionar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="mat-elevation-z1">
                    <table mat-table [dataSource]="indicadorCriteriosDataSource" matSort>
                      <tr mat-header-row *matHeaderRowDef="['nome', 'peso', 'actions']" class="bg-light"></tr>

                      <ng-container matColumnDef="nome">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome </th>
                        <td mat-cell *matCellDef="let element"> {{ element.criterio.nome }}</td>
                      </ng-container>

                      <ng-container matColumnDef="peso">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Peso </th>
                        <td mat-cell *matCellDef="let element"> {{element.peso}} </td>
                      </ng-container>

                      <ng-container cdkColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element; let i = index" class="block">
                          <div class="btn-group">
                            <button  [hidden]="visualizar" type="button" class="btn btn-danger btn-xs text-left"
                              (click)="excluirIndicadorCriterio(i)">
                              <i class="far fa-trash-alt"></i> </button>
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-row *matRowDef="let row; columns: ['nome', 'peso', 'actions'];"></tr>
                    </table>
                    <h5 [hidden]="!semDados" class="table-no-data">Nenhum critério cadastrado</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="btn-group" [hidden]="visualizar">
                <button class="btn btn-primary btn-sm mr" (click)="onSubmit(false)">
                    <i class="far fa-save"></i> Salvar
                </button>
                <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                <div class="dropdown-menu" role="menu" >
                    <a class="dropdown-item" (click)="onSubmit(false)">Salvar</a>
                    <a class="dropdown-item" (click)="onSubmit(true)">Salvar e fechar</a>
                </div>
              </div>

              <button [hidden]="visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Cancelar
              </button>

              <button [hidden]="!visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Sair 
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
