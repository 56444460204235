import { ModalJustificativaComponent } from './../../../shared/components/modal-justificativa/modal-justificativa.component';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ToastrService } from "ngx-toastr";
import { IItemSolicitacaoTecnicaModel } from "src/app/models/item-solicitacao-tecnica.model";
import { ISolicitacaoTecnicaModel, ISolicitacaoTecnicaUnidadeGestoraModel } from "src/app/models/solicitacao-tecnica.model";
import { SolicitacaoTecnicaService } from "src/app/services/solicitacao-tecnica.service";
import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import { ModalItemSolicitacaoComponent } from "../modal-item-solicitacao/modal-item-solicitacao.component";
import Swal from 'sweetalert2';

const sortingDataAccessor = (item, property) => {
	switch (property) {
		case "numero":
			return item?.numero;
		case "titulo":
			return item?.titulo;
		case "descricao":
			return item?.descricao;
		case "responsavelDelegado":
			return item?.nomeAuditor;
		case "status":
			return item?.status?.descricao;
		case "ug":
			return item?.siglaUg;
		case "responsavel":
			return item?.nomeAuditor;

		default:
			return item[property];
	}
}
@Component({
	selector: "app-solicitacao-tecnica-form",
	templateUrl: "./solicitacao-tecnica-form.component.html",
})
export class SolicitacaoTecnicaFormComponent
	extends BaseFormComponent
	implements OnInit, AfterViewInit
{
	public tituloPagina = "";
	public responder = false;
  public semDadosItens = true;
	public selectedModel: any;
	public pageEvent: any;
  public itensSolicitacaoDataSource =
		new MatTableDataSource<IItemSolicitacaoTecnicaModel>([]);
  public dataSourceUG =
  new MatTableDataSource<ISolicitacaoTecnicaUnidadeGestoraModel>([]);

	public model: ISolicitacaoTecnicaModel = {} as ISolicitacaoTecnicaModel;
	public displayedColumnsItems: string[] = [
		"numero",
		"titulo",
		"descricao",
    "responsavelDelegado",
		"status",
		"actions",
	];

	constructor(
		route: ActivatedRoute,
		toastr: ToastrService,
		router: Router,
		localeService: BsLocaleService,
		public matDialog: MatDialog,
		private solicicataoTecnicaService: SolicitacaoTecnicaService
	) {
		super(route, toastr, router, localeService, matDialog);
	}


  @ViewChild("TableOnePaginator", { static: true })
	tableOnePaginator: MatPaginator;
	@ViewChild("tabela1", { static: true }) tabela1: MatSort;

  @ViewChild("TableTwoPaginator", { static: true })
	tableTwoPaginator: MatPaginator;
	@ViewChild("tabela2", { static: true }) tabela2: MatSort;


	ngOnInit(): void {
		const path = this.route.snapshot.routeConfig.path?.toString();
		if (path?.endsWith("responder")) {
			this.tituloPagina = "Responder Solicitação Técnica";
			this.responder = true;
		}
		if (path?.endsWith("visualizar")) {
            this.visualizar = true
		}

		this.obterSolicitacaoTecnica();
	}

  ngAfterViewInit(): void {
    this.itensSolicitacaoDataSource.paginator = this.tableTwoPaginator;
    this.itensSolicitacaoDataSource.sortingDataAccessor = sortingDataAccessor;
    this.itensSolicitacaoDataSource.sort = this.tabela2

    this.dataSourceUG.paginator = this.tableOnePaginator;
    this.dataSourceUG.sort = this.tabela1;
    this.dataSourceUG.sortingDataAccessor = sortingDataAccessor;
  }

	public obterSolicitacaoTecnica() {
		this.solicicataoTecnicaService
			.obterPorId(this.id)
			.then((res) => {
				this.model = res.dados;
				if(this.visualizar)
				{
					this.tituloPagina = "Visualizar Solicitação Técnica - " + (this.model.numero ? this.model.numero : "");
				}
				this.itensSolicitacaoDataSource = new MatTableDataSource(
					res.dados.item
				);
        this.dataSourceUG.data = res.dados.solicitacaoTecnicaUgResponsavel

        this.itensSolicitacaoDataSource.paginator = this.tableTwoPaginator;
        this.itensSolicitacaoDataSource.sortingDataAccessor = sortingDataAccessor;
        this.itensSolicitacaoDataSource.sort = this.tabela2

        this.dataSourceUG.paginator = this.tableOnePaginator;
		    this.dataSourceUG.sort = this.tabela1;
		    this.dataSourceUG.sortingDataAccessor = sortingDataAccessor;

				this.semDadosItens =
					this.itensSolicitacaoDataSource.filteredData.length === 0;
			})
			.catch((err) => {
				this.exibirMensagemErro(err.mensagem?.descricao);
			});
	}

	public async visualizarItem(id: number) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component-resposta";
		dialogConfig.width = "60%";
		dialogConfig.height = "80%";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = false;
		dialogConfig.data = {
			titulo: "Visualizar Item de Solicitação",
			id: id,
			solicitacaoTecnicaId: this.id,
      numeroSolicitacao: this.model?.numero,
      visualizar: true
		};

		const modal = this.matDialog.open(
			ModalItemSolicitacaoComponent,
			dialogConfig
		);
	}

	public responderItem(id: number) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component-resposta";
		dialogConfig.width = "60%";
		dialogConfig.height = "90vh";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		dialogConfig.data = {
			titulo: "Visualizar Item de Solicitação",
			id: id,
			solicitacaoTecnicaId: this.id,
			responder: this.responder,
      numeroSolicitacao: this.model?.numero,
      visualizar: false
		};

		const modal = this.matDialog.open(
			ModalItemSolicitacaoComponent,
			dialogConfig
		);
    modal.afterClosed().subscribe((data) => {
     this.obterSolicitacaoTecnica();
    });
	}

  	public prorrogarPrazo(){
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component-prorrogar";
		dialogConfig.width = '45vw';
		dialogConfig.maxHeight = '85vh';
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		dialogConfig.data = { prorrogarPrazo: true };

		const modal = this.matDialog.open(ModalJustificativaComponent, dialogConfig);
		
		modal.afterClosed().subscribe((data) => {
			if (data) {
				this.processarAfterClosedModalProgrrogacaoPrazo(data);
			}
		});
  	}

	private processarAfterClosedModalProgrrogacaoPrazo(data: any) {
		this.solicicataoTecnicaService.prorrogarPrazo(this.id, data).then((res) => {
			if(res.sucesso) {
				this.mostraMensagemSucesso(res.mensagem.descricao);
				this.router.navigate(["/solicitacao-tecnica"]);
			} else {
				this.mostraMensagemAlerta(res.mensagem.descricao);				
			} 
		}).catch((err) => {
			this.mostraMensagemErro(err?.mensagem?.descricao);
		})
	}

	public pageChanged(e) {}

	public onBack() {
		this.router.navigate(["/solicitacao-tecnica"]);
	}

	private mostraMensagemSucesso(descricao: string): void {
		Swal.fire({
			toast: true,
			position: "top-end",
			icon: "success",
			text: descricao,
			showConfirmButton: false,
			timer: 5000,
			timerProgressBar: true,
		});	
	}

	private mostraMensagemAlerta(descricao: string): void {
		Swal.fire({
			toast: true,
			position: "top-end",
			icon: "warning",
			text: descricao,
			showConfirmButton: false,
			timer: 5000,
			timerProgressBar: true,
		});
	}

	private mostraMensagemErro(descricao: string): void {
		Swal.fire({
			toast: true,
			position: "top-end",
			icon: "error",
			text: descricao,
			showConfirmButton: false,
			timer: 5000,
			timerProgressBar: true,
		});
	}
}
