import { SolicitacaoTecnicaService } from './../../../services/solicitacao-tecnica.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IArquivoModel } from 'src/app/models/arquivo.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-modal-arquivo',
  templateUrl: './modal-arquivo.component.html',
  styleUrls: ['./modal-arquivo.component.scss']
})
export class ModalArquivoComponent implements OnInit, OnChanges{

  public dataSource = new MatTableDataSource<any>();
  public semDados: boolean;
  public arquivo: File;
  public nomeArquivo: string = '';
  public conteudoArquivo: string;
  public model: IArquivoModel = {} as IArquivoModel;
  public numeroItem: number;
  public nSolicitacaoTecnica: number;

  public form = new FormGroup({
    arquivo: new FormControl('', Validators.required),
  });

  constructor(
    public matDialog: MatDialog,
    private dialogRef: MatDialogRef<ModalArquivoComponent>,
    private solicitacaoTencica: SolicitacaoTecnicaService,
    @Inject(MAT_DIALOG_DATA) public data
    ) {
      this.numeroItem = data?.numero;
      this.nSolicitacaoTecnica =  data?.nSolicitacaoTecnica
    }


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  ngOnInit(): void {
		this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public salvar() {
    const dados = {
      Id: this.data?.id,
      Arquivos: this.dataSource.data
    }as any;

  this.solicitacaoTencica
    .salvarAnexo(dados)
    .then((res) => {
      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        
        setTimeout(() => this.dialogRef.close(), 500);

      } else {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    });
  }

  public onFileChange(event) {
    
    if (event.target.files && event.target.files.length > 0) {
      const selectedFiles = <FileList>event.srcElement.files;      
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e: any) => {
          const base64 = "base64,";
          const base64full: any = reader.result;
          var item = {
            id: 0,
            nomeArquivo: file.name,
            name: file.name,
            Arquivo: base64full.substring(base64full.indexOf(base64) + base64.length)
          }; 
          this.dataSource.data.push(item)
          this.dataSource.paginator = this.paginator;
        };
      }      
    }
  }


  public excluirArquivo(i: number){
    if(this.dataSource.data[i].id > 0){
      Swal.fire({
        title: "Excluir Registro",
        text: "Tem certeza que deseja excluir esse Registro?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((res) => {
        if (res.value) {
          this.solicitacaoTencica.excluirAnexo(this.data?.solicitacaoId, this.data?.id, this.dataSource.data[i].id)
          .then((res) => {
            if (res.sucesso) {
               Swal.fire({
                 toast: true,
                 position: "top-end",
                 icon: "success",
                 text: res.mensagem.descricao,
                 showConfirmButton: false,
                 timer: 5000,
                 timerProgressBar: true,
               });
               if (this.dataSource.data[i]) this.dataSource.data.splice(i, 1)
               this.dataSource._updateChangeSubscription();
             } else {
               Swal.fire({
                 toast: true,
                 position: "top-end",
                 icon: "warning",
                 text: res.mensagem.descricao,
                 showConfirmButton: false,
                 timer: 5000,
                 timerProgressBar: true,
               });
             }
           })
           .catch((err) => {
             Swal.fire({
               toast: true,
               position: "top-end",
               icon: "error",
               text: err.mensagem.descricao,
               showConfirmButton: false,
               timer: 5000,
               timerProgressBar: true,
             });
           });
        }})
    }else{
      if (this.dataSource.data[i]) this.dataSource.data.splice(i, 1)
      this.dataSource._updateChangeSubscription();
    }

  }

  public getBase64(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result)
      };
      reader.onerror = function (error) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: "Ocorreu um erro ao selecionar o arquivo" + error.toString(),
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        })
      };
    })
  }

  public cancelar() {
    this.dialogRef.close()
  }

  public Download(dataurl, filename) {
    const link = document.createElement("a");
    link.href = dataurl;
    link.download = filename;
    link.click();
  }
}
