<div>
  <div class="relatorio content">
      <div class="card card-outline" *ngFor="let subTipoAuditoria of lista">
        <div class="card-body">
          <hr />
          <div *ngFor="let subTipoMatriz of subTipoAuditoria.subtipoAuditoriaMatrizPlanejamento">
            <section id="indicesECriterios">
              <h4>{{subTipoAuditoria.nome}} - {{subTipoMatriz.matrizPlanejamento.nome}}</h4>
              <div>
                <table>
                  <tr>
                    <th>QUESTÕES DE AUDITORIA</th>
                    <th>CLASSIFICAÇÃO DA QUESTÃO</th>
                    <th>INFORMAÇÕES REQUERIDAS / FONTES DE INFORMAÇÃO</th>
                    <th>LEGISLAÇÃO APLICÁVEL</th>
                    <th>POSSÍVEIS CONSTATAÇÕES</th>
                    <th>PROCEDIMENTOS</th>
                    <th>DETALHAMENTO DOS PROCEDIMENTOS</th>
                    <th>TÉCNICAS</th>
                  </tr>

                  <tr *ngFor="let auxQuestao of subTipoMatriz.matrizPlanejamento.matrizPlanejamentoQuestaoPlanejamento">
                    <td>
                      <p>{{ auxQuestao.questao.nome }}</p>
                      <p></p>
                    </td>
                    <td>
                      <p class="e2e-inner-html-bound" [innerHTML]="auxQuestao.questao.classificacao"></p>
                    </td>
                    <td>
                      <p class="e2e-inner-html-bound" [innerHTML]="auxQuestao.questao.descricao"></p>
                    </td>
                    <td>
                      <p class="e2e-inner-html-bound" [innerHTML]="auxQuestao.questao.legislacaoAplicavelCriterio"></p>
                    </td>
                    <td>
                      <p *ngFor="let auxConstatacao of auxQuestao.questao.questaoConstatacao">
                        {{auxConstatacao.constatacao.nome}}
                      </p>
                    </td>
                    <td>
                      <p *ngFor="let auxProcedimentoAuditoria of auxQuestao.questao.questaoProcedimentoAuditoria">
                        {{auxProcedimentoAuditoria.procedimentoAuditoria.nome}}
                      </p>
                    </td>
                    <td>
                      <p *ngFor="let auxProcedimentoAuditoria of auxQuestao.questao.questaoProcedimentoAuditoria">
                        <a class="e2e-inner-html-bound"
                          [innerHTML]="auxProcedimentoAuditoria.procedimentoAuditoria.detalhamentoProcedimento"></a>
                      </p>
                    </td>
                    <td>
                      <p *ngFor="let auxQuestaoProcedimentoAuditoria of auxQuestao.questao.questaoProcedimentoAuditoria">
                        <a
                          *ngFor="let auxTecnicaAuditoria of auxQuestaoProcedimentoAuditoria.procedimentoAuditoria.procedimentoTecnicaAuditoria">
                          <a class="e2e-inner-html-bound"
                            [innerHTML]="auxTecnicaAuditoria.tecnicaAuditoria.nome"></a><br />
                        </a>
                      </p>
                    </td>
                  </tr>

                </table>

              </div>
            </section>
          </div>
        </div>
      </div>
    <div class="card-footer no-print">
      <button type="button" class="btn btn-default btn-sm mr-1" (click)="cancelar()">
        <i class="fas fa-times"></i> Fechar
      </button>
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="print()">
        <i class="fas fa-print"></i> Imprimir
      </button>
    </div>
  </div>
</div>