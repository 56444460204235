import { PaginacaoModel } from './../models/paginacao.model';
import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { BaseService } from './base.service';
import { filterBy } from './auditoria.service';
import { IUnidadeGestoraMaturidadeGRModel } from '../models/unidade-gestora-maturidadeGR.model';
import { FiltroModel } from '../models/filtro.model';

@Injectable({
  providedIn: 'root',
})
export class UnidadeGestoraMaturidadeGRService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(filtro?: FiltroModel): Promise<IBaseModel<IUnidadeGestoraMaturidadeGRModel[]>> {
      return this.httpClient
      .get<IBaseModel<IUnidadeGestoraMaturidadeGRModel[]>>(filterBy(filtro, `${this.apiBaseUrl}/unidadegestoramaturidadegr`))
      .toPromise();
  }

  
}
