import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IFluxoAprovacaoModel } from '../models/fluxo-aprovacao.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class FluxoAprovacaoService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string): Promise<IBaseModel<IFluxoAprovacaoModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    return this.httpClient
      .get<IBaseModel<IFluxoAprovacaoModel[]>>(`${this.apiBaseUrl}/fluxoaprovacao`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IFluxoAprovacaoModel>> {
    return this.httpClient
      .get<IBaseModel<IFluxoAprovacaoModel>>(`${this.apiBaseUrl}/fluxoaprovacao/${id}`)
      .toPromise();
  }

  public async inserir(data: IFluxoAprovacaoModel): Promise<IBaseModel<IFluxoAprovacaoModel>> {
    return this.httpClient
      .post<IBaseModel<IFluxoAprovacaoModel>>(`${this.apiBaseUrl}/fluxoaprovacao`, data)
      .toPromise();
  }

  public async atualizar(data: IFluxoAprovacaoModel): Promise<IBaseModel<IFluxoAprovacaoModel>> {
    return this.httpClient
      .put<IBaseModel<IFluxoAprovacaoModel>>(`${this.apiBaseUrl}/fluxoaprovacao/${data.id}`, data)
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/fluxoaprovacao/${id}`, {})
      .toPromise();
  }
  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient.post<IBaseModel<void>>(`${this.apiBaseUrl}/fluxoaprovacao/${id}/reativar`, {}).toPromise();
  }
  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient.post<IBaseModel<void>>(`${this.apiBaseUrl}/fluxoaprovacao/${id}/inativar`, {}).toPromise();
  }
}
