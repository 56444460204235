<div class="card-body">
    <div class="row">
        <div class="col-md-12">
            <div class="mat-elevation-z1">
                <table
                    mat-table
                    [dataSource]="capacidadeDataSource"
                    #tabela2="matSort"
                    matSort
                    matSortDisableClear
                    class="w-100"
                    [hidden]="capacidadeSemDados"
                >
                    <ng-container
                        matColumnDef="siglaCoordenacao"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                            Coordenação
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            {{
                                element?.siglaCoordenacao
                            }}
                        </td>
                        <td
                            mat-footer-cell
                            *matFooterCellDef
                        >
                            Capacidade Total
                        </td>
                    </ng-container>

                    <ng-container 
                        matColumnDef="capacidadePea"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                            Capacidade Total PEAC
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            {{
                                element.capacidadePea
                            }}
                        </td>
                        <td
                            mat-footer-cell
                            *matFooterCellDef
                        >
                            {{
                                mostrarCapacidadeTotalPea
                            }}
                        </td>
                    </ng-container>

                    <ng-container
                        matColumnDef="capacidadePaa"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                            Capacidade Total PAAC
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            >  
                            <div style="display: flex; align-items: center;">
                                <span style="margin-right: 10px;">
                                    {{ element.capacidadePaa }}
                                </span>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-xs"
                                    (click)="descricaoPAAC(element)"
                                    style="margin-right: 10px;"
                                >
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                        <td
                            mat-footer-cell
                            *matFooterCellDef
                        >
                            {{ mostrarCapacidadeTotal }}
                        </td>
                    </ng-container>

                    <ng-container
                        matColumnDef="nomesAuditores"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                            Auditores
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            {{
                                element.nomesAuditores
                            }}
                        </td>
                        <td
                            mat-footer-cell
                            *matFooterCellDef
                        ></td>
                    </ng-container>

                    <ng-container
                        matColumnDef="actions"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                        ></th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            <button
                                type="button"
                                class="
                                    btn
                                    btn-primary
                                    btn-xs
                                "
                                (click)="
                                visualizarCoordenacao(element)"
                            >
                                <i
                                    class="fas fa-eye"
                                ></i>
                            </button>
                        </td>
                        <td
                            mat-footer-cell
                            *matFooterCellDef
                        ></td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="
                            columnsCapacidade
                        "
                        class="bg-light"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="
                            let row;
                            columns: columnsCapacidade
                        "
                    ></tr>
                    <tr
                        mat-footer-row
                        *matFooterRowDef="
                            columnsCapacidade
                        "
                    ></tr>
                </table>
                <h5
                    [hidden]="!capacidadeSemDados"
                    class="table-no-data"
                >
                    Sem capacidade de trabalho
                </h5>
            </div>
        </div>
    </div>
</div>