import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { TipoCriterioFormComponent } from 'src/app/pages/tipo-criterio-form/tipo-criterio-form.component';

@Injectable()
export class TipoCriterioGuard implements CanDeactivate<TipoCriterioFormComponent> {
  canDeactivate(component: TipoCriterioFormComponent): Observable<boolean> | boolean {
    if (component.novoRegistro) {
      if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    } else { return true; }
  }
}
