<div id="modal-content-a-equipe">
    <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <div class="card-header bg-light" cdkDragHandle>
            <h4 class="card-title mb-0">Inserir seção</h4>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="cancelar()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-container p-3">

                <div class="row">
                    <div class="form-group col-md-6 was-validated"
                        [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                        <label for="txtDescricao" class="mb-1">Nome</label>
                        <input 
                        required
                        class="form-control form-control-sm"
                        type="text" 
                        id="txtDescricao" [(ngModel)]="model.nome"/>
                        <div class="invalid-feedback">
                            Campo obrigatório
                        </div>
                    </div>
                </div>

            </div>
            <div class="card-footer">
                <button  type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
                    <i class="fas fa-check"></i> Inserir
                </button>

                <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
                    <i class="fas fa-times"></i> Cancelar
                </button>
            </div>
        </div>
    </div>
</div>