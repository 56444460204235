import { SolicitacaoTecnicaService } from './../../../../services/solicitacao-tecnica.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IPeaUnidadeGestoraModel } from 'src/app/models/pea-unidade-gestora.model';
import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';
import { ModalSelecionarUgComponent } from 'src/app/pages/pea-form/modal-selecionar-ug/modal-selecionar-ug.component';
import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { ModalCancelarComponent } from '../../modal-cancelar/modal-cancelar-component';
import { IAmostraModel } from 'src/app/models/amostra.model';
import { IAmostraUnidadeGestora } from 'src/app/models/amostra-unidade-gestoras.model';
import { AmostraService } from 'src/app/services/amostra.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inserir-amostra',
  templateUrl: './inserir-amostra.component.html',
  styleUrls: ['./inserir-amostra.component.scss']
})
export class InserirAmostraComponent implements OnInit {
  public id!: number;
  public validar!: boolean;
  public dados = {
    nome: '',
    descricao: '',
    unidadeGestora: [] as IUnidadeGestoraModel[],
    arrayFiles: [],
  }

  public model = {} as IAmostraModel;
  public tipoAmostra!: string;
  public numeroOpa: string;
  public visualizar: boolean = false;

  public ugDataSource = new MatTableDataSource<IUnidadeGestoraModel>([]);
  public ugSemDados = true;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public matDialog: MatDialog,
    private ugService: SolicitacaoTecnicaService,
    private amostraService: AmostraService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.id = data.id;
    if (data.dados) this.model = data.dados;
    if (data.tipoAmostra) this.tipoAmostra = data.tipoAmostra;
    if (data.visualizar) this.visualizar = data.visualizar;
    if (data.numeroOpa) this.numeroOpa = data.numeroOpa;
  }

  ngOnInit(): void {
    this.dados.nome = this.model.nome;
    this.dados.descricao = this.model.descricao;

    if (this.model.amostraArquivos) {
      this.dados.arrayFiles = this.model.amostraArquivos;
    }

    if (this.model.amostraUnidadeGestoras?.length > 0) {
      const formatPeaUnidadeGestora = this.model?.amostraUnidadeGestoras.map(item => {
        return {
          id: item?.unidadeGestoraId,
          text: `${item?.unidadeGestora?.sigla} - ${item?.unidadeGestora?.nome}`,
          value: item?.unidadeGestoraId
        } as IUnidadeGestoraModel
      })

      this.dados.unidadeGestora = formatPeaUnidadeGestora;
      this.atualizarListaUgs(formatPeaUnidadeGestora)
    }
  }

  public atualizarListaUgs(unidadeGestora: IUnidadeGestoraModel[]) {
    const listaUgs = unidadeGestora.map(_ => ({ ..._, nome: `${_.sigla} - ${_.nome}` }));
    this.ugDataSource.paginator = this.paginator;
    this.ugDataSource.sort = this.sort;
    this.ugDataSource.filterPredicate = (data: { nome: string }, filterValue: string) => {
      return (data.nome || '').trim().toLowerCase().indexOf(filterValue) !== -1;
    };
    listaUgs.sort((a, b) => a['nome'].localeCompare(b['nome']));
    this.ugDataSource = new MatTableDataSource<IUnidadeGestoraModel>(
      listaUgs
    );
    this.ugDataSource.sortingDataAccessor = defaultDataAcessor;
    this.ugDataSource._updateChangeSubscription();
    this.ugSemDados = this.ugDataSource.filteredData.length === 0;
  }


  public async exibirModalUg() {
    const res = await this.ugService.obterUnidadeGestora(this.tipoAmostra, this.id);

    if (res.sucesso) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component-45';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      const unidadesAdicionadas = [] as any[];
      if (this.dados.unidadeGestora !== undefined) {
        this.dados.unidadeGestora.forEach((d) => {
          return unidadesAdicionadas.push(d)
        }

        );
        dialogConfig.data = res.dados.filter(
          (d) => !unidadesAdicionadas.some((x) => +d?.value == x?.id)
        );
      } else {
        dialogConfig.data = res.dados;
        this.dados.unidadeGestora = [] as IUnidadeGestoraModel[];
      }
      const modal = this.matDialog.open(
        ModalSelecionarUgComponent,
        dialogConfig
      );
      modal.afterClosed().subscribe((data) => {
        if (data) {
          if (Array.isArray(data)) {
            let unidadeSelecionada = [] as IUnidadeGestoraModel[];
            unidadeSelecionada = data.map(
              (d) =>
              ({
                value: d.value,
                text: d.text,
              } as IUnidadeGestoraModel)
            );
            unidadeSelecionada.forEach((x) =>
              this.dados.unidadeGestora.push(x)
            );
          }

          this.atualizarListaUgs(this.dados.unidadeGestora);
        }
      });
    } else {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: res.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public excluirUg(ind: number) {
    this.ugDataSource.data.splice(ind, 1);
    this.dados.unidadeGestora.splice(ind, 1);
    this.atualizarListaUgs(this.dados.unidadeGestora
    );

  }

  public deleteFile(i: number) {
    if (this.dados.arrayFiles[i]) this.dados.arrayFiles.splice(i, 1)
  }


  public getBase64(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result)
      };
      reader.onerror = function (error) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: "Ocorreu um erro ao selecionar o arquivo" + error.toString(),
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        })
      };
    })
  }

  public async uploadAmostra(e) {
    let file = (e.target as HTMLInputElement).files;

    let flatListFiles = Array.from(file);

    for (let index = 0; index < file.length; index++) {
      const fileToArray = await this.getBase64(file[index]);
      this.dados.arrayFiles.push({
        nome: flatListFiles[index].name,
        conteudo: fileToArray.toString()
      })
    }
  }

  public salvar() {
    if (!this.dados.nome || !this.dados.descricao) {
      this.validar = true;
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        icon: 'warning',
        text: 'Preencha os campos obrigatórios',
      });
      return
    }

    this.model.nome = this.dados.nome;
    this.model.descricao = this.dados.descricao;
    this.model.amostraArquivos = this.dados.arrayFiles;

    this.model.amostraUnidadeGestoras = this.dados.unidadeGestora.map(item => {
      return { unidadeGestoraId: +item.value } as IAmostraUnidadeGestora
    })

    this.amostraService.salvarAmostra(this.id, this.model, this.tipoAmostra).then(res => {
      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: 'success',
          text: res.mensagem.descricao,
        });
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: 'error',
          text: res.mensagem.descricao,
        });
      }
    }).catch(err => {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        icon: 'error',
        text: err.mensagem.descricao,
      });
    }).then(() => {
      if (this.model.amostraArquivos.length > 5) {
        setTimeout(() => {
          this.matDialog.closeAll();
        }, 5000)
      } else {
        this.matDialog.closeAll();
      }
    }).finally()

  }

  public cancelar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { interno: true };
    this.matDialog.open(ModalCancelarComponent, dialogConfig).afterClosed();
  }
}
