import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { FiltroModel } from 'src/app/models/filtro.model';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';
import { IEntendendoEntidadeListaModel } from 'src/app/models/entendendo-entidade-lista.model';
import { EntendendoEntidadeService } from 'src/app/services/entendendo-entidade.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { ModalSelecaoQuestionarioComponent } from '../modal-selecao-questionario/modal-selecao-questionario.component';
import { IModeloQuestionarioItemModel } from 'src/app/models/modelo-questionario.model';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';
import { IArquivoModel } from 'src/app/models/arquivo.model';
import { IQuestionarioUGModel } from 'src/app/models/questionario-ug.model';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { QuestionarioUGRespostaService } from 'src/app/services/questionarioug-resposta.service';

@Component({
  selector: 'app-importacao-arquivo-questionario',
  templateUrl: './importacao-arquivo-questionario.component.html',
  styleUrls: ['./importacao-arquivo-questionario.component.scss']
})
export class ImportacaoArquivoQuestionarioComponent extends BaseFormComponent implements OnInit  {
  public filtro = {} as FiltroModel;
  model: any;
  unidadeGestoraId: number;
  modeloRelatorioId: number;
  arquivo: File | null = null;
  mensagem: string = '';
  arquivoUrl: string;
  nomeArquivo: string;
  botaoEncerrarHabilitado: boolean = false;
  

  constructor(
    private authService: AuthService,
    private questionarioUGService: EntendendoEntidadeService,
    private questionarioUGRespostaService: QuestionarioUGRespostaService,
    matDialog: MatDialog,
    router: Router,
    private sharedService: SharedService,
    route: ActivatedRoute,
    toastr: ToastrService,
    localeService: BsLocaleService
  ) { 
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {
    this.unidadeGestoraId = this.sharedService.getId();
    this.downloadAnexo();
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      // Verifica se a extensão do arquivo é PDF
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'pdf') {
        // Emite um aviso caso a extensão não seja PDF
        this.toastr.error('O tipo de arquivo selecionado não é válido, selecione apenas arquivo PDF!', 'Extensão inválida');
        this.arquivo = null; // Reseta o arquivo selecionado
      } else {
        this.arquivo = file;
      }
    }
  }

  downloadAnexo(): void {

    this.questionarioUGService.DownloadArquivo(this.unidadeGestoraId).then(
      (res) => {
        this.arquivoUrl = res.dados.url;
        this.nomeArquivo = res.dados.nomeArquivo;
        if (this.arquivoUrl) {
          this.botaoEncerrarHabilitado = true;  // Habilita o botão
        }
   },
      (error) => {
      }
    );

  }

  onEncerrarAvaliacao(){
    Swal.fire({
      title: 'Atenção',
      text: "Esta ação não poderá ser desfeita! Deseja continuar? ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((res) => {
      if (res.value) {

    this.questionarioUGService.encerrarQuestionarioImportacaoArquivo(this.unidadeGestoraId, 1)
    .then((res) => {
      if (res.sucesso) {
        this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=1`);
      }
      else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })

  }
})}

  enviarArquivo(): void {
    if (this.arquivo) {
      const arquivoModel: IArquivoModel = {
        id: 0,
        nomeArquivo: this.arquivo.name,
        entidadeArquivo: 1, 
        arquivo: this.arquivo,
        url: ''
      };

      

      this.questionarioUGService.enviarArquivo(this.unidadeGestoraId, 1 ,arquivoModel).then(
        (response) => {
          this.downloadAnexo();
          this.exibirMensagemSucesso('Arquivo importado com sucesso');
          // this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=1`);
        },
        (error) => {
          this.exibirMensagemErro(error.mensagem.descricao);
        }
      );
    }
  }

  public onBack() {
    this.submit = false;
    this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=1`);
  }


}
