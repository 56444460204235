<div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item">
              <a [routerLink]="['/']">
                <i class="fas fa-home"></i>
                Página Inicial</a>
            </li>
            <li class="breadcrumb-item active">Categoria de Risco</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-outline card-lightblue">
            <div class="card-header">
              <h3 class="card-title mb-0">
                <i class="fas fa-table pr-2"></i>
                Categoria de Risco
              </h3>
            </div>
  
            <div class="card-body p-2">
              <div class="mat-elevation-z4">
                <mat-toolbar class="table-header">
                  <mat-toolbar-row>
                    <button type="button" class="btn btn-success btn-sm mr-1" (click)="novo()"   [hidden]="!permissoes.incluir">
                      <i class="far fa-file"></i> Novo
                    </button>
                    <button type="button" class="btn btn-primary btn-sm mr-1"  [hidden]="!permissoes.editar" [disabled]="!selectedModel || !selectedModel.ativo"
                      (click)="editar(selectedModel.id)">
                      <i class="far fas fa-pen"></i> Editar
                    </button>
                    <button type="button" class="btn btn-warning btn-sm mr-1"
                      [hidden]="!selectedModel || selectedModel.ativo || !permissoes.inativarReativar" mwlConfirmationPopover popoverTitle="Ativar"
                      popoverMessage="Tem certeza que deseja reativar esse registro?" placement="bottom" confirmText="Sim"
                      confirmButtonType="primary"
                      cancelText="Não" (confirm)="reativar(selectedModel.id)">
                      <i class="far fa-play-circle"></i>
                      Reativar
                    </button>
                    <button type="button" class="btn btn-warning btn-sm mr-1"
                      [hidden]="(selectedModel && !selectedModel.ativo) || !permissoes.inativarReativar" [disabled]="!selectedModel?.ativo"
                      mwlConfirmationPopover popoverTitle="Inativar"
                      popoverMessage="Tem certeza que deseja inativar esse registro?" placement="bottom" confirmText="Sim"
                      confirmButtonType="primary"
                      cancelText="Não" (confirm)="inativar(selectedModel.id)">
                      <i class="far fa-pause-circle"></i>
                      Inativar
                    </button>
                    <button type="button" class="btn btn-danger btn-sm mr-1"
                      [disabled]="!selectedModel"
                      mwlConfirmationPopover popoverTitle="Excluir"
                      [hidden]="!permissoes.excluir"
                      popoverMessage="Deseja realmente excluir o registro?" placement="bottom" confirmText="Sim"
                      confirmButtonType="primary"
                      cancelText="Não" (confirm)="excluir(selectedModel.id)">
                      <i class="fa fa-trash"></i>
                      Excluir
                    </button>
                    <button type="button"class="btn btn-info btn-sm mr-1"[disabled]="!selectedModel"
                    [hidden]="!permissoes.visualizar"
                    (click)="visualizar(selectedModel.id)">
                    <i class="far fas fa-eye"></i>
                    Visualizar
                  </button>    
                    <span class="fill-remaining-space"></span>
                    <div class="input-group input-group-sm" style="width: 200px;">
                      <input type="text" name="table_search" class="form-control float-right" (keyup.enter)="buscar()"
                        placeholder="Pesquisar" [(ngModel)]="filtro.pesquisa" />
  
                      <div class="input-group-append">
                        <button type="button" class="btn btn-info" (click)="buscar()">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                    <div class="btn-group">
                      <button type="button" data-toggle="dropdown" class="btn btn-tool btn-default dropdown-toggle">
                        <i class="fas fa-filter"></i>
                      </button>
                      <div role="menu" class="dropdown-menu dropdown-menu-right dropdown-menu-checkbox">
                        <a (click)="filtro.ativo = undefined; buscar()" class="dropdown-item"><i [ngClass]="
                          filtro.ativo === undefined
                                ? 'fas fa-check-circle'
                                : 'far fa-circle'
                            "></i>Exibir todos</a>
                        <a (click)="filtro.ativo = true; buscar()" class="dropdown-item"><i [ngClass]="
                              filtro.ativo === true
                                ? 'fas fa-check-circle'
                                : 'far fa-circle'
                            "></i>Exibir somente ativos</a>
                        <a (click)="filtro.ativo = false; buscar()" class="dropdown-item"><i [ngClass]="
                              filtro.ativo === false
                                ? 'fas fa-check-circle'
                                : 'far fa-circle'
                            "></i>Exibir somente inativos</a>
                      </div>
                    </div>
                  </mat-toolbar-row>
                </mat-toolbar>
                <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear matSortActive="id"
                  matSortDirection="asc" class="mat-elevation-z8" [hidden]="semDados" (matSortChange)="sortData($event)">
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns:
                                      displayedColumns; " (click)="selecionar(row)" class="clickable"
                                      [ngClass]="{'bg-light': row.selecionado}">
                                  </tr>
                  <ng-container matColumnDef="selecao">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="selectedModel = ($event.checked ? element : null)"
                        [checked]="selectedModel?.id === element.id">
                      </mat-checkbox>
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
                    <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="descricao">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrição </th>
                    <td mat-cell *matCellDef="let element"> {{element.descricao}} </td>
                  </ng-container>

                  <ng-container matColumnDef="ativo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Situação </th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.ativo" class="badge bg-success text-xs">Ativo</span>
                      <span *ngIf="!element.ativo" class="badge bg-gray-dark text-xs">Inativo</span>
                    </td>
                  </ng-container>
  
                </table>
                <h5 [hidden]="!semDados" class="table-no-data">Nenhum registro encontrado.</h5>
                <mat-paginator #TableOnePaginator="matPaginator" class="mt-4"
                                  [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons [length]="totalItens"
                                  (page)="selectedModel = null; pageEvent = pageChanged($event)">
                              </mat-paginator>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  