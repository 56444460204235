import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import Inputmask from 'inputmask';
import { toIntlNumberString } from 'src/app/core/helpers/number.helper';

@Component({
  selector: 'app-modal-preencher-formulario-paa',
  templateUrl: './modal-preencher-formulario-paa.component.html',
  styleUrls: ['./modal-preencher-formulario-paa.component.scss']
})
export class ModalPreencherFormularioPaaComponent implements OnInit, AfterViewInit {
  public dataSource = new MatTableDataSource<any>([]);
  public displayedColumns = ['ug', 'valor'];
  public semDados = true;
  public visualizar = false;

  constructor(
    private dialogRef: MatDialogRef<ModalPreencherFormularioPaaComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.dataSource = new MatTableDataSource<any>(data.respostas);
    this.visualizar = data.visualizar;
  }

  ngOnInit(): void {
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  ngAfterViewInit(): void {
    Inputmask().mask(document.querySelectorAll('input'));
  }

  public concluir() {
    const respostas = this.dataSource.data;
    for (const item of respostas) {
      item.valorNumerico = item.valorNumerico ? parseFloat(toIntlNumberString(item.valorNumerico.toString())) : undefined;
    }
    this.dialogRef.close(respostas);
  }

  public cancelar() {
    this.dialogRef.close();
  }
}

