import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { EnumRecursoPerfil } from '../models/enum/recurso-perfil.enum';
import { IPerfilUsuarioModel } from '../models/perfil-usuario.model';
import { IUsuarioEstadoModel } from '../models/usuario-estado.model';
import { BaseService } from './base.service';
import { IEnumModel } from '../models/enum.model';

@Injectable()
export class AuthService extends BaseService {
  public usuario: IPerfilUsuarioModel;

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public async obterPerfilUsuario(): Promise<IBaseModel<IPerfilUsuarioModel>> {
    if (this.usuario) {
      return Promise.resolve({ sucesso: true, dados: this.usuario } as IBaseModel<IPerfilUsuarioModel>);
    } else {
      try {
        const res = await this.httpClient
          .get<IBaseModel<IPerfilUsuarioModel>>(`${this.apiBaseUrl}/usuario/perfil`)
          .toPromise();
        this.usuario = res.dados;
        return res;
      }
      catch (err) {
        delete (this.usuario);
        throw (err);
      }
    }
  }

  public obterEstado(): Promise<IBaseModel<IUsuarioEstadoModel>> {
    return this.httpClient
      .get<IBaseModel<IUsuarioEstadoModel>>(`${this.apiBaseUrl}/usuario/estado`)
      .toPromise();
  }

  public logout(): void {
    window.location.replace('./conta/logout');
  }

  public login(): void {
    window.location.replace('./conta/login?returnUrl=' + window.location);
  }

  public possuiPermissao(recurso: string, acao: string) {
    if (EnumRecursoPerfil.Aberto == recurso)
      return true;
    var recursos = this.usuario && this.usuario.permissoesRecursos && this.usuario.permissoesRecursos.find(d => d.recurso.toUpperCase() == recurso.toUpperCase());
    return recursos != undefined && recursos.acoes.some(d => d.toUpperCase() == acao.toUpperCase());
  }

  public possuiPerfis(perfis: string[]) {
    return this.usuario
      && this.usuario.perfis
      && perfis.some(p => this.usuario.perfis.indexOf(p) >= 0);
  }

  public possuiPermissaoRecurso(recurso: string, acao: string) {
    return false;
  }

  public async obterPerfisSimplificado(): Promise<IBaseModel<IEnumModel[]>> {
    
      try {
        const res = await this.httpClient
          .get<IBaseModel<IEnumModel[]>>(`${this.apiBaseUrl}/usuario/obterPerfisSimplificado`)
          .toPromise();
        return res;
      }
      catch (err) {
        throw (err);
      }
    
  }
}
