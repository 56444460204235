import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { ITipoEnfaseModel } from 'src/app/models/tipo-enfase.model';
import { TipoEnfaseService } from 'src/app/services/tipo-enfase.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tipo-enfase-form',
  templateUrl: './tipo-enfase-form.component.html',
  styleUrls: ['./tipo-enfase-form.component.scss']
})
export class TipoEnfaseFormComponent extends BaseFormComponent implements OnInit {
  public model: ITipoEnfaseModel = {} as ITipoEnfaseModel;

  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required)
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private tipoEnfaseService: TipoEnfaseService,
    public matDialog: MatDialog
  ) {
    super(route, toastr,  router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Tipo de ênfase';
    }
  }

  async ngOnInit() {
    try {
      if (!this.novoRegistro) {
        const res = await this.tipoEnfaseService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;

          if (this.visualizar){
            this.titulo = 'Visualizar Tipo de ênfase - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Tipo de ênfase - ' + this.model.nome;
          }
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.router.navigate(['/tipo-enfase']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.router.navigate(['/tipo-enfase']);
    }
  }

  public async salvarDados(salvarEFechar: boolean){
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }
    this.submit = true;

    this.atualizarModel(this.form.value);

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        res = await this.tipoEnfaseService.atualizar(this.model);
      } else {
        res = await this.tipoEnfaseService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        if(salvarEFechar == true)
          this.router.navigate(['/tipo-enfase']);
        else
          if(this.novoRegistro){
            this.router.navigate([`/tipo-enfase/${res.dados.id}`]);
            await this.ngOnInit();
          }
      } else {
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    } 

  }

  public async onSubmit() {
    this.salvarDados(false);
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.router.navigate(['/tipo-enfase']);
  }
  
  public cancelar() {
    this.router.navigate(['/tipo-enfase']);
  }
}
