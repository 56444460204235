import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
	MatDialog,
	MatDialogConfig,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { IArquivoModel } from "src/app/models/arquivo.model";
import {
	IArquivoRespostaModel,
	IItemSolicitacaoTecnicaModel,
	IRepostaItemModel,
} from "src/app/models/item-solicitacao-tecnica.model";
import { SolicitacaoTecnicaService } from "src/app/services/solicitacao-tecnica.service";
import { ModalVisualizarItemComponent } from "src/app/shared/components/aba-solicitacao-tecnica/modal-visualizar-item/modal-visualizar-item.component";
import Swal from "sweetalert2";
import { ModalDelegarItemComponent } from "../modal-delegar-item/modal-delegar-item/modal-delegar-item.component";

@Component({
	selector: "app-modal-item-solicitacao",
	templateUrl: "./modal-item-solicitacao.component.html",
})
export class ModalItemSolicitacaoComponent implements OnInit {
	public dataSource = new MatTableDataSource<IArquivoModel>([]);
	public dataSourceResposta = new MatTableDataSource<IArquivoRespostaModel>(
		[]
	);
	public semDados: boolean = false;
	public semDadosResposta: boolean = false;
	public model: IItemSolicitacaoTecnicaModel =
		{} as IItemSolicitacaoTecnicaModel;
	public solicitacaoId: number;
	public responder = false;
	public tituloModal = "";
	public respostaItemModel: IRepostaItemModel = {} as IRepostaItemModel;
  public numeroSolicitacao: string;
  public visualizar = false;

	constructor(
		public matDialog: MatDialog,
		private dialogRef: MatDialogRef<ModalVisualizarItemComponent>,
		public solicitacaoService: SolicitacaoTecnicaService,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.model.id = data?.id;
		this.solicitacaoId = data?.solicitacaoTecnicaId;
		this.responder = data?.responder;
    this.numeroSolicitacao = data?.numeroSolicitacao;
    this.visualizar = data?.visualizar
	}

	@ViewChild("tabelaItemPaginator", { static: true })
	tabelaItemPaginator: MatPaginator;
	@ViewChild("tabelaItemSort", { static: true }) tabelaItemSort: MatSort;

	@ViewChild("tabelaRespostaPaginator", { static: true })
	tabelaRespostaPaginator: MatPaginator;
	@ViewChild("tabelaRespostaSort", { static: true })
	tabelaRespostaSort: MatSort;

	public arrayFiles = [];

	ngOnInit(): void {
		if (this.responder) this.tituloModal = "Responder Item de Solicitação";
		else this.tituloModal = "Visualizar Item de Solicitação";

		this.obterPorId();

		this.dataSource.paginator = this.tabelaItemPaginator;
		this.dataSource.sort = this.tabelaItemSort;
		this.dataSource.sortingDataAccessor = this.sortingDataAccessor;

		this.dataSourceResposta.sortingDataAccessor = this.sortingDataAccessor;
		this.dataSourceResposta.sort = this.tabelaRespostaSort;
		this.dataSourceResposta.paginator = this.tabelaRespostaPaginator;
	}

	public obterPorId() {
		this.solicitacaoService
			.obterItemId(this.solicitacaoId, this.model?.id)
			.then((res) => {
				if (res.sucesso) {
					this.model = res.dados;

					this.dataSource.data = res?.dados?.anexo;
					this.dataSource.paginator = this.tabelaItemPaginator;
					this.dataSource.sort = this.tabelaItemSort;
					this.dataSource.sortingDataAccessor =
						this.sortingDataAccessor;

					if (res?.dados?.respostaItemSolicitacaoTecnica)
						this.respostaItemModel =
							res?.dados?.respostaItemSolicitacaoTecnica;

					if (res?.dados?.respostaItemSolicitacaoTecnica?.anexos)
						this.dataSourceResposta.data =
							res?.dados?.respostaItemSolicitacaoTecnica?.anexos;
				} else {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "warning",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
				}
			});
	}

	public async anexarArquivosResposta(e) {
		let file = (e.target as HTMLInputElement).files;

		let flatListFiles = Array.from(file);

		for (let index = 0; index < file.length; index++) {
			const fileToArray = await this.getBase64(file[index]);
			this.dataSourceResposta.data.push({
				id: 0,
				nomeArquivo: flatListFiles[index].name,
				arquivo: fileToArray.toString(),
			});
		}

		this.dataSourceResposta.sortingDataAccessor = this.sortingDataAccessor;
		this.dataSourceResposta.sort = this.tabelaRespostaSort;
		this.dataSourceResposta.paginator = this.tabelaRespostaPaginator;
	}

	public async removerArquivoItemResposta(arquivo: IArquivoRespostaModel) {
    Swal.fire({
			title: "Excluir Registro",
			text: "Tem certeza que deseja excluir esse Registro?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then((res) => {
			if (res.value) {
        if (arquivo.id > 0) {
          this.solicitacaoService
            .excluirAnexoItemResposta(arquivo)
            .then((res) => {
              if (res.sucesso) {
                let index =
                  this.dataSourceResposta.data.indexOf(arquivo);
                this.dataSourceResposta.data.splice(index, 1);

                this.dataSourceResposta.sortingDataAccessor =
                  this.sortingDataAccessor;
                this.dataSourceResposta.sort =
                  this.tabelaRespostaSort;
                this.dataSourceResposta.paginator =
                  this.tabelaRespostaPaginator;
              } else {
                Swal.fire({
                  toast: true,
                  position: "top-end",
                  icon: "warning",
                  text: res.mensagem.descricao,
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                });
              }
            });
        } else {
          let index = this.dataSourceResposta.data.indexOf(arquivo);
          this.dataSourceResposta.data.splice(index, 1);

          this.dataSourceResposta.sortingDataAccessor =
            this.sortingDataAccessor;
          this.dataSourceResposta.sort = this.tabelaRespostaSort;
          this.dataSourceResposta.paginator =
            this.tabelaRespostaPaginator;
        }
      }
      })

	}

	public Download(arquivo: IArquivoModel) {
    this.solicitacaoService.downloadAnexo(this.model.id, arquivo.id).then((res) =>{
      if (res.sucesso) {
        const link = document.createElement("a");
        link.href = res.dados.toString();
        link.download = arquivo.nomeArquivo;
        link.click();
      } else {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }})
	}

  public finalizarItem(id: number) {
    Swal.fire({
			title: "Finalizar Registro",
			text: "Confirma finalização do item?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then((res) => {
			if (res.value) {
        this.solicitacaoService
				.finalizarItem(id)
				.then((res) => {
					if (res.sucesso) {
						Swal.fire({
							toast: true,
							position: "top-end",
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: "success",
							text: res.mensagem.descricao,
						});
					} else {
						Swal.fire({
							toast: true,
							position: "top-end",
							icon: "warning",
							text: res.mensagem.descricao,
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
					}
				}).finally(() => {
          this.obterPorId();
        });
      }})
	}


  public enviarItem(id: number) {
    Swal.fire({
			title: "Enviar Registro",
			text: "Confirma envio do item?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then((res) => {
			if (res.value) {
        this.solicitacaoService
				.enviarItem(id)
				.then((res) => {
					if (res.sucesso) {
						Swal.fire({
							toast: true,
							position: "top-end",
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: "success",
							text: res.mensagem.descricao,
						});
            this.dialogRef.close();
					} else {
						Swal.fire({
							toast: true,
							position: "top-end",
							icon: "warning",
							text: res.mensagem.descricao,
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
					}
				}).finally(() => {
          this.obterPorId();
        });
      }})
	}

	public delegarItem(item: IItemSolicitacaoTecnicaModel) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component-delegar";
    dialogConfig.width = "45vw";
    dialogConfig.height = 'auto';
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		dialogConfig.data = {
			item: item,
		};

		const modal = this.matDialog.open(
			ModalDelegarItemComponent,
			dialogConfig
		);modal.afterClosed().subscribe((data) => {
      this.obterPorId();
     });
	}

	public sortingDataAccessor = (item, property) => {
		switch (property) {
			case "arquivo":
				return item.nomeArquivo;
			default:
				return item[property];
		}
	};

	public salvar() {
		this.respostaItemModel.anexos = this.dataSourceResposta?.data;

		if (!this.respostaItemModel.descricao) {
			Swal.fire({
				toast: true,
				position: "top-end",
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
				icon: "warning",
				text: "Informe a descrição da resposta!",
			});
			return;
		}

		this.solicitacaoService
			.salvarItemResposta(this.model.id, this.respostaItemModel)
			.then((res) => {
				if (res.sucesso) {
					this.dialogRef.close();
					Swal.fire({
						toast: true,
						position: "top-end",
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
						icon: "success",
						text: res.mensagem.descricao,
					});
				} else {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "warning",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
				}
			});
	}

	public getBase64(file) {
		return new Promise((resolve, reject) => {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				resolve(reader.result);
			};
			reader.onerror = function (error) {
				Swal.fire({
					toast: true,
					position: "top-end",
					icon: "error",
					text:
						"Ocorreu um erro ao selecionar o arquivo" +
						error.toString(),
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
			};
		});
	}

	public downloadAnexoResposta(arquivo: IArquivoRespostaModel) {
		this.solicitacaoService
			.downloadArquivoRespostaItem(arquivo)
			.then((res) => {
				if (res.sucesso) {
					const link = document.createElement("a");
					link.href = res.dados;
					link.download = arquivo.nomeArquivo;
					link.click();
				} else {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "warning",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
				}
			});
	}

	public fechar() {
		this.dialogRef.close();
	}
}
