<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/calendario']">
              <i class="fas fa-home"></i>
              Página Inicial</a
            >
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/calendario']">Calendário</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>
          </div>

          <form
            [formGroup]="form"
            #f="ngForm"
            (keydown.enter)="$event.preventDefault()"
          >
            <div class="card-body">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="nome" class="mb-1">Nome</label>
                  <input
                    [attr.disabled]="visualizar ? true : null"
                    type="text"
                    id="inputNome"
                    class="form-control form-control-sm"
                    formControlName="nome"
                    maxlength="100"
                    [ngClass]="{
                      'is-invalid': f.submitted && form.controls['nome'].invalid
                    }"
                  />
                  <div
                    *ngIf="f.submitted && form.controls['nome'].invalid"
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.controls['nome'].errors.required">
                      Campo obrigatório
                    </div>
                  </div>
                </div>

                <div class="form-group col-md-2">
                  <label for="fixo" class="mb-1">Fixo</label>
                  <select
                    id="fixo"
                    formControlName="fixo"
                    [attr.disabled]="visualizar ? true : null"
                    class="form-control form-control-sm"
                    [ngClass]="{
                      'is-invalid': f.submitted && form.controls['fixo'].invalid
                    }"
                    (change)="fixo = $event.target.value"
                  >
                    <option
                      *ngFor="let item of opcoesFixo"
                      [value]="item.codigo"
                    >
                      {{ item.descricao }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-2">
                  <label for="data" class="mb-1">Data</label>
                  <input
                    *ngIf="fixo == '0'"
                    type="text"
                    id="data"
                    class="form-control form-control-sm suaClassDatePicker"
                    bsDatepicker
                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                    formControlName="data"
                    [attr.disabled]="visualizar ? true : null"
                  />
                  <input
                    *ngIf="fixo == '1'"
                    type="text"
                    id="data"
                    class="form-control form-control-sm suaClassDatePicker"
                    bsDatepicker
                    [bsConfig]="{ dateInputFormat: 'DD/MM' }"
                    formControlName="data"
                    [attr.disabled]="visualizar ? true : null"
                  />
                </div>

                <div class="form-group col-md-2">
                  <label for="selectTipo" class="mb-1">Tipo de Entrada</label>
                  <select
                    id="selectTipo"
                    formControlName="tipoFeriadoId"
                    class="form-control form-control-sm"
                    [attr.disabled]="visualizar ? true : null"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && form.controls['tipoFeriadoId'].invalid
                    }"
                  >
                    <option
                      *ngFor="let item of emunTipoFeriado"
                      [value]="item.codigo"
                    >
                      {{ item.descricao }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      f.submitted && form.controls['tipoFeriadoId'].invalid
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.controls['tipoFeriadoId'].errors.required">
                      Campo obrigatório
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="btn-group" [hidden]="visualizar">
                <button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
                    <i class="far fa-save"></i> Salvar
                </button>
                <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                <div class="dropdown-menu" role="menu">
                <a class="dropdown-item" (click)="salvarDados(false)">Salvar</a>
                <a class="dropdown-item" (click)="salvarDados(true)">Salvar e fechar</a>
                </div>
              </div>

              <button
                type="button"
                [hidden]="visualizar"
                class="btn btn-default btn-sm"
                (click)="onBack()"
              >
                <i class="fas fa-times"></i> Cancelar
              </button>

              <button
                [hidden]="!visualizar"
                type="button"
                class="btn btn-default btn-sm"
                (click)="onBack()"
              >
                <i class="fas fa-times"></i> Sair
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
