<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline" >
    <div class="card-header bg-light" >
      <h4 class="card-title mb-0">{{ titulo }}</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="nome" class="mb-1">Constatação</label>
              <input type="text" id="nome" disabled [(ngModel)]="model.nome"
               class="form-control form-control-sm" maxlength="100" />
            </div>
          </div>

          <div class="row">
            <div class="form-group col-md-12">
              <label for="descricao" class="mb-1">Descrição</label>
              <angular-editor [config]="config" id="descricao" rows="20" [(ngModel)]="model.descricao"></angular-editor>
            </div>
          </div>

          <div class="row pt-3">
            <div class="form-group col-md-6">
              <mat-checkbox color="primary" name="contaGovernador" disabled [(ngModel)]="model.contaGovernador" >
                Constas do Governador
              </mat-checkbox>
            </div>
            <div class="form-group col-md-6">
              <mat-checkbox color="primary" name="contaOrdenador" disabled [(ngModel)]="model.contaOrdenador">
                Constas dos Ordenadores
              </mat-checkbox>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12" [ngClass]="{'form-group': visualizacao}">
              <label for="inputConstatacao" class="mb-1" [hidden]="!visualizacao">Recomendação</label>
              <div class="mat-elevation-z1">
                <table mat-table [dataSource]="recomendacaoDataSource">
                  <tr mat-header-row *matHeaderRowDef="['nome']" class="bg-light"></tr>

                  <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef>Nome </th>
                    <td mat-cell *matCellDef="let element"> {{ element.recomendacao.nome }}</td>
                  </ng-container>

                  <tr mat-row *matRowDef="let row; columns: ['nome'];"></tr>
                </table>
              </div>
            </div>
          </div>

        </div>

      <div class="card-footer">
        <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
          <i class="fas fa-times"></i> Fechar
        </button>
      </div>
  </div>
</div>
