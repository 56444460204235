import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuditorCoordenacaoModel } from '../models/auditor-coordenacao.model';
import { IBaseModel } from '../models/base.model';
import { ICoordenacaoModel } from '../models/coordenacao.model';
import { BaseService } from './base.service';
import { promise } from 'protractor';
import { ICoordenacaoTipoTrabalhoModel } from '../models/coordenacao-tipo-trabalho.model';
import { ICoordenacaoAgentePublicoDisponibilidadeModel } from '../models/coordenacao-agentepublico-disponibilidade.model';
import { ICoordenacaoAgentePublicoDispensaModel } from '../models/coordenacao-agentepublico-dispensa.model';

@Injectable({
  providedIn: 'root',
})
export class CoordenacaoService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<ICoordenacaoModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<ICoordenacaoModel[]>>(`${this.apiBaseUrl}/coordenacao`, { params })
      .toPromise();
  }

  public obterCoordenacoesCoordenador(): Promise<IBaseModel<ICoordenacaoModel[]>> {
    return this.httpClient
      .get<IBaseModel<ICoordenacaoModel[]>>(`${this.apiBaseUrl}/coordenacao/obter-coordenacoes-coordenador`, {})
      .toPromise();
  }

  public obterAuditorPorCoordenacao(id: number, auditorId: string, cargoAuditor: number, ano: number): Promise<IBaseModel<IAuditorCoordenacaoModel[]>> {
    return this.httpClient
      .get<IBaseModel<IAuditorCoordenacaoModel[]>>(`${this.apiBaseUrl}/coordenacao/${id}/auditores/${auditorId}/novo-cargo/${cargoAuditor}/${ano}`, {})
      .toPromise();
  }

  public async obterPorId(id: number, exibeInativos: boolean = true): Promise<IBaseModel<ICoordenacaoModel>> {
    let params = new HttpParams();
    if (exibeInativos) {
      params = params.append('exibeInativos', exibeInativos.toString());
    }
    return this.httpClient
      .get<IBaseModel<ICoordenacaoModel>>(`${this.apiBaseUrl}/coordenacao/${id}`, { params })
      .toPromise();
  }

  public async obterPorIdAno(id: number, ano:number): Promise<IBaseModel<ICoordenacaoModel>> {
    
    return this.httpClient
      .get<IBaseModel<ICoordenacaoModel>>(`${this.apiBaseUrl}/coordenacao/${id}/${ano}`, {  })
      .toPromise();
  }

  public async obterCoordenadoresAtivos(): Promise<IBaseModel<IAuditorCoordenacaoModel[]>> {
    return this.httpClient
      .get<IBaseModel<IAuditorCoordenacaoModel[]>>(`${this.apiBaseUrl}/coordenacao/obter-coordenadores-ativos`)
      .toPromise();
  }

  public async inserir(data: ICoordenacaoModel): Promise<IBaseModel<ICoordenacaoModel>> {
    return this.httpClient
      .post<IBaseModel<ICoordenacaoModel>>(`${this.apiBaseUrl}/coordenacao`, data)
      .toPromise();
  }

  public async atualizar(data: ICoordenacaoModel): Promise<IBaseModel<ICoordenacaoModel>> {
    return this.httpClient
      .put<IBaseModel<ICoordenacaoModel>>(`${this.apiBaseUrl}/coordenacao/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/coordenacao/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/coordenacao/${id}/inativar`, {})
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/coordenacao/${id}/excluir`, {})
      .toPromise ();  
  }

  public async validarCoordenador(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
    .get<IBaseModel<void>>(`${this.apiBaseUrl}/coordenacao/${id}/validarUsuarioCoordenacao`)
    .toPromise();
  }

  public async inserirTipoTrabalho(data: ICoordenacaoTipoTrabalhoModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/coordenacao/inserirTipoTrabalho`, data)
      .toPromise();
  }

  public async atualizarTipoTrabalho(data: ICoordenacaoTipoTrabalhoModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/coordenacao/atualizarTipoTrabalho`, data)
      .toPromise();
  }
  public async reativarTipoTrabalho(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/coordenacao/${id}/reativarTipoTrabalho`, {})
      .toPromise();
  }

  public async inativarTipoTrabalho(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/coordenacao/${id}/inativarTipoTrabalho`, {})
      .toPromise();
  }
  public async excluirTipoTrabalho(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/coordenacao/${id}/excluirTipoTrabalho`, {})
      .toPromise ();  
  }

  public async obterCapacidadePorAno(id: number, ano: number): Promise<IBaseModel<ICoordenacaoModel>> {
    
    return this.httpClient
      .get<IBaseModel<ICoordenacaoModel>>(`${this.apiBaseUrl}/coordenacao/${id}/obterCapacidadePorAno/${ano}`, {  })
      .toPromise();
  }
  public async getDadosDisponibilidade(id: number, ano: number): Promise<IBaseModel<Array<ICoordenacaoAgentePublicoDisponibilidadeModel>>>{
    return this.httpClient
      .get<IBaseModel<Array<ICoordenacaoAgentePublicoDisponibilidadeModel>>>(`${this.apiBaseUrl}/coordenacao/${id}/AbaDisponibilidade/${ano}`, {})
      .toPromise();
  }

  public async getDadosDispensa(id: number, ano: number): Promise<IBaseModel<Array<ICoordenacaoAgentePublicoDispensaModel>>>{
    return this.httpClient
      .get<IBaseModel<Array<ICoordenacaoAgentePublicoDispensaModel>>>(`${this.apiBaseUrl}/coordenacao/${id}/AbaDisponibilidadeDispensa/${ano}`, {})
      .toPromise();
  }

}
