import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { PaginacaoModel } from '../models/paginacao.model';
import { FiltroModel } from '../models/filtro.model';
import { BaseService } from './base.service';
import { filterBy } from '../core/helpers/filterby';
import { IAgentePublicoModel } from '../models/agente-publico.model';
import { IAgentePublicoViewModel } from '../models/viewmodels/agente-publico-viewmodel';
import { IFiltroAgentePublico } from '../models/filtros/filtro-agente-publico.model';
import { IAgentePublicoDisponibilidadeModel } from '../models/agente-publico-disponibilidade.model';
import { IAgentePublicoDispensaModel } from '../models/agente-publico-dispensa.model';

@Injectable({
  providedIn: 'root',
})
export class AgentePublicoService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(filtro: FiltroModel): Promise<IBaseModel<IAgentePublicoViewModel>> {
    return this.httpClient
      .get<IBaseModel<IAgentePublicoViewModel>>(filterBy(filtro,`${this.apiBaseUrl}/agentePublico`))
      .toPromise();
  }

  public obterViewModel(filtro: IFiltroAgentePublico): Promise<IBaseModel<IAgentePublicoViewModel>>{
    return this 
            .httpClient
            .get<IBaseModel<IAgentePublicoViewModel>>(filterBy(filtro, `${this.apiBaseUrl}/agentePublico/obterViewModel`))
            .toPromise();
  }

  public async getDadosBasicos(id: number): Promise<IBaseModel<IAgentePublicoModel>>{
    return this.httpClient
          .get<IBaseModel<IAgentePublicoModel>>(`${this.apiBaseUrl}/agentePublico/${id}/AbaDadosBasicos`)
          .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IAgentePublicoModel>> {
    return this.httpClient
      .get<IBaseModel<IAgentePublicoModel>>(`${this.apiBaseUrl}/agentePublico/${id}`)
      .toPromise();
  }

  
  public async sincronizar(): Promise<IBaseModel<boolean>> {
    return this.httpClient
      .get<IBaseModel<boolean>>(`${this.apiBaseUrl}/agentePublico/sincronizar`, {})
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/agentePublico/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/agentePublico/${id}/inativar`, {})
      .toPromise();
  }

  public async getDadosDisponibilidade(id: number, ano: number): Promise<IBaseModel<IAgentePublicoModel>>{
    return this.httpClient
      .get<IBaseModel<IAgentePublicoModel>>(`${this.apiBaseUrl}/agentePublico/${id}/AbaDisponibilidade/${ano}`, {})
      .toPromise();
  }

  public async salvarDadosDisponibilidade(dados: IAgentePublicoDisponibilidadeModel[]) : Promise<IBaseModel<IAgentePublicoModel>>{
    return this.httpClient
    .post<IBaseModel<IAgentePublicoModel>>(`${this.apiBaseUrl}/agentePublico/AbaDisponibilidade/atualizarDisponibilidade`, dados)
    .toPromise();
  }

  public async getDadosDispensas(id: number, ano: number): Promise<IBaseModel<IAgentePublicoModel>>{
    return this.httpClient
    .get<IBaseModel<IAgentePublicoModel>>(`${this.apiBaseUrl}/agentePublico/${id}/AbaDispensas/${ano}`)
    .toPromise();
  }

  public async inserirDispensa(dispensa: IAgentePublicoDispensaModel): Promise<IBaseModel<IAgentePublicoModel>>{
    return this.httpClient
        .post<IBaseModel<IAgentePublicoModel>>(`${this.apiBaseUrl}/agentePublico/AbaDispensas/inserir`, dispensa)
        .toPromise();
  }

  public async atualizarDispensa(dispensa: IAgentePublicoDispensaModel): Promise<IBaseModel<IAgentePublicoModel>>{
    return this.httpClient
        .put<IBaseModel<IAgentePublicoModel>>(`${this.apiBaseUrl}/agentePublico/AbaDispensas/atualizar`, dispensa)
        .toPromise();
  }

  public async excluirDispensa(id: number): Promise<IBaseModel<boolean>>{
    return this.httpClient
      .delete<IBaseModel<boolean>>(`${this.apiBaseUrl}/agentePublico/AbaDispensas/excluir/${id}`)
      .toPromise();
  }

  
}
