import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IFluxoAprovacaoModel } from 'src/app/models/fluxo-aprovacao.model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { FluxoAprovacaoService } from 'src/app/services/fluxo-aprovacao.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';

@Component({
  selector: 'app-fluxo-aprovacao-list',
  templateUrl: './fluxo-aprovacao-list.component.html',
  styleUrls: ['./fluxo-aprovacao-list.component.scss']
})
export class FluxoAprovacaoListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<IFluxoAprovacaoModel>([]);
  public selectedModel: IFluxoAprovacaoModel;
  public displayedColumns: string[] = ['selecao', 'id', 'nome'];
  public searchNome: string;
  public semDados = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private fluxoAprovacaoService: FluxoAprovacaoService,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService
  ) {
    super();
    this.buscar();
  }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Configuracoes, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Configuracoes, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Configuracoes, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Configuracoes, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Configuracoes, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Configuracoes, EnumAcaoRecurso.Visualizar);
 }

  public buscar() {
    this.selectedModel = null;

    this.fluxoAprovacaoService
      .obter(this.searchNome)
      .then((res) => {
        this.dataSource = new MatTableDataSource<IFluxoAprovacaoModel>(res.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.toastr.error(err.mensagem.descricao, 'Atenção');
      })
  }

  public novo() {
    this.router.navigate([`/fluxos-aprovacao/novo`]);
  }

  public excluir(id: number) {
    this.fluxoAprovacaoService
    .excluir(id)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso('Registro excluído com sucesso!');
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
    .finally(() => {
      this.buscar();
    });
  }

  public visualizar(id: number) {
    this.router.navigate([`/fluxos-aprovacao/${id}/visualizar`]);
  }

  public reativar(id: number) {
    this.fluxoAprovacaoService
    .reativar(id)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso('Registro reativado com sucesso!')
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
    .finally(() => {
      this.buscar();
    });
  }
  
  public inativar(id: number) {
    this.fluxoAprovacaoService
    .inativar(id)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso('Registro inativado com sucesso!');
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
    .finally(() => {
      this.buscar();
    });
  }
  
  public editar(id: number) {
    this.router.navigate([`/fluxos-aprovacao/${id}`]);
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }

  public botaoReativarHidden() : boolean {
    return  !this.permissoes.inativarReativar;
  }

  public botaoInativarHidden() : boolean {
    return  !this.permissoes.inativarReativar;
  }
}
