import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { IProcedimentoTecnicaAuditoriaModel } from 'src/app/models/procedimento-tecnica-auditoria.model';
import { IQuestaoProcedimentoAuditoriaModel } from 'src/app/models/questao-procedimento-auditoria.model';
import { IProcedimentoAuditoriaModel } from 'src/app/models/procedimento-auditoria.model';
import { ProcedimentoAuditoriaService } from 'src/app/services/procedimento-auditoria.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-modal-procedimento-auditoria',
  templateUrl: './modal-procedimento-auditoria.component.html',
  styleUrls: ['./modal-procedimento-auditoria.component.scss']
})

export class ModalProcedimentoAuditoriaComponent implements OnInit {
  public modelQuestaoProcedimentoAuditoria: IQuestaoProcedimentoAuditoriaModel = {} as IQuestaoProcedimentoAuditoriaModel;
  public model: IProcedimentoAuditoriaModel = {} as IProcedimentoAuditoriaModel;
  public tecnicaAuditoriaDataSource = new MatTableDataSource<IProcedimentoTecnicaAuditoriaModel>([]);

  public semDados = true;
  public visualizacao = true;

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    fonteInformacao: new FormControl('', Validators.required),
    detalhamentoProcedimento: new FormControl('', Validators.required),
  });


  constructor(
    private dialogRef: MatDialogRef<ModalProcedimentoAuditoriaComponent>,
    private procedimentoAuditoriaService: ProcedimentoAuditoriaService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {
      this.modelQuestaoProcedimentoAuditoria = data;
  }

  config: AngularEditorConfig = {
    editable: false,
    spellcheck: false,
    sanitize: false,
    height: '10rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [[], []],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async ngOnInit() {
    const res = await this.procedimentoAuditoriaService.obterPorId(this.modelQuestaoProcedimentoAuditoria.procedimentoAuditoriaId);

    if (res.sucesso) {
      this.model = res.dados;

      this.tecnicaAuditoriaDataSource = new MatTableDataSource<IProcedimentoTecnicaAuditoriaModel>
      (
        this.model.procedimentoTecnicaAuditoria
      );
      this.tecnicaAuditoriaDataSource._updateChangeSubscription();
      this.semDados = this.tecnicaAuditoriaDataSource.filteredData.length === 0;
    }
  }

  public cancelar() {
    this.dialogRef.close(false);
  }
}

