import { IEnumModel } from './../../../models/enum.model';

import { ISubTipoAuditoriaType } from 'src/app/models/subtipo-auditoria.model';
import { ManterMatrizPlanejamentoService } from './../../../services/manter-matriz-planejamento.service';
import { ModalAdicionarQuestoesComponent } from './modal-adicionar-questoes/modal-adicionar-questoes.component';
import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ModalUgComponent } from './modal-ug/modal-ug.component';
import { PlanejamentoService } from 'src/app/services/planejamento.service';

@Component({
  selector: 'app-aba-matriz-planejamento',
  templateUrl: './aba-matriz-planejamento.component.html',
  styleUrls: ['./aba-matriz-planejamento.component.scss']
})
export class AbaMatrizPlanejamentoComponent implements OnInit, OnChanges {

  public displayedColumns: string[] = ['questao', 'legislacao', 'acoes'];
  public dataSource = new MatTableDataSource<ISubTipoAuditoriaType>();
  @Input() public id: number;
  @Input() public visualizar: boolean;
  @Input() public idPlanejamentoOsa!: number;
  public tipoTrabalho: IEnumModel = {} as IEnumModel;
  public tipotrabalhoId: number;
  public title: string

  @ViewChild("TableOnePaginator", { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild("tabela1", { static: true }) tabela1: MatSort;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private matDialog: MatDialog,
    private planejamentoService: PlanejamentoService,
    public manterMatrizPlanejamentoService: ManterMatrizPlanejamentoService,
    public route: ActivatedRoute,
    public router: Router) {

    this.tipotrabalhoId = +this.route.snapshot.paramMap.get("id");

    if (this.router.url.includes("planejamento")) {
      this.tipoTrabalho.nome = 'planejamento';
      this.tipoTrabalho.codigo = 7;
    }
    else if (this.router.url.includes("osa")) {
      this.tipoTrabalho.nome = 'osa';
      this.tipoTrabalho.codigo = 4;
      this.visualizar=true;
    }

    this.carregarRegistros();
  }

  public visualizarMatriz() {
    this.router.navigate(["/matriz-planejamento-visualizar/" + this.tipotrabalhoId]);
  }

  public carregarRegistros(): void {
    this.planejamentoService.planejamentoSubTipoAuditoria.subscribe((dados: any) => {
      if (dados.length > 0) {
        this.dataSource.data = dados;
      } else {
        this.dataSource.data = null;
      }
    });
  }

  public atualizaRegistros(dados: any[]): void {
    this.dataSource.data = this.dataSource.data ? this.dataSource.data : [];
    dados.forEach(item => {
      if (!this.dataSource.data.some((x: any) => x.id == item.id)) {
        this.dataSource.data.push(item);
      }
    });
  }

  public excluirRegistro(id: number): void {
    this.dataSource.data = this.dataSource.data.filter((x: any) => x.id != id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.id = changes.id.currentValue;
    this.obter();
  }

  ngOnInit(): void {

  }

  async obter() {
    this.planejamentoService.obterAbaMatrizPorId(this.tipoTrabalho.codigo, this.tipotrabalhoId);
    this.dataSource.sort = this.sort;
  }

  public modalUnidadeGestora(item: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-ug';
    dialogConfig.width = "40%";
    dialogConfig.maxHeight = "auto";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = false;
    dialogConfig.data = {
      id: this.id,
      subTipoId: item.id
    };
    const modal = this.matDialog.open(
      ModalUgComponent,
      dialogConfig
    );
    modal.afterClosed().subscribe((data) => {
      if (data) {
      }
    });

  }

  public abrirModalAdicionarItem(matriz: any, questoes: any[], subtipoAuditoriaId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component-solicitacao-tecnica';
    dialogConfig.width = '50%';
    dialogConfig.height = '60%';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = false;
    dialogConfig.data = { titulo: `Adicionar questão`, data: matriz, tipoTrabalho: 'matrizPlanejamento', tipoTrabalhoId: this.tipotrabalhoId, questoes };

    const modal = this.matDialog.open(
      ModalAdicionarQuestoesComponent,
      dialogConfig
    );

    modal.afterClosed().subscribe((data: any[]) => {
      if (data) {
        const dados = data.map(a => {
          if (this.tipoTrabalho.nome === "planejamento" || this.tipoTrabalho.nome === "osa") {
            return {
              matriz,
              subTipoAuditoriaId: subtipoAuditoriaId,
              relacionamentoId: this.tipotrabalhoId,
              questaoId: a.id || a.value,
              isQuestaoEspecifica: a.questaoId > 0,
              questao: a,
            }
          } else {
            return {
              subTipoAuditoriaId: matriz.id,
              questaoId: a.id || a.value,
              questao: a,
            }
          }
        })
        let questoesJoin = dados.map(a => a.questao.nome).join(' , ');

        if (questoesJoin == '' && dados.length == 0) {
          return;
        }

        Swal.fire({
          title: data.length === 1 ? 'Incluir Questão' : 'Incluir Questões',
          text: data.length === 1 ? 'Tem certeza que deseja incluir a seguinte questão? ' + questoesJoin : 'Tem certeza que deseja incluir as seguintes questões? ' + questoesJoin,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
          .then(async (res) => {
            if (res.value) {
              dados.forEach(item => {
                let encontrado = questoes.find(a => { return item.questaoId === a.questaoId });

                if (Object.keys(encontrado ?? {}).length > 0) {
                  return;
                }
                this.manterMatrizPlanejamentoService.vincularQuestao(this.tipoTrabalho.codigo, matriz?.matrizPlanejamento?.id, item?.questaoId, item?.isQuestaoEspecifica, item?.relacionamentoId,item?.subTipoAuditoriaId).then((res) => {
                  if (res.sucesso) {
                    Swal.fire({
                      toast: true,
                      position: "top-end",
                      icon: "success",
                      text: res.mensagem.descricao,
                      showConfirmButton: false,
                      timer: 5000,
                      timerProgressBar: true,
                    });
                  } else {
                    Swal.fire({
                      toast: true,
                      position: "top-end",
                      icon: "warning",
                      text: res.mensagem.descricao,
                      showConfirmButton: false,
                      timer: 5000,
                      timerProgressBar: true,
                    });
                  }
                }).catch((err) => {
                  Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    text: err.mensagem.descricao,
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                  });
                }).finally(() => {
                  let id = this.route.snapshot.paramMap.get('id');
                  if (this.tipoTrabalho.nome === "planejamento") {
                    this.planejamentoService.obterPorId(+id);
                  }
                  this.obter();
                })
              })
            }
          })
      }
    })
  }
}
