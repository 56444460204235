import { IEnumModel } from './../../../../models/enum.model';
import { ManterMatrizPlanejamentoService } from './../../../../services/manter-matriz-planejamento.service';
import { QuestaoFormComponent } from 'src/app/pages/questao-form/questao-form.component';
import { Component, Input, OnInit, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IMatrizPlanejamentoQuestaoModel } from 'src/app/models/matriz-planejamento-questao.model';
import { IQuestaoModel } from 'src/app/models/questao.model';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { MatrizPlanejamentoService } from 'src/app/services/matriz-planejamento.service';
import { IMatrizPlanejamentoModel } from 'src/app/models/matriz-planejamento.model';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { QuestaoService } from 'src/app/services/questao.service';

@Component({
  selector: 'app-listagem-questoes',
  templateUrl: './listagem-questoes.component.html',
  styleUrls: ['./listagem-questoes.component.scss']
})
export class ListagemQuestoesComponent implements OnInit, AfterViewInit {

  constructor(
    private matDialog: MatDialog,
    private planejamentoService: PlanejamentoService,
    private matrizPlanejamentoService: MatrizPlanejamentoService,
    public router: Router,
    private questaoService: QuestaoService,
    public route: ActivatedRoute) {
    {
      this.tipotrabalhoId = +this.route.snapshot.paramMap.get("id");

      if (this.router.url.includes("planejamento")) {
        this.tipoTrabalho.nome = 'planejamento';
        this.tipoTrabalho.codigo = 7;
        this.isPlanejamento = true;
      }
      else if (this.router.url.includes("osa")) {
        this.tipoTrabalho.nome = 'osa';
        this.tipoTrabalho.codigo = 4;
        this.isPlanejamento = false;
      }
    }
  }

  @Input() displayedColumns!: string[];
  @Input() subtipoId!: number;
  @Input() planejamentoId!: number;
  @Input() listaDados: IQuestaoModel[] = [] as IQuestaoModel[];
  @Input() matrizPlanejamento!: IMatrizPlanejamentoModel;
  @Output() excluirItemEvento: EventEmitter<void> = new EventEmitter<void>();
  @Input() visualizar: boolean;
  public isPlanejamento: boolean;
  public tipotrabalhoId: number;
  public tipoTrabalho: IEnumModel = {} as IEnumModel

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public dataSource: MatTableDataSource<IQuestaoModel | IMatrizPlanejamentoQuestaoModel> = new MatTableDataSource<IQuestaoModel | IMatrizPlanejamentoQuestaoModel>(this.listaDados);

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<IQuestaoModel>(this.listaDados);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;

  }


  public modalEditarQuestao(questao: IQuestaoModel, visualizar: Boolean = false) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-listagem-questoes-manter-planejamento';
    dialogConfig.width = '65%';
    dialogConfig.height = '85%';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = false;

    dialogConfig.data = {
      titulo: visualizar ? 'Editar questão' : 'Visualizar questão',
      data: questao,
      visualizar,
      tipoTrabalho: this.tipoTrabalho,
      tipoTrabalhoId: this.tipotrabalhoId
    };

    const modal = this.matDialog.open(
      QuestaoFormComponent,
      dialogConfig
    );

    modal.afterClosed().subscribe((data) => {
      this.excluirItemEvento.next();
    });
  }

  public routeIncludes(tipoTrabalho: string) {
    return this.router.url.includes(tipoTrabalho);
  }

  public sortingDataAccessor = (item: IQuestaoModel, property) => {
    switch (property) {
      case "legislacao":
        return item?.legislacaoAplicavelCriterio;
      case "questao":
        return item?.nome;
      default:
        return item[property];
    }
  };

  public async excluirQuestao(idQuestao: number) {
    Swal.fire({
      title: 'Excluir Registro',
      text: 'Tem certeza que deseja excluir esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    })
      .then(async (res) => {
        if (res.value) {
          try {
            const res = (await this.questaoService.excluir(idQuestao, this.tipoTrabalho?.nome));
            if (res.sucesso) {
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'success',
                text: res.mensagem.descricao,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });

              if (this.dataSource.data.length > 0) {
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
              }
              this.excluirItemEvento.next();
            } else {
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'warning',
                text: res.mensagem.descricao,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
            }
          } catch (error) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'error',
              text: error.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
        }
      })



  }
}
