import { SolicitacaoTecnicaService } from "./../../../../services/solicitacao-tecnica.service";
import { IItemSolicitacaoTecnicaModel } from "./../../../../models/item-solicitacao-tecnica.model";
import {
	Component,
	Inject,
	OnInit,
	ViewChild,
	AfterViewInit,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { IArquivoModel } from "src/app/models/arquivo.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import {
	MatDialog,
	MatDialogConfig,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ModalArquivoComponent } from "../../modal-arquivo/modal-arquivo.component";

@Component({
	selector: "app-modal-incluir-item",
	templateUrl: "./modal-incluir-item.component.html",
	styleUrls: ["./modal-incluir-item.component.scss"],
})
export class ModalIncluirItemComponent implements OnInit, AfterViewInit {
	public model: IItemSolicitacaoTecnicaModel = {} as IItemSolicitacaoTecnicaModel;
	public dataSource = new MatTableDataSource<IArquivoModel>([]);
	public semDados: boolean = true;
	public submitted: boolean;
	public titulo: string = "";
	public visualizar: boolean;
	public solicitacaoId: number;
    public nSolicitacaoTecnica: number;
	public nrItem: number;

	public form = new FormGroup({
		titulo: new FormControl("", Validators.required),
		descricao: new FormControl("", Validators.required),
	});

	constructor(
		public matDialog: MatDialog,
		private dialogRef: MatDialogRef<ModalIncluirItemComponent>,
		public solicitacaoService: SolicitacaoTecnicaService,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.visualizar = data?.visualizar;
		this.titulo = data?.titulo;
		this.solicitacaoId = data?.id;
		this.model.id = data?.element?.id;
    	this.nSolicitacaoTecnica = data?.numero;
		this.nrItem = data?.element?.numero;
	}

	@ViewChild("myDiv") private angularEditor: any;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "10rem",
		minHeight: "5rem",
		translate: "yes",
		enableToolbar: true,
		showToolbar: true,
		defaultParagraphSeparator: "",
		defaultFontName: "",
		defaultFontSize: "",
		fonts: [
			{ class: "arial", name: "Arial" },
			{ class: "times-new-roman", name: "Times New Roman" },
			{ class: "calibri", name: "Calibri" },
			{ class: "comic-sans-ms", name: "Comic Sans MS" },
		],
		customClasses: [
			{
				name: "quote",
				class: "quote",
			},
			{
				name: "redText",
				class: "redText",
			},
			{
				name: "titleText",
				class: "titleText",
				tag: "h1",
			},
		],
		uploadUrl: "v1/image",
		uploadWithCredentials: false,
		sanitize: true,
		toolbarPosition: "top",
		toolbarHiddenButtons: [["bold", "italic"], ["fontSize"]],
	};

	ngAfterViewInit(): void {
		function load(e: any) {
			var element = e.target as HTMLTextAreaElement;
			let permitirTeclas =
				e.key !== "Backspace" &&
				e.key !== "ArrowLeft" &&
				e.key !== "ArrowRight";
			if (element.innerHTML.length === 500 && permitirTeclas) {
				e.preventDefault();
			}
		}
		this.angularEditor?.textArea?.nativeElement?.addEventListener(
			"keydown",
			load,
			false
		);
	}

	ngOnInit(): void {
    if(this.model?.id){
      this.obterPorId()
    }
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor

		if (this.visualizar === true) {
			this.form.disable();
		}
	}

  public obterPorId(){
    this.solicitacaoService.obterItemId(this.solicitacaoId, this.model?.id).then((res) =>{
      if(res.sucesso){
        this.form.controls['titulo'].setValue(res?.dados?.titulo)
        this.form.controls['descricao'].setValue(res?.dados?.descricao)
        this.dataSource.data = res?.dados?.anexo;

		    this.dataSource.paginator = this.paginator;
		    this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor
      }else{
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    })
  }

  public sortingDataAccessor = (item, property) => {
		switch (property) {
			case "arquivo":
				return item?.nomeArquivo;
			default:
				return item[property];
		}
	};

	public Download(arquivo: IArquivoModel) {
    this.solicitacaoService.downloadAnexo(this.model.id, arquivo.id).then((res) =>{
      if (res.sucesso) {
        const link = document.createElement("a");
        link.href = res.dados.toString();
        link.download = arquivo.nomeArquivo;
        link.click();
      } else {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }})
	}

	public salvar(modalIncluirArquivo?: boolean, isClose?: boolean) {
		this.submitted = true;
		if (this.form.invalid) {
			return
		}
		const dados = {
			id: this.model?.id,
      solicitacaoTecnicaId:this.solicitacaoId,
			titulo: this.form.get("titulo").value,
			descricao: this.form.get("descricao").value,
		} as IItemSolicitacaoTecnicaModel;
		this.solicitacaoService
			.salvarItem(this.solicitacaoId, dados)
			.then((res) => {
				if (res.sucesso) {
					this.model.id = res?.dados?.id;
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "success",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
          if (isClose) this.dialogRef.close(true);
          if (modalIncluirArquivo) this.modalIncluirArquivo();
				} else {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "warning",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
				}
			})
			.catch((err) => {
				Swal.fire({
					toast: true,
					position: "top-end",
					icon: "error",
					text: err.mensagem.descricao,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
			})
			.finally(() => {});
	}

	public modalIncluirArquivo() {
    if(!this.model?.id){
      Swal.fire({
        toast: true,
        position: "top-end",
        title: "Atenção",
        icon: "warning",
        text: "Antes, salve as informações!",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.salvar(true);
    }else{
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = "modal-component-arquivo";
      dialogConfig.width = "45vw";
      dialogConfig.height = 'auto';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        id: this.model?.id,
        numero: this.model?.numero,
        nSolicitacaoTecnica: this.nSolicitacaoTecnica,
        solicitacaoId: this.solicitacaoId
      };
      const modal = this.matDialog.open(ModalArquivoComponent, dialogConfig);
      modal.afterClosed().subscribe((data) => {
        if(data){

        }
        this.obterPorId()
      });
    }
	}

	public excluirArquivo(id: number) {
		Swal.fire({
			title: "Excluir Registro",
			text: "Tem certeza que deseja excluir esse Registro?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then((res) => {
			if (res.value) {
				this.solicitacaoService
					.excluirAnexo(this.solicitacaoId, this.model?.id, id)
					.then((res) => {
						if (res.sucesso) {
							Swal.fire({
								toast: true,
								position: "top-end",
								icon: "success",
								text: res.mensagem.descricao,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							});
						} else {
							Swal.fire({
								toast: true,
								position: "top-end",
								icon: "warning",
								text: res.mensagem.descricao,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							});
						}
					})
					.catch((err) => {
						Swal.fire({
							toast: true,
							position: "top-end",
							text: err.mensagem.descricao,
							icon: "error",
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
					})
					.finally(() => {
            this.obterPorId()
          });
			}
		});
	}

	public cancelar() {
		this.dialogRef.close(false);
	}
}
