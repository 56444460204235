import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAmostraModel } from '../models/amostra.model';
import { IBaseModel } from '../models/base.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AmostraService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }
  public async obterAmostra(id: number, tipoAmostra: string): Promise<IBaseModel<IAmostraModel[]>> {
    if(tipoAmostra === "planejamento"){
      return this.httpClient
      .get<IBaseModel<IAmostraModel[]>>(`${this.apiBaseUrl}/amostras/planejamento/${id}`)
      .toPromise();
    }if(tipoAmostra === "osa"){
      return this.httpClient
      .get<IBaseModel<IAmostraModel[]>>(`${this.apiBaseUrl}/amostras/osas/${id}`)
      .toPromise();
    }if(tipoAmostra === "auditoria"){
      return this.httpClient
      .get<IBaseModel<IAmostraModel[]>>(`${this.apiBaseUrl}/amostras/auditoria/${id}`)
      .toPromise();
    }
  }

  public async obterAmostraId(id: number, amostraId: number, tipoAmostra:string): Promise<IBaseModel<IAmostraModel>> {
    if(tipoAmostra === "planejamento") {
      return this.httpClient
        .get<IBaseModel<IAmostraModel>>(`${this.apiBaseUrl}/amostras/${amostraId}/planejamento/${id}`)
        .toPromise();
    }if(tipoAmostra === "osa"){
      return this.httpClient
        .get<IBaseModel<IAmostraModel>>(`${this.apiBaseUrl}/amostras/${amostraId}/osas/${id}`)
        .toPromise();
    }if(tipoAmostra === "auditoria"){
      return this.httpClient
      .get<IBaseModel<IAmostraModel>>(`${this.apiBaseUrl}/amostras/${amostraId}/auditoria/${id}`)
      .toPromise();
    }
  }

  public async download(id: number, tipoAmostra: string): Promise<IBaseModel<IAmostraModel[]>> {
    if(tipoAmostra === "planejamento"){
      return this.httpClient
      .get<IBaseModel<IAmostraModel[]>>(`${this.apiBaseUrl}/amostras/anexo-planejamento/${id}/download`)
      .toPromise();
    }if(tipoAmostra === "osa"){
      return this.httpClient
      .get<IBaseModel<IAmostraModel[]>>(`${this.apiBaseUrl}/amostras/anexo-osa/${id}/download`)
      .toPromise();
    }
  }

  public async salvarAmostra(id: number, model: IAmostraModel, tipoAmostra: string): Promise<IBaseModel<void>> {
    if(tipoAmostra === "planejamento"){
      return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/amostras/planejamento/${id}`, model)
      .toPromise();
    }if(tipoAmostra === "osa"){
      return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/amostras/osas/${id}`, model)
      .toPromise();
    }if(tipoAmostra === "auditoria"){
      return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/amostras/auditoria/${id}`, model)
      .toPromise();
    }
  }

  public async excluirAmostra(id: number, amostraId: number, tipoAmostra: string): Promise<IBaseModel<void>> {
    if(tipoAmostra === "planejamento"){
      return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/amostras/${amostraId}/planejamento/${id}`, {})
      .toPromise();
    }if (tipoAmostra === "osa"){
      return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/amostras/${amostraId}/osas/${id}`, {})
      .toPromise();
    }if(tipoAmostra === "auditoria"){
      return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/amostras/${amostraId}/auditoria/${id}`, {})
      .toPromise();
    }
  }
}
