import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IParametrizacaoDocumentoEdocsModel } from 'src/app/models/parametrizacao-documento-edocs.model';
import { ParametrizacaoDocumentoEdocsService } from 'src/app/services/parametrizacao-documento-edocs.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';

@Component({
  selector: 'app-parametrizacao-documento-edocs-form',
  templateUrl: './parametrizacao-documento-edocs-form.component.html',
  styleUrls: ['./parametrizacao-documento-edocs-form.component.scss']
})
export class ParametrizacaoDocumentoEdocsFormComponent extends BaseFormComponent implements OnInit {
  public dataSource = new MatTableDataSource<IParametrizacaoDocumentoEdocsModel>([]);
  public dataSourcePAA = new MatTableDataSource<IParametrizacaoDocumentoEdocsModel>([]);

  @ViewChild('table') table: MatTable<any>;

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private paramService: ParametrizacaoDocumentoEdocsService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    this.titulo = 'Documentação E-Docs';
  }

  async ngOnInit() {

    try {
      const res = await this.paramService.obter();
      if (res.sucesso) {
        this.dataSource = new MatTableDataSource<IParametrizacaoDocumentoEdocsModel>(
          res.dados.filter(a => a?.tipoDocumentoId < 20).sort((a, b) => (a.ordem > b.ordem) ? 1 : -1));

        this.dataSourcePAA = new MatTableDataSource<IParametrizacaoDocumentoEdocsModel>(
          res.dados.filter(a => a?.tipoDocumentoId >= 20).sort((a, b) => (a.ordem > b.ordem) ? 1 : -1));
      } else {
        this.toastr.error(res.mensagem.descricao, 'Atenção');
        this.router.navigate(['/']);
        return;
      }


    } catch (err) {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
      this.router.navigate(['/']);
    }
  }

  public async onSubmit() {

    try {
      const data = this.dataSource.data.concat(this.dataSourcePAA.data);
      const res = await this.paramService.atualizarTodos(data);

      if (res.sucesso) {
        this.toastr.success('Registro salvo com sucesso!', 'Sucesso');
        this.router.navigate(['/']);
      } else {
        const validacoes = this.montarMensagensValidacao(res);

        this.toastr.warning(validacoes, res.mensagem.descricao);
      }
    } catch (err) {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
    } 
  }

  public onBack() {
    this.router.navigate(['/']);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].ordem = i + 1;
    }

    this.dataSource._updateChangeSubscription();
  }

  dropPAA(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataSourcePAA.data, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.dataSourcePAA.data.length; i++) {
      this.dataSourcePAA.data[i].ordem = i + 1;
    }

    this.dataSourcePAA._updateChangeSubscription();
  }
}
