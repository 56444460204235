<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i>
							Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item">
						<a [routerLink]="['/unidades-gestoras']"
							>Unidades Gestoras</a
						>
					</li>
					<li class="breadcrumb-item active">{{ titulo }}</li>
				</ol>
			</div>
		</div>
	</div>
</div>
<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<div class="component data component-card">
				<mat-card class="mat-card">
					<mat-table [dataSource]="dataSource" class="table">
						<ng-container class="container" matColumnDef="pergunta">
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header">Seção/Pergunta</span>
							</mat-header-cell>
							<mat-cell
								class="cell"
								*matCellDef="let element"
								
							>
								<span>
                  {{ element.pergunta }}
                </span>
                <br>
                <span class="resposta-pergunta" *ngIf="element.tipoEntrada"
                  [innerHtml]="element.resposta">
                 
                </span>
							</mat-cell>
						</ng-container>

						<ng-container
							class="container"
							matColumnDef="tipoAvaliacaoAuditor"
						>
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header"
									>Avaliação Auditor</span
								>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta">
                  {{element.tipoAvaliacaoAuditor	}}
                </span>
              </mat-cell>
						</ng-container>

            <ng-container
							class="container"
							matColumnDef="avaliacaoAuditor"
						>
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header"
									>Resposta Revisada</span
								>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta" [innerHtml]="element.avaliacaoAuditor"></span>
                
              </mat-cell>
						</ng-container>

						<ng-container class="container" matColumnDef="tipoReplica">
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header">Réplica</span>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta">
                  {{element.tipoReplica}}
                </span>
            </mat-cell>
						</ng-container>

            <ng-container class="container" matColumnDef="replica">
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header">Resposta Revisada da Réplica</span>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta" [innerHtml]="element.replica"></span>
            </mat-cell>
						</ng-container>

						<ng-container
							class="container"
							matColumnDef="tipoAvaliacaoFinal"
						>
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header">Avaliação Final</span>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta">
                  {{element.tipoAvaliacaoFinal}}
                
                </span>
            </mat-cell>
						</ng-container>

						<ng-container
							class="container"
							matColumnDef="avaliacaoFinal"
						>
							<mat-header-cell
								class="header-cell"
								*matHeaderCellDef
							>
								<span class="sort-header"
									> Resposta Final</span
								>
							</mat-header-cell>
							<mat-cell class="cell" *matCellDef="let element">
                <span class="resposta"
                  [innerHtml]="element.avaliacaoFinal"></span>
            </mat-cell>
						</ng-container>

						<mat-header-row
							class="header-row"
							*matHeaderRowDef="displayedColumns; sticky: true"
						></mat-header-row>
						<mat-row
							class="row"
							*matRowDef="let row; columns: displayedColumns"
              [ngClass]="{
    'tipo-secao': row.tipoEntrada === '',
    'tipo-pergunta': row.tipoEntrada !== ''
  }"
						>
						</mat-row>
					</mat-table>
				</mat-card>
			</div>

		
		</div>
    
	</div>
  <div class="row">
    <div class="col-md-12">
      <button
      [hidden]="visualizar"
      type="button"
      class="btn btn-default btn-sm"
      (click)="onBack()"
    >
      <i class="fas fa-times"></i> Fechar
    </button>
    </div>
  </div>

	
</div>
