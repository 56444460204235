<form [formGroup]="form1" #f="ngForm" (keydown.enter)="$event.preventDefault()">
<div class="card-body">
<div class="row">
            <div class="form-group col-md-9">
                <label for="nome" class="mb-1"
                    >Nome</label
                >
                <input
                    [attr.disabled]="
                        visualizar ? true : null
                    "
                    type="text"
                    id="nome"
                    [ngModel]="model.nome"
                    class="form-control form-control-sm"
                    formControlName="nome"
                    onkeyup="this.value= this.value.toUpperCase();"
                    maxlength="100"
                    [ngClass]="{
                        'is-invalid':
                            submit &&
                            form1.controls['nome']
                                .invalid
                    }"
                    [readonly]="paaHomologado || !exibirBotoesPaacEspecial() || emAprovacao"
                />
                <div
                    *ngIf="
                        submit &&
                        form1.controls['nome'].invalid
                    "
                    class="invalid-feedback"
                >
                    <div
                        *ngIf="
                            form1.controls['nome'].errors
                                .required
                        "
                    >
                        Campo obrigatório
                    </div>
                </div>
            </div>
            <div class="form-group col-md-3">
                <label for="txtOrigem" class="mb-1"
                    >Origem</label
                >
                <input
                    type="text"
                    id="txtOrigem"
                    class="form-control form-control-sm"
                    readonly="true"
                    formControlName="origemAuditoria"
                    [ngModel]="
                        model.origem
                            ?.descricao
                    "
                />
            </div>
        </div>
    
        <div class="row">
            <div class="form-group col-md-3" [hidden]="esconderCamposPaaEspecial">
                <label for="txtDataInicio" class="mb-1"
                    >Data Início</label
                >
                
                <input
                    
                    type="text"
                    id="txtDataInicio"
                    class="
                        form-control form-control-sm
                        suaClassDatePicker
                    "
                    autocomplete="off"
                    bsDatepicker
                    formControlName="dataInicio"
                    [ngClass]="{
                        'is-invalid':
                            submit &&
                            form1.controls['dataInicio']
                                .invalid
                    }"
                    [readonly]="!permiteEdicao"
                />
    
                <div
                    *ngIf="
                        submit &&
                        form1.controls['dataInicio']
                            .invalid
                    "
                    class="invalid-feedback"
                >
                    <div
                        *ngIf="
                            form1.controls['dataInicio']
                                .errors.required
                        "
                    >
                        {{msgDataInicio}}
                    </div>
                </div>
            </div>
            <div class="form-group col-md-3" [hidden]="esconderCamposPaaEspecial">
                <label for="txtDataFim" class="mb-1"
                    >Data Fim</label
                >
                
                <input
                    
                    type="text"
                    id="txtDataFim"
                    class="
                        form-control form-control-sm
                        suaClassDatePicker
                    "
                    autocomplete="off"
                    bsDatepicker
                    [readonly]="!permiteEdicao"
                    formControlName="dataFim"
                    [ngClass]="{
                        'is-invalid':
                            submit &&
                            form1.controls['dataFim']
                                .invalid
                    }"
                />
    
                <div
                    *ngIf="
                        submit &&
                        form1.controls['dataFim'].invalid
                    "
                    class="invalid-feedback"
                >
                    <div
                        *ngIf="
                            form1.controls['dataFim']
                                .errors.required
                        "
                    >
                        {{msgDataFim}}
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label for="inputPaa" class="mb-1"
                    >PEAC Referência</label
                >
                <div
                    class="input-group input-group-sm"
                    style="width: 100%"
                >
                    <input
                        type="text"
                        id="inputPaa"
                        class="
                            form-control form-control-sm
                            select-color
                        "
                        readonly
                        [ngModel]="
                            model.pea?.nome
                        "
                        [ngModelOptions]="{
                            standalone: true
                        }"
                    />
                    <div
                        class="input-group-append"
                        *ngIf="!paaHomologado"
                    >
                        <button
                            [hidden]="!exibirBotoesPaacEspecial()"
                            type="button"
                            class="btn btn-info"
                            (click)="
                                exibirModalPeaReferencia()
                            "
                        >
                            <i
                                class="fas fa-search"
                            ></i>
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    
        <div id="tables" class="row">
            <div class="col-md-6">
                <div class="mat-elevation-z1">
                    <table
                        mat-table
                        [dataSource]="ugDataSource"
                        matSort
                        matSortDisableClear
                        matSort
                        matSortActive="nome"
                        matSortDirection="asc"
                    >
                        <tr
                            mat-header-row
                            *matHeaderRowDef="[
                                'nome',
                                'actions'
                            ]"
                            class="bg-light"
                        ></tr>
    
                        <ng-container
                            matColumnDef="nome"
                        >
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                            >
                                Unidade(s) Gestora(s)
                            </th>
                            <td
                                mat-cell
                                *matCellDef="
                                    let element
                                "
                                class="text"
                            >
                                {{ element.nome }}
                            </td>
                        </ng-container>
    
                        <ng-container
                            cdkColumnDef="actions"
                        >
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                            >
                                <button
                                    [hidden]="!exibirBotoesPaacEspecial() || emAprovacao"
                                    type="button"
                                    class="
                                        btn
                                        btn-success
                                        btn-xs
                                    "
                                    (click)="
                                        exibirModalUg()
                                    "
                                    *ngIf="
                                        !paaHomologado
                                    "
                                >
                                    Adicionar
                                </button>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="
                                    let element;
                                    let i = index
                                "
                                class="block"
                            >
                                <div
                                    class="btn-group"
                                    *ngIf="
                                        !paaHomologado
                                    "
                                >
                                    <button
                                        [hidden]="!exibirBotoesPaacEspecial() || emAprovacao"
                                        type="button"
                                        class="
                                            btn
                                            btn-danger
                                            btn-xs
                                            text-left
                                        "
                                        (click)="
                                            excluirUg(i)
                                        "
                                    >
                                        <i
                                            class="
                                                far
                                                fa-trash-alt
                                            "
                                        ></i>
                                    </button>
                                </div>
                            </td>
                        </ng-container>
    
                        <tr
                            mat-row
                            *matRowDef="
                                let row;
                                columns: [
                                    'nome',
                                    'actions'
                                ]
                            "
                        ></tr>
                    </table>
                    <h5
                        [hidden]="!ugSemDados"
                        class="table-no-data"
                    >
                        Nenhuma Unidade Gestora
                        selecionada
                    </h5>
                </div>
            </div>
    
            <div class="col-md-6">
                <div class="mat-elevation-z1">
                    <table
                        mat-table
                        [dataSource]="
                            coordenacoesDataSource
                        "
                    >
                        <tr
                            mat-header-row
                            *matHeaderRowDef="[
                                'nome',
                                'actions'
                            ]"
                            class="bg-light"
                        ></tr>
    
                        <ng-container
                            matColumnDef="nome"
                        >
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                            >
                                Coordenação(ões)
                            </th>
                            <td
                                mat-cell
                                *matCellDef="
                                    let element
                                "
                            >
                                {{
                                    element.coordenacao
                                        .sigla
                                }}
                                -
                                {{
                                    element.coordenacao
                                        .nome
                                }}
                            </td>
                        </ng-container>
    
                        <ng-container
                            cdkColumnDef="actions"
                        >
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                            >
                                <button
                                [hidden]="!exibirBotoesPaacEspecial() || emAprovacao"
                                    type="button"
                                    class="
                                        btn
                                        btn-success
                                        btn-xs
                                    "
                                    (click)="
                                        exibirModalCoordenacoes()
                                    "
                                    *ngIf="
                                        !paaHomologado
                                    "
                                >
                                    Adicionar
                                </button>
                            </th>
                            <td
                                mat-cell
                                *matCellDef="
                                    let element;
                                    let i = index
                                "
                                class="block"
                            >
                                <div
                                    class="btn-group"
                                    *ngIf="
                                        !paaHomologado
                                    "
                                >
                                    <button
                                    [hidden]="!exibirBotoesPaacEspecial() || emAprovacao"
                                        type="button"
                                        class="
                                            btn
                                            btn-danger
                                            btn-xs
                                            text-left
                                        "
                                        (click)="
                                            excluirCoordenacao(
                                                i
                                            )
                                        "
                                    >
                                        <i
                                            class="
                                                far
                                                fa-trash-alt
                                            "
                                        ></i>
                                    </button>
                                </div>
                            </td>
                        </ng-container>
    
                        <tr
                            mat-row
                            *matRowDef="
                                let row;
                                columns: [
                                    'nome',
                                    'actions'
                                ]
                            "
                        ></tr>
                    </table>
                    <h5
                        [hidden]="!coordenacaoSemDados"
                        class="table-no-data"
                    >
                        Nenhuma Coordenação selecionada
                    </h5>
                </div>
            </div>
        </div>
    </div>        
    <div class="card-footer">
        <div class="btn-group" [hidden]="visualizar || !exibirBotoesPaacEspecial()" *ngIf="(aba === 0) ">
            <button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
                <i class="far fa-save"></i> Salvar
            </button>
            <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
            <div class="dropdown-menu" role="menu">
            <a class="dropdown-item" (click)="salvarDados(false)">Salvar</a>
            <a class="dropdown-item" (click)="salvarDados(true)">Salvar e fechar</a>
            </div>
        </div>


        <button
            type="button"
            class="btn btn-default btn-sm mr-1"
            (click)="cancelar()"
        >
            <i class="fas fa-times"></i> Fechar
        </button>
    </div>
</form>