import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IPaaRankingPrioridadeModel } from 'src/app/models/paa-ranking-prioridade.model';
import { RankingDistribuicaoService } from 'src/app/services/ranking-prioridade.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-ranking-prioridade',
  templateUrl: './modal-ranking-prioridade.component.html',
  styleUrls: ['./modal-ranking-prioridade.component.scss']
})
export class ModalRankingPrioridadeComponent {

    public rankingPrioridadeDataSource = new MatTableDataSource<IPaaRankingPrioridadeModel>([]);
    public rankingPrioridadeEmpresasPublicasDataSource = new MatTableDataSource<IPaaRankingPrioridadeModel>([]);
    public semDadosRanking = false;
    public semDadosRankingEmpresaPublica = false;

  constructor(
    private dialogRef: MatDialogRef<ModalRankingPrioridadeComponent>,
    public rankingService: RankingDistribuicaoService,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.obterRankingPrioridade()
  }

  public obterRankingPrioridade() {
    this.rankingService
        .obterRankingDistribuicaoSimplificado(this.data.id, this.data.indicadorId, 2)
        .then((res) => {
          
            const lista = res.dados.filter(_ => !_.unidadeGestora.empresaPublica).map(_ => ({ ..._, nome: _.unidadeGestora.nome, sigla: _.unidadeGestora.sigla, codigoUg: _.unidadeGestora.codigoUg }));
            this.rankingPrioridadeDataSource = new MatTableDataSource<IPaaRankingPrioridadeModel>(lista as unknown as IPaaRankingPrioridadeModel[]);
            this.rankingPrioridadeDataSource._updateChangeSubscription();
            this.semDadosRanking = this.rankingPrioridadeDataSource.data.length === 0;

            const listaPublicas = res.dados.filter(_ => _.unidadeGestora.empresaPublica).map(_ => ({ ..._, nome: _.unidadeGestora.nome, sigla: _.unidadeGestora.sigla, codigoUg: _.unidadeGestora.codigoUg }));
            this.rankingPrioridadeEmpresasPublicasDataSource = new MatTableDataSource<IPaaRankingPrioridadeModel>(listaPublicas as unknown as IPaaRankingPrioridadeModel[]);
            this.rankingPrioridadeEmpresasPublicasDataSource._updateChangeSubscription();
            this.semDadosRankingEmpresaPublica = this.rankingPrioridadeEmpresasPublicasDataSource.data.length === 0;

        })
        .catch((err) => {
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                text: err.mensagem.descricao,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            });
        })
}


  public concluir() {
    this.dialogRef.close();
  }

  public cancelar() {
    this.dialogRef.close();
  }
}

