import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IModeloRelatorioModel } from '../models/modelo-relatorio.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ModeloRelatorioService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<IModeloRelatorioModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<IModeloRelatorioModel[]>>(`${this.apiBaseUrl}/modelorelatorio`, { params })
      .toPromise();
  }

  public obterPorTipoTrabalho(tipoTrabalho: string, ativo: boolean, simplificado = false): Promise<IBaseModel<IModeloRelatorioModel[]>> {
    let params = new HttpParams();

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<IModeloRelatorioModel[]>>(`${this.apiBaseUrl}/modelorelatorio/${tipoTrabalho}/tipoTrabalho`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IModeloRelatorioModel>> {
    return this.httpClient
      .get<IBaseModel<IModeloRelatorioModel>>(`${this.apiBaseUrl}/modelorelatorio/${id}`)
      .toPromise();
  }

  public async inserir(data: IModeloRelatorioModel): Promise<IBaseModel<IModeloRelatorioModel>> {
    return this.httpClient
      .post<IBaseModel<IModeloRelatorioModel>>(`${this.apiBaseUrl}/modelorelatorio`, data)
      .toPromise();
  }

  public async atualizar(data: IModeloRelatorioModel): Promise<IBaseModel<IModeloRelatorioModel>> {
    return this.httpClient
      .put<IBaseModel<IModeloRelatorioModel>>(`${this.apiBaseUrl}/modelorelatorio/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/modelorelatorio/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/modelorelatorio/${id}/inativar`, {})
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/modelorelatorio/${id}/excluir/`, {})
      .toPromise();
  }
}

