import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';
import { ManterMatrizPlanejamentoService } from 'src/app/services/manter-matriz-planejamento.service';
import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-ug',
  templateUrl: './modal-ug.component.html',
  styleUrls: ['./modal-ug.component.scss']
})
export class ModalUgComponent implements OnInit {

  public dataSource = new MatTableDataSource([]);
  public displayedColumns: string[] = ['unidadeGestora'];
  public semDados = true;

  constructor(
    private matDialog: MatDialog,
    private service: ManterMatrizPlanejamentoService,
    private dialogRef: MatDialogRef<ModalUgComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.buscar()
  }

  public buscar() {
    this.service.obterUgSubtipo(this.data.id, this.data.subTipoId).then((res) => {
      if(res.sucesso){
        this.dataSource.data = res.dados
        this.dataSource.paginator = this.paginator;
		    this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor
        this.semDados = this.dataSource?.filteredData?.length === 0;
      }else{
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    })

  }

  public sortingDataAccessor = (item, property) => {
		switch (property) {
			case "unidadeGestora":
				return item?.siglaUg;
			default:
				return item[property];
		}
	};

  public fechar(){
    this.dialogRef.close()
  }

}
