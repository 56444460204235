<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/tipos-dispensa']">Tipos de Dispensa</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>
          </div>
          <form [formGroup]="form" #f="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
            <div class=" card-body">
              <div class="row">
                <div class="form-group col-md-8">
                  <label for="nome" class="mb-1">Nome</label>
                  <input [attr.disabled]="visualizar ? true : null" type="text" id="nome" class="form-control form-control-sm" formControlName="nome"
                    maxlength="100" [ngClass]="{ 'is-invalid': f.submitted && form.controls['nome'].invalid }" />
                  <div *ngIf="f.submitted && form.controls['nome'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['nome'].errors.required">Campo obrigatório</div>
                  </div>
                </div>

                <div class="form-group col-md-4">
                  <label for="nome" class="mb-1">Quant. dias Padrão</label>
                  <input [attr.disabled]="visualizar ? true : null" type="text" maxlength="10" id="inputDias" class="form-control form-control-sm"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" formControlName="quantidadeDiasPadrao" data-inputmask="'alias': 'integer'" inputmode="numeric"
                    [ngClass]="{ 'is-invalid': f.submitted &&
                    form.controls['quantidadeDiasPadrao'].invalid }" />
                  <div *ngIf="f.submitted && form.controls['quantidadeDiasPadrao'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['quantidadeDiasPadrao'].errors.required">Campo obrigatório</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-12">
                  <label for="inputDescricao" class="mb-1">Descrição</label>
                  <textarea [attr.disabled]="visualizar ? true : null" rows="3" id="inputDescricao" class="form-control form-control-sm"
                    formControlName="descricao" maxlength="500" [ngClass]="{ 'is-invalid': f.submitted &&
                    form.controls['descricao'].invalid }">
                  </textarea>
                  <div *ngIf="f.submitted && form.controls['descricao'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['descricao'].errors.required">Campo obrigatório</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <button [hidden]="visualizar" type="submit" class="btn btn-primary btn-sm mr-1">
                <i class="far fa-save"></i> Salvar
              </button>

              <button [hidden]="visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Cancelar
              </button>

              <button [hidden]="!visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Sair
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
