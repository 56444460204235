import { convertDate } from './../../../../shared/util/formatDate';
import { IAuditorModel, ISalvarAlocarEquipeModel } from './../../../../models/alocar-equipe-component';
import { IAlocarEquipeModel } from 'src/app/models/alocar-equipe-component';
import { AlocarEquipeComponent } from './../../../../shared/components/alocar-equipe/alocar-equipe.component';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import {
	MatDialog,
	MatDialogConfig,
} from "@angular/material/dialog";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import { IOsaModel } from "src/app/models/osa-model";
import { OsaService } from "src/app/services/osa.service";
import { Subscription } from "rxjs";
import { SharedService } from "src/app/services/shared.service";
import { CoordenacaoService } from "src/app/services/coordenacao.service";
import { IAuditorCoordenacaoModel } from "src/app/models/auditor-coordenacao.model";
import { SubtipoAuditoriaService } from "src/app/services/subtipo-auditoria.service";
import { defaultDataAcessor } from "src/app/core/helpers/mat-sort-data-accessor";
import { ISubtipoAuditoriaModel } from "src/app/models/subtipo-auditoria.model";
import { IPaaCoordenacaoAuditorModel } from "src/app/models/paa-coordenacao-auditor.model";
import { IPlanejamentoSubtipoAuditoriaModel } from 'src/app/models/planejamento-subtipo-auditoria.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
	selector: "app-aba-informacoes-basicas",
	templateUrl: "./aba-informacoes-basicas.component.html",
	styleUrls: ["./aba-informacoes-basicas.component.scss"],
})
export class AbaInformacoesBasicasComponent extends BaseFormComponent
	implements OnInit, AfterViewInit {
	clickEventSubscription: Subscription;
	public subTipoModel: ISubtipoAuditoriaModel[] = [] as ISubtipoAuditoriaModel[];
	public paaEquipeModel: IPaaCoordenacaoAuditorModel[] = [] as IPaaCoordenacaoAuditorModel[];
	public displayedColumns: string[] = ["auditor", "acoes"];
	public displayedColumnsSubtipo: string[] = ['nome'];
	public subtipoDataSource = new MatTableDataSource<IPlanejamentoSubtipoAuditoriaModel>([]);
	public equipeDataSource = new MatTableDataSource<IAuditorModel>([]);
	public model: IOsaModel = {} as IOsaModel;
	public coordenadoresAuditoria = [] as IAuditorCoordenacaoModel[];
	public semDados = true;
	public id: number;
	public subtipoNoData = true;
	public semDadosSubTipo = true;
	public subTipoSelecionado: number;
  public submitted: boolean = false

	public alocarEquipeModel: IAlocarEquipeModel = {} as IAlocarEquipeModel;

	@Input() public visualizar;

  public form = new FormGroup({
	dataInicioRealizacao: new FormControl("", Validators.required),
	dataFimRealizacao: new FormControl("", Validators.required),
    dataInicioPeriodoAuditado: new FormControl("", Validators.required),
    dataFimPeriodoAuditado: new FormControl("", Validators.required),
    notaRodape: new FormControl(""),
    auditor: new FormControl("", Validators.required),
	});


	constructor(
		public route: ActivatedRoute,
		public toastr: ToastrService,
		public router: Router,
		public localeService: BsLocaleService,
		public matDialog: MatDialog,
		private osaService: OsaService,
		private sharedService: SharedService,
		private coordenacaoService: CoordenacaoService,
		private subtipoService: SubtipoAuditoriaService
	) {
		super(route, toastr, router, localeService, matDialog);

		this.clickEventSubscription = this.sharedService
			.getEvent()
			.subscribe(() => {
				this.onSalvar();
			});
	}

  @ViewChild("TableOnePaginator", { static: true })
	tableOnePaginator: MatPaginator;
	@ViewChild("tabela1", { static: true }) tabela1: MatSort;

	@ViewChild("TableTwoPaginator", { static: true })
	tableTwoPaginator: MatPaginator;
	@ViewChild("tabela2", { static: true }) tabela2: MatSort;

	ngOnInit(): void {
		this.buscar();
	}


  ngAfterViewInit(): void {
    this.subtipoDataSource.paginator = this.tableOnePaginator;
		this.subtipoDataSource.sort = this.tabela1;
    this.subtipoDataSource.sortingDataAccessor = this.sortingDataAccessor
    this.equipeDataSource.paginator = this.tableTwoPaginator;
    this.equipeDataSource.sort = this.tabela2
    this.equipeDataSource.sortingDataAccessor = this.sortingDataAccessor
  }

  public buscar() {

		this.osaService
			.obterPorId(this.id)
			.then((res) => {
				if (res.sucesso) {
					this.model = res.dados;
          if(res.dados.dataInicioRealizacao) this.form.get('dataInicioRealizacao').setValue(convertDate(new Date(res.dados.dataInicioRealizacao)));
          if(res.dados.dataFimRealizacao) this.form.get('dataFimRealizacao').setValue(convertDate(new Date(res.dados.dataFimRealizacao)));
          if(res.dados.dataInicioPeriodoAuditado) this.form.get('dataInicioPeriodoAuditado').setValue(convertDate(new Date(res.dados.dataInicioPeriodoAuditado)));
          if(res.dados.dataFimPeriodoAuditado) this.form.get('dataFimPeriodoAuditado').setValue(convertDate(new Date(res.dados.dataFimPeriodoAuditado)));
          this.form.get('notaRodape').setValue(res.dados.notaRodape)
          this.form.get('auditor').setValue(res.dados.nomeAuditor)

          this.alocarEquipeModel.unidadeGestora = this.model.planejamento?.opa?.unidadeGestora;
          this.alocarEquipeModel.tipoAuditoria = this.model?.planejamento?.opa?.tipoAuditoria;

          this.subtipoDataSource.data = this.model.planejamento.planejamentoSubTipoAuditoria;
          this.subtipoDataSource.paginator = this.tableOnePaginator;
          this.subtipoDataSource.sort = this.tabela1;
          this.subtipoDataSource.sortingDataAccessor = this.sortingSubtiposDataAccessor;
				}
			}).then(() => this.obterAlocarEquipe())
			.catch((err) => {
				this.exibirMensagemErro(err?.mensagem?.descricao);
				this.router.navigate(["/osa"]);
			})
	}

  public onSalvar() {
    this.submitted = true
    if(this.form.invalid){
      this.exibirMensagemAlerta('Preencha todos os campos');
      return
    }
    const dados = {
      dataInicioRealizacao: new Date(this.form.get('dataInicioRealizacao').value),
      dataFimRealizacao: new Date(this.form.get('dataFimRealizacao').value),
      dataInicioPeriodoAuditado: new Date(this.form.get('dataInicioPeriodoAuditado').value),
      dataFimPeriodoAuditado: new Date(this.form.get('dataFimPeriodoAuditado').value),
      auditorId: this.model?.auditorId,
      notaRodape: this.form.get('notaRodape').value
    } as any
		this.osaService
			.salvarOsa(this.id, dados)
			.then((res) => {
				if (res.sucesso) {
          this.buscar()
					this.exibirMensagemSucesso(res.mensagem.descricao);
				} else {
					this.exibirMensagemErro(res.mensagem.descricao);
				}
			})
			.catch((err) => {
				this.exibirMensagemErro(err.mensagem.descricao);
			})
	}


	public obterCoordenadoresAuditoria(): Promise<IAuditorCoordenacaoModel[]> {
		return new Promise((resolve) => {
			this.coordenacaoService
				.obterCoordenadoresAtivos()
				.then((res) => {
					if (res.sucesso) {
						resolve(res.dados);
					} else {
						Swal.fire({
							toast: true,
							position: "top-end",
							icon: "error",
							text: res.mensagem.descricao,
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
					}
				})
				.catch((err) => {
					this.exibirMensagemErro(err.mensagem.descricao);
				});
		});
	}

	public teste = [] as any[];
  public obterAlocarEquipe() {
    this.osaService.obterAlocarEquipeOsaId(this.id)
      .then(res =>  {

				/*this.obterCoordenadoresAuditoria().then(a => {
				 this.teste = (this.model.planejamento.planejamentoEquipe as unknown as any[]).map(b => {
					 var result = a.find(c => c.auditorId === b.auditorId);
					 if(Object.keys(result ?? {})?.length > 0) {
						 return result;
					 }
				 })
				}).then(() => {*/

				//	res.dados = [...res.dados, ...this.teste]
					let auditoresSelecionados = res.dados.map(a => {
						return { auditorId: a.auditorId, nomeAuditor: a.nomeAuditor, id: a.id } as IAuditorModel
					})
					const [dataInicio, dataFim] = [res?.dados[0]?.dataInicio, res?.dados[0]?.dataFim];

					// Remover items duplicados
					auditoresSelecionados = auditoresSelecionados.filter(function (a) {
						return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
					}, Object.create(null))

					this.equipeDataSource.data = auditoresSelecionados;
					this.alocarEquipeModel.auditoresSelecionados = auditoresSelecionados;
					if (dataInicio?.toString()) this.alocarEquipeModel.dataInicio = new Date(dataInicio.toString());
					if (dataFim?.toString()) this.alocarEquipeModel.dataFim = new Date(dataFim.toString());
			//	})

      })
  }

	public async exibirModalAlocarEquipe() {
		try {

			const dialogConfig = new MatDialogConfig();
			dialogConfig.id = "modal-component";
			dialogConfig.width = "650px";
			dialogConfig.maxHeight = "80vh";
			dialogConfig.hasBackdrop = true;
			dialogConfig.disableClose = true;
			dialogConfig.data = this.alocarEquipeModel ;
			const modal = this.matDialog.open(
				AlocarEquipeComponent,
				dialogConfig
			);
			modal.afterClosed().subscribe((sucesso: IAlocarEquipeModel ) => {
				if (sucesso) {
					this.equipeDataSource.data = sucesso?.auditoresSelecionados;
					this.alocarEquipeModel.auditoresSelecionados = sucesso?.auditoresSelecionados;
					this.semDados = this.equipeDataSource.filteredData.length === 0;
          this.equipeDataSource.paginator = this.tableTwoPaginator;
          this.equipeDataSource.sort = this.tabela2;
          this.equipeDataSource.sortingDataAccessor = this.sortingDataAccessor;

          const dados: ISalvarAlocarEquipeModel = {
            auditorId: this.alocarEquipeModel.auditoresSelecionados.map(item => item.auditorId),
            dataInicio: new Date(sucesso?.dataInicio),
            dataFim: new Date(sucesso?.dataFim)
          } as ISalvarAlocarEquipeModel;

          this.osaService.salvarAlocarEquipeOsaId(this.id, dados).then(res => {
            if (res.sucesso) {
              this.buscar();
              this.exibirMensagemSucesso(res.mensagem.descricao)
            } else {
              this.exibirMensagemSucesso(res.mensagem.descricao)
            }
          })
				}
			});
		} catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);

		}
	}

  public desvincularAuditor(element: number) {
    this.osaService.desvincularAuditorId(this.id, element).then(res => {
      res.sucesso ? this.exibirMensagemSucesso(res.mensagem.descricao) : this.exibirMensagemErro(res.mensagem.descricao);
    }).then(() => this.buscar())
  }



	//Adiciona Subtipos de Auditoria

	public async exibirModalSubtipo() {
		try {
			const res = await this.subtipoService.obterAtivos();
			if (res.sucesso) {
				const modal = this.exibirModal(
					"Selecionar Subtipo de Auditoria",
					res.dados
				);
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.subTipoModel.push(data)
					}
				});
			} else {
				this.exibirMensagemErro(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

  public sortingDataAccessor = (item, property) => {
		switch (property) {
			case "auditor":
				return item?.nomeAuditor;
			case "nome":
				return item?.nome;
			default:
				return item[property];
		}
	};

  public sortingSubtiposDataAccessor = (item, property) => {
		switch (property) {
			case "nome":
				return item?.subTipoAuditoria?.nome;
			default:
				return item[property];
		}
	};

	public atualizarListaSubtipos() {

		this.subtipoDataSource.sortingDataAccessor = defaultDataAcessor;
		this.subtipoDataSource._updateChangeSubscription();
		this.semDadosSubTipo = this.subtipoDataSource.filteredData.length === 0;
	}

	// Aloca Equipe

	public async exibirModalCoordenadorAuditoria() {
		try {
			const res = await this.coordenacaoService.obterCoordenadoresAtivos();
			if (res.sucesso) {
				var newCordenador = res.dados.map(item => {
					return {id: item.auditorId, nome: item.nomeAuditor}
				})
				const modal = this.exibirModal(
					"Selecionar Coordenador da Auditoria",
					newCordenador,
          false
				);
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.model.auditorCoordenacao = data;
            this.model.auditorId = data?.id
						this.form.controls['auditor'].setValue(data?.nome)
					}
				});
			} else {
				this.exibirMensagemErro(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}


}
