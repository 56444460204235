<div id="modal-content-wrapper">
	<div class="card card-secondary card-outline">
		<div class="card-header bg-light">
			<h4 class="card-title mb-0">{{ tituloModal }}</h4>
			<div class="card-tools">
				<button type="button" class="btn btn-tool" (click)="cancelar()">
					<i class="fas fa-times"></i>
				</button>
			</div>
		</div>
		<div class="card-body p-0 wrapper">
			<div class="table-container p-3">
				<div class="row">
					<div class="form-group col-md-3">
						<label for="codigoUg" class="mb-1">Código UG</label>
					<input
                          class="form-control form-control-sm"
                          [attr.disabled]="true"
						  [ngModel]="codigo"
                          />
					</div>
					<div class="form-group col-md-3">
						<label for="siglaUg" class="mb-1">Sigla UG</label>
					<input
                          class="form-control form-control-sm"
                          [attr.disabled]="true"
						  [ngModel]="sigla"
                          />
					</div>
					<div class="form-group col-md-6">
						<label for="nomeUg" class="mb-1">Nome UG</label>
					<input
                          class="form-control form-control-sm"
                          [attr.disabled]="true"
						  [ngModel]="nome"
                          />
					</div>
				</div>
				<div class="row">
					<div class="form-group col-md-12">
						<div>
							<!-- <section class="content-header">
								<div class="container-fluid">
									<div class="row mb-2">
										<div class="col-sm-6">
                                            <h5 [hidden]="!semDados" class="table-no-data">
                                                Sem dados a exibir
                                            </h5>
										</div>
									</div>
								</div>
							</section> -->
							

							<table mat-table [dataSource]="dataSource" class="w-100">
								<tr
										mat-header-row
										*matHeaderRowDef="[
											'pessoaNome',
											'dataInclusao',
											'maturidade'
										]"
									></tr>

									<ng-container matColumnDef="pessoaNome">
										<th mat-header-cell *matHeaderCellDef>Alterado por</th>
										<td mat-cell *matCellDef="let element">
											{{ element.pessoaNome }}
										</td>
									</ng-container>

									<ng-container matColumnDef="dataInclusao">
										<th mat-header-cell *matHeaderCellDef>Data da alteração</th>
										<td mat-cell *matCellDef="let element">
											{{ element.dataInclusao | date: 'dd MMM yyyy HH:mm'}}
										</td>
									</ng-container>

									<ng-container matColumnDef="maturidade">
										<th mat-header-cell *matHeaderCellDef>Resultado da Avaliação</th>
										<td mat-cell *matCellDef="let element">
											{{ element.unidadeGestoraMaturidadeGR.nome }}
										</td>
									</ng-container>

									<tr
										mat-header-row
										*matRowDef="
											let row;
											columns: [
												'pessoaNome',
												'dataInclusao',
												'maturidade'
											]
										"
										class="bg-light"
									></tr>
							</table>
							<h5 [hidden]="!semDados" class="table-no-data">
								Sem dados a exibir
							</h5>

								<!-- <div
									class="timeline m-0"
									
								>
									<div *ngFor="let element of historicoDados">
										<i class="fas fa-user bg-info"></i>
										<div class="timeline-item">
											<span class="time"
												><i class="fas fa-clock"></i>
												{{element.dataInclusao | date: 'dd MMM yyyy HH:mm'}}</span
											>

											<h3 class="timeline-header">
												<a> Alterado para: {{ element.unidadeGestoraMaturidadeGR.nome }}</a>
											</h3>
                                            <div class="timeline-body">Usuário: {{ element.pessoaNome }}
                                            </div>
										</div>
									</div>
								</div> -->
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<button
				type="button"
				class="btn btn-default btn-sm"
				(click)="cancelar()"
			>
				<i class="fas fa-times"></i> Fechar
			</button>
		</div>
	</div>
</div>
