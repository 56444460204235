import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { IAprovacaoModel } from 'src/app/models/aprovacao.model';
import { IEnumModel } from 'src/app/models/enum.model';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IFiltroAprovacao } from 'src/app/models/filtros/filtro-aprovacao.model';
import { IFiltroLog } from 'src/app/models/filtros/filtro-log.model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AprovacaoService } from 'src/app/services/aprovacao.service';
import { AuthService } from 'src/app/services/auth.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';
import { ModalAvaliarComponent } from './modal-avaliar/modal-avaliar.component';

const sortingDataAccessor = (item, property) => {
    switch (property) {
        case 'tipoTrabalho':
            return item.tipoDocumentoAprovacao.descricao;
        case 'situacao':
            return item.situacao.nome;
        default:
            return item[property];
    }
};

@Component({
    selector: 'app-aprovacao-list',
    templateUrl: './aprovacao-list.component.html',
    styleUrls: ['./aprovacao-list.component.scss'],
})
export class AprovacaoListComponent extends BaseListComponent implements OnInit {
    public model: IAprovacaoModel = {} as IAprovacaoModel;
    public dataSource = new MatTableDataSource<IAprovacaoModel>([]);
    public selectedModel: IAprovacaoModel;
    public displayedColumns: string[] = ['selecao', 'tipoTrabalho', 'nome', 'situacao'];
    public searchNome: string;
    public searchTodos: string;
    public semDados = true;
    public searchSituacaoDocumento: number;
    public searchTipoDocumento: number;

    public pageEvent: any;
    private filtro: IFiltroAprovacao = {} as IFiltroAprovacao;
    public totalItens: number;

    public filtradoPor = 'Todos';
    public chamadaPendencia = false;

    @ViewChild('TableOnePaginator', { static: true })
    tableOnePaginator: MatPaginator;
    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;
    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    constructor(
        private aprovacaoService: AprovacaoService,
        private router: Router,
        public matDialog: MatDialog,
        public authService: AuthService,
        public route: ActivatedRoute
    ) {
        super();
        const id = this.route.snapshot.paramMap.get("id");
        if (id != undefined && id != "") {
            this.chamadaPendencia = true;
        }
    }

    ngOnInit(): void {
        this.setarPermissoes();
    }

    private setarPermissoes(): void {
        this.permissoes = new IPermissaoModel();
        this.permissoes.consultar = this.authService.possuiPermissao(
            EnumRecursoPerfil.Aprovacoes,
            EnumAcaoRecurso.Consultar
        );
        this.permissoes.relatoria = this.authService.possuiPermissao(
            EnumRecursoPerfil.Aprovacoes,
            EnumAcaoRecurso.Relatoria
        );
        this.permissoes.avaliar = this.authService.possuiPermissao(
            EnumRecursoPerfil.Aprovacoes,
            EnumAcaoRecurso.Avaliar
        );
        this.permissoes.visualizar = this.authService.possuiPermissao(
            EnumRecursoPerfil.Aprovacoes,
            EnumAcaoRecurso.Visualizar
        );
    }

    ngAfterViewInit() {
        this.configFiltro();
        this.buscar();
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = sortingDataAccessor;
    }

    public buscar() {
        this.configFiltro();
        this.selectedModel = null;

        this.aprovacaoService
            .obter(this.filtro)
            .then((res) => {
                this.dataSource = new MatTableDataSource<IAprovacaoModel>(res.dados.resultado);
                this.dataSource.sort = this.sort;
                this.dataSource.sortingDataAccessor = defaultDataAcessor;
                this.dataSource.sortingDataAccessor = sortingDataAccessor;
                this.totalItens = res.dados.totalItens;
                this.dataSource._updateChangeSubscription();
                this.semDados = this.dataSource.filteredData.length === 0;
                if (this.chamadaPendencia) {
                    var id = parseInt(this.route.snapshot.paramMap.get("id"));
                    var tipoDocumentoAprovacao = parseInt(this.route.snapshot.paramMap.get("tipoDocumentoAprovacao"));
                    this.selectedModel = res.dados.resultado.filter(d => d.id == id && d.tipoDocumentoAprovacao.codigo == tipoDocumentoAprovacao)[0];
                    if (this.selectedModel == undefined) {
                        this.tableOnePaginator.pageIndex = this.tableOnePaginator.pageIndex + 1;
                        this.buscar();
                    } else {
                        this.chamadaPendencia = false;
                        if (this.selectedModel?.situacao?.codigo != 4)
                            this.modalAvaliar();
                        else {
                            var id = parseInt(this.route.snapshot.paramMap.get("id"));
                            this.linhaTempo(id);
                        }
                    }
                }
            })
            .catch((err) => {
                this.exibirMensagemAlerta(err.mensagem.descricao);
            })
            .finally(() => { });
    }

    public selecionar(item: any) {
        this.selectedModel = !this.selectedModel || this.selectedModel !== item ? item : null;
    }

    public modalAvaliar() {
        try {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.id = 'modal-avaliar';
            dialogConfig.width = '700px';
            dialogConfig.hasBackdrop = true;
            dialogConfig.disableClose = true;
            dialogConfig.data = {
                id: this.selectedModel.id,
                tipoDocumento: this.selectedModel.tipoDocumentoAprovacao,
            };

            const modal = this.matDialog.open(ModalAvaliarComponent, dialogConfig);
            modal.afterClosed().subscribe((data) => {
                this.buscar();
            });
        } catch (err) {
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                text: err.mensagem.descricao,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            });
        } finally {
        }
    }

    public linhaTempo(id: number) {
        // PEAC
        if (this.selectedModel.tipoDocumentoAprovacao.codigo === 1) {
            this.router.navigate([`/pea/${id}/visualizar`], { queryParams: { aba: 6 } });
        }

        // PAAC
        if (this.selectedModel.tipoDocumentoAprovacao.codigo === 2) {
            this.router.navigate([`/paa/${id}/visualizar`], { queryParams: { aba: 6 } });
        }

        // OPA
        if (this.selectedModel.tipoDocumentoAprovacao.codigo === 3) {
            this.router.navigate([`/opa/${id}/visualizar`], { queryParams: { aba: 6 } });
        }

        // OSA
        if (this.selectedModel.tipoDocumentoAprovacao.codigo === 4) {
            this.router.navigate([`/osa/${id}/visualizar`], { queryParams: { aba: 6 } });
        }

        // Relatório de Auditoria
        if (this.selectedModel.tipoDocumentoAprovacao.codigo === 6) {
            this.router.navigate([`/auditoria/${id}/visualizar`], { queryParams: { aba: 6 } });
        }

        // Planejamento
        if (this.selectedModel.tipoDocumentoAprovacao.codigo === 7) {
            this.router.navigate([`/planejamento/${id}/visualizar`], { queryParams: { aba: 6 } });
        }
    }

    public relatoria(id: number) {
        if (this.selectedModel.tipoDocumentoAprovacao.codigo === 1) {
            this.router.navigate([`/pea/${id}/visualizar`], { queryParams: { aba: 5 } });
        } else if (this.selectedModel.tipoDocumentoAprovacao.codigo === 2) {
            this.router.navigate([`/paa/${id}/visualizar`], { queryParams: { aba: 5 } });
        } else if (this.selectedModel.tipoDocumentoAprovacao.codigo === 3) {
            this.router.navigate([`/opa/${id}/visualizar`], { queryParams: { aba: 5 } });
        } else if (this.selectedModel.tipoDocumentoAprovacao.codigo === 7) {
            this.router.navigate([`/planejamento/${id}/visualizar`], { queryParams: { aba: 5 } });
        } else if (this.selectedModel.tipoDocumentoAprovacao.codigo === 4) {
            this.router.navigate([`/osa/${id}/visualizar`], { queryParams: { aba: 5 } });
        }
    }

    configFiltro() {
        this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
        this.filtro.itensPorPagina = this.tableOnePaginator.pageSize;
        this.filtro.colunaOrder = this.sort.active;
        this.filtro.direcao = this.sort.direction;
        this.filtro.searchSituacaoDocumento = this.searchSituacaoDocumento;
        this.filtro.searchTipoDocumento = this.searchTipoDocumento;
        this.filtro.pesquisa = this.searchNome;
    }

    sortData(sort) {
        this.filtro.colunaOrder = sort.active;
        this.filtro.direcao = sort.direction;

        this.buscar();
    }

    pageChanged(e) {
        this.filtro.pagina = e.pageIndex + 1;
        e.pageIndex = this.filtro.pagina;
        this.filtro.itensPorPagina = e.pageSize;
        this.buscar();
    }
}
