import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { ICustoRecursoPlanejamentoModel } from '../models/custo-recurso-planejamento.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CustoRecursoPlanejamentoService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public async inserir(data: ICustoRecursoPlanejamentoModel, tipoTrabalho: string): Promise<IBaseModel<void>> {
    if(tipoTrabalho === 'planejamento'){
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/custoRecursoPlanejamento`, data)
      .toPromise();
    }
    if(tipoTrabalho === 'osa'){
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/custoRecursoOsa`, data)
      .toPromise();
    }
  }


  public async obterPorId(id: number, tipoTrabalho: string): Promise<IBaseModel<ICustoRecursoPlanejamentoModel[]>> {
    if(tipoTrabalho === 'planejamento'){
      return this.httpClient
      .get<IBaseModel<ICustoRecursoPlanejamentoModel[]>>(`${this.apiBaseUrl}/custoRecursoPlanejamento/${id}`)
      .toPromise();
    }
    if(tipoTrabalho === 'osa'){
      return this.httpClient
      .get<IBaseModel<ICustoRecursoPlanejamentoModel[]>>(`${this.apiBaseUrl}/custoRecursoOsa/osa/${id}`)
      .toPromise();
    }
  }

  public async excluir(id: number, tipoTrabalho: string): Promise<IBaseModel<void>> {
    if(tipoTrabalho === 'planejamento'){
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/custoRecursoPlanejamento/${id}`)
      .toPromise();
    }
    if(tipoTrabalho === 'osa'){
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/custoRecursoOsa/${id}`)
      .toPromise();
    }

  }

  public async atualizar(data: ICustoRecursoPlanejamentoModel, tipoTrabalho: string): Promise<IBaseModel<void>> {
    if(tipoTrabalho === 'planejamento'){
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/custoRecursoPlanejamento/${data.id}`, data)
      .toPromise();
    }
    if(tipoTrabalho === 'osa'){
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/custoRecursoOsa/${data.id}`, data)
      .toPromise();
    }
  }

}
