<div class="card-header bg-light">
	<h4 class="card-title mb-0">Incluir UG</h4>
</div>
<div class="card-body wrapper">
		<div class="row">
			<div class="col-sm-5">
				<label class="form-label">Selecionar UG</label>
				<ng-select
					id="unidadeGestora"
					[(ngModel)]="ugSelecionada"
					(change)="obterResponsaveis(ugSelecionada.value)"
          [ngModelOptions]="{ standalone: true }"
				>
					<ng-option
						*ngFor="let item of unidadesGetoras"
						[value]="item"
					>
						{{ item.text }}
					</ng-option>
				</ng-select>
			</div>
			<div class="col-sm-5">
				<label class="form-label">Selecionar Responsável </label>
				<ng-select
					id="responsavel"
					[(ngModel)]="responsavelSelecionado"
          [ngModelOptions]="{ standalone: true }"
				>
					<ng-option *ngFor="let item of responsaveis" [value]="item">
						{{ item.text }}
					</ng-option>
				</ng-select>
			</div>
			<div class="col-sm-2">
        <div class="form-group">
          <label class="form-label">ㅤ</label>
          <button
            [disabled]="!ugSelecionada || !responsavelSelecionado "
            type="button"
            class="btn form-control form-control-sm btn-success btn-sm"
            (click)="adicionarUg()"
          >
            Adicionar
          </button>
        </div>
			</div>
		</div>
	<div class="row mt-2" *ngIf="dataSourceUG.data.length > 0">
		<div class="col-sm-12">
			<div class="mat-elevation-z1">
				<table
					mat-table
					[dataSource]="dataSourceUG"
					matSortDisableClear
					#tabela1="matSort"
					matSort
					matSortDirection="asc"
				>
					<tr
						mat-header-row
						*matHeaderRowDef="['ug', 'responsavel', 'actions']"
						class="bg-light"
					></tr>

					<ng-container matColumnDef="ug">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							Unidade(s) Gestora(s)
						</th>
						<td mat-cell *matCellDef="let element" class="text">
							{{ element?.unidadeGestora?.text }}
						</td>
					</ng-container>

					<ng-container matColumnDef="responsavel">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							Responsável
						</th>
						<td mat-cell *matCellDef="let element" class="text">
							{{ element?.responsavel?.text }}
						</td>
					</ng-container>

					<ng-container cdkColumnDef="actions">
						<th
							mat-header-cell
							*matHeaderCellDef
							class="text-center"
						>
							Ação
						</th>
						<td
							mat-cell
							*matCellDef="let element; let i = index"
							class="block"
						>
							<div
								class="
									d-flex
									w-100
									justify-content-center
									align-items-center
								"
							>
								<button
									type="button"
									class="btn btn-danger btn-xs text-left"
									(click)="excluirUg(i, element.id)"
								>
									<i class="far fa-trash-alt"></i>
								</button>
							</div>
						</td>
					</ng-container>

					<tr
						mat-row
						*matRowDef="
							let row;
							columns: ['ug', 'responsavel', 'actions']
						"
					></tr>
				</table>
				<h5
					class="table-no-data"
					[hidden]="dataSourceUG?.data?.length > 0"
				>
					Nenhum registro encontrado
				</h5>
				<mat-paginator
					#TableOnePaginator="matPaginator"
					[pageSizeOptions]="[5]"
					showFirstLastButtons
				>
				</mat-paginator>
			</div>
		</div>
	</div>
</div>
<div class="card-footer">
	<button
		type="submit"
		class="btn btn-primary btn-sm mr-1"
		[disabled]="dataSourceUG?.data?.length === 0"
		(click)="salvar()"
	>
		<i class="far fa-save"></i> Salvar
	</button>
	<button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
		<i class="fas fa-times"></i> Cancelar
	</button>
</div>
