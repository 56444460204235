<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-12">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/']">
                            <i class="fas fa-home"></i> Página Inicial</a>
                    </li>
                    <li class="breadcrumb-item active">Solicitação Técnica</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-lightblue">
                    <div class="card-header">
                        <h3 class="card-title mb-0">
                            <i class="fas fa-table pr-2"></i>Listagem de Solicitação Técnica
                        </h3>
                    </div>

                    <div class="card-body p-2">
                        <form [formGroup]="form">
                        <div class="row">
                            <div class="col-md-3 form-group">
                                <label for="prazoResposta" class="mb-1">Prazo Resposta</label>
                                <div class="input-group input-group-sm" style="width: 100%;">
                                    <input type="date" id="prazoResposta" placeholder="Datepicker" bsdatepicker="" 
                                        class="form-control ng-pristine ng-valid ng-touched" formControlName="prazoResposta">
                                </div>
                            </div>
                            <div class="col-md-3 form-group">
                                <label for="numeroOPA" class="mb-1">Número OPA</label>
                                <input type="text" id="numeroOPA" placeholder="Número OPA" formControlName="numeroOpa" class="form-control form-control-sm">
                            </div>
                            <div class="col-md-3 form-group">
                                <label for="numeroOSA" class="mb-1">Número OSA</label>
                                <input type="text" id="numeroOSA" placeholder="Número OSA" formControlName="numeroOsa" class="form-control form-control-sm">
                            </div>
                            <div class="col-md-3 form-group">
                                <label for="numeroSolicitacao" class="mb-1">Número Solicitação</label>
                                <input type="text" id="numeroSolicitacao" placeholder="Número Solicitação" formControlName="numeroSolicitacao" class="form-control form-control-sm">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 form-group">
                                <label for="assuntoSolicitacao" class="mb-1">Assunto</label>
                                <input type="text" id="assuntoSolicitacao" placeholder="Assunto Solicitação" formControlName="assuntoSolicitacao" class="form-control form-control-sm">
                            </div>

                            <div class="col-sm-3">
                                <label for="selectSubtipoAuditoria" class="form-label">Status</label>
                                <ng-select placeholder="Selecionar" formControlName="status">
                                    <ng-option *ngFor="let status of arrayStatus" [value]="status.codigo">{{status.descricao}}</ng-option>
                                </ng-select>
                            </div>
                                
                            <div class="col-sm-4">
                                <label class="form-label">Responsável</label>
                                <ng-select placeholder="Selecionar" formControlName="responsavelId">
                                    <ng-option *ngFor="let responsavel of responsaveis" [value]="responsavel.value">{{responsavel.text}}</ng-option>
                                </ng-select>
                            </div>
                        </div>

                        <div class="mat-elevation-z4">
                            <mat-toolbar class="table-header">
                                <mat-toolbar-row>
                                    <button type="button" class="btn btn-primary btn-sm mr-1" *ngIf="selectedModel?.status?.codigo ===  3 || selectedModel?.status?.codigo === 7 || selectedModel?.status?.codigo === 6" (click)="responder(selectedModel.id)">
                                        <i class="far fas fa-pen"></i> Responder
                                    </button>

                                      <button type="button" class="btn btn-info btn-sm mr-1" [disabled]="!selectedModel" (click)="visualizar(selectedModel.id)">
                                      <i class="far fas fa-eye"></i>
                                      Visualizar
                                      </button>

                                    <!--Botões de ações -->
                                    <span class="fill-remaining-space"></span>

                                    <button type="button" (click)="filtrar()" class="btn btn-info btn-sm mr-1">
                                        <i class="fas fa-search"></i>
                                        Pesquisar
                                      </button>

                                </mat-toolbar-row>
                            </mat-toolbar>
                        </div>
                    </form>

                    <div class="mat-elevation-z4">
                            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear class="w-100 mat-elevation-z8" matSortActive="docAssociado" matSortDirection="desc" [hidden]="semDados">

                                <ng-container matColumnDef="selecao">
                                    <th mat-header-cell *matHeaderCellDef> </th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-checkbox (click)="$event.stopPropagation()" color="primary" (change)="selectedModel = ($event.checked ? element : null);" [checked]="selectedModel?.id === element.id">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="documentoAssociado">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Nº Doc. Associado</th>
                                    <td mat-cell *matCellDef="let element"> {{element?.documentoAssociado}} </td>
                                </ng-container>

                                <ng-container matColumnDef="numero">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Solicitação</th>
                                    <td mat-cell *matCellDef="let element"> {{element?.numero}} </td>
                                </ng-container>

                                <ng-container matColumnDef="prazo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Prazo de Resposta</th>
                                    <td mat-cell *matCellDef="let element"> {{element?.prazo | date:'dd/MM/yyyy'}} </td>
                                </ng-container>

                                <ng-container matColumnDef="assunto">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Assunto da Solicitação</th>
                                    <td mat-cell *matCellDef="let element"><a class="e2e-inner-html-bound" [innerHTML]="element?.assunto"></a></td>
                                </ng-container>

                                <ng-container matColumnDef="responsavelDelegacao">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Responsável por Delegação</th>
                                    <td mat-cell *matCellDef="let element"> {{ (element?.responsavelId) }} </td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
                                    <td mat-cell *matCellDef="let element">
                                      <span *ngIf="element?.status?.codigo === EnumStatusSolicitacaoTecnica.EmElaboracao" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
                                      <span *ngIf="element?.status?.codigo === EnumStatusSolicitacaoTecnica.Finalizada" class="badge bg-info text-xs">{{element?.status?.descricao}}</span>
                                      <span *ngIf="element?.status?.codigo === EnumStatusSolicitacaoTecnica.Enviada" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
                                      <span *ngIf="element?.status?.codigo === EnumStatusSolicitacaoTecnica.Cancelada" class="badge bg-danger text-xs">{{element?.status?.descricao}}</span>
                                      <span *ngIf="element?.status?.codigo === EnumStatusSolicitacaoTecnica.SolicitacaoProrrogacao" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
                                      <span *ngIf="element?.status?.codigo === EnumStatusSolicitacaoTecnica.Prorrogada" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
                                      <span *ngIf="element?.status?.codigo === EnumStatusSolicitacaoTecnica.AtendidaParcialmente" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
                                      <span *ngIf="element?.status?.codigo === EnumStatusSolicitacaoTecnica.AtendidaTotalmente" class="badge bg-primary text-xs">{{element?.status?.descricao}}</span>
                                      <span *ngIf="element?.status?.codigo === EnumStatusSolicitacaoTecnica.NaoAtendidaParcialmente" class="badge bg-secondary text-xs">{{element?.status?.descricao}}</span>
                                      <span *ngIf="element?.status?.codigo === EnumStatusSolicitacaoTecnica.NaoAtendidaTotalmente" class="badge bg-secondary text-xs">{{element?.status?.descricao}}</span>
                                      <span *ngIf="element?.status?.codigo === EnumStatusSolicitacaoTecnica.Pendente" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
                                      <span *ngIf="element?.status?.codigo === EnumStatusSolicitacaoTecnica.ProrrogacaoRejeitada" class="badge bg-danger text-xs">{{element?.status?.descricao}}</span></td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selecionar(row)" class="clickable"
                                [ngClass]="{'bg-light': selectedModel?.id === row.id}">
                                </tr>

                            </table>
                            <h5 [hidden]="!semDados" class="table-no-data">Sem dados a exibir</h5>
                            <mat-paginator [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons (page)="selectedModel = null; pageEvent = pageChanged($event)"></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
