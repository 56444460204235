<!-- ABA INFORMAÇÕES BÁSICAS -->
<!-- <form [formGroup]="form" #f="ngForm" > -->
<div class="card-body">
	<div class="row">
		<div class="col-md-12">
			<mat-toolbar class="table-header">
				<mat-toolbar-row>
					<button
						type="button"
						class="btn btn-primary btn-xs"
						(click)="abrirModalDispensa(null)"
					>
						<i class="fas fa-plus"></i>
						Adicionar dispensa
					</button>
					<span class="fill-remaining-space"></span>
					<div
						class="input-group input-group-sm"
						style="width: 200px"
					>
						<!-- <label class="mb-1">Ano</label> -->
						<select
							id="selectUnidade"
							[(ngModel)]="selectedYear"
							(change)="onChangeSelectAno($event)"
							[ngModelOptions]="{ standalone: true }"
							class="mat-form-ano form-control form-control-sm"
						>
							<option
								*ngFor="let item of selectedModelYears"
								[ngValue]="item"
							>
								{{ item }}
							</option>
						</select>
					</div>
				</mat-toolbar-row>
			</mat-toolbar>
			<table mat-table [dataSource]="dispensasDataSource" class="w-100">
				<tr
					mat-header-row
					*matHeaderRowDef="[
						'tipoDispensa',
						'dataInicio',
						'dataFim',
						'qtdDias',
						'actions'
					]"
				></tr>

				<ng-container matColumnDef="tipoDispensa">
					<th mat-header-cell *matHeaderCellDef>Descrição</th>
					<td mat-cell *matCellDef="let element">
						{{ element.tipoDispensa.nome }}
					</td>
				</ng-container>

				<ng-container matColumnDef="dataInicio">
					<th mat-header-cell *matHeaderCellDef>Inicio</th>
					<td mat-cell *matCellDef="let element">
						{{ element.dataInicioFormatted }}
					</td>
				</ng-container>
				<ng-container matColumnDef="dataFim">
					<th mat-header-cell *matHeaderCellDef>Fim</th>
					<td mat-cell *matCellDef="let element">
						{{ element.dataFimFormatted }}
					</td>
				</ng-container>
				<ng-container matColumnDef="qtdDias">
					<th mat-header-cell *matHeaderCellDef>Qtd. Dias</th>
					<td mat-cell *matCellDef="let element">
						{{ element.qtdDias }}
					</td>
				</ng-container>
				<ng-container cdkColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td
						mat-cell
						*matCellDef="let element; let i = index"
						class="block"
					>
					<button
							[hidden]="visualizar"
							type="button"
							class="btn btn-info btn-sm mr-1"
							(click)="abrirModalDispensa(element)"
						>
							<i class="far fas fa-eye"></i>
						</button>
						<button
							[hidden]="visualizar"
							type="button"
							class="btn btn-danger btn-sm mr-1"
							(click)="excluirDispensa(element.id)"
						>
							<i class="far fa-trash-alt"></i>
						</button>
					</td>
				</ng-container>
				<tr
					mat-header-row
					*matRowDef="
						let row;
						columns: [
							'tipoDispensa',
							'dataInicio',
							'dataFim',
							'qtdDias',
							'actions'
						]
					"
					class="bg-light"
				></tr>
			</table>
			<h5 [hidden]="!semDados" class="table-no-data">
				Sem dados a exibir
			</h5>
		</div>
	</div>
</div>
<!-- </form> -->
