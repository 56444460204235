<div class="modal-header">
	<div class="icon-container">
		<mat-icon class="warning-icon" color="warn">error_outline</mat-icon>
	</div>
</div>
<div class="modal-body">
	<h2>Remover sub-pergunta</h2>
	<p>
		Tem certeza que deseja remover esta condição para habilitação da
		sub-pergunta? Esta ação não poderá ser desfeita!
	</p>
</div>
<div class="modal-footer">
	<button mat-button class="btn-confirmar" (click)="onConfirm()">Sim</button>
	<button mat-button class="btn-cancelar" (click)="onCancel()">Não</button>
</div>
