import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IRelatoriaCapituloModel } from 'src/app/models/relatoria-capitulo.model';
import { IRelatoriaModel } from 'src/app/models/relatoria.model';
import { RelatoriaService } from 'src/app/services/relatoria.service';
import { Location } from '@angular/common';
import { BaseFormComponent } from '../../base-form/base-form.component';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { AprovacaoService } from 'src/app/services/aprovacao.service';
import { IBaseModel } from 'src/app/models/base.model';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';


@Component({
  selector: 'app-visualizar-relatoria',
  templateUrl: './visualizar-relatoria.component.html',
  styleUrls: ['./visualizar-relatoria.component.scss']
})

export class VisualizarRelatoriaComponent extends BaseFormComponent implements OnInit {
  relatoriaDados: IRelatoriaCapituloModel[];
  public dadosObtidos: IRelatoriaModel;
  public removeMinuta: boolean = true;
  visualizarRelatoriaHidden = false;
  tipoTrabalho;
  activatedRoute: any;
  @Output() public abaRelatoria: EventEmitter<number> = new EventEmitter<number>();
  @Output() public voltarAbaRelatoria: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private relatoriaService: RelatoriaService,
    private aprovacaoService: AprovacaoService,
    public matDialog: MatDialog,
  ) {
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {

    this.obter();
  }

  public async obter() {
    let res: IBaseModel<IRelatoriaModel>;
    
    if (this.id) {

       
        const avaliacao = await this.aprovacaoService.imprimirRelatoria(this.id);
        res = {
          sucesso: avaliacao.sucesso,
          dados: JSON.parse(avaliacao.dados),
          mensagem: avaliacao.mensagem,
          resultadoValidacao: avaliacao.resultadoValidacao
        } as IBaseModel<IRelatoriaModel>;
 
      if (res.sucesso) {        
        this.relatoriaDados = res.dados.relatoriasCapitulos;
        this.dadosObtidos = res.dados;
        
        if (this.dadosObtidos?.tipoTrabalhoStatus.codigo == 4)
              this.removeMinuta = false;
        if (Number(this.dadosObtidos?.tipoTrabalhoStatus) === 4) {
          this.removeMinuta = false;
        }
      }
      else {
        this.toastr.error(res.mensagem.descricao, 'Atenção');
        return null;
      }
    }
    this.visualizarRelatoriaHidden = true;
  }

  print(): void {
    window.print();
  }

  backClicked() {
    window.close()
  }

  VoltarRelatoria() {
    this.abaRelatoria.emit(5);
    this.voltarAbaRelatoria.emit(true);
    this.router.navigate(['/pea/', this.id]);    
  }
}
