<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/agente-publico']">Agente público</a>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header d-flex pb-0 flex-column">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>

            <ul class="nav nav-tabs ml-auto p-0 mt-2">
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 0 }"
									(click)="mudarAba(0)"
									>Dados básicos</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 1 }"
									(click)="mudarAba(1)"
									>Disponibilidade</a
								>
							</li>
              <li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 2 }"
									(click)="mudarAba(2)"
									>Dispensas</a
								>
							</li>
            </ul>

          </div>
          <form [formGroup]="form" #f="ngForm" (keydown.enter)="$event.preventDefault()">
            <div class="card-body pb-0">
              <div #abaDados [hidden]="aba !== 0">
                <div>
                  <app-aba-dados-basicos>
                  </app-aba-dados-basicos>
                </div>
            </div>

              <div #abaDisponibilidade [hidden]="aba !== 1">
                <div>
                  <app-aba-disponibilidade>
                  </app-aba-disponibilidade>
                </div>
              </div>

            <div #abaDispensas [hidden]="aba !== 2">
              <div>
                <app-aba-dispensas></app-aba-dispensas>
              </div>
            </div>

            </div>
            <div class="card-footer">
              
              <button *ngIf="showBtnSalvar" type="button" class="btn btn-primary btn-sm mr-1" (click)="salvar()">
                <i class="fas fa-save"></i> Salvar
            </button>

              <button [hidden]="visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Cancelar
              </button>

              <button [hidden]="!visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Sair
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
