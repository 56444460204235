<div class="h-100">
  <div class="card text-left h-100 px-0 w-100">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">{{ titulo }}</h4>
    </div>
    <div class="card-body wrapper">
      <form id="myForm" #f="ngForm" [formGroup]="filtrarModel" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-sm-10">
            <label for="validation-3" class="form-label">Subtipo: </label>
            <select class="form-control form-control-sm" formControlName="subtipo" name="subtipo">
              <option value="" class="text-gray" [disabled]="true">Selecione um subtipo</option>
              <option *ngFor="let item of listSubtipo" [value]="item.id">{{item.nome}}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-10">
            <label for="validation-3" class="form-label">Descrição: </label>
            <input type="text" class="form-control form-control-sm" formControlName="descricao" name="descricao" maxlength="250"/>
          </div>

          <div class="col-sm-2">
            <label for="validation-3" class="form-label">ㅤ</label><br/>
            <button type="button" class="btn btn-info btn-sm form-control form-control-sm w-100 " (click)="buscarComFiltro()">
              <i class="fas fa-search mr-2"></i> Filtrar
            </button>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <div class="card-body p-0">
              <label for="validation-3" class="form-label">Selecione um item para incluir: </label>
              <div class="mat-elevation-z1">
                <table mat-table [dataSource]="dataSource" class=" w-100" matSort matSortDisableClear>
                  <ng-container matColumnDef="incluir">
                    <th mat-header-cell *matHeaderCellDef>Incluir </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-checkbox (click)="$event?.stopPropagation()" color="primary" [checked]="element?.selecionado"
                        (change)="element.selecionado = $event?.checked" *ngIf="element?.readonly !== true">
                      </mat-checkbox>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="questao">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Questão</th>
                    <td mat-cell *matCellDef="let element">{{element?.nome || element?.text }}</td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="row.selecionado = !row?.selecionado && row?.readonly === false"
                    class="clickable" [ngClass]="{'bg-light': row?.selecionado}"></tr>
                </table>
                <h5 [hidden]="dataSource?.data?.length !== 0" class="table-no-data mt-2">
                  Nenhum registro encontrado.
                </h5>
                <mat-paginator [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons (page)="selectedModel= null">
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="d-flex w-100 justify-content-between align-items-center">
        <div class="list1">
          <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
            <i class="fas fa-times"></i> Fechar
          </button>
        </div>
        <div class="list2">
          <button type="button" class="btn btn-success btn-sm mr-1" (click)="abrirModalAdicionarQuestao()">
            <i class="fas fa-check"></i> Nova questão
          </button>
          <button type="submit" form="myForm" class="btn btn-primary btn-sm mr-1">
            <i class="far fa-save"></i> Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
