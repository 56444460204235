<div id="modal-content-wrapper">
    <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <div class="card-header bg-light" cdkDragHandle>
            <h4 class="card-title mb-0">Selecionar Responsável</h4>
            <div class="card-tools"></div>
        </div>
        <div class="card-body">
            <div>
                <div class="row">
                    <div class="form-group col-md-4">
                        <label for="" class="mb-1">CPF</label>
                        <div class="input-group input-group-sm" style="width: 100%">
                            <input type="text" id="" class="form-control form-control-sm"
                                data-inputmask="'mask': '999.999.999-99'" maxlength="14" />
                        </div>
                    </div>
                    <div class="form-group col-md-8">
                        <label for="" class="mb-1">Nome</label>
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm mr-1">
                            <div>
                                <button type="button" class="btn btn-info btn-sm" (click)="selecionar()">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear matSortActive="id"
              matSortDirection="asc" class="mat-elevation-z8" [hidden]="semDados">
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

              <ng-container matColumnDef="selecao">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox color="primary">
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="cpf">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CPF </th>
                <td mat-cell *matCellDef="let element"> {{element.cpf}}</td>
              </ng-container>
    
              <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
                <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
              </ng-container>
              
        </table>

        <mat-paginator [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons>
        </mat-paginator>

        <div class="card-footer">
            <button type="button" class="btn btn-primary btn-sm mr-1">
                <i class="fas fa-check"></i> Selecionar
            </button>

            <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
                <i class="fas fa-times"></i> Cancelar
            </button>
        </div>
    </div>