import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { ICalendarioModel } from 'src/app/models/calendario.model';
import { IBaseModel } from 'src/app/models/base.model';
import { AcessoCidadaoService } from 'src/app/services/acesso-cidadao.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import Swal from 'sweetalert2';
import { IEnumModel } from 'src/app/models/enum.model';
import { CalendarioService } from 'src/app/services/calendario.service';
import $ from 'jquery';

@Component({
  selector: 'app-calendario-form',
  templateUrl: './calendario-form.component.html',
  styleUrls: ['./calendario-form.component.scss']
})
export class CalendarioFormComponent extends BaseFormComponent implements OnInit {
  public model: ICalendarioModel = {} as ICalendarioModel;
  public modelAuditor: ICalendarioModel = {} as ICalendarioModel;
  public auditoresDataSource = new MatTableDataSource<ICalendarioModel>([]);
  public semDados = true;
  public fixo = '0';

  public emunTipoFeriado = [
    {
      codigo: 0,
      descricao: 'Selecione...'
    },
    {
      codigo: 3,
      descricao: 'Nacional'
    },
    {
      codigo: 1,
      descricao: 'Municipal'
    },
    {
      codigo: 2,
      descricao: 'Estadual'
    },
    {
      codigo: 4,
      descricao: 'Ponto Facultativo'
    },
    {
      codigo: 5,
      descricao: 'Outros'
    }] as IEnumModel[];

  public opcoesFixo = [
    {
      codigo: 1,
      descricao: 'Sim'
    },
    {
      codigo: 0,
      descricao: 'Não'
    }] as IEnumModel[];

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    fixo: new FormControl(''),
    data: new FormControl(null, Validators.required),
    tipoFeriadoId: new FormControl('', Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private calendarioService: CalendarioService,
    private acessoCidadaoService: AcessoCidadaoService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Calendário';
    } else {
      this.titulo = 'Editar Calendário #' + this.id;
    }
  }

  async ngOnInit() {

    try {
      if (!this.novoRegistro) {
        const res = await this.calendarioService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;
          this.fixo = this.model.fixo;

          if (this.novoRegistro) {
            this.titulo = 'Novo Calendário';
          } else {
            this.titulo = 'Editar Calendário - ' + this.model.nome;
          }
          if (this.visualizar) {
            this.titulo = 'Visualizar Calendário - ' + this.model.nome;
          }

        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
          this.router.navigate(['/calendario']);
          return;
        }
      }

      this.form.patchValue(
        {
          fixo: this.model.fixo ? '1' : '0',
          nome: this.model.nome,
          tipoFeriado: this.model.tipoFeriado,
          data: this.model.data ? new Date(this.model.data) : null,
          ativo: this.model.ativo,
          tipoFeriadoId: this.model.tipoFeriado ? this.model.tipoFeriado.codigo : 0
        } as ICalendarioModel);
    } catch (err) {
      this.exibirMensagemAlerta(err);
      this.router.navigate(['/calendario']);
    } 
  }

  public async salvarDados(salvarEFechar: boolean)
  {
    if (this.form.invalid) {
      this.exibirMensagemErro("Formulário invalido");
      return;
    }

    this.submit = true;
    
    this.atualizarModel(this.form.value);
    const tipoFeriado = parseInt(this.form.get('tipoFeriadoId').value) ?? 0 as number;
    this.model.tipoFeriado = { codigo: tipoFeriado } as IEnumModel;
    this.model.fixo = this.model.fixo === '1' ? 'true' : 'false';

    try {
      let res: IBaseModel<ICalendarioModel> = null;

      if (!this.novoRegistro) {
        res = await this.calendarioService.atualizar(this.model);
      } else {
        res = await this.calendarioService.inserir(this.model);
      }

      if (res.sucesso) {
        this.exibirMensagemSucesso(res.mensagem.descricao);
        
        if(salvarEFechar == true){
          this.router.navigate(['/calendario']);
        }else{
          this.router.navigate([`/calendario/${res.dados.id}`]);
        }
          
      } else {
        this.submit = false;
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    } catch (err) {
      this.submit = false;
      this.exibirMensagemAlerta(err.mensagem.descricao);
    } 
  }

  public async onSubmit() {
    this.salvarDados(false);
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.router.navigate(['/calendario']);
  }
}
