export class IPermissaoModel {
  consultar: boolean;
  incluir: boolean;
  editar: boolean;
  excluir: boolean;
  visualizar: boolean;
  inativarReativar: boolean;
  imprimir: boolean;
  enviarParaAprovacao:boolean;
  planejar:boolean;
  elaborar:boolean;
  gerarNovaVersao:boolean;
  relatoria:boolean;
  avaliar:boolean;
  exportar:boolean;
  detalhar:boolean;
  gerarPAA:boolean;
  importar:boolean;
}
