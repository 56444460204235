import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { IAgentePublicoACModel } from "src/app/models/agente-publico-ac.model";
import { EnumStatusSolicitacaoTecnica } from "src/app/models/enum/status-solicitcao-tecnica.enum";
import { IResponsavelModel } from "src/app/models/responsaveis.model";
import { AcessoCidadaoService } from "src/app/services/acesso-cidadao.service";
import { SolicitacaoTecnicaService } from "src/app/services/solicitacao-tecnica.service";
import { BaseListComponent } from "src/app/shared/components/base-list/base-list.component";

const sortingDataAccessor = (item, property) => {
	switch (property) {
		case "docAssociado":
			return item?.planejamento?.opa?.numero;
		case "numSolicitacao":
			return item?.id;
		case "prazoResposta":
			return item?.prazo;
		case "assuntoSolicitacao":
			return item?.assunto;
		case "status":
			return item?.status?.descricao;

		default:
			return item[property];
	}
};

@Component({
	selector: "app-solicitacao-tecnica-list",
	templateUrl: "./solicitacao-tecnica-list.component.html",
})
export class SolicitacaoTecnicaListComponent
	extends BaseListComponent
	implements OnInit
{

	public EnumStatusSolicitacaoTecnica = EnumStatusSolicitacaoTecnica;
	public dataSource = new MatTableDataSource<any>([]);
	public selectedModel: any;
	public pageEvent: any;
	public semDados = true;
	public prazoResposta = "";
	public numeroOpa = "";
	public numeroOsa = "";
	public numeroSolicitacao = "";
	public assunto = "";
	public status = "";
	public displayedColumns: string[] = [
		"selecao",
		"documentoAssociado",
		"numero",
		"prazo",
		"assunto",
		"status"
	];
  	public responsaveis = [] as IResponsavelModel[];

	public arrayStatus = [
		{ codigo: EnumStatusSolicitacaoTecnica.EmElaboracao, descricao: "Em Elaboração" },
		{ codigo: EnumStatusSolicitacaoTecnica.Finalizada, descricao: "Finalizada" },
		{ codigo: EnumStatusSolicitacaoTecnica.Enviada, descricao: "Enviada" },
		{ codigo: EnumStatusSolicitacaoTecnica.Cancelada, descricao: "Cancelada" },
		{ codigo: EnumStatusSolicitacaoTecnica.SolicitacaoProrrogacao, descricao: "Solicitação de Prorrogação" },
		{ codigo: EnumStatusSolicitacaoTecnica.Prorrogada, descricao: "Prorrogada" },
		{ codigo: EnumStatusSolicitacaoTecnica.AtendidaParcialmente, descricao: "Atendida Parcialmente" },
		{ codigo: EnumStatusSolicitacaoTecnica.AtendidaTotalmente, descricao: "Atendida Totalmente" },
		{ codigo: EnumStatusSolicitacaoTecnica.NaoAtendidaParcialmente, descricao: "Não Atendida Parcialmente" },
		{ codigo: EnumStatusSolicitacaoTecnica.NaoAtendidaTotalmente, descricao: "Não Atendida Totalmente" },
		{ codigo: EnumStatusSolicitacaoTecnica.Pendente, descricao: "Pendente" },
		{ codigo: EnumStatusSolicitacaoTecnica.ProrrogacaoRejeitada, descricao: "Prorrogação rejeitada" },
	];

	public form: FormGroup = this.fb.group({
		prazoResposta: [''],
		numeroOpa: [''],
		numeroOsa: [''],
		numeroSolicitacao: [''],
		assuntoSolicitacao: [''],
		status: [''],
		responsavelId: ['']
	});

  constructor(
		private solicitacaoTecnicaService: SolicitacaoTecnicaService,
		private router: Router,
		public fb: FormBuilder
	) {
		super();
	}

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	ngOnInit(): void {
		this.ObterResponsaveis();
    	this.filtrar();
	}


	public ObterResponsaveis() {
		this.solicitacaoTecnicaService.obterResponsaveisUGs()
			.then((res) => {
				this.responsaveis = res.dados;
			})
			.catch((err) => {
				this.exibirMensagemErro(err.mensagem.descricao);
			});
	}

	public visualizar(id: number) {
		this.router.navigate([`/solicitacao-tecnica/${id}/visualizar`]);
	}
	public responder(id: number) {
		this.router.navigate([`/solicitacao-tecnica/${id}/responder`]);
	}


	public filtrar() {
		this.selectedModel = null;
		this.solicitacaoTecnicaService
			.filtrar(this.form.value)
			.then((res) => {
				this.dataSource = new MatTableDataSource<any>(res.dados.resultado);
				this.dataSource.paginator = this.paginator;
				this.dataSource.sort = this.sort;
				this.dataSource.sortingDataAccessor = sortingDataAccessor;

				this.semDados = this.dataSource.filteredData.length === 0;
			})
			.catch((err) => {
				this.exibirMensagemErro(err.mensagem?.descricao);
			});
	}

	public reativar(id: number) {}

	public imprimir(id: number) {}

	pageChanged(e) {}

	public selecionar(item: any) {
		this.selectedModel =
			!this.selectedModel || this.selectedModel.id !== item.id
				? item
				: null;
	}
}
