import { MatSort, MatSortable } from "@angular/material/sort";
import {
	AfterViewInit,
	Component,
	Output,
	OnInit,
	ViewChild,
	EventEmitter,
} from "@angular/core";
import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DateHelper } from "src/app/core/helpers/date.helper";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { MatPaginator } from "@angular/material/paginator";
import { defaultDataAcessor } from "src/app/core/helpers/mat-sort-data-accessor";
import { Location } from "@angular/common";

//COMPONENTS
import { ModalSelecionarUGComponent } from "../modal-selecionar-ug/modal-selecionar-ug.component";
import { ModalSelecionarCoordenacoesComponent } from "../../pea-form/modal-selecionar-coordenacoes/modal-selecionar-coordenacoes.component";

//MODELS
import { IPaaDadosBasicosModel } from "src/app/models/paa-dados-basicos.model";
import { IPaaUnidadeGestoraModel } from "src/app/models/paa-unidade-gestora.model";
import { IPaaCoordenacaoModel } from "src/app/models/paa-coordenacao.model";
import { IUnidadeGestoraModel } from "src/app/models/unidade-gestora.model";
import { IPaaDistribuicaoModel } from "src/app/models/paa-distribuicao.model";
import { ICoordenacaoModel } from "src/app/models/coordenacao.model";
import { IBaseModel } from "src/app/models/base.model";

//SERVICES
import { PaaAbaDadosBasicosService } from "src/app/services/paa/paa-aba-dados-basicos.service";
import { UnidadeGestoraService } from "src/app/services/unidade-gestora.service";
import { CoordenacaoService } from "src/app/services/coordenacao.service";
import { IndicadorService } from "src/app/services/indicador.service";
import { PeaService } from "src/app/services/pea.service";
import { PeriodoService } from "src/app/services/periodo.service";

@Component({
	selector: "app-paa-aba-dados-basicos",
	templateUrl: "./paa-aba-dados-basicos.component.html",
	styleUrls: ["./paa-aba-dados-basicos.component.scss"],
})
export class PaaAbaDadosBasicosComponent
	extends BaseFormComponent
	implements OnInit
{
	@Output() public abaEmitter: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	public model = {} as IPaaDadosBasicosModel;

	public msgDataInicio = "Campo obrigatório";
	public msgDataFim = "Campo obrigatório";

	//BOOL
	public permiteEdicao = false;
	public emAprovacao = false;
	public coordenacaoSemDados = true;
	public salvarDadosPendente = false;
	public ugSemDados = true;
	public paaHomologado = false;
	public esconderCamposPaaEspecial = true;

	//DATA SOURCE
	public coordenacoesDataSource =
		new MatTableDataSource<IPaaCoordenacaoModel>([]);
	public ugDataSource= new MatTableDataSource<IPaaUnidadeGestoraModel>([]);
	public ugPlanejadaDataSource = new MatTableDataSource<IPaaDistribuicaoModel>([]);
	

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public form1 = new FormGroup({
		nome: new FormControl(""),
		dataInicio: new FormControl(""),
		dataFim: new FormControl(""),
		peaId: new FormControl(""),
		origem: new FormControl("", Validators.required),
		subcapitulo: new FormControl(""),
		origemAuditoria: new FormControl({ value: "", disabled: true }),
	});

	constructor(
		route: ActivatedRoute,
		toastr: ToastrService,
		router: Router,
		localeService: BsLocaleService,
		private _location: Location,
		public paaDadosBasicosService: PaaAbaDadosBasicosService,
		public matDialog: MatDialog,
		private dateHelper: DateHelper,
		private unidadeGestoraService: UnidadeGestoraService,
		private coordenacaoService: CoordenacaoService,
		private indicadorService: IndicadorService,
		private peaService: PeaService,
		private periodoService: PeriodoService
	) {
		super(route, toastr, router, localeService, matDialog);
	}

	async ngOnInit(): Promise<void> {
		if (this.novoRegistro) await this.gerarNova();
		else {
			await this.buscar();
		}

		//TODO: VERIFICAR PQ A VARIÝVEL VISUALIZAR ESTÝ SENDO RESETADA
		this.visualizar = this.route.routeConfig.path?.includes("visualizar");

		this.atribuirRequireds();
		this.desabilitaDataInicioFim();
	}

	atribuirRequireds() {
		if (this.model.origem.codigo == 2) {
			this.form1.controls["nome"].setValidators([Validators.required]);
			this.form1.controls["dataInicio"].setValidators([
				Validators.required,
			]);
			this.form1.controls["dataFim"].setValidators([Validators.required]);
			this.permiteEdicao = true;
			this.esconderCamposPaaEspecial = false;
		} else {
			this.form1.controls["nome"].clearValidators();
			this.form1.controls["dataInicio"].clearValidators();
			this.form1.controls["dataFim"].clearValidators();
			this.esconderCamposPaaEspecial = true;
		}

		this.form1.controls["nome"].updateValueAndValidity();
		this.form1.controls["dataInicio"].updateValueAndValidity();
		this.form1.controls["dataFim"].updateValueAndValidity();
	}

	async gerarNova() {
		await this.paaDadosBasicosService
			.gerarNova()
			.then((res) => {
				if (res.sucesso) {
					this.model.origem = res.dados.origem;
					this.permiteEdicao = true;
					this.esconderCamposPaaEspecial = false;

					this.form1.patchValue({
						origem: res.dados.origem,
						origemAuditoria: this.model.origem.descricao,
					});
				} else {
					this.exibirMensagemErro(res.mensagem.descricao);
				}
			})
			.catch((err) => {
				this.exibirMensagemErro(err.mensagem.descricao);
			});
	}

	async buscar() {
		try {
			await this.paaDadosBasicosService
				.obterDadosBasicos(this.id)
				.then((res) => {
					if (res.sucesso) {
						this.model = res.dados;
						if (Number(this.model?.situacao) === 2) 
							this.emAprovacao = true;
						this.form1.patchValue({
							nome: this.model.nome,
							origem: this.model.origem,
							origemAuditoria: this.model.origem.descricao,
							dataInicio: this.dateHelper.toStringPtBr(
								this.model.dataInicio
							),
							dataFim: this.dateHelper.toStringPtBr(
								this.model.dataFim
							),
							indicadorId: this.model.indicadorId,
							peaId: this.model.peaId,
							pea: this.model.pea,
						});
							this.atualizarListaUgs(this.model.paaUnidadeGestora);

						this.coordenacoesDataSource =
							new MatTableDataSource<IPaaCoordenacaoModel>(
								this.model.paaCoordenacao
							);
						this.coordenacoesDataSource._updateChangeSubscription();
						this.coordenacaoSemDados =
							this.coordenacoesDataSource.filteredData.length ===
							0;
					} else {
						this.submit = false;
						this.exibirMensagemErro(res.mensagem.descricao);
						this.router.navigate(["/paa"]);
						return;
					}
				});
		} catch (err) {
			this.submit = false;
			this.exibirMensagemErro(err.mensagem?.descricao);
			this.router.navigate(["/paa"]);
		}
	}

	public async exibirModalUg() {
		try {
			const res = await this.unidadeGestoraService.obterAtivos();
			if (res.sucesso) {
				const dialogConfig = new MatDialogConfig();
				dialogConfig.id = "modal-component";
				dialogConfig.width = "650px";
				dialogConfig.hasBackdrop = true;
				dialogConfig.disableClose = true;
				const unidadesAdicionadas = [] as IUnidadeGestoraModel[];

				let dados;
				if (this.model.paaUnidadeGestora !== undefined) {
					this.model.paaUnidadeGestora.forEach((d) =>
						unidadesAdicionadas.push(d.unidadeGestora)
					);
				  dados = res.dados.filter(
						(d) => !unidadesAdicionadas.some((x) => d.id === x.id)
					);
				} else {
				  dados = res.dados;
					this.model.paaUnidadeGestora =
						[] as IPaaUnidadeGestoraModel[];
				}
				
				dialogConfig.data = {dados: dados, modoSelecaoMultiplo: true};
				const modal = this.matDialog.open(
					ModalSelecionarUGComponent,
					dialogConfig
				);
				
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.salvarDadosPendente = true;
						if (Array.isArray(data)) {
							let unidadeSelecionada =
								[] as IPaaUnidadeGestoraModel[];
							unidadeSelecionada = data.map(
								(d) =>
									({
										unidadeGestoraId: d.id,
										unidadeGestora: d,
									} as IPaaUnidadeGestoraModel)
							);
							unidadeSelecionada.forEach((x) =>
								this.model.paaUnidadeGestora.push(x)
							);
						} else {
							let unidadeSelecionada =
								{} as IPaaUnidadeGestoraModel;
							unidadeSelecionada.unidadeGestora = data;
							unidadeSelecionada.unidadeGestoraId = data.id;
							this.model.paaUnidadeGestora.push(
								unidadeSelecionada
							);
						}
						this.atualizarListaUgs(this.model.paaUnidadeGestora);
					}
				});
			} else {
				this.exibirMensagemErro(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public excluirCoordenacao(ind: number) {
		Swal.fire({
			title: "Excluir Coordenação",
			text: "Deseja realmente excluir a informação?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then((resConfirm) => {
			if (resConfirm.isConfirmed) {
				this.salvarDadosPendente = true;
				this.coordenacoesDataSource.data.splice(ind, 1);
				this.coordenacoesDataSource._updateChangeSubscription();
				this.coordenacaoSemDados =
					this.coordenacoesDataSource.filteredData.length === 0;
			}
		});
	}

	public async exibirModalCoordenacoes() {
		try {
			const res = await this.coordenacaoService.obter("", true);
			if (res.sucesso) {
				const dialogConfig = new MatDialogConfig();
				dialogConfig.id = "modal-component";
				dialogConfig.width = "650px";
				dialogConfig.hasBackdrop = true;
				dialogConfig.disableClose = true;
				const coordenacoesAdicionadas = [] as ICoordenacaoModel[];
				if (this.model.paaCoordenacao !== undefined) {
					this.model.paaCoordenacao.forEach((d) =>
						coordenacoesAdicionadas.push(d.coordenacao)
					);
					dialogConfig.data = res.dados.filter(
						(d) =>
							!coordenacoesAdicionadas.some((x) => d.id === x.id)
					);
				} else {
					dialogConfig.data = res.dados;
					this.model.paaCoordenacao = [] as IPaaCoordenacaoModel[];
				}
				const modal = this.matDialog.open(
					ModalSelecionarCoordenacoesComponent,
					dialogConfig
				);
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.salvarDadosPendente = true;
						if (Array.isArray(data)) {
							let coordenacaoSelecionada =
								[] as IPaaCoordenacaoModel[];
							coordenacaoSelecionada = data.map(
								(d) =>
									({
										coordenacaoId: d.id,
										coordenacao: d,
									} as IPaaCoordenacaoModel)
							);
							coordenacaoSelecionada.forEach((x) =>
								this.model.paaCoordenacao.push(x)
							);
						}

						this.coordenacoesDataSource =
							new MatTableDataSource<IPaaCoordenacaoModel>(
								this.model.paaCoordenacao
							);
						this.coordenacoesDataSource._updateChangeSubscription();
						this.coordenacaoSemDados =
							this.coordenacoesDataSource.filteredData.length ===
							0;
					}
				});
			} else {
				this.exibirMensagemErro(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public excluirUg(ind: number) {
		Swal.fire({
			title: "Excluir Unidade Gestora",
			text: "Deseja realmente excluir a informação?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then((resConfirm) => {
			if (resConfirm.isConfirmed) {
				this.salvarDadosPendente = true;
				var ug = this.ugDataSource.data.splice(ind, 1);
				var indice = this.model.paaUnidadeGestora.indexOf(ug[0]);
				this.model.paaUnidadeGestora.splice(indice, 1);

				this.atualizarListaUgs(this.ugDataSource.data);
			}
		});
	}

	public async exibirModalIndicador() {
		try {
			const res = await this.indicadorService.obter("", true);
			if (res.sucesso) {
				res.dados.sort((a, b) => a["nome"].localeCompare(b["nome"]));
				const modal = this.exibirModal(
					"Selecionar Indicador",
					res.dados
				);
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.salvarDadosPendente = true;
						this.model.indicador = data;
						this.model.indicadorId = data.id;
						this.form1.controls["indicadorId"].setValue(data.id);
					}
				});
			} else {
				this.exibirMensagemErro(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public async exibirModalPeaReferencia() {
		try {
			const res = await this.peaService.obter("", true, false, null);
			if (res.sucesso) {
				res.dados.resultado.sort((a, b) =>
					a["nome"].localeCompare(b["nome"])
				);
				const modal = this.exibirModal(
					"Selecionar Pea",
					res.dados.resultado
				);
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.salvarDadosPendente = true;
						this.model.pea = data;
						this.model.peaId = data.id;
						this.model.pea.nome = data.nome;
						this.form1.controls["peaId"].setValue(data.id);
					}
				});
			} else {
				this.exibirMensagemErro(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public async exibirModalPeriodo() {
		try {
			const res = await this.periodoService.obter("", true);
			if (res.sucesso) {
				res.dados.sort(
					(d2, d1) =>
						new Date(d1.dataInicio).getTime() -
						new Date(d2.dataInicio).getTime()
				);
				const modal = this.exibirModal("Selecionar Periodo", res.dados);
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.salvarDadosPendente = true;
						this.model.periodo = data;
						this.model.periodoId = data.id;
						this.form1.controls["periodoId"].setValue(data.id);
					}
				});
			} else {
				this.exibirMensagemErro(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public async salvarDados(salvarEFechar: boolean) {
		this.submit = true;
		this.abaEmitter.next(this.submit);

		if (this.form1.invalid) {
			this.exibirMensagemErro("Formulário invalido!");
			return;
		}

		// if(!this.datasValidas()){
		// 	this.exibirMensagemErro('Formulário invalido!');
		//   	return;
		// }

		if (
			this.ugDataSource.data.length === 0 ||
			this.coordenacoesDataSource.data.length === 0
		) {
			this.exibirMensagemErro(
				"Informe alguma Unidade Gestora e alguma Coordenação"
			);
			return;
		}

		this.atualizarModel(this.form1.value);

		try {
			let res = await this.atualizarInserirAbaDadosBasicos();

			if (res.sucesso) {
				if (
					res.resultadoValidacao &&
					res.resultadoValidacao.length > 0
				) {
					this.exibirMensagemAlerta(
						this.montarMensagensValidacao(res),
						true
					);
				} else {
					this.exibirMensagemSucesso(res.mensagem.descricao);
				}

				this.salvarDadosPendente = false;
				if (
					res.dados?.pendencias?.pendencias &&
					res.dados?.pendencias?.pendencias.length > 0
				)
					this.showPendencias(res?.dados?.pendencias?.pendencias);
			} else {
				let validacao = this.montarMensagensValidacao(res);
				this.exibirMensagemErro(validacao, true);
				return;
			}

			if (salvarEFechar == true) this.router.navigate(["/paa"]);
			else {
				if (this.novoRegistro) {
					this.router.navigate([`/paa/${res.dados.id}`]);
				} else {
					await this.buscar();
				}
			}
		} catch (err) {
			this.exibirMensagemErro(err?.mensagem?.descricao);
		}
	}

	public async atualizarInserirAbaDadosBasicos() {
		Object.assign(this.model, this.form1.value);
		this.model.paaUnidadeGestora = this.ugDataSource.data;
		this.model.paaCoordenacao = this.coordenacoesDataSource.data;

		if (this.model.id > 0) {
			return await this.paaDadosBasicosService.atualizarDadosBasicos(
				this.model
			);
		} else {
			return await this.paaDadosBasicosService.inserir(this.model);
		}
	}

	private datasValidas(): boolean {
		let retorno = true;

		let valueDtInicio = this.form1.controls["dataInicio"].value;
		let valueDtFim = this.form1.controls["dataFim"].value;

		if (!valueDtInicio) {
			this.msgDataInicio = "Campo obrigatório";
			retorno = false;
		}

		if (!valueDtFim) {
			this.msgDataFim = "Campo obrigatório";
			retorno = false;
		}

		if (retorno == false) return retorno;

		let dtInicio = new Date(valueDtInicio);
		let dtFim = new Date(valueDtFim);

		if (dtInicio.getFullYear() != dtFim.getFullYear()) {
			this.msgDataInicio = "Anos não podem ser diferentes.";
			retorno = false;
		}

		if ((retorno = false)) return retorno;

		if (dtInicio.getDay() != 1 || dtInicio.getMonth() != 1) {
			this.msgDataInicio = "Data início diferente de 01/01.";
			retorno = false;
		}

		if (dtFim.getDay() != 31 || dtInicio.getMonth() != 12) {
			this.msgDataInicio = "Data fim diferente de 31/12.";
			retorno = false;
		}

		return retorno;
	}

	//##### INTERNAL
	public atualizarListaUgs(paaUnidadeGestora: IPaaUnidadeGestoraModel[] ) {	
			const listaUgs = Array.isArray(paaUnidadeGestora)
			? paaUnidadeGestora.map((_) => ({
				..._,
				nome: `${_.unidadeGestora.sigla} - ${_.unidadeGestora.nome}`,
			}))
			: [];
			this.ugDataSource.paginator = this.paginator;
			this.ugDataSource.sort = this.sort;
			this.ugDataSource.filterPredicate = (
				data: { nome: string },
				filterValue: string
			) => {
				return (
					(data.nome || "").trim().toLowerCase().indexOf(filterValue) !==
					-1
				);
			};
			listaUgs.sort((a, b) => a["nome"].localeCompare(b["nome"]));
			this.ugDataSource = new MatTableDataSource<IPaaUnidadeGestoraModel>(
				listaUgs
			);
			this.ugDataSource.sortingDataAccessor = defaultDataAcessor;
			this.ugDataSource._updateChangeSubscription();
			this.ugSemDados = this.ugDataSource.filteredData.length === 0;		
	}
	

	private atualizarModel(values: any) {
		Object.assign(this.model, values);
	}

	public cancelar() {
		this.router.navigate(["/paa"]);
	}

	public desabilitaDataInicioFim() {
		const urlAtual = this.router.url;

		if (!urlAtual.includes("novo")) {
			this.form1.controls["dataInicio"].disable();
			this.form1.controls["dataFim"].disable();
		}
	}

	  exibirBotoesPaacEspecial(): boolean{
		return this.model
				&& this.model.origem
				&& this.model.origem.codigo
				&& this.model.origem.codigo == 2
				&& !this.visualizar && !this.paaHomologado;
	  }
	  
}
