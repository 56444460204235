<div class="content-header" [hidden]="isModal">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/constatacao']">Constatação</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<!-- <div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
      </div>
    </div>
  </div>
</div> -->

<div class="h-100" [ngClass]="{'container-fluid': !isModal}" >
  <div class="card" [ngClass]="{'text-left h-100 px-0 w-100': isModal, 'card-outline card-lightblue': !isModal}">
    <div class="card-header" [ngClass]="{'bg-light': isModal}">
      <h3 class="card-title mb-0">
        <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
        <i *ngIf="!this.id" class="far fa-file pr-2"></i>
        {{ titulo }}
      </h3>
    </div>

    <div class="card-body" [ngClass]="{'wrapper': isModal}">
      <form [formGroup]="form" id="formConstatacao" #f="ngForm" >
        <div class="row">

          <div class="form-group col-md-12">
            <label for="nome" class="mb-1">Constatação</label>
            <input type="text" id="nome" [attr.disabled]="visualizar ? true : null"
              class="form-control form-control-sm" formControlName="nome" maxlength="100"
              [ngClass]="{ 'is-invalid': f.submitted && form.controls['nome'].invalid }" />
            <div *ngIf="f.submitted && form.controls['nome'].invalid" class="invalid-feedback">
              <div *ngIf="form.controls['nome'].errors.required">Campo obrigatório</div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <label for="descricao" class="mb-1">Descrição</label>
            <angular-editor [config]="config" id="descricao"  [attr.disabled]="visualizar ? true : null" rows="20" formControlName="descricao" [ngClass]="{
              'is-invalid':
                f.submitted && form.controls['descricao'].invalid
            }"></angular-editor>

            <div *ngIf="f.submitted && form.controls['descricao'].invalid" class="invalid-feedback">
              <div *ngIf="form.controls['descricao'].errors.required">
                Campo obrigatório
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-3">
          <div class="form-group col-md-12">
            <mat-checkbox color="primary" name="contaGovernador" formControlName="contaGovernador">
              Contas do Governador
            </mat-checkbox>
          </div>
          <div class="form-group col-md-12">
            <mat-checkbox color="primary" name="contaOrdenador" formControlName="contaOrdenador">
              Contas dos Ordenadores
            </mat-checkbox>
          </div>
        </div>

        <div *ngIf="!visualizar" class="row">

          <div class="form-group col-md-12">
            <label for="inputRecomendacao" class="mb-1">Recomendação</label>
            <div class="input-group input-group-sm" style="width: 100%;">
              <input type="text" id="inputRecomendacao" class="form-control form-control-sm" readonly
                [ngModel]="modelConstatacaoRecomendacao.recomendacao?.nome"
                [ngModelOptions]="{standalone: true}" />
              <div class=" input-group-append">
                <button type="button" class="btn btn-info" (click)="exibirModalRecomendacao()">
                  <i class="fas fa-search"></i>
                </button>
                <button type="button" class="btn btn-success ml-1" (click)="incluirRecomendacao()"
                  [disabled]="!(modelConstatacaoRecomendacao.recomendacaoId)">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="mat-elevation-z1">
              <table mat-table [dataSource]="recomendacaoDataSource">
                <tr mat-header-row *matHeaderRowDef="['nome', 'actions']" class="bg-light"></tr>

                <ng-container matColumnDef="nome">
                  <th mat-header-cell *matHeaderCellDef>Nome </th>
                  <td mat-cell *matCellDef="let element"> {{ element.recomendacao.nome }}</td>
                </ng-container>

                <ng-container cdkColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element; let i = index" class="block">
                    <div class="btn-group">
                      <button type="button" class="btn btn-info btn-xs text-left"
                        (click)="visualizarRecomendacao(element)">
                        <i class="far fas fa-eye"></i> </button>
                      <button *ngIf="!visualizar" type="button" class="btn btn-danger btn-xs text-left"
                        (click)="desvincularRecomendacao(element.recomendacao, i)">
                        <i class="far fa-trash-alt"></i> </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: ['nome', 'actions'];"></tr>
              </table>
              <h5 [hidden]="!semDados" class="table-no-data">Nenhuma recomendação cadastrada</h5>
            </div>
          </div>
        </div>
      </form>

    </div>

    <div class="card-footer">
      <div class="btn-group" [hidden]="visualizar">
          <button class="btn btn-primary btn-sm mr" (click)="onSubmit(false)">
              <i class="far fa-save"></i> Salvar
          </button>
          <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
          <div class="dropdown-menu" role="menu">
              <a class="dropdown-item" (click)="onSubmit(false)">Salvar</a>
              <a class="dropdown-item" (click)="onSubmit(true)">Salvar e fechar</a>
          </div>
      </div>
  
      <button type="button" class="btn btn-default btn-sm" (click)="onBack()">
          <i class="fas fa-times"></i> {{visualizar ? 'Sair' : 'Cancelar' }}
      </button>
    </div>
  </div>
</div>
