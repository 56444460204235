import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { IAuditorCoordenacaoModel } from 'src/app/models/auditor-coordenacao.model';
import { ICoordenacaoModel } from 'src/app/models/coordenacao.model';
import { IEnumModel } from 'src/app/models/enum.model';
import { IOsaFiltroModel } from 'src/app/models/osa-filtro-model';
import { IOsaModel } from 'src/app/models/osa-model';
import { ISubtipoAuditoriaModel } from 'src/app/models/subtipo-auditoria.model';
import { ITipoAuditoriaModel } from 'src/app/models/tipo-auditoria.model';
import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';
import { AuthService } from 'src/app/services/auth.service';
import { CoordenacaoService } from 'src/app/services/coordenacao.service';
import { OsaService } from 'src/app/services/osa.service';
import { SubtipoAuditoriaService } from 'src/app/services/subtipo-auditoria.service';
import { TipoAuditoriaService } from 'src/app/services/tipo-auditoria.service';
import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-filtro-component',
  templateUrl: './filtro-component.component.html',
  styleUrls: ['./filtro-component.component.scss']
})
export class FiltroComponent extends BaseListComponent implements OnInit, AfterViewInit {

  @Input() showButtonFilter: Boolean = true;

  public osaModel: IOsaModel = {} as IOsaModel;
  private filtro: IOsaFiltroModel = {} as IOsaFiltroModel;
  public filtroModel: IOsaFiltroModel = {} as IOsaFiltroModel;
  public situacaoSelecionada: number;
  public coordenadores = [] as IAuditorCoordenacaoModel[];
  public unidadesGestoras = [] as IUnidadeGestoraModel[];
  public coordenacoes = [] as ICoordenacaoModel[];
  public subtiposAuditoria = [] as ISubtipoAuditoriaModel[];
  public tiposAuditoria = [] as ITipoAuditoriaModel[];
  public situacoes = [{codigo: 1, descricao: 'Todos'}, {codigo: 2, descricao: 'Ativos'}, {codigo: 3, descricao: 'Inativos'}] as IEnumModel[];
  public status = [{codigo: 1, descricao: 'Em Elaboração'}, {codigo: 2, descricao: 'Em Aprovação'}, {codigo: 3, descricao: 'Em Homologacao'}, {codigo: 4, descricao: 'Homologado'}, {codigo: 5, descricao: 'Elaborar'}, {codigo: 6, descricao: 'Em Execução'}]
  public origem = [{codigo: 1, descricao: 'Planejada'}, {codigo: 2, descricao: 'Especial'}]

  constructor(
    public authService: AuthService,
    private osaService: OsaService,
    private unidadeGestoraService: UnidadeGestoraService,
    private coordenacaoService: CoordenacaoService,
    private subtipoAuditoriaService: SubtipoAuditoriaService,
    private tipoAuditoriaService: TipoAuditoriaService,
    ) {
      super()
    }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.obterCoordenadores();
    this.obterUnidadesGestoras();
    this.obterCoordenacoes();
    this.buscar();
    this.obterSubtiposAuditoria();
    this.obterTiposAuditoria();
  }

  public buscar() {
    this.osaService.obter()
  }

  public obterCoordenadores(){
    this.coordenacaoService
    .obterCoordenadoresAtivos()
    .then((res) => {
      if (res.sucesso) {
        this.coordenadores = res.dados;
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }

  private obterSubtiposAuditoria() {

    this.subtipoAuditoriaService
    .obterAtivos()
    .then((res) => {
      this.subtiposAuditoria = res.dados;
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }

  private obterTiposAuditoria() {

    this.tipoAuditoriaService
    .obterAtivos()
    .then((res) => {
      this.tiposAuditoria = res.dados;
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }

  public obterCoordenacoes(){
    this.coordenacaoService
    .obter("", undefined, false)
    .then((res) => {
      if (res.sucesso) {
        this.coordenacoes = res.dados;
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }

  private obterUnidadesGestoras() {

    this.unidadeGestoraService
    .obterAtivos()
    .then((res) => {
      this.unidadesGestoras = res.dados;
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }

  buscarComFiltro() {
    this.filtro.numeroOsa = this.filtroModel.numeroOsa;
    this.filtro.numeroOpa = this.filtroModel.numeroOpa;
    this.filtro.objeto = this.filtroModel.objeto;
    this.filtro.dataInicioRealizacao = this.filtroModel.dataInicioRealizacao;
    this.filtro.dataFimRealizacao = this.filtroModel.dataFimRealizacao;
    this.filtro.dataInicioPrevisto = this.filtroModel.dataInicioPrevisto;
    this.filtro.dataFimPrevisto = this.filtroModel.dataFimPrevisto;
    this.filtro.origemAuditoria = this.filtroModel.origemAuditoria;
    this.filtro.subtipoAuditoriaId = this.filtroModel.subtipoAuditoriaId;
    this.filtro.unidadeGestoraId = this.filtroModel.unidadeGestoraId;
    this.filtro.coordenador = this.filtroModel.coordenador;
    this.filtro.tipoAuditoriaId = this.filtroModel.tipoAuditoriaId;
    this.filtro.status = this.filtroModel.status;
    this.filtro.ativo = this.situacaoSelecionada > 1 ? this.situacaoSelecionada === 2 : null;
    this.filtro.itensPorPagina = 50

    this.osaService.obter(this.filtro).then(() => {

    }).catch(error => {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'Filtro não aplicado, ocorreu um erro!',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      })
    })
  }

}
