<div class="card-outline card-lightblue rounded-0">
  <div class="card-header bg-light">
      <h3 class="card-title mb-0">
          <i class="far fa-file pr-2"></i>{{titulo}}
      </h3>
  </div>
  <div class="card-body">
  <form [formGroup]="form" #f="ngForm" (ngSubmit)="concluir()">
    <div class="row">
    <div class="col-md-12">
      <label for="nome" class="form-label">Nome</label>
      <input type="text" class="form-control form-control-sm mb-2" formControlName="nome" id="validationCustom01" required [ngClass]="{
          'is-invalid': valido && form.controls['nome'].invalid}" maxlength="100">
      <div *ngIf="valido && form.controls['nome'].invalid" class="invalid-feedback">
        <div *ngIf="form.controls['nome'].errors.required">
          Campo obrigatório
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <label for="quantidadeDias" class="form-label">Quantidade de dias</label>
      <input  class="form-control form-control-sm mb-2" type="text" formControlName="quantidadeDias" maxlength="10"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="validationCustom02" min="1" required [ngClass]="{
          'is-invalid': valido && form.controls['quantidadeDias'].invalid}">
      <div *ngIf="valido && form.controls['quantidadeDias'].invalid" class="invalid-feedback">
        <div *ngIf="form.controls['quantidadeDias'].errors.required">
          Campo obrigatório
        </div>
      </div>
    </div>
  </div>
  </form>
</div>
</div>
<div class="card-footer">
  <button type="submit" (click)="concluir()" class="btn btn-primary btn-sm mr-1">
    <i class="far fa-save"></i> Salvar
  </button>

  <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
    <i class="fas fa-times"></i> Cancelar
  </button>
</div>