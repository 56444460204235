import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-modal-confirmar-inativacao',
  templateUrl: './modal-confirmar-inativacao.component.html',
  styleUrls: ['./modal-confirmar-inativacao.component.scss']
})
export class ModalConfirmarInativacaoComponent implements OnInit {
  public titulo = 'Tem certeza que deseja inativar esse registro?';
  public form = new FormGroup({
    justificativa: new FormControl('', Validators.required),
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dialogRef: MatDialogRef<ModalConfirmarInativacaoComponent>, @Inject(MAT_DIALOG_DATA) data) {
    if (data){
      this.titulo = data.titulo ? data.titulo : this.titulo;
    }
  }

  ngOnInit(): void {
  }

  public concluir() {
    this.dialogRef.close(this.form.get('justificativa').value);
  }

  public cancelar() {
    this.dialogRef.close();
  }
}

