<div id="modal-content-wrapper">
  <div
    class="card card-secondary card-outline"
    cdkDragBoundary="html"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
  >
    <div class="card-header bg-light" cdkDragHandle>
      <h4 class="card-title mb-0">Visualizar Respostas</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="fechar()">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="table-container">
        <div class="row">
          <div class="form-group col-md-2">
            <label for="codigoUg" class="mb-1">Cód. UG</label>
            <input
              type="text"
              id="codigoUg"
              [ngModel]="model.unidadeGestora.codigoUg"
              class="form-control form-control-sm"
              readonly
            />
          </div>

          <div class="form-group col-md-3">
            <label for="siglaUg" class="mb-1">Sigla UG</label>
            <input
              type="text"
              id="siglaUg"
              [ngModel]="model.unidadeGestora.sigla"
              class="form-control form-control-sm"
              readonly
            />
          </div>

          <div class="form-group col-md-5">
            <label for="nomeUg" class="mb-1">Nome UG</label>
            <input
              type="text"
              id="nomeUg"
              [ngModel]="model.unidadeGestora.nome"
              class="form-control form-control-sm"
              readonly
            />
          </div>
          <div class="form-group col-md-2">
            <label for="valorRiscoInerente" class="mb-1">Risco Inerente</label>
            <input
              type="text"
              id="valorRiscoInerente"
              [ngModel]="model.valorRisco"
              class="form-control form-control-sm"
              readonly
            />
          </div>
        </div>
        <table mat-table [dataSource]="dataSource" [hidden]="semDados">
          <ng-container matColumnDef="criterio">
            <th mat-header-cell *matHeaderCellDef>Critério</th>
            <td mat-cell *matCellDef="let element">
              {{ element.criterio?.criterio?.nome }}
            </td>
          </ng-container>

          <ng-container matColumnDef="peso">
            <th mat-header-cell *matHeaderCellDef>Peso</th>
            <td mat-cell *matCellDef="let element">{{ element.peso }}</td>
          </ng-container>

          <ng-container matColumnDef="nota">
            <th mat-header-cell *matHeaderCellDef>Nota</th>
            <td mat-cell *matCellDef="let element">
              {{ element.nota }}
            </td>
          </ng-container>

          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>Total</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
        </table>
        <h5 [hidden]="!semDados" class="table-no-data">Sem dados a exibir</h5>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-default btn-sm" (click)="fechar()">
        <i class="fas fa-times"></i> Fechar
      </button>
    </div>
  </div>
</div>
