import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IOrgaoModel } from '../models/orgao.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class OrganogramaService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public ObterOrgaos(): Promise<IBaseModel<IOrgaoModel[]>> {
    return this.httpClient
      .get<IBaseModel<IOrgaoModel[]>>(`${this.apiBaseUrl}/organograma/orgaos`)
      .toPromise();
  }
}
