import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { ITipoAuditoriaModel } from 'src/app/models/tipo-auditoria.model';
import { CoordenacaoService } from 'src/app/services/coordenacao.service';
import { TipoAuditoriaService } from 'src/app/services/tipo-auditoria.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tipo-auditoria-form',
  templateUrl: './tipo-auditoria-form.component.html',
  styleUrls: ['./tipo-auditoria-form.component.scss']
})
export class TipoAuditoriaFormComponent extends BaseFormComponent implements OnInit {
  public model: ITipoAuditoriaModel = {} as ITipoAuditoriaModel;

  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
    quantidadeDias: new FormControl({ readonly: true }),
    qtdDiasPlanejamento: new FormControl(null, [Validators.required, Validators.min(1)]),
    qtdDiasExecucao: new FormControl(null, [Validators.required, Validators.min(1)]),
    quantidadeMembros: new FormControl(null, Validators.required),
    coordenacaoId: new FormControl(null),
    coordenacaoNome: new FormControl('', Validators.required)
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private tipoAuditoriaService: TipoAuditoriaService,
    private coordenacaoService: CoordenacaoService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Tipo de Auditoria';
    }
  }

  async ngOnInit() {

    try {
      if (!this.novoRegistro) {
        const res = await this.tipoAuditoriaService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;
          if (this.visualizar) {
            this.titulo = 'Visualizar Tipo de Auditoria - ' + this.model.nome;
          } else {
            this.titulo = 'Editar Tipo de Auditoria - ' + this.model.nome;
          }
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
          this.router.navigate(['/tipos-auditoria']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      this.exibirMensagemErro(err.mensagem);
      this.router.navigate(['/tipos-auditoria']);
    } 
  }

  public async salvarDados(salvarEFechar: boolean)
  {
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }

    this.submit = true;

    this.atualizarModel(this.form.value);

    try {
      let res: IBaseModel<ITipoAuditoriaModel> = null;

      if (!this.novoRegistro) {
        res = await this.tipoAuditoriaService.atualizar(this.model);
      } else {
        res = await this.tipoAuditoriaService.inserir(this.model);
      }

      if (res.sucesso) {
        this.exibirMensagemSucesso(res.mensagem.descricao);

        if(salvarEFechar == true){
          this.router.navigate(['/tipos-auditoria']);
        }else{
          this.router.navigate([`/tipos-auditoria/${res.dados.id}`]);
        }
          
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem);
    }
  }
  public async onSubmit() {
   
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.submit = false;
    this.router.navigate(['/tipos-auditoria']);
  }

  public async exibirModalCoordenacao() {
			
    try {
      const res = await this.coordenacaoService.obter('', true, false);
      if (res.sucesso) {
        const customFilterPredicate = (data: { sigla: string, nome: string }, filterValue: string) => {
          return (data.sigla || '').trim().toLowerCase().indexOf(filterValue) !== -1
            || (data.nome || '').trim().toLowerCase().indexOf(filterValue) !== -1;
        };

      res.dados.sort((a, b) => a['nome'].localeCompare(b['nome']));
      const modal = this.exibirModal(
                      'Selecionar Coordenação', 
                      res.dados,
                      false,
                    [
                        { columnDef: 'selecao',
                          header:''
                        },
                        {
                          columnDef: 'nome',
                          header: 'Nome'
                        },
                        {
                          columnDef: 'sigla',
                          header: 'Sigla'
                        }],
                        customFilterPredicate,
                        10);
      modal.afterClosed().subscribe((data) => {
        if (data) {
         this.model.coordenacao = data;
        this.form.controls['coordenacaoId'].setValue(data.id);
        }
      });
      } else {
      this.exibirMensagemErro(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
    }


  public calcularTotalDias() {
    const total = Number(this.form.controls['qtdDiasPlanejamento'].value == null ? 0 : this.form.controls['qtdDiasPlanejamento'].value)
      + Number(this.form.controls['qtdDiasExecucao'].value == null ? 0 : this.form.controls['qtdDiasExecucao'].value);

    this.form.controls['quantidadeDias'].setValue(total);
  }
}
