import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-descricao-peac',
  templateUrl: './modal-descricao-peac.component.html',
  styleUrls: ['./modal-descricao-peac.component.scss']
})
export class ModalDescricaoPEACComponent implements OnInit {
  diasDisponiveis: number;
  qntDiasPorAuditor: number;
  numAuditoriaPorEquipe: number;
  numAuditoriaPorEquipeArred: number;
  numEquipeDisponiveis: number;
  capacidadeTotalPorCoord: number;

  constructor(
    private dialogRef: MatDialogRef<ModalDescricaoPEACComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    // Inicialização das propriedades com os dados recebidos
    this.diasDisponiveis = data.capacidade.diasDisponiveis;
    this.qntDiasPorAuditor = data.capacidade.qntDiasPorAuditor;
    this.numAuditoriaPorEquipe = data.capacidade.numAuditoriaPorEquipe;
    this.numAuditoriaPorEquipeArred = data.capacidade.numAuditoriaPorEquipeArred;
    this.numEquipeDisponiveis = data.capacidade.numEquipeDisponiveis;
    this.capacidadeTotalPorCoord = data.capacidade.capacidadeTotalPorCoord;
  }

  ngOnInit(): void {
    // Aqui você pode adicionar lógica adicional de inicialização, se necessário
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  // Exemplo de método para verificar se um objeto está vazio
  public isObjectEmpty(obj: any): boolean {
    return obj === null || obj === undefined || Object.keys(obj).length === 0;
  }
}
