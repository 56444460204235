import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';

@Component({
  selector: 'app-modal-selecionar-coordenacoes',
  templateUrl: './modal-selecionar-coordenacoes.component.html',
  styleUrls: ['./modal-selecionar-coordenacoes.component.scss']
})
export class ModalSelecionarCoordenacoesComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  public semDados = false;
  public displayedColumns = ['selecao', 'id', 'sigla', 'nome'];
  public elementosSelecionados = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<ModalSelecionarCoordenacoesComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.dataSource = new MatTableDataSource<any>(data);
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: { nome: string }, filterValue: string) => {
      return (data.nome || '').trim().toLowerCase().indexOf(filterValue) !== -1;
    };
    this.dataSource.sortingDataAccessor = defaultDataAcessor;
    this.dataSource._updateChangeSubscription();
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public filtrar(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public selecionarTodos() {
    this.dataSource.data.forEach(d => d.selecionado = !this.elementosSelecionados);
    this.elementosSelecionados = !this.elementosSelecionados;
  }

  public concluir() {
    this.dialogRef.close(this.dataSource.data.filter(d => d.selecionado));
  }

  public cancelar() {
    this.dialogRef.close();
  }
}

