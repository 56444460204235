<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a> 
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/tipos-auditoria']">Tipos de Auditoria</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>
          </div>

          <form [formGroup]="form" #f="ngForm"  (keydown.enter)="$event.preventDefault()">
            <div class="card-body">

              <div class="row">
                <div class="form-group col-md-5">
                  <label for="nome" class="mb-1">Nome</label>
                  <input [attr.disabled]="visualizar ? true : null" type="text" id="nome" class="form-control form-control-sm" formControlName="nome"
                    maxlength="100" [ngClass]="{ 'is-invalid': f.submitted && form.controls['nome'].invalid }" />
                  <div *ngIf="f.submitted && form.controls['nome'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['nome'].errors.required">Campo obrigatório</div>
                  </div>
                </div>
                <div class="form-group col-md-2">
                  <label for="quantidadeMembros" class="mb-1 ">Qtd. de membros</label>
                  <input [attr.disabled]="visualizar ? true : null" type="text"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" 
                  maxlength="4" id="quantidadeMembros" class="form-control form-control-sm text-right"
                    formControlName="quantidadeMembros"
                    [ngClass]="{ 'is-invalid': f.submitted && form.controls['quantidadeMembros'].invalid }" />
                  <div *ngIf="f.submitted && form.controls['quantidadeMembros'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['quantidadeMembros'].errors.required">Campo obrigatório</div>
                  </div>
                </div>
                <div class="form-group col-md-5">
                  <label for="coordenacaoNome" class="mb-1"
                    >Coordenação</label
                >
                <div
                    class="input-group input-group-sm"
                    style="width: 100%"
                >
                    <input
                        type="text"
                        id="coordenacaoNome"
                        class="form-control form-control-sm select-color"
                        readonly
                        formControlName="coordenacaoNome"
                        [ngModel]="model.coordenacao?.nome"
                        [ngClass]="{ 'is-invalid': f.submitted && form.controls['coordenacaoNome'].invalid }"
                    />
                    <div
                        class="input-group-append"
                    >
                        <button
                            [hidden]="visualizar"
                            type="button"
                            class="btn btn-info"
                            (click)="
                                exibirModalCoordenacao()
                            "
                        >
                            <i
                                class="fas fa-search"
                            ></i>
                        </button>
                    </div>
                    <div *ngIf="f.submitted && form.controls['coordenacaoNome'].invalid" class="invalid-feedback">
                      <div *ngIf="form.controls['coordenacaoNome'].errors.required">Campo obrigatório</div>
                    </div>
                    
                </div>
                </div>
              </div>

              <div class="row">
                <div class="card col-md">
                  <div class="card-header">
                    <label class="lbl-titulo">Quantidade de Dias</label>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label for="qtdDiasPlanejamento" class="mb-1">De Planejamento</label>
                        <input [attr.disabled]="visualizar ? true : null" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4"
                        id="qtdDiasPlanejamento" class="form-control form-control-sm text-right"
                          formControlName="qtdDiasPlanejamento"
                          (change)="calcularTotalDias()"
                          [ngClass]="{ 'is-invalid': f.submitted && form.controls['qtdDiasPlanejamento'].invalid }" />
                        <div *ngIf="f.submitted && form.controls['qtdDiasPlanejamento'].invalid" class="invalid-feedback">
                          <div *ngIf="form.controls['qtdDiasPlanejamento'].errors.min">Informar uma quantidade de dias válida</div>
                          <div *ngIf="form.controls['qtdDiasPlanejamento'].errors.required">Campo obrigatório</div>
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="qtdDiasExecucao" class="mb-1">De Execução</label>
                        <input [attr.disabled]="visualizar ? true : null" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" id="qtdDiasExecucao" class="form-control form-control-sm text-right"
                          formControlName="qtdDiasExecucao"
                          (change)="calcularTotalDias()"
                          [ngClass]="{ 'is-invalid': f.submitted && form.controls['qtdDiasExecucao'].invalid }" />
                        <div *ngIf="f.submitted && form.controls['qtdDiasExecucao'].invalid" class="invalid-feedback">
                          <div *ngIf="form.controls['qtdDiasExecucao'].errors.min">Informar uma quantidade de dias válida</div>
                          <div *ngIf="form.controls['qtdDiasExecucao'].errors.required">Campo obrigatório</div>
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="quantidadeDias" class="mb-1">Total (Planej. + Exec.)</label>
                        <input type="number" min="0" id="quantidadeDias" class="form-control form-control-sm text-right" readonly
                          formControlName="quantidadeDias"
                          [ngClass]="{ 'is-invalid': f.submitted && form.controls['quantidadeDias'].invalid }" />
                        <div *ngIf="f.submitted && form.controls['quantidadeDias'].invalid" class="invalid-feedback">
                          <div *ngIf="form.controls['quantidadeDias'].errors.required">Campo obrigatório</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-12">
                  <label for="inputDescricao" class="mb-1">Descrição</label>
                  <textarea [attr.disabled]="visualizar ? true : null" rows="3" id="inputDescricao" class="form-control form-control-sm"
                    formControlName="descricao" maxlength="500" [ngClass]="{ 'is-invalid': f.submitted &&
                    form.controls['descricao'].invalid }">
                  </textarea>
                  <div *ngIf="f.submitted && form.controls['descricao'].invalid" class="invalid-feedback">
                    <div *ngIf="form.controls['descricao'].errors.required">Campo obrigatório</div>
                  </div>
                </div>
              </div>

            </div>

            <div class="card-footer">
              <div class="btn-group" [hidden]="visualizar">
                <button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
                    <i class="far fa-save"></i> Salvar
                </button>
                <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                <div class="dropdown-menu" role="menu">
                <a class="dropdown-item" (click)="salvarDados(false)">Salvar</a>
                <a class="dropdown-item" (click)="salvarDados(true)">Salvar e fechar</a>
                </div>
              </div>

              <button [hidden]="visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Cancelar
              </button>

              <button [hidden]="!visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Sair 
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
