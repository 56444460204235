import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAgentePublicoACModel } from '../models/agente-publico-ac.model';
import { IBaseModel } from '../models/base.model';
import { BaseService } from './base.service';
import { IPerfilAcessoCidadaoModel } from '../models/perfil-acessocidadao.model';

@Injectable({
  providedIn: 'root',
})
export class AcessoCidadaoService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public ObterAgentesPublicosSecont(): Promise<IBaseModel<IAgentePublicoACModel[]>> {
    return this.httpClient
      .get<IBaseModel<IAgentePublicoACModel[]>>(`${this.apiBaseUrl}/acessocidadao/secont/agentesPublicosAtivos`)
      .toPromise();
  }

  public ObterAgentesPublicosPorConjunto(conjuntoId: string): Promise<IBaseModel<IAgentePublicoACModel[]>> {
    return this.httpClient
      .get<IBaseModel<IAgentePublicoACModel[]>>(`${this.apiBaseUrl}/acessocidadao/conjunto/${conjuntoId}/agentespublicos`)
      .toPromise();
  }

  public ObterPorId(id: string): Promise<IBaseModel<IAgentePublicoACModel>> {
    return this.httpClient
      .get<IBaseModel<IAgentePublicoACModel>>(`${this.apiBaseUrl}/acessocidadao/agentepublico/${id}`)
      .toPromise();
  }

  public ObterPerfisAcessoCidadao(): Promise<IBaseModel<IPerfilAcessoCidadaoModel[]>>{
    return this.httpClient
    .get<IBaseModel<IPerfilAcessoCidadaoModel[]>>(`${this.apiBaseUrl}/acessocidadao/buscarPerfisAcessoCidadao`)
    .toPromise();
  }
}
