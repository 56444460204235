
import { IItemSolicitacaoTecnicaModel } from "./../../../../models/item-solicitacao-tecnica.model";
import { IResponsavelModel } from "src/app/models/responsaveis.model";
import { IUnidadeGestoraModel } from "src/app/models/unidade-gestora.model";
import {
	ISolicitacaoTecnicaModel,
	ISolicitacaoTecnicaUnidadeGestoraModel,
} from "./../../../../models/solicitacao-tecnica.model";
import { SolicitacaoTecnicaService } from "./../../../../services/solicitacao-tecnica.service";
import { ModalIncluirItemComponent } from "./../modal-incluir-item/modal-incluir-item.component";
import { angularEditorConfig } from "./../../../util/angularEditorConfig";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import {
	Component,
	Inject,
	OnInit,
	ViewChild,
	AfterViewInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	MatDialog,
	MatDialogConfig,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import Swal from "sweetalert2";
import { ModalJustificativaComponent } from "../../modal-justificativa/modal-justificativa.component";
import { ModalIncluirUgComponent } from "../modal-incluir-ug/modal-incluir-ug.component";
import { IEnumModel } from "src/app/models/enum.model";
import { ModalAvaliarProrrogacaoComponent } from "../modal-avaliar-prorrogacao/modal-avaliar-prorrogacao.component";
import { EnumTipoDocumentoAprovacao } from "src/app/models/enum/tipo-documento-aprovacao.enum";
@Component({
	selector: "app-modal-incluir-solicitacao",
	templateUrl: "./modal-incluir-solicitacao.component.html",
	styleUrls: ["./modal-incluir-solicitacao.component.scss"],
})
export class IncluirSolicitacaoComponent implements OnInit, AfterViewInit {
	public editorConfig: AngularEditorConfig = angularEditorConfig(
		"Digite o assunto",
		"200px",
		"100%"
	);
	public dataSourceUG = new MatTableDataSource<ISolicitacaoTecnicaUnidadeGestoraModel>([]);
	public dataSourceItems = new MatTableDataSource<IItemSolicitacaoTecnicaModel>([]);
	public visualizar: boolean;
	public titulo: string = "";
	public model: ISolicitacaoTecnicaModel = {} as ISolicitacaoTecnicaModel;
	public submitted: boolean;
	public unidadesGetoras: IUnidadeGestoraModel[];
	public responsaveis: IResponsavelModel[];
	public ugSelecionada: IUnidadeGestoraModel;
	public responsavelSelecionado: IResponsavelModel;
	public permitidoIncluir: boolean;
	public tipoTrabalho: IEnumModel = {} as IEnumModel;
	public planejamentoId: number;
	public prorrogacao = true
	public numeroST: string;

	public incluirSolicitacao: FormGroup = this.fb.group({
		assunto: ["", Validators.required],
		prazoResposta: ["", Validators.required],
	});

	@ViewChild("TableOnePaginator", { static: true })
	tableOnePaginator: MatPaginator;
	@ViewChild("tabela1", { static: true }) tabela1: MatSort;

	@ViewChild("TableTwoPaginator", { static: true })
	tableTwoPaginator: MatPaginator;
	@ViewChild("tabela2", { static: true }) tabela2: MatSort;

	@ViewChild("TableThreePaginator", { static: true })
	tableThreePaginator: MatPaginator;
	@ViewChild("tabela3", { static: true }) tabela3: MatSort;

	@ViewChild("maxLengthEditor") private angularEditor: any;

	constructor(
		private fb: FormBuilder,
		public matDialog: MatDialog,
		public serviceSolicitacao: SolicitacaoTecnicaService,
		private dialogRef: MatDialogRef<IncluirSolicitacaoComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		if (data?.id) {
			this.model.id = data?.id;
		}
		this.visualizar = data?.visualizar;
		this.titulo = data?.titulo;
		this.model.status = data?.status;
		this.model.tipoTrabalhoId = data?.tipoTrabalhoId;
		this.model.associado = data?.numeroOpa;
		this.tipoTrabalho = data.tipoTrabalho;
		this.planejamentoId = data.planejamentoId;
		this.numeroST = data?.numeroST;
	}

	ngOnInit(): void {
		if (this.model?.id) {
			this.obterPorId();
		}


		this.dataSourceItems.paginator = this.tableThreePaginator;
		this.dataSourceItems.sort = this.tabela3;
		this.dataSourceItems.sortingDataAccessor = this.sortingDataAccessor;

		this.dataSourceUG.paginator = this.tableOnePaginator;
		this.dataSourceUG.sort = this.tabela1;
		this.dataSourceUG.sortingDataAccessor = this.sortingDataAccessor;

	}

	ngAfterViewInit() {

		function maxLengthAngularEditor(e: any) {
			var element = e.target as HTMLTextAreaElement;
			let permitirTeclas =
				e.key !== "Backspace" &&
				e.key !== "ArrowLeft" &&
				e.key !== "ArrowRight";
			if (element.innerHTML.length === 500 && permitirTeclas) {
				e.preventDefault();
			}
		}

		this.angularEditor?.textArea?.nativeElement.addEventListener(
			"keydown",
			maxLengthAngularEditor,
			false
		);
	}

	public sortingDataAccessor = (item, property) => {
		switch (property) {
			case "ug":
				return item?.unidadeGestora?.text;
			case "responsavel":
				return item?.responsavel?.text;
      case "tituloItem":
				return item?.titulo;
			case "numero":
				return item?.numero;
			case "status":
				return item?.status?.descricao;
			default:
				return item[property];
		}
	};


	public obterPorId() {
		this.serviceSolicitacao.obterPorId(this.model?.id).then((res) => {
			if (res.sucesso) {
				this.dataSourceItems =
					new MatTableDataSource<IItemSolicitacaoTecnicaModel>(
						res.dados.item
					);
				this.incluirSolicitacao.controls["assunto"].setValue(
					res.dados.assunto
				);
        this.model.prazo = new Date(res.dados.prazo);
        this.model.id = res?.dados?.id;
        this.model.status = res?.dados?.status
        this.model.numero = res.dados.numero
        this.model.prorrogarSolicitacao = res?.dados?.prorrogarSolicitacao
		this.dataSourceItems.paginator = this.tableThreePaginator;
        this.dataSourceItems.sort = this.tabela3;
        this.dataSourceItems.sortingDataAccessor = this.sortingDataAccessor;

        this.serviceSolicitacao.obterUgResponsavel(this.model.id).then((res) => {
          if(res.sucesso) {
            this.dataSourceUG.data = res.dados.map(a => {
              return {
                solicitacaoTecnicaId: a.solicitacaoTecnicaId,
                unidadeGestora: {
                    value: a.unidadeGestoraId,
                    text: `${a.siglaUg} - ${a.nomeUg}`
                },
                responsavel: {
                  value: a.responsavelId,
                  text: a.nomeAuditor
                }
              } as ISolicitacaoTecnicaUnidadeGestoraModel
            });

            this.dataSourceUG.paginator = this.tableOnePaginator;
            this.dataSourceUG.sort = this.tabela1;
            this.dataSourceUG.sortingDataAccessor = this.sortingDataAccessor;
          }
        })


			} else {
				Swal.fire({
					toast: true,
					position: "top-end",
					icon: "warning",
					text: res.mensagem.descricao,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
			}
		});
	}

	public finalizar() {
		this.serviceSolicitacao
			.finalizar(this.model?.id)
			.then((res) => {
				if (res.sucesso) {
					this.model.status = res?.dados?.status;
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "success",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
				} else {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "warning",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
				}
			})
			.catch((err) => {
				Swal.fire({
					toast: true,
					position: "top-end",
					icon: "error",
					text: err.mensagem.descricao,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
			});
	}

	public enviar() {
		this.serviceSolicitacao
			.enviar(this.model?.id, this.tipoTrabalho?.nome)
			.then((res) => {
				if (res.sucesso) {
					this.model.status = res?.dados?.status;
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "success",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
          this.dialogRef.close()
				} else {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "warning",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
				}
			})
			.catch((err) => {
				Swal.fire({
					toast: true,
					position: "top-end",
					icon: "error",
					text: err.mensagem.descricao,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
			});
	}


	public excluirUg(i: number, element: ISolicitacaoTecnicaUnidadeGestoraModel) {
    if(element?.solicitacaoTecnicaId) {
      Swal.fire({
        title: "Excluir Registro",
        text: "Tem certeza que deseja excluir esse Registro?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((res) => {
        if (res.value) {
          this.serviceSolicitacao.desvincularUgResponsavel(element.solicitacaoTecnicaId, element.unidadeGestora.value).then((res) => {
            if(res.sucesso) {
              Swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                text: res.mensagem.descricao,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
              this.obterPorId();
            }
          })
        }})
    } else {
      this.dataSourceUG.data.splice(i, 1);
      this.dataSourceUG._updateChangeSubscription();
    }
	}

	public salvar(exibirModalAdicionarItem?: boolean) {
		this.submitted = true;
		if (this.incluirSolicitacao.invalid) {
			return;
		}
    if(this.dataSourceUG.data.length <= 0){
      Swal.fire({
        toast: true,
        title: '',
        position: "top-end",
        icon: "warning",
        text: 'Selecione ao menos uma Unidade Gestora',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return
    }
		const dados = {
			id: this.model?.id,
			prazo: this.incluirSolicitacao.get("prazoResposta").value,
			assunto: this.incluirSolicitacao.get("assunto").value,
			tipoTrabalho: {codigo: this.tipoTrabalho.codigo} ,
			tipoTrabalhoId: this.data?.tipoTrabalhoId
		} as ISolicitacaoTecnicaModel;

		this.serviceSolicitacao
			.salvarSolicitacao(dados)
			.then((res) => {
				if (res.sucesso) {
					this.model.id = res?.dados?.id;
          this.salvarUgResponsavel()
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "success",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
          if (exibirModalAdicionarItem) this.modalIncluiritem('Incluir', false);
				} else {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "warning",
						text: res.mensagem.descricao,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					});
				}
			})
			.catch((err) => {
				Swal.fire({
					toast: true,
					position: "top-end",
					icon: "error",
					text: err.mensagem.descricao,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				});
			}).finally(()=>{
        this.obterPorId()
      })
	}

	public modalIncluiritem(
		titulo: string,
		visualizar: boolean,
		element?: IItemSolicitacaoTecnicaModel
	)
  {if(!this.model?.id){
    Swal.fire({
      toast: true,
      position: "top-end",
      title: "Atenção",
      icon: "warning",
      text: "Antes, salve as informações!",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
    });
      this.salvar(true)
    }else{
    const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component";
		dialogConfig.width = "45vw";
		dialogConfig.maxHeight = "90vh";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		dialogConfig.data = {
			titulo,
			visualizar,
			id: this.model?.id,
			element,
      		nSolicitacaoTecnica: this.numeroST
		};
		const modal = this.matDialog.open(
			ModalIncluirItemComponent,
			dialogConfig
		);
		modal.afterClosed().subscribe((data) => {
		if (data) {
			this.obterPorId()
		}     
    });
    }

	}
	public modalIncluirUg() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component-ug";
		dialogConfig.width = "70vw";
		dialogConfig.maxHeight = "65vh";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		dialogConfig.data = {
      id: this.model?.tipoTrabalhoId,
      dataSourcePaiUg: this.dataSourceUG.data,
      tipoTrabalho: this.tipoTrabalho,
      planejamentoId: this.planejamentoId
    };
		const modal = this.matDialog.open(
			ModalIncluirUgComponent,
			dialogConfig
		);
		modal.afterClosed().subscribe((data: ISolicitacaoTecnicaUnidadeGestoraModel[]) => {
      if (data) {
        data.forEach(element => {
          this.dataSourceUG.data = [...this.dataSourceUG.data, element]
        });
      }
    });
	}

  public salvarUgResponsavel(){
    const dados = {
      ugsResponsavel: this.dataSourceUG.data.map((item) => {
        return {
          unidadeGestoraId: +item.unidadeGestora.value,
          responsavelId: item.responsavel.value
        }
      })
    }
    this.serviceSolicitacao.salvarUgResponsavel(this.model.id, dados).then((res) => {
      if(res.sucesso){
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      this.obterPorId()
      }else{
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    }).catch((err) => {
      Swal.fire({
        toast: true,
        position: "top-end",
        text: err.mensagem.descricao,
        icon: "error",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    })
  }

	public excluirItem(id: number) {
		Swal.fire({
			title: "Excluir Registro",
			text: "Tem certeza que deseja excluir esse Registro?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then((res) => {
			if (res.value) {
				this.serviceSolicitacao
					.excluirItem(this.model?.id, id)
					.then((res) => {
						if (res.sucesso) {
							Swal.fire({
								toast: true,
								position: "top-end",
								icon: "success",
								text: res.mensagem.descricao,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							});
						} else {
							Swal.fire({
								toast: true,
								position: "top-end",
								icon: "warning",
								text: res.mensagem.descricao,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							});
						}
					})
					.catch((err) => {
						Swal.fire({
							toast: true,
							position: "top-end",
							text: err.mensagem.descricao,
							icon: "error",
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
					})
					.finally(() => {
            this.obterPorId()
          });
			}
		});
	}

	public cancelarItem(id: number) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component";
		dialogConfig.width = "45vw";
		dialogConfig.maxHeight = "85vh";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = false;
		dialogConfig.data = {};
		const modal = this.matDialog.open(
			ModalJustificativaComponent,
			dialogConfig
		);
		modal.afterClosed().subscribe((data) => {
			if (data) {
				this.serviceSolicitacao
					.cancelarItem(this.model?.id, id , data)
					.then((res) => {
						if (res.sucesso) {
							Swal.fire({
								toast: true,
								position: "top-end",
								icon: "success",
								text: res.mensagem.descricao,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							});
						} else {
							Swal.fire({
								toast: true,
								position: "top-end",
								icon: "warning",
								text: res.mensagem.descricao,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							});
						}
					})
					.catch((err) => {
						Swal.fire({
							toast: true,
							position: "top-end",
							icon: "error",
							text: err.mensagem.descricao,
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
					})
					.finally(() => {
            this.obterPorId()
          });
			}
		});
	}

	public avaliar() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component";
		dialogConfig.width = "25vw";
		dialogConfig.maxHeight = "85vh";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		dialogConfig.data = {
      solicitacaoId: this.model?.id,
      data: this.model?.prorrogarSolicitacao?.data
    };
		const modal = this.matDialog.open(
			ModalAvaliarProrrogacaoComponent,
			dialogConfig
		);
		modal.afterClosed().subscribe((data) => {
			this.obterPorId()
		});
	}

	public cancelar() {
		this.dialogRef.close();
	}
}
