import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FiltroModel } from 'src/app/models/filtro.model';
import { AuditoriaService } from 'src/app/services/auditoria.service';

@Component({
  selector: 'app-auditoria-list',
  templateUrl: './auditoria-list.component.html',
  styleUrls: ['./auditoria-list.component.scss']
})
export class AuditoriaListComponent implements OnInit {
  public dataSource = new MatTableDataSource([])
  public selectedModel: any;
  public semDados!: boolean;
  public pageEvent: any;
  public totalItens: number;
  public searchPesquisa: string;
  public searchAtivo = true;
  public filtro = new FiltroModel();
  public displayedColumn: string[] = ['numero', 'ano', 'dataFim', 'dataInicio', 'status', 'ativo',];

  @ViewChild('TableOnePaginator', {static: true}) tableOnePaginator: MatPaginator;
  @ViewChild('tabela1') public tabela1: MatSort;

  constructor(
    public router: Router,
    public service: AuditoriaService
    ) {
     }

  ngOnInit(): void {
    this.configurarFiltro();
    this.obter();
    this.configurarDatasource();
  }

  obter() {
    this.configurarFiltro()
    this.service.obterPorFiltros(this.filtro).subscribe((resultado) => {
      if(resultado.sucesso){
        this.dataSource = new MatTableDataSource<any>(resultado?.dados?.resultado);
        this.dataSource.sort = this.tabela1;
        this.semDados = this.dataSource.data.length === 0;
        this.totalItens = resultado.dados.totalItens;
      }
    })
  }

  private configurarDatasource() {
    this.dataSource.sort = this.tabela1;
  }

  private configurarFiltro() {
    this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
    this.filtro.itensPorPagina = this.tableOnePaginator.pageSize;
    this.filtro.colunaOrder = "Id";
    this.filtro.ativo = this.searchAtivo;
    this.filtro.pesquisa = this.searchPesquisa;
  }

  pageChanged(e) {
    this.filtro.pagina = e.pageIndex + 1;
    e.pageIndex = this.filtro.pagina;
    this.filtro.itensPorPagina = e.pageSize;
    this.obter();
  }

  selecionar(item: any): void {
    this.selectedModel = !this.selectedModel || this.selectedModel !== item ? item : null;
  }

}
