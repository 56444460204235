<div class="p-4 content">
	<div class="card card-outline">
		<div class="card-header d-flex pb-0">
			<h3>Solicitação Técnica</h3>
		</div>
		<div class="card-body p-2">
			<div class="header-imprimir">
				<img
					src="assets/img/logo_es_color.png"
					alt="SECONT-SIAC"
					class="brand-image"
					style="opacity: 0.8"
				/>
				<div class="title">
					<h5>
						<strong>Governo do Estado do Espírito Santo</strong>
					</h5>
					<h4>
						Secretaria de Estado de Controle e Transparência
						(SECONT)
					</h4>
				</div>
			</div>
			<div class="card-body p-2">
				<div>
					<div class="row border border-top-0 border-dark">
						<div class="col-3">
							<strong>Associado a:</strong>
							<p>{{ model?.planejamento?.opa?.numero }}</p>
						</div>
						<div
							class="
								col-3
								border border-top-0 border-bottom-0 border-dark
							"
						>
							<strong>Nº Solicitação Técnica:</strong>
							<p *ngIf="model?.numero">{{ model?.numero }}</p>
							<p *ngIf="!model?.numero">-</p>
						</div>
						<div
							class="
								col-3
								border
								border-top-0
								border-left-0
								border-bottom-0
								border-dark
							"
						>
							<strong>Prazo:</strong>
							<p>{{ model?.prazo | date: "shortDate" }}</p>
						</div>

						<div class="col-3">
							<strong>Status:</strong>
							<p>{{ model?.status?.descricao }}</p>
						</div>
					</div>
					<div
						class="row p-2 border border-top-0 border-dark d-block"
					>
						<strong>Assunto:</strong>
						<p>{{ model?.assunto }}</p>
					</div>
					<div
						class="row p-2 border border-top-0 border-dark"
						*ngFor="
							let ug of model?.solicitacaoTecnicaUgResponsavel
						"
					>
						<div class="col-6">
							<strong>Unidade Gestora:</strong>
							<p>{{ ug?.siglaUg }} - {{ ug?.nomeUg }}</p>
						</div>
						<div class="col-6">
							<strong>Responsável:</strong>
							<p>{{ ug?.nomeAuditor }}</p>
						</div>
					</div>
					<div
						*ngFor="let item of model?.item"
						class="row p-2 border border-top-0 border-dark d-block"
					>
						<strong>Item Nº: </strong>
						<p>{{ item?.numero }}</p>
						<div class="row p-2 d-block">
							<strong>Titulo:</strong>
							<p>{{ item?.titulo }}</p>
						</div>
						<div class="row p-2 d-block">
							<strong>Descrição:</strong>
							<p>{{ item?.descricao }}</p>
						</div>
						<div class="row p-2 d-block">
							<strong>Arquivos do Item:</strong>
							<div *ngFor="let anexo of item?.anexo">
								<p>{{ anexo?.nomeArquivo }}</p>
							</div>
						</div>
						<div class="row p-2 d-block">
							<strong>Descrição da Resposta:</strong>
							<p *ngIf="item?.resposta">
								{{ item?.resposta?.descricao }}
							</p>
							<p *ngIf="!item?.resposta">NÃO RESPONDIDO</p>
						</div>
						<div class="row p-2 d-block">
							<strong>Arquivos da Resposta:</strong>
							<div *ngFor="let anexo of item?.resposta?.anexo">
								<p>{{ anexo?.nomeArquivo }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="card-footer doNotPrint">
			<button
				type="button"
				class="btn btn-default btn-sm mr-1"
				(click)="cancelar()"
			>
				<i class="fas fa-times"></i> Fechar
			</button>
			<button
				type="button"
				class="btn btn-primary btn-sm mr-1"
				(click)="print()"
			>
				<i class="fas fa-print"></i> Imprimir
			</button>
		</div>
	</div>
</div>
