import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { MatTableDataSource } from '@angular/material/table';
import Inputmask from 'inputmask';
import * as cloneDeep from 'lodash/cloneDeep';
import { ToastrService } from 'ngx-toastr';
import { IAuditorCoordenacaoModel } from 'src/app/models/auditor-coordenacao.model';
import { ICoordenacaoModel } from 'src/app/models/coordenacao.model';
import { IPeaCoordenacaoCapacidadeModel } from 'src/app/models/pea-coordenacao-capacidade.model';
import { IPeaCoordenacaoDispensaModel } from 'src/app/models/pea-coordenacao-dispensa.model';
import { ITipoDispensaModel } from 'src/app/models/tipo-dispensa.model';
import { PeaService } from 'src/app/services/pea.service';
import { ModalCancelarComponent } from '../../../shared/components/modal-cancelar/modal-cancelar-component';
import Swal from 'sweetalert2';
import { IAgentePublicoModel } from 'src/app/models/agente-publico.model';
import { IAgentePublicoDisponibilidadeModel } from 'src/app/models/agente-publico-disponibilidade.model';

@Component({
  selector: 'app-modal-capacidade',
  templateUrl: './modal-capacidade.component.html',
  styleUrls: ['./modal-capacidade.component.scss']
})
export class ModalCapacidadeComponent implements OnInit {
  public model: IPeaCoordenacaoCapacidadeModel;
  public visualizar: boolean;

  public modelNovaDispensa = {} as IPeaCoordenacaoDispensaModel;
  public dispensaDataSource = new MatTableDataSource<any>([]);
  public auditorDataSource = new MatTableDataSource<any>([]);

  public dispensaSemDados = true;
  public auditorSemDados = true;
  public colunasOriginais = ['nomeAuditor'];
  public colunasAdicionar = [];
  public columnsDisponibilidades = [];

  public tabelaAuditores = [] as IAgentePublicoModel[];

  constructor(
    private dialogRef: MatDialogRef<ModalCapacidadeComponent>,
    public toastr: ToastrService,
    private peaService: PeaService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {

    this.visualizar = data.visualizar;  
    this.model = data.capacidade;
    
    this.tabelaAuditores = (data.coordenacao as ICoordenacaoModel).auditores;

    for (const auditor of this.tabelaAuditores) {

      //Monta lista dispensas
      for(const dispensa of auditor.dispensas){
        this.dispensaDataSource.data.push({
          nomeAuditor: auditor.pessoa.nome,
          descricao: dispensa.tipoDispensa.nome,
          dataInicio: dispensa.dataInicioFormatted,
          dataFim: dispensa.dataFimFormatted,
          qtdDias: dispensa.qtdDias
        })
      };

      this.auditorDataSource.data.push({
        nomeAuditor: auditor.pessoa.nome,
        disponibilidades: auditor.disponibilidades
      })      
      
    }

     this.dispensaSemDados = this.dispensaDataSource.data.length === 0;
     this.auditorSemDados = this.auditorDataSource.data.length === 0;

    // Montar lista disponibilidade Auditores
    var colunasNomes: any = {};
    if(this.tabelaAuditores[0]){
      this.tabelaAuditores[0].disponibilidades.forEach((disponibilidade) => {
        
        const nomeColuna = disponibilidade.tipoTrabalho.nome;
        if(!this.colunasAdicionar.some((c) => c === nomeColuna)){
            this.colunasAdicionar.push(nomeColuna);
        }
      })
    }
    this.columnsDisponibilidades = [];
    this.columnsDisponibilidades = this.colunasOriginais.concat(this.colunasAdicionar);

    this.auditorDataSource._updateChangeSubscription();
    
    
  }

  public obterValorColuna(element: any, coluna: string): string{
    
    if(!element || !coluna)
      return '';

    const result = element.disponibilidades.find((d) => d.tipoTrabalho.nome == coluna);
    if(!result)
      return '';

    let retorno = result.percentual.toFixed(2).toString();
    retorno += "%";
    return retorno;
  }

  ngOnInit(): void {
    Inputmask().mask(document.querySelectorAll('input'));
  }

  
  public fechar(){
    return this.matDialog.closeAll()
  }
}

