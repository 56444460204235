import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IEntranharDocumentoModel } from 'src/app/models/edocs/entranhar-documento.model';
import { IPapelModel } from 'src/app/models/edocs/papel.model';
import { AuthService } from 'src/app/services/auth.service';
import { EdocsService } from 'src/app/services/edocs.service';
import { OpaService } from 'src/app/services/opa.service';
import { OsaService } from 'src/app/services/osa.service';
import { PaaService } from 'src/app/services/paa.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-entranhar',
  templateUrl: './modal-entranhar.component.html',
  styleUrls: ['./modal-entranhar.component.scss']
})
export class ModalEntranharComponent implements OnInit {
  private tipoTrabalhoId: number;
  public tipoTrabalho: number;
  public comentario: string;
  public model = {} as IEntranharDocumentoModel;
  public papeis = [] as IPapelModel[];

  constructor(
    private dialogRef: MatDialogRef<ModalEntranharComponent>,
    public matDialog: MatDialog,
    public toastr: ToastrService,
    private edocsService: EdocsService,
    private paaService: PaaService,
    public opaService: OpaService,
    public osaService: OsaService,
    public planejamentoService: PlanejamentoService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) data) {

    this.tipoTrabalhoId = data.tipoTrabalhoId;
    this.tipoTrabalho = data.tipoTrabalho;
    this.comentario = data.comentario;
  }

  async ngOnInit() {
    await this.obterPapeis();
  }

  public async obterPapeis() {

    try {
      const res = await this.edocsService.obterPapeis();
      if (res.sucesso) {
        this.papeis = res.dados;
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      })
    }
  }

  public async concluir() {

    try {
      let res;
      this.model.comentario = this.comentario;
      if(!this.tipoTrabalho){
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: 'Tipo de Trabalho indefinido',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
      }
      else if(this.tipoTrabalho === 1){
         res = await this.paaService.homologar(this.tipoTrabalhoId, this.model)
      }
      else if(this.tipoTrabalho === 2){
         res = await this.opaService.homologar(this.tipoTrabalhoId, this.model)
      }
      else if(this.tipoTrabalho === 3){
         res = await this.planejamentoService.homologar(this.tipoTrabalhoId, this.model)
      }
      else if(this.tipoTrabalho === 4){
         res = await this.osaService.homologar(this.tipoTrabalhoId, this.model)
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Item homologado com sucesso!',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
        this.dialogRef.close(true);
        this.matDialog.closeAll();
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        })
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      })
    }
  }

  public cancelar() {
    this.dialogRef.close(false);
  }
}

