<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a
            >
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/rolresponsaveis']">Rol de Responsáveis</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group col-md-2">
                <label for="codigoUg" class="mb-1">Código da UG</label>
                <input
                  disabled
                  type="text"
                  class="form-control form-control-sm"
                  id="codigoUg"
                  [(ngModel)]="model.codigoUg"
                />
              </div>
              <div class="form-group col-md-10">
                <label for="nome" class="mb-1">Unidade Gestora</label>
                <input
                  type="text"
                  id="nome"
                  class="form-control form-control-sm"
                  disabled
                  [(ngModel)]="model.sigla + ' - ' + model.nome"
                />
              </div>
            </div>
            <div class="card card-outline">
              <div class="card-header">
                <h3 class="card-title mb-0">
                  <b>Responsáveis</b>
                </h3>
              </div>
              <div class="card-body p-0">
                <div class="mat-elevation-z4">
                  <mat-toolbar  class="table-header">
                    <mat-toolbar-row>
                      <button
                        [hidden]="visualizar"
                        type="button"
                        class="btn btn-success btn-sm mr-1"
                        (click)="modalIncluirResponsavel()"
                      >
                        <i class="fas fa-plus"></i> Adicionar
                      </button>

                      <button type="button" class="btn btn-info btn-sm mr-1" (click)="imprimir()">
                        <i class="fas fa-print"></i> Imprimir
                      </button>

                      <span class="fill-remaining-space"></span>
                      <div
                        class="input-group input-group-sm mr-1"
                        style="width: 100px">
                        <input
                          type="text"
                          name="table_search"
                          class="form-control float-right"
                          placeholder="Natureza da Responsabilidade"
                          [(ngModel)]="NaturezaResponsabilidade"
                        />
                      </div>                      
                      <div
                        class="input-group input-group-sm mr-1"
                        style="width: 100px">
                        <input
                          type="text"
                          name="table_search"
                          class="form-control float-right"
                          placeholder="Tipo de Agente"
                          [(ngModel)]="TipoAgente"
                        />
                      </div>
                      <div
                        class="input-group input-group-sm mr-1"
                        style="width: 100px">
                        <input
                          type="datetime"
                          name="date_search"
                          class="form-control float-right"
                          placeholder="Data Início"
                          [(ngModel)]="dataInicio"
                          bsDatepicker
                          [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                          />
                      </div>
    
                      <div
                        class="input-group input-group-sm mr-1"
                        style="width: 100px">
                        <input
                          type="datetime"
                          name="date_search"
                          class="form-control float-right"
                          placeholder="Data Fim"
                          [(ngModel)]="dataFim"
                          bsDatepicker
                          [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                          />
                      </div>

                      <div
                        class="input-group input-group-sm"
                        style="width: 200px"
                      >
                        <input
                          type="text"
                          name="table_search"
                          class="form-control float-right"
                          (keyup.enter)="buscar()"
                          placeholder="Pesquisar por Nome"
                          [(ngModel)]="searchNome"
                        />
                        <div class="input-group-append">
                          <button
                            type="button"
                            class="btn btn-info"
                            (click)="buscar()"
                          >
                            <i class="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                      <div class="btn-group">
                        <button
                          type="button"
                          data-toggle="dropdown"
                          class="btn btn-tool btn-default dropdown-toggle"
                        >
                          <i class="fas fa-filter"></i>
                        </button>
                        <div
                          role="menu"
                          class="
                            dropdown-menu
                            dropdown-menu-right
                            dropdown-menu-checkbox
                          "
                        >
                          <a
                            (click)="searchAtivo = undefined; buscar()"
                            class="dropdown-item"
                            ><i
                              [ngClass]="
                                searchAtivo === undefined
                                  ? 'fas fa-check-circle'
                                  : 'far fa-circle'
                              "
                            ></i
                            >Exibir todos</a
                          >
                          <a
                            (click)="searchAtivo = true; buscar()"
                            class="dropdown-item"
                            ><i
                              [ngClass]="
                                searchAtivo === true
                                  ? 'fas fa-check-circle'
                                  : 'far fa-circle'
                              "
                            ></i
                            >Exibir somente ativos</a
                          >
                          <a
                            (click)="searchAtivo = false; buscar()"
                            class="dropdown-item"
                            ><i
                              [ngClass]="
                                searchAtivo === false
                                  ? 'fas fa-check-circle'
                                  : 'far fa-circle'
                              "
                            ></i
                            >Exibir somente inativos</a
                          >
                        </div>
                      </div>
                    </mat-toolbar-row>
                  </mat-toolbar>

                  <table mat-table [dataSource]="dataSource" matSort class="table table-hover" [hidden]="semDados">
                   <!-- Coluna Nome -->
                    <ng-container matColumnDef="nome">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="v-align-middle">Nome</th>
                      <td mat-cell *matCellDef="let element">{{ element.nome }}</td>
                    </ng-container>
                  
                    <!-- Coluna Natureza da Responsabilidade -->
                    <ng-container  matColumnDef="naturezaResponsabilidade">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="v-align-middle">Natureza da Responsabilidade</th>
                      <td mat-cell *matCellDef="let element">{{ element.nomeNaturezaResponsabilidadeId }}</td>
                    </ng-container>
                  
                    <!-- Coluna Tipo de Agente -->
                    <ng-container matColumnDef="tipoAgente">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="v-align-middle">Tipo de Agente</th>
                      <td mat-cell *matCellDef="let element">{{ element.nomeTipoAgente }}</td>
                    </ng-container>
                  
                    <!-- Coluna Data Início -->
                    <ng-container matColumnDef="dataInicio">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="v-align-middle">Data Início</th>
                      <td mat-cell *matCellDef="let element">{{ element.dataInicio | date: 'dd/MM/yyyy' }}</td>
                    </ng-container>
                  
                    <!-- Coluna Data Fim -->
                    <ng-container matColumnDef="dataFim">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="v-align-middle">Data Fim</th>
                      <td mat-cell *matCellDef="let element">{{ element.dataFim | date: 'dd/MM/yyyy' }}</td>
                    </ng-container>
                  
                    <!-- Coluna Situação -->
                    <ng-container matColumnDef="ativo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="v-align-middle text-center">Situação</th>
                      <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.ativo" class="badge bg-success text-xs">Ativo</span>
                        <span *ngIf="!element.ativo" class="badge bg-gray-dark text-xs">Inativo</span>
                      </td>
                    </ng-container>
                  
                    <!-- Coluna Ações -->
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef class="v-align-middle text-center" [hidden]="!visualizar">Visualizar</th>
                      <td mat-cell *matCellDef="let element">
                        <div class="btn-group mr-1">
                          <button
                            [hidden]="visualizar"
                            type="button"
                            class="btn btn-primary btn-xs text-left"
                            (click)="modalIncluirResponsavel(true, element)"
                          >
                            <i class="far fas fa-pen"></i>
                          </button>
                          <button
                            [hidden]="!visualizar && visualizar"
                            type="button"
                            class="btn btn-info btn-xs text-left"
                            (click)="modalIncluirResponsavel(false, element, true)"
                          >
                            <i class="far fas fa-eye"></i>
                          </button>
                          <button
                            [hidden]="visualizar"
                            type="button"
                            class="btn btn-danger btn-xs text-left"
                          >
                            <i class="far fa-trash-alt" (click)="excluirResponsaveis(element.id)"></i>
                          </button>
                          <button
                            [hidden]="visualizar || element.ativo"
                            type="button"
                            class="btn btn-warning btn-xs text-left"
                            (click)="inativarReativarResponsaveis(element.id, true)"
                          >
                            <i class="far fa-play-circle"></i>
                          </button>
                          <button
                            [hidden]="visualizar || !element.ativo"
                            type="button"
                            class="btn btn-warning btn-xs text-left"
                            (click)="inativarReativarResponsaveis(element.id, false)"
                          >
                            <i class="far fa-pause-circle"></i>
                          </button>
                        </div>
                      </td>
                    </ng-container>
                  
                    <!-- Cabeçalho e linhas da tabela -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->

                  </table>
                  
                  
                  
                  <h5 [hidden]="!semDados" class="table-no-data">
                    Nenhum registro encontrado.
                  </h5>
                  <mat-paginator
                    [pageSizeOptions]="[15, 30, 50]"
                    showFirstLastButtons
                  >
                  </mat-paginator>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button
              type="button"
              class="btn btn-default btn-sm"
              (click)="onBack()"
            >
              <i class="fas fa-times"></i> Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
