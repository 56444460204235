<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/log']">Logs do Sistema</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>
          </div>
          
          <div class=" card-body">
            <div class="row">
              <div class="form-group col-md-4">
                <label for="txtFuncionalidade" class="mb-1">Funcionalidade</label>
                <input type="text" id="txtFuncionalidade" class="form-control form-control-sm" disabled="true" [value]="model.funcionalidade.descricao"/>
              </div>

              <div class="form-group col-md-4">
                <label for="txtTipoLog" class="mb-1">Ação Realizada</label>
                <input type="text" id="txtTipoLog" class="form-control form-control-sm" disabled="true" [value]="model.tipoLog.descricao"/>
              </div>

              <div class="form-group col-md-4">
                <label for="txtDataRegistro" class="mb-1">Data</label>
                <input type="text" id="txtDataRegistro" class="form-control form-control-sm" disabled="true" [value]="model.dataRegistro | date:'dd/MM/yyyy HH:mm:ss'"/>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6">
                <label for="txtNomeUsuario" class="mb-1">Usuário</label>
                <input type="text" id="txtNomeUsuario" class="form-control form-control-sm" disabled="true" [value]="model.nomeUsuario"/>
              </div>

              <div class="form-group col-md-6">
                <label for="txtIp" class="mb-1">IP</label>
                <input type="text" id="txtIp" class="form-control form-control-sm" disabled="true" [value]="model.ip"/>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-12">
                <label for="txtUrl" class="mb-1">URL</label>
                <input type="text" id="txtUrl" class="form-control form-control-sm" disabled="true" [value]="model.url"/>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-12">
                <label for="txtAntes" class="mb-1">Antes</label>
                <textarea id="txtAntes" rows="10" class="form-control form-control-sm" disabled="true">{{model.antes}}</textarea>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-12">
                <label for="txtDepois" class="mb-1">Depois</label>
                <textarea id="txtDepois" rows="10" class="form-control form-control-sm" disabled="true">{{model.depois}}</textarea>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
              <i class="fas fa-times"></i> Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>