import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { IBaseModel } from '../../models/base.model';
import { IPaaDadosBasicosModel } from '../../models/paa-dados-basicos.model';
import { IPaaModel } from '../../models/paa.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';

@Injectable({
    providedIn: 'root'
  })
export class PaaAbaDadosBasicosService extends BaseService{
  
  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public async obterDadosBasicos(id: number): Promise<IBaseModel<IPaaDadosBasicosModel>>{
    
    return this.httpClient
    .get<IBaseModel<IPaaDadosBasicosModel>>(`${this.apiBaseUrl}/paaAbaDadosBasicos/${id}/dadosbasicos`)
    .toPromise();
  }

  public gerarNova(): Promise<IBaseModel<IPaaModel>> {
    return this.httpClient
      .get<IBaseModel<IPaaModel>>(`${this.apiBaseUrl}/paa/novo`)
      .toPromise();
  }

  public async atualizarDadosBasicos(data: IPaaDadosBasicosModel): Promise<IBaseModel<IPaaDadosBasicosModel>>{
    
    return this.httpClient
    .put<IBaseModel<IPaaDadosBasicosModel>>(`${this.apiBaseUrl}/paaAbaDadosBasicos`, data)
    .toPromise();
  }

  public async inserir(data: IPaaDadosBasicosModel): Promise<IBaseModel<IPaaDadosBasicosModel>> {
    
    return this.httpClient
      .post<IBaseModel<IPaaDadosBasicosModel>>(`${this.apiBaseUrl}/paaAbaDadosBasicos`, data)
      .toPromise();
  }
}
