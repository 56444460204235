import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { ImprimirModel } from 'src/app/models/imprimir-model';
import { IPaaCoordenacaoCapacidadeModel } from 'src/app/models/paa-coordenacao-capacidade.model';
import { IPaaGraficoModel } from 'src/app/models/paa-grafico.model';
import { IPaaModel } from 'src/app/models/paa.model';
import { IPeriodoModel } from 'src/app/models/periodo.model';
import { AprovacaoService } from 'src/app/services/aprovacao.service';
import { PaaService } from 'src/app/services/paa.service';
import { GroupByPipe } from 'src/app/shared/pipes/groupby-pipe';
import { IPaaDistribuicaoModel } from 'src/app/models/paa-distribuicao.model';
import { MatTableDataSource } from '@angular/material/table';
import { IPaaDistribuicaoColunasModel } from 'src/app/models/paa-distribuicao-colunas.model';
import { MatSort, MatSortable } from '@angular/material/sort';


@Component({
  selector: 'app-paa-relatorio',
  templateUrl: './paa-relatorio.component.html',
  styleUrls: ['./paa-relatorio.component.scss'],
  providers: [GroupByPipe]
})
export class PaaRelatorioComponent implements OnInit {
  idParam: number;
  homologado = false;
  dados: ImprimirModel = {} as ImprimirModel;
  periodo: IPeriodoModel;
  public totalCapacidadeCoordenacaoes: number = 0 as number;
  public totalCapacidadeCoordenacaoesPea: number = 0 as number;
  espelho = false;
  filterPainelDistribuicao!: any[];
  listaEquipes!: string[];
  public grafico: IPaaGraficoModel[] = [] as IPaaGraficoModel[];
  groupedData: any;
  public distribuicaoDataSource = new MatTableDataSource<IPaaDistribuicaoModel>([]);
  public columnsDistribuicaoOriginais1 = ['unidadeGestora', 'valorRisco'];
  public columnsDistribuicao = [];
  public colunasAdicionarDistribuicao = [];
  @ViewChild('tabela3') public tabela3: MatSort;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private localeService: BsLocaleService,
    private paaService: PaaService,
    private aprovacaoService: AprovacaoService,
    private groupByPipe: GroupByPipe
  ) {  }

  ngOnInit() {
    this.localeService.use('pt-br');
    const id = this.route.snapshot.paramMap.get('id');
    const path = this.route.snapshot.url.pop().toString();
    
    if (id) {
      this.idParam = parseInt(id, 10);
    }
    
    if (path === 'espelho-relatorio') {
      this.espelho = true;
    }
    
    this.obterDados();
    this.obterGrafico();
    
  }

  public async obterGrafico() {
    try {
      this.grafico = (await this.paaService.obterGrafico(Number(this.route.snapshot.paramMap.get('id')))).dados;
    } catch (err) {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
    }
  }

  groupData() {
    if (this.dados && this.dados.indicadores) {
        this.groupedData = this.groupByPipe.transform(this.dados.indicadores);
    } else {
        this.groupedData = {}; // ou qualquer outro valor padrão desejado
    }
}

  
getObjectKeys(obj: any): string[] {
  return obj ? Object.keys(obj) : [];
}

  public async obterDados() {
    try {
      let res: IBaseModel<ImprimirModel>;

      if (this.espelho) {
        const avaliacao = await this.aprovacaoService.imprimir(this.idParam);
        res = {
          sucesso: avaliacao.sucesso,
          dados: JSON.parse(avaliacao.dados),
          mensagem: avaliacao.mensagem,
          resultadoValidacao: avaliacao.resultadoValidacao
        } as IBaseModel<ImprimirModel>;
      }
      else {
        res = await this.paaService.imprimir(this.idParam);
      }

      if (res.sucesso) {
        this.dados = res.dados;
        
        this.groupData();
        this.homologado = res.dados.paa.situacao.nome === 'Homologado';

       
        
        this.dados.capacidadeTrabalho = res.dados.capacidadeTrabalho.map((item) => {
          return {
            coordenacaoSigla: item.coordenacaoSigla,
            capacidadeTotalCoordenacao: item.capacidadeTotalCoordenacao as number,
            capacidadeTotalCoordenacaoPea: item.capacidadeTotalCoordenacaoPea as number,
            auditores: item.auditor.map(_ => _.nomeAuditor)
          } as IPaaCoordenacaoCapacidadeModel;
        });
        this.totalCapacidadeCoordenacaoes = res.dados.capacidadeTrabalho.map(_ => _.capacidadeTotalCoordenacao).reduce((sum, current) => sum + current, 0);
        this.totalCapacidadeCoordenacaoesPea = res.dados.capacidadeTrabalho.map(_ => _.capacidadeTotalCoordenacaoPea).reduce((sum, current) => sum + current, 0);
        
        this.dados.equipes = res.dados.equipes.filter(c => c.paaDistribuicao.ativo === true);
        this.colunasAdicionarDistribuicao = [];
        this.columnsDistribuicao = [];
        this.columnsDistribuicao = this.columnsDistribuicao.concat(
          Object.assign([], this.columnsDistribuicaoOriginais1)
        );

        var colunasNomes: any = {};
      if (res && res.dados && res.dados.painelDistribuicao && res.dados.painelDistribuicao[0] && res.dados.painelDistribuicao[0].colunasValores) {
        
        res.dados.painelDistribuicao[0].colunasValores.forEach((element) => {
          const nomeColuna = this.obterNomeColuna(element);

          colunasNomes[nomeColuna.toString()] = nomeColuna;

          this.columnsDistribuicao.push(nomeColuna);
          if (
            !this.colunasAdicionarDistribuicao.some((c) => c === nomeColuna)
          ) {
            this.colunasAdicionarDistribuicao.push(nomeColuna);
          }
        });
      }

      var dadosDistribuicao = res.dados.painelDistribuicao.map((item) => {
        if (item.tipoAuditoria?.id == 0)
          item.tipoAuditoriaId = null;
        item.unidadeGestora.sigla = item.unidadeGestora.sigla.toUpperCase();
        return item;
      });

      this.distribuicaoDataSource =
        new MatTableDataSource<IPaaDistribuicaoModel>(dadosDistribuicao);
      this.distribuicaoDataSource._updateChangeSubscription();

      this.distribuicaoDataSource.sortingDataAccessor = (
        item: IPaaDistribuicaoModel,
        property
      ) => {
        switch (property) {
          case 'unidadeGestora':
            return item.unidadeGestora.sigla;

          case 'valorRisco':
            return item.valorRisco;

          case colunasNomes[property]:
            return parseFloat(
              this.obterValorColuna(item, property)
                .replace(/[.]/g, '')
                .replace(/[,]/g, '.') == ''
                ? '0'
                : this.obterValorColuna(item, property)
                  .replace(/[.]/g, '')
                  .replace(/[,]/g, '.')
            );
          default:
            return item[property];
        }
      };

      this.distribuicaoDataSource.sort = this.tabela3;
        

      }
      else {
        this.toastr.error(res.mensagem.descricao, 'Atenção');
        this.router.navigate(['/paa']);
      }

    } catch (err) {
      console.error('Erro ao obter dados:', err);
      this.toastr.error(err?.mensagem?.descricao || 'Erro desconhecido', 'Atenção');
      this.router.navigate(['/paa']);
    }

    
  }

  private obterNomeColuna(element: IPaaDistribuicaoColunasModel) {
    return element.nomeIndicador && element.nomeIndicador !== ''
      ? element.nomeIndicador + ' / ' + element.nomeCriterio
      : element.nomeCriterio;
  }

  public obterValorColuna(
    element: IPaaDistribuicaoModel,
    coluna: string
  ): string {
    if (!element || !coluna) {
      return '';
    }

    const result = element.colunasValores.find(
      (x) => this.obterNomeColuna(x) === coluna
    );

    if (!result || !result.valorNumerico) {
      return '';
    }

    return result.valorNumerico.toFixed(2);
  }
  

  print(): void {
    window.print();
  }

  public cancelar() {
    this.router.navigate(['/paa']);
  }
}
