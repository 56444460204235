<div class="card-body wrapper">
	<div>
		<table
			mat-table
			[dataSource]="dataSource"
			class="w-100"
			matSort
			matSortDisableClear
			matSortActive="id"
			matSortDirection="asc"
			[hidden]="semDados"
		>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

			<ng-container matColumnDef="unidadeGestora">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div>Unidade Gestora</div>
				</th>
				<td mat-cell *matCellDef="let element">
					{{ element?.siglaUg }} - {{ element.nomeUg }}
				</td>
			</ng-container>

			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<h5 [hidden]="!semDados" class="table-no-data m-2">
			Nenhum registro encontrado.
		</h5>
		<mat-paginator [pageSizeOptions]="[5]" >
		</mat-paginator>
	</div>
</div>
<div class="card-footer text-right">
	<button type="button" class="btn btn-default btn-sm" (click)="fechar()">
		<i class="fas fa-times"></i> Fechar
	</button>
</div>
