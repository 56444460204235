<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">Importar Unidade</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="row pt-3">
        <div class="form-group col-md-6 offset-md-3">
          <label for="inputId" class="mb-1">Sigla</label>
          <input type="text" id="inputId" class="form-control form-control-sm" [(ngModel)]="sigla" />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()"
        [disabled]="!sigla || sigla.length === 0">
        <i class="fas fa-check"></i> Importar
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
</div>
