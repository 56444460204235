import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Inputmask from 'inputmask';
import { IRelatoriaModel } from 'src/app/models/relatoria.model';
import { IRelatoriaCapituloModel } from 'src/app/models/relatoria-capitulo.model';
import { IHistoricoRelatoriaCapituloModel } from 'src/app/models/historico-relatoria-capitulo.model';

@Component({
  selector: 'app-modal-visualizar-historico',
  templateUrl: './modal-visualizar-historico.component.html',
  styleUrls: ['./modal-visualizar-historico.component.scss'],
})

export class ModalVisualizarHistoricoComponent
  implements OnInit, AfterViewInit {
  [x: string]: any;
  public historico: IHistoricoRelatoriaCapituloModel = {} as IHistoricoRelatoriaCapituloModel;
  public modelRelatoria: IRelatoriaModel = {} as IRelatoriaModel;
  public model: IRelatoriaCapituloModel = {} as IRelatoriaCapituloModel;
  differences: any[] = [];
  public removido: string;
  public adicionado: string;
  public semAlteracao: string;
  public textoDestacado: string = '';
  public textoRemovido: string = '';
  public dataFormatada: string = '';

  constructor(
    private dialogRef: MatDialogRef<ModalVisualizarHistoricoComponent>,
    public matDialog: MatDialog,

    @Inject(MAT_DIALOG_DATA) public data) {
    this.historico = data.historico;
  }

  ngOnInit(): void {
    const minLength = Math.min(this.historico.conteudoAnterior.length, this.historico.conteudoAtual.length);
    let diffStartIndex = 0;
    while (diffStartIndex < minLength && this.historico.conteudoAnterior[diffStartIndex] === this.historico.conteudoAtual[diffStartIndex]) {
      diffStartIndex++;
    }

    let diffEndIndex = 0;
    while (
      diffEndIndex < minLength - diffStartIndex &&
      this.historico.conteudoAnterior[this.historico.conteudoAnterior.length - diffEndIndex - 1] === this.historico.conteudoAtual[this.historico.conteudoAtual.length - diffEndIndex - 1]
    ) {
      diffEndIndex++;
    }
    this.removido = this.historico.conteudoAnterior.substring(diffStartIndex, this.historico.conteudoAnterior.length - diffEndIndex);
    this.adicionado = this.historico.conteudoAtual.substring(diffStartIndex, this.historico.conteudoAtual.length - diffEndIndex);
    this.semAlteracao = this.historico.conteudoAtual.substring(0, diffStartIndex) + this.adicionado + this.historico.conteudoAtual.substring(this.historico.conteudoAtual.length - diffEndIndex);
    this.dataFormatada = this.getFormattedDate(this.historico.dataAlteracao);
    
    this.differences = [
      { value: this.removido, removido: true },
      { value: this.adicionado, adicionado: true },
      { value: this.semAlteracao, semAlteracao: true }
    ];
  }

  destacarAlteracoes() {
    let diffOutput = '';
    let startIndex = 0;

    // Encontra o índice onde a diferença ocorre
    for (let i = 0; i < this.historico.conteudoAnterior.length; i++) {
      if (this.historico.conteudoAnterior[i] !== this.historico.conteudoAtual[i]) {
        diffOutput += `<span>${this.historico.conteudoAnterior.substring(startIndex, i)}</span>`;
        diffOutput += `<span class="destaqueTexto" style="color: blue !important; font-weight: bold !important;">${this.historico.conteudoAtual.substring(i, i + 1)}</span>`;
        startIndex = i + 1;
      }
    }

    // Adiciona o restante do texto que não sofreu alterações
    diffOutput += `<span>${this.historico.conteudoAnterior.substring(startIndex)}</span>`;

    this.textoDestacado = diffOutput != '' ? diffOutput : this.historico.conteudoAtual;
  }

  identificarRemocoes() {
    let removido = this.diffString(this.historico.conteudoAnterior, this.historico.conteudoAtual);
    this.textoRemovido = this.realcarRemocoes(removido);
  }

  // Algoritmo de diferença de strings (Exemplo simples)
  diffString(textoOriginal: string, textoAtual: string): string {
    let diffOutput = '';
    for (let i = 0; i < textoOriginal.length; i++) {
      if (textoOriginal[i] !== textoAtual[i]) {
        diffOutput += textoOriginal[i];
      }
    }
    return diffOutput;
  }

  realcarRemocoes(textoRemovido: string): string {
    return this.historico.conteudoAnterior.replace(
      new RegExp(`(${textoRemovido})`, 'g'),
      '<span "style=text-decoration: line-through;">$1</span>'
    );
  }

  ngAfterViewInit(): void {
    Inputmask().mask(document.querySelectorAll('input'));
    this.destacarAlteracoes();
    this.identificarRemocoes();
  }

  public getFormattedDate(data) {
    var date = new Date(data);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var hour = date.getHours();
    var minutes = date.getMinutes();
    return day + '/' + month + '/' + year + ' às ' + hour + ':' + minutes;
  }

  public fechar() {
    this.dialogRef.close();
  }
}
