import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as cloneDeep from 'lodash/cloneDeep';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';

@Component({
  selector: 'app-modal-associar',
  templateUrl: './modal-associar.component.html',
  styleUrls: ['./modal-associar.component.scss']
})
export class ModalAssociarComponent implements OnInit {
  public dataSource = new MatTableDataSource<IUnidadeGestoraModel>([]);
  public todasUnidadesGestoras = [] as IUnidadeGestoraModel[];
  public unidadesGestorasDisponiveis = [] as IUnidadeGestoraModel[];
  public idSelecionado: number;
  public semDados = true;
  public displayedColumns = ['id', 'sigla', 'nome', 'actions'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<ModalAssociarComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.todasUnidadesGestoras = data;
    this.unidadesGestorasDisponiveis = data;
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = defaultDataAcessor;
    this.dataSource._updateChangeSubscription();
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public remover(ind: number) {
    this.dataSource.data.splice(ind, 1);
    this.dataSource._updateChangeSubscription();
    this.semDados = this.dataSource.data.length === 0;

    const idsSelecionadas = this.dataSource.data.map((a) => (a.id));
    this.unidadesGestorasDisponiveis = this.todasUnidadesGestoras.filter(u => idsSelecionadas.indexOf(u.id) < 0);
  }

  public adicionar() {
    const idsSelecionadas = this.dataSource.data.map((a) => (a.id));
    idsSelecionadas.push(this.idSelecionado);

    this.unidadesGestorasDisponiveis = this.todasUnidadesGestoras.filter(u => idsSelecionadas.indexOf(u.id) < 0);
    this.dataSource.data.push(cloneDeep(this.todasUnidadesGestoras.find(a => a.id === this.idSelecionado)));
    this.dataSource._updateChangeSubscription();
    delete (this.idSelecionado);
    this.semDados = false;
  }

  public concluir() {
    this.dialogRef.close(this.dataSource.data);
  }

  public cancelar() {
    this.dialogRef.close();
  }
}

