import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { toIntlNumberString } from 'src/app/core/helpers/number.helper';
import { IFaixaClassificacaoModel } from 'src/app/models/faixa-classificacao';
import { FaixaClassificacaoService } from 'src/app/services/faixa-classificacao.service';
import Swal from 'sweetalert2';
import { BaseFormComponent } from '../base-form/base-form.component';

@Component({
  selector: 'app-faixa-classificacao-component',
  templateUrl: './faixa-classificacao-component.component.html',
  styleUrls: ['./faixa-classificacao-component.component.scss']
})
export class FaixaClassificacaoComponentComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() public desabilitado!: boolean;
  @Input() public model: IFaixaClassificacaoModel = {} as IFaixaClassificacaoModel;
  @Output() public faixadeClassificacaoOutput: EventEmitter<IFaixaClassificacaoModel> = new EventEmitter<IFaixaClassificacaoModel>();


  public form = new FormGroup({
    pisoBaixo: new FormControl(this.model.pisoBaixo, Validators.required),
    tetoBaixo: new FormControl(null, Validators.required),
    pisoMedio: new FormControl(null, Validators.required),
    tetoMedio: new FormControl(null, Validators.required),
    pisoAlto: new FormControl(null, Validators.required),
    tetoAlto: new FormControl(null, Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private faixaService: FaixaClassificacaoService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    this.titulo = 'Editar Faixa de Classificação';
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    
    this.form.controls['pisoBaixo'].setValue(this.formatarNumero(this.model.pisoBaixo));
    this.form.controls['tetoBaixo'].setValue(this.formatarNumero(this.model.tetoBaixo));
    this.form.controls['pisoMedio'].setValue(this.formatarNumero(this.model.pisoMedio));
    this.form.controls['tetoMedio'].setValue(this.formatarNumero(this.model.tetoMedio));
    this.form.controls['pisoAlto'].setValue(this.formatarNumero(this.model.pisoAlto));
    this.form.controls['tetoAlto'].setValue(this.formatarNumero(this.model.tetoAlto));
  }

  enviarClassificacao() {
    this.faixadeClassificacaoOutput.emit(this.salvarModel());
  }

  async ngOnInit() {

    Inputmask().mask(document.querySelectorAll('input'));

    if (!this.model) {
      try {
        const res = await this.faixaService.obter();
        if (res.sucesso) {
          this.model = res.dados;

          this.form.controls['pisoBaixo'].setValue(this.formatarNumero(this.model.pisoBaixo));
          this.form.controls['tetoBaixo'].setValue(this.formatarNumero(this.model.tetoBaixo));
          this.form.controls['pisoMedio'].setValue(this.formatarNumero(this.model.pisoMedio));
          this.form.controls['tetoMedio'].setValue(this.formatarNumero(this.model.tetoMedio));
          this.form.controls['pisoAlto'].setValue(this.formatarNumero(this.model.pisoAlto));
          this.form.controls['tetoAlto'].setValue(this.formatarNumero(this.model.tetoAlto));
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'warning',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          return;
        }

      } catch (err) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        this.router.navigate(['/']);
      }
    }
  }

  private formatarNumero(n: number) {
    return n?.toFixed(4)?.toString()?.replace('.', ',');
  }

  public salvarModel() {
    this.model.pisoBaixo = parseFloat(toIntlNumberString(this.form.controls['pisoBaixo'].value));
    this.model.tetoBaixo = parseFloat(toIntlNumberString(this.form.controls['tetoBaixo'].value));
    this.model.pisoMedio = parseFloat(toIntlNumberString(this.form.controls['pisoMedio'].value));
    this.model.tetoMedio = parseFloat(toIntlNumberString(this.form.controls['tetoMedio'].value));
    this.model.pisoAlto = parseFloat(toIntlNumberString(this.form.controls['pisoAlto'].value));
    this.model.tetoAlto = parseFloat(toIntlNumberString(this.form.controls['tetoAlto'].value));
    return this.model;
  }

  public calcular(field) {
    if (!this.form.controls['pisoBaixo'].value) {
      this.form.controls['pisoBaixo'].setValue('0,0000');
    }
    if (!this.form.controls['tetoBaixo'].value) {
      this.form.controls['tetoBaixo'].setValue('0,0000');
    }
    if (!this.form.controls['pisoMedio'].value) {
      this.form.controls['pisoMedio'].setValue('0,0000');
    }
    if (!this.form.controls['tetoMedio'].value) {
      this.form.controls['tetoMedio'].setValue('0,0000');
    }
    if (!this.form.controls['pisoAlto'].value) {
      this.form.controls['pisoAlto'].setValue('0,0000');
    }
    if (!this.form.controls['tetoAlto'].value) {
      this.form.controls['tetoAlto'].setValue('0,0000');
    }

    this.model.pisoBaixo = parseFloat(toIntlNumberString(this.form.controls['pisoBaixo'].value) || '0,0000');
    this.model.tetoBaixo = parseFloat(toIntlNumberString(this.form.controls['tetoBaixo'].value) || '0,0000');
    this.model.pisoMedio = parseFloat(toIntlNumberString(this.form.controls['pisoMedio'].value) || '0,0000');
    this.model.tetoMedio = parseFloat(toIntlNumberString(this.form.controls['tetoMedio'].value) || '0,0000');
    this.model.pisoAlto = parseFloat(toIntlNumberString(this.form.controls['pisoAlto'].value) || '0,0000');
    this.model.tetoAlto = parseFloat(toIntlNumberString(this.form.controls['tetoAlto'].value) || '0,0000');

    this.validarFormulario();

    const nomeCampoAlterado = field.id;
    if (nomeCampoAlterado === 'tetoBaixo') {
      this.model.pisoMedio = this.model.tetoBaixo + 0.0001;
      this.form.controls['pisoMedio'].setValue(this.formatarNumero(this.model.pisoMedio));
    }
    else if (nomeCampoAlterado === 'pisoMedio') {
      this.model.tetoBaixo = this.model.pisoMedio - 0.0001;
      this.form.controls['tetoBaixo'].setValue(this.formatarNumero(this.model.tetoBaixo));
    }
    else if (nomeCampoAlterado === 'tetoMedio') {
      this.model.pisoAlto = this.model.tetoMedio + 0.0001;
      this.form.controls['pisoAlto'].setValue(this.formatarNumero(this.model.pisoAlto));
    }
    else if (nomeCampoAlterado === 'pisoAlto') {
      this.model.tetoMedio = this.model.pisoAlto - 0.0001;
      this.form.controls['tetoMedio'].setValue(this.formatarNumero(this.model.tetoMedio));
    }

    if (this.model.pisoBaixo < 0)
      this.model.pisoBaixo = 0;

    if (this.model.tetoAlto > 10)
      this.model.tetoAlto = 10;
  }

  public validarFormulario() {

    if (this.model.pisoMedio >= this.model.tetoMedio) {
      this.form.controls['pisoMedio'].setErrors({ invalid: true });
    } else {
      this.form.controls['pisoMedio'].setErrors(null);
    }

    if (this.model.tetoBaixo >= this.model.pisoMedio) {
      this.form.controls['tetoBaixo'].setErrors({ invalid: true });
    } else {
      this.form.controls['tetoBaixo'].setErrors(null);
    }

    if (this.model.pisoBaixo >= this.model.tetoBaixo) {
      this.form.controls['pisoBaixo'].setErrors({ invalid: true });
    } else {
      this.form.controls['pisoBaixo'].setErrors(null);
    }

    if (this.model.tetoMedio >= this.model.pisoAlto) {
      this.form.controls['tetoMedio'].setErrors({ invalid: true });
    } else {
      this.form.controls['tetoMedio'].setErrors(null);
    }

    if (this.model.pisoAlto >= this.model.tetoAlto) {
      this.form.controls['pisoAlto'].setErrors({ invalid: true });
    } else {
      this.form.controls['pisoAlto'].setErrors(null);
    }

    if (this.model.tetoAlto > 10) {
      this.form.controls['tetoAlto'].setErrors({ invalid: true });
    } else {
      this.form.controls['tetoAlto'].setErrors(null);
    }
  }

  public onBack() {
    this.router.navigate(['/']);
  }

}
