<div class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-12">
              <ol class="breadcrumb float-sm-left">
                  <li class="breadcrumb-item">
                      <a [routerLink]="['/']">
                          <i class="fas fa-home"></i>
                          Página Inicial</a>
                  </li>
                  <li class="breadcrumb-item active">Auditoria</li>
              </ol>
          </div>
      </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
      <div class="row">
          <div class="col-md-12">
              <div class="card card-outline card-lightblue">
                  <div class="card-header">
                      <h3 class="card-title mb-0">
                          <i class="fas fa-table pr-2"></i>
                          Auditoria
                      </h3>
                  </div>
                  <div class="card-body p-2">
                    <div class="mat-elevation-z4">
                      <mat-toolbar class="table-header">
                        <mat-toolbar-row>
                          <span class="fill-remaining-space"></span>

                          <div class="input-group input-group-sm mr-1" style="width:
                          250px;">
                          <input type="text" name="table_search" class="form-control
                            float-right" (keyup.enter)="obter()"
                            placeholder="Pesquisar"
                            [(ngModel)]="searchPesquisa" />

                          <div class="input-group-append">
                            <button type="button" class="btn btn-info"
                              (click)="obter()">
                              <i class="fas fa-search"></i>
                            </button>
                          </div>
                        </div>
                        <div class="btn-group">
                          <button type="button" data-toggle="dropdown" class="btn btn-tool btn-default dropdown-toggle">
                            <i class="fas fa-filter"></i>
                          </button>
                          <div role="menu" class="dropdown-menu dropdown-menu-right dropdown-menu-checkbox">
                            <a (click)="searchAtivo = undefined; obter()" class="dropdown-item"><i [ngClass]="
                                  searchAtivo === undefined
                                    ? 'fas fa-check-circle'
                                    : 'far fa-circle'
                                "></i>Exibir todos</a>
                            <a (click)="searchAtivo = true; obter()" class="dropdown-item"><i [ngClass]="
                                  searchAtivo === true
                                    ? 'fas fa-check-circle'
                                    : 'far fa-circle'
                                "></i>Exibir somente ativos</a>
                            <a (click)="searchAtivo = false; obter()" class="dropdown-item"><i [ngClass]="
                                  searchAtivo === false
                                    ? 'fas fa-check-circle'
                                    : 'far fa-circle'
                                "></i>Exibir somente inativos</a>
                          </div>
                        </div>
                        </mat-toolbar-row>
                      </mat-toolbar>

                      <table mat-table [dataSource]="dataSource" #tabela1="matSort"  *ngIf="!semDados" matSort matSortDisableClear matSortDirection="desc" [hidden]="semDados"
                        class="w-100 ">

                        <ng-container matColumnDef="numero">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº OSA </th>
                          <td mat-cell *matCellDef="let element"> {{element?.numero}} </td>
                        </ng-container>

                        <ng-container matColumnDef="ano">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ano </th>
                          <td mat-cell *matCellDef="let element"> {{element?.ano}} </td>
                        </ng-container>

                        <ng-container matColumnDef="dataInicio">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Inicio </th>
                          <td mat-cell *matCellDef="let element"> {{element?.dataInicio | date:'dd/MM/yyyy'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="dataFim">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Fim </th>
                          <td mat-cell *matCellDef="let element"> {{element?.dataFim | date:'dd/MM/yyyy'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                          <td mat-cell *matCellDef="let element">
                            <span *ngIf="element?.status?.codigo === 1" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
                            <span *ngIf="element?.status?.codigo === 2" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
                            <span *ngIf="element?.status?.codigo === 3" class="badge bg-primary text-xs">{{element?.status?.descricao}}</span>
                            <span *ngIf="element?.status?.codigo === 4" class="badge bg-secondary text-xs">{{element?.status?.descricao}}</span>
                            <span *ngIf="element?.status?.codigo === 5" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
                            <span *ngIf="element?.status?.codigo === 6" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
                            <span *ngIf="element?.status?.codigo === 7" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
                            <span *ngIf="element?.status?.codigo === 8" class="badge bg-danger text-xs">{{element?.status?.descricao}}</span>
                            <span *ngIf="element?.status?.codigo === 9" class="badge bg-info text-xs">{{element?.status?.descricao}}</span>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="ativo">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Situação </th>
                          <td mat-cell *matCellDef="let element">
                            <span *ngIf="element?.ativo" class="badge bg-success text-xs">Ativo</span>
                            <span *ngIf="!element?.ativo" class="badge bg-gray-dark text-xs">Inativo</span>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="selecionar(row)" class="cursor-pointer-table"
                          [ngClass]="{'bg-light': selectedModel?.id === row.id && selectedModel?.grupo === row.grupo}"></tr>
                      </table>

                      <h5 class="table-no-data" *ngIf="semDados">Nenhum registro encontrado</h5>

                      <mat-paginator  #TableOnePaginator="matPaginator" class="mt-4" [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons  [length]="totalItens" (page)="pageEvent = pageChanged($event)">
                      </mat-paginator>
                    </div>
                  </div>


              </div>
          </div>
      </div>
  </div>
</div>
