<div id="modal-content-wrapper">
    <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">

        <div class="card-header bg-light" cdkDragHandle>
            <h4 class="card-title mb-0">{{titulo}}</h4>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="Cancelar()"><i class="fas fa-times"></i>
          </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-container p-3">
                <div class="row ">
                    <div class="form-group col-md-12">
                        <label
                        for="selectClassificacao"
                        class="mb-1"
                        >Nome</label>
                    <input
                    type="text"
                    id="nomeTxt"
                    class="form-control float"
                    [ngModel]="model.nome"
                    maxlength="99"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="onChangeNome($event)"
                    />
                    </div>
                </div>
            </div>

        </div>
        <div class="card-footer">
            <button type="button" class="btn btn-primary btn-sm mr-1" (click)="Confirmar()">
          <i class="fas fa-check"></i> Confirmar
        </button>

            <button type="button" class="btn btn-default btn-sm" (click)="Cancelar()">
          <i class="fas fa-times"></i> Cancelar
        </button>
        </div>
    </div>
</div>