<div id="modal-content-wrapper">
    <div
      class="card card-secondary card-outline"
      cdkDragBoundary="html"
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
    >
      <div class="card-header bg-light" cdkDragHandle>
        <h4 class="card-title mb-0">Delegar Questionário</h4>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" (click)="cancelar()">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="table-container p-3">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="selectResponsavel" class="mb-1"
                >Escolha um Responsável</label
              >
              <select id="selectResponsavel" [(ngModel)]="idResponsavel" [ngModelOptions]="{standalone: true}"
                class="form-control form-control-sm">
                <option [ngValue]="">
                  Selecione o Responsável
                </option>
                <option *ngFor="let item of model" [ngValue]="item.idResponsavel">
                  {{item.sigla}} - {{item.nome}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="button"
        [disabled]="!idResponsavel"
        class="btn btn-primary btn-sm mr-1"
        (click)="delegar()"
      >
        <i class="fas fa-check"></i> Delegar
      </button>
  
      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>
  </div>
  