<div id="modal-content-wrapper">
	<div
		class="card card-secondary card-outline"
		cdkDragBoundary="html"
		cdkDrag
		cdkDragRootElement=".cdk-overlay-pane"
	>
		<div class="card-header bg-light" cdkDragHandle>
			<h4 class="card-title mb-0">
				Cálculo da Capacidade Total PAAC (Planejamento e Especial)
			</h4>
			<div class="card-tools">
				<button
					type="button"
					class="btn btn-tool small-btn"
					(click)="onClose()"
				>
					<i class="fas fa-times small-icon"></i>
				</button>
			</div>
		</div>
		<div class="card-body p-0">
			<div class="table-container p-3">
				<table class="table">
					<tbody>
						<tr class="row-white">
							<td>Dias disponíveis (A)</td>
							<td>{{ diasDisponiveis }}</td>
						</tr>
						<tr class="row-white">
							<td>Quantidade de dias por auditoria (B)</td>
							<td>{{ qntDiasPorAuditor || 0 }}</td>
						</tr>
						<tr class="row-white">
							<td>Número de auditorias por equipe (C) = (A/B)</td>
							<td>{{ numAuditoriaPorEquipe || 0 }}</td>
						</tr>
						<tr class="row-white">
							<td>
								Número de auditorias por equipe (D) -
								arredondado
							</td>
							<td>{{ numAuditoriaPorEquipeArred || 0 }}</td>
						</tr>
						<tr class="row-white">
							<td>Número de equipe disponível (E)</td>
							<td>{{ numEquipeDisponiveis || 0 }}</td>
						</tr>
						<tr class="row-white bold">
							<td>
								<strong
									>Capacidade total por coordenação = (D x
									E)</strong
								>
							</td>
							<td>
								<strong>{{
									capacidadeTotalPorCoord || 0
								}}</strong>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="card-footer">
			<button
				type="button"
				class="btn btn-default btn-sm"
				(click)="onClose()"
			>
				<i class="fas fa-times"></i> Fechar
			</button>
		</div>
	</div>
</div>
