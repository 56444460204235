<div class="mat-elevation-z4">
	<mat-toolbar class="table-header">
		<mat-toolbar-row>
			<button type="button" class="btn btn-primary btn-sm mr-1"
				[disabled]="!selectedModel || (selectedModel?.status?.codigo !== 1 && selectedModel?.status?.codigo !== 4)" 
				*ngIf="permissoes.elaborar"
				(click)="elaborar(selectedModel.id)">
				<i class="far fas fa-pen"></i> Editar
			</button>
			<button type="button" class="btn btn-warning btn-sm mr-1"
				*ngIf="selectedModel != undefined && selectedModel?.ativo && selectedModel?.status?.codigo == 1 && permissoes.inativarReativar"
				(click)="reativar(selectedModel.id)">
				<i class="far fa-play-circle"></i>
				Reativar
			</button>
			<button type="button" class="btn btn-warning btn-sm mr-1"
				*ngIf="selectedModel?.ativo && selectedModel?.status?.codigo == 1 && permissoes.inativarReativar"
				[disabled]="!selectedModel?.ativo || selectedModel?.status?.codigo !== 1"
				(click)="inativar(selectedModel?.id)">
				<i class="far fa-pause-circle"></i>
				Inativar
			</button>

			<button type="button" class="btn btn-info btn-sm mr-1" [disabled]="!selectedModel"
				(click)="visualizar(selectedModel?.id)"
				*ngIf="permissoes.visualizar">
				<i class="far fas fa-eye"></i>
				Visualizar
			</button>

			<button type="button" class="btn btn-info btn-sm mr-1" [disabled]="!selectedModel"
				(click)="imprimir(selectedModel.id)"
				*ngIf="permissoes.imprimir">
				<i class="far fas fa-print"></i> Imprimir
			</button>

			<button type="button" class="btn btn-primary btn-sm mr-1" 
				*ngIf="selectedModel?.status?.codigo === 4 && permissoes.gerarNovaVersao"
				(click)="novaVersao(selectedModel.id)">
				<i class="far fa-save"></i> Gerar Nova Versão
			</button>
			<!--Botões de ações -->
			<span class="fill-remaining-space"></span>


			<div class="input-group input-group-sm" style="width: 100%; display: flex; justify-content: flex-end;">
				<input type="text" name="table_search" class="form-control float-right" style="max-width:30%;" (keyup.enter)="filtrarPorNumero()"
					placeholder="Pesquisar por Número Osa" [(ngModel)]="numeroOsa" />

				<div class="input-group-append">
					<button type="button" class="btn btn-info" (click)="filtrarPorNumero()">
						<i class="fas fa-search"></i>
					</button>
				</div>
				<div class="input-group-append">
					<button class="btn btn-default" type="button" data-toggle="collapse" data-target="#collapseExample"
						aria-expanded="false" aria-controls="collapseExample">
						<i class="fas fa-filter"></i> Filtro Avançado
					</button>
				</div>
			</div>
		</mat-toolbar-row>
	</mat-toolbar>
	<div>
		<ng-content></ng-content>
	</div>

	<table mat-table [dataSource]="dataSource" #tabela1="matSort" matSort matSortDisableClear
		(matSortChange)="sortData($event)" matSortActive="numeroOpa" matSortDirection="desc" *ngIf="semDados == true"
		class="w-100 ">
		<!-- select Column -->
		<ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let element">
				<mat-checkbox (click)="$event.stopPropagation()" color="primary"
					(change)="selectedModel =( $event.checked ? element : null)"
					[checked]="selectedModel?.id === element.id">
				</mat-checkbox>
			</td>
		</ng-container>
		<!-- numero Osa -->
		<ng-container matColumnDef="numeroOsa">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				Número Osa
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element?.numero }}
			</td>
		</ng-container>
		<!-- numero Opa -->
		<ng-container matColumnDef="numeroOpa">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				Número Opa
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element?.planejamento?.opa?.numero }}
			</td>
		</ng-container>
		<!-- ano -->
		<ng-container matColumnDef="ano">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Ano</th>
			<td mat-cell *matCellDef="let element">
				{{ element?.ano }}
			</td>
		</ng-container>
		<!-- ug -->
		<ng-container matColumnDef="ug">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>UG</th>
			<td mat-cell *matCellDef="let element">
				{{ element?.unidadeGestora?.sigla || element?.planejamento?.opa?.unidadeGestora?.sigla }}
			</td>
		</ng-container>
		<!-- origem Auditoria -->
		<ng-container matColumnDef="origemAuditoria">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				Origem Auditoria
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element?.planejamento?.opa?.origem?.descricao }}
			</td>
		</ng-container>
		<!-- coordenacao -->
		<ng-container matColumnDef="coordenacao">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				Coordenação
			</th>
			<td mat-cell *matCellDef="let element">
				{{
				element?.planejamento?.opa?.auditorCoordenacao?.coordenacao
				?.sigla
				}}
			</td>
		</ng-container>
		<!-- dataInicio -->
		<ng-container matColumnDef="dataInicio">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				Data Início
			</th>
			<td mat-cell *matCellDef="let element">
				<p *ngIf="!element.dataInicioRealizacao">{{ element?.planejamento?.opa?.dataInicioPrevista | date:
					"dd/MM/yyyy"}}</p>
				<p *ngIf="element.dataInicioRealizacao">{{ element?.dataInicioRealizacao | date: "dd/MM/yyyy"}}</p>
			</td>
		</ng-container>
		<!-- dataFim -->
		<ng-container matColumnDef="dataFim">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				Data Fim
			</th>
			<td mat-cell *matCellDef="let element">

				<p *ngIf="!element.dataFimRealizacao">{{ element?.planejamento?.opa?.dataFimPrevista | date:
					"dd/MM/yyyy"}}</p>
				<p *ngIf="element.dataFimRealizacao">{{ element?.dataFimRealizacao | date: "dd/MM/yyyy"}}</p>
			</td>
		</ng-container>
		<!-- status -->
		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				Status
			</th>
			<td mat-cell *matCellDef="let element">
				<span *ngIf="element.status?.nome === 'Homologado'" class="badge bg-primary text-xs">Homologado</span>
				<span *ngIf="element.status?.nome === 'EmAprovacao'" class="badge bg-warning text-xs">Em
					Aprovação</span>
				<span *ngIf="element.status?.nome === 'EmElaboracao'" class="badge bg-success text-xs">Em
					Elaboração</span>
				<span *ngIf="element.status?.nome === 'Elaborar'" class="badge bg-success text-xs">
					Elaborar</span>
				<span *ngIf="element.status?.nome === 'EmExecucao'" class="badge bg-primary text-xs">Em Execução</span>
			</td>
		</ng-container>
		<!-- situacao -->
		<ng-container matColumnDef="situacao">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Situação</th>
			<td mat-cell *matCellDef="let element">
				<span *ngIf="element.ativo" class="badge bg-success text-xs">Ativo</span>
				<span *ngIf="element.ativo === false" class="badge bg-gray-dark text-xs">Inativo</span>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="selecionar(row)" class="clickable"
			[ngClass]="{'bg-light': selectedModel?.id === row.id && selectedModel?.grupo === row.grupo}"></tr>
	</table>

	<h5 *ngIf="semDados == false" class="table-no-data">Nenhum registro encontrado</h5>

	<mat-paginator class="mt-4" [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons [length]="totalItens"
		(page)="selectedModel = null; pageEvent = pageChanged($event)">
	</mat-paginator>
</div>