import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IPerguntaModel } from '../models/pergunta.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class PerguntaService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<IPerguntaModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }


    return this.httpClient
      .get<IBaseModel<IPerguntaModel[]>>(`${this.apiBaseUrl}/pergunta`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IPerguntaModel>> {
    return this.httpClient
      .get<IBaseModel<IPerguntaModel>>(`${this.apiBaseUrl}/pergunta/${id}`)
      .toPromise();
  }

  public async inserir(data: IPerguntaModel): Promise<IBaseModel<IPerguntaModel>> {
    return this.httpClient
      .post<IBaseModel<IPerguntaModel>>(`${this.apiBaseUrl}/pergunta`, data)
      .toPromise();
  }

  public async atualizar(data: IPerguntaModel): Promise<IBaseModel<IPerguntaModel>> {
    return this.httpClient
      .put<IBaseModel<IPerguntaModel>>(`${this.apiBaseUrl}/pergunta/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/pergunta/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/pergunta/${id}/inativar`, {})
      .toPromise();
      
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/pergunta/${id}/excluir`, {})
      .toPromise();
      
  }
}
