import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EntendendoEntidadeService } from 'src/app/services/entendendo-entidade.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-modal-solicitar-preenchimento',
  templateUrl: './modal-solicitar-preenchimento.component.html',
  styleUrls: ['./modal-solicitar-preenchimento.component.scss']
})
export class ModalSolicitarPreenchimentoComponent implements OnInit {
  prazoDias: number;
  unidadeGestoraId: number;

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private questionarioUGService: EntendendoEntidadeService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<ModalSolicitarPreenchimentoComponent>

  ) { }

  ngOnInit(): void {
    this.unidadeGestoraId = this.sharedService.getId();
  }

  public cancelar(){
    this.dialogRef.close();
  }

  public concluir(){

    this.questionarioUGService
    .solicitarPreenchimentoQuestionario(this.data.param1, this.prazoDias)
    .then((res) => {
      if (res.sucesso) {
        // aqui chamar o pagina principal
        this.dialogRef.close();
      } else {
        // this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      // this.exibirMensagemErro(err.mensagem);
    })
    .finally(() => {
      //  this.buscar();
    });
  }

  public onChangePreencherPrazo(event){
    this.prazoDias = event.target.value;
  }

}
