import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { ICalendarioModel } from 'src/app/models/calendario.model';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarioService } from 'src/app/services/calendario.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';

const sortingDataAccessor = (item, property) => {
  switch (property) {
    case 'tipoFeriado':
      return item.tipoFeriado.descricao;
    default:
      return item[property];
  }
};

@Component({
  selector: 'app-calendario-list',
  templateUrl: './calendario-list.component.html',
  styleUrls: ['./calendario-list.component.scss']
})
export class CalendarioListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<ICalendarioModel>([]);
  public selectedModel: ICalendarioModel;
  public displayedColumns: string[] = ['selecao', 'nome', 'data', 'tipoFeriado', 'fixo', 'ativo'];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;
  public permissoes: IPermissaoModel;
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private calendarioservice: CalendarioService,
    private router: Router,
    private authService: AuthService
  ) {
    super();
    this.buscar(); 
  }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Visualizar);
 }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
  }

  public novo() {
    this.router.navigate(['/calendario/novo']);
  }

  public buscar() {
    this.selectedModel = null;

    this.calendarioservice
      .obter(this.searchNome, this.searchAtivo, false)
      .then((res) => { 
        this.dataSource = new MatTableDataSource<ICalendarioModel>(res.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.dataSource.sortingDataAccessor = sortingDataAccessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public reativar(id: number) {
    this.calendarioservice
      .reativar(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Feriado reativado com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public inativar(id: number) {
    this.calendarioservice
      .inativar(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Feriado inativado com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public editar(id: number) {
    this.router.navigate([`/calendario/${id}`]);
  }

  public visualizar(id: number) {
    this.router.navigate([`/calendario/${id}/visualizar`]);
  }

  public excluir(id: number){
    this.calendarioservice
      .excluir(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Feriado excluído com sucesso!',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      }) 
          .catch ((err) => {
            this.exibirMensagemErro(err.mensagem.descricao);
        })
        .finally (() => {
          this.buscar();
        });
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }
}
