import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { CustoRecursoFormComponent } from 'src/app/pages/custo-recurso-form/custo-recurso-form.component';

@Injectable()
export class CustoRecursoGuard
  implements CanDeactivate<CustoRecursoFormComponent> {
  canDeactivate(
    component: CustoRecursoFormComponent
  ): Observable<boolean> | boolean {
    if (!component.visualizar) {
      if(component?.salvar) {return true;}
        if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    }
    else { return true; }
  }
}
