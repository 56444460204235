import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';

@Component({
  selector: 'app-modal-confirmacao-rejeitar',
  templateUrl: './modal-confirmacao-rejeitar.component.html',
  styleUrls: ['./modal-confirmacao-rejeitar.component.scss']
})
export class ModalConfirmarRejeitarComponent extends BaseFormComponent implements OnInit {
  public form = new FormGroup({
    justificativa: new FormControl('', Validators.required),
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(route: ActivatedRoute,
              toastr: ToastrService,
              router: Router,
              localeService: BsLocaleService,
              public matDialog: MatDialog,
              private dialogRef: MatDialogRef<ModalConfirmarRejeitarComponent>) {
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {
  }

  public concluir() {
    this.dialogRef.close(this.form.get('justificativa').value);
  }

  public cancelar() {
    this.dialogRef.close();
  }
}

