import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EnumTipoQuestionarioUG } from 'src/app/models/enum/tipo-questionario-ug.enum';
import { QuestionarioUGService } from 'src/app/services/questionarioug.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-modal-questionario-importa-dados-shared',
  templateUrl: './modal-questionario-importa-dados.component.html',
  styleUrls: ['./modal-questionario-importa-dados.component.scss']
})
export class ModalQuestionarioImportaDadosSharedComponent  implements OnInit {
  unidadeGestoraId: number;

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any ,
    private questionarioUGService: QuestionarioUGService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<ModalQuestionarioImportaDadosSharedComponent>
  ) { }

  ngOnInit(): void {
    this.unidadeGestoraId = this.sharedService.getId();
  }

  public Sim() {
    if(!this.data.tipoQuestionario){
      this.dialogRef.close();
    }

    let aba = 0;
    if(this.data.tipoQuestionario == 1){
      aba = 1;
    }else if (this.data.tipoQuestionario == 2){
      aba = 2;
    }

    this.questionarioUGService
    .inserirQuestionarioAPartirDoUltimo(this.unidadeGestoraId, this.data.tipoQuestionario)
    .then((res) => {
      if (res.sucesso) {
        // aqui chamar o pagina principal
        this.dialogRef.close();
        this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=${aba}`);
        window.location.reload();
      } else {
        // this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      // this.exibirMensagemErro(err.mensagem);
    })
    .finally(() => {
      //  this.buscar();
    });
  }

  public Nao() {
    this.dialogRef.close();
    this.router.navigate(['gerar-questionario-shared']);
  }

  public cancelar() {
    this.dialogRef.close();
  }

}
