import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { IPeaCriterioQuestionarioModel } from 'src/app/models/pea-criterio-questionario.model';
import { IBaseModel } from 'src/app/models/base.model';
import { IQuestionarioModel } from 'src/app/models/questionario.model';
import { QuestionarioService } from 'src/app/services/questionario.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';


const sortingDataAccessor = (item, property) => {
  
  switch (property) {
    case 'pea':
      return item.pea.nome.trim().toLowerCase();
    case 'paa':
      return item?.paaIndiceCriterio?.paa?.nome.trim().toLowerCase();
    case 'ug':
      return item.unidadeGestora.sigla.trim().toLowerCase();
    case 'responsavel':
      return item.responsavelId.trim().toLowerCase();
    case 'dataEnvio':
      return item.dataCriacao.trim().toLowerCase();
    case 'dataPrazo':
      return item.dataLimite.trim().toLowerCase();
    default:
      return item[property].trim().toLowerCase();
  }
}
@Component({
  selector: 'app-questionario-list',
  templateUrl: './questionario-list.component.html',
  styleUrls: ['./questionario-list.component.scss'],
})
export class QuestionarioListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<IQuestionarioModel>([]);
  public selectedModel: IPeaCriterioQuestionarioModel;
  public searchNome: string;

  public displayedColumns: string[] =  ['selecao', 'pea', 'ug', 'responsavel', 'dataEnvio', 'dataPrazo', 'status'];

  public semDados = true;
  private segmentoUrl: string;
  public documento: string;
  public isPEA: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private questionarioService: QuestionarioService,
    private router: Router,
    private toastr: ToastrService,
  ) {
    super();
    this.segmentoUrl = this.recuperarSegmento();
    this.documento = this.segmentoUrl.toUpperCase();
    this.buscar();
  }

  public buscar() {
    this.selectedModel = null;

    switch (this.segmentoUrl) {
      case 'pea':
        this.isPEA = true;
        this.buscarQuestionariosPea();
        break;
      case 'paa':
        this.buscarQuestionariosPaa();
        break;
      default:
        this.buscarQuestionariosPea();
        break;
    }
  }

  public responder() {
    
  if(this.isPEA){
    this.router.navigate([`/questionario/${this.segmentoUrl}/${this.selectedModel.peaId}/criterio/${this.selectedModel.criterioId}/ug/${this.selectedModel.unidadeGestoraId}`]);
  }else{
    this.router.navigate([`/questionario/${this.segmentoUrl}/${this.selectedModel.paaId}/criterio/${this.selectedModel.criterioId}/ug/${this.selectedModel.unidadeGestoraId}`]);
  }
    
  }

  public visualizar(id: number, criterioId: number, unidadeGestoraId: number) {
    this.router.navigate([`/questionario/${this.segmentoUrl}/${id}/criterio/${criterioId}/ug/${unidadeGestoraId}/visualizar`]);
  }

  public visualizarQuestionario(object: any) {
    if (this.segmentoUrl == "pea") {
      this.router.navigate([`/questionario/${this.segmentoUrl}/${object.peaId}/criterio/${object.criterioId}/ug/${object.unidadeGestoraId}/visualizar`]);
    } else {
      this.router.navigate([`/questionario/${this.segmentoUrl}/${object.paaIndiceCriterio.paa.id}/criterio/${object.criterioId}/ug/${object.unidadeGestoraId}/visualizar`]);
    }
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.documentoId !== item.documentoId ? item : null;
  }

  private buscarQuestionariosPea() {
    this.tratarBuscar(this.questionarioService
      .obterQuestionariosPendentePea(this.searchNome));
  }

  private buscarQuestionariosPaa() {
    this.tratarBuscar(this.questionarioService
      .obterQuestionariosPendentePaa(this.searchNome));
  }

  private tratarBuscar(buscar: Promise<IBaseModel<IQuestionarioModel[]>>) {
    buscar.then((res) => {
      this.dataSource = new MatTableDataSource<IQuestionarioModel>(res.dados);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = sortingDataAccessor;
      this.dataSource._updateChangeSubscription();
      this.semDados = this.dataSource.filteredData.length === 0;
    })
      .catch((err) => {
        this.toastr.error(err.mensagem.descricao, 'Atenção');
      })
  }

  private recuperarSegmento() {
    let segmento = '';
    if (this.router.url == '/questionario/pea') {
      this.isPEA = true;
      segmento = 'pea';
    }
    else if (this.router.url == '/questionario/paa') {
      segmento = 'paa';
      this.displayedColumns =  ['selecao', 'pea', 'paa','ug', 'responsavel', 'dataEnvio', 'dataPrazo', 'status'];
    }
    this.route.url.subscribe(val => segmento = val[val.length - 1].path);

    return segmento;
  }

  mostrarColunaPAAC(element: any): boolean{
		return  !this.isPEA;
  }

  retornaNomePea(element: any): string{
    let retorno ="" ;
      if(element.pea != undefined){
        retorno = element.pea.nome;
      }else if(element.paaIndiceCriterio.paa.pea){
        retorno = element.paaIndiceCriterio.paa.pea.nome;
      }
      return retorno;              
  }

  sortData(sort) {
     this.buscar();
}

}

