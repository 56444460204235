import { ToastrService } from 'ngx-toastr';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { CoordenacaoService } from 'src/app/services/coordenacao.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { IBaseModel } from 'src/app/models/base.model';
import { MatTableDataSource } from '@angular/material/table';
import { IModeloQuestionarioItemModel } from 'src/app/models/modelo-questionario.model';
import { IPerguntaModel } from 'src/app/models/pergunta.model';
import { PerguntaService } from 'src/app/services/pergunta.service';

@Component({
  selector: 'app-modal-modelo-questionario-pergunta',
  templateUrl: './modal-pergunta.component.html',
  styleUrls: ['./modal-pergunta.component.scss'],
})

export class ModalModeloQuestionarioPerguntaComponent extends BaseFormComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  public model: IModeloQuestionarioItemModel;
  public dataSource = new MatTableDataSource<IPerguntaModel>([]);
  public semDados = true;
  public displayedColumns: string[] = ['selecao', 'nome', 'tipo', 'obrigatorio'];
  public selecionado: IPerguntaModel;
  public totalItens: number;

  constructor(
    route: ActivatedRoute,
    private dialogRef: MatDialogRef<ModalModeloQuestionarioPerguntaComponent>,
    public toastr: ToastrService,
    localeService: BsLocaleService,
    router: Router,
    private perguntaService: PerguntaService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super(route, toastr, router, localeService, matDialog);
    

  }

  ngOnInit(): void {
    this.buscarPerguntas();
  }
 
  
  ngAfterViewInit(): void {
    
  }


  public selecionar(item: IPerguntaModel) {
    this.selecionado = !this.selecionado || this.selecionado.id !== item.id ? item : null;
  }

  public async concluir() {
    this.dialogRef.close(this.selecionado);
  }

  public buscarPerguntas(){
    
    this.perguntaService
            .obter("", true)
            .then((res) => {
                
                if(res.sucesso){
                    this.dataSource = new MatTableDataSource<IPerguntaModel>(res.dados);
                    this.dataSource._updateChangeSubscription();
                    this.semDados = this.dataSource.data.length == 0;
                    this.totalItens = res.dados.length;
                    this.dataSource.paginator = this.paginator;
                }
            });
    
  }

  public filtrar(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.semDados = this.dataSource.filteredData.length === 0;
  }

  public cancelar() {
    this.dialogRef.close(null);
  }

}

