import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { IPlanejamentoAuditoresSalvarModel, IPlanejamentoSubtipoAuditoriaSalvarModel, IPlanejamentoSubtipoExcluirModel, IPlanejamentoUGSubtipoSalvarModel } from '../models/planejamento-subtipo-auditoria.model';
import { IBaseModel } from '../models/base.model';
import { IPlanejamentoModel } from '../models/planejamento.model';
import { IValidacaoNivelModel } from '../models/validaca-nivel-aprovacao.model';
import { BaseService } from './base.service';
import { IPlanejamentoEquipeModel } from '../models/planejamento-equipe.model';
import { IPlanejamentoImprimirModel } from '../models/planejamento-imprimir.model';
import { Subject } from 'rxjs';
import { PaginacaoModel } from '../models/paginacao.model';
import { filterBy } from './auditoria.service';
import { IEntranharDocumentoModel } from '../models/edocs/entranhar-documento.model';
import { ISubtipoAuditoriaModel } from '../models/subtipo-auditoria.model';

type subjectOfArray = any | any[] | Subject<any>;

@Injectable({
  providedIn: 'root',
})
export class PlanejamentoService extends BaseService {
  private _planejamentoSubTipoAuditoria: subjectOfArray = new Subject();

  public get planejamentoSubTipoAuditoria(): subjectOfArray {
    return this._planejamentoSubTipoAuditoria.asObservable() as Subject<any>;
  }

  public set planejamentoSubTipoAuditoria(value: subjectOfArray ) {
    this._planejamentoSubTipoAuditoria.next(value);
  }

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa?: string, ativo?: boolean, simplificado = false): Promise<IBaseModel<IPlanejamentoModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    return this.httpClient
      .get<IBaseModel<IPlanejamentoModel[]>>(`${this.apiBaseUrl}/planejamento`, { params })
      .toPromise();
  }

  public obterFiltrado(filtro: any): Promise<IBaseModel<PaginacaoModel<IPlanejamentoModel[]>>> {

    return this.httpClient
      .get<IBaseModel<PaginacaoModel<IPlanejamentoModel[]>>>(filterBy(filtro, `${this.apiBaseUrl}/planejamento`) )
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IPlanejamentoModel>> {
    return this.httpClient
      .get<IBaseModel<IPlanejamentoModel>>(`${this.apiBaseUrl}/planejamento/${id}`)
      .toPromise();
  }

  public async obterAbaMatrizPorIdImprimir(tipoTrabalho: number, id: number, imprimir: boolean=false): Promise<IBaseModel<ISubtipoAuditoriaModel[]>> {
    var url =  `${this.apiBaseUrl}/aba-matriz-planejamento/${tipoTrabalho}/tipoTrabalho/${id}/imprimir`;
    return this.httpClient
      .get<IBaseModel<ISubtipoAuditoriaModel[]>>(url)
      .toPromise();
  }

  public async obterAbaMatrizPorId(tipoTrabalho: number, id: number): Promise<IBaseModel<IPlanejamentoModel>> {
    return this.httpClient
      .get<IBaseModel<IPlanejamentoModel>>(`${this.apiBaseUrl}/aba-matriz-planejamento/${tipoTrabalho}/tipoTrabalho/${id}`)
      .toPromise().then(a => {
        this.planejamentoSubTipoAuditoria = a.dados as any;
        return a;
      });
  }

  public async imprimir(id: number): Promise<IBaseModel<IPlanejamentoImprimirModel>> {
    return this.httpClient
      .get<IBaseModel<IPlanejamentoImprimirModel>>(`${this.apiBaseUrl}/planejamento/${id}/imprimir-planejamento`)
      .toPromise();
  }

  public async atualizar(data: IPlanejamentoModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/planejamento/${data.id}`, data)
      .toPromise();
  }

  public async salvarSubtipoAuditoria(id: number, data:  IPlanejamentoSubtipoAuditoriaSalvarModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/planejamento/${id}/salvar-subTipos`, data)
      .toPromise();
  }

  public async excluirSubtipoAuditoria(id: number, subtipoId: number,  data:  IPlanejamentoSubtipoExcluirModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .patch<IBaseModel<void>>(`${this.apiBaseUrl}/planejamento/${id}/SubTipo/${subtipoId}/excluir`, data)
      .toPromise();
  }

  public async salvarUgSubtipo(id: number, data: IPlanejamentoUGSubtipoSalvarModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/planejamento/${id}/salvar-ugs-subTipo`, data)
      .toPromise();
  }

  public async salvarAlocarEquipe(id: number, data: IPlanejamentoAuditoresSalvarModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/planejamento/${id}/alocar-equipe`, data)
      .toPromise();
  }

  public async obterAlocarEquipe(id: number): Promise<IBaseModel<IPlanejamentoEquipeModel>> {
    return this.httpClient
      .get<IBaseModel<IPlanejamentoEquipeModel>>(`${this.apiBaseUrl}/planejamento/${id}/equipes`)
      .toPromise();
  }

  public async desvincularAlocarEquipe(id: number, auditorId: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/planejamento/${id}/equipe/${auditorId}/desvincular`)
      .toPromise();
  }

  public async desvincularUgSubtipo(id: number, subtipoId: number, ug: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/planejamento/${id}/ug/${ug}/subtipo/${subtipoId}/desvincular`)
      .toPromise();
  }

  public async enviarAprovacao(id: number): Promise<IBaseModel<IPlanejamentoModel>> {
    return this.httpClient
      .put<IBaseModel<IPlanejamentoModel>>(`${this.apiBaseUrl}/planejamento/${id}/enviar-aprovacao`, null)
      .toPromise();
  }

  public async homologar(id: number, entranharDocumento: IEntranharDocumentoModel): Promise<IBaseModel<IPlanejamentoModel>> {
    return this.httpClient
      .put<IBaseModel<IPlanejamentoModel>>(`${this.apiBaseUrl}/planejamento/${id}/homologar`, entranharDocumento)
      .toPromise();
  }

  public async verificarNivelAprovacao(id: number): Promise<IBaseModel<IValidacaoNivelModel>> {
    return this.httpClient
      .get<IBaseModel<IValidacaoNivelModel>>(`${this.apiBaseUrl}/planejamento/${id}/verificar-nivel-aprovacao`)
      .toPromise();
  }


}
