import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { IFiltroPendenciaModel } from "src/app/models/filtro-pendencia.model";
import { IPendenciaModel } from "src/app/models/pendencia.model";
import { PendenciaService } from "src/app/services/pendencia.service";
import { BaseListComponent } from "src/app/shared/components/base-list/base-list.component";

@Component({
	selector: "app-pendencia-list",
	templateUrl: "./pendencia-list.component.html",
	styleUrls: ["./pendencia-list.component.scss"],
})
export class PendenciaListComponent
	extends BaseListComponent
	implements OnInit {
	public dataSource = new MatTableDataSource<IPendenciaModel>([]);
	public selectedModel: IPendenciaModel;
	public semDados = true;
	public searchNome: string;
	public searchStatus: number = 1;
	public filtro = {
		itensPorPagina: 15,
		status: 1
	} as IFiltroPendenciaModel;

	public displayedColumns: string[] = [
		"selecao",
		"tipo",
		"dataRecebimento",
		"dataPrazo",
		"status",
	];

	public totalItens: number;
	public pageEvent: any;

	@ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		private pendenciaService: PendenciaService,
		private toastr: ToastrService,
		private router: Router
	) {
		super();
		this.buscar();
	}

	ngOnInit(): void {
		this.buscar();
		this.configurarFiltro();
	}

	ngAfterViewInit() {
		this.configurarFiltro();
		this.buscar();
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.tableOnePaginator;
		this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
	}

	public buscar() {
		this.selectedModel = null;

		this.pendenciaService
			.obter(this.filtro)
			.then((res) => {
				this.dataSource = new MatTableDataSource<IPendenciaModel>(
					res.dados?.resultado
				);

				this.totalItens = res.dados.totalItens;
				this.dataSource.sort = this.sort;
				this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
				this.dataSource._updateChangeSubscription();
				this.semDados = this.dataSource.filteredData.length === 0;

			})
			.catch((err) => {
				this.toastr.error(err?.mensagem?.descricao ?? err, "Atenção");
			});
	}

	public editar(pendencia: IPendenciaModel) {
		this.router.navigate([pendencia.url]);
	}

	public sortingDataAccessor = (item, property) => {
		switch (property) {
			case "tipo":
				return item?.tipoDePendencia;
			case "dataRecebimento":
				return item?.dataDoRecebimento;
			case "dataPrazo":
				return item?.dataDoPrazo;
			case "status":
				return item?.status?.descricao;
			default:
				return item[property];
		}
	};

	public configurarFiltro() {
		this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
		this.filtro.itensPorPagina = this.tableOnePaginator.pageSize;
		this.filtro.pesquisa = this.searchNome;
		this.filtro.status = this.searchStatus;
		this.filtro.direcao = this.sort.direction;
		this.filtro.colunaOrder = this.sort.active;
	}

	pageChanged(e) {
		this.filtro.pagina = e.pageIndex + 1;
		e.pageIndex = this.filtro.pagina;
		this.filtro.itensPorPagina = e.pageSize;
		this.buscar();
	}

	sortData(sort) {
		this.filtro.colunaOrder = sort.active;
		this.filtro.direcao = sort.direction;
		this.buscar();
   }

	public selecionar(item: any) {
		this.selectedModel =
			!this.selectedModel ||
				(this.selectedModel.id !== item.id)
				? item
				: null;
	}
}
