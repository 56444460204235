import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IFasesAtividadeModel } from 'src/app/models/fases-atividade-model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { FaseAtividadeService } from 'src/app/services/fase-atividade.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fase-atividade-list',
  templateUrl: './fase-atividade-list.component.html',
  styleUrls: ['./fase-atividade-list.component.scss']
})
export class FaseAtividadeListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<IFasesAtividadeModel>([]);
  public displayedColumns: string[] = ['selecao', 'nome', 'ativo'];
  public selectedModel: IFasesAtividadeModel;
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;
  public permissoes: IPermissaoModel;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private faseAtividadeService: FaseAtividadeService,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService
    )
     {
      super();
      this.buscar();
    }

    ngOnInit(): void {
      this.setarPermissoes();
    }
  
    private setarPermissoes(): void {
      this.permissoes = new IPermissaoModel();
      this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Consultar);
      this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
      this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Excluir);
      this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.AtivarInativar);
      this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Inserir);
      this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Visualizar);
   }

  public buscar() {
    this.selectedModel = null;

    this.faseAtividadeService
      .obter(this.searchNome, this.searchAtivo, true)
      .then((res) => {
        this.dataSource = new MatTableDataSource<IFasesAtividadeModel>(res.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.exibirMensagemAlerta(err.mensagem.descricao);
      })
  }

  public reativar(id: number) {
        this.faseAtividadeService
        .reativar(id)
        .then((res) => {
          if (res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: 'Fase de atividade reativada com sucesso.',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          } else {
            this.exibirMensagemAlerta(res.mensagem.descricao);
          }
        })
        .catch((err) => {
          this.exibirMensagemErro(err.mensagem.descricao);
        })
        .finally(() => {
          this.buscar();
        });
  }

  public inativar(id: number) {
    this.faseAtividadeService
    .inativar(id)
    .then((res) => {
      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Fase de atividade inativada com sucesso.',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
    .finally(() => {
      this.buscar();
    });
  }

  public editar(id: number) {
    this.router.navigate([`/fase-atividade/${id}`]);
  }

  public novo() {
    this.router.navigate(['/fase-atividade/novo']);
  }

  public excluir(id: number) {
    this.faseAtividadeService
    .excluir(id)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Fase de atividade excluída com sucesso',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
    .finally(() => {
      this.buscar();
    });
  }

  public visualizar(id: number) {
    this.router.navigate([`/fase-atividade/${id}/visualizar`]);
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }

}
