import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAvaliacaoRelatoriaModel } from '../models/avaliacao-relatoria-model';
import { IBaseModel } from '../models/base.model';
import { IRelatoriaCapituloModel } from '../models/relatoria-capitulo.model';
import { BaseService } from './base.service';
import { RelatoriaService } from './relatoria.service';

@Injectable({
  providedIn: 'root',
})
export class RelatoriaCapituloService extends BaseService {

  constructor(httpClient: HttpClient, private relatoriaService: RelatoriaService, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public async obterPorId(id: number): Promise<IBaseModel<IRelatoriaCapituloModel[]>> {
    return this.httpClient
      .get<IBaseModel<IRelatoriaCapituloModel[]>>(`${this.apiBaseUrl}/relatoriascapitulos/${id}`)
      .toPromise();
  }

  public async excluir(idTipodeTrabalho: number, idItem: number, tipoTrabalho: number): Promise<IBaseModel<void>> {
    return this.httpClient
    .patch<IBaseModel<void>>(`${this.apiBaseUrl}/relatoriascapitulos/${idItem}/tipo-trabalho/${idTipodeTrabalho}/excluir`, {})
      .toPromise().then(res => {
        this.relatoriaService.obterPorTipoTrabalho(idTipodeTrabalho, tipoTrabalho);
        return res;
      });
  }

  public async reativar(idTipodeTrabalho: number, idItem: number, tipoTrabalho: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .patch<IBaseModel<void>>(`${this.apiBaseUrl}/relatoriascapitulos/${idItem}/tipo-trabalho/${idTipodeTrabalho}/reativar`, {})
      .toPromise().then(res => {
        this.relatoriaService.obterPorTipoTrabalho(idTipodeTrabalho, tipoTrabalho);
        return res;
      });
  }

  public async avaliarExclusao(id: number, situacao: IAvaliacaoRelatoriaModel, tipoTrabalho: number, tipoTrabalhoId: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .patch<IBaseModel<void>>(`${this.apiBaseUrl}/relatoriascapitulos/${id}/avaliar-exclusao/`, situacao)
      .toPromise().then(res => {
        this.relatoriaService.obterPorTipoTrabalho(tipoTrabalhoId, tipoTrabalho);
        return res;
      });
  }

  public async salvar(relatoriaDados: IRelatoriaCapituloModel, tipoTrabalho: number, id?: number): Promise<IBaseModel<IRelatoriaCapituloModel>> {
    return this.httpClient
      .post<IBaseModel<IRelatoriaCapituloModel>>(`${this.apiBaseUrl}/relatoriascapitulos/salvar`, relatoriaDados)
      .toPromise().then(res => {
        if (id) { this.relatoriaService.obterPorTipoTrabalho(id, tipoTrabalho); }
        return res;
      });
  }
}

