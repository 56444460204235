import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { ICapituloRelatorioModel } from '../models/capitulo-relatorio.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CapituloRelatorioService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<ICapituloRelatorioModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<ICapituloRelatorioModel[]>>(`${this.apiBaseUrl}/capitulorelatorio`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<ICapituloRelatorioModel>> {
    return this.httpClient
      .get<IBaseModel<ICapituloRelatorioModel>>(`${this.apiBaseUrl}/capitulorelatorio/${id}`)
      .toPromise();
  }

  public async inserir(data: ICapituloRelatorioModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/capitulorelatorio`, data)
      .toPromise();
  }

  public async atualizar(data: ICapituloRelatorioModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/capitulorelatorio/${data.id}`, data)
      .toPromise();
  }

  public async aprovarReprovarCapitulo(id: number, aprovado: boolean): Promise<IBaseModel<void>> {
    return this.httpClient
      .patch<IBaseModel<void>>(`${this.apiBaseUrl}/capitulorelatorio/${id}/aprovar/${aprovado}`, {})
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/capitulorelatorio/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/capitulorelatorio/${id}/inativar`, {})
      .toPromise();
  }
}
