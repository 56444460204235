import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { toIntlNumberString } from 'src/app/core/helpers/number.helper';
import { IPeaCriterioRespostaModel } from 'src/app/models/pea-criterio-resposta.model';

@Component({
  selector: 'app-modal-importar-arquivo-criterio',
  templateUrl: './modal-importar-arquivo-criterio.component.html',
  styleUrls: ['./modal-importar-arquivo-criterio.component.scss'],
})
export class ModalImportarArquivoCriterioComponent {
  public conteudoArquivo: string;
  public mensagens: string[];
  public respostas = [] as IPeaCriterioRespostaModel[];

  constructor(
    private dialogRef: MatDialogRef<ModalImportarArquivoCriterioComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.respostas = data as IPeaCriterioRespostaModel[];
  }

  public onFileChange(event) {
    this.mensagens = [];
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsText(file);
      reader.onload = () => {
        this.conteudoArquivo = reader.result.toString();
        this.lerValidarArquivo();
      };
    }
  }

  public lerValidarArquivo() {
    if (!this.conteudoArquivo) {
      this.mensagens = ['O arquivo não tem um conteúdo válido.'];
      return;
    }

    let erros = [];
    this.conteudoArquivo.split('\n').forEach((l, i) => {
      if(l.length === 1 || l === ""){
        return;
      }
      if (l.trim().length > 0 && l.split(';').length !== 2) {
        erros = erros.concat([`A linha ${i + 1} deve ter somente duas colunas sendo separadas pelo caractere ';'.`]);
      } else {
        const resposta = this.respostas.find(r => r.unidadeGestora.codigoUg.toString() === l.split(';')[0]);
        if (resposta) {
          const valor = parseFloat(toIntlNumberString(l.split(';')[1]));
          if (!valor.toString() || isNaN(valor) || valor < 0) {
            erros = erros.concat([`Não há valor válido na linha ${i + 1}. Favor verificar e realizar nova importação.`]);
            this.conteudoArquivo = null;
          } else {
            resposta.valorNumerico = valor;
          }
        }
        else {
          erros = erros.concat([`O código da UG na linha ${i + 1} não esta associado ao PEAC.`]);
          this.conteudoArquivo = null;
        }
      }
    });

    if (erros.length === 0) {
      this.mensagens = ['Conteúdo do arquivo validado e disponível para importação.'];
    }
    else {
      this.mensagens = erros;
    }
  }

  public concluir() {
    this.dialogRef.close(this.respostas);
  }

  public cancelar() {
    this.dialogRef.close();
  }
}
