import { IPaaCoordenacaoCapacidadeModel } from 'src/app/models/paa-coordenacao-capacidade.model';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';
import { IBaseModel } from 'src/app/models/base.model';
import { IPaaDistribuicaoImprimirModel } from 'src/app/models/paa-distribuicao-imprimir.model';
import { IPaaDistribuicaoModel } from 'src/app/models/paa-distribuicao.model';
import { IPaaDistribuicaoColunasModel } from 'src/app/models/paa-distribuicao-colunas.model';
import { IPaaModel } from 'src/app/models/paa.model';
import { IPaaDistribuicaoHistoricoModel } from 'src/app/models/paa-distribuicao.historico.model';

@Injectable({
    providedIn: 'root'
  })
  export class PaaAbaDistribuicaoService extends BaseService{
    constructor(httpClient: HttpClient, locationStrategy: LocationStrategy){
        super(httpClient, locationStrategy);
    }

    public obterPainelDistribuicaoImprimir(id: number): Promise<IBaseModel<IPaaDistribuicaoImprimirModel[]>> {
        return this.httpClient
          .get<IBaseModel<IPaaDistribuicaoImprimirModel[]>>(`${this.apiBaseUrl}/paa/${id}/painel-distribuicao/imprimir`)
          .toPromise();
      }
    
      public obterPainelDistribuicao(id: number): Promise<IBaseModel<IPaaDistribuicaoModel[]>> {
        return this.httpClient
          .get<IBaseModel<IPaaDistribuicaoModel[]>>(`${this.apiBaseUrl}/paaAbaDistribuicao/${id}/painel-distribuicao`)
          .toPromise();
      }
    
      public obterPainelDistribuicaoColunas(id: number): Promise<IBaseModel<IPaaDistribuicaoColunasModel[]>> {
        return this.httpClient
          .get<IBaseModel<IPaaDistribuicaoColunasModel[]>>(`${this.apiBaseUrl}/paa/${id}/painel-distribuicao/colunas`)
          .toPromise();
      }
    
        public async AlterarSatusDistribuicaoColunas(item: IPaaDistribuicaoColunasModel[]): Promise<IBaseModel<IPaaDistribuicaoColunasModel[]>> {
        return this.httpClient
          .post<IBaseModel<IPaaDistribuicaoColunasModel[]>>(`${this.apiBaseUrl}/paa/painel-distribuicao/distribuicao-colunas`, item)
          .toPromise();
      }
    
      public async AlterarStatusDistribuicao(id: number, ativo: boolean, justificativa: string): Promise<IBaseModel<IPaaModel>> {
        const dados = {
          ativo,
          justificativa
        };
    
        return this.httpClient
          .put<IBaseModel<IPaaModel>>(`${this.apiBaseUrl}/paaAbaDistribuicao/${id}/painel-distribuicao/status`, dados)
          .toPromise();
      }
    
      public async AlterartipoAuditoriaDistribuicao(id: number, tipoAuditoriaId: number, justificativa: string): Promise<IBaseModel<IPaaModel>> {
        const dados = {
          justificativa
        };
        return this.httpClient
          .put<IBaseModel<IPaaModel>>(`${this.apiBaseUrl}/paaAbaDistribuicao/${id}/painel-distribuicao/tipo-auditoria/${tipoAuditoriaId}`, dados)
          .toPromise();
      }
    
      public async AlterarTipoEnfaseDistribuicao(id: number, tipoEnfaseId: number, justificativaEnfase: string): Promise<IBaseModel<IPaaModel>>{
        return this.httpClient
        .put<IBaseModel<IPaaModel>>(`${this.apiBaseUrl}/paaAbaDistribuicao/${id}/painel-distribuicao/tipo-enfase/${tipoEnfaseId}&${justificativaEnfase}`, null)
        .toPromise();
      }
    
      public async ValidarTipoAuditoria(id: number, tipoAuditoriaId: number): Promise<IBaseModel<IPaaModel>> {
        return this.httpClient
          .put<IBaseModel<IPaaModel>>(`${this.apiBaseUrl}/paaAbaDistribuicao/${id}/painel-distribuicao/validar-tipo-auditoria/${tipoAuditoriaId}`, null)
          .toPromise();
      }
    
      

      public obterHistoricoDistribuicao(id: number, unidadeGestoraId: number): Promise<IBaseModel<IPaaDistribuicaoHistoricoModel[]>> {
        return this.httpClient
          .get<IBaseModel<IPaaDistribuicaoHistoricoModel[]>>(`${this.apiBaseUrl}/paaAbaDistribuicao/${id}/unidade-gestora/${unidadeGestoraId}/historico-distribuicao`)
          .toPromise();
      }
  }