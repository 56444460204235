<div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-outline card-lightblue">
            <div class="card-header">
              <h3 class="card-title mb-0">
                <i class="fas fa-table pr-2"></i>
                Entendendo a Entidade
              </h3>
            </div>
  
            <div class="card-body p-2">
              <div class="mat-elevation-z4">
                <mat-toolbar class="table-header">
                  <mat-toolbar-row>

                    
                    <div class="input-group input-group-sm" style="width: 200px;">
                        <input type="text" name="table_search" class="form-control float-right" (keyup.enter)="buscarQuestionario()"
                          placeholder="Pesquisar" [(ngModel)]="filtro.pesquisa" />
                        <div class="input-group-append">
                          <button type="button" class="btn btn-info" (click)="buscarQuestionario()">
                            <i class="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                      
                    <button type="button" class="btn btn-success btn-sm mr-1" (click)="gerarQuestionario()" >
                      <i class="far fa-file"></i> Gerar Questionário
                    </button>
                   
                  </mat-toolbar-row>
                </mat-toolbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>