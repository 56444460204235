import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EnumTipoQuestionarioUG } from '../models/enum/tipo-questionario-ug.enum';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private unidadeGestoraId: number;
  private subject = new Subject<any>();
  private tipoQuestionario: EnumTipoQuestionarioUG;
  private modoVisualizarTelaUnidadeGestora : boolean;

  sendSalvarEvent() {
    this.subject.next();

  }
  getEvent():Observable<any>{
    return this.subject.asObservable();
  }

  setId(id: number) {
    this.unidadeGestoraId = id;
  }
  
  getId(): number {
    return this.unidadeGestoraId;
  }

  setTipoQuestionario(tipo: EnumTipoQuestionarioUG){
    this.tipoQuestionario = tipo;
  }

  getTipoQuestionario(): EnumTipoQuestionarioUG{
    return this.tipoQuestionario;
  }

  setModoVisualizacaoAbasUnidadeGestora(modoVisualizacao: boolean) {
    this.modoVisualizarTelaUnidadeGestora = modoVisualizacao;
  }
  
  getModoVisualizacaoAbasUnidadeGestora() : boolean {
    return this.modoVisualizarTelaUnidadeGestora;
  }
}
