import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IQuestaoModel } from '../models/questao.model';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root',
})
export class QuestaoService extends BaseService {
    constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
        super(httpClient, locationStrategy);
    }

    public obter(pesquisa?: string, ativo?: boolean, simplificado = false): Promise<IBaseModel<IQuestaoModel[]>> {
        let params = new HttpParams();
        params = params.append('pesquisa', pesquisa || '');

        if (ativo !== undefined) {
            params = params.append('ativo', ativo.toString());
        }

        if (simplificado) {
            params = params.append('simplificado', simplificado.toString());
        }

        return this.httpClient.get<IBaseModel<IQuestaoModel[]>>(`${this.apiBaseUrl}/questao`, { params }).toPromise();
    }

    public obterPorTipoTrabalho(tipoTrabalho: number): Promise<IBaseModel<IQuestaoModel[]>> {
        return this.httpClient
            .get<IBaseModel<IQuestaoModel[]>>(`${this.apiBaseUrl}/questao/tipoTrabalho/${tipoTrabalho}`)
            .toPromise();
    }

    public async obterPorId(id: number, tipoTrabalho: string): Promise<IBaseModel<IQuestaoModel>> {
        if (tipoTrabalho === 'planejamento') {
            return this.httpClient
                .get<IBaseModel<IQuestaoModel>>(`${this.apiBaseUrl}/questao/${id}/planejamento`)
                .toPromise();
        } else if (tipoTrabalho === 'osa') {
            return this.httpClient.get<IBaseModel<IQuestaoModel>>(`${this.apiBaseUrl}/questao/${id}/osa`).toPromise();
        } else {
            return this.httpClient.get<IBaseModel<IQuestaoModel>>(`${this.apiBaseUrl}/questao/${id}`).toPromise();
        }
    }

    public async inserir(
        data: IQuestaoModel,
        tipoTrabalho: string,
        tipoTrabalhoId: number,
        subTipoAuditoriaId: number
    ): Promise<IBaseModel<IQuestaoModel>> {
        if (tipoTrabalho === 'planejamento') {
            data.tipoTrabalhoId = tipoTrabalhoId;
            data.subTipoAuditoriaId = subTipoAuditoriaId;
            return this.httpClient
                .post<IBaseModel<IQuestaoModel>>(`${this.apiBaseUrl}/questao/planejamento`, data)
                .toPromise();
        } else if (tipoTrabalho === 'osa') {
            data.tipoTrabalhoId = tipoTrabalhoId;
            return this.httpClient.post<IBaseModel<IQuestaoModel>>(`${this.apiBaseUrl}/questao/osa`, data).toPromise();
        } else {
            return this.httpClient.post<IBaseModel<IQuestaoModel>>(`${this.apiBaseUrl}/questao`, data).toPromise();
        }
    }

    public async atualizar(
        data: IQuestaoModel,
        tipoTrabalho: string,
        tipoTrabalhoId: number
    ): Promise<IBaseModel<IQuestaoModel>> {
        if (tipoTrabalho === 'planejamento') {
            data.tipoTrabalhoId = tipoTrabalhoId;
            return this.httpClient
                .post<IBaseModel<IQuestaoModel>>(`${this.apiBaseUrl}/questao/planejamento`, data)
                .toPromise();
        } else if (tipoTrabalho === 'osa') {
            data.tipoTrabalhoId = tipoTrabalhoId;
            return this.httpClient.post<IBaseModel<IQuestaoModel>>(`${this.apiBaseUrl}/questao/osa`, data).toPromise();
        } else {
            return this.httpClient.post<IBaseModel<IQuestaoModel>>(`${this.apiBaseUrl}/questao`, data).toPromise();
        }
    }

    public async reativar(id: number): Promise<IBaseModel<void>> {
        return this.httpClient.post<IBaseModel<void>>(`${this.apiBaseUrl}/questao/${id}/reativar`, {}).toPromise();
    }

    public async inativar(id: number): Promise<IBaseModel<void>> {
        return this.httpClient.post<IBaseModel<void>>(`${this.apiBaseUrl}/questao/${id}/inativar`, {}).toPromise();
    }

    public async excluir(id: number, tipoTrabalho: string): Promise<IBaseModel<void>> {
        if (tipoTrabalho === 'planejamento') {
            return this.httpClient
                .delete<IBaseModel<void>>(`${this.apiBaseUrl}/questao/${id}/planejamento`, {})
                .toPromise();
        } else if (tipoTrabalho === 'osa') {
            return this.httpClient.delete<IBaseModel<void>>(`${this.apiBaseUrl}/questao/${id}/osa`, {}).toPromise();
        } else {
            return this.httpClient.delete<IBaseModel<void>>(`${this.apiBaseUrl}/questao/${id}`, {}).toPromise();
        }
    }

    public async vincularQuestaoConstatacao(questaoId: number, data: { constatacoes: number[] }, tipoTrabalho: string) {
        if (tipoTrabalho === 'planejamento') {
            return this.httpClient
                .post<IBaseModel<void>>(
                    `${this.apiBaseUrl}/Questao/${questaoId}/questao-constatacao/salvar/planejamento`,
                    data
                )
                .toPromise();
        } else if (tipoTrabalho === 'osa') {
            return this.httpClient
                .post<IBaseModel<void>>(`${this.apiBaseUrl}/Questao/${questaoId}/questao-constatacao/salvar/osa`, data)
                .toPromise();
        } else {
            return this.httpClient
                .post<IBaseModel<void>>(`${this.apiBaseUrl}/Questao/${questaoId}/questao-constatacao/salvar`, data)
                .toPromise();
        }
    }

    public async vincularQuestaoProcedimento(
        questaoId: number,
        data: { procedimentos: number[] },
        tipoTrabalho: string
    ) {
        if (tipoTrabalho === 'planejamento') {
            return this.httpClient
                .post<IBaseModel<void>>(
                    `${this.apiBaseUrl}/Questao/${questaoId}/questao-procedimento/salvar/planejamento`,
                    data
                )
                .toPromise();
        } else if (tipoTrabalho === 'osa') {
            return this.httpClient
                .post<IBaseModel<void>>(`${this.apiBaseUrl}/Questao/${questaoId}/questao-procedimento/salvar/osa`, data)
                .toPromise();
        } else {
            return this.httpClient
                .post<IBaseModel<void>>(`${this.apiBaseUrl}/Questao/${questaoId}/questao-procedimento/salvar`, data)
                .toPromise();
        }
    }

    public async desVincularQuestaoConstatacao(questaoId: number, constatcaoId: number, tipoTrabalho: string) {
        if (tipoTrabalho === 'planejamento') {
            return this.httpClient
                .delete<IBaseModel<void>>(
                    `${this.apiBaseUrl}/Questao/${questaoId}/constatacao/${constatcaoId}/excluir/planejamento`
                )
                .toPromise();
        } else if (tipoTrabalho === 'osa') {
            return this.httpClient
                .delete<IBaseModel<void>>(
                    `${this.apiBaseUrl}/Questao/${questaoId}/constatacao/${constatcaoId}/excluir/osa`
                )
                .toPromise();
        } else {
            return this.httpClient
                .delete<IBaseModel<void>>(`${this.apiBaseUrl}/Questao/${questaoId}/constatacao/${constatcaoId}/excluir`)
                .toPromise();
        }
    }

    public async desVincularQuestaoProcedimento(questaoId: number, procedimentoId: number, tipoTrabalho: string) {
        if (tipoTrabalho === 'planejamento') {
            return this.httpClient
                .delete<IBaseModel<void>>(
                    `${this.apiBaseUrl}/Questao/${questaoId}/procedimento/${procedimentoId}/excluir/planejamento`
                )
                .toPromise();
        } else if (tipoTrabalho === 'osa') {
            return this.httpClient
                .delete<IBaseModel<void>>(
                    `${this.apiBaseUrl}/Questao/${questaoId}/procedimento/${procedimentoId}/excluir/osa`
                )
                .toPromise();
        } else {
            return this.httpClient
                .delete<IBaseModel<void>>(
                    `${this.apiBaseUrl}/Questao/${questaoId}/procedimento/${procedimentoId}/excluir`
                )
                .toPromise();
        }
    }

    public async aprovar(questaoId: number): Promise<IBaseModel<void>> {
        return this.httpClient
            .patch<IBaseModel<void>>(`${this.apiBaseUrl}/questao/${questaoId}/avaliar`, { avaliar: 1 })
            .toPromise();
    }

    public async reprovar(questaoId: number): Promise<IBaseModel<void>> {
        return this.httpClient
            .patch<IBaseModel<void>>(`${this.apiBaseUrl}/questao/${questaoId}/avaliar`, { avaliar: 0 })
            .toPromise();
    }
}
