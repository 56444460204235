import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as cloneDeep from 'lodash/cloneDeep';
import { IPaaDistribuicaoColunasModel } from 'src/app/models/paa-distribuicao-colunas.model';
import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';

@Component({
  selector: 'app-modal-selecionar-colunas',
  templateUrl: './modal-selecionar-colunas.component.html',
  styleUrls: ['./modal-selecionar-colunas.component.scss']
})
export class ModalSelecionarColunasComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  public todosIndicesCriterios = [] as IPaaDistribuicaoColunasModel[];
  public semDados = true;
  public displayedColumns = ['selecao', 'nome'];
  public searchNome: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<ModalSelecionarColunasComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.todosIndicesCriterios = data;
    this.dataSource.data = data.map(d => {
      return { id: d.id, ativo: d.ativo, nome: d.nomeIndicador ? d.nomeIndicador + '/' + d.nomeCriterio : d.nomeCriterio };
    });
    this.dataSource._updateChangeSubscription();
  }

  ngOnInit(): void {
    this.sort.sort(({ id: 'nome', start: 'asc'}) as MatSortable);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.semDados = this.dataSource.filteredData.length === 0;
  }

  public concluir() {
    this.dialogRef.close(this.dataSource.data);
  }

  public itemAgrupador(index: number, item: any): boolean {
    return item.grupo;
  }

  public cancelar() {
    this.dialogRef.close();
  }
}
