<div class="card-header bg-light">
  <h4 class="card-title mb-0">
    <i class="far fa-file"></i>
    Anexar Arquivo
  </h4>
</div>
<div class="p-2 wrapper">
  <div class="row">
    <div class="form-group col-md-6">
      <label class="mr-1">Nº Solicitação Técnica</label>
      <input class="form-control form-control-sm" [ngModel]="nSolicitacaoTecnica"  [ngModelOptions]="{standalone: true}" disabled />
    </div>
    <div class="form-group col-md-6 ">
      <label class="mr-1">Nº Item</label>
      <input class="form-control form-control-sm" [ngModel]="numeroItem"  [ngModelOptions]="{standalone: true}" disabled />
    </div>
  </div>
  <div class="row">
      <div class="form-group col-md-12 ">
        <input
					type="file"
					class="form-control-file"
					id="file"
					name="file[]"
					multiple="multiple"
					(change)="onFileChange($event)"
					class="inputfile"
          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.wordprocessingml.template, application/vnd.ms-word.document.macroEnabled.12"  multiple
				/>
				<label for="file" class="button-style btn btn-success btn-sm"
					><i class="fa fa-upload" aria-hidden="true"></i>ㅤEscolha os
					arquivos</label
				>
      </div>
  </div>
  <div class="col-md-12 mt-3">
    <div class="mat-elevation-z4">
      <table mat-table [dataSource]="dataSource" class=" w-100 " matSort matSortDisableClear>
        <tr mat-header-row *matHeaderRowDef="['arquivo', 'acoes']" class="bg-light"></tr>
        <ng-container matColumnDef="arquivo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Arquivos</th>
          <td mat-cell *matCellDef="let element">
            <small (click)="Download(element?.arquivo, element?.nomeArquivo)" class="download">{{ element.name }}</small>
          </td>
        </ng-container>

        <ng-container matColumnDef="acoes">
          <th mat-header-cell *matHeaderCellDef class="text-center">
            Ações
          </th>
          <div class="w-100 d-flex justify-content-center">
            <td mat-cell *matCellDef="let element; let i = index" class="block">
              <div class="w-100 d-flex justify-content-center">
                <button type="button" class="btn btn-danger btn-xs text-left" (click)="excluirArquivo(i)">
                  <i class="far fa-trash-alt"></i>
                </button>
              </div>
            </td>
          </div>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: ['arquivo', 'acoes']"></tr>
      </table>
      <h5  [hidden]="dataSource?.data?.length > 0" class="table-no-data">
        Adicione um novo arquivo!
      </h5>
      <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
<div class="card-footer">
  <button type="submit" class="btn btn-primary btn-sm mr-1" (click)="salvar()" [disabled]="dataSource?.data?.length === 0">
    <i class="far fa-save"></i> Salvar
  </button>
  <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
    <i class="fas fa-times"></i> Fechar
  </button>
</div>
