import { PaginacaoModel } from './../models/paginacao.model';
import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { BaseService } from './base.service';
import { filterBy } from './auditoria.service';
import { IUnidadeGestoraMaturidadeGRHistoricoModel } from '../models/unidade-gestora-maturidadeGRHistorico.model';
import { IFiltroUnidadeGestoraMaturidadeGRHistoricoModel } from '../models/filtro-unidade-gestora-maturidadegrhistorico.model';

@Injectable({
  providedIn: 'root',
})
export class UnidadeGestoraMaturidadeGRHistoricoService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(filtro?: IFiltroUnidadeGestoraMaturidadeGRHistoricoModel): Promise<IBaseModel<IUnidadeGestoraMaturidadeGRHistoricoModel[]>> {
      return this.httpClient
      .get<IBaseModel<IUnidadeGestoraMaturidadeGRHistoricoModel[]>>(filterBy(filtro, `${this.apiBaseUrl}/unidadegestoramaturidadegrhistorico`))
      .toPromise();
  }

  
}
