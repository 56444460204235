<div id="modal-content-wrapper">
    <div class="card card-secondary card-outline">
      <div class="card-header bg-light">
        <h4 class="card-title mb-0">Visualizar o ranking de prioridade do indicador {{ data.nomeIndicador}}</h4>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <!-- RANKING PRIORIDADE -->
        <app-ranking-prioridade
          [rankingPrioridadeDataSource]="rankingPrioridadeDataSource"
          [rankingPrioridadeEmpresasPublicasDataSource]="
            rankingPrioridadeEmpresasPublicasDataSource
          "
          [semDadosRanking]="semDadosRanking"
          [semDadosRankingEmpresaPublica]="semDadosRankingEmpresaPublica"
        ></app-ranking-prioridade>
        <!-- Fim -->
      </div>
      <div class="card-footer">
        <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
          <i class="fas fa-times"></i> Cancelar
        </button>
      </div>
    </div>
  </div>
