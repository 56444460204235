import { SolicitacaoTecnicaService } from './../../../../services/solicitacao-tecnica.service';
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { IResponsavelModel } from "src/app/models/responsaveis.model";
import { ISolicitacaoTecnicaUnidadeGestoraModel } from "src/app/models/solicitacao-tecnica.model";
import { IUnidadeGestoraModel } from "src/app/models/unidade-gestora.model";
import Swal from 'sweetalert2';
import { IEnumModel } from 'src/app/models/enum.model';

@Component({
	selector: "app-modal-incluir-ug",
	templateUrl: "./modal-incluir-ug.component.html",
	styleUrls: ["./modal-incluir-ug.component.scss"],
})
export class ModalIncluirUgComponent implements OnInit {
	public dataSourceUG =
		new MatTableDataSource<ISolicitacaoTecnicaUnidadeGestoraModel>([]);
	public unidadesGetoras: IUnidadeGestoraModel[];
	public responsaveis: IResponsavelModel[];
	public ugSelecionada: IUnidadeGestoraModel = {} as IUnidadeGestoraModel;
	public responsavelSelecionado: IResponsavelModel = {} as IResponsavelModel;
  public ugCadastrada: ISolicitacaoTecnicaUnidadeGestoraModel[] = [] as ISolicitacaoTecnicaUnidadeGestoraModel[];
  public dataSourceUgPai: ISolicitacaoTecnicaUnidadeGestoraModel[] = [] as ISolicitacaoTecnicaUnidadeGestoraModel[];
  public tipoTrabalho: IEnumModel = {} as IEnumModel;
  public planejamentoId: number;

	public form = new FormGroup({
		ug: new FormControl("", Validators.required),
		responsavel: new FormControl("", Validators.required),
	});

	constructor(
		public matDialog: MatDialog,
		private dialogRef: MatDialogRef<ModalIncluirUgComponent>,
    public service: SolicitacaoTecnicaService,
		@Inject(MAT_DIALOG_DATA) public data
	) {
    this.dataSourceUgPai = data.dataSourcePaiUg;
    this.tipoTrabalho = data.tipoTrabalho
    if(this.tipoTrabalho?.codigo === 4){
      this.planejamentoId = data?.planejamentoId
    }else if(this.tipoTrabalho?.codigo === 7){
      this.planejamentoId =  data?.id
    }
  }

	@ViewChild("TableOnePaginator", { static: true })
	tableOnePaginator: MatPaginator;
	@ViewChild("tabela1", { static: true }) tabela1: MatSort;

	ngOnInit(): void {
    this.responsavelSelecionado = undefined;
    this.ugSelecionada = undefined;
    this.obterUG()
		this.dataSourceUG.sort = this.tabela1;
		this.dataSourceUG.sortingDataAccessor = this.sortingDataAccessor;
  }

  public obterUG(){
    this.service.obterUnidadeGestora( this.tipoTrabalho.nome, this.planejamentoId).then((res) => {
      if(res.sucesso){
        this.unidadesGetoras = res.dados
      }else{
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    })
  }

  public obterResponsaveis(id: number){
    this.service.obterResponsaveis(id).then((res) => {
      if(res.sucesso){
        this.responsaveis = res.dados;
      }else{
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
    }})

  }

	public salvar() {
		this.dialogRef.close(this.dataSourceUG.data);
	}

	public adicionarUg() {

    const findUgSelecionada = this.dataSourceUgPai.find(x =>  (x?.responsavel?.value === this.responsavelSelecionado?.value) && ((Number(x?.unidadeGestora?.value) === Number(this.ugSelecionada?.value))))
    const findDataUgSelecionada = this.dataSourceUG.data.find(x =>  (x?.responsavel?.value === this.responsavelSelecionado?.value) && ((Number(x?.unidadeGestora?.value) === Number(this.ugSelecionada?.value))))

    if ((Object?.keys(findUgSelecionada || {} )?.length > 0) || (Object?.keys(findDataUgSelecionada || {} )?.length > 0)) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        text: ' Unidade gestora, já selecionada!',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return;
    }

		this.dataSourceUG.data.push({
			unidadeGestora: {
				text: this.ugSelecionada.text,
				value: this.ugSelecionada.value,
			},
			responsavel: {
				text: this.responsavelSelecionado.text,
				value: this.responsavelSelecionado.value,
			},
		} as ISolicitacaoTecnicaUnidadeGestoraModel);
		this.dataSourceUG.data = this.dataSourceUG.data;
		this.dataSourceUG.paginator = this.tableOnePaginator;

    this.ugSelecionada = undefined;
		this.responsavelSelecionado = undefined;

	}

	public sortingDataAccessor = (item, property) => {
		switch (property) {
			case "ug":
				return item.unidadeGestora?.sigla;
			case "responsavel":
				return item.unidadeGestora?.sigla;
			default:
				return item[property];
		}
	};

	public excluirUg(i: number, element: ISolicitacaoTecnicaUnidadeGestoraModel) {
    this.dataSourceUG.data.splice(i, 1);
		this.dataSourceUG._updateChangeSubscription();


	}

	public cancelar() {
		this.dialogRef.close();
	}
}
