<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="callout callout-info">
          <h3>Bem vindo {{authService.usuario?.nome}}!</h3>
          Utilize o menu ao lado para acessar as opções disponíveis.
        </div>
      </div>
    </div>
    <div class="row" *ngIf="count >= 1">
      <div class="col-md-12">
        <div class="callout callout-info">
          <h5 *ngIf="count > 1">Existem {{count}} pendências a serem resolvidas. <a class="link" [routerLink]="['/pendencias']">Clique aqui</a></h5>
          <h5 *ngIf="count === 1">Existe {{count}} pendência a ser resolvida. <a class="link" [routerLink]="['/pendencias']">Clique aqui</a></h5>
        </div>
      </div>
    </div>
  </div>
</div>
