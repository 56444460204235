import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Output, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IAvaliacaoRelatoriaModel } from 'src/app/models/avaliacao-relatoria-model';
import { IRelatoriaCapituloModel } from 'src/app/models/relatoria-capitulo.model';
import { IRelatoriaModel } from 'src/app/models/relatoria.model';
import { RelatoriaCapituloService } from 'src/app/services/relatoria-capitulo.service';
import { RelatoriaService } from 'src/app/services/relatoria.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { BaseFormComponent } from '../base-form/base-form.component';
import { ModalHistoricoRelatoriaComponent } from './modal-historico-relatoria/modal-historico-relatoria.component';
import { ModalRelatoriaCapituloComponent } from './modal-relatoria-capitulo/modal-relatoria-capitulo.component';
import { angularEditorConfig } from './../../util/angularEditorConfig';

@Component({
  selector: 'app-capitulo',
  templateUrl: './capitulo.component.html',
  styleUrls: ['./capitulo.component.scss'],
})
export class CapituloComponent extends BaseFormComponent implements OnInit {

  @Input() relatoriaDados: IRelatoriaCapituloModel[];
  @Input() somenteLeitura: Boolean;
  @Input() public model: any = {};
  @Input() id: number;
  @Input() dadosObtidos: IRelatoriaModel;
  @Input() obterRelatorio: () => void;
  @Input() obterDados: () => void;
  @Input() public impressao: boolean;
  @Input() public reprovado: boolean;
  @Input() public homologado: boolean;
  @Input() tipoTrabalhoRef: number;
  relatoriaDadosEspelho: any;


  public situacao = {} as IAvaliacaoRelatoriaModel;
  tipoTrabalho;
  public keywords = ['Período', 'Indicador', 'UGs', 'Coordenações', 'Capacidade de trabalho', 'Auditores da Coordenação', 'Distribuição Contempladas', 'Ranking de Distribuição'] as string[];
  public keywordsPaaPadrao = ['Período do PEAC', 'Indicadores do PAAC corrente', 'UGs do PAAC corrente', 'Coordenações do PAAC corrente', 'Capacidade de trabalho do PAAC corrente',
    'Auditores da Coordenação do PAAC corrente', 'Distribuição Contempladas do PEAC', 'Ranking de Distribuição do PAAC corrente', 'Indicadores e Critérios do PAAC corrente',
    'Painel de Distribuição do PAAC corrente', 'Auditorias inativas no Painel de Distribuição com respectiva justificativa', 'Auditorias por Tipo e Coordenação e UGs',
    'Alocação da Equipe', 'Calendário do PAAC corrente', 'Capacidade total PAAC', 'Capacidade total PEAC'] as string[];
  public keywordsOpa = ['Tipo de auditoria', 'Origem', 'Unidade auditada', 'Objeto', 'Período', 'Coordenador', 'Auditores do OPA', 'Subtipo de Auditoria do OPA', 'Tipo de Ênfase',
     'Data da Apresentação do Planejamento', 'Quantidade de dias Úteis', 'Data prevista Início', 'Data prevista Fim', 'Data Início de Realização', 'Data Fim de Realização', 'Número da Opa'] as string[];
  public keywordsPlanejamento = ['Auditores do Planejamento', 'Subtipo de Auditoria do Planejamento', 'UGs do Planejamento'] as string[];
  public keywordsOsa = ['Auditores do OSA', 'Subtipo de Auditoria do OSA'] as string[];

  public keywordsPaa = this.keywordsPaaPadrao;
  public keywordsPaaEspecial = this.keywordsPaaPadrao;
  public novaVersao = false;
  public config: AngularEditorConfig = angularEditorConfig('Descreva o capitulo');
  public habilitarEdicaoRelatoria = {
    index: null,
    indexSubChapter: null,
    active: false,
    activeAll: false,
    atctiveCapitulo: false,
  };

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private _location: Location,
    public matDialog: MatDialog,
    private relatoriaService: RelatoriaService,
    private relatoriaCapituloService: RelatoriaCapituloService
  ) {
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {
    if(!this.tipoTrabalhoRef || this.tipoTrabalhoRef == 0){
      const path = this.route.routeConfig.path?.toString();
      if (path?.includes('pea')) {
        this.tipoTrabalho = 1;
      }
      else if (path?.includes('paa')) {
        this.tipoTrabalho = 2;
      }
      else if (path?.includes('opa')) {
        this.tipoTrabalho = 3;
      }
      else if (path?.includes('osa')) {
        this.tipoTrabalho = 4;
      }
      else if (path?.includes('planejamento')) {
        this.tipoTrabalho = 7;
      }
    }else{
      this.tipoTrabalho = this.tipoTrabalhoRef;
    }
    
    if(this.impressao){
      this.habilitarEdicaoRelatoria.activeAll = false;
    }
    if (this.router.url.includes("nova-versao")) {
      this.novaVersao = true
    }
  }

  public habilitarEdicaoCapituloRelatoria(index?: (number | null), status?: (boolean | null), indexSubChapter?: (number | null), activeAll?: boolean, editarCapitulo?: boolean) {
    if (activeAll) {
      return this.habilitarEdicaoRelatoria.activeAll = true;
    }

    let capitulo = this.relatoriaDados.find((_, indexCapitulo) => indexCapitulo === index);

    if (editarCapitulo == false) {
      capitulo.active = false;

      this.relatoriaService.obterPorTipoTrabalho(this.id, this.tipoTrabalho).then(() => {
        this.relatoriaService.reltoriaCurrent.subscribe(relatoriaDados => {
          this.relatoriaDadosEspelho = relatoriaDados;
          this.dadosObtidos = relatoriaDados;
        });
      });

      capitulo = this.relatoriaDadosEspelho.find((_, indexCapitulo) => indexCapitulo === index);
      this.relatoriaDados = this.relatoriaDadosEspelho;
      this.mudarAba(5);
    }

    if ((!indexSubChapter && indexSubChapter < 0) || editarCapitulo) {
      capitulo.active = status;
    }
    else {
      const subCapitulo = capitulo.subRelatoriaCapitulos.find((_, indexSubCapitulo) => indexSubCapitulo === indexSubChapter);
      subCapitulo.active = status;
    }
  }

  public mudarAba(ind: number) {
    this.aba = ind;
  }

  public habilitarTodasEdicoesCapituloRelatoria() {
    this.relatoriaDados.forEach((capitulo, index) => {
      this.habilitarEdicaoCapituloRelatoria(index, true)
      capitulo.subRelatoriaCapitulos.forEach((subCapitulo, indexSub) => {
        this.habilitarEdicaoCapituloRelatoria(index, true, indexSub)
      })
    })
  }


  public modalRelatoriaCapitulo(element?: IRelatoriaCapituloModel, subCapitulo?: boolean) {
    try {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-relatoria-capitulo';
      dialogConfig.width = '1200px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        element,
        subCapitulo,
        id: this.id,
        tipoTrabalho: this.tipoTrabalho
      };
      const modal = this.matDialog.open(ModalRelatoriaCapituloComponent, dialogConfig);
      modal.afterClosed().subscribe((data) => {
        this.relatoriaService.reltoriaCurrent.subscribe(relatoriaDados => {
          this.relatoriaDados = relatoriaDados.relatoriasCapitulos
          this.dadosObtidos = relatoriaDados;
        })
      });
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public async imprimir() {
    this.router.navigate([`imprimir-relatoria/${this.id}/tipo-trabalho/${this.tipoTrabalho}`]);
    this.impressao = true
  }

  public modalHistoricoRelatoria(element: IRelatoriaCapituloModel) {
    try {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-historico-relatoria';
      dialogConfig.width = '800px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        capitulo: element
      };
      const modal = this.matDialog.open(ModalHistoricoRelatoriaComponent, dialogConfig);
      modal.afterClosed().subscribe((data) => {
        if (data) {
        }
      });
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public excluirRelatoria() {

    Swal.fire({
      title: 'Excluir Relatoria',
      text: 'Tem certeza que deseja excluir essa Relatoria?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(res => {

      if (res.value) {

        this.relatoriaService.excluirRelatoria(this.dadosObtidos.id).then((res) => {
          if (res.sucesso) {
            this.exibirMensagemSucesso(res.mensagem.descricao);
            this.obterDados();
            this.relatoriaService.reltoriaCurrent.subscribe(relatoriaDados => {
              this.relatoriaDados = relatoriaDados.relatoriasCapitulos
              this.dadosObtidos = relatoriaDados;
            })
          } else {
           this.exibirMensagemErro(res.mensagem.descricao);
          }
        })

      }
    }).catch(err => {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }

  public excluirCapitulo(itemId: number, capituloFixo: boolean, isCapitulo: boolean) {
    if (capituloFixo) {
     this.exibirMensagemErro('Capítulo obrigatório. Não é permitida a sua exclusão.');
      return;
    }
    
    if(isCapitulo && this.dadosObtidos.relatoriasCapitulos.length == 1){
      this.exibirMensagemErro("Ação não permitida pois é necessário ao menos 1 capítulo.");
      return;
    }


    Swal.fire({
      title: 'Excluir',
      text: 'Tem certeza que deseja excluir?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(res => {
      if (res.value) {
        this.relatoriaCapituloService.excluir(this.id, itemId, this.tipoTrabalho)
          .then(res => {
            if (res.sucesso) {
             this.exibirMensagemSucesso(res.mensagem.descricao);
            } else {
              this.exibirMensagemErro(res.mensagem.descricao);
            }
          }).catch(err => {
            this.exibirMensagemErro(err.mensagem.descricao);
          })
      }
    })

  }

  public reativarCapitulo(itemId: number) {
    Swal.fire({
      title: 'Reativar',
      text: 'Tem certeza que deseja reativar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(res => {
      if (res.value) {
        this.relatoriaCapituloService.reativar(this.id, itemId, this.tipoTrabalho)
          .then(res => {
            if (res.sucesso) {
              this.exibirMensagemSucesso(res.mensagem.descricao);
            } else {
             this.exibirMensagemErro(res.mensagem.descricao);
            }
          }).catch(err => {
           this.exibirMensagemErro(err.mensagem.descricao);
          })
      }
    })

  }

  public avaliarExclusao(id: number, status: boolean) {
    if (this.model?.situacao?.codigo !== 1) return;

    var name: string = "";
    if (status) {
      this.situacao = { situacao: { codigo: 1 } } as IAvaliacaoRelatoriaModel;
      name = 'confirmar'
    } else {
      this.situacao = { situacao: { codigo: 2 } } as IAvaliacaoRelatoriaModel;
      name = 'rejeitar'
    }
    Swal.fire({
      title: 'Avaliar exclusão do capítulo!',
      text: 'Tem certeza que deseja ' + name + ' a exclusão desse capítulo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(res => {
      if (res.value) {
        this.relatoriaCapituloService.avaliarExclusao(id, this.situacao, this.tipoTrabalho, this.id).then(res => {
          if (res.sucesso) {
            this.exibirMensagemSucesso(res.mensagem.descricao);
          } else {
           this.exibirMensagemErro(res.mensagem.descricao);
          }
        }).catch(err => {
         this.exibirMensagemErro(err.mensagem.descricao);
        })
      }
    })
  }

  public async salvarOne(itemCapitulo?: IRelatoriaCapituloModel, i?: number, isubcapitulo?: number, salvarTodos?: boolean) {
    if (!salvarTodos) {

      Swal.fire({
        title: isubcapitulo == undefined ? 'Salvar o capítulo?' : 'Salvar o sub-capítulo?',
        text: isubcapitulo == undefined ? 'Deseja realmente alterar este capítulo?' : 'Deseja realmente alterar este sub-capítulo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(res => {
        if (res.value) {
          this.relatoriaCapituloService.salvar(itemCapitulo, this.tipoTrabalho, this.id)
            .then(res => {
              if (res.sucesso) {
                this.exibirMensagemSucesso(res.mensagem.descricao);
                this.obterRelatorio();
              } else {
                this.exibirMensagemErro(res.mensagem.descricao);
                this.obterDados();
              }
            }).catch(err => {
              this.exibirMensagemErro(err.mensagem.descricao);
            })
        }
      })
    }
    else {
      const res = await this.relatoriaCapituloService.salvar(itemCapitulo, this.tipoTrabalho);
      try {
        this.exibirMensagemSucesso(res.mensagem.descricao);

        if (!salvarTodos) {
          this.relatoriaService.obterPorTipoTrabalho(this.id, this.tipoTrabalho).then(() => {
            this.relatoriaService.reltoriaCurrent.subscribe(relatoriaDados => {
              this.relatoriaDados = relatoriaDados.relatoriasCapitulos;
              this.dadosObtidos = relatoriaDados;
            });
          });
        }

      } catch (error) {
        this.exibirMensagemErro(error.mensagem.descricao);
      }
      finally {
        this.habilitarEdicaoRelatoria.activeAll = false;
        this.habilitarEdicaoCapituloRelatoria(i, false, isubcapitulo, null, false);
        this.habilitarEdicaoCapituloRelatoria(i, false, null, null, false);
      }
    }
  }

  async onDrop(event: CdkDragDrop<string[]>) {
    if (this.model?.situacao?.codigo !== 1) return;

    moveItemInArray(this.relatoriaDados, event.previousIndex, event.currentIndex);
    const capitulos = this.relatoriaDados.filter(_ => !_.relatoriaCapituloPaiId && _.status.codigo != 4);
    capitulos.forEach((element, idx) => {
      element.numero = idx + 1;
      const subCapitulos = this.relatoriaDados.filter(_ => _.relatoriaCapituloPaiId === element.id && _.status.codigo != 4);
      subCapitulos.forEach((subElement, subIdx) => {
        subElement.numero = subIdx + 1;
      });
    });
    await this.salvarTodos();
  }

  async onSubDrop(idCapitulo: number, event: CdkDragDrop<string[]>) {
    if (this.model?.situacao?.codigo !== 1) return;

    const capitulo = this.relatoriaDados.find(_ => _.id === idCapitulo);
    moveItemInArray(this.relatoriaDados.find(_ => _.id === idCapitulo).subRelatoriaCapitulos, event.previousIndex, event.currentIndex);
    capitulo.subRelatoriaCapitulos.filter(_ => _.status.codigo != 4).forEach((element, idx) => {
      element.numero = idx + 1;
    });
    await this.salvarTodos();
  }

  public async salvarTodos() {
    const tasks = [];

    this.relatoriaDados.filter(_ => _.status.codigo !== 3 && _.status.codigo !== 4).forEach((item) => {
      tasks.push(this.salvarOne(item, null, null, true));
      item?.subRelatoriaCapitulos.filter(_ => _.status.codigo !== 3 && _.status.codigo !== 4).forEach(subItem => {
        tasks.push(this.salvarOne(subItem, null, null, true));
      });
    });

    Promise.all(tasks)
      .finally(() =>
        this.relatoriaService.obterPorTipoTrabalho(this.id, this.tipoTrabalho).then(() => {
          this.relatoriaService.reltoriaCurrent.subscribe(relatoriaDados => {
            this.relatoriaDados = relatoriaDados.relatoriasCapitulos;
            this.dadosObtidos = relatoriaDados;
          });
        })
      );
  }

  public mostrarBotaoEditar() {
    return !this.impressao
            && !this.somenteLeitura
            && (this.dadosObtidos == undefined || this.dadosObtidos?.tipoTrabalhoStatus?.codigo === 1 || this.dadosObtidos.tipoTrabalho.codigo === 4);
  }

  public mostrarBotaoExcluir() {
    return !this.impressao
          && !this.somenteLeitura
          && (this.dadosObtidos == undefined || this.dadosObtidos?.tipoTrabalhoStatus?.codigo === 1);
  }

 
  addTag(keyword: string, position: number) {
    //this.atualizarModel(this.form.value);
    this.model.conteudo += `{{${keyword}}}`;
    //this.form.patchValue(this.model);
    this.relatoriaDados[position].conteudo += `{{${keyword}}}`;
  }

}
