import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { ITipoAuditoriaModel } from 'src/app/models/tipo-auditoria.model';
import { AuthService } from 'src/app/services/auth.service';
import { TipoAuditoriaService } from 'src/app/services/tipo-auditoria.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';

const sortingDataAccessor = (item, property) => {
  switch (property) {
    case 'nome': return item.nome.trim().toLowerCase();      
    case 'sigla':
      return item.coordenacao.sigla.trim().toLowerCase();
    default:
      return item[property];
  }
};

@Component({
  selector: 'app-tipo-auditoria-list',
  templateUrl: './tipo-auditoria-list.component.html',
  styleUrls: ['./tipo-auditoria-list.component.scss']
})
export class TipoAuditoriaListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<ITipoAuditoriaModel>([]);
  public selectedModel: ITipoAuditoriaModel;
  public displayedColumns: string[] = ['selecao', 'nome', 'quantidadeMembros', 'quantidadeDias', 'ativo'];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private tipoAuditoriaService: TipoAuditoriaService,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService
  ) {
    super();
    this.buscar();
  }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Visualizar);
 }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
  }

  public novo() {
    this.router.navigate(['/tipos-auditoria/novo']);
  }

  public buscar() {
    this.selectedModel = null;

    this.tipoAuditoriaService
      .obter(this.searchNome, this.searchAtivo, false)
      .then((res) => {
        this.dataSource = new MatTableDataSource<ITipoAuditoriaModel>(res.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.dataSource.sortingDataAccessor = sortingDataAccessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.toastr.error(err.mensagem.descricao, 'Atenção');
      })
  }

  public reativar(id: number) {
    this.tipoAuditoriaService
      .reativar(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Registro reativado com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.toastr.error(err.mensagem.descricao, 'Atenção');
      })
      .finally(() => {
        this.buscar();
      });
  }

  public inativar(id: number) {
    this.tipoAuditoriaService
      .inativar(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Registro inativado com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
      .catch((err) => {
        this.toastr.error(err.mensagem.descricao, 'Atenção');
      })
      .finally(() => {
        this.buscar();
      });
  }

  public editar(id: number) {
    this.router.navigate([`/tipos-auditoria/${id}`]);
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }

  public excluir(id: number) {
    this.tipoAuditoriaService
      .excluir(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Tipo auditoria excluído com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          showConfirmButton: false,
          timer: 5000
        });
      })
      .finally(() => {
        this.buscar();
      });
  }

  public visualizar(id: number) {
    this.router.navigate([`/tipos-auditoria/${id}/visualizar`]);
  }
}
