import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IMatrizPlanejamentoModel } from 'src/app/models/matriz-planejamento.model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { MatrizPlanejamentoService } from 'src/app/services/matriz-planejamento.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-matriz-planejamento-list',
  templateUrl: './matriz-planejamento-list.component.html',
  styleUrls: ['./matriz-planejamento-list.component.scss']
})
export class MatrizPlanejamentoListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<IMatrizPlanejamentoModel>([]);
  public selectedModel: IMatrizPlanejamentoModel;
  public displayedColumns: string[] = ['selecao', 'nome', 'ativo'];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private matrizPlanejamentoService: MatrizPlanejamentoService,
    private router: Router,
    private authService: AuthService
  ) {
    super();
    this.buscar();
  }
  
  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Visualizar);
    this.permissoes.imprimir = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Imprimir);
 }

  public novo() {
    this.router.navigate(['/matriz-planejamento/novo']);
  }

  public buscar() {
    this.selectedModel = null;

    this.matrizPlanejamentoService
      .obter(this.searchNome, this.searchAtivo, false)
      .then((res) => {
        this.dataSource = new MatTableDataSource<IMatrizPlanejamentoModel>(res.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public reativar(id: number) {
    this.matrizPlanejamentoService
      .reativar(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Registro reativado com sucesso!',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public inativar(id: number) {
    this.matrizPlanejamentoService
      .inativar(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Registro inativado com sucesso!',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public editar(id: number) {
    this.router.navigate([`/matriz-planejamento/${id}/edicao`]);
  }

  public visualizar(id: number) {
    this.router.navigate([`/matriz-planejamento/${id}/visualizar`]);
  }

  public imprimir(id: number) {
    this.router.navigate([`/matriz-planejamento/relatorio/${id}`]);
  }

  public excluir(id: number) {
    this.matrizPlanejamentoService
      .excluir(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Registro excluído com sucesso!',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel !== item ? item : null;
  }
}
