<div [class]="homologado ? '' : 'homologado'">
    <div class="relatorio content" *ngIf="dados">
        <div class="card card-outline">
            <div class="card-body">
                <section id="header">
                    <h3>PAAC - {{ dados.paa?.pea?.nome }}</h3>
                </section>
                <hr />

                <hr />
                <section id="indicadorCriterio">
                    <h4>Indicador e Critérios</h4>
                    <div *ngFor="let indicador of dados.indicadores">
                      <div>
                        <label>Indicador: </label>
                        <span>{{ indicador.nome }}</span>
                      </div>
        
                      <div
                        class="criterio"
                        *ngFor="let criterio of indicador.indicadorCriterio"
                      >
                        <div class="row ml-1">
                          <div class="mr-2">
                            <label><strong>Nome do critério: </strong></label>
                            <span>{{ criterio.criterio.nome }}</span>
                          </div>
                          <div class="mr-2">
                            <label><strong>Peso: </strong></label>
                            <span>{{ criterio.criterio.peso }}</span>
                          </div>
                          <div class="mr-2">
                            <label><strong>Tipo de Entrada: </strong></label>
                            <span>{{ criterio.criterio.tipoEntrada.nome }}</span>
                          </div>
                        </div>
        
                        <table
                          class="table m-0"
                          *ngIf="criterio.criterio.criterioMetrica.length > 0"
                        >
                          <thead>
                            <tr>
                              <th>Métrica</th>
                              <th class="text-center" style="width: 115px;">Nota</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let metrica of criterio.criterio.criterioMetrica">
                              <td *ngIf="metrica.operacao1Id">
                                {{ metrica.operacao1.descricao }}
                                {{ metrica.valorNumerico1 | number: "1.2":"pt" }}
                                <span *ngIf="metrica.operacao2?.codigo > 0"
                                  >{{ metrica.operacao2.descricao }}
                                  {{
                                    metrica.valorNumerico2 | number: "1.2":"pt"
                                  }}</span
                                >
                              </td>
                              <td *ngIf="metrica.perguntaOpcaoId">
                                {{ metrica.perguntaOpcao.nome }}
                                {{ metrica.nota | number: "1.2":"pt" }}
                                >
                              </td>
                              <td class="text-center">{{ metrica.nota }}</td>
                            </tr>
                          </tbody>
                          
                        </table>
                      </div>
                    </div>
                    
                    <div
                        class="criterio"
                        *ngFor="let criterio of dados.criterios"
                      >
                        <div class="row ml-1">
                          <div class="mr-2">
                            <label><strong>Nome do critério: </strong></label>
                            <span>{{ criterio.nome }}</span>
                          </div>
                          <div class="mr-2">
                            <label><strong>Peso: </strong></label>
                            <span>{{ criterio.peso }}</span>
                          </div>
                          <div class="mr-2">
                            <label><strong>Tipo de Entrada: </strong></label>
                            <span>{{ criterio.tipoEntrada.nome }}</span>
                          </div>
                        </div>
        
                        <table
                          class="table m-0"
                          *ngIf="criterio.criterioMetrica.length > 0"
                        >
                          <thead>
                            <tr>
                              <th>Métrica</th>
                              <th class="text-center" style="width: 115px;">Nota</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let metrica of criterio.criterioMetrica">
                              <td>
                                {{ metrica.operacao1.descricao }}
                                {{ metrica.valorNumerico1 | number: "1.2":"pt" }}
                                <span *ngIf="metrica.operacao2?.codigo > 0"
                                  >{{ metrica.operacao2.descricao }}
                                  {{
                                    metrica.valorNumerico2 | number: "1.2":"pt"
                                  }}</span
                                >
                              </td>
                              <td class="text-center">{{ metrica.nota }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                  </section>
              
            
            
            <hr />
                <section id="capacidadeTrabalho">
                    <h4>Capacidade de Trabalho</h4>

                    <div>
                        <table>
                            <tr>
                                <th>Coordenação</th>
                                <th class="text-right">Capacidade Total</th>
                                <th class="text-right">Capacidade Total do PEAC</th>
                                <th>Auditores</th>
                            </tr>

                            <tr *ngFor="let item of dados?.capacidadeTrabalho">
                                <td>{{ item.coordenacaoSigla }}</td>
                                <td>{{ item.capacidadeTotalCoordenacao }}</td>
                                <td>{{ item.capacidadeTotalCoordenacaoPea }}</td>
                                <td>{{ item.auditores }}</td>
                            </tr>

                            <tr>
                                <th>Total</th>
                                <th>{{totalCapacidadeCoordenacaoes}}</th>
                                <th>{{totalCapacidadeCoordenacaoesPea}}</th>
                            </tr>
                        </table>
                    </div>
                </section>

                <hr />
                <section id="distribuicao" class="page-break">
                    <h4>Painel de Distribuição</h4>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="mat-elevation-z1 scroll" style="width: 100%">
                                <table
											mat-table
											[dataSource]="
												distribuicaoDataSource
											"
											#tabela3="matSort"
											matSort
											matSortDisableClear
											class="w-100"
										>
                                        <ng-container
												matColumnDef="unidadeGestora"
											>
                                            <th
                                            mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header
                                            align="center"
                                        >
                                            Sigla
                                        </th>
                                        <td
                                            mat-cell
                                            *matCellDef="let element"
                                        >
                                            {{
                                                element?.unidadeGestora
                                                    ?.sigla
                                            }}
                                            <span
                                                *ngIf="
                                                    element
                                                        ?.unidadeGestora
                                                        ?.auditoriaObrigatoria &&
                                                    element?.peaId ==
                                                        null &&
                                                    element?.peaId ==
                                                        undefined
                                                "
                                                class="
                                                    badge
                                                    bg-danger
                                                    text-xs
                                                "
                                                >Obrigatória</span
                                            >
                                        </td>
                                        </ng-container>
                                        
                                        <ng-container
												matColumnDef="valorRisco"
											>
												<th
													mat-header-cell
													*matHeaderCellDef
													mat-sort-header
													align="center"
												>
													Valor de Risco Inerente
												</th>
												<td
													mat-cell
													*matCellDef="let element"
												>
													<span
														*ngIf="
															element?.peaId !=
																null &&
															element?.peaId !=
																undefined
														"
													>
														{{
															element?.valorRisco
														}}
													</span>
												</td>
											</ng-container>

                                           
                                            <div
												*ngFor="
													let coluna of colunasAdicionarDistribuicao
												"
											>
												<ng-container
													[matColumnDef]="coluna"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														mat-sort-header
														align="center"
													>
														{{ coluna }}
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														{{
															obterValorColuna(
																element,
																coluna
															)
																| number
																	: "1.2-2"
																	: "pt-br"
														}}
													</td>
												</ng-container>
											</div>

                                            <tr
                                            mat-header-row
                                            *matHeaderRowDef="
                                                columnsDistribuicao
                                            "
                                            class="bg-light"
                                        ></tr>
                                        <tr
                                            mat-row
                                            *matRowDef="
                                                let row;
                                                columns: columnsDistribuicao
                                            "
                                        ></tr>

                                    </table>
                            </div>
                        </div>
                    </div>

                </section>

                <hr />
                <section id="alocaloes">
                    <h4>Alocação de Equipes</h4>

                    <div class="cards inline">
                        <div class="card inline">
                            <table>
                                <tr>
                                    <th>Unidade Gestora</th>
                                    <th>Tipo de Auditoria</th>
                                    <th>Data Início</th>
                                    <th>Data Fim</th>
                                    <th>Tipo Ênfase</th>
                                    <th>Equipe Alocada</th>
                                </tr>
                                <tr *ngFor="let item of dados?.equipes">
                                    <td>
                                        <p>{{ item.paaDistribuicao?.unidadeGestora?.nome }}</p>
                                    </td>
                                    <td>
                                        <p>{{ item.paaDistribuicao?.tipoAuditoria?.nome }}</p>
                                    </td>
                                    <td>
                                        <p>{{ item.dataInicio | date: 'dd/MM/yyyy'}}</p>
                                    </td>
                                    <td>
                                        <p>{{ item.dataFim | date: 'dd/MM/yyyy'}}</p>
                                    </td>
                                    <td>
                                        <p>{{ item.tipoEnfase}}</p>
                                    </td>
                                    <td>
                                        <div *ngFor="let itemAuditor of item.paaEquipeAuditor">
                                            <p>{{ itemAuditor.nomeAuditor }}</p>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="mt-3 w-100">

                            <app-modal-grafico-distribuicao [dadosImprimir]="grafico"></app-modal-grafico-distribuicao>
                        </div>
                    </div>
                </section>
            </div>
            <div class="card-footer no-print">
                <button type="button" class="btn btn-default btn-sm mr-1" (click)="cancelar()">
                    <i class="fas fa-times"></i> Fechar
                </button>
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="print()">
                    <i class="fas fa-print"></i> Imprimir
                </button>
            </div>
        </div>
    </div>
</div>