import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filterBy } from '../core/helpers/filterby';
import { IBaseModel } from '../models/base.model';
import { IFiltroPendenciaModel } from '../models/filtro-pendencia.model';
import { PaginacaoModel } from '../models/paginacao.model';
import { IPendenciaModel } from '../models/pendencia.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PendenciaService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public async obter(filtro: IFiltroPendenciaModel) {
    let params = new HttpParams();
    params = params.append('pesquisa', filtro.pesquisa || '');

    if (Object.keys(filtro).length > 0) {
      return this.httpClient
        .get<IBaseModel<PaginacaoModel<IPendenciaModel[]>>>(filterBy(filtro, `${this.apiBaseUrl}/pendencia`), { params })
        .toPromise();
    }
  }

  public async obterPendencias(): Promise<IBaseModel<any>> {
    return this.httpClient
      .get<IBaseModel<any>>(`${this.apiBaseUrl}/pendencia/nao-resolvidas`)
      .toPromise();
  }
}
