import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { IConstatacaoModel } from 'src/app/models/constatacao.model';
import { IQuestaoConstatacaoModel } from 'src/app/models/questao-constatacao.model';
import { ConstatacaoService } from 'src/app/services/constatacao.service';
import { IConstatacaoRecomendacaoModel } from 'src/app/models/constatacao-recomendacao.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { angularEditorConfig } from 'src/app/shared/util/angularEditorConfig';

@Component({
  selector: 'app-modal-constatacao',
  templateUrl: './modal-constatacao.component.html',
  styleUrls: ['./modal-constatacao.component.scss']
})

export class ModalConstatacaoComponent implements OnInit {
  public modelQuestaoConstatacao: IQuestaoConstatacaoModel = {} as IQuestaoConstatacaoModel;
  public model: IConstatacaoModel = {} as IConstatacaoModel;
  public modelConstatacaoRecomendacao: IConstatacaoRecomendacaoModel = {} as IConstatacaoRecomendacaoModel;
  public recomendacaoDataSource = new MatTableDataSource<IConstatacaoRecomendacaoModel>([]);

  public semDados = true;
  public visualizacao = true;
  public titulo = '';

  constructor(
    private dialogRef: MatDialogRef<ModalConstatacaoComponent>,
    public toastr: ToastrService,
    private constatacaoService: ConstatacaoService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {

    this.modelQuestaoConstatacao = data;

    this.titulo = 'Visualização de Constatação';

  }

  public config: AngularEditorConfig = angularEditorConfig('', '10rem', '100%', false);

  async ngOnInit() {

    if (this.modelQuestaoConstatacao !== null) {
      const res = await this.constatacaoService.obterPorId(this.modelQuestaoConstatacao.constatacaoId);

      if (res.sucesso) {
        this.model = res.dados;

        this.recomendacaoDataSource = new MatTableDataSource<IConstatacaoRecomendacaoModel>
        (
          this.model.constatacaoRecomendacao
        );
        this.recomendacaoDataSource._updateChangeSubscription();
        this.semDados = this.recomendacaoDataSource.filteredData.length === 0;
      }
    }
  }

  public cancelar() {
    this.dialogRef.close(false);
  }
}

