<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">Tem certeza que deseja rejeitar esse registro?</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">

      <div class="table-container p-3">

        <div class="row">
          <div class="form-group col-md-12" [formGroup]="form">
            <label for="justificativa" class="mb-1">Justificativa</label>
            <textarea rows="5" name="justificativa" id="justificativa" class="form-control form-control-sm" formControlName="justificativa"></textarea>
              <div *ngIf="form.controls['justificativa'].touched && form.controls['justificativa'].invalid"
                      class="invalid-feedback">
              </div>
          </div>
        </div>

      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()"
      [disabled]="form.controls['justificativa'].invalid">
        <i class="fas fa-check"></i> Sim
      </button>

      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Não
      </button>
    </div>
  </div>
</div>
