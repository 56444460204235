import { PaginacaoModel } from './../models/paginacao.model';
import { FiltroModel } from './../models/filtro.model';
import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IUnidadeGestoraModel } from '../models/unidade-gestora.model';
import { BaseService } from './base.service';
import { filterBy } from './auditoria.service';

@Injectable({
  providedIn: 'root',
})
export class UnidadeGestoraService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(filtro?: FiltroModel): Promise<IBaseModel<PaginacaoModel<IUnidadeGestoraModel[]>>> {
      return this.httpClient
      .get<IBaseModel<PaginacaoModel<IUnidadeGestoraModel[]>>>(filterBy(filtro, `${this.apiBaseUrl}/unidadegestora`))
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IUnidadeGestoraModel>> {
    return this.httpClient
      .get<IBaseModel<IUnidadeGestoraModel>>(`${this.apiBaseUrl}/unidadegestora/${id}`)
      .toPromise();
  }

  public obterAtivos(): Promise<IBaseModel<IUnidadeGestoraModel[]>> {
    return this.httpClient
      .get<IBaseModel<IUnidadeGestoraModel[]>>(`${this.apiBaseUrl}/unidadegestora/ativos`)
      .toPromise();
  }

  public async inserir(data: IUnidadeGestoraModel): Promise<IBaseModel<IUnidadeGestoraModel>> {
    return this.httpClient
      .post<IBaseModel<IUnidadeGestoraModel>>(`${this.apiBaseUrl}/unidadegestora`, data)
      .toPromise();
  }

  public async atualizar(data: IUnidadeGestoraModel): Promise<IBaseModel<IUnidadeGestoraModel>> {
    return this.httpClient
      .put<IBaseModel<IUnidadeGestoraModel>>(`${this.apiBaseUrl}/unidadegestora/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/unidadegestora/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/unidadegestora/${id}/inativar`, {})
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/unidadegestora/${id}/excluir`, {})
      .toPromise();
  }

  public async importarUgServico() {
    return this.httpClient
      .get(`/incluir-ug-servico`)
      .toPromise();
  }

  public async importar(sigla: string): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/unidadegestora/importar/${sigla}`, {})
      .toPromise();
  }
}
