<h2 mat-dialog-title>
    <mat-icon>warning</mat-icon>
    Concordar com a(s) Resposta(s) Selecionada(s)?
</h2>

<mat-dialog-content>
    <p>Caso confirme, todas as respostas serão finalizadas!</p>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-raised-button color="primary" (click)="onConfirm()">Sim</button>
    <button mat-button color="warn" (click)="onCancel()">Não</button>
</mat-dialog-actions>
