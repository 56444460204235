
import { AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Inputmask from 'inputmask';
import Swal from 'sweetalert2';
import { PeriodoExercicioService } from 'src/app/services/periodo-exercicio.service';
import { IPeriodoExercicioModel } from 'src/app/models/periodo-exercicio.model';
import { INaturezaResponsabilidadeModel } from 'src/app/models/natureza-responsabilidade';
import { IEnumModel } from 'src/app/models/enum.model';
import { IAtoNomeacaoModel } from 'src/app/models/ato-nomeacao.model';
import { NaturezaResponsabilidadeService } from 'src/app/services/natureza-responsabilidade.service';
import { LookupModalComponent } from 'src/app/shared/components/lookup-modal/lookup-modal.component';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { IAtoExoneracaoModel } from 'src/app/models/ato-exoneracao.model';

@Component({
  selector: 'app-modal-incluir-periodo-exercicio',
  templateUrl: './modal-incluir-periodo-exercicio.component.html',
  styleUrls: ['./modal-incluir-periodo-exercicio.component.scss'],
})
export class ModalIncluirPeriodoExercicioComponent
  implements OnInit, AfterViewInit {

  public enumTipoAgente = [];
  public enumTipoAto = [];
  public model: IPeriodoExercicioModel = {} as IPeriodoExercicioModel;
  public visualizar = true;
  public submited = false;
  public dataInicio: Date;
  public dataFim: Date;
  public dataAtoNomeacao: Date;
  public dataPublicacaoNomeacao: Date;
  public dataAtoExoneracao: Date;
  public dataPublicacaoExoneracao: Date;

  constructor(
    private dialogRef: MatDialogRef<ModalIncluirPeriodoExercicioComponent>,
    public matDialog: MatDialog,
    private periodoExercicioService: PeriodoExercicioService,
    private tabelaDominioService: TabelaDominioService,
    private naturezaResponsabilidadeService: NaturezaResponsabilidadeService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.model.responsavelId = data.responsavelId;
    this.model.unidadeGestoraId = data.unidadeGestoraId;

    if (data.editar) {
      if (!data.element?.id) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: 'Responsável não encontrado.',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        this.dialogRef.close(true);
      }
      else {
        this.obterPorId(data.element.id);
      }
    }
    this.visualizar = data.visualizar;
  }

  onDataInicioChange(newDate: Date) {
    this.dataInicio = new Date(newDate);
  }

  onDataFimChange(newDate: Date) {
    this.dataFim = new Date(newDate);
  }

  onDataAtoNomeacaoChange(newDate: Date) {
    this.dataAtoNomeacao = new Date(newDate);
  }

  onDataPublicacaoNomeacaoChange(newDate: Date) {
    this.dataPublicacaoNomeacao = new Date(newDate);
  }

  onNumeroNomeacaoChange(numero: number) {
    if (!this.model.atoNomeacao) {
      this.model.atoNomeacao = {} as IAtoNomeacaoModel;
    }
    this.model.atoNomeacao.numero = numero;
  }

  onDataAtoExoneracaoChange(newDate: Date) {
    this.dataAtoExoneracao = new Date(newDate);
  }

  onDataPublicacaoExoneracaoChange(newDate: Date) {
    this.dataPublicacaoExoneracao = new Date(newDate);
  }

  onNumeroExoneracaoChange(numero: number) {
    if (!this.model.atoExoneracao) {
      this.model.atoExoneracao = {} as IAtoExoneracaoModel;
    }
    this.model.atoExoneracao.numero = numero;
  }

  onTipoAtoNomeacaoChange(event: any) {
    if (!this.model.atoNomeacao) {
      this.model.atoNomeacao = {} as IAtoNomeacaoModel;
      if (!this.model.atoNomeacao.tipoAto) {
        this.model.atoNomeacao.tipoAto = {} as IEnumModel;
      }
    }
    this.model.atoNomeacao.tipoAto = {
      codigo: event.target.value
    } as IEnumModel;
  }

  onTipoAtoExoneracaoChange(event: any) {
    if (!this.model.atoExoneracao) {
      this.model.atoExoneracao = {} as IAtoExoneracaoModel;
      if (!this.model.atoExoneracao.tipoAto) {
        this.model.atoExoneracao.tipoAto = {} as IEnumModel;
      }
    }
    this.model.atoExoneracao.tipoAto = {
      codigo: event.target.value
    } as IEnumModel;
  }

  onTipoAgenteChange(event: any) {
    if (!this.model.tipoAgente) {
      this.model.tipoAgente = {
        codigo: event.target.value
      } as IEnumModel;
    }
    this.model.tipoAgente = {
      codigo: event.target.value
    } as IEnumModel;
  }

  ngOnInit(): void {
    this.tabelaDominioService.obterTipoAgente().then((res) => this.enumTipoAgente = res.dados);
    this.tabelaDominioService.obterTipoAto().then((res) => this.enumTipoAto = res.dados);
  }

  ngAfterViewInit(): void {
    Inputmask().mask(document.querySelectorAll('input'));
  }

  public obterPorId(id: number) {
    this.periodoExercicioService.obterPorId(id)
      .then((res) => {
        if (res.sucesso) {
          this.model = res.dados;
          this.dataInicio = this.model.dataInicio ? new Date(this.model.dataInicio) : null;
          this.dataFim = this.model.dataFim ? new Date(this.model.dataFim) : null;
          this.dataAtoNomeacao = this.model.atoNomeacao?.dataAto ? new Date(this.model.atoNomeacao.dataAto) : null;
          this.dataPublicacaoNomeacao = this.model.atoNomeacao?.dataPublicacao ? new Date(this.model.atoNomeacao.dataPublicacao) : null;
          this.dataAtoExoneracao = this.model.atoExoneracao?.dataAto ? new Date(this.model.atoExoneracao.dataAto) : null;
          this.dataPublicacaoExoneracao = this.model.atoNomeacao?.dataPublicacao ? new Date(this.model.atoExoneracao.dataPublicacao) : null;
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'warning',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
  }

  public concluir(fechar: boolean) {

    this.submited = false;
    
    this.model.dataInicio = this.dataInicio ? new Date(this.dataInicio) : null;
    this.model.dataFim = this.dataFim ? new Date(this.dataFim) : null;

    if(this.model.dataFim){
      if(this.model.dataFim < this.model.dataInicio){
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: 'Data início não pode ser superior a data fim.',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        return;
      }
    }

    if (!this.model.atoNomeacao) { this.model.atoNomeacao = {} as IAtoNomeacaoModel; }
    if (!this.dataAtoNomeacao && !this.dataPublicacaoNomeacao && !this.model.atoNomeacao?.numero) { 
      this.model.atoNomeacaoId = null; 
      this.model.atoNomeacao = null; 
    }
    else {
      this.model.atoNomeacao.dataAto = this.dataAtoNomeacao ? new Date(this.dataAtoNomeacao) : null;
      this.model.atoNomeacao.dataPublicacao = this.dataPublicacaoNomeacao ? new Date(this.dataPublicacaoNomeacao) : null;      
    }

    if (!this.model.atoExoneracao) { this.model.atoExoneracao = {} as IAtoExoneracaoModel; }
    if (!this.dataAtoExoneracao && !this.dataPublicacaoExoneracao && !this.model.atoExoneracao?.numero) {
      this.model.atoExoneracao = null;
      this.model.atoExoneracaoId = null;
    }
    else {
      this.model.atoExoneracao.dataAto = this.dataAtoExoneracao ? new Date(this.dataAtoExoneracao) : null;
      this.model.atoExoneracao.dataPublicacao = this.dataPublicacaoExoneracao ? new Date(this.dataPublicacaoExoneracao) : null;      
    }

    if (!this.model.naturezaResponsabilidadeId || !this.model.tipoAgente?.codigo
      || !this.model.unidadeGestoraId || !this.model.responsavelId || !this.model.dataInicio
      || !this.model.atoNomeacao || !this.model.atoNomeacao.tipoAto?.codigo || !this.model.atoNomeacao.dataAto || !this.model.atoNomeacao.numero || !this.model.atoNomeacao.dataPublicacao
      || (this.dataFim && !this.model.atoExoneracao?.dataAto && !this.model.atoExoneracao?.numero && !this.model.atoExoneracao?.dataPublicacao
        && !this.model.atoExoneracao?.tipoAto?.codigo)) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: 'Formulário inválido',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.submited = true;
      return;
    }

    if (this.model.id) {
      this.periodoExercicioService.atualizar(this.model)
        .then((res) => {
          if (res.sucesso) {
            this.model = res.dados;
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
            if (fechar) {
              this.dialogRef.close(true); // Fecha a tela após salvar
            }
          } else {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'warning',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: err.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        })
    }
    else {
      this.periodoExercicioService.inserir(this.model)
        .then((res) => {
          if (res.sucesso) {
            this.model = res.dados;
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
            if (fechar) {
            this.dialogRef.close(true);
            }
          } else {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'warning',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: err.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        })
    }
  }

  public async exibirModalNaturezaResponsabilidade() {
    try {
      const res = await this.naturezaResponsabilidadeService.obter('', true);
      if (res.sucesso) {
        const modal = this.exibirModal('Selecionar Natureza da Responsabilidade', res.dados);
        modal.afterClosed().subscribe((data) => {
          if (data) {
            this.model.naturezaResponsabilidadeId = data.id;
            if (!this.model.naturezaResponsabilidade) { this.model.naturezaResponsabilidade = {} as INaturezaResponsabilidadeModel; }
            this.model.naturezaResponsabilidade.nome = data.nome;
          }
        });
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public exibirModal(titulo: string, dados: any, exibirId = false, customizeColumns = null): MatDialogRef<any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component-natureza';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = { titulo, dados, exibirId, customizeColumns };
    return this.matDialog.open(LookupModalComponent, dialogConfig);
  }

  public fechar() {
    this.dialogRef.close();
  }

  public cancelar() {
    this.dialogRef.close(true);
  }
}
